import React from 'react';
import { connect } from "react-redux";
import { BrowserRouter, Route, NavLink, withRouter } from 'react-router-dom';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IosLogOut from 'react-ionicons/lib/IosLogOut';

//actions import
import {logOutUser} from "../actions/AuthenticationActions";
import { fetchTotalBook } from "../actions/AnalyticsActions";
import { fetchAdmins, grabAnalytics, fetchLoansWithSurplus, fetchExternalCollectors, demouSe } from "../actions/AppActions";
import { fetchProfile } from "../actions/ProfileActions";
import { checkChequeClearance, calculatePenalInterest, fetchSystemInfo } from "../actions/AppActions";
import { fetchBouncedCheques, fetchOverdueTerms } from "../actions/MasterListActions";
import { computeIncorrectLoans, fetchSurplus } from "../actions/ManualOverrideActions";
import {fetchEcecutives} from "../actions/SettingsActions";

//components import
import Home from "./clients/Home";
import CustomerProfile from "./clients/customerProfile/CustomerProfile";
import LoanPage from "./clients/customerProfile/LoanPage";
import Analytics from "./analytics/Analytics";
import MasterFile from "./pdc/MasterFile";
import Collections from "./collections/Collections";
import SignUpPage from './authentication/SignUpPage';
import Profiles from './authentication/Profiles';
import CustomerDetails from "./collections/subComponents/CustomerDetails";
import SelectedLoan from "./collections/subComponents/SelectedLoan";
import Industry from "./settings/manage/Industry";
import Segment from "./settings/manage/Segment";
import Status from "./settings/manage/Status";
import Reports from "./reports/Reports";
import MasterList from "./masterList/MasterList";
import Unidentified from "./unidentified/Unidentified";
import HeldCheques from "./heldCheques/HeldCheques";
import Settings from "./settings/Settings";
import ServiceCharges from './serviceCharges/ServiceCharges';
import DownPayments from './payments/DownPayments';
import IncorrectLoans from './settings/subComponents/IncorrectLoans';
import IncompleteLoans from "./settings/subComponents/IncompleteLoans";
import CurrentPaidLoans from './settings/subComponents/CurrentPaidLoans';
import SurplusLoans from './settings/subComponents/SurplusLoans';
import DemandNoticesCard from "./masterList/cards/DemandNoticesCard";
import ODAging from "./masterList/ODAging";
import ODAgingNew from './masterListNew/ODAgingNew';

import ForgetPassword from "./authentication/ForgetPassword";

//import IdentifiedCustomerLoans from "./unidentified/identifiedPayments/IdentifiedCustomerLoans";
import IdentifiedCustomer from "./unidentified/identifiedPayments/IdentifiedCustomer";
import TermTermPage from "./clients/customerProfile/LoanTermPage";
import ManageUser from "./authentication/ManageUser";
//import {Spinner} from "./common/Spinner";
import InitialSettings from './InitialSettings';

//customer imports
import ManualOverride from "./clients/customerProfile/subComponents/ManualOverride";


//file imports
import Logo from '../assets/logo/logo.png';
import {BeatLoader} from "react-spinners";
import Character from "./settings/manage/Character";
import Action from "./settings/manage/Action";
import { Button } from './common/Button';

const retrievedProject = localStorage.getItem('afclInitializedProject');


class Main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            systemInfo: this.props.systemInfo,
            system: null,
            user: null,
            profile: this.props.profile,
            navTitle: 'hideTitle'
        };

        this.hoverActivated = this.hoverActivated.bind(this);
        this.hoverDeactivated = this.hoverDeactivated.bind(this);
        this.onLogOutPress = this.onLogOutPress.bind(this);

    }

    componentWillMount() {

        //this.props.fetchTotalBook();
        this.props.fetchAdmins();
        this.props.fetchExternalCollectors();
        this.props.fetchEcecutives();
        this.props.fetchProfile();
        //this.props.checkChequeClearance();
        //this.props.calculatePenalInterest({main: true});
        this.props.fetchSystemInfo();
        this.props.fetchBouncedCheques();
        this.props.fetchOverdueTerms();
        // this.props.demouSe();
        //this.props.fetchSurplus();
        // this.props.grabAnalytics();
        //this.props.computeIncorrectLoans();
        // this.props.fetchLoansWithSurplus();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.computeSystemPosition(nextProps.systemInfo);
            this.setState({ systemInfo: nextProps.systemInfo })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }
    }

    computeSystemPosition(systemInfo) {
        let system = false;

        if (!(_.isEmpty(systemInfo))) {
            'exchangeRate' in systemInfo ? system = true : system = false;
        }

        this.setState({ system });
    }

    onLogOutPress() {
        //
        const {history} = this.props;

        localStorage.removeItem("afclFirebaseInitializationObject");
        localStorage.removeItem("afclInitializedProject");
        //log out action called
        this.props.logOutUser(history);
    }

    renderProfile() {
        //extract state
        const { profile } = this.state;

        //check if profile is not empty
        if (!(_.isEmpty(profile))) {
            switch (profile.role) {
                case "creditManager":
                    return <h4><b>{profile.name}</b> | Credit Manager</h4>;
                case "collectionsManager":
                    return <h4><b>{profile.name}</b> | Collections Manager</h4>;
                case "collector":
                    return <h4><b>{profile.name}</b> | Collector</h4>;
                case "accountant":
                    return <h4><b>{profile.name}</b> | Accountant</h4>;
                case "superAdmin":
                    return <h4><b>{profile.name}</b> | Super Admin</h4>;
                default:
                    return <h4>Admin</h4>;
            }
        } else {
            return <BeatLoader/>
        }
    }

    hoverActivated() {
        this.setState({ navTitle: 'showTitle'});
    }

    hoverDeactivated() {
        this.setState({ navTitle: 'hideTitle'});
    }

    renderSystem() {
        //extract state
        const { profile, system } = this.state;
        

        //check if profile is not empty
        if (!(_.isEmpty(profile))) {
            switch (system) {
                case true:
                    return(
                        <div className={"bodyContainerContents"}>
                            <Route exact path={"/"} component={Collections} />
                            <Route exact path={"/clients"} component={Home} />
                            <Route exact path={"/customer-page"} component={CustomerProfile} />
                            <Route exact path={"/loan-page"} component={LoanPage} />
                            <Route exact path={"/loan-term"} component={TermTermPage} />
                            <Route exact path={"/analytics-page"} component={Analytics} />
                            <Route exact path={"/master-file"} component={MasterFile} />
                            <Route exact path={"/customer-details"} component={CustomerDetails} />
                            <Route exact path={"/customer-loan"} component={SelectedLoan} />
                            <Route exact path={"/reports-page"} component={Reports} />
                            <Route exact path={"/overdue-aging"} component={ODAging} />
                            <Route exact path={"/overdue-aging-new"} component={ODAgingNew} />
                            <Route exact path={"/held-cheques"} component={HeldCheques} />
                            <Route exact path={"/service-charges"} component={ServiceCharges} />
                            <Route exact path={"/down-payment"} component={DownPayments} />
                            <Route exact path={"/unidentified-page"} component={Unidentified} />
                            <Route exact path={"/identified-customer"} component={IdentifiedCustomer} />
                            <Route exact path={"/profiles"} component={Profiles} />
                            <Route exact path={"/sign-up"} component={SignUpPage} />
                            <Route exact path={"/manage-user"} component={ManageUser} />
                            <Route exact path={"/manage-industry"} component={Industry} />
                            <Route exact path={"/manage-segment"} component={Segment} />
                            <Route exact path={"/manage-characters"} component={Character} />
                            <Route exact path={"/manage-actions"} component={Action} />
                            <Route exact path={"/manage-loan"} component={Status} />
                            <Route exact path={"/settings"} component={Settings} />
                            <Route exact path={"/demand-notices"} component={DemandNoticesCard} />
                            <Route exact path={"/manual-override"} component={ManualOverride} />
                            <Route exact path={"/incorrect-loans"} component={IncorrectLoans} />
                            <Route exact path={"/incomplete-loans"} component={IncompleteLoans} />
                            <Route exact path={"/current-paid-loans"} component={CurrentPaidLoans} />
                            <Route exact path={"/surplus-loans"} component={SurplusLoans} />
                        </div>
                    );
                case false:
                    return <h1 className={"initialSettingsHeading"}>Set Initial Settings</h1>;
                default:
                    return <BeatLoader/>;
            }
        } else { 
            return <BeatLoader/>
        }
    }

    render() {

        const { navTitle, system, profile } = this.state;

        let project = {};

        if (retrievedProject) {
            project = JSON.parse(retrievedProject);
        }

        //determine modal visibility
        let visible;
        switch (system) {
            case true:
                visible = false;
                break;
            case false:
                visible = true;
                break;
            default:
                visible = false;
                break;
        }

        
        return (
            <BrowserRouter>
                <div className={"mainContainer"}>
                    <div className={"nav"} onMouseEnter={() => this.hoverActivated()} onMouseLeave={() => this.hoverDeactivated()}>
                        <div className={"logoBox"}>
                            <img src={Logo} alt="tata logo" />
                        </div>
                        <ul>
                            <li><NavLink to={'/'}><FontAwesomeIcon icon="object-group" /><span id={navTitle}> Collections</span></NavLink></li>
                            <li><NavLink to={'/unidentified-page'}><FontAwesomeIcon icon="exclamation-circle" /><span id={navTitle}> Unidentified</span></NavLink></li>
                            <li><NavLink to={'/service-charges'}><FontAwesomeIcon icon="th-large" /><span id={navTitle}> Service Charges</span></NavLink></li>
                            <li><NavLink to={'/down-payment'}><FontAwesomeIcon icon="th-large" /><span id={navTitle}> Down Payment</span></NavLink></li>
                            {/* <li><NavLink to={'/overdue-aging'}><FontAwesomeIcon icon="layer-group" /><span id={navTitle}> OD Aging</span></NavLink></li> */}
                            {/* {
                                (project.privateID === "0001") ?
                                (profile.username === "admin@firm23.co.tz") ?
                                <li><NavLink to={'/overdue-aging-new'}><FontAwesomeIcon icon="layer-group" /><span id={navTitle}> OD Aging New</span></NavLink></li> 
                                : null
                            }  */}
                            <li><NavLink to={'/overdue-aging-new'}><FontAwesomeIcon icon="layer-group" /><span id={navTitle}> OD Aging</span></NavLink></li> 

                        
                            <li><NavLink to={'/held-cheques'}><FontAwesomeIcon icon="pause" /><span id={navTitle}> Held Cheques</span></NavLink></li>
                            <li><NavLink to={'/clients'}><FontAwesomeIcon icon="th-large" /><span id={navTitle}> Clients</span></NavLink></li>
                            <li><NavLink to={'/analytics-page'}><FontAwesomeIcon icon="chart-line" /><span id={navTitle}> Analytics</span></NavLink></li>
                            <li><NavLink to={'/reports-page'}><FontAwesomeIcon icon="info-circle" /><span id={navTitle}> Reports</span></NavLink></li>
                            <li><NavLink to={'/master-file'}><FontAwesomeIcon icon="file" /><span id={navTitle}> PDC</span></NavLink></li>
                            <li><NavLink to={'/profiles'}><FontAwesomeIcon icon="user-alt" /><span id={navTitle}> Profiles</span></NavLink></li>
                            <li><NavLink to={'/settings'}><FontAwesomeIcon icon="sliders-h" /><span id={navTitle}> Settings</span></NavLink></li>
                        </ul>
                    </div>

                    <div className={"bodyContainer"}>
                        <div className={"bodyContainerTopBar"}>
                            <div className={"creditManagerBox"}>
                                { this.renderProfile() }
                                <div className={'iconContainer'}>
                                    <FontAwesomeIcon icon="check-circle" size="lg" color="green" />
                                </div>
                            </div>
                            <div className={"signOutButton"}>  
                                <button onClick={() => this.onLogOutPress()}>
                                    <IosLogOut fontSize="2rem" color="#d40000" />
                                </button>  
                            </div>
                        </div>
                        { this.renderSystem() }
                        <div>
                            <InitialSettings
                                visible={visible}
                            />
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {

    const { systemInfo } = state.app;
    const { profile } = state.profile;

    return {
        systemInfo,
        profile
    }
};

export default withRouter(connect(mapStateToProps,{ grabAnalytics, logOutUser, fetchTotalBook, fetchEcecutives, fetchAdmins, fetchExternalCollectors, fetchProfile, checkChequeClearance, calculatePenalInterest, fetchSystemInfo,
    fetchBouncedCheques, fetchOverdueTerms, computeIncorrectLoans, fetchLoansWithSurplus, fetchSurplus, demouSe })(Main));
