import * as firebase from 'firebase';
import moment from 'moment';
import _ from "lodash";
import arraySort from "array-sort";
import {message} from "antd";

import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

//property declaration
const XLSX = require('xlsx');

const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

//ANALYSIS
export const exampleAnalysis = (systemInfo) => {
    return async () => {
        let successArray = [];

        try {
        const groupedDays = [{bucket: "1-30", ageing: 1}, {bucket: "31-60", ageing: 2}, {bucket: "61-90", ageing: 3}, {bucket: "91-120", ageing: 4},
        {bucket: "121-150", ageing: 5}, {bucket: "151-180", ageing: 6}, {bucket: "181-210", ageing: 7}, {bucket:  "211-240", ageing: 8}, {bucket: "241-270", ageing: 9},
        {bucket: "271-300", ageing: 10}, {bucket: "301-330", ageing: 11}, {bucket: "331-360", ageing: 12}, {bucket: ">360", ageing: 13}];
        
        let overDueTerms = [];
    
        const reportID = `masterlistNew_29_02_2024`;
    
        const data = JSON.stringify({ reportID, path: "masterListNew" });
    
        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                console.log('cheques', bouncedCheques);
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        overDueTerms.push(term);
                    });
                })
            })
            .catch(e => {
                console.log(e);
            })


        //check if there are overdue terms
        let dates = {};
        let reportArray = [];
        let totalCustomers;
        if (overDueTerms.length !== 0) {
            //we have overdue terms
            //loop all overdue terms
            overDueTerms.forEach(term => {
                //check if dueDateID already exists in dates object
                if (`${term.customerID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${term.customerID}`].terms;
                    terms.push(term);
                    dates[`${term.customerID}`].terms = terms;
                } else {
                    //its a new date so create new object for it
                    dates[`${term.customerID}`] = {
                        customerID: term.customerID,
                        terms: [term],
                    }
                }
            });


            //FIND GRAND CONTENTS


            //sum of customers
            let customerStore = [];
            overDueTerms.map(term => {
                customerStore.push(term);
            });

            let distinctCustomers = {};

            customerStore.map(term => {
                if(`${term.customerID}` in distinctCustomers) {
                    //customer already in customers object
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        //grab terms from customer object
                        let terms = distinctCustomers[`${term.customerID}`].terms;
                        terms.push(term);

                        distinctCustomers[`${term.customerID}`].terms = terms;
                    }
                } else {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        distinctCustomers[`${term.customerID}`] = {
                            customerID: term.customerID,
                            customerName: term.customerName,
                            terms: [term]
                        }
                    }
                }
            });

            totalCustomers = Object.keys(distinctCustomers).length;

        }



        let groupedReport = {};

        groupedDays.forEach ((data) => {
            //
            //check if bucketID already exists in reports object
            if (`${data.bucket}` in groupedReport) {
                //customer with this bucket already exist
            } else {
                //its a new date so create new object for it
                groupedReport[`${data.bucket}`] = {
                    bucket: data.bucket,
                    customers: [],
                    ageing: data.ageing,
                }
            }
        });

        //for examining each cheque contents
        if (!(_.isEmpty(dates))) {
            ///extract date into array so it can be sorted
            const datesArray = _.map(dates, date => date);
            const sortedDates = arraySort(datesArray, "customerID");

            sortedDates.map((data) => {
                const customerID = data.customerID;
                const terms = data.terms;

                const res = getGroupedEarliestBucket({ customerID, terms});
                successArray.push(res);

            })

            //
            Promise.all(successArray).then((customerData) => {

                // here is our array of dashboard data
                if(customerData.length !== 0){
                    //
                    customerData.map(customer => {
                        //check if bucketID already exists in reports object
                        if (`${customer.bucket}` in groupedReport) {
                            //customer with this bucket already exist
                            let customers = groupedReport[`${customer.bucket}`].customers;
                            customers.push(customer);
                            groupedReport[`${customer.bucket}`].customers = customers;
                        } else {
                            //its a new date so create new object for it
                            groupedReport[`${customer.bucket}`] = {
                                bucket: customer.bucket,
                                customers: [customer],
                            }
                        }
                    })

                    if (!(_.isEmpty(groupedReport))) {
                        ///extract groupedReport into array so it can be sorted
                        const bucketArray = _.map(groupedReport, report => report);
                        const sortedBuckets = bucketArray.sort(function(a, b){return a.ageing - b.ageing});

                        sortedBuckets.map((data) => {
                            const bucket = data.bucket;
                            const customers = data.customers;

                            const res = getNumberOfCustomersOnGroupedBucket1({ bucket, customers });
                            reportArray.push(res);

                        })


                        Promise.all(reportArray).then((dashboardData) => {

                            // here is our array of dashboard data
                            if(dashboardData.length !== 0){
                                dashboardData.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers  });
                            }

                            callBucketWithOverduePhase2({groupedReportPhase1: dashboardData, response: overDueTerms, systemInfo});
                        })
                    }

                }
            })
        }

        } catch (e) {
            console.log('failed to get overdue on all terms');
            console.log(e);
        }

        return Promise.all(successArray).then(() => {
            return console.log("Provision report saved successfully");
        });
    }
}

async function getGroupedEarliestBucket({customerID, terms}) {

    const dueDateArray = terms.map((term) => {
        let seconds;

        if ("transactionDate" in term){
            //
            term.transactionDate.seconds? (seconds = term.transactionDate.seconds): (seconds = term.transactionDate._seconds);
        } else {
            //
            term.dueDate.seconds? (seconds = term.dueDate.seconds): (seconds = term.dueDate._seconds);
        }

        return moment.unix(seconds);
    })

    const earliestMoment = _.min(dueDateArray);
    const earliestDate = earliestMoment.toDate();

    const today = moment();
    //todat is 01/02/2024
    let futureDate = today.add(30, 'days');
    futureDate.set({ year: 2024, month: 1, date: 29 });

    //find the number of days from today
    const days = futureDate.diff(earliestDate, 'days');
    // const ageing = Math.round(days/30);
    // console.log({days, customerName, customerID});

    return getCustomerGroupedBucket({ customerID, terms, days });
}

async function getCustomerGroupedBucket({ customerID, terms, days }){

    let bucket = "";

    //provision starts with 1-30
    switch (true) {
        case (days >= 1 && days <= 30):
            bucket = "1-30";
            break;
        case (days >= 31 && days <= 60):
            bucket = "31-60";
            break;
        case (days >= 61 && days <= 90):
            bucket = "61-90";
            break;
        case (days >= 91 && days <= 120):
            bucket = "91-120";
            break;
        case (days >= 121 && days <= 150):
            bucket = "121-150";
            break;
        case (days >= 151 && days <= 180):
            bucket = "151-180";
            break;
        case (days >= 181 && days <= 210):
            bucket = "181-210";
            break;
        case (days >= 211 && days <= 240):
            bucket = "211-240";
            break;
        case (days >= 241 && days <= 270):
            bucket = "241-270";
            break;
        case (days >= 271 && days <= 300):
            bucket = "271-300";
            break;
        case (days >= 301 && days <= 330):
            bucket = "301-330";
            break;
        case (days >= 331 && days <= 360):
            bucket = "331-360";
            break;
        case (days > 360):
            bucket = ">360";
            break;

    }

    return {customerID, terms, days, bucket}

}

async function getNumberOfCustomersOnGroupedBucket1({ bucket, customers }){

    const numberOfCustomers = customers.length;

    return {numberOfCustomers, groupedBucket: bucket};
}

async function callBucketWithOverduePhase2({groupedReportPhase1, response, systemInfo}){

    let successArray = [];

    if(response.length !== 0){
        let termsArray = [];
        let dates = {};

        response.forEach(term => {
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                termsArray.push(term);
            }
        });

        let sumOfODInUSD;


        //sum of OD in usd
        let usdTermStore = [];
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });

        sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);

        if (termsArray.length !== 0){
            termsArray.map(term => {
                //grab the date and change it into a string
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
                const dueDate = moment.unix(seconds);
                const today = moment();
                //todat is 01/02/2024
                let futureDate = today.add(30, 'days');
                futureDate.set({ year: 2024, month: 1, date: 29 });
                //grab the month and year
                const dueDateID = futureDate.format("MMYYYY");

                //check if dueDateID already exists in dates object

                //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
                if (`${dueDateID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${dueDateID}`].terms;

                    //find the number of days from today
                    const fromNow = futureDate.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    //check if bucket already exists
                    //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                    if (`${bucket}` in terms) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = terms[`${bucket}`];

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        terms[`${bucket}`] = termBucket;

                        //assign the new objects as new values of the the existing terms object in the date object
                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        terms[`${bucket}`] = [term];
                        dates[`${dueDateID}`].terms = terms;
                    }


                    //dates[`${dueDateID}`].terms = terms;
                } else {

                    //find the number of days from today
                    const fromNow = futureDate.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    let terms = {};
                    terms[`${bucket}`] = [term];

                    dates[`${dueDateID}`] = {
                        date: dueDate,
                        terms,
                        termsArray
                    }
                }
            });
        }

        //sort the dates array
        const datesArray = _.map(dates, date => date);
        const sortedDates = arraySort(datesArray, "date");

        if(sortedDates.length !== 0){
            sortedDates.forEach(date => {
                _.map(date.terms, (terms, key) => {
                    // console.log({ageing: key, terms});
                    const response = findBucketOD({ageing: key, terms, systemInfo});
                    successArray.push(response);
                });
            })
        }

        //
        Promise.all(successArray).then((dashboardData) => {
            //here is our array of dashboard data
            dashboardData.push({ groupedBucket: "Grand Total", totalOverdue: sumOfODInUSD });

            callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2: dashboardData, response, systemInfo});
        })
    }
}

async function findBucketOD({ageing, terms, systemInfo}) {

    // const dates  = {};
    let amount = 0;
    let overdue = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];

    terms.forEach(term => {

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    overdue = roundAccurately(sumOfODInUSD, 2);

    let bucket = "";

    switch (ageing) {
        case "1":
            bucket = "1-30";
            break;
        case "2":
            bucket = "31-60";
            break;
        case "3":
            bucket = "61-90";
            break;
        case "4":
            bucket = "91-120";
            break;
        case "5":
            bucket = "121-150";
            break;
        case "6":
            bucket = "151-180";
            break;
        case "7":
            bucket = "181-210";
            break;
        case "8":
            bucket = "211-240";
            break;
        case "9":
            bucket = "241-270";
            break;
        case "10":
            bucket = "271-300";
            break;
        case "11":
            bucket = "301-330";
            break;
        case "12":
            bucket = "331-360";
            break;
        case "13":
            bucket = ">360";
            break;
    }

    return {ageing, groupedBucket: bucket, totalOverdue: overdue};
}

async function callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2, response, systemInfo}) {
    //
    let successArray = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    try {
        //
        let dates = {};
        let buckets = {};

        response.forEach(term => {
            let seconds;
            if ("transactionDate" in term) {
                if (term.transactionDate) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }

            const dueDate = moment.unix(seconds);

            //put term in current month bucket
            //grab the month and year
            const dueDateID = dueDate.format("MMYYYY");

            //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
            if (`${dueDateID}` in dates) {
                //terms with this date already exist
                let terms = dates[`${dueDateID}`].terms;

                //calculate bucket
                const endOfMonth = dueDate.endOf('month');

                //grab end of month of next month
                const today = moment();
                //todat is 01/02/2024
                let futureDate = today.add(30, 'days');
                futureDate.set({ year: 2024, month: 1, date: 29 });
                const nextMonth = futureDate.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');

                //find the number of days from today
                const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

                //find the number of days from today
                // const fromNow = today.diff(dueDate, 'days');

                //find the bucket
                let bucket = 1;
                switch (true) {
                    case (fromNow >= 1 && fromNow <= 30):
                        bucket = 1;
                        break;
                    case (fromNow >= 31 && fromNow <= 60):
                        bucket = 2;
                        break;
                    case (fromNow >= 61 && fromNow <= 90):
                        bucket = 3;
                        break;
                    case (fromNow >= 91 && fromNow <= 120):
                        bucket = 4;
                        break;
                    case (fromNow >= 121 && fromNow <= 150):
                        bucket = 5;
                        break;
                    case (fromNow >= 151 && fromNow <= 180):
                        bucket = 6;
                        break;
                    case (fromNow >= 181 && fromNow <= 210):
                        bucket = 7;
                        break;
                    case (fromNow >= 211 && fromNow <= 240):
                        bucket = 8;
                        break;
                    case (fromNow >= 241 && fromNow <= 270):
                        bucket = 9;
                        break;
                    case (fromNow >= 271 && fromNow <= 300):
                        bucket = 10;
                        break;
                    case (fromNow >= 301 && fromNow <= 330):
                        bucket = 11;
                        break;
                    case (fromNow >= 331 && fromNow <= 360):
                        bucket = 12;
                        break;
                    case (fromNow > 360):
                        bucket = 13;
                        break;
                }

                //check if bucket already exists
                //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                if (`${bucket}` in buckets) {
                    //grab the bucket from terms object and assign to new array (new array will have existing terms)
                    let termBucket = buckets[`${bucket}`].terms;

                    //push new term to new bucket array
                    termBucket.push(term);

                    //assign the bucket property in the original terms object with the new created termBucket
                    buckets[`${bucket}`].terms = termBucket;
                } else {
                    buckets[`${bucket}`] = {
                        bucket: bucket,
                        terms: [term]
                    };
                }


            } else {
                //its a new date so create new object for it
                //calculate bucket
                //grab the end of the month
                //const endOfMonth = dueDate.endOf('month');

                //calculate bucket
                const endOfMonth = dueDate.endOf('month');

                //grab end of month of next month
                const today = moment();
                //todat is 01/02/2024
                let futureDate = today.add(30, 'days');
                futureDate.set({ year: 2024, month: 1, date: 29 });
                const nextMonth = futureDate.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');

                //find the number of days from today
                const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

                //find the bucket
                let bucket = 1;
                switch (true) {
                    case (fromNow >= 1 && fromNow <= 30):
                        bucket = 1;
                        break;
                    case (fromNow >= 31 && fromNow <= 60):
                        bucket = 2;
                        break;
                    case (fromNow >= 61 && fromNow <= 90):
                        bucket = 3;
                        break;
                    case (fromNow >= 91 && fromNow <= 120):
                        bucket = 4;
                        break;
                    case (fromNow >= 121 && fromNow <= 150):
                        bucket = 5;
                        break;
                    case (fromNow >= 151 && fromNow <= 180):
                        bucket = 6;
                        break;
                    case (fromNow >= 181 && fromNow <= 210):
                        bucket = 7;
                        break;
                    case (fromNow >= 211 && fromNow <= 240):
                        bucket = 8;
                        break;
                    case (fromNow >= 241 && fromNow <= 270):
                        bucket = 9;
                        break;
                    case (fromNow >= 271 && fromNow <= 300):
                        bucket = 10;
                        break;
                    case (fromNow >= 301 && fromNow <= 330):
                        bucket = 11;
                        break;
                    case (fromNow >= 331 && fromNow <= 360):
                        bucket = 12;
                        break;
                    case (fromNow > 360):
                        bucket = 13;
                        break;
                }

                let terms = {};
                terms[`${bucket}`] = [term];

                dates[`${dueDateID}`] = {
                    date: dueDate,
                    terms,
                }

                if (`${bucket}` in buckets) {
                    //grab the bucket from terms object and assign to new array (new array will have existing terms)
                    let termBucket = buckets[`${bucket}`].terms;

                    //push new term to new bucket array
                    termBucket.push(term);

                    //assign the bucket property in the original terms object with the new created termBucket
                    buckets[`${bucket}`].terms = termBucket;
                } else {
                    buckets[`${bucket}`] = {
                        bucket: bucket,
                        terms: [term]
                    };
                }
            }
        })

        //FIND GRAND CONTENTS
        let totalCustomers;
        let totalODArr = [];

        //sum of customers
        if(response.length !== 0){
            let customerStore = [];
            let amount = 0;
            response.map(term => {
                //
                customerStore.push(term);

                if (term.currency === "usd"){
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    totalODArr.push(amount);

                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;

                    totalODArr.push(amount);
                }

            });

            let distinctCustomers = {};

            customerStore.map(term => {
                if(`${term.customerID}` in distinctCustomers) {
                    //customer already in customers object
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        //grab terms from customer object
                        let terms = distinctCustomers[`${term.customerID}`].terms;
                        terms.push(term);

                        distinctCustomers[`${term.customerID}`].terms = terms;
                    }
                } else {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        distinctCustomers[`${term.customerID}`] = {
                            customerID: term.customerID,
                            customerName: term.customerName,
                            terms: [term]
                        }
                    }
                }
            });

            totalCustomers = Object.keys(distinctCustomers).length;
        }


        let debtTotal = totalODArr.reduce((a, b) => a + b, 0);
        debtTotal = roundAccurately(debtTotal, 2);

        //for examining each cheque contents
        if (!(_.isEmpty(buckets))) {

            //sort the dates array
            const datesArray = _.map(buckets, date => date);
            const sortedDates = arraySort(datesArray, "bucket");

            if(sortedDates.length !== 0){
                sortedDates.forEach(date => {
                    const response = getGroupedBucket({ageing: date.bucket, terms: date.terms, systemInfo});
                    successArray.push(response);
                })
            }

            Promise.all(successArray).then((dashboardData) => {
                let value = dashboardData.filter(function (el) { return el != null; });
                // here is our array of dashboard data
                value.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers, debtTotal});

                console.log('analysis', { value, groupedReportPhase1, groupedReportPhase2 })
                //store the dashboard data array as provision report
                //create file and send it storage
                // saveJSONToStorage({ clients: {value, groupedReportPhase1, groupedReportPhase2} });
            })
        }
    } catch (e) {
        console.log(e);
    }
}

async function getGroupedBucket({ ageing, terms, systemInfo }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    // const dates  = {};
    let amount = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];
    let customerBucket = {};

    terms.forEach(term => {

        const customerID = term.customerID;

        if(`${term.customerID}` in customerBucket) {
            //grab terms from customer object
            let terms = customerBucket[`${customerID}`].terms;
            terms.push(term);

            customerBucket[`${customerID}`].terms = terms;
        } else {
            //check that term is not cleared
            customerBucket[`${customerID}`] = {
                customerID: customerID,
                terms: [term]
            }
        }

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    //find bucket
    let bucket = "";

    switch (ageing) {
        case 1:
            bucket = "1-30";
            break;
        case 2:
            bucket = "31-60";
            break;
        case 3:
            bucket = "61-90";
            break;
        case 4:
            bucket = "91-120";
            break;
        case 5:
            bucket = "121-150";
            break;
        case 6:
            bucket = "151-180";
            break;
        case 7:
            bucket = "181-210";
            break;
        case 8:
            bucket = "211-240";
            break;
        case 9:
            bucket = "241-270";
            break;
        case 10:
            bucket = "271-300";
            break;
        case 11:
            bucket = "301-330";
            break;
        case 12:
            bucket = "331-360";
            break;
        case 13:
            bucket = ">360";
            break;
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const debtTotal = roundAccurately(sumOfODInUSD, 2);

    const numberOfCustomers = Object.keys(customerBucket).length;

    return { numberOfCustomers, debtTotal, groupedBucket: bucket, terms};

}



//DB SEGMENT
export const exampleDB = (systemInfo) => {
    return async () => {
        let successArray = [];
        try {

        let overDueTerms = [];
    
        const reportID = `masterlistNew_29_02_2024`;
    
        const data = JSON.stringify({ reportID, path: "masterListNew" });
    
        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                console.log('cheques', bouncedCheques);
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        overDueTerms.push(term);
                    });
                })
            })
            .catch(e => {
                console.log(e);
            })

            const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("segment");
            const info = await infoRef.get();

            const exchangeRate = systemInfo.exchangeRate;

            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);


            //check if there are overdue terms
            // let statuses = {};
            if (overDueTerms.length !== 0) {
                //we have overdue terms
                //loop all overdue terms
                let dates = {};

                overDueTerms.forEach(term => {

                    let seconds;
                    if ("transactionDate" in term) {
                        if (term.transactionDate) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                        }
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }

                    const dueDate = moment.unix(seconds);
                    const endOfMonth = dueDate.endOf('month').format("DD-MM-YYYY");

                    //put term in current month bucket
                    //grab the month and year
                    const dueDateID = dueDate.format("MMYYYY");
                    const year = dueDate.format("YYYY");
                    // console.log(dueDateID);

                    //if loan is in usd convert the overdue to default currency
                    let termAmount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            termAmount = term.amount - term.modulo;
                        } else {
                            termAmount = term.amount;
                        }

                    } else {

                        //grab the total overdue
                        if ("modulo" in term) {
                            termAmount = term.amount - term.modulo;
                        } else {
                            termAmount = term.amount;
                        }

                        termAmount = termAmount/exchangeRate;
                        termAmount = roundAccurately(termAmount, 2);
                    }

                    //check if dueDateID already exists in dates object
                    if (`${dueDateID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${dueDateID}`].terms;
                        terms.push(term);

                        let overdueTerms = dates[`${dueDateID}`].overdueTerms;
                        overdueTerms.push(termAmount);

                        dates[`${dueDateID}`].terms = terms;
                        dates[`${dueDateID}`].overdueTerms = overdueTerms;
                    } else {
                        //its a new date so create new object for it
                        dates[`${dueDateID}`] = {
                            dateID: dueDateID,
                            date: endOfMonth,
                            terms: [term],
                            termsStore: overDueTerms,
                            year,
                            overdueTerms: [termAmount]
                        }
                    }
                })


                //FIND GRAND CONTENTS
                let totalCustomers;
                let sumOfODInUSD;

                //sum of OD in usd
                let usdTermStore = [];
                overDueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            usdTermStore.push(amount);
                        } else {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            const convertedAmount = amount / exchangeRate;

                            usdTermStore.push(convertedAmount);
                        }
                    }
                });

                sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);


                //sum of customers
                let distinctCustomers = {};

                overDueTerms.map(term => {
                    if(`${term.customerID}` in distinctCustomers) {
                        //customer already in customers object
                        //check that term is not cleared
                        if (term.termStatus.status) {
                            //term is cleared do nothing
                        } else {
                            //grab terms from customer object
                            let terms = distinctCustomers[`${term.customerID}`].terms;
                            terms.push(term);

                            distinctCustomers[`${term.customerID}`].terms = terms;
                        }
                    } else {
                        //check that term is not cleared
                        if (term.termStatus.status) {
                            //term is cleared do nothing
                        } else {
                            distinctCustomers[`${term.customerID}`] = {
                                customerID: term.customerID,
                                customerName: term.customerName,
                                terms: [term]
                            }
                        }
                    }
                });

                totalCustomers = Object.keys(distinctCustomers).length;


                //sum of OD in usd for blank segment
                let usdTermStoreBlank = [];
                overDueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        if("segment" in term) {
                            //
                            if(term.segment === ""){
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    amount = roundAccurately(amount, 2);
                                    usdTermStoreBlank.push(amount);
                                } else {

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    let convertedAmount = amount/exchangeRate;
                                    convertedAmount = roundAccurately(convertedAmount, 2);

                                    usdTermStoreBlank.push(convertedAmount);
                                }
                            }
                        } else {
                            //if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }

                                amount = roundAccurately(amount, 2);
                                usdTermStoreBlank.push(amount);
                            } else {

                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }

                                let convertedAmount = amount/exchangeRate;
                                convertedAmount = roundAccurately(convertedAmount, 2);

                                usdTermStoreBlank.push(convertedAmount);
                            }
                        }
                    }
                });

                //total customers with blank segment
                let blankSegment = [];
                overDueTerms.map(term => {
                    //sort terms based on segment
                    if("segment" in term) {
                        //
                        if(term.segment === ""){
                            blankSegment.push(term);
                        }
                    } else {
                        blankSegment.push(term);
                    }
                });


                //sum of OD in usd for tml segment
                let usdTermStoreTml = [];
                overDueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //sort terms based on segment
                        if("segment" in term) {
                            //
                            if (term.segment === "TML"){
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStoreTml.push(amount);
                                } else {

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    let convertedAmount = amount/exchangeRate;
                                    convertedAmount = roundAccurately(convertedAmount, 2);

                                    usdTermStoreTml.push(convertedAmount);
                                }
                            }
                        }
                    }
                });

                //total customers with tml segment
                let tmlSegment = [];
                overDueTerms.map(term => {
                    //sort terms based on segment
                    if("segment" in term) {
                        //
                        if (term.segment === "TML"){
                            tmlSegment.push(term);
                        }
                    }
                });


                //sum of OD in usd for daewoo segment
                let usdTermStoreDaewoo = [];
                overDueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        if("segment" in term) {
                            //
                            if (term.segment === "DAEWOO"){
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStoreDaewoo.push(amount);
                                } else {

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    let convertedAmount = amount/exchangeRate;
                                    convertedAmount = roundAccurately(convertedAmount, 2);

                                    usdTermStoreDaewoo.push(convertedAmount);
                                }
                            }
                        }
                    }
                });


                //total customers with daewoo segment
                let daewooSegment = [];
                overDueTerms.map(term => {
                    //sort terms based on segment
                    if("segment" in term) {
                        //
                        if (term.segment === "DAEWOO"){
                            daewooSegment.push(term);
                        }
                    }
                });


                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    // const sortedDates = arraySort(datesArray, "dateID");
                    const sortedDates = datesArray.sort(function(a, b){return a.dateID - b.dateID});
                    const sortedArray = sortedDates.sort(function(a, b){return a.year - b.year});

                    sortedArray.map(data => {
                        const date = data.date;
                        const terms = data.terms;
                        const overdueTerms = data.overdueTerms;

                        // console.log({ID: data.dateID, terms: terms, date: date});

                        const res = getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((dashboardData) => {

                        //here is our array of dashboard data
                        let dbSegmentArray = [];
                        if(dashboardData.length !== 0){
                            dashboardData.push({ date: "Grand Total", segments: {Blank: {terms: blankSegment, totalOverdue: usdTermStoreBlank},
                                    DAEWOO: {terms: daewooSegment, totalOverdue: usdTermStoreDaewoo}, TML: {terms: tmlSegment, totalOverdue: usdTermStoreTml}},
                                customers: totalCustomers, totalOD: sumOfODInUSD});

                            dashboardData.map(cheque => {

                                let blankNumber = {};
                                let daewooNumber = {};
                                let tmlNumber = {};

                                const segmentData = cheque.segments;
                                const blank = segmentData.Blank;
                                const daewoo = segmentData.DAEWOO;
                                const tml = segmentData.TML;

                                if(blank.terms.length !== 0){
                                    blank.terms.forEach((term) => {
                                        const customerID = term.customerID;

                                        if (`${customerID}` in blankNumber) {
                                            //terms with this segment already exist
                                            let terms = blankNumber[`${customerID}`].terms;
                                            terms.push(term);

                                            blankNumber[`${customerID}`].terms = terms;
                                        } else {
                                            blankNumber[`${customerID}`] = {
                                                customerID: customerID,
                                                terms: [term],
                                            }
                                        }
                                    });
                                }

                                if (daewoo.terms.length !== 0){
                                    daewoo.terms.forEach((term) => {
                                        const customerID = term.customerID;

                                        if (`${customerID}` in daewooNumber) {
                                            //terms with this segment already exist
                                            let terms = daewooNumber[`${customerID}`].terms;
                                            terms.push(term);

                                            daewooNumber[`${customerID}`].terms = terms;
                                        } else {
                                            daewooNumber[`${customerID}`] = {
                                                customerID: customerID,
                                                terms: [term],
                                            }
                                        }
                                    });
                                }

                                if (tml.terms.length !== 0){
                                    tml.terms.forEach((term) => {
                                        const customerID = term.customerID;

                                        if (`${customerID}` in tmlNumber) {
                                            //terms with this segment already exist
                                            let terms = tmlNumber[`${customerID}`].terms;
                                            terms.push(term);

                                            tmlNumber[`${customerID}`].terms = terms;
                                        } else {
                                            tmlNumber[`${customerID}`] = {
                                                customerID: customerID,
                                                terms: [term],
                                            }
                                        }
                                    });
                                }

                                const blankOverdue = blank.totalOverdue.reduce((a, b) => a + b, 0);
                                const daewooOverdue = daewoo.totalOverdue.reduce((a, b) => a + b, 0);
                                const tmlOverdue = tml.totalOverdue.reduce((a, b) => a + b, 0);

                                const blankCustomers = Object.keys(blankNumber).length;
                                const daewooCustomers = Object.keys(daewooNumber).length;
                                const tmlCustomers = Object.keys(tmlNumber).length;

                                dbSegmentArray.push({eqm: cheque.date, numberOfCustomersBlank: blankCustomers, blankODInUSD: blankOverdue, numberOfCustomersDaewoo: daewooCustomers,
                                    daewooODInUSD: daewooOverdue, numberOfCustomersTML: tmlCustomers, tmlODInUSD: tmlOverdue, distinctCountOfCustomers: cheque.customers,
                                    totalSumOfODInUSD: cheque.totalOD
                                });
                            });
                        }

                        dbIndustryReport({exchangeRate, response: overDueTerms, dbSegmentArray});
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }

        return Promise.all(successArray).then(() => {
            return console.log("Db segment report saved successfully");
        });
    }
}


async function getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate }){

    const segmentCustomers = {};

    terms.forEach(term => {

        const customerID = term.customerID;

        //check if customerID already exists in dates object
        if (`${customerID}` in segmentCustomers) {
            //terms with this segment already exist
            let terms = segmentCustomers[`${customerID}`].terms;
            terms.push(term);

            segmentCustomers[`${customerID}`].terms = terms;
        } else {
            segmentCustomers[`${customerID}`] = {
                terms: [term],
            }
        }
    })

    const customers = Object.keys(segmentCustomers).length;

    return getSegment({ date, terms, info, overdueTerms, customers, exchangeRate});

}


async function getSegment({ date, terms, info, overdueTerms, customers, exchangeRate }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    const buckets = {};
    //its a new industry so create new object for it
    buckets[`${"Blank"}`] = {
        segment: "Blank",
        terms: [],
        totalOverdue: [],
    }

    if (info.size !== 0){
        info.forEach(doc => {
            const data = doc.data();
            const value = data.value;

            //its a new industry so create new object for it
            buckets[`${value}`] = {
                segment: value,
                terms: [],
                totalOverdue: [],
            }
        })
    }

    terms.forEach(term => {

        let seconds;
        if ("transactionDate" in term) {
            if (term.transactionDate) {
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const endOfMonth = dueDate.format("DD-MM-YYYY");

        let amount = 0;
        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {

            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
            amount = roundAccurately(amount, 2);
        }

        let segmentID;
        if ("segment" in term) {
            //
            if(term.segment !== ""){
                segmentID = term.segment;
            } else {
                segmentID = "Blank";
            }
        } else {
            segmentID = "Blank";
        }

        //check if segmentID already exists in dates object
        if (`${segmentID}` in buckets) {
            //terms with this segment already exist
            let terms = buckets[`${segmentID}`].terms;
            terms.push(term);

            let overdue = buckets[`${segmentID}`].totalOverdue;
            overdue.push(amount);

            buckets[`${segmentID}`].terms = terms;
            buckets[`${segmentID}`].totalOverdue = overdue;
        } else {
            buckets[`${segmentID}`] = {
                totalOverdue: [amount],
                terms: [term],
            }
        }
    })

    const overdue = overdueTerms.reduce((a, b) => a + b, 0);
    const totalOD = roundAccurately(overdue, 2);

    if(!(_.isEmpty(buckets))) {
        // console.log({date: date, segments: buckets, customers: customers, OD: totalOD});

        return {date, segments: buckets, totalOD, customers};
    }

}



async function dbIndustryReport({ exchangeRate, response, dbSegmentArray}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let successArray = [];

    try {
        //
        const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("industry");
        const info = await infoRef.get();

        let industries = {};

        //put industry values into industries object
        if (info.size !== 0){
            info.forEach(doc => {
                const data = doc.data();
                const value = data.value;

                //its a new industry so create new object for it
                industries[`${value}`] = {
                    industry: value,
                    terms: [],
                    termsStore: response
                }
            })
        }

        response.forEach(term => {
            let industryID;
            if ("industry" in term) {
                industryID = term.industry;
            } else {
                industryID = "Blank";
            }

            //check if industryID already exists in dates object
            if (`${industryID}` in industries) {
                //terms with this industry already exist
                let terms = industries[`${industryID}`].terms;
                terms.push(term);

                industries[`${industryID}`].terms = terms;
            } else {
                //its a new industry so create new object for it
                industries[`${industryID}`] = {
                    industry: industryID,
                    terms: [term],
                    termsStore: response
                }
            }
        })

        //FIND GRAND CONTENTS
        let sumOfODInUSD;

        //sum of OD in usd
        let usdTermStore = [];
        if (response.length !== 0){
            response.map(term => {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        usdTermStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        usdTermStore.push(convertedAmount);
                    }
                }
            });
        }

        sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
        sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

        //for examining each cheque contents
        if (!(_.isEmpty(industries))) {
            ///extract date into array so it can be sorted
            const industryArray = _.map(industries, date => date);
            const sortedIndustries = arraySort(industryArray, "industry");

            sortedIndustries.map(data => {
                const industry = data.industry;
                const terms = data.terms;

                const res = getSumOfODWithIndustryInUSD({ exchangeRate, industry, terms });
                successArray.push(res);

            })

            Promise.all(successArray).then((dashboardData) => {

                // // here is our array of dashboard data
                dashboardData.push({ industry: "Grand Total", totalODInUsd: sumOfODInUSD });
                console.log({dbSegmentArray, dashboardData})
                // saveJSONToStorage({ clients: {dbSegmentArray, industryDataArray: dashboardData} });
            })
        }

    } catch (e) {
        console.log(e);
    }
}


async function getSumOfODWithIndustryInUSD({ exchangeRate, industry, terms }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, industry }
}


//StatusAnalysis
export const example = (systemInfo) => {
    return async () => {
        let successArray = [];
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        try {
            let overDueTerms = [];
            const exchangeRate = systemInfo.exchangeRate;
            const reportID = `masterlistNew_29_02_2024`;
        
            const data = JSON.stringify({ reportID, path: "masterListNew" });
        
            //invoke custom database function
            const url = `${project.serverUrl}downloadGeneratedReport`;
            await fetch(url, {
                method: 'POST',
                mode: 'cors',
                body: data,
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((bouncedCheques) => {
                    console.log('cheques', bouncedCheques);
                    //assign bouncedCheques to overdue terms
                    // console.log({bounced: bouncedCheques});
                    _.map(bouncedCheques, client => {
                        //
                        client.values.map(term => {
                            overDueTerms.push(term);
                        });
                    })
                })
                .catch(e => {
                    console.log(e);
                })


            //check if there are overdue terms
            let statuses = {};
            if (overDueTerms.length !== 0) {
                //we have overdue terms
                //loop all overdue terms
                overDueTerms.forEach(term => {
                    const customerID = term.customerID;
                    const customerName = term.customerName;

                    let amount = 0;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
                    } else {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        amount = amount / exchangeRate;
                    }


                    //check if dueDateID already exists in dates object
                    if (`${customerID}` in statuses) {
                        //terms with this date already exist
                        let terms = statuses[`${customerID}`].terms;
                        terms.push(amount);

                        statuses[`${customerID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        statuses[`${customerID}`] = {
                            customerID: customerID,
                            customerName: customerName,
                            terms: [amount],
                            termsStore: overDueTerms
                        }
                    }
                });


                //FIND GRAND CONTENTS
                let sumOfODInUSD;

                //sum of OD in usd
                let usdTermStore = [];
                overDueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            usdTermStore.push(amount);
                        } else {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            const convertedAmount = amount / exchangeRate;

                            usdTermStore.push(convertedAmount);
                        }
                    }
                });

                sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);


                //for examining each cheque contents
                if (!(_.isEmpty(statuses))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(statuses, date => date);
                    const sortedDates = arraySort(datesArray, "customerName");

                    sortedDates.map(data => {
                        const customerName = data.customerName;
                        const customerID = data.customerID;
                        const terms = data.terms;

                        const res = grabOverdueAndCustomerNameInUSD({ customerName, terms, customerID });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((dashboardData) => {
                        //
                        dashboardData.push({ customerName: "Grand Total", totalODInUsd: sumOfODInUSD });

                        createStatusAnalysisReport({resultArray: dashboardData, exchangeRate, response: overDueTerms});
                    })
                }
            }

        } catch (e) {
            console.log(e);
        }

        return Promise.all(successArray).then(() => {
            return console.log("Provision report saved successfully");
        });
    }
}


async function grabOverdueAndCustomerNameInUSD({customerName, terms, customerID}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let totalODInUsd = terms.reduce((a, b) => a + b, 0);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return {customerName, customerID, totalODInUsd};
}


async function createStatusAnalysisReport({resultArray, response, exchangeRate}){

    let successArray = [];

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let statuses = {};

    response.forEach(term => {
        let statusID;
        if ("legalRepoStatus" in term) {
            statusID = term.legalRepoStatus;
        } else {
            statusID = "Blank";
        }

        //check if dueDateID already exists in dates object
        if (`${statusID}` in statuses) {
            //terms with this date already exist
            let terms = statuses[`${statusID}`].terms;
            terms.push(term);

            statuses[`${statusID}`].terms = terms;
        } else {
            //its a new date so create new object for it
            statuses[`${statusID}`] = {
                status: statusID,
                terms: [term],
                termsStore: response
            }
        }
    })

    //FIND GRAND CONTENTS
    let totalCustomers;
    let sumOfODInUSD;

    //sum of OD in usd
    let usdTermStore = [];
    if (response.length !== 0){
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);


    //sum of customers
    if(response.length !== 0){
        let customerStore = [];
        response.map(term => {
            customerStore.push(term);
        });

        let distinctCustomers = {};

        customerStore.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        totalCustomers = Object.keys(distinctCustomers).length;
    }

    //for examining each cheque contents
    if (!(_.isEmpty(statuses))) {
        ///extract date into array so it can be sorted
        const datesArray = _.map(statuses, date => date);
        const sortedDates = arraySort(datesArray, "status");

        sortedDates.map(data => {
            const status = data.status;
            const terms = data.terms;
            const termsStore = data.termsStore;

            const res = getNumberOfCustomersWithStatus({ status, terms, termsStore, exchangeRate });
            successArray.push(res);

        })

        Promise.all(successArray).then((dashboardData) => {
            //here is our array of dashboard data
            dashboardData.push({ status: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });
            createCharacterAnalysisReport({statusAnalysis: dashboardData, resultArray, response, exchangeRate});
        })
    }

    
}


async function getNumberOfCustomersWithStatus({ status, terms, termsStore, exchangeRate }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getValueWithStatus({ numberOfCustomers, status, terms, termsStore, exchangeRate});
}



async function getValueWithStatus({ numberOfCustomers, status, terms, termsStore, exchangeRate }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const customerValue = Object.keys(distinctCustomers).length;

    let allDistinctCustomers = {};
    //find total of terms within for all overdue loans
    termsStore.map(term => {
        //
        if(`${term.customerID}` in allDistinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = allDistinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                allDistinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                allDistinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    //total value of distinct number of customers
    const totalValue = Object.keys(allDistinctCustomers).length;

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const numberPercent = (customerValue/totalValue) * 100;
    let countPercent = roundAccurately(numberPercent, 1);

    return getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent, exchangeRate });

}


async function getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent, exchangeRate }){

    let store = [];
    let totalStore = [];

    //find total of terms within this month
    terms.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const total = store.reduce((a, b) => a + b, 0);

    //find total of terms within for all overdue loans
    termsStore.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                totalStore.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                totalStore.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const allTotal = totalStore.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const ODPercent = (total/allTotal) * 100;
    let valuePercent = roundAccurately(ODPercent, 1);

    return getSumOfODInUSD({ valuePercent, exchangeRate, numberOfCustomers, status, terms, countPercent  });
}


async function getSumOfODInUSD({ valuePercent, exchangeRate, numberOfCustomers, status, terms, countPercent }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, status };
}



async function createCharacterAnalysisReport({statusAnalysis, resultArray, response, exchangeRate}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let successArray = [];

    let characters = {};

    response.forEach(term => {
        let characterID;
        if ("character" in term) {
            if(term.character) {
                characterID = term.character;
            } else {
                characterID = "Blank"
            }
        } else {
            characterID = "Blank";
        }

        //check if characterID already exists in dates object
        if (`${characterID}` in characters) {
            //terms with this date already exist
            let terms = characters[`${characterID}`].terms;
            terms.push(term);

            characters[`${characterID}`].terms = terms;
        } else {
            //its a new date so create new object for it
            characters[`${characterID}`] = {
                character: characterID,
                terms: [term],
                termsStore: response
            }
        }
    })

    //FIND GRAND CONTENTS
    let totalCustomers;
    let sumOfODInUSD;

    //sum of OD in usd
    let usdTermStore = [];
    if (response.length !== 0){
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

    //sum of customers
    if(response.length !== 0){
        let customerStore = [];
        response.map(term => {
            customerStore.push(term);
        });

        let distinctCustomers = {};

        customerStore.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        totalCustomers = Object.keys(distinctCustomers).length;
    }


    //for examining each cheque contents
    if (!(_.isEmpty(characters))) {
        ///extract date into array so it can be sorted
        const datesArray = _.map(characters, date => date);
        const sortedDates = arraySort(datesArray, "character");

        sortedDates.map(data => {
            const character = data.character;
            const terms = data.terms;
            const termsStore = data.termsStore;

            const res = getNumberOfCustomersWithCharacter({ character, terms, termsStore, exchangeRate });
            successArray.push(res);

        })

        Promise.all(successArray).then((dashboardData) => {

            // here is our array of dashboard data
            dashboardData.push({ character: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });
            console.log({dashboardData, statusAnalysis, resultArray});
            saveJSONToStorage({ clients: {characterAnalysis: dashboardData, statusAnalysis, resultArray} });
        })
    }

}



async function getNumberOfCustomersWithCharacter({ character, terms, termsStore, exchangeRate }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getValueWithCharacter({ numberOfCustomers, character, terms, termsStore, exchangeRate});
}

async function getValueWithCharacter({ numberOfCustomers, character, terms, termsStore, exchangeRate }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const customerValue = Object.keys(distinctCustomers).length;

    let allDistinctCustomers = {};
    //find total of terms within for all overdue loans
    termsStore.map(term => {
        //
        if(`${term.customerID}` in allDistinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = allDistinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                allDistinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                allDistinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    //total value of distinct number of customers
    const totalValue = Object.keys(allDistinctCustomers).length;

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const numberPercent = (customerValue/totalValue) * 100;
    let countPercent = roundAccurately(numberPercent, 1);

    return getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent, exchangeRate });


}

async function getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent, exchangeRate }){

    let store = [];
    let totalStore = [];

    //find total of terms within this month
    terms.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const total = store.reduce((a, b) => a + b, 0);

    //find total of terms within for all overdue loans
    termsStore.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                totalStore.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                totalStore.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const allTotal = totalStore.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const ODPercent = (total/allTotal) * 100;
    let valuePercent = roundAccurately(ODPercent, 1);

    return getSumOfCharactersWithODInUSD({ valuePercent, exchangeRate, numberOfCustomers, character, terms, countPercent });
}

async function getSumOfCharactersWithODInUSD({ valuePercent, exchangeRate, numberOfCustomers, character, terms, countPercent }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, character };
}
// //save file report
async function saveJSONToStorage({ clients }) {

    const data = JSON.stringify({
        clients
    });

    const url = `${project.serverUrl}analysisOftheDate`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log(response);
        }).catch((error) => {
        console.log(error);
    })
}