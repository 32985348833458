import {
    CASH_COLLECTION_UPDATE_DETAILS_CHANGED,
    CLEAR_SAVED_COLLECTION_MESSAGE,
    COLLECTION_CURRENCY_CHANGED,
    CUSTOMER_BANK_DATE_CHANGED,
    CUSTOMER_COLLECTION_DETAILS_CHANGED,
    CUSTOMER_LOAN_LIST_CHANGED,
    CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
    CUSTOMER_LOAN_TERM_CHANGED,
    DELETE_CASH_COLLECTION,
    DELETE_CASH_COLLECTION_FAILED,
    DELETE_CASH_COLLECTION_SUCCESSFUL,
    FETCH_CASH_COLLECTIONS,
    FETCH_CASH_COLLECTIONS_SUCCESSFUL,
    FETCH_COLLECTION_LOAN_TERMS,
    FETCH_COLLECTION_LOAN_TERMS_SUCCESSFUL,
    FETCH_COLLECTION_LOANS,
    FETCH_COLLECTION_LOANS_SUCCESSFUL,
    FETCH_CUSTOMER_DETAILS,
    FETCH_CUSTOMER_DETAILS_SUCCESSFUL,
    PUSHED_CURRENT_LOAN,
    SAVE_CASH_COLLECTION,
    SAVE_CASH_COLLECTION_FAILED,
    SAVE_CASH_COLLECTION_SUCCESSFUL,
    SEARCH_CUSTOMER,
    SEARCH_CUSTOMER_FAILED,
    SEARCH_CUSTOMER_NO_RECORDS,
    SEARCH_CUSTOMER_SUCCESSFUL,
    SHOW_CASH_COLLECTION_MESSAGE,
    UPDATE_CASH_COLLECTION,
    UPDATE_CASH_COLLECTION_BANK_DATE_CHANGED,
    UPDATE_CASH_COLLECTION_SUCCESSFUL,
} from "../actions/Types";


const INITIAL_STATE = {

    customerSearch: '',
    fetchedCustomer: {},
    noRecord: false,

    customerDetails: {},
    fetchedCustomerLoans: {},
    currentLoanTerms: {},
    currentLoan:{},
    cashCollections: {},
    loanTerm: {},
    selectedLoan: '',
    customerLoanTerm: '',

    //update cash collection
    bankName: '',
    bankDate: '',
    paidAmount: '',
    currency: false,
    comment: '',


    loading: false,
    fetching: false,
    customerFetched: false,
    loanFetched: false,
    visible: false,
    loanSaved: false,

    updateCollectionLoading: false,
    searchCustomerLoading: false,
    fetchLoanTermsLoading: false,
    cashCollectionSavedSuccessful: false,
    cashCollectionSavedFailed: false,

    fetchCashCollectionsLoading: false,

    updateCashCollectionLoading: false,
    deleteCashCollectionLoading: false,
    cashCollectionDeletedSuccessful: false,
    successfullyUpdated: false,

};

const CollectionsReducer = ( state = INITIAL_STATE, action) => {
    switch(action.type) {

        //search existing customer reducers
        case CUSTOMER_COLLECTION_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, cashCollectionSavedSuccessful: false, customerFetched: false,
                loanFetched: false, customerDetails: {}, selectedLoan: ""};
        case SEARCH_CUSTOMER:
            return { ...state, fetching: true, noRecord: false, fetchedCustomer: {}, cashCollectionSavedSuccessful: false };
        case SEARCH_CUSTOMER_SUCCESSFUL:
            return { ...state, fetchedCustomer: action.payload, fetching: false, cashCollectionSavedSuccessful: false };
        case SEARCH_CUSTOMER_NO_RECORDS:
            return { ...state, noRecord: true, fetching: false };
        case SEARCH_CUSTOMER_FAILED:
            return { ...state, fetching: false };

        //push to customer profile reducers
        case FETCH_CUSTOMER_DETAILS:
            return {...state, searchCustomerLoading: true, };
        case FETCH_CUSTOMER_DETAILS_SUCCESSFUL:
            return { ...state, customerDetails: action.payload, customerFetched: true, searchCustomerLoading: false, cashCollectionSavedSuccessful: false };



        case FETCH_COLLECTION_LOANS:
            return {...state, loading: true};
        case FETCH_COLLECTION_LOANS_SUCCESSFUL:
            return {...state, fetchedCustomerLoans: action.payload, loading: true, };
        case FETCH_COLLECTION_LOAN_TERMS:
            return {...state, fetchLoanTermsLoading: true};
        case PUSHED_CURRENT_LOAN:
            return {...state, currentLoan: action.payload};
        case FETCH_COLLECTION_LOAN_TERMS_SUCCESSFUL:
            return {...state, currentLoanTerms: action.payload, loanFetched: true, fetchLoanTermsLoading: false}


//collection
        case CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case CUSTOMER_BANK_DATE_CHANGED:
            return {...state, bankDate: action.payload};
        case CUSTOMER_LOAN_LIST_CHANGED:
            return {...state, selectedLoan: action.payload, loanFetched: false };
        case CUSTOMER_LOAN_TERM_CHANGED:
            return {...state, customerLoanTerm: action.payload};
        case COLLECTION_CURRENCY_CHANGED:
            return {...state, currency: action.payload };
        case SAVE_CASH_COLLECTION:
            return {...state, updateCollectionLoading: true};
        case SHOW_CASH_COLLECTION_MESSAGE:
            return {...state, cashCollectionSavedSuccessful: true };
        case SAVE_CASH_COLLECTION_SUCCESSFUL:
            return {...state, paidAmount: "", cheque: true, chequeName: "", chequeNumber: "", bankName: "", bankDate: "",
                comments: "", currency: "", loanTermStatus: false, selectedLoan: "", customerSearch: "", fetchedCustomerLoans: {}, currentLoan: {},
                customerDetails: {}, fetchedCustomer: {}, loanFetched: false, customerFetched: false, updateCollectionLoading: false,};
        case SAVE_CASH_COLLECTION_FAILED:
            return {...state, updateCollectionLoading: false, paidAmount: "", cheque: true, chequeName: "", chequeNumber: "", bankName: "", bankDate: "",
                comments: "", currency: "", loanTermStatus: false, selectedLoan: "", customerSearch: "", fetchedCustomerLoans: {}, currentLoan: {},
                customerDetails: {}, fetchedCustomer: {}, loanFetched: false, customerFetched: false };
            
//cash collections
        case FETCH_CASH_COLLECTIONS:
            return {...state, fetchCashCollectionsLoading: true };
        case FETCH_CASH_COLLECTIONS_SUCCESSFUL:
            return {...state, cashCollections: action.payload, loanFetched: false, fetchCashCollectionsLoading: false };
        case CLEAR_SAVED_COLLECTION_MESSAGE:
            return {...state, cashCollectionSavedSuccessful: false, loanFetched: false, cashCollectionSavedFailed: false };

            //update cash collection
        case CASH_COLLECTION_UPDATE_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case UPDATE_CASH_COLLECTION_BANK_DATE_CHANGED:
            return {...state, bankDate: action.payload };
        case UPDATE_CASH_COLLECTION:
            return {...state, updateCashCollectionLoading: true };
        case UPDATE_CASH_COLLECTION_SUCCESSFUL:
            return {...state, updateCashCollectionLoading: false, successfullyUpdated: true };

        case DELETE_CASH_COLLECTION:
            return {...state, deleteCashCollectionLoading: true };
        case DELETE_CASH_COLLECTION_SUCCESSFUL:
            return {...state, deleteCashCollectionLoading: false, };
        case DELETE_CASH_COLLECTION_FAILED:
            return {...state, deleteCashCollectionLoading: false };


        default:
            return state
    }
};

export default CollectionsReducer;