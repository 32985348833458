import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {message} from "antd";


//component imports
import { clearRescheduleStatus } from '../../../../actions/LoanActions';
import { Button } from '../../../common/Button';


class DeleteRescheduleLoanStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }
    }

    onDeleteRescheduleStatus(loanTermsIDs) {
        const { currentLoan } = this.state;

        const legalRepoStatus = currentLoan.legalRepoStatus;
        const loanID = currentLoan.loanID;
        const customerID = currentLoan.customerID;

        if (loanTermsIDs) {
            this.setState({rescheduleStatusSaved: false});
            this.props.clearRescheduleStatus({legalRepoStatus, loanID, customerID, loanTermsIDs})
        } else {
            message.warning("There are no loan terms in a selected loan");
        }
    }

    renderRescheduleStatus(value) {

        switch (value) {
            case "oldRescheduledLoan":
                return (
                    <div>
                        <p id={"rescheduleStatus"}>Old Rescheduled Loan</p>
                    </div>
                );
            case "newRescheduledLoan":
                return (
                    <div>
                        <p id={"rescheduleStatus"}>New Rescheduled Loan</p>
                    </div>
                );

            default:
                return null
        }
    }



    render() {

        const { currentLoan, currentLoanTerms } = this.state;

        //extract loan terms IDs

        let loanTermsIDs = [];

        _.map(currentLoanTerms, term => {

            if('loanTermID' in term) {

                const loanTermID = term.loanTermID;
                loanTermsIDs.push(loanTermID);
            }

        });

        return (
            <div>
                {
                    currentLoan.rescheduleStatus ?

                        <div className={"deleteLoanStatusBox"}>
                            <div className={"deleteLoanStatusCardBox"}>
                                {this.renderRescheduleStatus(currentLoan.rescheduleStatus)}
                            </div>
                            <div>
                                <Button
                                    text={"Clear Status"}
                                    onPress={() => this.onDeleteRescheduleStatus(loanTermsIDs)}
                                />
                            </div>
                        </div> :
                        null

                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {currentLoan, currentLoanTerms,} = state.loans;

    return {
        currentLoan,
        currentLoanTerms,
    }
};

export default connect(mapStateToProps,{clearRescheduleStatus}) (DeleteRescheduleLoanStatus);
