import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
//import { NavLink } from 'react-router-dom';
import {Popconfirm, message, Button} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//actions import
import {fetchLoanTerms, fetchLoan, deleteLoan, fetchLoanLogs} from "../../../../actions/LoanActions";
import {fetchCashCollections} from "../../../../actions/CollectionsActions";

//components imports
import LoanCard from "../Cards/LoanCard";
import {BeatLoader} from "react-spinners";


class ListOfLoans extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            fetchedCustomerLoans: this.props.fetchedCustomerLoans,
            loading: this.props.loading,
            deleteLoanSuccessful: this.props.deleteLoanSuccessful,
            deleteLoanFailed: this.props.deleteLoanFailed,
            deleteLoanLoading: this.props.deleteLoanLoading,
            item: {}
        };

        this.onFetchLoanTerms = this.onFetchLoanTerms.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.fetchedCustomerLoans !== this.props.fetchedCustomerLoans) {
            this.setState ({fetchedCustomerLoans: nextProps.fetchedCustomerLoans})
        }

        if (nextProps.deleteLoanSuccessful !== this.props.deleteLoanSuccessful) {
            this.setState ({deleteLoanSuccessful: nextProps.deleteLoanSuccessful})
        }

        if (nextProps.deleteLoanLoading !== this.props.deleteLoanLoading) {
            this.setState ({deleteLoanLoading: nextProps.deleteLoanLoading})
        }

        if (nextProps.deleteLoanFailed !== this.props.deleteLoanFailed) {
            this.setState ({deleteLoanFailed: nextProps.deleteLoanFailed})
        }

        if (nextProps.loading !== this.props.loading) {
            this.setState ({loading: nextProps.loading})
        }

    }

    onDelete(loan) {
        this.setState({item: loan})
        const customerID = loan.customerID;
        const loanID = loan.loanID;
        this.props.deleteLoan({customerID, loanID})
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    onFetchLoanTerms(loan) {
        //extract props
        const { history } = this.props;

        //fetch customer loan terms
        const customerID = loan.customerID;
        const loanID = loan.loanID;
        console.log(loanID);

        this.props.fetchLoan({customerID, loanID});
        this.props.fetchLoanTerms({customerID, loanID });
        this.props.fetchLoanLogs({customerID, loanID });
        this.props.fetchCashCollections({customerID, loanID});
        history.push('/loan-page');
    }

    renderMessage() {
        const {deleteLoanSuccessful} = this.state;

        if (deleteLoanSuccessful) {
            return <p>{message.success("Loan is deleted successfully")}</p>
        } else {
            return null
        }
    }
    renderFailedMessage() {
        const {deleteLoanFailed} = this.state;

        if (deleteLoanFailed) {
            return <p>{message.error("Sorry! Loan is not deleted")}</p>
        } else {
            return null
        }
    }

    renderLoans() {
        //extract state
        const { loading, fetchedCustomerLoans, item } = this.state;
        let ID = '';
        if (!(_.isEmpty(item))){
            ID = item.loanID;
        }
        if(fetchedCustomerLoans.length !== 0){
            console.log(fetchedCustomerLoans);

            const loans = _.map(fetchedCustomerLoans, customerLoan => {
                return customerLoan
            });
            if (loading) {
                //fetching is on
                return <BeatLoader/>
            } else {
                //fetched
                return(
                    <div className={"loanCard"}>
                        {
                            loans.map(loan => {
                                if("loanID" in loan){
                                    return (
                                        <div key={loan.loanID} className={"loanContainer"}>
                                            <div onClick={ () => this.onFetchLoanTerms(loan) } className={"loanCardBox"}>
                                                <LoanCard
                                                    loan={loan}
                                                />
                                            </div>
                                            {
                                                (loan.loanID === ID) && (this.state.deleteLoanLoading) ?
                                                <div className={"deleteLoanButtonBox"}><BeatLoader size={14} color="black"/></div> :
                                                <div className={"deleteLoanButtonBox"}>
                                                <Popconfirm
                                                    title="Are you sure to delete this loan?"
                                                    onConfirm={() => this.onDelete(loan)}
                                                    onCancel={this.cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button><FontAwesomeIcon icon={faTrashAlt} color="#d10000" /> </Button>
                                                </Popconfirm>
                                                </div>
                                            }
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                );
            }
        }
    }


    render() {
        return (
            <div className={"loanListContainer"}>
                {
                    this.state.fetchedCustomerLoans? 
                    <div>
                        <h4>List Of Loans</h4>
                        <div>
                            { this.renderLoans() }
                        </div>
                    </div> : null
                }

                <div>
                    {this.renderMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { fetchedCustomerLoans, loading, deleteLoanSuccessful, deleteLoanFailed, deleteLoanLoading } = state.loans;

    //return object with state properties
    return {
        fetchedCustomerLoans,
        loading,
        deleteLoanSuccessful,
        deleteLoanFailed,
        deleteLoanLoading
    }
};

export default withRouter(connect(mapStateToProps,{fetchLoanTerms, fetchLoan, fetchCashCollections, deleteLoan, fetchLoanLogs})(ListOfLoans));