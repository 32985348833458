import React from "react";
import { connect } from 'react-redux';
import {Modal, message} from "antd";
import {BeatLoader} from "react-spinners";



//actions imports
import { hideExtraFee, extraFeeChanged, saveExtraFee } from "../../../../actions/LoanActions";

//component imports
import {Input} from "../../../common/Input";
import {Button} from "../../../common/Button";
import {PickDate} from "../../../common/PickDate";


class ExtraFeeCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            extraFeeName: this.props.extraFeeName,
            extraFee: this.props.extraFee,
            extraFeeDate: this.props.extraFeeDate,
            extraFeeSaved: this.props.extraFeeSaved,
            extraFeeFailed: this.props.extraFeeFailed,
            currentLoan: this.props.currentLoan,
            extraFeeLoading: this.props.extraFeeLoading
        };

        this.saveExtraFee = this.saveExtraFee.bind(this);
        this.onExtraFeeDateChange = this.onExtraFeeDateChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }

        if (nextProps.extraFeeLoading !== this.props.extraFeeLoading) {
            this.setState({extraFeeLoading: nextProps.extraFeeLoading})
        }

        if (nextProps.extraFeeName !== this.props.extraFeeName) {
            this.setState({extraFeeName: nextProps.extraFeeName})
        }

        if (nextProps.extraFee !== this.props.extraFee) {
            this.setState({extraFee: nextProps.extraFee})
        }

        if (nextProps.extraFeeDate !== this.props.extraFeeDate) {
            this.setState({extraFeeDate: nextProps.extraFeeDate})
        }

        if (nextProps.extraFeeSaved !== this.props.extraFeeSaved) {
            this.setState({extraFeeSaved: nextProps.extraFeeSaved})
        }

        if (nextProps.extraFeeFailed !== this.props.extraFeeFailed) {
            this.setState({extraFeeFailed: nextProps.extraFeeFailed})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }
    }

    handleOk = () => {
        this.props.hideExtraFee();
    };

    handleCancel = () => {
        this.props.hideExtraFee();
    };

    onExtraFeeDateChange(date) {
        this.props.extraFeeChanged({ prop: "extraFeeDate", value: date});
    }

    saveExtraFee() {
        //extract state
        const { extraFeeName, extraFee, extraFeeDate, currentLoan } = this.state;

        ///extract customerID and loanID from currentLoan
        const { customerID, loanID } = currentLoan;

        if (customerID && loanID && extraFee && extraFeeDate && extraFeeName) {
            const extraFeeAmount = parseInt(extraFee);
            const feeDate = extraFeeDate.toDate();

            this.props.saveExtraFee({ customerID, loanID, extraFeeName, extraFeeDate: feeDate, extraFeeAmount });

        } else {
            message.warning("Kindly fill in extra fee name and amount");
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title="Extra Fee"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={800}
                >
                    <div className={"modalBox"}>
                        <Input
                            label={'Fee Name'}
                            type={'text'}
                            onChange={e => this.props.extraFeeChanged({ prop: "extraFeeName", value: e.target.value})}
                            value={this.state.extraFeeName}
                        />
                        <Input
                            label={'Enter Fee'}
                            type={'number'}
                            onChange={e => this.props.extraFeeChanged({ prop: "extraFee", value: e.target.value})}
                            value={this.state.extraFee}
                        />
                        <PickDate
                            label={"Select extra fee date"}
                            onChange={this.onExtraFeeDateChange}
                            value={this.state.extraFeeDate}
                        />
                        {
                            this.state.extraFeeLoading ?
                            <BeatLoader/> :
                            <Button
                            text={"Save"}
                            onPress={() => this.saveExtraFee() }
                        />
                        }
                    </div>
                    <div>
                        {
                            this.state.extraFeeSaved?
                                <p>{message.success("Extra fee is saved")}</p>: null
                        }
                        {
                            this.state.extraFeeFailed?
                                <p>{message.error("Sorry! Extra fee is not saved")}</p>: null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { extraFeeName, extraFee, extraFeeDate, extraFeeSaved, extraFeeFailed, extraFeeLoading } = state.loans;

    return {
        extraFeeName,
        extraFee,
        extraFeeDate,
        extraFeeSaved,
        extraFeeFailed,
        extraFeeLoading
    }
};

export default connect(mapStateToProps, { hideExtraFee, extraFeeChanged, saveExtraFee })(ExtraFeeCard);