import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {message, Popconfirm, Button} from "antd";
import {BeatLoader} from "react-spinners";
//import moment from "moment";

//action imports
import { fetchLoan, fetchLoanTerms, fetchLoanLogs} from "../../../../actions/LoanActions";
import { manualOverrideLoan, enableManualOverrideBtn, clearLoanMigratedTerms, clearLoanChequeTerms, fixLoan } from "../../../../actions/ManualOverrideActions";

//components imports
import ManualOverrideCard from "../Cards/ManualOverrideCard";
import LogsCard from "../Cards/LogsCard";
//import {Spinner} from "../../../common/Spinner";
//import { Button } from "../../../common/Button";


class ManualOverride extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loan: this.props.currentLoan,
            loanTerms: this.props.currentLoanTerms,
            manualOverrideLoading: this.props.manualOverrideLoading,
            buttonDisabled: this.props.buttonDisabled,
            clearLoanTermsLoading: this.props.clearLoanTermsLoading,
            loanLogs: this.props.loanLogs,
        }

        this.onManualOverrideLoan = this.onManualOverrideLoan.bind(this);
        this.renderLoanTerms = this.renderLoanTerms.bind(this);
        this.onClearMigratedTerms = this.onClearMigratedTerms.bind(this);
        this.onClearCheques = this.onClearCheques.bind(this);
        this.onFixLoan = this.onFixLoan.bind(this);
    }

    componentWillMount() {
        //extract state
        const { loan } = this.state;
        //check that loan term is not empty
        if (!(_.isEmpty(loan))) {
            const { customerID, loanID } = loan;
            this.props.fetchLoanTerms({ customerID, loanID, });
            this.props.fetchLoanLogs({ customerID, loanID, });
            this.props.fetchLoan({ customerID, loanID });
        }

        this.setState({ loan: this.props.currentLoan, loanTerms: this.props.currentLoanTerms, loanLogs: this.props.loanLogs });

        this.props.enableManualOverrideBtn();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        // if (nextProps.currentLoan !== this.props.currentLoan) {
        //     console.log("uploading loan");
        //     console.log({ loan: nextProps.currentLoan });
        //     this.setState({ loan: nextProps.currentLoan });
        // }
        //
        // if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
        //     console.log("uploading loanTerms");
        //     console.log({ loan: nextProps.currentLoanTerms });
        //     this.setState({ loanTerms: nextProps.currentLoanTerms });
        // }
        if (nextProps.loanLogs !== this.props.loanLogs) {
            this.setState({ loanLogs: nextProps.loanLogs });
        }

        if (nextProps.manualOverrideLoading !== this.props.manualOverrideLoading) {
            this.setState({ manualOverrideLoading: nextProps.manualOverrideLoading });
        }

        if (nextProps.buttonDisabled !== this.props.buttonDisabled) {
            this.setState({ buttonDisabled: nextProps.buttonDisabled });
        }

        if (nextProps.clearLoanTermsLoading !== this.props.clearLoanTermsLoading) {
            this.setState({ clearLoanTermsLoading: nextProps.clearLoanTermsLoading });
        }
    }

    onManualOverrideLoan() {
        //extract state
        const { loan, loanTerms } = this.state;
        const customerID = loan.customerID;
        const loanID = loan.loanID;
        // const totalCashPaid = loan.totalCashPaid;
        // const totalChequePaid = loan.totalChequePaid;
        // const totalPaid = loan.totalPaid;

        this.props.manualOverrideLoan({ customerID, loanID, loanTerms });
        ///this.props.resetFetchCashCollections({ customerID, loanID, loanTerms, totalCashPaid, totalChequePaid, totalPaid });
    }

    onFixLoan() {
        //extract state
        const {loan} = this.state;
        const customerID = loan.customerID;
        const loanID = loan.loanID;

        this.props.fixLoan({customerID, loanID});
    }

    onCancel() {
        message.info("Manual Override Canceled");
    }

    onClearMigratedTerms() {
        //extract state
        const { loan } = this.state;
        const customerID = loan.customerID;
        const loanID = loan.loanID;

        this.props.clearLoanMigratedTerms({ loanID, customerID });
    }

    onClearCheques() {
        //extract state
        const { loan } = this.state;
        const customerID = loan.customerID;
        const loanID = loan.loanID;

        this.props.clearLoanChequeTerms({ loanID, customerID });
    }

    renderLoanTerms() {
        //extract state
        const { loanTerms } = this.state;

        if (!(_.isEmpty(loanTerms))) {
            return(
                <div className={"manualOverrideContainer"}>
                    {
                        _.map(loanTerms, term => {
                            return <ManualOverrideCard term={term}/>
                        })
                    }
                </div>
            );
        } else {
            return <p style={{ margin: "3rem", fontFamily: "myriadBold", fontSize: "1.3rem"}}>No Loan Terms</p>;
        }
    }

    renderLogs() {
        //extract state
        const { loanLogs } = this.state;

        if (!(_.isEmpty(loanLogs))) {
            const logs = _.orderBy(loanLogs, ['logDate'], ['desc']); //asc
            return(
                <div className={"loanLogsContainer"}>
                    {
                        logs.map(log => {
                            return <LogsCard log={log}/>
                        })
                    }
                </div>
            );
        } else {
            return <p style={{ margin: "3rem", fontFamily: "myriadBold", fontSize: "1.3rem"}}>No Logs</p>;
        }
    }

    renderButton() {
        //extract state
        const { manualOverrideLoading, buttonDisabled } = this.state;

        if (manualOverrideLoading) {
            return <BeatLoader/>
        } else {
            return(
                <Popconfirm
                    title="You are now resetting this loan. Action will clear all previous transactions on loan"
                    onConfirm={() => this.onManualOverrideLoan()}
                    onCancel={() => this.onCancel()}
                    okText="Proceed"
                    cancelText="Cancel"
                    disabled={buttonDisabled}
                >
                    <Button
                        disabled={buttonDisabled}
                    >
                        <span style={{ color: "red" }}>Reset</span>
                    </Button>
                </Popconfirm>
            );
        }
    }

    renderFixLoanButton() {
        //extract state
        const { manualOverrideLoading, buttonDisabled } = this.state;

        if (manualOverrideLoading) {
            return <BeatLoader/>
        } else {
            return(
                <Popconfirm
                    title="You are now fixing this loan. Action will clear all previous transactions on loan"
                    onConfirm={() => this.onFixLoan()}
                    onCancel={() => this.onCancel()}
                    okText="Proceed"
                    cancelText="Cancel"
                    disabled={buttonDisabled}
                >
                    <Button
                        disabled={buttonDisabled}
                    >
                        <span style={{ color: "green" }}>Fix</span>
                    </Button>
                </Popconfirm>
            );
        }
    }

    renderClearTermsButton() {
        //extract state
        const { clearLoanTermsLoading, manualOverrideLoading, buttonDisabled, loan } = this.state;

        if (clearLoanTermsLoading || manualOverrideLoading) {
            return <BeatLoader/>
        } else {
            //check that loan is migrate or not
            if (loan.migrate) {
                //loan is from migration
                if(loan.migrationCleared) {
                    //has already been migration computed, call normal clear
                    return (
                        <Button
                            disabled={buttonDisabled}
                            onClick={() => this.onClearCheques()}
                        >
                            <span style={{ color: "#35797C" }}>Clear Cheques</span>
                        </Button>
                    );
                } else {
                    //call migration clearance
                    return (
                        <Button
                            disabled={buttonDisabled}
                            onClick={() => this.onClearMigratedTerms()}
                        >
                            <span style={{ color: "#35797C" }}>Clear Migrated Terms</span>
                        </Button>
                    );
                }
            } else {
                //loan has been uploaded
                //call normal clear
                return (
                    <Button
                        disabled={buttonDisabled}
                        onClick={() => this.onClearCheques()}
                    >
                        <span style={{ color: "#35797C" }}>Clear Cheques</span>
                    </Button>
                );
            }
        }
    }

    renderSurplus() {
        //extract state
        const { loan } = this.state;

        if ("surplus" in loan) {
            return <p>Surplus: <span>{loan.surplus}</span></p>
        }
    }

    render(){
        //extract state
        const { loan } = this.state;

        return (
            <div className={"serviceChargeContainer"}>
                <h3>Manually Override Loan {loan.loanID}</h3>

                <div>
                    { this.renderLoanTerms() }
                </div>

                <div className={"btnContainer"}>
                    { this.renderSurplus() }
                    { this.renderClearTermsButton() }
                    { this.renderFixLoanButton() }
                    { this.renderButton() }
                </div>

                <div style={{ marginBottom: '5rem'}}>
                    <h2 style={{ textAlign: "center" }}>Logs</h2>
                    {this.renderLogs()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { manualOverrideLoading, buttonDisabled, clearLoanTermsLoading } = state.manualOverride;
    const { currentLoanTerms, currentLoan, loanLogs } = state.loans;

    return {
        currentLoanTerms,
        currentLoan,
        manualOverrideLoading,
        buttonDisabled,
        clearLoanTermsLoading,
        loanLogs
    }

};

export default connect(mapStateToProps, { fetchLoan, fetchLoanTerms, fetchLoanLogs, manualOverrideLoan, enableManualOverrideBtn,
    clearLoanMigratedTerms, clearLoanChequeTerms, fixLoan })(ManualOverride)
