import React from "react";
import { connect } from 'react-redux';


//components import
import ChooseCustomer from "./subComponents/ChooseCustomer";
import CustomerDetails from "./subComponents/CustomerDetails";
import SelectedLoan from "./subComponents/SelectedLoan";

//file imports
import homeArtwork from '../../assets/images/homeArtwork.png';

class Collections extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerFetched: this.props.customerFetched,
            loanFetched: this.props.loanFetched,
            profile: this.props.profile,
        }
    }

    componentWillMount() {
        this.setState({loanFetched: false});
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.customerFetched !== this.props.customerFetched) {
            this.setState({customerFetched: nextProps.customerFetched})
        }
        if (nextProps.loanFetched !== this.props.loanFetched) {
            this.setState({loanFetched: nextProps.loanFetched})
        }
        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

    }

    checkUserAccess() {
        //extract state
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "collections");

        if (access) {
            return(
                <div>
                    <h2>Manage Collections</h2>
                    <div className={"homeCustomerBox"}>
                        <div>
                            <ChooseCustomer/>
                        </div>
                        <div>
                            {
                                this.state.customerFetched?
                                    <CustomerDetails/>: null
                            }
                        </div>
                    </div>
                    <div className={"homeCustomerSelectedLoanBox"}>
                        {
                            this.state.loanFetched?
                                <SelectedLoan/>: null
                        }
                    </div>

                    <div className={"homeArtworkBox"}>
                        <img src={homeArtwork} alt="landscape artwork" />
                    </div>

                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {

        return (
            <div className={"homeContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { customerFetched, loanFetched, } = state.collections;
    const { profile } = state.profile;

    return {
        //
        profile,
        customerFetched,
        loanFetched,
    }
};

export default connect(mapStateToProps,{})(Collections);