import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment';
import {Button, message, Modal, Popconfirm} from "antd";
import { withRouter } from "react-router-dom";

//actions import
import {showAssignCollector, deleteAssignee, deleteDemandNotice, deleteSecondDemandNotice, fetchComments} from "../../../actions/MasterListActions";
import { fetchLoanTerms, fetchLoan, fetchLoanLogs } from "../../../actions/LoanActions";
import { fetchCashCollections } from "../../../actions/CollectionsActions";

//components import
import AssignCollector from "../subCollections/AssignCollector";
import DemandNoticesCard from "./DemandNoticesCard";
import CommentCard from "./CommentCard";
import SecondDemandNotices from "./SecondDemandNotices";

import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";
import { BeatLoader } from "react-spinners";



class BouncedChequeCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            visibleCollector: false,
            visibleNotice: false,
            visibleSecondNotice: false,
            cheque: this.props.cheque,
            profile: this.props.profile,
            showComments: this.props.showComments,
            showCommentCard: this.props.showCommentCard,
            systemInfo: this.props.systemInfo,
            overdueTerms: this.props.overdueTerms,
            secondDemandLoading: this.props.secondDemandLoading,
            firstDemandLoading: this.props.firstDemandLoading,
            lastComment: this.props.lastComment,
            fetchedComment: this.props.fetchedComment,
            selectedTermOne: this.props.selectedTermOne,
            selectedTermTwo: this.props.selectedTermTwo,

            
            localCurrency: "TSH",
        };

        this.displayTransactionDate = this.displayTransactionDate.bind(this);
        this.displayDueDate = this.displayDueDate.bind(this);
        this.calculateDailyBucket = this.calculateDailyBucket.bind(this);
        this.showNotice = this.showNotice.bind(this);
        this.showModal = this.showModal.bind(this);
        this.onFetchLoanTerms = this.onFetchLoanTerms.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(nextProps.cheque !== this.props.cheque) {
            this.setState({ cheque: nextProps.cheque });
        }

        if (nextProps.overdueTerms !== this.props.overdueTerms) {
            this.setState({ overdueTerms: nextProps.overdueTerms })
        }

        if (nextProps.selectedTermOne !== this.props.selectedTermOne) {
            this.setState({ selectedTermOne: nextProps.selectedTermOne })
        }

        if (nextProps.selectedTermTwo !== this.props.selectedTermTwo) {
            this.setState({ selectedTermTwo: nextProps.selectedTermTwo })
        }

        if (nextProps.secondDemandLoading !== this.props.secondDemandLoading) {
            this.setState({ secondDemandLoading: nextProps.secondDemandLoading })
        }

        if (nextProps.firstDemandLoading !== this.props.firstDemandLoading) {
            this.setState({ firstDemandLoading: nextProps.firstDemandLoading })
        }

        if(nextProps.lastComment !== this.props.lastComment) {
            this.setState({ lastComment: nextProps.lastComment });
        }

        if(nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if(nextProps.showComments !== this.props.showComments) {
            this.setState({ showComments: nextProps.showComments });
        }

        if(nextProps.showCommentCard !== this.props.showCommentCard) {
            this.setState({ showCommentCard: nextProps.showCommentCard });
        }

        if(nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }

        if (nextProps.fetchedComment !== this.props.fetchedComment) {
            this.setState({ fetchedComment: nextProps.fetchedComment })
        }
    }

    componentWillMount(){
        const { cheque } = this.props;
        this.checkCurrencyFormat();
        // this.props.fetchComments({loanID: cheque.loanID});
    }

    showModal ({cheque}) {
        // if (cheque) {
        //     //extract state
        //     const loanID = cheque.loanID;
        //     const loanTermID = cheque.loanTermID;
        //     // this.props.fetchComments({loanID,loanTermID})
        // }

        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    showCollector(terms) {
        this.props.showAssignCollector(terms);
        if (terms) {
            this.setState({
                visibleCollector: true,
            });
        }
    };

    handleOkCollector = () => {
        this.setState({
            visibleCollector: false,
        });

    };

    handleCancelCollector = () => {
        this.setState({
            visibleCollector: false,
        });
    };

    showNotice() {
        this.setState({
            visibleNotice: true,
        });
    };

    handleOkNotice = () => {
        this.setState({
            visibleNotice: false,
        });

    };

    handleCancelNotice = () => {
        this.setState({
            visibleNotice: false,
        });
    };

    showSecondNotice() {
        this.setState({
            visibleSecondNotice: true,
        });
    };

    handleOkSecondNotice = () => {
        this.setState({
            visibleSecondNotice: false,
        });

    };

    handleCancelSecondNotice = () => {
        this.setState({
            visibleSecondNotice: false,
        });
    };


    confirmDelete = ({ cheque }) => {
        //grab the first cheque and take the loan term ID
        const loanTerms = this.props.loanLoanTerms;
        //send to firebase with action
        this.props.deleteAssignee({ customerID: cheque.customerID, loanTerms });
    };

    cancelDelete = () => {
        message.info('Deletion is canceled');
    };

    confirmFirstDelete = ({ cheque }) => {
        const { overdueTerms } = this.state;
        this.setState({selectedTermOne: cheque});
        const terms = overdueTerms.filter(term => term.loanID === cheque.loanID);

        this.props.deleteDemandNotice({ customerID: cheque.customerID, loanTermID: cheque.loanTermID, terms });
        // message.success('First demand notice is deleted');

    };

    cancelFirstDelete = () => {
        // message.info('Deletion is canceled');
    };

    confirmSecondDelete = ({ cheque }) => {
        const { overdueTerms } = this.state;
        this.setState({selectedTermTwo: cheque});
        const terms = overdueTerms.filter(term => term.loanID === cheque.loanID);
        this.props.deleteSecondDemandNotice({ customerID: cheque.customerID, loanTermID: cheque.loanTermID, terms });
        // message.success('Second demand notice is Deleted');

    };

    cancelSecondDelete = () => {
        message.info('Deletion is canceled');
    };

    displayTransactionDate(value) {
        let seconds;
        value.seconds ? seconds = value.seconds : seconds = value._seconds;

        const transactionDate = moment.unix(seconds);
        const displayDate = transactionDate.format("DD/MM/YYYY");

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem'}}>{displayDate}</p>
    }

    displayDueDate(value) {
        let seconds;
        value.seconds ? seconds = value.seconds : seconds = value._seconds;

        const dueDate = moment.unix(seconds);
        const displayDate = dueDate.format("DD/MM/YYYY");

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem'}}>{displayDate}</p>
    }

    calculateDailyBucket(cheque) {

        let seconds;
        if ("transactionDate" in cheque) {
            if (cheque.transactionDate) {
                cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds : seconds = cheque.transactionDate._seconds;
            }
        } else {
            cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const now = moment();

        const fromNow = now.diff(dueDate, 'days');

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem', marginTop: '0.9rem'}}>{fromNow}</p>

    }

    calculateDailyAge(cheque) {

        let seconds;
        if ("transactionDate" in cheque) {
            if (cheque.transactionDate) {
                cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds : seconds = cheque.transactionDate._seconds;
            }
        } else {
            cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const now = moment();

        const fromNow = now.diff(dueDate, 'days');

        const bucket = Math.ceil(fromNow/30);

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem', marginTop: '0.9rem'}}>{bucket}</p>
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, cheque, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        const loanCurrency = cheque.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = value;
        } else {
            if (profile.viewInUSD) {
                //change currency to usd
                currency = "USD";

                //check if user has selected a specific exchange rate date
                if ("exchangeRateOnDate" in profile) {
                    //grab the exchange rate from profile
                    const { exchangeRateOnDate } = profile;
                    displayValue = value / exchangeRateOnDate;
                } else {
                    //grab the default exchange rate from system
                    const { exchangeRate } = systemInfo;
                    displayValue = value / exchangeRate;
                }
            }  else {
                currency = localCurrency;

                displayValue = value;
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem', wordBreak: 'break-all', width: '98%', marginTop: '0.9rem' }}>{formatter.format(displayValue)}</p>;
    }

    renderAssignee(cheque) {

        if ("assignee" in cheque) {
            //
            return(
                <Popconfirm
                    title="Are you sure to delete this assignee?"
                    onConfirm={() => this.confirmDelete({ cheque }) }
                    onCancel={() => this.cancelDelete() }
                    okText="Yes"
                    cancelText="No"
                    zIndex={2000}
                >
                    <h4 style={{color: '#4262e9', fontFamily: 'avenirBlack', fontSize: '0.65rem', marginTop: '1.05rem', textTransform: 'capitalize'}}>{cheque.assignee.name}</h4>
                </Popconfirm>
            );
        } else {
            return(
                <Button type="default" onClick={()=> this.showCollector(cheque) }>
                    Collector
                </Button>
            );
        }
    }

    renderFirstDemandNotice({cheque}) {

        if ('firstDemandNotice' in cheque) {
            //
            let seconds;
            cheque.firstDemandNotice.seconds ? seconds = cheque.firstDemandNotice.seconds : seconds = cheque.firstDemandNotice._seconds;
            const date = moment.unix(seconds);
            const firstDemandNotice = date.format("DD/MM/YYYY");

            return(
                <Popconfirm
                    title="Are you sure to delete this demand notice?"
                    onConfirm={() => this.confirmFirstDelete({ cheque }) }
                    onCancel={() => this.cancelFirstDelete() }
                    okText="Yes"
                    cancelText="No"
                    zIndex={2000}
                >
                    {
                        (cheque.loanTermID === this.state.selectedTermOne.loanTermID) && (this.state.firstDemandLoading) ?
                        <BeatLoader /> :
                        <h4 style={{color: '#4262e9', fontFamily: 'avenirBlack', fontSize: '0.8rem', marginTop: '1.1rem'}}>{firstDemandNotice}</h4>
                    }
                </Popconfirm>
            );
        } else {
            return(
                <Button type="default" onClick={()=>this.showNotice()}>
                    View Notice
                </Button>
            );
        }
    }

    renderSecondDemandNotice({ cheque }) {
        if ('secondDemandNotice' in cheque) {
            //
            let seconds;
            cheque.secondDemandNotice.seconds ? seconds = cheque.secondDemandNotice.seconds : seconds = cheque.secondDemandNotice._seconds;
            const date = moment.unix(seconds);
            const secondDemandNotice = date.format("DD/MM/YYYY");

            return(
                <Popconfirm
                    title="Are you sure to delete this demand notice?"
                    onConfirm={() => this.confirmSecondDelete({ cheque }) }
                    onCancel={() => this.cancelSecondDelete() }
                    okText="Yes"
                    cancelText="No"
                    zIndex={2000}
                >
                    {
                        (cheque.loanTermID === this.state.selectedTermTwo.loanTermID) && (this.state.secondDemandLoading) ?
                        <BeatLoader /> :
                        <h4 style={{color: '#4262e9', fontFamily: 'avenirBlack', fontSize: '0.8rem', marginTop: '1.1rem'}}>{secondDemandNotice}</h4>
                    }
                </Popconfirm>
            );
        } else {
            return(
                <Button type="default" onClick={()=>this.showSecondNotice()}>
                    View Notice
                </Button>
            );
        }
    }



    renderModulo(term) {
        //extract payment and modulo
        const modulo = term.modulo;
        const amount = term.amount;

        const overdue = amount - modulo;

        return <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all'}}>{this.displayCurrency(overdue)}</p>;
    }

    // renderPaymentDate(term) {
    //     //check if termStatus status is true. if true it indicates that loan has been cleared so show payment date
    //     if (term.termStatus.status) {
    //         //show date
    //         let seconds;
    //         term.termStatus.paidDate.seconds ? seconds = term.termStatus.paidDate.seconds : seconds = term.termStatus.paidDate._seconds;
    //         const date = moment.unix(seconds);
    //         const paidDate = date.format("DD/MM/YYYY");
    //         return <p>{paidDate}</p>
    //     }
    // }

    onFetchLoanTerms(term) {
        //extract props
        const { history } = this.props;

        //fetch customer loan terms
        const customerID = term.customerID;
        const loanID = term.loanID;

        this.props.fetchLoan({customerID, loanID});
        this.props.fetchLoanTerms({customerID, loanID });
        this.props.fetchLoanLogs({customerID, loanID });
        this.props.fetchCashCollections({customerID, loanID});
        history.push('/loan-page');
    }

    renderSumOfODInUSD(term) {
        //extract state
        const { systemInfo } = this.state;

        if (!(_.isEmpty(systemInfo))) {

            let total = 0;

            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //check if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        total = term.amount - term.modulo;
                    } else {
                        total = term.amount;
                    }

                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    total = amount/exchangeRate;
                }
            }
            const currency = "USD";

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
            });

            return <div><p>{formatter.format(total)}</p></div>
        } else {
            return <h3>-</h3>
        }
    }

    renderOverdue(term) {
        //check if term is cleared or not
        if (term.termStatus.status) {
            return <p>{ this.displayCurrency(0) }</p>
        } else {
            return <p>{ this.displayCurrency(term.amount) }</p>
        }
    }

    renderClientProfile(cheque) {
        if (cheque) {
            switch (cheque.clientProfile) {
                case "individual":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Individual</p>
                        </div>
                    );
                case "sme":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>SME</p>
                        </div>
                    );
                case "corporate":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Corporate</p>
                        </div>
                    );
                case "trust":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Trust</p>
                        </div>
                    );
                case "government":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Government</p>
                        </div>
                    );
                case "others":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Others</p>
                        </div>
                    );

                default:
                    return null
            }
        } else {
            return null
        }
    }

    renderLegalRepoStatus(cheque) {
        if (cheque) {
            switch (cheque.legalRepoStatus) {
                case "legal":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Legal</p>
                        </div>
                    );
                case "repo":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Repossessed</p>
                        </div>
                    );
                case "legalRepo":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Repossessed & Legal</p>
                        </div>
                    );
                case "released":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Released</p>
                        </div>
                    );
                case "underFollowUp":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Under Follow-Up</p>
                        </div>
                    );
                case "customerAbsconding":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Customer Absconding</p>
                        </div>
                    );
                case "assetNonTraceable":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Asset Non Traceable</p>
                        </div>
                    );
                case "auctioned":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Auctioned</p>
                        </div>
                    );
                case "nonStarter":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Non Starter</p>
                        </div>
                    );
                case "others":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Others</p>
                        </div>
                    );

                default:
                    return null
            }
        } else {
            return null
        }
    }

    renderBouncedReason(cheque) {
        if (cheque) {
            switch (cheque.bouncedReason) {
                case "insufficientFunds":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Insufficient Funds</p>
                        </div>
                    );
                case "drawer'sSignatureRequired":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Drawer's Signature Required</p>
                        </div>
                    );
                case "staleCheque":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Stale Cheque</p>
                        </div>
                    );
                case "accountClosed":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Account Closed</p>
                        </div>
                    );
                case "dormantAccount":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Dormant Account</p>
                        </div>
                    );
                case "spoiltCheque":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Spoilt Cheque</p>
                        </div>
                    );
                case "drawer'sConfirmationRequired":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Drawer's Confirmation Required</p>
                        </div>
                    );
                case "correctionInCheque":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Correction In Cheque</p>
                        </div>
                    );
                case "chequeNotStamped":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Cheque Not Stamped</p>
                        </div>
                    );
                case "stopPayment":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Stop Payment</p>
                        </div>
                    );
                case "amountInWordsAndFigureDiffer":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Amount In Words And Figure Differ</p>
                        </div>
                    );
                case "chequeOnHold":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Cheque On Hold</p>
                        </div>
                    );
                case "referToDrawer":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Refer To Drawer</p>
                        </div>
                    );
                case "others":
                    return (
                        <div>
                            <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>Others</p>
                        </div>
                    );

                default:
                    return null
            }
        } else {
            return null
        }
    }

    renderOtherReason(cheque) {

        if (cheque) {
            if ('otherReason' in cheque){
                return (
                    <div>
                        <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>{cheque.otherReason}</p>
                    </div>
                );
            }
        } else {
            return null
        }
    }

    renderComment(){
        const { cheque } = this.state;
        if ('masterListComment' in cheque){
            return(
                <div className={"masterlistCommentButton"}>
                    <div><p onClick={()=>this.showModal({cheque})} style={{fontFamily: 'Rockwell', color: 'blue'}}>{cheque.masterListComment.newComment}</p></div>
                    <Modal
                        title="Comments"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        width={900}
                    >
                        <CommentCard
                            //loan={loan}
                            loanTerm={cheque}
                        />
                    </Modal>
                </div>
            )
        }else{
            return(
                <div className={"masterlistCommentButton"}>
                    <Button type="default" onClick={()=>this.showModal({cheque})}>
                        Comments
                    </Button>
                    <Modal
                        title="Comments"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        width={900}
                    >
                        <CommentCard
                            //loan={loan}
                            loanTerm={cheque}
                        />
                    </Modal>
                </div>
            )
        }
    }

    render() {
        const { cheque } = this.state;
        if ('firstDemandNotice' in cheque) {
             // return cheque;
        }

        return (
            <div className={"bouncedChequeContainer"}>
                <div className={"bouncedChequeBox"}>
                    <div>
                        {
                            cheque.transactionDate ?
                                <p>{this.displayTransactionDate(cheque.transactionDate)}</p>: <p>{this.displayTransactionDate(cheque.dueDate)}</p>
                        }
                    </div>

                    <div >
                        <p style={{fontFamily: 'avenirBlack', fontSize: '0.7rem', textTransform: 'uppercase', width: '98%', wordBreak: 'break-all', color: '#383838'}}>{cheque.customerName}</p>
                    </div>

                    <div >
                        <div  className={"masterlistCollectorAssignee"}>
                            {this.renderAssignee(cheque)}
                            <Modal
                                title="Choose Collector"
                                visible={this.state.visibleCollector}
                                onOk={this.handleOkCollector}
                                onCancel={this.handleCancelCollector}
                                footer={null}
                                width={700}
                            >
                                <AssignCollector
                                    //loan={loan}
                                    loanLoanTerms={this.props.loanLoanTerms}
                                />
                            </Modal>
                        </div>
                    </div>

                    <div >
                        <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all',}}>{cheque.salesExe}</p>
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell'}}>{cheque.chequeNumber}</p>
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all'}}>{ this.displayCurrency(cheque.amount) }</p>
                    </div>

                    <div>
                        { cheque.bouncedReason ?
                            <div>
                                {this.renderBouncedReason(cheque)}
                            </div>: null
                        }
                    </div>

                    <div>
                        { cheque.otherReason ?
                            <div>
                                {this.renderOtherReason(cheque)}
                            </div>: null
                        }
                    </div>

                    {/*<div>*/}
                    {/*    /!* Payment *!/*/}
                    {/*    { this.renderPaymentDate(cheque) }*/}
                    {/*</div>*/}

                    <div>
                        {/*<p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all'}}>{ this.displayCurrency(cheque.amount) }</p>*/}
                        { "modulo" in cheque ? this.renderModulo(cheque): <p style={{fontFamily: 'Rockwell', width: '98%', wordBreak: 'break-all'}}>{ this.renderOverdue(cheque)}</p> }
                    </div>

                    <div>
                        <div>
                            <p style={{fontFamily: 'Rockwell'}}>{cheque.character}</p>
                        </div>
                    </div>

                    <div>
                        <div>
                            <p style={{fontFamily: 'Rockwell'}}>{cheque.clientAction}</p>
                        </div>
                    </div>

                    <div>
                        {
                            cheque.legalRepoStatus ?
                                <div>
                                    <p style={{fontFamily: 'Rockwell'}}>{cheque.legalRepoStatus}</p>
                                </div>: null
                        }
                    </div>

                    <div>

                        {
                            // this.renderComment()
                            cheque.masterListComment ?
                            <div className={"masterlistCommentButton"}>
                                <div><p onClick={()=>this.showModal({cheque})} style={{fontFamily: 'Rockwell', color: 'blue'}}>{cheque.masterListComment.newComment}</p></div>
                            <Modal
                                title="Comments"
                                visible={this.state.visible}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel}
                                footer={null}
                                width={900}
                            >
                                <CommentCard
                                    //loan={loan}
                                    loanTerm={cheque}
                                />
                            </Modal>
                            </div> :
                            <div className={"masterlistCommentButton"}>
                                <Button type="default" onClick={()=>this.showModal({cheque})}>
                                    Comments
                                </Button>
                                <Modal
                                    title="Comments"
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                    width={900}
                                >
                                    <CommentCard
                                        //loan={loan}
                                        loanTerm={cheque}
                                    />
                                    {cheque.loanTermID}
                                </Modal>
                            </div>
                        }

                    </div>

                    <div>
                        <p onClick={ () => this.onFetchLoanTerms(cheque) }>View</p>
                    </div>

                    <div>
                        { this.calculateDailyBucket(cheque) }
                    </div>

                    <div>
                        {this.calculateDailyAge(cheque)}
                    </div>

                    <div>
                        {this.renderClientProfile(cheque)}
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell'}}>{cheque.truck}</p>
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell'}}>{cheque.industry}</p>
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell'}}>{cheque.model}</p>
                    </div>

                    <div>
                        <p style={{fontFamily: 'Rockwell'}}>{cheque.segment}</p>
                    </div>

                    <div>
                        {/* OD Reporting USD */}
                        { this.renderSumOfODInUSD(cheque) }
                    </div>

                    <div>
                        <p>{this.displayDueDate(cheque.dueDate)}</p>
                    </div>

                    <div>
                        <div className={"noticesBox"}>
                            {this.renderFirstDemandNotice({cheque})}
                            <Modal
                                title="Demand Notices"
                                visible={this.state.visibleNotice}
                                onOk={this.handleOkNotice}
                                onCancel={this.handleCancelNotice}
                                footer={null}
                                width={900}
                            >
                                <DemandNoticesCard
                                    //loan={loan}
                                    loanTerm={cheque}
                                />
                            </Modal>
                        </div>
                    </div>

                    <div>
                        <div className={"noticesBox"}>
                            {this.renderSecondDemandNotice({cheque})}
                            <Modal
                                title="Demand Notices"
                                visible={this.state.visibleSecondNotice}
                                onOk={this.handleOkSecondNotice}
                                onCancel={this.handleCancelSecondNotice}
                                footer={null}
                                width={900}
                            >
                                <SecondDemandNotices
                                    //loan={loan}
                                    loanTerm={cheque}
                                />
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { showCommentCard, lastComment, fetchedComment, overdueTerms, secondDemandLoading, firstDemandLoading, selectedTermOne, selectedTermTwo } = state.masterList;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        showCommentCard,
        profile,
        systemInfo,
        lastComment,
        fetchedComment,
        overdueTerms,
        secondDemandLoading,
        firstDemandLoading,
        selectedTermOne,
        selectedTermTwo
    }
};

export default withRouter(connect(mapStateToProps, {showAssignCollector, deleteAssignee, deleteDemandNotice, deleteSecondDemandNotice, fetchComments, fetchLoanTerms, fetchLoan,
    fetchCashCollections, fetchLoanLogs })(BouncedChequeCard));