import React from 'react'
import { connect } from 'react-redux';
//import _ from "lodash";
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {BeatLoader} from "react-spinners";
import {message, Button} from "antd";

//...import actions
import { computeLoansForPenalInterest } from "../../../actions/ManualOverrideActions";
import { calculatePenalInterest } from "../../../actions/AppActions";

//...component imports
import CurrentPaidLoanCard from '../cards/CurrentPaidLoanCard';
// import {Button} from "../../common/Button";
//import IncorrectLoanCard from "../cards/IncorrectLoanCard";

export class CurrentPaidLoans extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loansForPenalInterest: this.props.loansForPenalInterest,
            loansForPenalInterestLoading: this.props.loansForPenalInterestLoading,
            calculatePenalInterestLoading: this.props.calculatePenalInterestLoading,
            penalBtn: this.props.penalBtn,
        };

        this.onRefresh = this.onRefresh.bind(this);
        this.onCalculatePenalInterest = this.onCalculatePenalInterest.bind(this);
    }

    componentWillMount(){
        this.props.computeLoansForPenalInterest();
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.loansForPenalInterest !== this.props.loansForPenalInterest){
            this.setState({loansForPenalInterest: nextProps.loansForPenalInterest});
        }

        if(nextProps.loansForPenalInterestLoading !== this.props.loansForPenalInterestLoading){
            this.setState({loansForPenalInterestLoading: nextProps.loansForPenalInterestLoading});
        }

        if(nextProps.calculatePenalInterestLoading !== this.props.calculatePenalInterestLoading){
            this.setState({calculatePenalInterestLoading: nextProps.calculatePenalInterestLoading});
        }

        if(nextProps.penalBtn !== this.props.penalBtn){
            this.setState({penalBtn: nextProps.penalBtn});
        }
    }

    onRefresh() {
        this.props.computeLoansForPenalInterest();
    }

    onCalculatePenalInterest() {
        //extract state
        const { loansForPenalInterest } = this.state;

        const loansArray = arraySort(loansForPenalInterest, "customerName");

        if (loansArray.length !== 0) {
            const batch = loansArray.slice(0,10);
            console.log(batch);
            this.props.calculatePenalInterest({ loansArray: batch, penalBtn: "penalInterest" });
        } else {
            message.info("No Penal Interest Loans to compute");
        }
    }

    renderPenalInterestBtn() {
        //extract state
        const { calculatePenalInterestLoading, penalBtn } = this.state;
        let disabled = false;

        if (calculatePenalInterestLoading) {
            if (penalBtn !== "penalInterest") {
                disabled = true
            }
        }

        if (calculatePenalInterestLoading && penalBtn === "penalInterest") {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <Button
                        disabled={disabled}
                        onClick={() => this.onCalculatePenalInterest()}
                    >
                        <span style={{ color: "#6D66E7" }}>Calculate Penal Interest</span>
                    </Button>
                    <p>Computes 10 loans per batch</p>
                </div>
            )
        }
    }

    renderRefreshButton() {
        //extract state
        const { loansForPenalInterestLoading } = this.state;

        if (loansForPenalInterestLoading) {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={'sync-alt'} className={'refreshIcon'} onClick={ () => this.onRefresh() }/>
                </div>
            )
        }
    }

    renderLoansForPenalInterest(){
        const { loansForPenalInterest, loansForPenalInterestLoading } = this.state;

        const sortedLoans = arraySort(loansForPenalInterest, "customerName");

        if (sortedLoans.length !== 0) {
            return(
                <div className={'manualOverrideContainer'}>
                    {
                        sortedLoans.map(loan => {
                            return (
                                <CurrentPaidLoanCard loan={loan} key={loan.loanID} />
                            )
                        })
                    }
                </div>
            );
        } else {
            if (loansForPenalInterestLoading) {
                return <BeatLoader/>
            } else {
                return <p className={'userAdminsEmptyWarning'}>No loans for calculations currently available!</p>;
            }
        }

    }



    render() {
        //extract state
        const { loansForPenalInterest } = this.state;
        console.log({ loansForPenalInterest });
        const numberOfLoans = loansForPenalInterest.length;
        return (
            <div className={'incorrectLoansContainer'}>
                <div>
                    <h4>({numberOfLoans}) Loans for calculations</h4>
                </div>

                <div>
                    { this.renderPenalInterestBtn() }
                </div>

                <div>
                    <hr className="sep-2"/>
                </div>

                <div style={{ textAlign: 'right'}}>
                    {this.renderRefreshButton()}
                </div>

                <div>
                    { this.renderLoansForPenalInterest() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { loansForPenalInterest, loansForPenalInterestLoading } = state.manualOverride;
    const { calculatePenalInterestLoading, penalBtn } = state.settings;

    return{
        loansForPenalInterest,
        loansForPenalInterestLoading,
        calculatePenalInterestLoading,
        penalBtn
    }
}


export default connect(mapStateToProps, { calculatePenalInterest, computeLoansForPenalInterest })(CurrentPaidLoans);
