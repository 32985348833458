import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {message, Modal} from "antd";
import {BeatLoader} from "react-spinners";

//action imports
import { rescheduleStatusChanged, saveRescheduleStatus, hideRescheduleStatus } from '../../../../actions/LoanActions';

//component imports
import SelectRescheduleStatus from "../../../common/SelectRescheduleStatus";
import DeleteRescheduleLoanStatus from "./DeleteRescheduleLoanStatus";
import { Button } from '../../../common/Button';


class RescheduleStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            visible: this.props.visible,
            rescheduleStatus: this.props.rescheduleStatus,
            saveRescheduleStatusLoading: this.props.saveRescheduleStatusLoading,
            rescheduleStatusCleared: this.props.rescheduleStatusCleared,
            rescheduleStatusSaved: this.props.rescheduleStatusSaved,
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,

        };
        this.onRescheduleStatusChange = this.onRescheduleStatusChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }

        if (nextProps.rescheduleStatus !== this.props.rescheduleStatus) {
            this.setState({rescheduleStatus: nextProps.rescheduleStatus})
        }

        if (nextProps.saveRescheduleStatusLoading !== this.props.saveRescheduleStatusLoading) {
            this.setState({saveRescheduleStatusLoading: nextProps.saveRescheduleStatusLoading})
        }

        if (nextProps.rescheduleStatusSaved !== this.props.rescheduleStatusSaved) {
            this.setState({rescheduleStatusSaved: nextProps.rescheduleStatusSaved})
        }

        if (nextProps.rescheduleStatusCleared !== this.props.rescheduleStatusCleared) {
            this.setState({rescheduleStatusCleared: nextProps.rescheduleStatusCleared})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }
    }

    handleOk = () => {
        this.props.hideRescheduleStatus()
    };

    handleCancel = () => {
        this.props.hideRescheduleStatus()
    };

    onRescheduleStatusChange(value) {
        this.props.rescheduleStatusChanged(value)
    }

    onSave(loanTermsIDs) {
        const { rescheduleStatus, currentLoan, } = this.state;

        //check if there are loan terms in a selected loan

        if (loanTermsIDs.length !== 0) {

            // extract
            const customerID = currentLoan.customerID;
            const loanID = currentLoan.loanID;

            if (rescheduleStatus) {
                this.props.saveRescheduleStatus({rescheduleStatus, customerID, loanID, loanTermsIDs})
            } else {
                message.warning("Select a reschedule status");
            }

        } else {
            message.warning("There is no selected loan");
        }
    }

    render() {
        const {currentLoanTerms} = this.state;

        //extract loan terms IDs

        let loanTermsIDs = [];

        _.map(currentLoanTerms, term => {

            if('loanTermID' in term) {

                const loanTermID = term.loanTermID;
                loanTermsIDs.push(loanTermID);
            }

            return term
        });


        return (
            <div className={"loanStatusCardModal"}>
                <Modal
                    title="Reschedule Loan Status"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={800}
                >
                    <div>
                        <div className={"modalBox"}>
                            <SelectRescheduleStatus
                                label={"Select reschedule loan status"}
                                onChange={this.onRescheduleStatusChange}
                                value= {this.state.rescheduleStatus}
                            />

                            {
                                this.state.saveRescheduleStatusLoading?
                                    <BeatLoader/>:
                                    <Button
                                        onPress={() => this.onSave(loanTermsIDs)}
                                        text={"Save"}
                                    />
                            }
                        </div>

                        <div className={"legalRepoModalStatusBox"}>
                            <DeleteRescheduleLoanStatus/>
                        </div>
                        <div>
                            {
                                this.state.rescheduleStatusCleared?
                                    <p>{message.success("Reschedule loan status is deleted")}</p>: null
                            }
                        </div>
                        <div>
                            {
                                this.state.rescheduleStatusSaved?
                                    <p>{message.success("Status is saved")}</p>: null
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { rescheduleStatus, saveRescheduleStatusLoading, rescheduleStatusSaved, currentLoan, currentLoanTerms, rescheduleStatusCleared } = state.loans;

    return {
        rescheduleStatus,
        saveRescheduleStatusLoading,
        rescheduleStatusCleared,
        rescheduleStatusSaved,
        currentLoan,
        currentLoanTerms,
    }
};

export default connect(mapStateToProps,{rescheduleStatusChanged, saveRescheduleStatus, hideRescheduleStatus})(RescheduleStatus);
