import * as firebase from 'firebase';
import {message} from "antd";
import _ from "lodash";
// import algoliasearch from 'algoliasearch';
// const client = algoliasearch('YourApplicationID', 'YourAdminAPIKey');
// const index = client.initIndex('tz_customerNames');

//Types imports
import {
    CALCULATE_PENAL_INTEREST,
    CALCULATE_PENAL_INTEREST_FAILED,
    CALCULATE_PENAL_INTEREST_SUCCESSFUL,
    CASH_COLLECTION_UPDATE_DETAILS_CHANGED,
    CLEAR_PENAL_INTEREST_MESSAGE,
    CLEAR_SAVED_COLLECTION_MESSAGE,
    COLLECTION_CURRENCY_CHANGED, COMPUTE_MODULO_AND_CURRENT_PAID,
    COMPUTE_MODULO_AND_CURRENT_PAID_FAILED,
    COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL,
    CUSTOMER_BANK_DATE_CHANGED,
    CUSTOMER_COLLECTION_DETAILS_CHANGED,
    CUSTOMER_LOAN_LIST_CHANGED,
    CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
    CUSTOMER_LOAN_TERM_CHANGED,
    DELETE_CASH_COLLECTION,
    DELETE_CASH_COLLECTION_FAILED,
    DELETE_CASH_COLLECTION_SUCCESSFUL,
    FETCH_CASH_COLLECTIONS,
    FETCH_CASH_COLLECTIONS_FAILED,
    FETCH_CASH_COLLECTIONS_SUCCESSFUL,
    FETCH_COLLECTION_LOAN_TERMS,
    FETCH_COLLECTION_LOAN_TERMS_FAILED,
    FETCH_COLLECTION_LOAN_TERMS_SUCCESSFUL,
    FETCH_COLLECTION_LOANS,
    FETCH_COLLECTION_LOANS_FAILED,
    FETCH_COLLECTION_LOANS_SUCCESSFUL,
    FETCH_CUSTOMER_DETAILS,
    FETCH_CUSTOMER_DETAILS_FAILED,
    FETCH_CUSTOMER_DETAILS_SUCCESSFUL,
    SAVE_CASH_COLLECTION,
    SAVE_CASH_COLLECTION_FAILED,
    SAVE_CASH_COLLECTION_SUCCESSFUL,
    SEARCH_CUSTOMER_FAILED,
    SEARCH_CUSTOMER_NO_RECORDS,
    SEARCH_CUSTOMER_SUCCESSFUL,
    SHOW_CASH_COLLECTION_MESSAGE,
    UPDATE_CASH_COLLECTION,
    UPDATE_CASH_COLLECTION_BANK_DATE_CHANGED,
    UPDATE_CASH_COLLECTION_FAILED,
    UPDATE_CASH_COLLECTION_SUCCESSFUL,
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const customerLoanTermChanged = (value) => {

    return{
        type: CUSTOMER_LOAN_TERM_CHANGED,
        payload: value
    }
};

export const customerLoanListChanged = (value) => {

    return{
        type: CUSTOMER_LOAN_LIST_CHANGED,
        payload: value
    }
};

export const loanTermCardDetailsChanged = ({prop, value}) => {

    return{
        type: CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const bankDateChanged = (date) => {

    return{
        type: CUSTOMER_BANK_DATE_CHANGED,
        payload: date
    }
};

export const cashCollectionUpdateDetailsChanged = ({prop, value}) => {

    return{
        type: CASH_COLLECTION_UPDATE_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const updateCashCollectionBankDate = (date) => {

    return{
        type: UPDATE_CASH_COLLECTION_BANK_DATE_CHANGED,
        payload: date
    }
};

export const customerDetailsChanged = ({prop, value}) => {

    return{
        type: CUSTOMER_COLLECTION_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const clearSavedCollectionMessage = () => {

    return {
        type: CLEAR_SAVED_COLLECTION_MESSAGE,
    }
};

export const collectionCurrencyChanged = (status) => {

    return{
        type: COLLECTION_CURRENCY_CHANGED,
        payload: status
    }
};


export const searchExistingCustomers = (value) => {

    console.log({ value });

    //check if value is not a number (customer name)
    let searchProperty;
    let searchValue;
    if (isNaN(value)) {
        //its a customer name
        //title case the value string
        //searchValue = value.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        searchValue = value.toUpperCase();
        searchProperty = 'customerName';
    } else {
        searchValue = value;
        searchProperty = 'customerID';
    }

    return(dispatch) => {
        firebase.firestore().collection("customerBucket").where(searchProperty, "==", searchValue)
            .get()
            .then(function(querySnapshot) {

                //check if query has no result
                if (querySnapshot.size !== 0) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        const data = doc.data();

                        if ("active" in data) {
                            if (data.active) {
                                dispatch({
                                    type: SEARCH_CUSTOMER_SUCCESSFUL,
                                    payload: data
                                });
                            }
                        }

                    });
                } else {
                    //no record for query
                    dispatch({
                        type: SEARCH_CUSTOMER_NO_RECORDS,
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
                dispatch({
                    type: SEARCH_CUSTOMER_FAILED,
                })
            });
    }

};


export const pushToCustomerDetails = ({ customerID }) => {

    return(dispatch) => {
        //fetch customer profile
        dispatch({ type: FETCH_CUSTOMER_DETAILS});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("account").doc("info")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: FETCH_CUSTOMER_DETAILS_SUCCESSFUL,
                        payload: data
                    });
                } else {
                    console.log("account doc doesn't exists");
                    dispatch({ type: FETCH_CUSTOMER_DETAILS_FAILED })
                }
            })
    };
};


export const fetchCollectionsLoans = ({ customerID }) => {
    return(dispatch) => {

        dispatch({ type: FETCH_COLLECTION_LOANS });

        //create loans obj
        let loans = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans")
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots

                    //add loan too loans object
                    loans[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_COLLECTION_LOANS_SUCCESSFUL,
                    payload: loans
                });
            }, function (error) {
                console.log('could not get loans');
                console.log(error);

                dispatch({
                    type: FETCH_COLLECTION_LOANS_FAILED
                });
            })

    }
};


export const fetchCollectionsLoanTerms = ({ customerID, loanID }) => {

    return(dispatch) => {

        dispatch({ type: FETCH_COLLECTION_LOAN_TERMS });

        //create loan terms obj
        let loanTerms = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    loanTerms[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_COLLECTION_LOAN_TERMS_SUCCESSFUL,
                    payload: loanTerms
                })
            }, function (error) {
                console.log("Error getting documents: ", error);
                dispatch({ type: FETCH_COLLECTION_LOAN_TERMS_FAILED });
            })

    }
};

export const saveCashCollection = ({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, paidDate, comment, currency }) => {

    //
    const bankDate = paidDate.toDate();

    return(dispatch) => {

        dispatch({ type: SAVE_CASH_COLLECTION });
        firebase.firestore().collection("loans").doc(loanID)
            .get()
            .then((doc) => {

                if (doc.exists){
                    //
                    const data = doc.data();
                    if (data.customerID === customerID){
                        //
                        writeCashCollection({customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, paidDate, comment, currency, bankDate, dispatch});
                    } else {
                        dispatch({ type: SAVE_CASH_COLLECTION_FAILED });
                        message.warn("Sorry! Please refresh the system and try again to save");
                    }
                } else {
                    message.warn("Sorry! Please verify the selected loan");
                }
            })
            .catch(error => {
                console.log(error);
                console.log("cash collection is NOT saved to customer profile");
                dispatch({ type: SAVE_CASH_COLLECTION_FAILED });
                message.error("Failed to save cash collection");
            })

    };
};

function writeCashCollection({customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, comment, currency, bankDate, dispatch}) {

    const data = JSON.stringify({
        customerID,
        customerName,
        loanID,
        cashCollectionID,
        paidAmount,
        bankName,
        comment,
        currency,
        bankDate,
        event: "saving cash collection"
    });

    const url = `${project.serverUrl}saveCashCollection`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            message.success("Cash collection is saved");
            dispatch({ type: SAVE_CASH_COLLECTION_SUCCESSFUL });
            console.log(response);

        }).catch((error) => {
        dispatch({ type: SAVE_CASH_COLLECTION_FAILED });
        message.success("Cash collection is saved");
        console.log(error);
    })
}

// export const saveCashCollection = ({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, paidDate, comment, currency }) => {
//
//     //
//     const bankDate = paidDate.toDate();
//     const lastEdit = new Date();
//
//     return(dispatch) => {
//
//         dispatch({ type: SAVE_CASH_COLLECTION });
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
//             .set({ cashCollectionID, loanID, paidAmount, bankName, bankDate, comment, currency, lastEdit })
//             .then(() => {
//                 console.log("cash collection is saved to customer profile");
//                 dispatch({type: SHOW_CASH_COLLECTION_MESSAGE});
//                 // save loan term to term bucket
//                 saveCashCollectionToBucket({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit, dispatch });
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.log("cash collection is NOT saved to customer profile");
//                 dispatch({ type: SAVE_CASH_COLLECTION_FAILED });
//             })
//
//     };
// };
//
//
// function saveCashCollectionToBucket({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit, dispatch }) {
//
//     firebase.firestore().collection("cashCollections").doc(cashCollectionID)
//         .set({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit })
//         .then(() => {
//             console.log("cash collection is saved to bucket");
//             dispatch({ type: SAVE_CASH_COLLECTION_SUCCESSFUL });
//             computeCashPayment({ customerID, loanID, paidAmount, lastEdit, cashCollectionID, bankDate });
//         })
//         .catch(error => {
//             console.log(error);
//             console.log("cash collection is NOT saved to bucket");
//             dispatch({ type: SAVE_CASH_COLLECTION_FAILED });
//         })
// }
//
// function computeCashPayment({ customerID, loanID, paidAmount, lastEdit, cashCollectionID, bankDate }) {
//
//     firebase.firestore().collection("loans").doc(loanID).get()
//         .then((doc) => {
//             if (doc.exists) {
//                 const data = doc.data();
//
//                 const oldTotalCashPaid = data.totalCashPaid;
//                 //calculate total paid
//                 const oldTotalPaid = data.totalPaid;
//
//                 //add paid amount to old total cash paid
//                 const totalCashPaid = paidAmount + oldTotalCashPaid;
//
//                 //add paid amount to old total paid
//                 const totalPaid = paidAmount + oldTotalPaid;
//
//                 //check if loan has been cleared or not
//                 let loanStatus = false;
//                 const totalAmount = data.totalAmount;
//                 //find if there are extra fees and their total
//                 let totalArray = [];
//                 if ("extraFees" in data) {
//                     //there are extra fees, loop over each extra fee and put amount into amountArray
//                     _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
//                 }
//                 let totalFeesAmount = 0;
//                 //calculate the total amount from numbers in the array
//                 //check that total array is not empty
//                 if (totalArray.length > 0) {
//                     totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
//                 }
//
//                 let debt = totalAmount + totalFeesAmount;
//                 if ("earlyLiquidation" in data) {
//                     debt = data.earlyLiquidation;
//                 }
//                 const clearedAmount = debt - totalPaid;
//                 //if clearedAmount is below zero then debt is cleared
//                 if (clearedAmount <= 0) {
//                     loanStatus = true;
//                 }
//
//                 if (loanStatus) {
//                     //assign loanStatus true to all terms in this loan
//                     assignClearedLoanStatus({ customerID, loanID });
//                 }
//
//                 //register currentPaid
//                 let oldCurrentPaid = 0;
//                 if ("currentPaid" in data) {
//                     oldCurrentPaid = data.currentPaid;
//                 }
//                 const currentPaid = paidAmount + oldCurrentPaid;
//
//                 //post to loan profile on loan bucket and customer profile
//                 firebase.firestore().collection("loans").doc(loanID).update({ totalCashPaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: bankDate, cheque: false } })
//                     .then(() => {
//                         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                             .update({ totalCashPaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: bankDate, cheque: false  } })
//                             .then(() => {
//                                 console.log("loan calculations saved");
//                                 //calculateManuallyPenalInterest();
//                                 storeCurrentPaid({ loanID, cashCollectionID, customerID, paidAmount });
//                             }).catch((error) => {
//                             console.log("error in saving calculations to loan in profile");
//                             console.log(error);
//                         });
//                     }).catch((error) => {
//                     console.log("error in saving calculations to loan in bucket");
//                     console.log(error);
//                 });
//
//             }
//         }).catch(e => e);
// }
//
//
// function storeCurrentPaid({ loanID, cashCollectionID, customerID, paidAmount }) {
//
//     const currentPaidID = cashCollectionID;
//
//     //save current paid  to bucket
//     firebase.firestore().collection("currentPaidBucket").doc(currentPaidID)
//         .set({ loanID, customerID, paidAmount, currentPaidID })
//         .then(() => {
//             console.log("current paid is saved to bucket");
//             //update current paid amount on loan bucket and user loan path
//
//         }).catch((error) => {
//         console.log("error in saving current paid to loan bucket");
//         console.log(error);
//     });
// }
//
// // function calculateManuallyPenalInterest() {
// //     const url = `${project.serverUrl}calculatePenalInterest`;
// //     fetch(url, {
// //         method: 'GET',
// //         mode: 'cors',
// //         headers: {'Content-Type': 'application/json'},
// //     }).then((response) => response.json())
// //         .then((response) => {
// //             console.log("penal interest calculated");
// //             console.log(response);
// //         }).catch((error) => {
// //         console.log("Here's your penal interest error");
// //         console.log(error);
// //     })
// // }
//
// function assignClearedLoanStatus({ customerID, loanID }) {
//     //fetch loan terms ids
//     //create loan terms obj
//     let loanTerms = {};
//
//     firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
//         .onSnapshot(function(querySnapshot) {
//             querySnapshot.forEach(function(doc) {
//                 // doc.data() is never undefined for query doc snapshots
//                 loanTerms[doc.id] = doc.data();
//             });
//         }, function (error) {
//             console.log("Error getting documents: ", error);
//         });
//
//     //loop over all loan terms
//     _.map(loanTerms, term => {
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
//             .update({ loanStatus: true, [`termStatus.status`]: true })
//             .then(() => {
//                 firebase.firestore().collection("loanTerms").doc(term.loanTermID)
//                     .update({ loanStatus: true, [`termStatus.status`]: true })
//                     .then(() => {
//                         //
//                     })
//                     .catch(e => {
//                         console.log(e);
//                     })
//             }).catch(e => console.log(e))
//     })
// }

export const fetchCashCollections = ({customerID, loanID}) => {

    return(dispatch) => {

        //fetch cash collections
        dispatch({ type: FETCH_CASH_COLLECTIONS });

        //create cash collections obj
        let cashCollections = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").where("loanID", "==", loanID)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    cashCollections[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_CASH_COLLECTIONS_SUCCESSFUL,
                    payload: cashCollections
                });
            }, function (error) {
                console.log("Error getting documents: ", error);
                dispatch({ type: FETCH_CASH_COLLECTIONS_FAILED });
            })

    };
};


export const updateCashCollection = ({ customerID, cashCollectionID,  bankName, bankDate }) => {

    //
    const lastEdit = new Date();

    return(dispatch) => {

        dispatch({ type: UPDATE_CASH_COLLECTION });
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
            .update({ bankName, bankDate, lastEdit })
            .then(() => {
                console.log("cash collection is updated to customer profile");
                // save loan term to term bucket
                updateCashCollectionToBucket({ bankName, bankDate, cashCollectionID, lastEdit, dispatch });
            })
            .catch(error => {
                console.log(error);
                console.log("cash collection is NOT updated to customer profile");
                dispatch({ type: UPDATE_CASH_COLLECTION_FAILED });
            })

    };
};


function updateCashCollectionToBucket({ bankName, bankDate, cashCollectionID, lastEdit, dispatch }) {

    firebase.firestore().collection("cashCollections").doc(cashCollectionID)
        .update({ bankName, bankDate, lastEdit })
        .then(() => {
            console.log("cash collection is updated to bucket");
            dispatch({ type: UPDATE_CASH_COLLECTION_SUCCESSFUL });
        })
        .catch(error => {
            console.log(error);
            console.log("cash collection is NOT updated to bucket");
            dispatch({ type: UPDATE_CASH_COLLECTION_FAILED });
        })
}

export const deleteCashCollection = ({ customerID, cashCollectionID, loanID, paidAmount }) => {

    return(dispatch) => {

        dispatch({ type: DELETE_CASH_COLLECTION });

        const data = JSON.stringify({
            customerID,
            loanID,
            paidAmount,
            currentPaidID: cashCollectionID
        });

        const url = `${project.serverUrl}deleteCashCollection`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                fetchCashCollectionAfterDeletion({customerID, loanID, dispatch});
                console.log("delete_cash_collection_successful");
                console.log(response);

            }).catch((error) => {
            dispatch({ type: DELETE_CASH_COLLECTION_FAILED });
            fetchCashCollectionAfterDeletion({customerID, loanID, dispatch});
            console.log("delete_cash_collection_successful");
            console.log(error);
        })

    };
};

function fetchCashCollectionAfterDeletion({customerID, loanID, dispatch}) {
    //fetch cash collections after successful deletion of cash collection
    //create cash collections obj
    let cashCollections = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").where("loanID", "==", loanID)
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                cashCollections[doc.id] = doc.data();
            });
            dispatch({ type: DELETE_CASH_COLLECTION_SUCCESSFUL });
            dispatch({
                type: FETCH_CASH_COLLECTIONS_SUCCESSFUL,
                payload: cashCollections
            });
        }, function (error) {
            console.log("Error getting documents: ", error);
            dispatch({ type: FETCH_CASH_COLLECTIONS_FAILED });
        })
}

// export const deleteCashCollection = ({ customerID, cashCollectionID, loanID, paidAmount }) => {
//
//     return(dispatch) => {
//
//         dispatch({ type: DELETE_CASH_COLLECTION });
//
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
//             .delete()
//             .then(() => {
//                 console.log("cash collection is deleted to customer profile");
//                 // save loan term to term bucket
//                 deleteCashCollectionToBucket({ customerID, cashCollectionID, loanID, paidAmount, dispatch });
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.log("cash collection is NOT deleted to customer profile");
//                 dispatch({ type: DELETE_CASH_COLLECTION_FAILED });
//             })
//
//     };
// };
//
//
// function deleteCashCollectionToBucket({ customerID, cashCollectionID, loanID, paidAmount, dispatch }) {
//
//     firebase.firestore().collection("cashCollections").doc(cashCollectionID)
//         .delete()
//         .then(() => {
//             console.log("cash collection is deleted to bucket");
//             computeCashPaymentAfterDeletion({customerID, loanID, cashCollectionID, paidAmount, dispatch});
//             dispatch({ type: DELETE_CASH_COLLECTION_SUCCESSFUL });
//         })
//         .catch(error => {
//             console.log(error);
//             console.log("cash collection is NOT deleted to bucket");
//             dispatch({ type: DELETE_CASH_COLLECTION_FAILED });
//         })
// }
//
// function computeCashPaymentAfterDeletion({ customerID, loanID, cashCollectionID, paidAmount, dispatch }) {
//
//     console.log(customerID, loanID, paidAmount);
//     firebase.firestore().collection("loans").doc(loanID).get()
//         .then((doc) => {
//             if (doc.exists) {
//                 const data = doc.data();
//
//                 const oldTotalCashPaid = data.totalCashPaid;
//                 //calculate total paid
//                 const oldTotalPaid = data.totalPaid;
//
//                 //add paid amount to old total cash paid
//                 const totalCashPaid = oldTotalCashPaid - paidAmount;
//
//                 //add paid amount to old total paid
//                 const totalPaid = oldTotalPaid - paidAmount;
//
//                 //check if loan has been cleared or not
//                 let loanStatus = false;
//                 const totalAmount = data.totalAmount;
//                 //find if there are extra fees and their total
//                 let totalArray = [];
//                 if ("extraFees" in data) {
//                     //there are extra fees, loop over each extra fee and put amount into amountArray
//                     _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
//                 }
//                 let totalFeesAmount = 0;
//                 //calculate the total amount from numbers in the array
//                 //check that total array is not empty
//                 if (totalArray.length > 0) {
//                     totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
//                 }
//                 const debt = totalAmount + totalFeesAmount;
//                 const clearedAmount = debt - totalPaid;
//                 //if clearedAmount is below zero then debt is cleared
//                 if (clearedAmount <= 0) {
//                     loanStatus = true;
//                 }
//
//                 //extract modulo
//                 const modulo = data.modulo;
//
//                 //post to loan profile on loan bucket and customer profile
//                 firebase.firestore().collection("loans").doc(loanID).update({ totalCashPaid, totalPaid, loanStatus })
//                     .then(() => {
//                         console.log("calculations saved to bucket");
//                         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                             .update({ totalCashPaid, totalPaid, loanStatus })
//                             .then(() => {
//                                 console.log("calculations saved to loan in profile");
//                                 //remove modulo or current paid from loan and loan term
//                                 computeModuloAndCurrentPaid({ customerID, loanID, cashCollectionID, paidAmount, dispatch });
//
//                                 //removeLoanModulo({ loanID, paidAmount, modulo, customerID });
//
//                                 //fetch cash collections after successful deletion of cash collection
//                                 //create cash collections obj
//                                 let cashCollections = {};
//
//                                 firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").where("loanID", "==", loanID)
//                                     .onSnapshot(function(querySnapshot) {
//                                         querySnapshot.forEach(function(doc) {
//                                             // doc.data() is never undefined for query doc snapshots
//                                             cashCollections[doc.id] = doc.data();
//                                         });
//
//                                         dispatch({
//                                             type: FETCH_CASH_COLLECTIONS_SUCCESSFUL,
//                                             payload: cashCollections
//                                         });
//                                     }, function (error) {
//                                         console.log("Error getting documents: ", error);
//                                         dispatch({ type: FETCH_CASH_COLLECTIONS_FAILED });
//                                     })
//
//
//                             }).catch((error) => {
//                             console.log("error in saving calculations to loan in profile");
//                             console.log(error);
//                         });
//                     }).catch((error) => {
//                     console.log("error in saving calculations to loan in bucket");
//                     console.log(error);
//                 });
//
//             }
//         }).catch(e => e);
// }

export const computeModuloAndCurrentPaid = ({ customerID, loanID, cashCollectionID, paidAmount, dispatch }) => {

    dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID });

    const data = JSON.stringify({
        customerID,
        loanID,
        paidAmount,
        currentPaidID: cashCollectionID
    });

    const url = `${project.serverUrl}computeModuloAndCurrentPaid`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL });
            console.log("compute_modulo_and_current_paid_successful");
            console.log(response);

        }).catch((error) => {
        dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_FAILED });
        console.log("compute_modulo_and_current_paid_successful");
        console.log(error);
    })
};

// function removeLoanModulo({ loanID, paidAmount, modulo, customerID }) {
//
//     //compute remaining modulo
//     const remModulo = modulo - paidAmount;
//
//     let updates = {};
//
//     //check if remaining modulo is positive or negative
//     if (remModulo > 0) {
//         //its positive
//         updates["modulo"] = remModulo;
//     } else {
//         //its negative
//         updates["modulo"] = firebase.firestore.FieldValue.delete()
//     }
//
//     //remove from loan bucket
//     firebase.firestore().collection("loans").doc(loanID)
//         .update(updates)
//         .then(() => {
//             //remove from loan user path
//             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                 .update(updates)
//                 .then(() => {
//                     //remove from loanTerms
//                     removeLoanTermModulo({ loanID, updates, customerID });
//                 })
//                 .catch(e => {
//                     console.log(e);
//                 })
//         })
//         .catch(e => {
//             console.log(e);
//         })
//
// }

// function removeLoanTermModulo({ loanID, updates, customerID }) {
//     //fetch loanTerms
//     //create loan terms obj
//     let loanTerm;
//
//     firebase.firestore().collection("loanTerms").where("loanID", "==", loanID)
//         .onSnapshot(function(querySnapshot) {
//             querySnapshot.forEach(function(doc) {
//                 // doc.data() is never undefined for query doc snapshots
//
//                 const data = doc.data();
//
//                 if ("modulo" in data) {
//                     loanTerm = data
//                 }
//             });
//
//             //check that we have a loanTerm
//             if (loanTerm) {
//                 //remove modulo
//
//                 //remove from loan bucket
//                 firebase.firestore().collection("loanTerms").doc(loanTerm.loanTermID)
//                     .update(updates)
//                     .then(() => {
//                         //remove from loanTerm user path
//                         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTerm.loanTermID)
//                             .update(updates)
//                             .then(() => {
//
//                             })
//                             .catch(e => {
//                                 console.log(e);
//                             })
//                     })
//                     .catch(e => {
//                         console.log(e);
//                     })
//
//             }
//
//
//         }, function (error) {
//             console.log("Error getting documents: ", error);
//
//         })
// }


export const updateMigrationCleared = () => {
  //
  return() => {
      firebase.firestore().collection("loanTerms").where("cheque", "==", false).where("migrate", "==", true)
          .onSnapshot(function (querySnapshot) {
             if (querySnapshot.size !== 0){
                 querySnapshot.forEach(doc => {
                     const data = doc.data();
                     const customerID = data.customerID;
                     const loanTermID = data.loanTermID;

                     if ("migrationCleared" in data){

                     } else {
                         //write
                         writeMigrationCleared({customerID, loanTermID});
                     }
                 })
             }
          })
  }
};

function writeMigrationCleared({customerID, loanTermID}) {
    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
        .update({migrationCleared: true})
        .then(() => {
            //
            firebase.firestore().collection("loanTerms").doc(loanTermID)
                .update({migrationCleared: true})
                .then(() => {

                    console.log({customerID: customerID});

                })
                .catch(e => {console.log(e)})

        })
        .catch(e => {console.log(e)})
}