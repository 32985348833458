import React from 'react';
import {Modal, message, Popconfirm, Button} from 'antd';
import moment from "moment";
import { connect } from 'react-redux';
import _ from 'lodash';

//action imports
import { hideEarlyLiquidation, earlyLiquidationChanged, saveEarlyLiquidation, reverseLiquidation } from '../../../../actions/LoanActions';

//component imports
import {Input} from '../../../common/Input';

class EarlyLiquidationCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: this.props.visible,
            earlyLiquidation: this.props.earlyLiquidation,
            earlyLiquidationSaved: this.props.earlyLiquidationSaved,
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
        };

        this.onSaveEarlyLiquidation= this.onSaveEarlyLiquidation.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }

        if (nextProps.earlyLiquidation !== this.props.earlyLiquidation) {
            this.setState({earlyLiquidation: nextProps.earlyLiquidation})
        }

        if (nextProps.earlyLiquidationSaved !== this.props.earlyLiquidationSaved) {
            this.setState({earlyLiquidationSaved: nextProps.earlyLiquidationSaved})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms});
        }
    }

    handleOk = () => {
        this.props.hideEarlyLiquidation();
    };

    handleCancel = () => {
        this.props.hideEarlyLiquidation();
    };

    confirmReverse() {
        ///extract state
        const { currentLoan, currentLoanTerms } = this.state;
        const { customerID, loanID } = currentLoan;

        //proceed with reverse process
        this.props.reverseLiquidation({ customerID, loanID, loanTerms: currentLoanTerms });
    }

    cancelReverse = () => {
        message.info('Canceled');
    };

    onSaveEarlyLiquidation = () => {
        //extract state
        const { earlyLiquidation, currentLoan, currentLoanTerms } = this.state;

        //extract variables from current loan
        const { loanID, customerID, totalCashPaid, totalChequePaid } = currentLoan;

        //check that currentLoanTerms is not empty
        if(!(_.isEmpty(currentLoanTerms))) {
            //filter loan terms that are not passed today
            const loanTerms = _.map(currentLoanTerms, term => term);

            if (earlyLiquidation && loanID && customerID) {
                this.props.saveEarlyLiquidation({ customerID, loanID, earlyLiquidation: parseInt(earlyLiquidation), loanTerms });
            } else {
                message.warning("Kindly enter custom supposed to pay");
            }
        }
    };

    render(){
        return (
            <div>
                <Modal
                    title="Early Liquidation"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={800}
                >
                    <div className={"modalBox"}>
                        <Input 
                            label={'Enter Custom Suppose to pay'}
                            type={'number'}
                            onChange={e => this.props.earlyLiquidationChanged(e.target.value)}
                            value={this.state.earlyLiquidation}
                        />

                        <Button onClick={() => this.onSaveEarlyLiquidation()}>Save</Button>
                        <div>
                            <Popconfirm
                                title="Are you sure to reverse early liquidation?"
                                onConfirm={() => this.confirmReverse() }
                                onCancel={() => this.cancelReverse() }
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button>Reverse</Button>
                            </Popconfirm>
                        </div>
                    </div>

                    <div>
                        {
                            this.state.earlyLiquidationSaved?
                                <p>{message.success("Early liquidation is saved")}</p>: null
                        }
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    
    const { earlyLiquidation, earlyLiquidationSaved, currentLoan, currentLoanTerms } = state.loans;

    return{
        earlyLiquidation,
        earlyLiquidationSaved,
        currentLoan,
        currentLoanTerms
    }
};

export default connect(mapStateToProps, { hideEarlyLiquidation, earlyLiquidationChanged, saveEarlyLiquidation, reverseLiquidation  }) (EarlyLiquidationCard);
