import React from "react";
import {connect} from "react-redux";

//actions import
import {customerDetailsChanged, addNewCustomer } from "../../../actions/CustomerActions";

//components import
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import {message} from "antd";
import {BeatLoader,} from "react-spinners";


class AddNewCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerName: this.props.customerName,
            customerID: this.props.customerID,
            saveCustomerLoading: this.props.saveCustomerLoading,
            customerSaved: this.props.customerSaved,
            profile: this.props.profile,
            saveCustomerFailed: this.props.saveCustomerFailed,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.customerName !== this.props.customerName){
            this.setState({customerName: nextProps.customerName})
        }

        if (nextProps.customerID !== this.props.customerID){
            this.setState({customerID: nextProps.customerID})
        }

        if (nextProps.saveCustomerLoading !== this.props.saveCustomerLoading){
            this.setState({saveCustomerLoading: nextProps.saveCustomerLoading})
        }

        if (nextProps.customerSaved !== this.props.customerSaved){
            this.setState({customerSaved: nextProps.customerSaved})
        }

        if (nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.saveCustomerFailed !== this.props.saveCustomerFailed){
            this.setState({saveCustomerFailed: nextProps.saveCustomerFailed})
        }
    }


    renderMessage() {
        const {customerSaved} = this.state;

        if (customerSaved) {
            return <p>{message.success("A new customer is saved")}</p>
        } else {
            return null;
        }
    }

    renderFailedMessage() {
            const {saveCustomerFailed} = this.state;

            if (saveCustomerFailed) {
                return <p>{message.error("Sorry! New customer is not saved")}</p>
            } else {
                return null;
            }
        }

    renderButton() {
        const {saveCustomerLoading} = this.state;

        if (saveCustomerLoading) {
            return <BeatLoader/>
        } else {
            return (
                <div className={"newCustomerModalButtonBox"}>
                    <Button
                        text={'Save'}
                        onPress={()=> this.onSave()}
                    />
                </div>
            );
        }
    }

    onSave() {
        const {customerName, customerID, profile } = this.state;

        const access = profile.userAccess.some(access => access === "clientsUpdates");

        if (access) {
            //check user access
            if (customerName && customerID) {
                const totalCashPaid = 0;
                const totalChequePaid = 0;
                const totalOutstanding = 0;
                const totalOverdue = 0;
                //save to own path and client bucket
                this.props.addNewCustomer({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue })
            } else {
                message.warning('Fill all the required fields');
            }
        } else {
            message.warning("You're not authorized to create a new user");
        }

    }

    render() {
        return (
            <div className={"newCustomerModalContainer"}>
                <div className={"newCustomerModalHeaderBox"}>
                    <h2>Customer Details</h2>
                    <h4>Please enter Customer details</h4>
                </div>
                <div>
                    <Input
                        // label={'Customer name'}
                        placeholder={"Customer name"}
                        type={'text'}
                        onChange={e => this.props.customerDetailsChanged({prop: 'customerName', value: e.target.value})}
                        value={this.props.customerName}
                    />
                </div>
                <div>
                    <Input
                        // label={'Customer ID'}
                        placeholder={"Customer ID"}
                        type={'text'}
                        onChange={e => this.props.customerDetailsChanged({prop: 'customerID', value: e.target.value})}
                        value={this.props.customerID}
                    />
                </div>
                {this.renderButton()}
                {this.renderMessage()}
                {this.renderFailedMessage()}
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const {customerName, customerID, saveCustomerLoading, customerSaved, saveCustomerFailed} = state.customer;
    const {profile} = state.profile;

    //return object with state properties
    return {
        customerName,
        customerID,
        saveCustomerLoading,
        customerSaved,
        profile,
        saveCustomerFailed,
    }
};

export default connect(mapStateToProps,{customerDetailsChanged, addNewCustomer })(AddNewCustomer);