import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Modal, Button, message, Popconfirm } from 'antd';
import moment from "moment";
import arraySort from 'array-sort';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

//action imports
import { commentsChanged, saveComments, fetchComments, commentsDateRangeChanged, fetchRangedComments, collectorChanged,
    hideComments, clearPage, deleteComment } from "../../../actions/MasterListActions";

//component imports
import SelectCollector from "../../common/SelectCollector";
import {TextArea} from "../../common/TextArea";
import {DateRange} from "../../common/DateRange";
import {BeatLoader} from "react-spinners";


class CommentCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,

            loan: this.props.loan,
            loanTerm: this.props.loanTerm,

            admins: this.props.admins,
            visible: this.props.visible,

            comments: this.props.comments,
            commentsDateRange: this.props.commentsDateRange,
            fetchedComment: this.props.fetchedComment,
            commentsLoading: this.props.commentsLoading,

            commentsFetched: false,

            collector: this.props.collector,

            commentsSaveLoading: this.props.commentsSaveLoading,
            deleteCommentLoading: this.props.deleteCommentLoading
        };

        this.renderTime = this.renderTime.bind(this);
        this.renderCollector = this.renderCollector.bind(this);
        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.saveComment = this.saveComment.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({ visible: nextProps.visible })
        }

        if (nextProps.loan !== this.props.loan) {
            this.setState({ loan: nextProps.loan })
        }

        if (nextProps.loanTerm !== this.props.loanTerm) {
            this.setState({ loanTerm: nextProps.loanTerm })
        }

        if (nextProps.admins !== this.props.admins) {
            this.setState({ admins: nextProps.admins })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.comments !== this.props.comments) {
            this.setState({ comments: nextProps.comments })
        }

        if (nextProps.commentsDateRange !== this.props.commentsDateRange) {
            this.setState({ commentsDateRange: nextProps.commentsDateRange })
        }

        if (nextProps.deleteCommentLoading !== this.props.deleteCommentLoading) {
            this.setState({ deleteCommentLoading: nextProps.deleteCommentLoading })
        }

        if (nextProps.fetchedComment !== this.props.fetchedComment) {
            this.setState({ fetchedComment: nextProps.fetchedComment })
        }

        if (nextProps.commentsLoading !== this.props.commentsLoading) {
            this.setState({ commentsLoading: nextProps.commentsLoading })
        }

        if (nextProps.collector !== this.props.collector) {
            this.setState({ collector: nextProps.collector })
        }

        if (nextProps.commentsSaveLoading !== this.props.commentsSaveLoading) {
            this.setState({ commentsSaveLoading: nextProps.commentsSaveLoading })
        }
    }

    // componentWillMount() {
    //     //extract state
    //     const { loanID } = this.state.loan;
    //     const {loanTermID} = this.state.loanTerm;
    //     this.props.fetchComments({loanID,loanTermID})
    // }

    componentWillUnmount() {
        this.props.clearPage()
    }

    // handleOk = () => {
    //     this.props.hideComments();
    // };
    //
    // handleCancel = () => {
    //     this.props.hideComments();
    // };

    onDateRangeChange(date){
        this.props.commentsDateRangeChanged(date)
    }

    onClear() {
        //extract state
        const {loanID} = this.state.loanTerm;

        this.setState({ commentsFetched: !this.state.commentsFetched, collector: {}, commentsDateRange: "" });
        this.props.fetchComments({loanID});
    }

    renderTime(comment) {

        if (!(_.isEmpty(comment))) {
            let seconds;
            comment.timeStamp._seconds ? seconds = comment.timeStamp._seconds : seconds = comment.timeStamp.seconds;
            const time = moment.unix(seconds);
            const displayTime = time.format("HH:mm | ddd, DD/MMM/YYYY");

            return <p className={"commentDisplayTime"}>{displayTime}</p>
        }
    }

    renderCollector(comment) {
        //extract state
        const { admins } = this.state;

        if (!(_.isEmpty(comment))) {
            //extract collector ID
            const adminInArray = admins.filter(admin => admin.userID === comment.collectorID);

            const admin = adminInArray[0];

            return <p className={"commentDisplayName"}>{admin.name}</p>
        }
    }

    renderSaveButton() {
        //extract state
        const { commentsSaveLoading } = this.state;

        if (commentsSaveLoading) {
            return <BeatLoader/>
        } else {
            return(
                <Button type="default" onClick={() => this.saveComment() }>
                    Save
                </Button>
            );
        }
    }

    saveComment() {
        //extract state
        const { comments, profile, loan, loanTerm } = this.state;

        const access = profile.userAccess.some(access => access === "masterListUpdates");

        if (access) {

            const collectorID = profile.userID;
            const loanID = loanTerm.loanID;
            const loanTermID = loanTerm.loanTermID;

            const now = moment();
            const ID = moment(now).unix();
            const commentID = ID.toString();

            if (commentID && comments && collectorID && loanID && loanTermID) {
                this.props.saveComments({ commentID, comments, collectorID, loanID, loanTermID });
            } else {
                message.warning("Fill in a comment");
            }
        } else {
            message.warning("You have no authorization to save a comment");
        }
    }

    onFetchRangedComments() {
        //extract state
        const { loan, loanTerm, commentsDateRange, collector  } = this.state;

        if (!(_.isEmpty(commentsDateRange))) {
            //check if collector is not empty
            if (!(_.isEmpty(collector))) {
                //extract ID
                const collectorObj = JSON.parse(collector);
                const collectorID = collectorObj.userID;
                this.setState({ commentsFetched: true });
                this.props.fetchRangedComments({ loanID: loanTerm.loanID, loanTermID: loanTerm.loanTermID, dateRange: commentsDateRange, collectorID })
            } else {
                const collectorID = "";
                this.setState({ commentsFetched: true });
                this.props.fetchRangedComments({ loanID: loanTerm.loanID, loanTermID: loanTerm.loanTermID, dateRange: commentsDateRange, collectorID })
            }

        } else {
            message.warning("Please fill in a date range")
        }
    }

    cancelDelete = () => {
        message.info('Deletion is canceled');
    };


    onDelete = (loanTerm) => {
        const commentID = loanTerm.masterListComment.commentID;
        const loanID = loanTerm.loanID;

        this.props.deleteComment({ loanID, commentID });
    }

    renderComment() {
        //extract props
        const { loanTerm } = this.state;
        //make sure object is not empty
        if ("masterListComment" in loanTerm) {
            return (
                <div className={"commentModal"}>
                    <h3>Comments</h3>
                    <div className={"viewServiceChargeValue"}>
                        <h4>{loanTerm.masterListComment.newComment}</h4>
                        {
                            this.state.deleteCommentLoading ?
                            <div style={{textAlign: 'center'}}>
                                <BeatLoader/>
                            </div> :
                            <div style={{textAlign: 'center'}}>
                                <Popconfirm
                                    title="Are you sure to delete this comment?"
                                    onConfirm={() => this.onDelete(loanTerm)}
                                    onCancel={() => this.cancelDelete()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                </Popconfirm>
                            </div>
                        }
                    </div>
                </div>
            );
        }else{
            return <h3 className={"userAdminsEmptyWarning"}>There are no comment</h3>
        }
    }


    render() {

        //extract state
        const { commentsDateRange, commentsFetched, collector, } = this.state;


        let collectorValue;
        if (!(_.isEmpty(collector))) {
            //collector is not empty
            collectorValue = collector;
        } else {
            //collector is empty
            collectorValue = "";
        }

        return (
            <div>
                <div className={"commentBox"}>
                        <TextArea
                            label={'Post a comment'}
                            onChange={e => this.props.commentsChanged(e.target.value)}
                            value={this.state.comments}
                        />
                        { this.renderSaveButton() }
                    </div>
                <div className={"renderComment"}>
                    <div>{ this.renderComment() }</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {

    //fetch state properties from specific reducer
    const { admins } = state.app;
    const { profile } = state.profile;
    const { comments, commentsDateRange, fetchedComment, deleteCommentLoading, commentsLoading, collector, commentsSaveLoading } = state.masterList;

    return {
        admins,
        profile,
        comments,
        commentsDateRange,
        fetchedComment,
        commentsLoading,
        collector,
        commentsSaveLoading,
        deleteCommentLoading
    }
};

export default connect(mapStateToProps, { commentsChanged, deleteComment, saveComments, fetchComments, commentsDateRangeChanged, fetchRangedComments, collectorChanged
    ,hideComments, clearPage
})(CommentCard);


