import React from 'react';
import { Select } from 'antd';
import 'antd/dist/antd.css';


class UserRole extends React.Component {


    render() {
        const { Option } = Select;

        return (
            <div>
                <div className={"selectRoleLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 295 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value={"creditManager"}>Credit Manager</Option>
                    <Option value={"collectionsManager"}>Collections Manager</Option>
                    <Option value={"collector"}>Collector</Option>
                    <Option value={"accountant"}>Accountant</Option>
                </Select>

            </div>
        );
    }
}


export default (UserRole);
