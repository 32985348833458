import React from 'react';
import {
    BounceLoader,
} from "react-spinners";

export class Spinner extends React.Component {

    render() {
        return(
            <BounceLoader/>
        );
    }

}