import * as firebase from 'firebase';
import moment from "moment";

import {
    SERVICE_CHARGE_DETAILS_CHANGED,
    SAVE_SERVICE_CHARGE_DETAILS_SUCCESSFUL,
    SAVE_SERVICE_CHARGE_DETAILS_FAILED,
    SERVICE_CHARGE_DATE_CHANGED,
    SAVE_SERVICE_CHARGE_DETAILS,

    FETCH_SERVICE_CHARGES,
    FETCH_SERVICE_CHARGES_SUCCESSFUL,
    FETCH_SERVICE_CHARGES_FAILED,
    DELETE_SERVICE_CHARGE,
    DELETE_SERVICE_CHARGE_SUCCESSFUL, DELETE_SERVICE_CHARGE_FAILED, CLEAR_SERVICE_CHARGE_MESSAGE
} from './Types';

export const serviceChargeDetailsChanged = ({prop, value}) => {
    return{
        type: SERVICE_CHARGE_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const serviceChargeDateChanged = (date) => {
    return{
        type: SERVICE_CHARGE_DATE_CHANGED,
        payload: date
    }
};


export const saveServiceCharge = ({serviceChargePaidAmount, serviceChargeBankName, serviceChargeDate, serviceChargeComments, currency }) => {
    
    const ID = moment().unix();
    const serviceChargeID = ID.toString();

    return(dispatch) => {
        dispatch({ type: SAVE_SERVICE_CHARGE_DETAILS });
        firebase.firestore().collection("serviceCharges").doc(serviceChargeID)
            .set({ serviceChargePaidAmount, serviceChargeBankName, serviceChargeDate, serviceChargeComments, serviceChargeID, currency})
            .then(() => {
                //fetch service charges
                callServiceCharges({dispatch});
                dispatch({ type: SAVE_SERVICE_CHARGE_DETAILS_SUCCESSFUL });
                console.log("service charge is saved");
                dispatch({ type: CLEAR_SERVICE_CHARGE_MESSAGE });
            })
            .catch(error => {
                console.log(error);
                console.log("service charge is not saved");
                dispatch({ type: SAVE_SERVICE_CHARGE_DETAILS_FAILED });
            })
    };
};

export const fetchServiceCharges = () => {
    return(dispatch) => {
        callServiceCharges({dispatch});
        dispatch({ type: CLEAR_SERVICE_CHARGE_MESSAGE });
    };
};

export const deleteServiceCharge = (serviceChargeID) => {

    return(dispatch) => {

        dispatch({type: DELETE_SERVICE_CHARGE});
        firebase.firestore().collection("serviceCharges").doc(serviceChargeID)
            .delete()
            .then(() => {
                //fetch unidentified payments
                callServiceCharges({dispatch});
                dispatch({ type: DELETE_SERVICE_CHARGE_SUCCESSFUL });
                console.log("service charge is deleted to bucket");
                dispatch({ type: CLEAR_SERVICE_CHARGE_MESSAGE });
            })
            .catch(error => {
                console.log(error);
                console.log("service charge is NOT deleted to bucket");
                dispatch({ type: DELETE_SERVICE_CHARGE_FAILED });
            })
    }

};


function callServiceCharges({dispatch}){

    dispatch({ type: FETCH_SERVICE_CHARGES });

    //create service charges payments obj
    let serviceChargesPayments = {};

    firebase.firestore().collection("serviceCharges")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                //add service charges to object
                serviceChargesPayments[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_SERVICE_CHARGES_SUCCESSFUL,
                payload: serviceChargesPayments
            });

        }, function (error) {
            console.log('fetching service charges failed');
            console.log(error);

            dispatch({
                type: FETCH_SERVICE_CHARGES_FAILED
            });
        })
}


