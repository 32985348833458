import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Modal, message } from 'antd';

//actions
import { numberOfLoansToAssignChanged, collectorChanged, assignCollector, hideUnAssignedLoans } from "../../../actions/MasterListActions";

//component imports
//import {Spinner} from "../../common/Spinner";
import {Button} from "../../common/Button";
import SelectCollector from "../../common/SelectCollector";
import {Input} from "../../common/Input";
import {BeatLoader} from "react-spinners";
import arraySort from "array-sort";


class UnAssignedLoans extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            bouncedCheques: this.props.bouncedCheques,
            numberOfLoans: this.props.numberOfLoans,
            collector: this.props.collector,
            saveAssigneeLoading: this.props.saveAssigneeLoading,
        };

        this.handleUnassignedLoans = this.handleUnassignedLoans.bind(this);
        this.pickNumberAndCollector = this.pickNumberAndCollector.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({ visible: nextProps.visible })
        }

        if (nextProps.bouncedCheques !== this.props.bouncedCheques) {
            this.setState({ bouncedCheques: nextProps.bouncedCheques })
        }

        if (nextProps.numberOfLoans !== this.props.numberOfLoans) {
            this.setState({ numberOfLoans: nextProps.numberOfLoans })
        }

        if (nextProps.collector !== this.props.collector) {
            this.setState({ collector: nextProps.collector })
        }

        if (nextProps.saveAssigneeLoading !== this.props.saveAssigneeLoading) {
            this.setState({ saveAssigneeLoading: nextProps.saveAssigneeLoading })
        }
    }



    handleOk = () => {
        this.props.hideUnAssignedLoans()
    };

    handleCancel = () => {
        this.props.hideUnAssignedLoans()
    };

    onCollectorSelect(value) {
        //
        this.props.collectorChanged(value);
    }

    onAssignCollector({ loans }) {
        //extract state
        const { collector, numberOfLoans } = this.state;

        //check collector is not empty
        if (!(_.isEmpty(collector))) {
            //collector is not empty
            //check that number of loans is not 0
            if (numberOfLoans !== 0) {
                //check if number of loans is not bigger than the number loans available to be assigned
                if (numberOfLoans <= loans.length) {
                    //grab customer ID from first loan term in terms array
                    //check that there is at least one cheque in the terms array
                    if (loans.length !== 0) {
                        //[{}, {}]
                        //sort loans
                        const sortedCheques = arraySort(loans, 'customerName');
                        //pick the number of loans chosen by admin
                        const selectedLoans = sortedCheques.slice(0, numberOfLoans);

                        // //loop over the loans individually and assign the collector
                        // selectedLoans.map(loan => {
                        //
                        //     const terms = loan.terms;
                        //     //grab the first cheque and take the customer ID
                        //     const customerID = terms[0].customerID;
                        //     //send to firebase with action
                        //     this.props.assignCollector({ customerID, terms, assignee: JSON.parse(collector) });
                        // });

                        this.props.assignCollector({ selectedLoans, assignee: JSON.parse(collector) });

                    }
                } else {
                    message.warning("Number of loans chosen is bigger than the number of loans available");
                }
            } else {
                message.warning("Kindly pick number of loans you want to assign a collector");
            }
        } else {
            message.warning("Kindly select a collector");
        }
    }

    renderButton(loansArray) {
        return(
           <div>
               {
                   this.state.saveAssigneeLoading?
                       <BeatLoader/>:
                       <Button
                           text={'Assign Collector'}
                           onPress={() => this.onAssignCollector({ loans: loansArray })}
                       />
               }
           </div>
        );
    }

    pickNumberAndCollector() {
        //extract state
        const { numberOfLoans, collector } = this.state;

        //sort out collector
        let collectorValue;
        if (!(_.isEmpty(collector))) {
            //collector is not empty
            collectorValue = collector;
        } else {
            //collector is empty
            collectorValue = "";
        }

        return (
            <div>
                <div className={"modalBox"}>
                    <Input
                        label={'Loans To Assign'}
                        type={'number'}
                        onChange={e => this.props.numberOfLoansToAssignChanged(e.target.value)}
                        value={numberOfLoans}
                    />
                </div>
                <div className={"modalBox"}>
                    <SelectCollector
                        value={collectorValue}
                        label={"Select Collector"}
                        onChange={(value) => this.onCollectorSelect(value) }
                    />
                </div>
            </div>
        )
    }

    handleUnassignedLoans() {
        //extract state
        const { bouncedCheques } = this.state;

        //check that bounced cheques is not empty
        if (!(_.isEmpty(bouncedCheques))) {
            //we have cheques in it
            //find unassigned loans
            //put terms of the same loan into one object
            // [{}, {}]
            let loans = {};

            _.map(bouncedCheques, client => {
                //extract loans from client
                client.values.map(cheque => {
                    // check if cheque is already assigned
                    if ("assignee" in cheque) {
                        //do nothing
                    } else {
                        //check if loan already exists in loan object or not
                        if (`${cheque.loanID}` in loans) {
                            //loan already exists
                            let termsArray = loans[`${cheque.loanID}`].terms;
                            termsArray.push(cheque);

                            //set the loan properties
                            loans[`${cheque.loanID}`].terms = termsArray;
                        } else {
                            //this cheque belongs to a new loan
                            //initialize new loan and put terms in it
                            loans[`${cheque.loanID}`] = {
                                loanID: cheque.loanID,
                                customerName: cheque.customerName,
                                terms: [cheque],
                            }
                        }
                    }
                });
            });

            //check that we have unassigned loans
            if (!(_.isEmpty(loans))) {

                //we have unassigned loans
                //find the number of un assigned loans
                const loansArray = _.map(loans, loan => loan);

                const numberOfLoans = loansArray.length;

                return (
                    <div className={"unassignedModalBox"}>
                        <h2>{numberOfLoans} unassigned loan(s)</h2>
                        <h3>Pick number of loans to assign to a collector</h3>

                        { this.pickNumberAndCollector() }

                        <div className={"unassignedModalBoxButton"}>{ this.renderButton(loansArray) }</div>
                    </div>
                );
            } else {
                return <h3 className={"userAdminsEmptyWarning"}>All loans have been assigned to a collector</h3>
            }
        } else {
            return <h3 className={"userAdminsEmptyWarning"}>There's no loan to assign</h3>
        }

    }


    render() {

        return (
            <div className={"unsignedChequesBox"}>
                <Modal
                    title="Un Assigned loans"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    { this.handleUnassignedLoans() }
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { collector, bouncedCheques, numberOfLoans, saveAssigneeLoading, loading } = state.masterList;

    return {
        collector,
        bouncedCheques,
        numberOfLoans,
        saveAssigneeLoading,
        loading
    }

};

export default connect(mapStateToProps, { numberOfLoansToAssignChanged, collectorChanged, assignCollector, hideUnAssignedLoans })(UnAssignedLoans);
