import axios from 'axios';
import {
    CLEAR_MASTER_FILE_MESSAGES,
    UPLOAD_MASTER_FILE,
    UPLOAD_MASTER_FILE_FAILED,
    UPLOAD_MASTER_FILE_SUCCESSFUL
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');

let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


export const uploadMasterFile = (data) => {
    return(dispatch) => {
        console.log(data)
        dispatch({type: UPLOAD_MASTER_FILE});
        const url = `${project.serverUrl}importMasterFile`;
        axios.post(url, data, { // receive two parameter endpoint url ,form data
        })
        .then(res => { // then print response status
            dispatch({type: UPLOAD_MASTER_FILE_SUCCESSFUL});
            console.log('file upload complete');
            console.log(res.statusText);
            dispatch({type: CLEAR_MASTER_FILE_MESSAGES})
        })
        .catch(function (error) {
            dispatch({type: UPLOAD_MASTER_FILE_FAILED});
            console.log("there's an error uploading the file");
            console.log(error);
            dispatch({type: CLEAR_MASTER_FILE_MESSAGES});
        });
    }
};

export const migrateMasterFile = (data) => {
    return(dispatch) => {

        dispatch({type: UPLOAD_MASTER_FILE});
        const url = `${project.serverUrl}migrateMasterFile`;
        console.log({ url });
        axios.post(url, data, {
            // receive two parameter endpoint url ,form data
        })
            .then(res => { // then print response status
                dispatch({type: UPLOAD_MASTER_FILE_SUCCESSFUL});
                console.log('file upload complete');
                console.log(res.statusText);
                dispatch({type: CLEAR_MASTER_FILE_MESSAGES});
            })
            .catch(function (error) {
                dispatch({type: UPLOAD_MASTER_FILE_SUCCESSFUL});
                console.log("there's an error uploading the file");
                console.log(error);
                dispatch({type: CLEAR_MASTER_FILE_MESSAGES});
            });

    }
};

export const clearMessages = () => {

    return {
        type: CLEAR_MASTER_FILE_MESSAGES,
    }
};