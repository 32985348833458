import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Modal} from "antd";
import moment from "moment";

//actions import

//components import
import DistinctCustomersCard from "./DistinctCustomersCard";

import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";

class DashBoardCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            date: this.props.date,
            systemInfo: this.props.systemInfo,
            localCurrency: "TSH",
        }

        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillMount() {
        this.checkCurrencyFormat();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.date !== this.props.date) {
            this.setState({ date: this.props.date });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: this.props.systemInfo });
        }
    }

    
    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }


    showModal = () => {
        this.setState({visible: true});
    };

    handleOk = () => {
        this.setState({visible: false});
    };

    handleCancel = () => {
        this.setState({visible: false});
    };

    renderDate() {
        //extract state
        const { date } = this.state.date;

        const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

        return <p>{endOfMonth}</p>;
    }

    renderDebitAmountLCY() {
        //extract state
        const { date, allBeforeTerms } = this.state.date;
        const { localCurrency } = this.state;

        //create the terms amount store
        let store = [];

        //take all terms with tzs
        allBeforeTerms.map(term => {
            //filter if they are cleared or not
            if (term.cheque) {
                if (term.chequeStatus === "bounced") {
                    let seconds;
                    if ("transactionDate" in term) {
                        if (term.transactionDate) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                        }
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                    }
                    const termDueDate = moment.unix(seconds);
                    if (termDueDate.isSame(date, "months")) {
                        if (term.currency !== "usd") {
                            store.push(term.amount);
                        }
                    }
                }
            } else {
                //check that dueDate month is similar to dueDate month in date object
                let seconds;
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if ("termStatus" in term) {
                        if (!term.termStatus.status) {
                            if (term.termStatus.penalInterest > 0) {
                                if (term.currency !== "usd") {
                                    store.push(term.amount);
                                }
                            }
                        }
                    }
                }
            }
        });

        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);

        return <div><p>{this.displayCurrency({ value: total, currency: localCurrency })}</p></div>;
    }

    renderDebitAmountUSD() {
        //extract state
        const { date, allBeforeTerms } = this.state.date;

        //create the terms amount store
        let store = [];

        //take all terms with tzs
        allBeforeTerms.map(term => {
            //filter if they are cleared or not
            if (term.cheque) {
                if (term.chequeStatus === "bounced") {
                    let seconds;
                    if ("transactionDate" in term) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                    }
                    const termDueDate = moment.unix(seconds);
                    if (termDueDate.isSame(date, "months")) {
                        if (term.currency === "usd") {
                            store.push(term.amount);
                        }
                    }
                }
            } else {
                //check that dueDate month is similar to dueDate month in date object
                let seconds;
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    //check if term has penal interest
                    if ("termStatus" in term) {
                        if (!term.termStatus.status) {
                            if (term.termStatus.penalInterest > 0) {
                                if (term.currency === "usd") {
                                    store.push(term.amount);
                                }
                            }
                        }
                    }
                }
            }
        });

        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);

        if (total !== 0) {
            return <div><p>{this.displayCurrency({ value: total, currency: "USD" })}</p></div>;
        } else {
            return <div><p>-</p></div>;
        }
    }

    renderSumOfODInUSD() {
        //extract state
        const { date, systemInfo } = this.state;
        const { terms } = date;

        if (!(_.isEmpty(systemInfo))) {

            //create the terms amount store
            let store = [];

            terms.map(term => {

                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //check if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        store.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        store.push(convertedAmount);
                    }
                }
            });

            //calculate the total amount from numbers in the array
            const total = store.reduce((a, b) => a + b, 0);

            return <div><p>{this.displayCurrency({ value: total, currency: "USD" })}</p></div>
        } else {
            return <h3>-</h3>
        }
    }

    renderDistinctCustomersPressable() {
        //extract state
        const { terms } = this.state.date;

        //compute the number of distinct customers who have uncleared terms
        let distinctCustomers = {};
        terms.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });


        let customers = {};
        terms.map(term => {
            if(`${term.customerID}` in customers) {
                //customer already in customers object
                //grab terms from customer object
                let terms = customers[`${term.customerID}`].terms;
                terms.push(term);

                customers[`${term.customerID}`].terms = terms;

            } else {
                customers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        });

        const numberOfCustomers = Object.keys(distinctCustomers).length;


        return (
            <div>
                <h3 onClick={() => this.showModal()}>{numberOfCustomers}</h3>
                <Modal
                    title="Data returned for Distinct Count of Customers # Def"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={'auto'}
                >
                    <div className={"masterlistOverdueLoansBox"}>
                        <div className={"bouncedChequeHeaders"}>
                            <div className={"stickyDivHeader1"}><p>Transaction Date</p></div>
                            <div className={"stickyDivHeader2"}><p>Name</p></div>
                            <div className={"stickyDivHeader3"}><p>Collector</p></div>
                            <div className={"stickyDivHeader4"}><p>Sales Officer</p></div>
                            <div className={"stickyDivHeader5"}><p>Cheque #</p></div>
                            <div><p>Debit Amount</p></div>
                            <div><p>Bounced Reason</p></div>
                            <div><p>Others Specify</p></div>
                            <div><p>Overdue</p></div>
                            <div><p>Character</p></div>
                            <div><p>Action</p></div>
                            <div><p>Status</p></div>
                            <div><p>Comment</p></div>
                            <div><p>Loan Statement</p></div>
                            <div><p>Days</p></div>
                            <div><p>Age</p></div>
                            <div><p>Profile</p></div>
                            <div><p>Truck</p></div>
                            <div><p>Industry</p></div>
                            <div><p>Model</p></div>
                            <div><p>Segment</p></div>
                            <div><p>OD Reporting USD</p></div>
                            <div><p>Due Date</p></div>
                            <div><p>First Demand Notice</p></div>
                            <div><p>Second Demand Notice</p></div>
                        </div>

                        <div className={"clientChequesBox"}>
                            <DistinctCustomersCard
                                customers={distinctCustomers}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    renderBucket() {
        // let momentTerms = [];
        // terms.forEach(term => {
        //     if (!term.termStatus.status) {
        //         let seconds;
        //         if ("transactionDate" in term) {
        //             term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
        //         } else {
        //             term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        //         }
        //         momentTerms.push(moment.unix(seconds));
        //     }
        // });
        //
        // console.log({ momentTerms });
        // //find the earliest date (moment objects)
        // const earliestDate = _.min(momentTerms);
        // const latestDate = _.max(momentTerms);
        //extract state
        const { date } = this.state.date;

        //grab the end of the month
        const endOfMonth = date.endOf('month');

        //grab end of month of next month
        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');

        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

        const bucket =Math.round(fromNow/30);
        // console.log({ endOfMonth: endOfMonth.format("DD-MM-YYYY") , endOfNextMonth: endOfNextMonth.format("DD-MM-YYYY"), fromNow });
        //
        // //find the bucket
        // let bucket = 1;
        // switch (true) {
        //     case (fromNow >= 1 && fromNow <= 30):
        //         bucket = 1;
        //         break;
        //     case (fromNow >= 31 && fromNow <= 60):
        //         bucket = 2;
        //         break;
        //     case (fromNow >= 61 && fromNow <= 90):
        //         bucket = 3;
        //         break;
        //     case (fromNow >= 91 && fromNow <= 120):
        //         bucket = 4;
        //         break;
        //     case (fromNow >= 121 && fromNow <= 150):
        //         bucket = 5;
        //         break;
        //     case (fromNow >= 151 && fromNow <= 180):
        //         bucket = 6;
        //         break;
        //     case (fromNow >= 181 && fromNow <= 210):
        //         bucket = 7;
        //         break;
        //     case (fromNow >= 211 && fromNow <= 240):
        //         bucket = 8;
        //         break;
        //     case (fromNow >= 241 && fromNow <= 270):
        //         bucket = 9;
        //         break;
        //     case (fromNow >= 271 && fromNow <= 300):
        //         bucket = 10;
        //         break;
        //     case (fromNow >= 301 && fromNow <= 330):
        //         bucket = 11;
        //         break;
        //     case (fromNow >= 331 && fromNow <= 360):
        //         bucket = 12;
        //         break;
        //     case (fromNow > 360):
        //         bucket = 13;
        //         break;
        // }

        return <p>{bucket}</p>;
    }

    renderNumberOfCustomers() {
        //extract state
        const { terms } = this.state.date;

        //compute the number of distinct customers who have uncleared terms
        let distinctCustomers = {};
        terms.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        const numberOfCustomers = Object.keys(distinctCustomers).length;

        return <p>{numberOfCustomers}</p>;
    }

    renderODPercent() {
        //extract state
        const { date, systemInfo } = this.state;
        const { terms, termsStore } = date;

        if (!(_.isEmpty(systemInfo))) {

            //create the terms amount store
            let store = [];
            let totalStore = [];

            //find total of terms within this month
            terms.map(term => {

                //check that term is not cleared
                if (!term.termStatus.status) {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        store.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        store.push(convertedAmount);
                    }
                }
            });


            //calculate the total amount from numbers in the array
            const total = store.reduce((a, b) => a + b, 0);

            //find total of terms within for all overdue loans
            termsStore.map(term => {

                //check that term is not cleared
                if (!term.termStatus.status) {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        totalStore.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        totalStore.push(convertedAmount);
                    }
                }
            });


            //calculate the total amount from numbers in the array
            const allTotal = totalStore.reduce((a, b) => a + b, 0);

            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
            const ODPercent = (total/allTotal) * 100;
            let percent = roundAccurately(ODPercent, 2);


            return <p>{percent} %</p>;
        } else {
            return <h3>-</h3>
        }
    }

    displayCurrency({ value, currency }) {
        //extract state
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem', wordBreak: 'break-all', width: '100%', }}>{formatter.format(value)}</p>;
    }

    
    renderBucketGraph() {
        //extract state
        const { date } = this.state.date;

        //grab the end of the month
        const endOfMonth = date.endOf('month');

        //grab end of month of next month
        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');

        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

        const bucket = Math.round(fromNow/30);

        let daysRange = "(0)";

        //compute date range depending on bucket
        if(bucket !== 0) {
            const endDate = bucket * 30;
            const startDate = endDate - 29;

            daysRange = `(${startDate}-${endDate})`
        }

        return <p>{daysRange}</p>

        // //find and display the bucket
        // switch (bucket) {
        //     case 1:
        //         return <p>(1-30)</p>;
        //     case 2:
        //         return <p>(31-60)</p>;
        //     case 3:
        //         return <p>(61-90)</p>;
        //     case 4:
        //         return <p>(91-120)</p>;
        //     case 5:
        //         return <p>(121-150)</p>;
        //     case 6:
        //         return <p>(151-180)</p>;
        //     case 7:
        //         return <p>(181-210)</p>;
        //     case 8:
        //         return <p>(211-240)</p>;
        //     case 9:
        //         return <p>(241-270)</p>;
        //     case 10:
        //         return <p>(271-300)</p>;
        //     case 11:
        //         return <p>(301-330)</p>;
        //     case 12:
        //         return <p>(331-360)</p>;
        //     case 13:
        //         return <p>(>360)</p>;
        //     default:
        //         return <p>(0)</p>;
        // }
    }

    render() {
        return (
            <div className={"dashboardValues"}>
                {/* <div>
                    <div><h4>Row Labels</h4></div>
                    <div><h4>Sum of Debit amount LCY</h4></div>
                    <div><h4>Sum of Debit amount $</h4></div>
                    <div><h4>Sum of OD Reporting USD</h4></div>
                    <div><h4>Distinct Count of Customers #</h4></div>
                    <div><h4>Ageing</h4></div>
                    <div><h4>Bucket</h4></div>
                    <div><h4>Number of Customers</h4></div>
                    <div><h4>Total OD % per Bucket</h4></div>
                </div> */}

                
                <div><p>{ this.renderDate() }</p></div>
                <div><p>{ this.renderDebitAmountLCY() }</p></div>
                <div><p>{ this.renderDebitAmountUSD() }</p></div>
                <div><p>{ this.renderSumOfODInUSD() }</p></div>
                <div><p>{ this.renderDistinctCustomersPressable() }</p></div>
                <div>{ this.renderBucketGraph() }</div>
                <div><p>{ this.renderBucket() }</p></div>
                <div><p>{ this.renderODPercent() }</p></div>

                {/* <div>
                    <div><h4>Grand Total</h4></div>
                    <div><h4>Total label</h4></div>
                    <div><h4>Total label</h4></div>
                    <div><h4>Total label</h4></div>
                    <div><h4>Total label</h4></div>
                    <div><h4>Ageing</h4></div>
                    <div><h4>Bucket</h4></div>
                    <div><h4>Total label</h4></div>
                    <div><h4>Total OD % per Bucket</h4></div>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { systemInfo } = state.app;

    return {
        systemInfo
    }
};

export default connect(mapStateToProps)(DashBoardCard);