import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";


//components imports

class ManualOverrideCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            term: this.props.term
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.term !== this.props.term) {
            this.setState({ term: nextProps.term });
        }
    }

    render(){
        //extract state
        const { term } = this.state;

        let seconds;
        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
        const dueDate = moment.unix(seconds);
        const dateString = dueDate.format("DD/MM/YYYY");

        let status = "Pending";
        let statusColor = "#BBD7ED";
        if ("termStatus" in term) {
            if (term.termStatus.status) {
                status = "EMI Cleared";
                statusColor = "#8FBC44";
            } else {
                status = "EMI Not Cleared"
                statusColor = "#F6C058";
            }
        }
        return (
            <div className={"manualOverrideCard"}>
                <div>
                    <p className={"manualOverrideCardTitle"}>ID</p>
                    {
                        term.cheque ? <p>{term.chequeNumber}</p> : <p>{term.loanTermID}</p>
                    }
                </div>
                <div>
                    <p className={"manualOverrideCardTitle"}>Date</p>
                    <p>{dateString}</p>
                </div>

                <div>
                    <p className={"manualOverrideCardTitle"}>Status</p>
                    <p style={{ color: statusColor }}>{status}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})



export default connect(mapStateToProps)(ManualOverrideCard)
