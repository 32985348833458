import * as firebase from 'firebase';
import moment from 'moment';
import _ from "lodash";
import arraySort from "array-sort";
import { Switch, message } from "antd";
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

//actions import
import {
    ALL_REPORT,
    ALL_REPORT_SUCCESSFUL,
    ALL_REPORT_FAILED,
} from "./Types";

//property declaration
const XLSX = require('xlsx');

const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

let contry = '';
if (!(_.isEmpty(project))) {
    if (project.privateID === "0001") {
        contry = "TANZANIA";
    } else if (project.privateID === "0002") {
        contry = "KENYA";
    } else if (project.privateID === "0003") {
        contry = "SENEGAL";
    } else if (project.privateID === "0004") {
        contry = "NIGERIA";
    } else if (project.privateID === "0005") {
        contry = "GHANA";
    } else if (project.privateID === "0006") {
        contry = "ZAMBIA";
    } else if (project.privateID === "0007") {
        contry = "S.AFRICA";
    } else if (project.privateID === "0000") {
        contry = "FIRM23";
    }
}


export const allReportsNew = ({bouncedCheques, filteredLegalRepoStatus, filterBucket,  profile, }) => {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    return async (dispatch) => {
        
        dispatch({type: ALL_REPORT});

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        let successArray = [];

        const today = moment();


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const localCurrency = systemInfo.defaultCurrency;
            const url = `${project.serverUrl}fetchAllBeforeTerms`;

            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((allBeforeTerms) => {
                    console.log({23: allBeforeTerms});

                    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
                    fetch(beforeUrl, {
                        method: 'GET',
                        mode: 'cors',
                        headers: {'Content-Type': 'application/json'},
                    }).then((response) => response.json())
                        .then((response) => {

                            let dates = {};

                            response.forEach(term => {
                                let seconds;
                                if ("transactionDate" in term) {
                                    if (term.transactionDate) {
                                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                    } else {
                                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                    }
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }

                                const dueDate = moment.unix(seconds);

                                //put term in current month bucket
                                //grab the month and year
                                const dueDateID = dueDate.format("MMYYYY");

                                //check if dueDateID already exists in dates object
                                if (`${dueDateID}` in dates) {
                                    //terms with this date already exist
                                    let terms = dates[`${dueDateID}`].terms;
                                    terms.push(term);

                                    dates[`${dueDateID}`].terms = terms;
                                } else {
                                    //its a new date so create new object for it
                                    dates[`${dueDateID}`] = {
                                        date: dueDate,
                                        terms: [term],
                                        allBeforeTerms,
                                        termsStore: response
                                    }
                                }
                            })

                            //FIND GRAND CONTENTS
                            let totalCustomers;
                            let sumInUsd;
                            let sumLCY;
                            let sumOfODInUSD;

                            //sum of debit in local currency
                            let storeTerms = [];
                            if (allBeforeTerms.length !== 0) {
                                allBeforeTerms.map(term => {
                                    if (term.cheque) {
                                        if (term.chequeStatus === "bounced") {
                                            if (term.currency !== "usd") {
                                                storeTerms.push(term.amount);
                                            }
                                        }
                                    } else {
                                        if (term.currency !== "usd") {
                                            storeTerms.push(term.amount);
                                        }
                                    }
                                });

                                sumLCY = storeTerms.reduce((a, b) => a + b, 0);
                                sumLCY = roundAccurately(sumLCY, 2);

                                    // const formatter = new Intl.NumberFormat('en-US', {
                                    //     style: 'currency',
                                    //     currency: localCurrency,
                                    // });

                                    // sumLCY = formatter.format(sumLCY);
                            }

                            //sum of debit in usd format
                            let totalInUSD = [];
                            if (allBeforeTerms.length !== 0) {
                                allBeforeTerms.map(term => {
                                    //filter if they are cleared or not
                                    if (term.cheque) {
                                        if (term.chequeStatus === "bounced") {
                                            if (term.currency === "usd") {
                                                totalInUSD.push(term.amount);
                                            }
                                        }
                                    } else {
                                        if (term.currency === "usd") {
                                            totalInUSD.push(term.amount);
                                        }
                                    }
                                });

                                sumInUsd = totalInUSD.reduce((a, b) => a + b, 0);
                                sumInUsd = roundAccurately(sumInUsd, 2);

                                // const  currency = "USD";

                                // const formatter = new Intl.NumberFormat('en-US', {
                                //     style: 'currency',
                                //     currency,
                                // });

                                // sumInUsd = formatter.format(sumInUsd);
                            }

                            //sum of OD in usd
                            let usdTermStore = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    //if loan is in usd convert the overdue to default currency
                                    if (term.currency === "usd") {
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        usdTermStore.push(amount);
                                    } else {
                                        //fetch system info exchange rate
                                        const exchangeRate = systemInfo.exchangeRate;

                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        const convertedAmount = amount/exchangeRate;

                                        usdTermStore.push(convertedAmount);
                                    }
                                }
                            });

                            sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                            sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);
                                // const  currency = "USD";

                                // const formatter = new Intl.NumberFormat('en-US', {
                                //     style: 'currency',
                                //     currency,
                                // });

                                // sumOfODInUSD = formatter.format(sumOfODInUSD);

                            //sum of customers
                            if(response.length !== 0){
                                let customerStore = [];
                                response.map(term => {
                                    customerStore.push(term);
                                });

                                let distinctCustomers = {};

                                customerStore.map(term => {
                                    if(`${term.customerID}` in distinctCustomers) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomers[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomers[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomers[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomers = Object.keys(distinctCustomers).length;
                            }

                            // console.log(totalCustomers, sumInUsd, sumLCY, sumOfODInUSD )

                            //for examining each cheque contents
                            if (!(_.isEmpty(dates))) {
                                ///extract date into array so it can be sorted
                                const datesArray = _.map(dates, date => date);
                                const sortedDates = arraySort(datesArray, "date");

                                sortedDates.map(data => {
                                    const allBeforeTerms = data.allBeforeTerms;
                                    const date = data.date;
                                    const terms = data.terms;
                                    const termsStore = data.termsStore;

                                    const res = getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
                                    successArray.push(res);

                                })

                                Promise.all(successArray).then((dashboardData) => {
                                    if(dashboardData.length !== 0){
                                        dashboardData.push({ endOfMonth: "Grand Total", totalLCY: sumLCY, totalDebitInUSD: sumInUsd, totalInUsd: sumOfODInUSD, numberOfCustomers: totalCustomers, daysRange: "", bucket: "", percent: "" });

                                    }
                                    
                                    fetchStatusAnalysis({bouncedCheques, profile,  filteredLegalRepoStatus, filterBucket, systemInfo, dashboardData, dispatch});
                                    
                                })
                            }

                        }).catch((error) => {
                        console.log("Here's your error");
                        console.log(error);
                        dispatch({type: ALL_REPORT_FAILED});

                    })

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED});

            })
        }
    }
};

async function getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    const endOfMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

    const bucket = Math.round(fromNow/30);

    return getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });

}

async function getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
}

async function getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    let store = [];
    let totalStore = [];

    //find total of terms within this month
    terms.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const total = store.reduce((a, b) => a + b, 0);

    //find total of terms within for all overdue loans
    termsStore.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                totalStore.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                totalStore.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const allTotal = totalStore.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const ODPercent = (total/allTotal) * 100;
    let percent = roundAccurately(ODPercent, 2);

    return renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket });
}

async function renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket,  }){

    const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

    return renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket  });
}

async function renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket  }){
    //grab the end of the month
    const endMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endMonth, 'days');

    const buckets = Math.round(fromNow/30);

    let daysRange = "(0)";

    //compute date range depending on bucket
    if(buckets !== 0) {
        const endDate = buckets * 30;
        const startDate = endDate - 29;

        daysRange = `(${startDate}-${endDate})`
    }

    return renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket  });
}

async function renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket  }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalInUsd = store.reduce((a, b) => a + b, 0);

    return renderDebitInUSD({ totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });

}

async function renderDebitInUSD({ totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency === "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                //check if term has penal interest
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency === "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalDebitInUSD = store.reduce((a, b) => a + b, 0);

    // const  currency = "USD";

    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency,
    // });

    // totalDebitInUSD = formatter.format(totalDebitInUSD);

    return renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });

}

async function renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){
    //create the terms amount store
    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    if (term.transactionDate) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency !== "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency !== "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalLCY = store.reduce((a, b) => a + b, 0);


    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency: localCurrency,
    // });

    // totalLCY = formatter.format(totalLCY);

    return { totalLCY, totalInUsd, totalDebitInUSD, percent, numberOfCustomers, bucket, endOfMonth, daysRange }
}

//second function
async function fetchStatusAnalysis({ dashboardData, bouncedCheques, profile,  filteredLegalRepoStatus, filterBucket, systemInfo, dispatch}) {
    let successArray = [];

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

        fetch(beforeUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                let statuses = {};

                response.forEach(term => {
                    let statusID;
                    if ("legalRepoStatus" in term) {
                        statusID = term.legalRepoStatus;
                    } else {
                        statusID = "Loans with no status";
                    }

                    //check if dueDateID already exists in dates object
                    if (`${statusID}` in statuses) {
                        //terms with this date already exist
                        let terms = statuses[`${statusID}`].terms;
                        terms.push(term);

                        statuses[`${statusID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        statuses[`${statusID}`] = {
                            status: statusID,
                            terms: [term],
                            termsStore: response
                        }
                    }
                })

                //FIND GRAND CONTENTS
                let totalCustomers;
                let sumOfODInUSD;

                //sum of OD in usd
                let usdTermStore = [];
                if (response.length !== 0){
                    response.map(term => {
                        //check that term is not cleared
                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            //if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }

                                usdTermStore.push(amount);
                            } else {
                                //fetch system info exchange rate
                                const exchangeRate = systemInfo.exchangeRate;

                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }

                                const convertedAmount = amount/exchangeRate;

                                usdTermStore.push(convertedAmount);
                            }
                        }
                    });
                }

                sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);
                //     const  currency = "USD";
                //
                //     const formatter = new Intl.NumberFormat('en-US', {
                //         style: 'currency',
                //         currency,
                //     });
                //
                //     sumOfODInUSD = formatter.format(sumOfODInUSD);

                //sum of customers
                if(response.length !== 0){
                    let customerStore = [];
                    response.map(term => {
                        customerStore.push(term);
                    });

                    let distinctCustomers = {};

                    customerStore.map(term => {
                        if(`${term.customerID}` in distinctCustomers) {
                            //customer already in customers object
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                //grab terms from customer object
                                let terms = distinctCustomers[`${term.customerID}`].terms;
                                terms.push(term);

                                distinctCustomers[`${term.customerID}`].terms = terms;
                            }
                        } else {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                distinctCustomers[`${term.customerID}`] = {
                                    customerID: term.customerID,
                                    customerName: term.customerName,
                                    terms: [term]
                                }
                            }
                        }
                    });

                    totalCustomers = Object.keys(distinctCustomers).length;
                }

                //for examining each cheque contents
                if (!(_.isEmpty(statuses))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(statuses, date => date);
                    const sortedDates = arraySort(datesArray, "status");

                    sortedDates.map(data => {
                        const status = data.status;
                        const terms = data.terms;
                        const termsStore = data.termsStore;

                        const res = getNumberOfCustomersWithStatus({ status, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((analysis) => {

                        // // here is our array of dashboard data
                        if(analysis.length !== 0){

                            analysis.push({ status: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });
                        }

                        createCharacterAnalysis({bouncedCheques, filteredLegalRepoStatus,  profile,  filterBucket, statusAnalysis: analysis, dashboardData, systemInfo, response, dispatch});
                    })
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED});

        })
}

async function getNumberOfCustomersWithStatus({ status, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

let distinctCustomers = {};
terms.map(term => {
    if(`${term.customerID}` in distinctCustomers) {
        //customer already in customers object
        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared do nothing
        } else {
            //grab terms from customer object
            let terms = distinctCustomers[`${term.customerID}`].terms;
            terms.push(term);

            distinctCustomers[`${term.customerID}`].terms = terms;
        }
    } else {
        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared do nothing
        } else {
            distinctCustomers[`${term.customerID}`] = {
                customerID: term.customerID,
                customerName: term.customerName,
                terms: [term]
            }
        }
    }
});

const numberOfCustomers = Object.keys(distinctCustomers).length;

return getValueWithStatus({ numberOfCustomers, status, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo});
}

async function getValueWithStatus({ numberOfCustomers, status, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo}){

let distinctCustomers = {};
terms.map(term => {
    if(`${term.customerID}` in distinctCustomers) {
        //customer already in customers object
        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared do nothing
        } else {
            //grab terms from customer object
            let terms = distinctCustomers[`${term.customerID}`].terms;
            terms.push(term);

            distinctCustomers[`${term.customerID}`].terms = terms;
        }
    } else {
        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared do nothing
        } else {
            distinctCustomers[`${term.customerID}`] = {
                customerID: term.customerID,
                customerName: term.customerName,
                terms: [term]
            }
        }
    }
});

const customerValue = Object.keys(distinctCustomers).length;

let allDistinctCustomers = {};
    //find total of terms within for all overdue loans
    termsStore.map(term => {
        //
        if(`${term.customerID}` in allDistinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = allDistinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                allDistinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                allDistinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    //total value of distinct number of customers
    const totalValue = Object.keys(allDistinctCustomers).length;

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const numberPercent = (customerValue/totalValue) * 100;
    let countPercent = roundAccurately(numberPercent, 1);

    return getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo});


}

async function getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo}){

    let store = [];
    let totalStore = [];

    //find total of terms within this month
    terms.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const total = store.reduce((a, b) => a + b, 0);

    //find total of terms within for all overdue loans
    termsStore.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                totalStore.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                totalStore.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const allTotal = totalStore.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const ODPercent = (total/allTotal) * 100;
    let valuePercent = roundAccurately(ODPercent, 1);

    return getSumOfODInUSD({ valuePercent, systemInfo, numberOfCustomers, status, terms, countPercent });
}

async function getSumOfODInUSD({ valuePercent, systemInfo, numberOfCustomers, status, terms, countPercent }){
let store = [];

terms.map(term => {

    //check that term is not cleared
    if (term.termStatus.status) {
        //term is cleared
        //do nothing
    } else {
        //check if loan is in usd convert the overdue to default currency
        if (term.currency === "usd") {
            //grab the total overdue
            let amount;
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            store.push(amount);
        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            let amount;
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            const convertedAmount = amount/exchangeRate;

            store.push(convertedAmount);
        }
    }
});

//calculate the total amount from numbers in the array
let totalODInUsd = store.reduce((a, b) => a + b, 0);

const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
totalODInUsd = roundAccurately(totalODInUsd, 2);

return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, status }
}

async function createCharacterAnalysis({bouncedCheques, profile,  filteredLegalRepoStatus, filterBucket, statusAnalysis, dashboardData, systemInfo, response, dispatch}){

    let successArray = [];

    let characters = {};

    response.forEach(term => {
        let characterID;
        if ("character" in term) {
            if(term.character) {
                characterID = term.character;
            } else {
                characterID = "Blank"
            }
        } else {
            characterID = "Blank";
        }

        //check if characterID already exists in dates object
        if (`${characterID}` in characters) {
            //terms with this date already exist
            let terms = characters[`${characterID}`].terms;
            terms.push(term);

            characters[`${characterID}`].terms = terms;
        } else {
            //its a new date so create new object for it
            characters[`${characterID}`] = {
                character: characterID,
                terms: [term],
                termsStore: response
            }
        }
    })

    //FIND GRAND CONTENTS
    let totalCustomers;
    let sumOfODInUSD;

    //sum of OD in usd
    let usdTermStore = [];
    if (response.length !== 0){
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);


    //sum of customers
    if(response.length !== 0){
        let customerStore = [];
        response.map(term => {
            customerStore.push(term);
        });

        let distinctCustomers = {};

        customerStore.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        totalCustomers = Object.keys(distinctCustomers).length;
    }

    // console.log({sumOfNum: totalCustomers, sumOfOD: sumOfODInUSD})

    //for examining each cheque contents
    if (!(_.isEmpty(characters))) {
        ///extract date into array so it can be sorted
        const datesArray = _.map(characters, date => date);
        const sortedDates = arraySort(datesArray, "character");

        sortedDates.map(data => {
            const character = data.character;
            const terms = data.terms;
            const termsStore = data.termsStore;

            const res = getNumberOfCustomersWithCharacter({ character, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
            successArray.push(res);

        })

        Promise.all(successArray).then((array) => {

            // here is our array of dashboard data
            if(array.length !== 0){
                array.push({ character: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });
            }

            createOverdueAnalysis({characterAnalysis: array, statusAnalysis, profile,  dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch });

        })
    }
}

async function getNumberOfCustomersWithCharacter({ character, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getValueWithCharacter({ numberOfCustomers, character, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, });
}

async function getValueWithCharacter({ numberOfCustomers, character, terms, termsStore, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const customerValue = Object.keys(distinctCustomers).length;

    let allDistinctCustomers = {};
    //find total of terms within for all overdue loans
    termsStore.map(term => {
        //
        if(`${term.customerID}` in allDistinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = allDistinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                allDistinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                allDistinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    //total value of distinct number of customers
    const totalValue = Object.keys(allDistinctCustomers).length;

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const numberPercent = (customerValue/totalValue) * 100;
    let countPercent = roundAccurately(numberPercent, 1);

    return getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });


}

async function getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }){

    let store = [];
    let totalStore = [];

    //find total of terms within this month
    terms.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const total = store.reduce((a, b) => a + b, 0);

    //find total of terms within for all overdue loans
    termsStore.map(term => {

        //check that term is not cleared
        if (!term.termStatus.status) {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                totalStore.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                totalStore.push(convertedAmount);
            }
        }
    });


    //calculate the total amount from numbers in the array
    const allTotal = totalStore.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const ODPercent = (total/allTotal) * 100;
    let valuePercent = roundAccurately(ODPercent, 1);

    return getSumOfCharactersWithODInUSD({ valuePercent, systemInfo, numberOfCustomers, character, terms, countPercent });
}

async function getSumOfCharactersWithODInUSD({ valuePercent, systemInfo, numberOfCustomers, character, terms, countPercent }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, character }
}

async function createOverdueAnalysis({characterAnalysis, statusAnalysis, profile,  dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch}){

    let overdueAmount = 0;
    let customerID = "";
    let customerName = "";
    let overdueArr = [];

    response.forEach(term => {
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                if ("modulo" in term) {
                    overdueAmount = term.amount - term.modulo;
                    customerID = term.customerID;
                    customerName = term.customerName;
                    overdueArr.push({overdueAmount, customerID, customerName});
                } else {
                    overdueAmount = term.amount;
                    customerID = term.customerID;
                    customerName = term.customerName;
                    overdueArr.push({overdueAmount, customerID, customerName});
                }
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                if ("modulo" in term) {
                    overdueAmount = term.amount - term.modulo;
                } else {
                    overdueAmount = term.amount;
                }

                overdueAmount = overdueAmount/exchangeRate;
                customerID = term.customerID;
                customerName = term.customerName;
                overdueArr.push({overdueAmount, customerID, customerName});

            }
        }
    })

    //remove undefined element from the array
    overdueArr = overdueArr.filter(function( element ) {
        return element !== undefined;
    });

    let distinctCustomers = {};
    overdueArr.forEach(term => {
        if(`${term.customerID}` in distinctCustomers) {

            distinctCustomers[`${term.customerID}`].overdueAmount = distinctCustomers[`${term.customerID}`].overdueAmount + term.overdueAmount;
        } else {

            distinctCustomers[`${term.customerID}`] = {
                customerID: term.customerID,
                customerName: term.customerName,
                overdueAmount: term.overdueAmount,
            }
        }
    })

    return grabOverdueInUSD({distinctCustomers, profile,  characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch});
}


async function grabOverdueInUSD({distinctCustomers, profile,  characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch}){
    let successArray = [];

    try{
        const customerArr = Object.values(distinctCustomers);
        customerArr.forEach(customer => {
            const res = changeOverFromLocalToUSD({customer});
            successArray.push(res);
        })

    } catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});

    }

    Promise.all(successArray).then((value) => {
        const resultArray = value.sort((a, b) => (b.overdue) - (a.overdue));

        createODAgingReport({customerOD: resultArray, profile, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch });
    });
}

async function changeOverFromLocalToUSD({customer}){
    try {

       return {overdue: customer.overdueAmount, customerName: customer.customerName, customerID: customer.customerID};
    
    } catch(e){
        console.log(e);
    }
}


async function createODAgingReport({customerOD, profile, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, response, dispatch }){
    const url = `${project.serverUrl}fetchMasterListUpdate`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((bouncedChequesOD) => {
            grabAllBeforeTerms({bouncedChequesOD, profile, customerOD, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, overdueTermsOD: response, dispatch})
        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
    })
}

async function grabAllBeforeTerms({overdueTermsOD, profile, bouncedChequesOD, customerOD, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch}){
    const url = `${project.serverUrl}fetchAllBeforeTerms`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((allBeforeTermsOD) => {
            grabOverdueAgingCheques({allBeforeTermsOD, profile, overdueTermsOD, bouncedChequesOD, customerOD, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch})
        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({type: ALL_REPORT_FAILED});

    })
}

async function grabOverdueAgingCheques({allBeforeTermsOD, systemInfo, profile, overdueTermsOD, bouncedChequesOD, customerOD, characterAnalysis, statusAnalysis, dashboardData, bouncedCheques, filteredLegalRepoStatus, filterBucket, dispatch}){
    let successArray = [];
    try{
        if(overdueTermsOD.length !== 0){
            let legalRepoFilteredCheques = [];
    
            if(filteredLegalRepoStatus){
                legalRepoFilteredCheques = overdueTermsOD.filter(cheque => cheque.legalRepoStatus === filteredLegalRepoStatus );
            } else {
                legalRepoFilteredCheques = overdueTermsOD.map(cheque => cheque);
            }
    
            //create filtered loans bucket
            let bucketFilteredCheques = [];
    
            if (legalRepoFilteredCheques.length !== 0) {
                legalRepoFilteredCheques.map(cheque => {
                    //get the cheque bucket
                    let seconds;
                    if ("transactionDate" in cheque) {
                        cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds : seconds = cheque.transactionDate._seconds;
                    } else {
                        cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
                    }
    
                    const dueDate = moment.unix(seconds);
                    const now = moment();
    
                    const fromNow = now.diff(dueDate, 'days');
    
                    const bucket = Math.ceil(fromNow/30);
    
                    switch (filterBucket) {
                        case "1":
                            if (bucket === 1) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "2":
                            if (bucket === 2) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "3":
                            if (bucket === 3) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "4":
                            if (bucket === 4) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "5":
                            if (bucket === 5) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "6":
                            if (bucket === 6) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "7":
                            if (bucket === 7) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "8":
                            if (bucket === 8) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "9":
                            if (bucket === 9) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "10":
                            if (bucket === 10) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "11":
                            if (bucket === 11) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "12":
                            if (bucket === 12) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        case "13":
                            if (bucket === 13) {
                                bucketFilteredCheques.push(cheque)
                            }
                            break;
                        default:
                            bucketFilteredCheques.push(cheque);
                            break;
                    }
                })
            }
    
            const sortedCheques = arraySort(bucketFilteredCheques, 'customerName');
            sortedCheques.map(cheque => {
                const loanLoanTerms = overdueTermsOD.filter(term => term.loanID === cheque.loanID);
                const res = grabChequeData({loanLoanTerms, cheque, systemInfo, profile});
                successArray.push(res);
            })
        }
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});


    }

    Promise.all(successArray).then((overdueAging) => {

        createProvisionReport({overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch})
    })
}

async function grabChequeData({loanLoanTerms, cheque, systemInfo, profile}){
    try{
        //get transaction date
        let transactionDate;
        let assignee = "";
        let bouncedReason = "";
        let otherReason = "";
        let overdue = 0;
        if("transactionDate" in cheque){
            let seconds5;
            cheque.transactionDate.seconds ? seconds5 = cheque.transactionDate.seconds : seconds5 = cheque.transactionDate._seconds;
    
            transactionDate = moment.unix(seconds5);
            transactionDate = transactionDate.format("DD/MM/YYYY");
            
        }else{
            let seconds5;
            cheque.dueDate.seconds ? seconds5 = cheque.dueDate.seconds : seconds5 = cheque.dueDate._seconds;
    
            transactionDate = moment.unix(seconds5);
            transactionDate = transactionDate.format("DD/MM/YYYY");
        }

        //get customer name, cheque#, salesExe, amount
        const customerName = cheque.customerName;
        const chequeNumber = cheque.chequeNumber;
        const salesExe = cheque.salesExe;


        let amountTotal = cheque.amount;
        let displayValue;
        //check if currency is activated in profile
        let currency;
        const loanCurrency = cheque.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = amountTotal;
        } else {
            if (profile.viewInUSD) {
                //change currency to usd
                currency = "USD";

                //check if user has selected a specific exchange rate date
                if ("exchangeRateOnDate" in profile) {
                    //grab the exchange rate from profile
                    const { exchangeRateOnDate } = profile;
                    displayValue = amountTotal / exchangeRateOnDate;
                } else {
                    //grab the default exchange rate from system
                    const { exchangeRate } = systemInfo;
                    displayValue = amountTotal / exchangeRate;
                }
            }  else {
                currency = systemInfo.defaultCurrency;

                displayValue = amountTotal;
            }
        }

        // const formatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency,
        // });
        // amountTotal = formatter.format(displayValue);
        amountTotal = displayValue;


        //assignee
        if("assignee" in cheque){
            assignee = cheque.assignee.name
        }

        //bounced reason
        if("bouncedReason" in cheque){
            switch (cheque.bouncedReason) {
                case "insufficientFunds":
                    bouncedReason = "Insufficient Funds";
                    break;
                case "drawer'sSignatureRequired":
                    bouncedReason = "Drawer's Signature Required";
                    break;
                case "staleCheque":
                    bouncedReason = "Stale Cheque";
                    break;
                case "accountClosed":
                    bouncedReason = "Account Closed";
                    break;
                case "dormantAccount":
                    bouncedReason = "Dormant Account";
                    break;
                case "spoiltCheque":
                    bouncedReason = "Spoilt Cheque";
                    break;
                case "drawer'sConfirmationRequired":
                    bouncedReason = "Drawer's Confirmation Required";
                    break;
                case "correctionInCheque":
                    bouncedReason = "Correction In Cheque";
                    break;
                case "chequeNotStamped":
                    bouncedReason = "Cheque Not Stamped";
                    break;
                case "stopPayment":
                    bouncedReason = "Stop Payment";
                    break;
                case "amountInWordsAndFigureDiffer":
                    bouncedReason = "Amount In Words And Figure Differ";
                    break;
                case "chequeOnHold":
                    bouncedReason = "Cheque On Hold";
                    break;
                case "referToDrawer":
                    bouncedReason = "Refer To Drawer";
                    break;
                case "others":
                    bouncedReason = "Others";
                    break;
                default:
                    bouncedReason = "";
                    break;
            }
        }

        //other reason
        if("otherReason" in cheque){
            otherReason = cheque.otherReason;
        }

        //grab overdue
        if("modulo" in cheque){
            const modulo = cheque.modulo;
            const amount = cheque.amount;
            overdue = amount - modulo;
            let currency;
            const loanCurrency = cheque.currency;
            if (loanCurrency === "usd") {
                currency = "USD";
    
                displayValue = overdue;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";
    
                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = overdue / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = overdue / exchangeRate;
                    }
                }  else {
                    currency = systemInfo.defaultCurrency;
    
                    displayValue = overdue;
                }
            }
    
            // const formatter = new Intl.NumberFormat('en-US', {
            //     style: 'currency',
            //     currency,
            // });
            // overdue = formatter.format(displayValue);
            overdue = displayValue;

        }else{
            if("termStatus" in cheque){
                if(!(cheque.termStatus.status)){
                    overdue = cheque.amount;
                    let currency;
                    const loanCurrency = cheque.currency;
                    if (loanCurrency === "usd") {
                        currency = "USD";
            
                        displayValue = overdue;
                    } else {
                        if (profile.viewInUSD) {
                            //change currency to usd
                            currency = "USD";
            
                            //check if user has selected a specific exchange rate date
                            if ("exchangeRateOnDate" in profile) {
                                //grab the exchange rate from profile
                                const { exchangeRateOnDate } = profile;
                                displayValue = overdue / exchangeRateOnDate;
                            } else {
                                //grab the default exchange rate from system
                                const { exchangeRate } = systemInfo;
                                displayValue = overdue / exchangeRate;
                            }
                        }  else {
                            currency = systemInfo.defaultCurrency;
            
                            displayValue = overdue;
                        }
                    }
            
                    // const formatter = new Intl.NumberFormat('en-US', {
                    //     style: 'currency',
                    //     currency,
                    // });
                    // overdue = formatter.format(displayValue);
                    overdue = displayValue;

                }
            }
        }

        let character = "";
        let clientAction = "";
        if("character" in cheque){
            character = cheque.character;
        }
        if("clientAction" in cheque){
            clientAction = cheque.clientAction;
        }
        let legalRepoStatus = "";
        let comment = "";
        if("legalRepoStatus" in cheque){
            legalRepoStatus = cheque.legalRepoStatus;
        }
        if("masterListComment" in cheque){
            comment = cheque.masterListComment.newComment;
        }
        const truck = cheque.truck;
        const industry = cheque.industry;
        const model = cheque.model;
        const segment = cheque.segment;

        //daily bucket
        let seconds;
        if ("transactionDate" in cheque) {
            if (cheque.transactionDate) {
                cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds : seconds = cheque.transactionDate._seconds;
            }
        } else {
            cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const now = moment();

        const dailyBucket = now.diff(dueDate, 'days');


        //daily age
        let seconds1;
        if ("transactionDate" in cheque) {
            if (cheque.transactionDate) {
                cheque.transactionDate.seconds ? seconds1 = cheque.transactionDate.seconds : seconds1 = cheque.transactionDate._seconds;
            }
        } else {
            cheque.dueDate.seconds ? seconds1 = cheque.dueDate.seconds : seconds1 = cheque.dueDate._seconds;
        }
        const dueDate1 = moment.unix(seconds1);
        const now1 = moment();
        const fromNow = now1.diff(dueDate1, 'days');
        const dailyAge = Math.ceil(fromNow/30);

        //client profile
        let clientProfile = "";
        if(cheque.clientProfile === "individual"){
            clientProfile = "Individual";
        }
        if(cheque.clientProfile === "sme"){
            clientProfile = "SME";
        }
        if(cheque.clientProfile === "corporate"){
            clientProfile = "Corporate";
        }
        if(cheque.clientProfile === "trust"){
            clientProfile = "Trust";
        }
        if(cheque.clientProfile === "government"){
            clientProfile = "Government";
        }
        if(cheque.clientProfile === "others"){
            clientProfile = "Others";
        }


        //firstDemandNotice
        let firstDemandNotice = "";
        if("firstDemandNotice" in cheque) {
            let seconds2;
            cheque.firstDemandNotice.seconds ? seconds2 = cheque.firstDemandNotice.seconds : seconds2 = cheque.firstDemandNotice._seconds;
            const date = moment.unix(seconds2);
            firstDemandNotice = date.format("DD/MM/YYYY");
        }

        //secondDemandNotice
        let secondDemandNotice = "";
        if ('secondDemandNotice' in cheque) {
            let seconds3;
            cheque.secondDemandNotice.seconds ? seconds3 = cheque.secondDemandNotice.seconds : seconds3 = cheque.secondDemandNotice._seconds;
            const date = moment.unix(seconds3);
            secondDemandNotice = date.format("DD/MM/YYYY");
        }

        //due date
        let seconds4;
        cheque.dueDate.seconds ? seconds4 = cheque.dueDate.seconds : seconds4 = cheque.dueDate._seconds;

        let dueDateValue = moment.unix(seconds4);
        dueDateValue = dueDateValue.format("DD/MM/YYYY");

        //sum of OD in USD
        let sumODinUSD = 0;
        if (!(_.isEmpty(systemInfo))) {

            let total = 0;

            //check that term is not cleared
            if (cheque.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //check if loan is in usd convert the overdue to default currency
                if (cheque.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in cheque) {
                        total = cheque.amount - cheque.modulo;
                    } else {
                        total = cheque.amount;
                    }

                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount1;
                    if ("modulo" in cheque) {
                        amount1 = cheque.amount - cheque.modulo;
                    } else {
                        amount1 = cheque.amount;
                    }

                    total = amount1/exchangeRate;
                }
            }
            // const currency = "USD";

            // const formatter = new Intl.NumberFormat('en-US', {
            //     style: 'currency',
            //     currency,
            // });

            // sumODinUSD = formatter.format(total);
            sumODinUSD = total;


        }

        return {sumODinUSD, dueDateValue, firstDemandNotice, secondDemandNotice, character, clientAction, legalRepoStatus, comment, truck, industry, model, segment, dailyBucket, dailyAge, clientProfile, transactionDate,
            assignee, bouncedReason, otherReason, overdue, customerName, chequeNumber, salesExe, amountTotal}

    }
    catch(e){
        console.log(e);
    }

}


async function createProvisionReport({overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch}){

    const today = moment();

        let successArray = [];

        const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
        fetch(beforeUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                let dates = {};
                let tanzania = false;

                if(project.currency === "TSH"){
                    tanzania = true;
                }

                response.forEach(term => {
                    const customerID = term.customerID;
                    const customerName = term.customerName;
                    let legalStatus = 0;


                    if ("legalRepoStatus" in term){
                        const legalRepoStatus = term.legalRepoStatus;
                        const position = legalRepoStatus.search(/legal/i);
                        if (position >= 0){
                            legalStatus = position + 1;
                        }
                    }

                    if (term.currency === "tzs"){
                        tanzania = true;
                    }

                    //check if dueDateID already exists in dates object
                    if (`${customerID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${customerID}`].terms;
                        terms.push(term);

                        const status = dates[`${customerID}`].legalStatus;
                        const newStatus = status + legalStatus;

                        dates[`${customerID}`].terms = terms;
                        dates[`${customerID}`].legalStatus = newStatus;
                    } else {
                        //its a new date so create new object for it
                        dates[`${customerID}`] = {
                            customerID,
                            customerName,
                            terms: [term],
                            termsStore: response,
                            tanzania,
                            legalStatus
                        }
                    }
                })

                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "customerID");

                    sortedDates.map(data => {
                        const customerID = data.customerID;
                        const customerName = data.customerName;
                        const terms = data.terms;
                        const termsStore = data.termsStore;
                        const legalStatus = data.legalStatus;
                        const tanzania = data.tanzania;

                        // console.log({ customerID, customerName, legalStatus, tanzania});

                        const res = findCustomerBucket({ customerID, customerName, terms, termsStore, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((provision) => {
                        // here is our array of dashboard data
                        if(provision.length !== 0){
                            provision.push({ customerName: "Grand Total", provision: ""});
                        }

                        createAnalysisReport({provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch});
                    })
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED});

        })

}

async function findCustomerBucket({ customerID, customerName, terms, termsStore, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, }) {

    const dueDateArray = terms.map((term) => {
        let seconds;

        if ("transactionDate" in term){
            //
            term.transactionDate.seconds? (seconds = term.transactionDate.seconds): (seconds = term.transactionDate._seconds);
        } else {
            //
            term.dueDate.seconds? (seconds = term.dueDate.seconds): (seconds = term.dueDate._seconds);
        }

        return moment.unix(seconds);
    })

    const earliestMoment = _.min(dueDateArray);
    const earliestDate = earliestMoment.toDate();
    const today = moment();
    //find the number of days from today
    const days = today.diff(earliestDate, 'days');
    // const aging = Math.round(days/30);
    // console.log({days, customerName, customerID});


    return findCustomerBucketGroup({ customerID, customerName, terms, termsStore, days, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  });
}

async function findCustomerBucketGroup({ customerID, customerName, terms, termsStore, days, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }) {

    let bucket = "";

    if (tanzania){
        //provision starts with 1-5
        switch (true) {
            case (days >= 1 && days <= 5):
                bucket = "1-5";
                break;
            case (days >= 6 && days <= 30):
                bucket = "6-30";
                break;
            case (days >= 31 && days <= 60):
                bucket = "31-60";
                break;
            case (days >= 61 && days <= 90):
                bucket = "61-90";
                break;
            case (days >= 91 && days <= 120):
                bucket = "91-120";
                break;
            case (days >= 121 && days <= 150):
                bucket = "121-150";
                break;
            case (days >= 151 && days <= 180):
                bucket = "151-180";
                break;
            case (days >= 181 && days <= 210):
                bucket = "181-210";
                break;
            case (days >= 211 && days <= 240):
                bucket = "211-240";
                break;
            case (days >= 241 && days <= 270):
                bucket = "241-270";
                break;
            case (days >= 271 && days <= 300):
                bucket = "271-300";
                break;
            case (days >= 301 && days <= 330):
                bucket = "301-330";
                break;
            case (days >= 331 && days <= 360):
                bucket = "331-360";
                break;
            case (days > 360):
                bucket = ">360";
                break;

            default:
                return null;

        }

    } else {
        //provision starts with 1-30
        switch (true) {
            case (days >= 1 && days <= 30):
                bucket = "1-30";
                break;
            case (days >= 31 && days <= 60):
                bucket = "31-60";
                break;
            case (days >= 61 && days <= 90):
                bucket = "61-90";
                break;
            case (days >= 91 && days <= 120):
                bucket = "91-120";
                break;
            case (days >= 121 && days <= 150):
                bucket = "121-150";
                break;
            case (days >= 151 && days <= 180):
                bucket = "151-180";
                break;
            case (days >= 181 && days <= 210):
                bucket = "181-210";
                break;
            case (days >= 211 && days <= 240):
                bucket = "211-240";
                break;
            case (days >= 241 && days <= 270):
                bucket = "241-270";
                break;
            case (days >= 271 && days <= 300):
                bucket = "271-300";
                break;
            case (days >= 301 && days <= 330):
                bucket = "301-330";
                break;
            case (days >= 331 && days <= 360):
                bucket = "331-360";
                break;
            case (days > 360):
                bucket = ">360";
                break;

            default:
                return null;

        }

    }

    return findCustomerProvision({customerID, customerName, terms, termsStore, bucket, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, });
}

async function findCustomerProvision({ customerID, customerName, terms, termsStore, bucket, legalStatus, tanzania, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo,  }) {

    let provision = "0%";
    //provision starts with 1-5 bucket range
    if (legalStatus > 0){
        //set provision 5 for all customers with legal status
        provision = "5%";
    } else {
        //
        switch (bucket) {
            case "1-5":
                provision = "2%";
                break;
            case "6-30":
                provision = "2%";
                break;
            case "1-30":
                provision = "2%";
                break;
            case "31-60":
                provision = "2%";
                break;
            case "61-90":
                provision = "3%";
                break;
            case "91-120":
                provision = "4%";
                break;
            case "121-150":
                provision = "4%";
                break;
            case "151-180":
                provision = "4%";
                break;
            case "181-210":
                provision = "4%";
                break;
            case "211-240":
                provision = "4%";
                break;
            case "241-270":
                provision = "4%";
                break;
            case "271-300":
                provision = "4%";
                break;
            case "301-330":
                provision = "4%";
                break;
            case "331-360":
                provision = "4%";
                break;
            case ">360":
                provision = "5%";
                break;

            default:
                return null;
        }
    }

    return ({customerID, customerName, terms, termsStore, bucket, provision, tanzania});
}

async function createAnalysisReport({provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, dispatch}) {

    const groupedDays = [{bucket: "1-30", ageing: 1}, {bucket: "31-60", ageing: 2}, {bucket: "61-90", ageing: 3}, {bucket: "91-120", ageing: 4},
        {bucket: "121-150", ageing: 5}, {bucket: "151-180", ageing: 6}, {bucket: "181-210", ageing: 7}, {bucket:  "211-240", ageing: 8}, {bucket: "241-270", ageing: 9},
        {bucket: "271-300", ageing: 10}, {bucket: "301-330", ageing: 11}, {bucket: "331-360", ageing: 12}, {bucket: ">360", ageing: 13}];

        let successArray = [];
        let reportArray = [];

        const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
        fetch(beforeUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                let dates = {};

                response.forEach(term => {
                    //check if dueDateID already exists in dates object
                    if (`${term.customerID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${term.customerID}`].terms;
                        terms.push(term);
                        dates[`${term.customerID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        dates[`${term.customerID}`] = {
                            customerID: term.customerID,
                            terms: [term],
                        }
                    }
                })

                //FIND GRAND CONTENTS
                let totalCustomers;


                //sum of customers
                if(response.length !== 0){
                    let customerStore = [];
                    response.map(term => {
                        customerStore.push(term);
                    });

                    let distinctCustomers = {};

                    customerStore.map(term => {
                        if(`${term.customerID}` in distinctCustomers) {
                            //customer already in customers object
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                //grab terms from customer object
                                let terms = distinctCustomers[`${term.customerID}`].terms;
                                terms.push(term);

                                distinctCustomers[`${term.customerID}`].terms = terms;
                            }
                        } else {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                distinctCustomers[`${term.customerID}`] = {
                                    customerID: term.customerID,
                                    customerName: term.customerName,
                                    terms: [term]
                                }
                            }
                        }
                    });

                    totalCustomers = Object.keys(distinctCustomers).length;
                }


                let groupedReport = {};

                groupedDays.forEach ((data) => {
                    //
                    //check if bucketID already exists in reports object
                    if (`${data.bucket}` in groupedReport) {
                        //customer with this bucket already exist
                    } else {
                        //its a new date so create new object for it
                        groupedReport[`${data.bucket}`] = {
                            bucket: data.bucket,
                            customers: [],
                            ageing: data.ageing,
                        }
                    }
                });


                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "customerID");

                    sortedDates.map((data) => {
                        const customerID = data.customerID;
                        const terms = data.terms;

                        const res = getGroupedEarliestBucket({ customerID, terms });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((customerData) => {

                        // here is our array of dashboard data
                        if(customerData.length !== 0){
                            //
                            customerData.map(customer => {
                                //check if bucketID already exists in reports object
                                if (`${customer.bucket}` in groupedReport) {
                                    //customer with this bucket already exist
                                    let customers = groupedReport[`${customer.bucket}`].customers;
                                    customers.push(customer);
                                    groupedReport[`${customer.bucket}`].customers = customers;
                                } else {
                                    //its a new date so create new object for it
                                    groupedReport[`${customer.bucket}`] = {
                                        bucket: customer.bucket,
                                        customers: [customer],
                                    }
                                }
                            })

                            if (!(_.isEmpty(groupedReport))) {
                                ///extract groupedReport into array so it can be sorted
                                const bucketArray = _.map(groupedReport, report => report);
                                const sortedBuckets = bucketArray.sort(function(a, b){return a.ageing - b.ageing});

                                sortedBuckets.map((data) => {
                                    const bucket = data.bucket;
                                    const customers = data.customers;

                                    const res = getNumberOfCustomersOnGroupedBucket1({ bucket, customers });
                                    reportArray.push(res);

                                })


                                Promise.all(reportArray).then((array) => {

                                    // here is our array of dashboard data
                                    if(array.length !== 0){
                                        array.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers  });
                                    }

                                    callBucketWithOverduePhase2({groupedReportPhase1: array, response, systemInfo, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch });
                                })
                            }
                        }
                    })
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED});

        })
}

async function getGroupedEarliestBucket({customerID, terms}) {

    const dueDateArray = terms.map((term) => {
        let seconds;

        if ("transactionDate" in term){
            //
            term.transactionDate.seconds? (seconds = term.transactionDate.seconds): (seconds = term.transactionDate._seconds);
        } else {
            //
            term.dueDate.seconds? (seconds = term.dueDate.seconds): (seconds = term.dueDate._seconds);
        }

        return moment.unix(seconds);
    })

    const earliestMoment = _.min(dueDateArray);
    const earliestDate = earliestMoment.toDate();
    const today = moment();
    //find the number of days from today
    const days = today.diff(earliestDate, 'days');
    // const ageing = Math.round(days/30);
    // console.log({days, customerName, customerID});

    return getCustomerGroupedBucket({ customerID, terms, days });
}

async function getCustomerGroupedBucket({ customerID, terms, days }){

    let bucket = "";

    //provision starts with 1-30
    switch (true) {
        case (days >= 1 && days <= 30):
            bucket = "1-30";
            break;
        case (days >= 31 && days <= 60):
            bucket = "31-60";
            break;
        case (days >= 61 && days <= 90):
            bucket = "61-90";
            break;
        case (days >= 91 && days <= 120):
            bucket = "91-120";
            break;
        case (days >= 121 && days <= 150):
            bucket = "121-150";
            break;
        case (days >= 151 && days <= 180):
            bucket = "151-180";
            break;
        case (days >= 181 && days <= 210):
            bucket = "181-210";
            break;
        case (days >= 211 && days <= 240):
            bucket = "211-240";
            break;
        case (days >= 241 && days <= 270):
            bucket = "241-270";
            break;
        case (days >= 271 && days <= 300):
            bucket = "271-300";
            break;
        case (days >= 301 && days <= 330):
            bucket = "301-330";
            break;
        case (days >= 331 && days <= 360):
            bucket = "331-360";
            break;
        case (days > 360):
            bucket = ">360";
            break;

    }

    return {customerID, terms, days, bucket}

}

async function getNumberOfCustomersOnGroupedBucket1({ bucket, customers }){

    const numberOfCustomers = customers.length;

    return {numberOfCustomers, groupedBucket: bucket};
}


async function callBucketWithOverduePhase2({groupedReportPhase1, response, systemInfo, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){

    let successArray = [];

    if(response.length !== 0){
        let termsArray = [];
        let dates = {};

        response.forEach(term => {
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                termsArray.push(term);
            }
        });

        let sumOfODInUSD;


        //sum of OD in usd
        let usdTermStore = [];
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });

        sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);

        if (termsArray.length !== 0){
            termsArray.map(term => {
                //grab the date and change it into a string
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
                const dueDate = moment.unix(seconds);
                const today = moment();
                //grab the month and year
                const dueDateID = today.format("MMYYYY");

                //check if dueDateID already exists in dates object

                //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
                if (`${dueDateID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${dueDateID}`].terms;

                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    //check if bucket already exists
                    //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                    if (`${bucket}` in terms) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = terms[`${bucket}`];

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        terms[`${bucket}`] = termBucket;

                        //assign the new objects as new values of the the existing terms object in the date object
                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        terms[`${bucket}`] = [term];
                        dates[`${dueDateID}`].terms = terms;
                    }


                    //dates[`${dueDateID}`].terms = terms;
                } else {
                    //its a new date so create new object for it
                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    let terms = {};
                    terms[`${bucket}`] = [term];

                    dates[`${dueDateID}`] = {
                        date: dueDate,
                        terms,
                        termsArray
                    }
                }
            });
        }

        //sort the dates array
        const datesArray = _.map(dates, date => date);
        const sortedDates = arraySort(datesArray, "date");

        if(sortedDates.length !== 0){
            sortedDates.forEach(date => {
                _.map(date.terms, (terms, key) => {
                    // console.log({ageing: key, terms});
                    const response = findBucketOD({ageing: key, terms, systemInfo});
                    successArray.push(response);
                });
            })
        }

        //
        Promise.all(successArray).then((array) => {

            //here is our array of dashboard data
            if(array.length !== 0){
                array.push({ groupedBucket: "Grand Total", totalOverdue: sumOfODInUSD  });
            }

            callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2: array, response, systemInfo, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
        })
    }
}

async function findBucketOD({ageing, terms, systemInfo}) {

    // const dates  = {};
    let amount = 0;
    let overdue = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];

    terms.forEach(term => {

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    overdue = roundAccurately(sumOfODInUSD, 2);

    let bucket = "";

    switch (ageing) {
        case "1":
            bucket = "1-30";
            break;
        case "2":
            bucket = "31-60";
            break;
        case "3":
            bucket = "61-90";
            break;
        case "4":
            bucket = "91-120";
            break;
        case "5":
            bucket = "121-150";
            break;
        case "6":
            bucket = "151-180";
            break;
        case "7":
            bucket = "181-210";
            break;
        case "8":
            bucket = "211-240";
            break;
        case "9":
            bucket = "241-270";
            break;
        case "10":
            bucket = "271-300";
            break;
        case "11":
            bucket = "301-330";
            break;
        case "12":
            bucket = "331-360";
            break;
        case "13":
            bucket = ">360";
            break;
    }

    return {ageing, groupedBucket: bucket, totalOverdue: overdue};
}

async function callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2, response, systemInfo, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}) {

    let successArray = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    try {
        //
        let dates = {};
        let buckets = {};

        response.forEach(term => {
            let seconds;
            if ("transactionDate" in term) {
                if (term.transactionDate) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }

            const dueDate = moment.unix(seconds);

            //put term in current month bucket
            //grab the month and year
            const dueDateID = dueDate.format("MMYYYY");

            //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
            if (`${dueDateID}` in dates) {
                //terms with this date already exist
                let terms = dates[`${dueDateID}`].terms;

                //calculate bucket
                const endOfMonth = dueDate.endOf('month');

                //grab end of month of next month
                const today = moment();
                const nextMonth = today.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');

                //find the number of days from today
                let fromNow = endOfNextMonth.diff(endOfMonth, 'days');
                fromNow = Math.round(fromNow/30);

                //find the number of days from today
                // const fromNow = today.diff(dueDate, 'days');

                //find the bucket
                let bucket = '';
                switch (true) {
                    case (fromNow === 1):
                        bucket = 1;
                        break;
                    case (fromNow === 2):
                        bucket = 2;
                        break;
                    case (fromNow === 3):
                        bucket = 3;
                        break;
                    case (fromNow === 4):
                        bucket = 4;
                        break;
                    case (fromNow === 5):
                        bucket = 5;
                        break;
                    case (fromNow === 6):
                        bucket = 6;
                        break;
                    case (fromNow === 7):
                        bucket = 7;
                        break;
                    case (fromNow === 8):
                        bucket = 8;
                        break;
                    case (fromNow === 9):
                        bucket = 9;
                        break;
                    case (fromNow === 10):
                        bucket = 10;
                        break;
                    case (fromNow === 11):
                        bucket = 11;
                        break;
                    case (fromNow === 12):
                        bucket = 12;
                        break;
                    case (fromNow >= 13):
                        bucket = 13;
                        break;
                }

                //check if bucket already exists
                //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                if(bucket !== undefined && bucket !== null && bucket !== ''){
                    if (`${bucket}` in buckets) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = buckets[`${bucket}`].terms;

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        buckets[`${bucket}`].terms = termBucket;
                    } else {
                        buckets[`${bucket}`] = {
                            bucket: bucket,
                            terms: [term]
                        };
                    }
                }

            } else {
                //its a new date so create new object for it
                //calculate bucket
                //grab the end of the month
                //const endOfMonth = dueDate.endOf('month');

                //calculate bucket
                const endOfMonth = dueDate.endOf('month');

                //grab end of month of next month
                const today = moment();
                const nextMonth = today.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');

                //find the number of days from today
                let fromNow = endOfNextMonth.diff(endOfMonth, 'days');
                fromNow = Math.round(fromNow/30);


                //find the bucket
                let bucket = '';
                switch (true) {
                    case (fromNow === 1):
                        bucket = 1;
                        break;
                    case (fromNow === 2):
                        bucket = 2;
                        break;
                    case (fromNow === 3):
                        bucket = 3;
                        break;
                    case (fromNow === 4):
                        bucket = 4;
                        break;
                    case (fromNow === 5):
                        bucket = 5;
                        break;
                    case (fromNow === 6):
                        bucket = 6;
                        break;
                    case (fromNow === 7):
                        bucket = 7;
                        break;
                    case (fromNow === 8):
                        bucket = 8;
                        break;
                    case (fromNow === 9):
                        bucket = 9;
                        break;
                    case (fromNow === 10):
                        bucket = 10;
                        break;
                    case (fromNow === 11):
                        bucket = 11;
                        break;
                    case (fromNow === 12):
                        bucket = 12;
                        break;
                    case (fromNow >= 13):
                        bucket = 13;
                        break;
                }

                let terms = {};
                terms[`${bucket}`] = [term];

                dates[`${dueDateID}`] = {
                    date: dueDate,
                    terms,
                }

                if(bucket !== undefined && bucket !== null && bucket !== ''){
                    if (`${bucket}` in buckets) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = buckets[`${bucket}`].terms;

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        buckets[`${bucket}`].terms = termBucket;
                    } else {
                        buckets[`${bucket}`] = {
                            bucket: bucket,
                            terms: [term]
                        };
                    }
                }
            }
        })

        //FIND GRAND CONTENTS
        let totalCustomers;
        let totalODArr = [];

        //sum of customers
        if(response.length !== 0){
            let customerStore = [];
            let amount = 0;
            response.map(term => {
                //
                customerStore.push(term);

                if (term.currency === "usd"){
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    totalODArr.push(amount);

                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;

                    totalODArr.push(amount);
                }

            });

            let distinctCustomers = {};

            customerStore.map(term => {
                if(`${term.customerID}` in distinctCustomers) {
                    //customer already in customers object
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        //grab terms from customer object
                        let terms = distinctCustomers[`${term.customerID}`].terms;
                        terms.push(term);

                        distinctCustomers[`${term.customerID}`].terms = terms;
                    }
                } else {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared do nothing
                    } else {
                        distinctCustomers[`${term.customerID}`] = {
                            customerID: term.customerID,
                            customerName: term.customerName,
                            terms: [term]
                        }
                    }
                }
            });

            totalCustomers = Object.keys(distinctCustomers).length;
        }


        let debtTotal = totalODArr.reduce((a, b) => a + b, 0);
        debtTotal = roundAccurately(debtTotal, 2);

        //for examining each cheque contents
        if (!(_.isEmpty(buckets))) {

            //sort the dates array
            const datesArray = _.map(buckets, date => date);
            const sortedDates = arraySort(datesArray, "bucket");

            if(sortedDates.length !== 0){
                sortedDates.forEach(date => {
                    const response = getGroupedBucket({ageing: date.bucket, terms: date.terms, systemInfo});
                    successArray.push(response);
                })
            }

            Promise.all(successArray).then((x) => {
                let value = x.filter(function (el) { return el != null; });

                // here is our array of dashboard data
                if(value.length !== 0){

                    value.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers, debtTotal});
                }

                createDBSegmentReport({value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
            })
        }
    } catch (e) {
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});

    }
}

async function getGroupedBucket({ ageing, terms, systemInfo }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    // const dates  = {};
    let amount = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];
    let customerBucket = {};

    terms.forEach(term => {

        const customerID = term.customerID;

        if(`${term.customerID}` in customerBucket) {
            //grab terms from customer object
            let terms = customerBucket[`${customerID}`].terms;
            terms.push(term);

            customerBucket[`${customerID}`].terms = terms;
        } else {
            //check that term is not cleared
            customerBucket[`${customerID}`] = {
                customerID: customerID,
                terms: [term]
            }
        }

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    //find bucket
    let bucket = "";

    switch (ageing) {
        case 1:
            bucket = "1-30";
            break;
        case 2:
            bucket = "31-60";
            break;
        case 3:
            bucket = "61-90";
            break;
        case 4:
            bucket = "91-120";
            break;
        case 5:
            bucket = "121-150";
            break;
        case 6:
            bucket = "151-180";
            break;
        case 7:
            bucket = "181-210";
            break;
        case 8:
            bucket = "211-240";
            break;
        case 9:
            bucket = "241-270";
            break;
        case 10:
            bucket = "271-300";
            break;
        case 11:
            bucket = "301-330";
            break;
        case 12:
            bucket = "331-360";
            break;
        case 13:
            bucket = ">360";
            break;
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const debtTotal = roundAccurately(sumOfODInUSD, 2);

    const numberOfCustomers = Object.keys(customerBucket).length;

    return { numberOfCustomers, debtTotal, groupedBucket: bucket, terms};

}

async function createDBSegmentReport({value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){
    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("segment");
    const info = await infoRef.get();

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let successArray = [];


    if(systemDoc.exists){

        const systemInfo = systemDoc.data();
        const localCurrency = systemInfo.defaultCurrency;
        const url = `${project.serverUrl}fetchAllBeforeTerms`;

        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((allBeforeTerms) => {

                const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
                fetch(beforeUrl, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {'Content-Type': 'application/json'},
                }).then((response) => response.json())
                    .then((response) => {

                        let dates = {};

                        const exchangeRate = systemInfo.exchangeRate;

                        response.forEach(term => {

                            let seconds;
                            if ("transactionDate" in term) {
                                if (term.transactionDate) {
                                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                            } else {
                                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                            }

                            const dueDate = moment.unix(seconds);
                            const endOfMonth = dueDate.endOf('month').format("DD-MM-YYYY");

                            //put term in current month bucket
                            //grab the month and year
                            const dueDateID = dueDate.format("MMYYYY");
                            const year = dueDate.format("YYYY");

                            //if loan is in usd convert the overdue to default currency
                            let termAmount;
                            if (term.currency === "usd") {
                                //grab the total overdue
                                if ("modulo" in term) {
                                    termAmount = term.amount - term.modulo;
                                } else {
                                    termAmount = term.amount;
                                }

                            } else {
                                //fetch system info exchange rate
                                const exchangeRate = systemInfo.exchangeRate;

                                //grab the total overdue
                                if ("modulo" in term) {
                                    termAmount = term.amount - term.modulo;
                                } else {
                                    termAmount = term.amount;
                                }

                                termAmount = termAmount/exchangeRate;
                                termAmount = roundAccurately(termAmount, 2);
                            }

                            //check if dueDateID already exists in dates object
                            if (`${dueDateID}` in dates) {
                                //terms with this date already exist
                                let terms = dates[`${dueDateID}`].terms;
                                terms.push(term);

                                let overdueTerms = dates[`${dueDateID}`].overdueTerms;
                                overdueTerms.push(termAmount);

                                dates[`${dueDateID}`].terms = terms;
                                dates[`${dueDateID}`].overdueTerms = overdueTerms;
                            } else {
                                //its a new date so create new object for it
                                dates[`${dueDateID}`] = {
                                    dateID: dueDateID,
                                    date: endOfMonth,
                                    terms: [term],
                                    termsStore: response,
                                    year,
                                    overdueTerms: [termAmount]
                                }
                            }
                        })

                        //FIND GRAND CONTENTS
                        let totalCustomers;
                        let totalCustomerBlank;
                        let totalCustomerDAEWOO;
                        let totalCustomerTML;
                        let sumOfODInUSD;
                        let sumOfODInUSDBlank;
                        let sumOfODInUSDDAEWOO;
                        let sumOfODInUSDTML;


                        //sum of OD in usd
                        let usdTermStore = [];
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount/exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });

                        sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                        sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

                        //sum of customers
                        let customerStore = [];
                        if(response.length !== 0){
                            response.map(term => {
                                customerStore.push(term);
                            });

                            let distinctCustomers = {};

                            customerStore.map(term => {
                                if(`${term.customerID}` in distinctCustomers) {
                                    //customer already in customers object
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        //grab terms from customer object
                                        let terms = distinctCustomers[`${term.customerID}`].terms;
                                        terms.push(term);

                                        distinctCustomers[`${term.customerID}`].terms = terms;
                                    }
                                } else {
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        distinctCustomers[`${term.customerID}`] = {
                                            customerID: term.customerID,
                                            customerName: term.customerName,
                                            terms: [term]
                                        }
                                    }
                                }
                            });

                            totalCustomers = Object.keys(distinctCustomers).length;
                        }


                        //sum of OD in usd for blank segment
                        let usdTermStoreBlank = [];
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                if("segment" in term) {
                                    //
                                    if(term.segment === ""){
                                        //if loan is in usd convert the overdue to default currency
                                        if (term.currency === "usd") {
                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            amount = roundAccurately(amount, 2);
                                            usdTermStoreBlank.push(amount);
                                        } else {
                                            //fetch system info exchange rate
                                            const exchangeRate = systemInfo.exchangeRate;

                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            let convertedAmount = amount/exchangeRate;
                                            convertedAmount = roundAccurately(convertedAmount, 2);

                                            usdTermStoreBlank.push(convertedAmount);
                                        }
                                    }
                                } else {
                                    //if loan is in usd convert the overdue to default currency
                                    if (term.currency === "usd") {
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        amount = roundAccurately(amount, 2);
                                        usdTermStoreBlank.push(amount);
                                    } else {
                                        //fetch system info exchange rate
                                        const exchangeRate = systemInfo.exchangeRate;

                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        let convertedAmount = amount/exchangeRate;
                                        convertedAmount = roundAccurately(convertedAmount, 2);

                                        usdTermStoreBlank.push(convertedAmount);
                                    }
                                }
                            }
                        });

                        sumOfODInUSDBlank = usdTermStoreBlank.reduce((a, b) => a + b, 0);

                        //total customers with blank segment
                        let blankSegment = [];
                        if(response.length !== 0){
                            response.map(term => {
                                //sort terms based on segment
                                if("segment" in term) {
                                    //
                                    if(term.segment === ""){
                                        blankSegment.push(term);
                                    }
                                } else {
                                    blankSegment.push(term);
                                }
                            });

                            let distinctCustomersWithBlankSegment = {};

                            blankSegment.map(term => {
                                if(`${term.customerID}` in distinctCustomersWithBlankSegment) {
                                    //customer already in customers object
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        //grab terms from customer object
                                        let terms = distinctCustomersWithBlankSegment[`${term.customerID}`].terms;
                                        terms.push(term);

                                        distinctCustomersWithBlankSegment[`${term.customerID}`].terms = terms;
                                    }
                                } else {
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        distinctCustomersWithBlankSegment[`${term.customerID}`] = {
                                            customerID: term.customerID,
                                            customerName: term.customerName,
                                            terms: [term]
                                        }
                                    }
                                }
                            });

                            totalCustomerBlank = Object.keys(distinctCustomersWithBlankSegment).length;
                        }


                        //sum of OD in usd for tml segment
                        let usdTermStoreTml = [];
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //sort terms based on segment
                                if("segment" in term) {
                                    //
                                    if (term.segment === "TML"){
                                        //if loan is in usd convert the overdue to default currency
                                        if (term.currency === "usd") {
                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            usdTermStoreTml.push(amount);
                                        } else {
                                            //fetch system info exchange rate
                                            const exchangeRate = systemInfo.exchangeRate;

                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            let convertedAmount = amount/exchangeRate;
                                            convertedAmount = roundAccurately(convertedAmount, 2);

                                            usdTermStoreTml.push(convertedAmount);
                                        }
                                    }
                                }
                            }
                        });

                        sumOfODInUSDTML = usdTermStoreTml.reduce((a, b) => a + b, 0);

                        //total customers with tml segment
                        let tmlSegment = [];
                        if(response.length !== 0){
                            response.map(term => {
                                //sort terms based on segment
                                if("segment" in term) {
                                    //
                                    if (term.segment === "TML"){
                                        tmlSegment.push(term);
                                    }
                                }
                            });

                            let distinctCustomersWithTmlSegment = {};

                            tmlSegment.map(term => {
                                if(`${term.customerID}` in distinctCustomersWithTmlSegment) {
                                    //customer already in customers object
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        //grab terms from customer object
                                        let terms = distinctCustomersWithTmlSegment[`${term.customerID}`].terms;
                                        terms.push(term);

                                        distinctCustomersWithTmlSegment[`${term.customerID}`].terms = terms;
                                    }
                                } else {
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        distinctCustomersWithTmlSegment[`${term.customerID}`] = {
                                            customerID: term.customerID,
                                            customerName: term.customerName,
                                            terms: [term]
                                        }
                                    }
                                }
                            });

                            totalCustomerTML = Object.keys(distinctCustomersWithTmlSegment).length;
                        }

                        //sum of OD in usd for daewoo segment
                        let usdTermStoreDaewoo = [];
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                if("segment" in term) {
                                    //
                                    if (term.segment === "DAEWOO"){
                                        //if loan is in usd convert the overdue to default currency
                                        if (term.currency === "usd") {
                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            usdTermStoreDaewoo.push(amount);
                                        } else {

                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            let convertedAmount = amount/exchangeRate;
                                            convertedAmount = roundAccurately(convertedAmount, 2);

                                            usdTermStoreDaewoo.push(convertedAmount);
                                        }
                                    }
                                }
                            }
                        });

                        sumOfODInUSDDAEWOO = usdTermStoreDaewoo.reduce((a, b) => a + b, 0);

                        //total customers with daewoo segment
                        let daewooSegment = [];
                        if(response.length !== 0){
                            response.map(term => {
                                //sort terms based on segment
                                if("segment" in term) {
                                    //
                                    if (term.segment === "DAEWOO"){
                                        daewooSegment.push(term);
                                    }
                                }
                            });

                            let distinctCustomersWithDaewooSegment = {};

                            daewooSegment.map(term => {
                                if(`${term.customerID}` in distinctCustomersWithDaewooSegment) {
                                    //customer already in customers object
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        //grab terms from customer object
                                        let terms = distinctCustomersWithDaewooSegment[`${term.customerID}`].terms;
                                        terms.push(term);

                                        distinctCustomersWithDaewooSegment[`${term.customerID}`].terms = terms;
                                    }
                                } else {
                                    //check that term is not cleared
                                    if (term.termStatus.status) {
                                        //term is cleared do nothing
                                    } else {
                                        distinctCustomersWithDaewooSegment[`${term.customerID}`] = {
                                            customerID: term.customerID,
                                            customerName: term.customerName,
                                            terms: [term]
                                        }
                                    }
                                }
                            });

                            totalCustomerDAEWOO = Object.keys(distinctCustomersWithDaewooSegment).length;
                        }

                        //for examining each cheque contents
                        if (!(_.isEmpty(dates))) {
                            ///extract date into array so it can be sorted
                            const datesArray = _.map(dates, date => date);
                            // const sortedDates = arraySort(datesArray, "dateID");
                            const sortedDates = datesArray.sort(function(a, b){return a.dateID - b.dateID});
                            const sortedArray = sortedDates.sort(function(a, b){return a.year - b.year});

                            sortedArray.map(data => {
                                const date = data.date;
                                const terms = data.terms;
                                const overdueTerms = data.overdueTerms;

                                // console.log({ID: data.dateID, terms: terms, date: date});

                                const res = getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate });
                                successArray.push(res);

                            })

                            Promise.all(successArray).then((x) => {

                                //here is our array of dashboard data
                                let dbSegmentArray = [];
                                if(x.length !== 0){
                                    x.push({ date: "Grand Total", segments: {Blank: {terms: blankSegment, totalOverdue: usdTermStoreBlank},
                                            DAEWOO: {terms: daewooSegment, totalOverdue: usdTermStoreDaewoo}, TML: {terms: tmlSegment, totalOverdue: usdTermStoreTml}},
                                        customers: totalCustomers, totalOD: sumOfODInUSD});

                                    x.map(cheque => {

                                        let blankNumber = {};
                                        let daewooNumber = {};
                                        let tmlNumber = {};

                                        const segmentData = cheque.segments;
                                        if('Blank' in segmentData){
                                            const blank = segmentData.Blank;
                                            if(blank.terms.length !== 0){
                                                blank.terms.forEach((term) => {
                                                    const customerID = term.customerID;
    
                                                    if (`${customerID}` in blankNumber) {
                                                        //terms with this segment already exist
                                                        let terms = blankNumber[`${customerID}`].terms;
                                                        terms.push(term);
    
                                                        blankNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        blankNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }
                                        }
                                        
                                        if('DAEWOO' in segmentData){
                                            const daewoo = segmentData.DAEWOO;
                                            if (daewoo.terms.length !== 0){
                                                daewoo.terms.forEach((term) => {
                                                    const customerID = term.customerID;
    
                                                    if (`${customerID}` in daewooNumber) {
                                                        //terms with this segment already exist
                                                        let terms = daewooNumber[`${customerID}`].terms;
                                                        terms.push(term);
    
                                                        daewooNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        daewooNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }
                                        }

                                        if('TML' in segmentData){
                                            const tml = segmentData.TML;
                                            if (tml.terms.length !== 0){
                                                tml.terms.forEach((term) => {
                                                    const customerID = term.customerID;
    
                                                    if (`${customerID}` in tmlNumber) {
                                                        //terms with this segment already exist
                                                        let terms = tmlNumber[`${customerID}`].terms;
                                                        terms.push(term);
    
                                                        tmlNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        tmlNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }
                                        }

                                        let blankOverdue = 0;
                                        if('DAEWOO' in segmentData){
                                            const blank = segmentData.Blank;
                                            blankOverdue = blank.totalOverdue.reduce((a, b) => a + b, 0);

                                        }

                                        let daewooOverdue = 0;
                                        if('DAEWOO' in segmentData){
                                            const daewoo = segmentData.DAEWOO;
                                            daewooOverdue = daewoo.totalOverdue.reduce((a, b) => a + b, 0);

                                        }

                                        let tmlOverdue = 0;
                                        if('TML' in segmentData){
                                            const tml = segmentData.TML;
                                            tmlOverdue = tml.totalOverdue.reduce((a, b) => a + b, 0);

                                        }
                                        

                                        const blankCustomers = Object.keys(blankNumber).length;
                                        const daewooCustomers = Object.keys(daewooNumber).length;
                                        const tmlCustomers = Object.keys(tmlNumber).length;

                                        dbSegmentArray.push({eqm: cheque.date, numberOfCustomersBlank: blankCustomers, blankODInUSD: blankOverdue, numberOfCustomersDaewoo: daewooCustomers, 
                                            daewooODInUSD: daewooOverdue, numberOfCustomersTML: tmlCustomers, tmlODInUSD: tmlOverdue, distinctCountOfCustomers: cheque.customers,
                                            totalSumOfODInUSD: cheque.totalOD
                                        });
                                    });
                                }

                                dbIndustryReport({dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch})
                            })
                        }

                    }).catch((error) => {
                    console.log("Here's your error");
                    console.log(error);
                    dispatch({type: ALL_REPORT_FAILED});

                })

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED});

        })
    }
}


async function getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate }){

const segmentCustomers = {};

terms.forEach(term => {

    const customerID = term.customerID;

    //check if customerID already exists in dates object
    if (`${customerID}` in segmentCustomers) {
        //terms with this segment already exist
        let terms = segmentCustomers[`${customerID}`].terms;
        terms.push(term);

        segmentCustomers[`${customerID}`].terms = terms;
    } else {
        segmentCustomers[`${customerID}`] = {
            terms: [term],
        }
    }
})

const customers = Object.keys(segmentCustomers).length;

return getSegment({ date, terms, info, overdueTerms, customers, exchangeRate });

}


async function getSegment({ date, terms, info, overdueTerms, customers, exchangeRate }){

const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

const buckets = {};
//its a new industry so create new object for it
buckets[`${"Blank"}`] = {
    segment: "Blank",
    terms: [],
    totalOverdue: [],
}

if (info.size !== 0){
    info.forEach(doc => {
        const data = doc.data();
        const value = data.value;

        //its a new industry so create new object for it
        buckets[`${value}`] = {
            segment: value,
            terms: [],
            totalOverdue: [],
        }
    })
}

terms.forEach(term => {

    let seconds;
    if ("transactionDate" in term) {
        if (term.transactionDate) {
            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        }
    } else {
        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
    }

    const dueDate = moment.unix(seconds);
    const endOfMonth = dueDate.format("DD-MM-YYYY");

    let amount = 0;
    if (term.currency === "usd") {
        //grab the total overdue
        if ("modulo" in term) {
            amount = term.amount - term.modulo;
        } else {
            amount = term.amount;
        }

    } else {

        if ("modulo" in term) {
            amount = term.amount - term.modulo;
        } else {
            amount = term.amount;
        }

        amount = amount/exchangeRate;
        amount = roundAccurately(amount, 2);
    }

    let segmentID;
    if ("segment" in term) {
        //
        if(term.segment !== ""){
            segmentID = term.segment;
        } else {
            segmentID = "Blank";
        }
    } else {
        segmentID = "Blank";
    }

    //check if segmentID already exists in dates object
    if (`${segmentID}` in buckets) {
        //terms with this segment already exist
        let terms = buckets[`${segmentID}`].terms;
        terms.push(term);

        let overdue = buckets[`${segmentID}`].totalOverdue;
        overdue.push(amount);

        buckets[`${segmentID}`].terms = terms;
        buckets[`${segmentID}`].totalOverdue = overdue;
    } else {
        buckets[`${segmentID}`] = {
            totalOverdue: [amount],
            terms: [term],
        }
    }
})

const overdue = overdueTerms.reduce((a, b) => a + b, 0);
const totalOD = roundAccurately(overdue, 2);

    if(!(_.isEmpty(buckets))) {
        // console.log({date: date, segments: buckets, customers: customers, OD: totalOD});

        return {date, segments: buckets, totalOD, customers};
    }

}


async function dbIndustryReport({ dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){
    //

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("industry");
        const info = await infoRef.get();

        let successArray = [];

        const today = moment();


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    let industries = {};

                    //put industry values into industries object
                    if (info.size !== 0){
                        info.forEach(doc => {
                            const data = doc.data();
                            const value = data.value;

                            //its a new industry so create new object for it
                            industries[`${value}`] = {
                                industry: value,
                                terms: [],
                                termsStore: response
                            }
                        })
                    }

                    response.forEach(term => {
                        let industryID;
                        if ("industry" in term) {
                            industryID = term.industry;
                        } else {
                            industryID = "Blank";
                        }

                        //check if industryID already exists in dates object
                        if (`${industryID}` in industries) {
                            //terms with this industry already exist
                            let terms = industries[`${industryID}`].terms;
                            terms.push(term);

                            industries[`${industryID}`].terms = terms;
                        } else {
                            //its a new industry so create new object for it
                            industries[`${industryID}`] = {
                                industry: industryID,
                                terms: [term],
                                termsStore: response
                            }
                        }
                    })

                    //FIND GRAND CONTENTS
                    let sumOfODInUSD;

                    //sum of OD in usd
                    let usdTermStore = [];
                    if (response.length !== 0){
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount/exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });
                    }

                    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);

                    //for examining each cheque contents
                    if (!(_.isEmpty(industries))) {
                        ///extract date into array so it can be sorted
                        const industryArray = _.map(industries, date => date);
                        const sortedIndustries = arraySort(industryArray, "industry");

                        sortedIndustries.map(data => {
                            const industry = data.industry;
                            const terms = data.terms;

                            const res = getSumOfODWithIndustryInUSD({ systemInfo, industry, terms });
                            successArray.push(res);

                        })

                        Promise.all(successArray).then((array) => {

                            // // here is our array of dashboard data
                            if(array.length !== 0){
                                array.push({ industry: "Grand Total", totalODInUsd: sumOfODInUSD });
                            }

                            createCollectionTracker({ industryDataArray: array, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch})
                        })
                    }

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED});

            })
        }
}


async function getSumOfODWithIndustryInUSD({ systemInfo, industry, terms }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, industry }
}


async function createCollectionTracker({ industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}) {

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        const systemInfo = systemDoc.data();
        const exchangeRate = systemInfo.exchangeRate;

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        //fetch end of last month overdue terms
        const date = moment().subtract(1, "months").endOf('month').format("DD_MM_YYYY");
        console.log(date);

        //get specific date overdue data
        const reportID = `masterlistNew_${date}`;

        const info = JSON.stringify({ reportID, path: "masterListNew" });

        //invoke custom database function
        const url1 = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url1, {
            method: 'POST',
            mode: 'cors',
            body: info,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                //find the total overdue as of end of last month
                let termsStore = [];

                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        //term has any of repossess status
                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            //if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                termsStore.push(amount);
                            } else {
    
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                const convertedAmount = amount/exchangeRate;
    
                                termsStore.push(convertedAmount);
                            }
                        }
                    });
                })

                //CALCULATE OPENING OD
                let openingOverdue = termsStore.reduce((a, b) => a + b, 0);
                    openingOverdue = roundAccurately(openingOverdue, 2);

                //FETCH ANALYTICS DATA
                const url = `${project.serverUrl}fetchAnalytics`;
                fetch(url, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {'Content-Type': 'application/json'},
                }).then((response) => response.json())
                    .then((data) => {

                        //for examining each cheque contents
                        if (!(_.isEmpty(data))) {

                            console.log({analytics: data});

                            let overdueArray = [];
                            const month = moment().startOf('month').format("DD-MM-YYYY");

                            data["openingOD"] = openingOverdue;
                            console.log(data)

                            overdueArray.push({value: openingOverdue, label: `Opening OD as of ${month}`, key: "1"});

                            _.map(data, (value, key) => {

                                switch (key) {
                                    case "totalOverdue":
                                        let overdue = value/exchangeRate;
                                        overdue = roundAccurately(overdue, 2);
                                        overdueArray.push({value: overdue, label: "Current OD per current month", key: "2"});
                                        break;

                                    case "bouncedTd":
                                        let bouncedOverdue = value/exchangeRate;
                                        bouncedOverdue = roundAccurately(bouncedOverdue, 2);
                                        overdueArray.push({value: bouncedOverdue, label: "Bounces for the month", key: "3"});
                                        break;
                                }
                            });


                            const bouncedTd = data.bouncedTd/exchangeRate;
                            let totalOverdue = openingOverdue + bouncedTd;

                            totalOverdue = roundAccurately(totalOverdue, 2);
                            overdueArray.push({value: totalOverdue, label: "Total OD", key: "4"});

                            // const currentMonthOD = data.totalOverdue/exchangeRate;
                            const monthCollections = data.odCollTd/exchangeRate;

                            // const monthCollections = totalOverdue - currentMonthOD;


                            ///extract date into array so it can be sorted
                            if (overdueArray.length !== 0){
                                overdueArray = arraySort(overdueArray, "key");

                                overdueArray.push({ label: "COLLECTIONS FOR THE MONTH", value: monthCollections });

                            }

                            collectionTrackerReportOne11({collectionTracker: overdueArray, analytics: data, exchangeRate, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});

                        }

                    })

            })
            .catch(e => {
                console.log(e);
                dispatch({type: ALL_REPORT_FAILED});
            })

};

async function collectionTrackerReportOne11({collectionTracker, analytics, exchangeRate, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let overdueArray = [];

    //for examining each cheque contents
    if (!(_.isEmpty(analytics))) {

        //initial values
        const currentOD = analytics.totalOverdue/exchangeRate;
        const tc = analytics.tc/exchangeRate;
        const pdcTD = analytics.pdcTd/exchangeRate;
        const bouncedTD = analytics.bouncedTd/exchangeRate;
        const openingOD = analytics.openingOD;
        const pdcFtm  = analytics.pdcFtm/exchangeRate;

        //due collections
        let dueCollection = pdcTD - bouncedTD;
        const dcRatio = (dueCollection/pdcTD) * 100;
        const dueCollectionRatio = roundAccurately(dcRatio, 2);
        dueCollection = roundAccurately(dueCollection, 2);

        overdueArray.push({amount: dueCollection, description: "Due Collections", ratio: `${dueCollectionRatio}%`, key: "1"});

        //overdue collections
        let overdueCollection = analytics.odCollTd/exchangeRate;
        const ocRatio = (overdueCollection/currentOD) * 100;
        const overdueCollectionRatio = roundAccurately(ocRatio, 2);
        overdueCollection = roundAccurately(overdueCollection, 2);

        overdueArray.push({amount: overdueCollection, description: "Overdue Collections", ratio: `${overdueCollectionRatio}%`, key: "2"});

        //collections efficiency
        let collectionEfficiency = analytics.tc/exchangeRate;
        const ceRatio = (tc/pdcTD) * 100;
        const collectionEfficiencyRatio = roundAccurately(ceRatio, 2);
        collectionEfficiency = roundAccurately(collectionEfficiency, 2);

        overdueArray.push({amount: collectionEfficiency, description: "Collections Efficiency", ratio: `${collectionEfficiencyRatio}%`, key: "3"});

        //total collections
        let totalCollection = openingOD + pdcTD;
        const tcRatio = (tc/totalCollection) * 100;
        const totalCollectionRatio = roundAccurately(tcRatio, 2);
        totalCollection = roundAccurately(totalCollection, 2);

        overdueArray.push({amount: totalCollection, description: "Total Collections", ratio: `${totalCollectionRatio}%`, key: "4"});


        ///extract date into array so it can be sorted
        if (overdueArray.length !== 0){
            overdueArray = arraySort(overdueArray, "key");
            overdueArray.push({ description: "", amount: "", ratio: "" });
        }

        // console.log({OD: analytics.openingOD});
        return collectionTrackerReportOne({collectionTracker, collectionTracker1: overdueArray, analytics, exchangeRate, overdueOpening: analytics.openingOD, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
    }
}

async function collectionTrackerReportOne({collectionTracker, collectionTracker1, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let report = [];

    if (!(_.isEmpty(analytics))) {
        //{ date, totalOverdue }
        // console.log({analytics: analytics});
        let row = {};

        const today = moment();

        const pdcTD = analytics.pdcTd/exchangeRate;
        const bouncedTD = analytics.bouncedTd/exchangeRate;
        const pdcFTM = analytics.pdcFtm/exchangeRate;
        const openingOD = analytics.openingOD;
        const bouncedForTheMonth = analytics.bouncedTd/exchangeRate;

        let pdcBouncedTD = pdcTD - bouncedTD;
        pdcBouncedTD = roundAccurately(pdcBouncedTD, 2);

        const totalCollReqTarget = roundAccurately(pdcFTM, 2);

        const totalOverdue = openingOD + bouncedForTheMonth;
        let totalODReqTarget = totalOverdue * 0.5;
        totalODReqTarget = roundAccurately(totalODReqTarget, 2);

        row["month"]  = today.endOf('month').format("DD/MM/YYYY");
        row["pdcBouncedTD"] = pdcBouncedTD;
        row["totalODReqTarget"] = totalODReqTarget;
        row["totalCollReqTarget"] = totalCollReqTarget;
        row["tally"] = "";

        _.map(analytics, (value, key) => {

            switch (key) {
                case "totalOverdue":
                    row["totalOverdue"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "pdcFtm":
                    row["pdcFtm"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "pdcTd":
                    row["pdcTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "bouncedTd":
                    row["bouncedTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "odCollTd":
                    row["odCollTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "tc":
                    row["tc"] = roundAccurately(value/exchangeRate, 2);
                    break;
            }
        });

        report = [row];
    }

    return getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2: report, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
}


async function getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}) {

    let successArray = [];

    const getDaysOfMonth = function() {
        let daysInMonth = moment().format('DD');
        let arrDays = [];

        while(daysInMonth) {
            const current = moment().date(daysInMonth);
            arrDays.push({date: current.format('MM-DD-YYYY'), timeStamp: current});
            daysInMonth--;
        }

        return arrDays;
    };

    const dateList = getDaysOfMonth();

    analytics["currency"] = "localCurrency";

    //fetch current overdue terms
    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
    fetch(beforeUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            // console.log(response);
            const overdue = response;

            //check if both current analytics and overdue terms objects are empty
            if (!(_.isEmpty(analytics))) {

                if (dateList.length !== 0){
                    console.log(dateList);
                    //loop date list
                    dateList.forEach((date) => {
                        const response = getCollectionTrackerAnalyticsData({date: date.date, timeStamp: date.timeStamp, analyticsData: analytics, overdueTerms: overdue, exchangeRate, overdueOpening});
                        successArray.push(response);
                    });
                        //
                        Promise.all(successArray).then((dataArray) => {
                            //here is our array of dashboard data
                            if(dataArray.length !== 0){
                                // /* create a new blank workbook */
                                const wb = XLSX.utils.book_new();
                                //create report bucket to store all the excel rows as objects
                                let report = [];

                                dataArray.forEach(day => {
                                    let termRow = {};

                                    termRow["newDate"] = day.newDate;
                                    termRow["totalBook"] = day.totalBook;
                                    termRow["dueCollections"] = day.dueCollections;
                                    termRow["cumulativeCollections"] = day.cumulativeCollections;
                                    termRow["dailyCollections"] = day.dailyCollections;
                                    termRow["collectionEfficiency"] = day.collectionEfficiency;
                                    termRow["repoNumber"] = "";
                                    termRow["repoValue"] = "";
                                    termRow["legalCases"] = day.legalCases;
                                    termRow["legalValue"] = day.legalValue;
                                    termRow["overdue"] = day.overdue;
                                    termRow["deliquencyRate"] = day.deliquencyRate;
                                    termRow["bucket1"] = day.bucket1;
                                    termRow["bucket2"] = day.bucket2;
                                    termRow["bucket3"] = day.bucket3;
                                    termRow["bucket4"] = day.bucket4;
                                    termRow["overdueTarget"] = day.overdueTarget;
                                    termRow["overdueActual"] = day.overdueActual;
                                    termRow["legalRepo"] = day.legalRepo;
                                    termRow["insuranceCase"] = day.insuranceCase;
                                    termRow["repoProgress"] = day.repoProgress;
                                    termRow["promisePay"] = day.promisePay;
                                    termRow["watchlist"] = day.watchlist;
                                    termRow["caseOpening"] = day.caseOpening;
                                    termRow["requestCase"] = day.requestCase;
                                    termRow["noStatusValue"] = day.noStatusValue;
                                    termRow["underFollowUpValue"] = day.underFollowUpValue;
                                    termRow["partialReposessionValue"] = day.partialReposessionValue;
                                    termRow["nonStarterValue"] = day.nonStarterValue;


                                    report.push(termRow);
                                })

                            exportAllData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3: report, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});

                        }
                    })
                }

            } else {
                console.log("There are no data");
            }
        }).catch((error) => {
        console.log("Here's your error 1");
        console.log(error);
        dispatch({type: ALL_REPORT_FAILED})
    })
}

async function getCollectionTrackerAnalyticsData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){

    const today = moment().format("MM-DD-YYYY");
    if (date !== today){
        //get specific date analytics data
        const reportDate = timeStamp.format("DD_MM_YYYY");
        const reportID = `analytics_${reportDate}`;
        const analyticsRef = firebase.firestore().collection('analyticsDailyReports').doc(reportID);
        const doc = await analyticsRef.get();

        if (doc.exists) {
            // console.log({analytics: data});
            analyticsData = doc.data();
        }
    }

    return getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}

async function getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){


    const today = moment().format("MM-DD-YYYY");

    if (date !== today){
        let termsStore = [];
        //get specific date overdue data
        const dateID = timeStamp.format("DD_MM_YYYY");
        const reportID = `masterlistNew_${dateID}`;

        const data = JSON.stringify({ reportID, path: "masterListNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        termsStore.push(term);
                    });
                })
            })
            .catch(e => {
                console.log(e);
            })

        //assign overdue terms
        if (termsStore.length !== 0){
            overdueTerms = termsStore;
        }
    }

    // console.log({date: date, overdueTerms: overdueTerms});
    return getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}


async function getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let totalBook = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            totalBook = analyticsData.totalBook;
            totalBook = totalBook/exchangeRate;
            totalBook = roundAccurately(totalBook, 2);
        } else {
            totalBook = analyticsData.totalBook;
            totalBook = roundAccurately(totalBook, 2);
        }
    } else {
        totalBook = analyticsData.totalBook;
        totalBook = roundAccurately(totalBook, 2);
    }

    // console.log({date: date, totalBook: totalBook});

    return getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening});
}

async function getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dueCollections = 0;

    if ("currency" in analyticsData){
        if (analyticsData.currency === "localCurrency"){
            dueCollections = analyticsData.pdcTd;
            dueCollections = dueCollections/exchangeRate;
            dueCollections = roundAccurately(dueCollections, 2);
        } else {
            dueCollections = analyticsData.pdcTd;
            dueCollections = roundAccurately(dueCollections, 2);
        }
    } else {
        dueCollections = analyticsData.pdcTd;
        dueCollections = roundAccurately(dueCollections, 2);
    }

    return getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening });
}

async function getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening }) {
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let cumulativeCollections = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = cumulativeCollections/exchangeRate;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        } else {
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        }
    } else {
        cumulativeCollections = analyticsData.tc;
        cumulativeCollections = roundAccurately(cumulativeCollections, 2);
    }


    return getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening});
}

async function getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening}) {
    //get daily collections
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dailyCollections = 0;
    let collectionArray = [];

    const collectionRef = firebase.firestore().collection('cashCollections');
    const snapshot = await collectionRef.get();

    if (snapshot.size !== 0) {
        snapshot.forEach(doc => {
            const data = doc.data();

            let seconds;
            data.bankDate.seconds ? seconds = data.bankDate.seconds :  seconds = data.bankDate._seconds;

            const bankDate = moment.unix(seconds).format("MM-DD-YYYY");
            // const tday = moment();

            // if(date === bankDate){
                if(moment(bankDate).isSame(timeStamp, "day")){
                    console.log(bankDate);
                    if (data.currency === "usd") {
                        //grab the paid amount
                        collectionArray.push(data.paidAmount);
    
                    } else {
    
                        const amount = data.paidAmount/exchangeRate;
                        collectionArray.push(amount);
                    }
                }
            // }
        })
    }

    //find sum of daily collections
    dailyCollections = collectionArray.reduce((a, b) => a + b, 0);
    dailyCollections = roundAccurately(dailyCollections, 2);

    // console.log({date, totalBook: totalBook, dueCollections: dueCollections, cumulativeCollections: cumulativeCollections, collection: dailyCollections});

    return getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening});
}

async function getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening}) {
    //find collection efficiency
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let collectionEfficiency = 0;
    collectionEfficiency = cumulativeCollections/dueCollections;
    collectionEfficiency = roundAccurately(collectionEfficiency, 1);

    return getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening});
}

async function getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening}) {
    //
    let repoNumber = 0;
    let repoBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in repoBucket) {
                        //terms with this customerID already exist
                        let terms = repoBucket[`${customerID}`].terms;
                        terms.push(term);

                        repoBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        repoBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(repoBucket))){
        repoNumber = Object.keys(repoBucket).length;
    }

    // console.log({repoNumber: repoNumber});

    return getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening});
}

async function getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening}) {
    //
    let repoValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        repoValue = termStore.reduce((a, b) => a + b, 0);
        repoValue = roundAccurately(repoValue, 2);
    }

    // console.log({repoValue: repoValue});

    return getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        overdueOpening});
}

async function getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                      overdueOpening}) {
    //
    let legalCases = 0;
    let legalBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                    //term has any of legal status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in legalBucket) {
                        //terms with this customerID already exist
                        let terms = legalBucket[`${customerID}`].terms;
                        terms.push(term);

                        legalBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        legalBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(legalBucket))){
        legalCases = Object.keys(legalBucket).length;
    }

    // console.log({legalCases: legalCases});

    return getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, overdueOpening});
}

async function getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                  legalCases, overdueOpening}) {
   //
    let legalValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        legalValue = termStore.reduce((a, b) => a + b, 0);
        legalValue = roundAccurately(legalValue, 2);
    }

    // console.log({legalValue: legalValue});

    return getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdueOpening});
}

async function getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                   legalCases, legalValue, overdueOpening}) {
   //
    let overdue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if (!term.termStatus.status){
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    termStore.push(amount);
                } else {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    termStore.push(convertedAmount);
                }
            }
        })
    }

    if(termStore.length !== 0){
        overdue = termStore.reduce((a, b) => a + b, 0);
        overdue = roundAccurately(overdue, 2);
    }


    return getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, overdueOpening});
}

async function getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                       legalCases, legalValue, overdue, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const dRate = overdue/totalBook * 100;
    const deliquencyRate = roundAccurately(dRate, 2);

    return getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, overdueOpening});
}

async function getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                  legalCases, legalValue, overdue, deliquencyRate, overdueOpening}) {
    //
    let b1 = 0;
    let b2 = 0;
    let b3 = 0;
    let b4 = 0;

    let arr = [];
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    let arr4 = [];
    let arr5 = [];
    let arr6 = [];
    let arr7 = [];
    let arr8 = [];
    let arr9 = [];
    let arr10 = [];
    let arr11 = [];
    let arr12 = [];

if(overdueTerms.length !== 0){
    let terms = {};
    overdueTerms.forEach(term => {
        
        let seconds;
        if ("transactionDate" in term) {
            if(term.transactionDate){
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
            }else{
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
        }


        //grab end of month of next month
        const dueDate = moment.unix(seconds);
        const endMonth = dueDate.endOf('month');

        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');
    
        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endMonth, 'days');
    
        const bucket = Math.round(fromNow/30);
    
        let daysRange = "(0)";
        //compute date range depending on bucket
        if(bucket !== 0) {
            const endDate = bucket * 30;
            const startDate = endDate - 29;

            daysRange = `(${startDate}-${endDate})`
        }

        if(bucket !== undefined && bucket !== null){
            if (`${daysRange}` in terms) {
                let termBucket = terms[`${daysRange}`].terms;
                termBucket.push(term);
                terms[`${daysRange}`].terms = termBucket;

            } else {
                terms[`${daysRange}`] = {
                    range: daysRange,
                    terms: [term]
                }
            }
        }
    })


    const termsArr = _.map(terms, client => client );

    termsArr.forEach(term => {
        if(term.range === "(1-30)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr.push(amount);
            })
        }
        
        if(term.range === "(31-60)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr1.push(amount);
            })
        }
        
        if(term.range === "(61-90)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr2.push(amount);
            })
        }
        
        if(term.range === "(91-120)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr3.push(amount);
            })
        }
        
        if(term.range === "(121-150)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr4.push(amount);
            })
        }
        
        if(term.range === "(151-180)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr5.push(amount);
            })
        }
        
        if(term.range === "(181-210)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr6.push(amount);
            })
        }

        if(term.range === "(211-240)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr7.push(amount);
            })
        }
        
        if(term.range === "(241-270)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr8.push(amount);
            })
        }

        if(term.range === "(271-300)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr9.push(amount);
            })
        }
        
        if(term.range === "(301-330)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr10.push(amount);
            })
        }
        
        if(term.range === "(331-360)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr11.push(amount);
            })
        } 
        
        if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr12.push(amount);
            })
        }
    })

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let bucket1 = arr.reduce((a, b) => a + b, 0);
    bucket1 = roundAccurately(bucket1, 2);
    let bucket2 = arr1.reduce((a, b) => a + b, 0);
    bucket2 = roundAccurately(bucket2, 2);
    let bucket3 = arr2.reduce((a, b) => a + b, 0);
    bucket3 = roundAccurately(bucket3, 2);
    let bucket4 = arr3.reduce((a, b) => a + b, 0);
    bucket4 = roundAccurately(bucket4, 2);
    let bucket5 = arr4.reduce((a, b) => a + b, 0);
    bucket5 = roundAccurately(bucket5, 2);
    let bucket6 = arr5.reduce((a, b) => a + b, 0);
    bucket6 = roundAccurately(bucket6, 2);
    let bucket7 = arr6.reduce((a, b) => a + b, 0);
    bucket7 = roundAccurately(bucket7, 2);
    let bucket8 = arr7.reduce((a, b) => a + b, 0);
    bucket8 = roundAccurately(bucket8, 2);
    let bucket9 = arr8.reduce((a, b) => a + b, 0);
    bucket9 = roundAccurately(bucket9, 2);
    let bucket10 = arr9.reduce((a, b) => a + b, 0);
    bucket10 = roundAccurately(bucket10, 2);
    let bucket11 = arr10.reduce((a, b) => a + b, 0);
    bucket11 = roundAccurately(bucket11, 2);
    let bucket12 = arr11.reduce((a, b) => a + b, 0);
    bucket12 = roundAccurately(bucket12, 2);
    let bucket13 = arr12.reduce((a, b) => a + b, 0);
    bucket13 = roundAccurately(bucket13, 2);

    b1 = bucket1 + bucket2 + bucket3;
    b2 = bucket4 + bucket5 + bucket6;
    b3 = bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12;
    b4 = bucket13;
}

    return getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1: b1, bucket2: b2, bucket3: b3, bucket4: b4, overdueOpening});
}

async function getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueOpening}) {
    //
    let overdueTarget = 0;
    // console.log({overdue: overdueOpening});

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            const bouncedForTheMonth = analyticsData.bouncedTd/exchangeRate;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        } else {
            const bouncedForTheMonth = analyticsData.bouncedTd;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        }
    } else {
        const bouncedForTheMonth = analyticsData.bouncedTd;
        const totalOverdue = overdueOpening + bouncedForTheMonth;
        const totalODReqTarget = totalOverdue * 0.5;
        overdueTarget = roundAccurately(totalODReqTarget, 2);
    }


    return getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget});
}

async function getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget}) {
    //
    let overdueActual = 0;
    // let termsStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            overdueActual = analyticsData.odCollTd;
            overdueActual = overdueActual/exchangeRate;
            overdueActual = roundAccurately(overdueActual, 2);
        } else {
            overdueActual = analyticsData.odCollTd;
            overdueActual = roundAccurately(overdueActual, 2);
        }
    } else {
        overdueActual = analyticsData.odCollTd;
        overdueActual = roundAccurately(overdueActual, 2);
    }

    return getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual});
}

async function getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                          legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let legalOrRepo = [];
    let watchList = [];
    let insurance = [];
    let promiseToPay = [];
    let repoInProgress = [];
    let caseOpeningInProgress = [];
    let requestToOpenCase = [];
    let underFollowUp = [];
    let noStatus = [];
    let partialReposession = [];
    let nonStarter = [];

    let above90Terms = [];                                        
    overdueTerms.forEach((term) => {
        let seconds;
        if ("transactionDate" in term) {
            if (term.transactionDate) {
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const endMonth = dueDate.endOf('month');

        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');
    
        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endMonth, 'days');

        if(fromNow > 90){

            above90Terms.push(term);
        }
    })

    let statusTerms = {};
    above90Terms.forEach(emi => {
        if("legalRepoStatus" in emi){
            if (`${emi.legalRepoStatus}` in statusTerms) {
                let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
                statusBucket.push(emi);
        
                statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
            } else {
                statusTerms[`${emi.legalRepoStatus}`] = [emi];
            }
        }else{
            emi['legalRepoStatus'] = "No Status";
            if (`${emi.legalRepoStatus}` in statusTerms) {
                let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
                statusBucket.push(emi);
        
                statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
            } else {
                statusTerms[`${emi.legalRepoStatus}`] = [emi];
            }
        }
    })


    let legalRepoStatusArray = [];
    let totalOverdueArr = [];
    const termsArr = _.map(statusTerms, client => client );
    termsArr.forEach((loanTerms) => {
        loanTerms.forEach(loanTerm => {
            let amount;
            if (loanTerm.currency === "usd") {
                //grab the total overdue
                if ("modulo" in loanTerm) {
                    amount = loanTerm.amount - loanTerm.modulo;
                } else {
                    amount = loanTerm.amount;
                }

            } else {
                if ("modulo" in loanTerm) {
                    amount = loanTerm.amount - loanTerm.modulo;
                } else {
                    amount = loanTerm.amount;
                }

                amount = amount/exchangeRate;
            }
            totalOverdueArr.push(amount);
            legalRepoStatusArray.push({name: loanTerm.legalRepoStatus, value: amount});
        })
    })
    console.log({legalRepoStatusArray: legalRepoStatusArray});
    let resultObject = {};

    legalRepoStatusArray.forEach(element => {
        if (resultObject.hasOwnProperty(element.name)) {
            resultObject[element.name] = resultObject[element.name] + element.value;
        } else {
            resultObject[element.name] = element.value;
        }
    });

    let resultArray = [];

    for (let prop in resultObject) {
        resultArray.push({ name: prop, value: resultObject[prop] });
    }

    let totalOv = totalOverdueArr.reduce((a, b) => a + b, 0);
    console.log({totalOv90: totalOv});

    resultArray.forEach(term => {
        const amount = term.value;
        if ("name" in term) {
            const legalRepoStatus = term.name;
            const legalPosition = legalRepoStatus.search(/legal/i);
            const repoPosition = legalRepoStatus.search(/repossessed/i);

            switch (true) {
                case (legalPosition >= 0 || repoPosition >= 0):
                    legalOrRepo.push(amount);
                    break;
                case (legalRepoStatus === "Insurance case"):
                    insurance.push(amount);
                    break;
                case (legalRepoStatus === "Under Follow-up"):
                    underFollowUp.push(amount);
                    break;
                case (legalRepoStatus === "Repossession in progress"):
                    repoInProgress.push(amount)
                    break;
                case (legalRepoStatus === "Promise to pay"):
                    promiseToPay.push(amount);
                    break;
                case (legalRepoStatus === "Watchlist"):
                    watchList.push(amount);
                    break;
                case (legalRepoStatus === "Case Opening in Progress"):
                    caseOpeningInProgress.push(amount);
                    break;
                case (legalRepoStatus === "Partial Repossession"):
                    partialReposession.push(amount);
                    break;
                case (legalRepoStatus === "Request To Open Case"):
                    requestToOpenCase.push(amount);
                    break;
                case (legalRepoStatus === "No Status"):
                    noStatus.push(amount);
                    break;
                case (legalRepoStatus === "Non-Starter"):
                    nonStarter.push(amount);
                    break;
            }
        }
    })

    let legalRepo = legalOrRepo.reduce((a, b) => a + b, 0);
    legalRepo = roundAccurately(legalRepo, 2);

    let insuranceCase = insurance.reduce((a, b) => a + b, 0);
    insuranceCase = roundAccurately(insuranceCase, 2);

    let repoProgress = repoInProgress.reduce((a, b) => a + b, 0);
    repoProgress = roundAccurately(repoProgress, 2);

    let promisePay = promiseToPay.reduce((a, b) => a + b, 0);
    promisePay = roundAccurately(promisePay, 2);

    let watchlist = watchList.reduce((a, b) => a + b, 0);
    watchlist = roundAccurately(watchlist, 2);

    let requestCase = requestToOpenCase.reduce((a, b) => a + b, 0);
    requestCase = roundAccurately(requestCase, 2);

    let caseOpening = caseOpeningInProgress.reduce((a, b) => a + b, 0);
    caseOpening = roundAccurately(caseOpening, 2);

    let noStatusValue = noStatus.reduce((a, b) => a + b, 0);
    noStatusValue = roundAccurately(noStatusValue, 2);

    let partialReposessionValue = partialReposession.reduce((a, b) => a + b, 0);
    partialReposessionValue = roundAccurately(partialReposessionValue, 2);

    let underFollowUpValue = underFollowUp.reduce((a, b) => a + b, 0);
    underFollowUpValue = roundAccurately(underFollowUpValue, 2);

    let nonStarterValue = nonStarter.reduce((a, b) => a + b, 0);
    nonStarterValue = roundAccurately(nonStarterValue, 2);

    const newDate = timeStamp.format("DD-MM-YYYY");

    return {date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual, legalRepo, insuranceCase, watchlist, promisePay, requestCase,
        caseOpening, repoProgress, newDate, noStatusValue, nonStarterValue, underFollowUpValue, partialReposessionValue};
}

// async function createCollectionTracker({ industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}) {

//     const systemRef = firebase.firestore().collection("system").doc("info");
//     const systemDoc = await systemRef.get();
//     const systemInfo = systemDoc.data();
//     const exchangeRate = systemInfo.exchangeRate;

//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     const date = moment().subtract(1, "months").endOf('month').format("DD_MM_YYYY");
//     const dateID = `analyticsNew_${date}`;
//     console.log(dateID);

//     firebase.firestore().collection("analyticsDailyNewReports").doc(dateID)
//         .get()
//         .then((endDoc) => {
//             if (endDoc.exists) {
//                 const endMonthData = endDoc.data();
//                 //CALCULATE OPENING OD
//                 let openingOverdue = endMonthData.totalOverdue;
//                 openingOverdue = roundAccurately(openingOverdue, 2);

//                             //FETCH ANALYTICS DATA
//             const url = `${project.serverUrl}fetchAnalytics`;
//             fetch(url, {
//                 method: 'GET',
//                 mode: 'cors',
//                 headers: {'Content-Type': 'application/json'},
//             }).then((response) => response.json())
//                 .then((data) => {

//                     //for examining each cheque contents
//                     if (!(_.isEmpty(data))) {

//                         console.log({analytics: data});

//                         let overdueArray = [];
//                         const month = moment().startOf('month').format("DD-MM-YYYY");

//                         data["openingOD"] = openingOverdue;

//                         overdueArray.push({value: openingOverdue, label: `Opening OD as of ${month}`, key: "1"});

//                         _.map(data, (value, key) => {

//                             switch (key) {
//                                 case "totalOverdue":
//                                     let overdue = value/exchangeRate;
//                                     overdue = roundAccurately(overdue, 2);
//                                     overdueArray.push({value: overdue, label: "Current OD per current month", key: "2"});
//                                     break;

//                                 case "bouncedTd":
//                                     let bouncedOverdue = value/exchangeRate;
//                                     bouncedOverdue = roundAccurately(bouncedOverdue, 2);
//                                     overdueArray.push({value: bouncedOverdue, label: "Bounces for the month", key: "3"});
//                                     break;
//                             }
//                         });


//                         const bouncedTd = data.bouncedTd/exchangeRate;
//                         let totalOverdue = openingOverdue + bouncedTd;

//                         totalOverdue = roundAccurately(totalOverdue, 2);
//                         overdueArray.push({value: totalOverdue, label: "Total OD", key: "4"});

//                         // const currentMonthOD = data.totalOverdue/exchangeRate;
//                         const monthCollections = data.odCollTd/exchangeRate;

//                         // const monthCollections = totalOverdue - currentMonthOD;

//                         ///extract date into array so it can be sorted
//                         if (overdueArray.length !== 0){
//                             overdueArray = arraySort(overdueArray, "key");

//                             overdueArray.push({ label: "COLLECTIONS FOR THE MONTH", value: monthCollections });

//                         }

//                         collectionTrackerReportOne11({collectionTracker: overdueArray, analytics: data, exchangeRate, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});

//                     }

//                 })
//                 .catch(e => {
//                     console.log(e);
//                     dispatch({type: ALL_REPORT_FAILED});
//                 })
//             } else {
//                 message.info("There is no end month data to generate this report");
//                 console.log("no data for the end of the month");
//             }
//         })
//         .catch((e) => {
//             console.log(e);
//         })
// };

// async function collectionTrackerReportOne11({collectionTracker, analytics, exchangeRate, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){

//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     let overdueArray = [];

//     //for examining each cheque contents
//     if (!(_.isEmpty(analytics))) {

//         //initial values
//         const currentOD = analytics.totalOverdue/exchangeRate;
//         const tc = analytics.tc/exchangeRate;
//         const pdcTD = analytics.pdcTd/exchangeRate;
//         const bouncedTD = analytics.bouncedTd/exchangeRate;
//         const openingOD = analytics.openingOD;
//         const pdcFtm  = analytics.pdcFtm/exchangeRate;

//         //due collections
//         let dueCollection = pdcTD - bouncedTD;
//         const dcRatio = (dueCollection/pdcTD) * 100;
//         const dueCollectionRatio = roundAccurately(dcRatio, 2);
//         dueCollection = roundAccurately(dueCollection, 2);

//         overdueArray.push({amount: dueCollection, description: "Due Collections", ratio: `${dueCollectionRatio}%`, key: "1"});

//         //overdue collections
//         let overdueCollection = analytics.odCollTd/exchangeRate;
//         const ocRatio = (overdueCollection/currentOD) * 100;
//         const overdueCollectionRatio = roundAccurately(ocRatio, 2);
//         overdueCollection = roundAccurately(overdueCollection, 2);

//         overdueArray.push({amount: overdueCollection, description: "Overdue Collections", ratio: `${overdueCollectionRatio}%`, key: "2"});

//         //collections efficiency
//         let collectionEfficiency = analytics.tc/exchangeRate;
//         const ceRatio = (tc/pdcTD) * 100;
//         const collectionEfficiencyRatio = roundAccurately(ceRatio, 2);
//         collectionEfficiency = roundAccurately(collectionEfficiency, 2);

//         overdueArray.push({amount: collectionEfficiency, description: "Collections Efficiency", ratio: `${collectionEfficiencyRatio}%`, key: "3"});

//         //total collections
//         let totalCollection = openingOD + pdcTD;
//         const tcRatio = (tc/totalCollection) * 100;
//         const totalCollectionRatio = roundAccurately(tcRatio, 2);
//         totalCollection = roundAccurately(totalCollection, 2);

//         overdueArray.push({amount: totalCollection, description: "Total Collections", ratio: `${totalCollectionRatio}%`, key: "4"});


//         ///extract date into array so it can be sorted
//         if (overdueArray.length !== 0){
//             overdueArray = arraySort(overdueArray, "key");
//             overdueArray.push({ description: "", amount: "", ratio: "" });

//         }

//         console.log({OD: analytics.openingOD});
//         return collectionTrackerReportOne({collectionTracker, collectionTracker1: overdueArray, analytics, exchangeRate, overdueOpening: analytics.openingOD, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
//     }
// }

// async function collectionTrackerReportOne({collectionTracker, collectionTracker1, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){

//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     let report = [];

//     if (!(_.isEmpty(analytics))) {
//         //{ date, totalOverdue }
//         // console.log({analytics: analytics});
//         let row = {};

//         const today = moment();

//         const pdcTD = analytics.pdcTd/exchangeRate;
//         const bouncedTD = analytics.bouncedTd/exchangeRate;
//         const pdcFTM = analytics.pdcFtm/exchangeRate;
//         const openingOD = analytics.openingOD;
//         const bouncedForTheMonth = analytics.bouncedTd/exchangeRate;

//         let pdcBouncedTD = pdcTD - bouncedTD;
//         pdcBouncedTD = roundAccurately(pdcBouncedTD, 2);

//         const totalCollReqTarget = roundAccurately(pdcFTM, 2);

//         const totalOverdue = openingOD + bouncedForTheMonth;
//         let totalODReqTarget = totalOverdue * 0.5;
//         totalODReqTarget = roundAccurately(totalODReqTarget, 2);

//         row["month"]  = today.endOf('month').format("DD/MM/YYYY");
//         row["pdcBouncedTD"] = pdcBouncedTD;
//         row["totalODReqTarget"] = totalODReqTarget;
//         row["totalCollReqTarget"] = totalCollReqTarget;
//         row["tally"] = "";

//         _.map(analytics, (value, key) => {

//             switch (key) {
//                 case "totalOverdue":
//                     row["totalOverdue"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//                 case "pdcFtm":
//                     row["pdcFtm"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//                 case "pdcTd":
//                     row["pdcTd"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//                 case "bouncedTd":
//                     row["bouncedTd"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//                 case "odCollTd":
//                     row["odCollTd"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//                 case "tc":
//                     row["tc"] = roundAccurately(value/exchangeRate, 2);
//                     break;
//             }
//         });

//         report = [row];
//     }

//     return getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2: report, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});
// }


// async function getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2, analytics, exchangeRate, overdueOpening, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}) {

//     let successArray = [];

//     const getDaysOfMonth = function() {
//         let daysInMonth = moment().format('DD');
//         let arrDays = [];

//         while(daysInMonth) {
//             const current = moment().date(daysInMonth);
//             arrDays.push({date: current.format('MM-DD-YYYY'), timeStamp: current});
//             daysInMonth--;
//         }

//         return arrDays;
//     };

//     const dateList = getDaysOfMonth();

//     analytics["currency"] = "localCurrency";

//     //fetch current overdue terms
//     const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
//     fetch(beforeUrl, {
//         method: 'GET',
//         mode: 'cors',
//         headers: {'Content-Type': 'application/json'},
//     }).then((response) => response.json())
//         .then((response) => {
//             // console.log(response);
//             const overdue = response;

//             //check if both current analytics and overdue terms objects are empty
//             if (!(_.isEmpty(analytics))) {
//                 console.log("hitting row");
//                 //{ date, totalOverdue }
//                 // console.log(data);

//                 if (dateList.length !== 0){
//                     //loop date list
//                     dateList.forEach((date) => {
//                         const response = getCollectionTrackerAnalyticsData({date: date.date, timeStamp: date.timeStamp, analyticsData: analytics, overdueTerms: overdue, exchangeRate, overdueOpening});
//                         successArray.push(response);
//                     });
//                         //
//                         Promise.all(successArray).then((x) => {
//                             //here is our array of dashboard data
//                             if(x.length !== 0){
//                                 // /* create a new blank workbook */
//                                 const wb = XLSX.utils.book_new();
//                                 //create report bucket to store all the excel rows as objects
//                                 let report = [];

//                                 x.forEach(day => {
//                                     let termRow = {};

//                                     termRow["newDate"] = day.newDate;
//                                     termRow["totalBook"] = day.totalBook;
//                                     termRow["dueCollections"] = day.dueCollections;
//                                     termRow["cumulativeCollections"] = day.cumulativeCollections;
//                                     termRow["dailyCollections"] = day.dailyCollections;
//                                     termRow["collectionEfficiency"] = day.collectionEfficiency;
//                                     termRow["repoNumber"] = day.repoNumber;
//                                     termRow["repoValue"] = day.repoValue;
//                                     termRow["legalCases"] = day.legalCases;
//                                     termRow["legalValue"] = day.legalValue;
//                                     termRow["overdue"] = day.overdue;
//                                     termRow["deliquencyRate"] = day.deliquencyRate;
//                                     termRow["bucket1"] = day.bucket1;
//                                     termRow["bucket2"] = day.bucket2;
//                                     termRow["bucket3"] = day.bucket3;
//                                     termRow["bucket4"] = day.bucket4;
//                                     termRow["overdueTarget"] = day.overdueTarget;
//                                     termRow["overdueActual"] = day.overdueActual;
//                                     termRow["legalRepo"] = day.legalRepo;
//                                     termRow["insuranceCase"] = day.insuranceCase;
//                                     termRow["repoProgress"] = day.repoProgress;
//                                     termRow["promisePay"] = day.promisePay;
//                                     termRow["watchlist"] = day.watchlist;
//                                     termRow["caseOpening"] = day.caseOpening;
//                                     termRow["requestCase"] = day.requestCase;
//                                     termRow["noStatusValue"] = day.noStatusValue;
//                                     termRow["underFollowUpValue"] = day.underFollowUpValue;
//                                     termRow["partialReposessionValue"] = day.partialReposessionValue;
//                                     termRow["nonStarterValue"] = day.nonStarterValue;

//                                     report.push(termRow);
//                                 })

//                                 exportAllData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3: report, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch});

//                         }
//                     })
//                 }

//             } else {
//                 console.log("There are no data");
//             }
//         }).catch((error) => {
//         console.log("Here's your error 1");
//         console.log(error);
//         dispatch({type: ALL_REPORT_FAILED});
//     })
// }

// async function getCollectionTrackerAnalyticsData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){

//     const today = moment().format("MM-DD-YYYY");

//     if (date !== today){
//         //get specific date analytics data
//         const reportDate = timeStamp.format("DD_MM_YYYY");
//         const reportID = `analytics_${reportDate}`;

//         const analyticsRef = firebase.firestore().collection('analyticsDailyReports').doc(reportID);
//         const doc = await analyticsRef.get();

//         if (doc.exists) {
//             // console.log({analytics: data});
//             analyticsData = doc.data();
//         }
//     }

//     return getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
// }

// async function getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){


//     const today = moment().format("MM-DD-YYYY");

//     if (date !== today){
//         let termsStore = [];
//         //get specific date overdue data
//         const dateID = timeStamp.format("DD_MM_YYYY");
//         const reportID = `masterlistNew_${dateID}`;

//         const data = JSON.stringify({ reportID, path: "masterListNew" });

//         //invoke custom database function
//         const url = `${project.serverUrl}downloadGeneratedReport`;
//         await fetch(url, {
//             method: 'POST',
//             mode: 'cors',
//             body: data,
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((bouncedCheques) => {
//                 //assign bouncedCheques to overdue terms
//                 // console.log({bounced: bouncedCheques});
//                 _.map(bouncedCheques, client => {
//                     //
//                     client.values.map(term => {
//                         termsStore.push(term);
//                     });
//                 })
//             })
//             .catch(e => {
//                 console.log(e);
//             })

//         //assign overdue terms
//         if (termsStore.length !== 0){
//             overdueTerms = termsStore;
//         }
//     }

//     // console.log({date: date, overdueTerms: overdueTerms});
//     return getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
// }


// async function getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}) {
//     //
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     let totalBook = 0;

//     if ("currency" in analyticsData){
//         //
//         if (analyticsData.currency === "localCurrency"){
//             totalBook = analyticsData.totalBook;
//             totalBook = totalBook/exchangeRate;
//             totalBook = roundAccurately(totalBook, 2);
//         } else {
//             totalBook = analyticsData.totalBook;
//             totalBook = roundAccurately(totalBook, 2);
//         }
//     } else {
//         totalBook = analyticsData.totalBook;
//         totalBook = roundAccurately(totalBook, 2);
//     }

//     // console.log({date: date, totalBook: totalBook});

//     return getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening});
// }

// async function getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening}) {

//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     let dueCollections = 0;

//     if ("currency" in analyticsData){
//         //
//         if (analyticsData.currency === "localCurrency"){
//             dueCollections = analyticsData.pdcTd;
//             dueCollections = dueCollections/exchangeRate;
//             dueCollections = roundAccurately(dueCollections, 2);
//         } else {
//             dueCollections = analyticsData.pdcTd;
//             dueCollections = roundAccurately(dueCollections, 2);
//         }
//     } else {
//         dueCollections = analyticsData.pdcTd;
//         dueCollections = roundAccurately(dueCollections, 2);
//     }

//     return getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening });
// }

// async function getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening }) {
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     let cumulativeCollections = 0;

//     if ("currency" in analyticsData){
//         //
//         if (analyticsData.currency === "localCurrency"){
//             cumulativeCollections = analyticsData.tc;
//             cumulativeCollections = cumulativeCollections/exchangeRate;
//             cumulativeCollections = roundAccurately(cumulativeCollections, 2);
//         } else {
//             cumulativeCollections = analyticsData.tc;
//             cumulativeCollections = roundAccurately(cumulativeCollections, 2);
//         }
//     } else {
//         cumulativeCollections = analyticsData.tc;
//         cumulativeCollections = roundAccurately(cumulativeCollections, 2);
//     }


//     return getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening});
// }

// async function getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening}) {
//     //get daily collections
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     let dailyCollections = 0;
//     let collectionArray = [];

//     const collectionRef = firebase.firestore().collection('cashCollections');
//     const snapshot = await collectionRef.get();

//     if (snapshot.size !== 0) {
//         snapshot.forEach(doc => {
//             const data = doc.data();

//             let seconds;
//             data.bankDate.seconds ? seconds = data.bankDate.seconds :  seconds = data.bankDate._seconds;

//             const bankDate = moment.unix(seconds).format("MM-DD-YYYY");

//             if(date === bankDate){
//                 if (data.currency === "usd") {
//                     //grab the paid amount
//                     collectionArray.push(data.paidAmount);

//                 } else {

//                     const amount = data.paidAmount/exchangeRate;

//                     collectionArray.push(amount);
//                 }
//             }
//         })
//     }

//     //find sum of daily collections
//     dailyCollections = collectionArray.reduce((a, b) => a + b, 0);
//     dailyCollections = roundAccurately(dailyCollections, 2);

//     // console.log({date, totalBook: totalBook, dueCollections: dueCollections, cumulativeCollections: cumulativeCollections, collection: dailyCollections});

//     return getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening});
// }

// async function getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening}) {
//     //find collection efficiency
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     let collectionEfficiency = 0;

//     collectionEfficiency = cumulativeCollections/dueCollections;
//     collectionEfficiency = roundAccurately(collectionEfficiency, 1);

//     return getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening});
// }

// async function getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening}) {
//     //
//     let repoNumber = 0;
//     let repoBucket = {};

//     if (overdueTerms.length !== 0){
//         overdueTerms.forEach((term) => {
//             const customerID = term.customerID;

//             if ("legalRepoStatus" in term){
//                 const legalRepoStatus = term.legalRepoStatus;
//                 const position = legalRepoStatus.search(/repossess/i);
//                 if (position >= 0){
//                     //term has any of repossess status
//                     //check if customerID already exists in repos object
//                     if (`${customerID}` in repoBucket) {
//                         //terms with this customerID already exist
//                         let terms = repoBucket[`${customerID}`].terms;
//                         terms.push(term);

//                         repoBucket[`${customerID}`].terms = terms;
//                     } else {
//                         //its a customerID so create new object for it
//                         repoBucket[`${customerID}`] = {
//                             customerID: customerID,
//                             terms: [term],
//                         }
//                     }
//                 }
//             }
//         })
//     }

//     if(!(_.isEmpty(repoBucket))){
//         repoNumber = Object.keys(repoBucket).length;
//     }

//     // console.log({repoNumber: repoNumber});

//     return getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening});
// }

// async function getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening}) {
//     //
//     let repoValue = 0;
//     let termStore = [];
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     if (overdueTerms.length !== 0){
//         overdueTerms.forEach((term) => {

//             if ("legalRepoStatus" in term){
//                 const legalRepoStatus = term.legalRepoStatus;
//                 const position = legalRepoStatus.search(/repossess/i);
//                 if (position >= 0){
//                     //term has any of repossess status
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         let amount;
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }

//                         termStore.push(amount);
//                     } else {

//                         //grab the total overdue
//                         let amount;
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }

//                         const convertedAmount = amount/exchangeRate;

//                         termStore.push(convertedAmount);
//                     }
//                 }
//             }
//         })
//     }

//     if(termStore.length !== 0){
//         repoValue = termStore.reduce((a, b) => a + b, 0);
//         repoValue = roundAccurately(repoValue, 2);
//     }

//     // console.log({repoValue: repoValue});

//     return getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         overdueOpening});
// }

// async function getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                       overdueOpening}) {
//     //
//     let legalCases = 0;
//     let legalBucket = {};

//     if (overdueTerms.length !== 0){
//         overdueTerms.forEach((term) => {
//             const customerID = term.customerID;

//             if ("legalRepoStatus" in term){
//                 const legalRepoStatus = term.legalRepoStatus;
//                 const position = legalRepoStatus.search(/legal/i);
//                 if (position >= 0){
//                     //term has any of legal status
//                     //check if customerID already exists in repos object
//                     if (`${customerID}` in legalBucket) {
//                         //terms with this customerID already exist
//                         let terms = legalBucket[`${customerID}`].terms;
//                         terms.push(term);

//                         legalBucket[`${customerID}`].terms = terms;
//                     } else {
//                         //its a customerID so create new object for it
//                         legalBucket[`${customerID}`] = {
//                             customerID: customerID,
//                             terms: [term],
//                         }
//                     }
//                 }
//             }
//         })
//     }

//     if(!(_.isEmpty(legalBucket))){
//         legalCases = Object.keys(legalBucket).length;
//     }

//     // console.log({legalCases: legalCases});

//     return getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, overdueOpening});
// }

// async function getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                   legalCases, overdueOpening}) {
//    //
//     let legalValue = 0;
//     let termStore = [];
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     if (overdueTerms.length !== 0){
//         overdueTerms.forEach((term) => {

//             if ("legalRepoStatus" in term){
//                 const legalRepoStatus = term.legalRepoStatus;
//                 const position = legalRepoStatus.search(/legal/i);
//                 if (position >= 0){
//                     //term has any of repossess status
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         let amount;
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }

//                         termStore.push(amount);
//                     } else {

//                         //grab the total overdue
//                         let amount;
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }

//                         const convertedAmount = amount/exchangeRate;

//                         termStore.push(convertedAmount);
//                     }
//                 }
//             }
//         })
//     }

//     if(termStore.length !== 0){
//         legalValue = termStore.reduce((a, b) => a + b, 0);
//         legalValue = roundAccurately(legalValue, 2);
//     }

//     // console.log({legalValue: legalValue});

//     return getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdueOpening});
// }

// async function getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                    legalCases, legalValue, overdueOpening}) {
//    //
//     let overdue = 0;
//     let termStore = [];
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     if (overdueTerms.length !== 0){
//         overdueTerms.forEach((term) => {

//             if (!term.termStatus.status){
//                 if (term.currency === "usd") {
//                     //grab the total overdue
//                     let amount;
//                     if ("modulo" in term) {
//                         amount = term.amount - term.modulo;
//                     } else {
//                         amount = term.amount;
//                     }

//                     termStore.push(amount);
//                 } else {
//                     //grab the total overdue
//                     let amount;
//                     if ("modulo" in term) {
//                         amount = term.amount - term.modulo;
//                     } else {
//                         amount = term.amount;
//                     }

//                     const convertedAmount = amount/exchangeRate;

//                     termStore.push(convertedAmount);
//                 }
//             }
//         })
//     }

//     if(termStore.length !== 0){
//         overdue = termStore.reduce((a, b) => a + b, 0);
//         overdue = roundAccurately(overdue, 2);
//     }


//     return getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, overdueOpening});
// }

// async function getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                        legalCases, legalValue, overdue, overdueOpening}) {
//     //
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//     const dRate = overdue/totalBook * 100;
//     const deliquencyRate = roundAccurately(dRate, 2);

//     return getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, deliquencyRate, overdueOpening});
// }

// async function getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                   legalCases, legalValue, overdue, deliquencyRate, overdueOpening}) {
//     //

//         let b1 = 0;
//         let b2 = 0;
//         let b3 = 0;
//         let b4 = 0;

//         let arr = [];
//         let arr1 = [];
//         let arr2 = [];
//         let arr3 = [];
//         let arr4 = [];
//         let arr5 = [];
//         let arr6 = [];
//         let arr7 = [];
//         let arr8 = [];
//         let arr9 = [];
//         let arr10 = [];
//         let arr11 = [];
//         let arr12 = [];

//     if(overdueTerms.length !== 0){
//         let terms = {};
//         overdueTerms.forEach(term => {
            
//             let seconds;
//             if ("transactionDate" in term) {
//                 if(term.transactionDate){
//                     term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
//                 }else{
//                         term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//                 }
//             } else {
//                 term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
//             }


//             //grab end of month of next month
//             const dueDate = moment.unix(seconds);
//             const endMonth = dueDate.endOf('month');

//             const today = moment();
//             const nextMonth = today.add(1, 'month');
//             const endOfNextMonth = nextMonth.endOf('month');
        
//             //find the number of days from today
//             const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
//             const bucket = Math.round(fromNow/30);
        
//             let daysRange = "(0)";
//             //compute date range depending on bucket
//             if(bucket !== 0) {
//                 const endDate = bucket * 30;
//                 const startDate = endDate - 29;

//                 daysRange = `(${startDate}-${endDate})`
//             }

//             if(bucket !== undefined && bucket !== null){
//                 if (`${daysRange}` in terms) {
//                     let termBucket = terms[`${daysRange}`].terms;
//                     termBucket.push(term);
//                     terms[`${daysRange}`].terms = termBucket;

//                 } else {
//                     terms[`${daysRange}`] = {
//                         range: daysRange,
//                         terms: [term]
//                     }
//                 }
//             }
//         })


//         const termsArr = _.map(terms, client => client );

//         termsArr.forEach(term => {
//             if(term.range === "(1-30)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr.push(amount);
//                 })
//             }
            
//             if(term.range === "(31-60)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr1.push(amount);
//                 })
//             }
            
//             if(term.range === "(61-90)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr2.push(amount);
//                 })
//             }
            
//             if(term.range === "(91-120)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr3.push(amount);
//                 })
//             }
            
//             if(term.range === "(121-150)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr4.push(amount);
//                 })
//             }
            
//             if(term.range === "(151-180)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr5.push(amount);
//                 })
//             }
            
//             if(term.range === "(181-210)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr6.push(amount);
//                 })
//             }

//             if(term.range === "(211-240)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr7.push(amount);
//                 })
//             }
            
//             if(term.range === "(241-270)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr8.push(amount);
//                 })
//             }

//             if(term.range === "(271-300)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr9.push(amount);
//                 })
//             }
            
//             if(term.range === "(301-330)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr10.push(amount);
//                 })
//             }
            
//             if(term.range === "(331-360)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr11.push(amount);
//                 })
//             } 
            
//             if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
//                 term.terms.forEach(term => {
//                     let amount;
//                     if (term.currency === "usd") {
//                         //grab the total overdue
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                     } else {
//                         if ("modulo" in term) {
//                             amount = term.amount - term.modulo;
//                         } else {
//                             amount = term.amount;
//                         }
    
//                         amount = amount/exchangeRate;
//                     }
    
//                     arr12.push(amount);
//                 })
//             }
//         })


//         const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
//         let bucket1 = arr.reduce((a, b) => a + b, 0);
//         bucket1 = roundAccurately(bucket1, 2);
//         let bucket2 = arr1.reduce((a, b) => a + b, 0);
//         bucket2 = roundAccurately(bucket2, 2);
//         let bucket3 = arr2.reduce((a, b) => a + b, 0);
//         bucket3 = roundAccurately(bucket3, 2);
//         let bucket4 = arr3.reduce((a, b) => a + b, 0);
//         bucket4 = roundAccurately(bucket4, 2);
//         let bucket5 = arr4.reduce((a, b) => a + b, 0);
//         bucket5 = roundAccurately(bucket5, 2);
//         let bucket6 = arr5.reduce((a, b) => a + b, 0);
//         bucket6 = roundAccurately(bucket6, 2);
//         let bucket7 = arr6.reduce((a, b) => a + b, 0);
//         bucket7 = roundAccurately(bucket7, 2);
//         let bucket8 = arr7.reduce((a, b) => a + b, 0);
//         bucket8 = roundAccurately(bucket8, 2);
//         let bucket9 = arr8.reduce((a, b) => a + b, 0);
//         bucket9 = roundAccurately(bucket9, 2);
//         let bucket10 = arr9.reduce((a, b) => a + b, 0);
//         bucket10 = roundAccurately(bucket10, 2);
//         let bucket11 = arr10.reduce((a, b) => a + b, 0);
//         bucket11 = roundAccurately(bucket11, 2);
//         let bucket12 = arr11.reduce((a, b) => a + b, 0);
//         bucket12 = roundAccurately(bucket12, 2);
//         let bucket13 = arr12.reduce((a, b) => a + b, 0);
//         bucket13 = roundAccurately(bucket13, 2);

//         b1 = bucket1 + bucket2 + bucket3;
//         b2 = bucket4 + bucket5 + bucket6;
//         b3 = bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12;
//         b4 = bucket13;
//     }


//     return getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, deliquencyRate, bucket1: b1, bucket2: b2, bucket3: b3, bucket4: b4, overdueOpening});
// }

// async function getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                          legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueOpening}) {
//     //
//     let overdueTarget = 0;
//     // console.log({overdue: overdueOpening});

//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     if ("currency" in analyticsData){
//         //
//         if (analyticsData.currency === "localCurrency"){
//             const bouncedForTheMonth = analyticsData.bouncedTd/exchangeRate;
//             const totalOverdue = overdueOpening + bouncedForTheMonth;
//             const totalODReqTarget = totalOverdue * 0.5;
//             overdueTarget = roundAccurately(totalODReqTarget, 2);
//         } else {
//             const bouncedForTheMonth = analyticsData.bouncedTd;
//             const totalOverdue = overdueOpening + bouncedForTheMonth;
//             const totalODReqTarget = totalOverdue * 0.5;
//             overdueTarget = roundAccurately(totalODReqTarget, 2);
//         }
//     } else {
//         const bouncedForTheMonth = analyticsData.bouncedTd;
//         const totalOverdue = overdueOpening + bouncedForTheMonth;
//         const totalODReqTarget = totalOverdue * 0.5;
//         overdueTarget = roundAccurately(totalODReqTarget, 2);
//     }


//     return getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget});
// }

// async function getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                          legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget}) {
//     //
//     let overdueActual = 0;
//     // let termsStore = [];
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     if ("currency" in analyticsData){
//         //
//         if (analyticsData.currency === "localCurrency"){
//             overdueActual = analyticsData.odCollTd;
//             overdueActual = overdueActual/exchangeRate;
//             overdueActual = roundAccurately(overdueActual, 2);
//         } else {
//             overdueActual = analyticsData.odCollTd;
//             overdueActual = roundAccurately(overdueActual, 2);
//         }
//     } else {
//         overdueActual = analyticsData.odCollTd;
//         overdueActual = roundAccurately(overdueActual, 2);
//     }

//     return getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual});
// }

// async function getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//                                           legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual}) {
//     //
//     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

//     let legalOrRepo = [];
//     let watchList = [];
//     let insurance = [];
//     let promiseToPay = [];
//     let repoInProgress = [];
//     let caseOpeningInProgress = [];
//     let requestToOpenCase = [];
//     let underFollowUp = [];
//     let noStatus = [];
//     let partialReposession = [];
//     let nonStarter = [];

//     let above90Terms = [];                                        
//     overdueTerms.forEach((term) => {
//         let seconds;
//         if ("transactionDate" in term) {
//             if (term.transactionDate) {
//                 term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
//             } else {
//                 term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//             }
//         } else {
//             term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//         }

//         //grab today moment
//         const dueDate = moment.unix(seconds);
//         const endMonth = dueDate.endOf('month');

//         const today = moment();
//         const nextMonth = today.add(1, 'month');
//         const endOfNextMonth = nextMonth.endOf('month');
    
//         //find the number of days from today
//         const fromNow = endOfNextMonth.diff(endMonth, 'days');

//         if(fromNow > 90){

//             above90Terms.push(term);
//         }
//     })

//     let statusTerms = {};
//     above90Terms.forEach(emi => {
//         if("legalRepoStatus" in emi){
//             if (`${emi.legalRepoStatus}` in statusTerms) {
//                 let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
//                 statusBucket.push(emi);
        
//                 statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
//             } else {
//                 statusTerms[`${emi.legalRepoStatus}`] = [emi];
//             }
//         }else{
//             emi['legalRepoStatus'] = "No Status";
//             if (`${emi.legalRepoStatus}` in statusTerms) {
//                 let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
//                 statusBucket.push(emi);
        
//                 statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
//             } else {
//                 statusTerms[`${emi.legalRepoStatus}`] = [emi];
//             }
//         }
//     })


//     let legalRepoStatusArray = [];
//     let totalOverdueArr = [];
//     const termsArr = _.map(statusTerms, client => client );
//     termsArr.forEach((loanTerms) => {
//         loanTerms.forEach(loanTerm => {
//             let amount;
//             if (loanTerm.currency === "usd") {
//                 //grab the total overdue
//                 if ("modulo" in loanTerm) {
//                     amount = loanTerm.amount - loanTerm.modulo;
//                 } else {
//                     amount = loanTerm.amount;
//                 }

//             } else {
//                 if ("modulo" in loanTerm) {
//                     amount = loanTerm.amount - loanTerm.modulo;
//                 } else {
//                     amount = loanTerm.amount;
//                 }

//                 amount = amount/exchangeRate;
//             }
//             totalOverdueArr.push(amount);
//             legalRepoStatusArray.push({name: loanTerm.legalRepoStatus, value: amount});
//         })
//     })

//     let resultObject = {};

//     legalRepoStatusArray.forEach(element => {
//         if (resultObject.hasOwnProperty(element.name)) {
//             resultObject[element.name] = resultObject[element.name] + element.value;
//         } else {
//             resultObject[element.name] = element.value;
//         }
//     });

//     let resultArray = [];

//     for (let prop in resultObject) {
//         resultArray.push({ name: prop, value: resultObject[prop] });
//     }

//     let totalOv = totalOverdueArr.reduce((a, b) => a + b, 0);
//     console.log(resultArray);
//     console.log(totalOv);
//     resultArray.forEach(term => {
//         const amount = term.value;
//         if ("name" in term) {
//             const legalRepoStatus = term.name;
//             const legalPosition = legalRepoStatus.search(/legal/i);
//             const repoPosition = legalRepoStatus.search(/repossessed/i);

//             switch (true) {
//                 case (legalPosition >= 0 || repoPosition >= 0):
//                     legalOrRepo.push(amount);
//                     break;
//                 case (legalRepoStatus === "Insurance case"):
//                     insurance.push(amount);
//                     break;
//                 case (legalRepoStatus === "Under Follow-up"):
//                     underFollowUp.push(amount);
//                     break;
//                 case (legalRepoStatus === "Repossession in progress"):
//                     repoInProgress.push(amount)
//                     break;
//                 case (legalRepoStatus === "Promise to pay"):
//                     promiseToPay.push(amount);
//                     break;
//                 case (legalRepoStatus === "Watchlist"):
//                     watchList.push(amount);
//                     break;
//                 case (legalRepoStatus === "Case Opening in Progress"):
//                     caseOpeningInProgress.push(amount);
//                     break;
//                 case (legalRepoStatus === "Partial Repossession"):
//                     partialReposession.push(amount);
//                     break;
//                 case (legalRepoStatus === "Request To Open Case"):
//                     requestToOpenCase.push(amount);
//                     break;
//                 case (legalRepoStatus === "No Status"):
//                     noStatus.push(amount);
//                     break;
//                 case (legalRepoStatus === "Non-Starter"):
//                     nonStarter.push(amount);
//                     break;
//             }
//         }
//     })


//     let legalRepo = legalOrRepo.reduce((a, b) => a + b, 0);
//     legalRepo = roundAccurately(legalRepo, 2);

//     let insuranceCase = insurance.reduce((a, b) => a + b, 0);
//     insuranceCase = roundAccurately(insuranceCase, 2);

//     let repoProgress = repoInProgress.reduce((a, b) => a + b, 0);
//     repoProgress = roundAccurately(repoProgress, 2);

//     let promisePay = promiseToPay.reduce((a, b) => a + b, 0);
//     promisePay = roundAccurately(promisePay, 2);

//     let watchlist = watchList.reduce((a, b) => a + b, 0);
//     watchlist = roundAccurately(watchlist, 2);

//     let requestCase = requestToOpenCase.reduce((a, b) => a + b, 0);
//     requestCase = roundAccurately(requestCase, 2);

//     let caseOpening = caseOpeningInProgress.reduce((a, b) => a + b, 0);
//     caseOpening = roundAccurately(caseOpening, 2);

//     let noStatusValue = noStatus.reduce((a, b) => a + b, 0);
//     noStatusValue = roundAccurately(noStatusValue, 2);

//     let partialReposessionValue = partialReposession.reduce((a, b) => a + b, 0);
//     partialReposessionValue = roundAccurately(partialReposessionValue, 2);

//     let underFollowUpValue = underFollowUp.reduce((a, b) => a + b, 0);
//     underFollowUpValue = roundAccurately(underFollowUpValue, 2);

//     let nonStarterValue = nonStarter.reduce((a, b) => a + b, 0);
//     nonStarterValue = roundAccurately(nonStarterValue, 2);

//     const newDate = timeStamp.format("DD-MM-YYYY");

//     return {date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
//         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual, legalRepo, insuranceCase, watchlist, promisePay, requestCase,
//         caseOpening, repoProgress, newDate, noStatusValue, nonStarterValue, underFollowUpValue, partialReposessionValue};
// }

async function exportAllData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3, industryDataArray, dbSegmentArray, value, groupedReportPhase1, groupedReportPhase2, provision, overdueAging, statusAnalysis, dashboardData, characterAnalysis, customerOD, dispatch}){
    collectionTracker = collectionTracker.filter(function (el) { return el != null; });
    collectionTracker1 = collectionTracker1.filter(function (el) { return el != null; });
    collectionTracker2 = collectionTracker2.filter(function (el) { return el != null; });
    collectionTracker3 = collectionTracker3.filter(function (el) { return el != null; });
    industryDataArray = industryDataArray.filter(function (el) { return el != null; });
    dbSegmentArray = dbSegmentArray.filter(function (el) { return el != null; });
    value = value.filter(function (el) { return el != null; });
    groupedReportPhase1 = groupedReportPhase1.filter(function (el) { return el != null; });
    groupedReportPhase2 = groupedReportPhase2.filter(function (el) { return el != null; });
    provision = provision.filter(function (el) { return el != null; });
    overdueAging = overdueAging.filter(function (el) { return el != null; });
    dashboardData = dashboardData.filter(function (el) { return el != null; });
    statusAnalysis = statusAnalysis.filter(function (el) { return el != null; });
    characterAnalysis = characterAnalysis.filter(function (el) { return el != null; });
    customerOD = customerOD.filter(function (el) { return el != null; });

    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (wb) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(wb, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }





    const date = moment().format("DD/MM/YYYY");
    const today = moment().format("DD/MM/YYYY");


    let empty = [{A: ''}];

//----------------dashboard begin--------//
    let title = [{A: ` DASHBOARD REPORT AS OF ${date}`}];
    let table = [
        {
            A: "MONTHS",
            B: "SUM OF DEBIT AMOUNT LCY",
            C: "SUM OF DEBIT AMOUNT USD",
            D: "SUM OF OD REPORTING USD",
            E: "NUMBER OF CUSTOMERS",
            F: "AGEING",
            G: "BUCKET",
            H: "TOTAL OD PER BUCKET"
        }
    ];

    if(dashboardData.length !== 0){
        dashboardData.forEach(cheque => {
            table.push({
                A: cheque.endOfMonth,
                B: cheque.totalLCY,
                C: cheque.totalDebitInUSD,
                D: cheque.totalInUsd,
                E: cheque.numberOfCustomers,
                F: cheque.daysRange,
                G: cheque.bucket,
                H: cheque.percent
            })
        })
    }

    table = (['']).concat(table);
    const finalData = [...empty, ...title, ...table];

//-------------dashboard ending--------//

//--------------------status analysis(status + character + OD analysis report)----begin-----------//
    //create status analysis
    let title1 = [{A: ` STATUS ANALYSIS REPORT AS OF ${date}`}];

    let table1 = [
        {
            A: "STATUS",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD IN USD",
            D: "PERCENT ON COUNT",
            E: "PERCENT ON VALUE"
        }
    ];

    if(statusAnalysis.length !== 0){
        statusAnalysis.forEach(data => {
            table1.push({
                A: data.status,
                B: data.numberOfCustomers,
                C: data.totalODInUsd,
                D: data.countPercent,
                E: data.valuePercent
            })
        })
    }

    table1 = (['']).concat(table1);

    //create character analysis

    let table2 = [
        {
            A: "CHARACTER",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD IN USD",
            D: "PERCENT ON COUNT",
            E: "PERCENT ON VALUE"
        }
    ];

    if(characterAnalysis.length !== 0){
        characterAnalysis.forEach(data => {
            table2.push({
                A: data.character,
                B: data.numberOfCustomers,
                C: data.totalODInUsd,
                D: data.countPercent,
                E: data.valuePercent
            })
        })
    }

    table2 = (['']).concat(table2);

    //create OD report
    let table3 = [
        {
            A: "NAME",
            B: "OD IN USD",
        }
    ];

    if(customerOD.length !== 0){
        customerOD.forEach(data => {
            table3.push({
                A: data.customerName,
                B: data.overdue,
            })
        })
    }

    table1 = (['']).concat([{A: "STATUS ANALYSIS"}]).concat(table1).concat(['']).concat(['']).concat(['']).concat([{A: "CHARACTER ANALYSIS"}]).concat(table2).concat(['']).concat(['']).concat(['']).concat([{A: "CUSTOMERS OVERDUE"}]).concat(table3);;
    const finalData1 = [...empty, ...title1, ...table1];

    //--------------------status analysis(status + character + OD analysis report)-----ending-----------//

    //--------------------provision report--------------------------------------------begin------------//
    let title4 = [{A: ` PROVISION REPORT AS OF ${date}`}];
    let table4 = [
        {
            A: "CUSTOMER NAME",
            B: "BUCKET GROUP",
            C: "PROVISION",
        }
    ];

    if(provision.length !== 0){
        provision.forEach(cheque => {
            table4.push({
                A: cheque.customerName,
                B: cheque.bucket,
                C: cheque.provision,
            })
        })
    }

    table4 = (['']).concat(table4);
    const finalData2 = [...empty, ...title4, ...table4];
    //--------------------provision report--------------------------------------------ending------------//

    //--------------------DB Segment report--------------------------------------------begin------------//
    let title5 = [{A: ` DB SEGMENT REPORT AS OF ${today}`}];

    let table5 = [
        {
             A: "EOM",
             B: "NUMBER OF CUSTOMERS BLANK",
             C: "BLANK OD IN USD",
             D: "NUMBER OF CUSTOMERS DAEWOO",
             E: "DAEWOO OD IN USD",
             F: "NUMBER OF CUSTOMERS TML",
             G: "TML OD IN USD",
             H: "DISTINCT COUNT OF OF CUSTOMERS",
             I: "TOTAL SUM OF OD IN USD",
        }
    ];
 
    let table6 = [
        {
            A: "ROW LABELS",
            B: "SUM OF OD REPORTING USD",
        }
    ];
 
    if(dbSegmentArray.length !== 0){
         dbSegmentArray.forEach(data => {
             table5.push({
                 A: data.eqm, 
                 B: data.numberOfCustomersBlank, 
                 C: data.blankODInUSD,
                 D: data.numberOfCustomersDaewoo,
                 E: data.daewooODInUSD, 
                 F: data.numberOfCustomersTML, 
                 G: data.tmlODInUSD, 
                 H: data.distinctCountOfCustomers, 
                 I: data.totalSumOfODInUSD, 
             })
         })
    }
 
    if(industryDataArray.length !== 0){
         industryDataArray.forEach(data => {
             table6.push({
                 A: data.industry,
                 B: data.totalODInUsd,
             })
         })
     }
    table5 = (['']).concat(table5).concat(['']).concat(['']).concat(['']).concat(table6)
    const finalData3 = [...empty, ...title5, ...table5];
    //--------------------DB Segment report--------------------------------------------ending------------//

    //--------------------Analysis report--------------------------------------------begin------------//
    let title7 = [{A: ` ANALYSIS REPORT ${today}`}];

    let table7 = [
        {
            A: "BUCKET",
            B: "Number of Customers",
        }
    ];

    let table8 = [
        {
            A: "BUCKET",
            B: "Sum of OD Reporting USD",
        }
    ];

    let table9 = [
        {
            A: "BUCKET GROUPED",
            B: "Number of Customers",
        }
    ];

    let table10 = [
        {
            A: "BUCKET GROUPED",
            B: "Sum of OD Reporting USD",
        }
    ];

    if(value.length !== 0){
        value.forEach(data => {
            table7.push({
                A: data.groupedBucket,
                B: data.numberOfCustomers,
            })
        })
    }

    if(value.length !== 0){
        value.forEach(data => {
            table8.push({
                A: data.groupedBucket,
                B: data.debtTotal,
            })
        })
    }

    if(groupedReportPhase1.length !== 0){
        groupedReportPhase1.forEach(data => {
            table9.push({
                A: data.groupedBucket,
                B: data.numberOfCustomers,
            })
        })
    }

    if(groupedReportPhase2.length !== 0){
        groupedReportPhase2.forEach(data => {
            table10.push({
                A: data.groupedBucket,
                B: data.totalOverdue,
            })
        })
    }


    table7 = (['']).concat(table7).concat(['']).concat(['']).concat(['']).concat(table8).concat(['']).concat(['']).concat(['']).concat(table9).concat(['']).concat(['']).concat(['']).concat(table10);
    const finalData4 = [...empty, ...title7, ...table7];

    //--------------------Analysis report--------------------------------------------ending------------//

    //--------------------Collection Tracker report--------------------------------------------begin------------//

    let title11 = [{A: ` COLLECTION TRACKER AS OF ${today}`}];

    let table11 = [
        {
            A: "COLLECTIONS FOR THE MONTH",
            B: "VALUE",
        }
    ];
 
    let table12 = [
        {
            A: "DESCRIPTION",
            B: "AMOUNT",
            C: "RATIO"
        }
    ];
 
    let table13 = [
         {
             A: "MONTH",
             B: "TOTAL OVERDUE",
             C: "PDC FTM",
             D: "PDC TD",
             E: "PDC TD - BOUNCED TD",
             F: "BOUNCED TD",
             G: "OD COLL TD",
             H: "TC",
             I: "TOTAL COLL REQ TARGET",
             J: "TOTAL OD REQ TARGET",
             K: "TALLY",
         }
     ];

     let table30 = [
        {
            A: "Date",
            B: "Total Book",
            C: "Scheduled Due Collections",
            D: "Cumulative Collections",
            E: "Daily Collections",
            F: "Collection Efficiency",
            G: "Repossessed Number",
            H: "Repossessed Value",
            I: "Legal Cases",
            J: "Legal Value",
            K: "Overdue",
            L: "Deliquency Rate",
            M: "0-90",
            N: "91-180",
            O: "181-360",
            P: ">360",
            Q: "Overdue Target",
            R: "Overdue Actual",
            S: ">90 Days Legal/Repossessed",
            T: ">90 Days Insurance Case",
            U: ">90 Days Repossession In Progress",
            V: ">90 Days Promise To Pay",
            W: ">90 Days Watchlist",
            X: ">90 Days Case Opening In Progress",
            Y: ">90 Days Request To Open Case",
            Z: ">90 Days Under Follow-Up",
            AA: ">90 Days Partial Repossession",
            AB: ">90 Non Starter",
            AC: ">90 Days Blank"
        }
    ];
 
    if(collectionTracker.length !== 0){
         collectionTracker.forEach(data => {
             table11.push({
                 A: data.label,
                 B: data.value,
             })
         })
    }
 
    if(collectionTracker1.length !== 0){
         collectionTracker1.forEach(data => {
             table12.push({
                 A: data.description,
                 B: data.amount,
                 C: data.ratio
 
             })
         })
     }
     
     if(collectionTracker2.length !== 0){
         collectionTracker2.forEach(data => {
             table13.push({
                 A: data.month,
                 B: data.totalOverdue,
                 C: data.pdcFtm,
                 D: data.pdcTd,
                 E: data.pdcBouncedTD,
                 F: data.bouncedTd,
                 G: data.odCollTd,
                 H: data.tc,
                 I: data.totalCollReqTarget,
                 J: data.totalODReqTarget,
                 K: data.tally,
             })
         })
     }

     if(collectionTracker3.length !== 0){
        collectionTracker3.forEach(data => {
            table30.push({
                A: data.newDate,
                B: data.totalBook,
                C: data.dueCollections,
                D: data.cumulativeCollections,
                E: data.dailyCollections,
                F: data.collectionEfficiency,
                G: "",
                H: "",
                // G: data.repoNumber,
                // H: data.repoValue,
                I: data.legalCases,
                J: data.legalValue,
                K: data.overdue,
                L: data.deliquencyRate,
                M: data.bucket1,
                N: data.bucket2,
                O: data.bucket3,
                P: data.bucket4,
                Q: data.overdueTarget,
                R: data.overdueActual,
                S: data.legalRepo,
                T: data.insuranceCase,
                U: data.repoProgress,
                V: data.promisePay,
                W: data.watchlist,
                X: data.caseOpening,
                Y: data.requestCase,
                Z: data.underFollowUpValue,
                AA: data.partialReposessionValue,
                AB: data.nonStarterValue,
                AC: data.noStatusValue,
            })
        })
    }

     table11 = (['']).concat(table11).concat(['']).concat(['']).concat(['']).concat(table12).concat(['']).concat(['']).concat(['']).concat(table13).concat(['']).concat(['']).concat(['']).concat(table30)
     const finalData6 = [...empty, ...title11, ...table11];
    //--------------------Collection Tracker report--------------------------------------------ending------------//


    //--------------------Overdue Aging report--------------------------------------------------begin------------//
    let title20 = [{A: ` OVERDUE AGING REPORT AS OF ${date}`}];
    let table20 = [
        {
            A: "TRANSACTION DATE",
            B: "NAME",
            C: "COLLECTOR",
            D: "SALES OFFICER",
            E: "CHEQUE #",
            F: "DEBIT AMOUNT",
            G: "BOUNCED REASON",
            H: "OTHER SPECIFY",
            I: "OVERDUE",
            J: "CHARACTER",
            K: "ACTION",
            L: "STATUS",
            M: "COMMENT",
            N: "DAYS",
            O: "AGE",
            P: "PROFILE",
            Q: "TRUCK",
            R: "INDUSTRY",
            S: "MODEL",
            T: "SEGMENT",
            U: "OD REPORTING USD",
            V: "DUE DATE",
            W: "1st DEMAND NOTICE",
            X: "2nd DEMAND NOTICE"
        }
    ];

    if(overdueAging.length !== 0){
        overdueAging.forEach(data => {
            table20.push({
                A: data.transactionDate,
                B: data.customerName,
                C: data.assignee,
                D: data.salesExe,
                E: data.chequeNumber,
                F: data.amountTotal,
                G: data.bouncedReason,
                H: data.otherReason,
                I: data.overdue,
                J: data.character,
                K: data.clientAction,
                L: data.legalRepoStatus,
                M: data.comment,
                N: data.dailyBucket,
                O: data.dailyAge,
                P: data.clientProfile,
                Q: data.truck,
                R: data.industry,
                S: data.model,
                T: data.segment,
                U: data.sumODinUSD,
                V: data.dueDateValue,
                W: data.firstDemandNotice,
                X: data.secondDemandNotice,
            })
        })
    }

    table20 = (['']).concat(table20);
    const finalData5 = [...empty, ...title20, ...table20];
    
    //-------------------------Overdue Aging report-------------------------------ending--------------------------//
    

    const wb = XLSX.utils.book_new();

    const w = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    w['!cols'] = [
        {wch:25},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
    ];


    const w1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    w1['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:20},
        {wch:25},
        {wch:25},
    ];

    const w2 = XLSX.utils.json_to_sheet(finalData2, {
        skipHeader: true,
    })

    w2['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:20},
    ];


    const w3 = XLSX.utils.json_to_sheet(finalData3, {
        skipHeader: true,
    })

    w3['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
    ];


    const w4 = XLSX.utils.json_to_sheet(finalData4, {
        skipHeader: true,
    })

    w4['!cols'] = [
        {wch:15},
        {wch:25},
    ];

    const w5 = XLSX.utils.json_to_sheet(finalData5, {
        skipHeader: true,
    })


    w5['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
    ];


    const w6 = XLSX.utils.json_to_sheet(finalData6, {
        skipHeader: true,
    })

    w6['!cols'] = [
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
    ];



    XLSX.utils.book_append_sheet(wb, w, 'Dashboard');
    XLSX.utils.book_append_sheet(wb, w1, 'Status analysis');
    XLSX.utils.book_append_sheet(wb, w2, 'Provision');
    XLSX.utils.book_append_sheet(wb, w3, 'DB Segment');
    XLSX.utils.book_append_sheet(wb, w4, 'Analysis');
    XLSX.utils.book_append_sheet(wb, w5, 'OD Aging');
    XLSX.utils.book_append_sheet(wb, w6, 'Collection Tracker');

    const workbookBlob = workbook2blob(wb);


    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'MONTHS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:H2',
        tbodyRange: `A2:H${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:H${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }


    const headerIndexA = [];
    const headerIndex1A = [];
    const headerIndex2A = [];
    const headerIndex3A = [];

    finalData1.forEach((data, index) => data['A'] === 'STATUS' ? headerIndexA.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'CHARACTER' ? headerIndex1A.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'NAME' ? headerIndex2A.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3A.push(index) : null )


    const dataInfo1 = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData1.length}`,
        theadRange: headerIndexA.length >= 1 ? `A${headerIndexA[0] + 1}:E${headerIndexA[0] + 1}` : null,
        theadRange1: headerIndex1A.length >= 1 ? `A${headerIndex1A[0] + 1}:E${headerIndex1A[0] + 1}` : null,
        theadRange2: headerIndex2A.length >= 1 ? `A${headerIndex2A[0] + 1}:B${headerIndex2A[0] + 1}` : null,
        theadRange3: headerIndex3A.length >= 1 ? `A${headerIndex3A[0] + 1}:E${headerIndex3A[0] + 1}` : null,
        theadRange4: headerIndex3A.length >= 2 ? `A${headerIndex3A[1] + 1}:D${headerIndex3A[1] + 1}` : null,
        theadRange5: headerIndex3A.length >= 3 ? `A${headerIndex3A[2] + 1}:B${headerIndex3A[2] + 1}` : null,
    }


    const headerIndexB = [];
    const headerIndex1B = [];

    finalData2.forEach((data, index) => data['A'] === 'CUSTOMER NAME' ? headerIndexB.push(index) : null )
    finalData2.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex1B.push(index) : null )


    const dataInfo2 = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData2.length}`,
        theadRange: headerIndexB.length >= 1 ? `A${headerIndexB[0] + 1}:C${headerIndexB[0] + 1}` : null,
        theadRange1: headerIndex1B.length >= 1 ? `A${headerIndex1B[0] + 1}:C${headerIndex1B[0] + 1}` : null,
    }


    const headerIndexC = [];
    const headerIndex2C = [];
    const headerIndex3C = [];

    finalData3.forEach((data, index) => data['A'] === 'EOM' ? headerIndexC.push(index) : null )
    finalData3.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2C.push(index) : null )
    finalData3.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3C.push(index) : null )

    const dataInfo3 = {
        titleCell: 'A2',
        titleRange: 'A1:I2',
        tbodyRange: `A2:I${finalData3.length}`,
        theadRange: headerIndexC.length >= 1 ? `A${headerIndexC[0] + 1}:I${headerIndexC[0] + 1}` : null,
        theadRange2: headerIndex2C.length >= 1 ? `A${headerIndex2C[0] + 1}:B${headerIndex2C[0] + 1}` : null,
        theadRange3: headerIndex3C.length >= 1 ? `A${headerIndex3C[0] + 1}:I${headerIndex3C[0] + 1}` : null,
        theadRange4: headerIndex3C.length >= 2 ? `A${headerIndex3C[1] + 1}:B${headerIndex3C[1] + 1}` : null,
    }


    const headerIndexD = [];
    const headerIndex1D = [];
    const headerIndex2D = [];


    finalData4.forEach((data, index) => data['A'] === 'BUCKET' ? headerIndexD.push(index) : null )
    finalData4.forEach((data, index) => data['A'] === 'BUCKET GROUPED' ? headerIndex1D.push(index) : null )
    finalData4.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex2D.push(index) : null )

    const dataInfo4 = {
        titleCell: 'A2',
        titleRange: 'A1:B2',
        tbodyRange: `A2:B${finalData4.length}`,
        theadRange: headerIndexD.length >= 1 ? `A${headerIndexD[0] + 1}:B${headerIndexD[0] + 1}` : null,
        theadRange1: headerIndexD.length >= 2 ? `A${headerIndexD[1] + 1}:B${headerIndexD[1] + 1}` : null,
        theadRange2: headerIndex1D.length >= 1 ? `A${headerIndex1D[0] + 1}:B${headerIndex1D[0] + 1}` : null,
        theadRange3: headerIndex1D.length >= 2 ? `A${headerIndex1D[1] + 1}:B${headerIndex1D[1] + 1}` : null,
        theadRange4: headerIndex2D.length >= 1 ? `A${headerIndex2D[0] + 1}:B${headerIndex2D[0] + 1}` : null,
        theadRange5: headerIndex2D.length >= 2 ? `A${headerIndex2D[1] + 1}:B${headerIndex2D[1] + 1}` : null,
        theadRange6: headerIndex2D.length >= 3 ? `A${headerIndex2D[2] + 1}:B${headerIndex2D[2] + 1}` : null,
        theadRange7: headerIndex2D.length >= 4 ? `A${headerIndex2D[3] + 1}:B${headerIndex2D[3] + 1}` : null,
    }


    const headerIndexE = [];
    finalData5.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndexE.push(index) : null )

    const dataInfo5 = {
        titleCell: 'A2',
        titleRange: 'A1:X2',
        tbodyRange: `A2:X${finalData5.length}`,
        theadRange: headerIndexE.length >= 1 ? `A${headerIndexE[0] + 1}:X${headerIndexE[0] + 1}` : null,
    }


    const headerIndexF = [];
    const headerIndex1F = [];
    const headerIndex2F = [];
    const headerIndex3F = [];

    finalData6.forEach((data, index) => data['A'] === 'COLLECTIONS FOR THE MONTH' ? headerIndexF.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'DESCRIPTION' ? headerIndex1F.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'MONTH' ? headerIndex2F.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'Date' ? headerIndex3F.push(index) : null )

    const dataInfo6 = {
        titleCell: 'A2',
        titleRange: 'A1:AC2',
        tbodyRange: `A2:AC${finalData6.length}`,
        theadRange: headerIndexF.length >= 1 ? `A${headerIndexF[0] + 1}:B${headerIndexF[0] + 1}` : null,
        theadRange1: headerIndex1F.length >= 1 ? `A${headerIndex1F[0] + 1}:C${headerIndex1F[0] + 1}` : null,
        theadRange2: headerIndex2F.length >= 1 ? `A${headerIndex2F[0] + 1}:K${headerIndex2F[0] + 1}` : null,
        theadRange3: headerIndex3F.length >= 1 ? `A${headerIndex3F[0] + 1}:AC${headerIndex3F[0] + 1}` : null,
    }


    stylePaymentMissedReport({ workbookBlob, dataInfo, dataInfo1, dataInfo2, dataInfo3, dataInfo4, dataInfo5, dataInfo6, dispatch, today})

}

const stylePaymentMissedReport = ({ workbookBlob, dataInfo, dataInfo1, dataInfo2, dataInfo3, dataInfo4, dataInfo5, dataInfo6, today, dispatch}) =>{
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach((sheet, index) => {
            if(index === 0){
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                });
        
                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                });
        
                sheet.range(dataInfo.theadRange).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                });
        
                sheet.range(dataInfo.theadRange1).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                });
            } else if (index === 1) {
                sheet.range(dataInfo1.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo1.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
                if (dataInfo1.theadRange) {
                    sheet.range(dataInfo1.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange1) {
                    sheet.range(dataInfo1.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange2) {
                    sheet.range(dataInfo1.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange3) {
                    sheet.range(dataInfo1.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
        
                if (dataInfo1.theadRange4) {
                    sheet.range(dataInfo1.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange5) {
                    sheet.range(dataInfo1.theadRange5).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 2) {
                sheet.range(dataInfo2.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo2.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                if (dataInfo2.theadRange) {
                    sheet.range(dataInfo2.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo2.theadRange1) {
                    sheet.range(dataInfo2.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 3) {
                sheet.range(dataInfo3.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo3.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo3.theadRange) {
                    sheet.range(dataInfo3.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange2) {
                    sheet.range(dataInfo3.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange3) {
                    sheet.range(dataInfo3.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange4) {
                    sheet.range(dataInfo3.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 4) {
                sheet.range(dataInfo4.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo4.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo4.theadRange) {
                    sheet.range(dataInfo4.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange1) {
                    sheet.range(dataInfo4.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }


                if (dataInfo4.theadRange2) {
                    sheet.range(dataInfo4.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange3) {
                    sheet.range(dataInfo4.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange4) {
                    sheet.range(dataInfo4.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange5) {
                    sheet.range(dataInfo4.theadRange5).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange6) {
                    sheet.range(dataInfo4.theadRange6).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange7) {
                    sheet.range(dataInfo4.theadRange7).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 5) {
                sheet.range(dataInfo5.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo5.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                if (dataInfo5.theadRange) {
                    sheet.range(dataInfo5.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 6) { 
                sheet.range(dataInfo6.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo6.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo6.theadRange) {
                    sheet.range(dataInfo6.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange1) {
                    sheet.range(dataInfo6.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange2) {
                    sheet.range(dataInfo6.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange3) {
                    sheet.range(dataInfo6.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            }
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', `AFCL PAYMENT MISSED REPORT ${contry} ${today}.xlsx`);
            // downloadAnchorNode.setAttribute('download', `paymentMissedReportNew${today}.xlsx`);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}