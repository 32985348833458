import React from 'react';
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import {Button, message, Popconfirm} from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import { projectArray } from "../../../../env/PROJECTSCONFIGURATIONS";

//actions import
import {pushChequeStatus, deleteLoanTerm, deleteExtraFee} from "../../../../actions/LoanActions";

//components import
import CommentCard from "../Cards/CommentCard";
import LoanCashCollectionCard from "../Cards/LoanCashCollectionCard";
import {BeatLoader} from "react-spinners";
import {Spinner} from "../../../common/Spinner";


class LoanStatement extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            currentLoanTerms: this.props.currentLoanTerms,
            currentLoan: this.props.currentLoan,
            deleteLoanTermSuccessful: this.props.deleteLoanTermSuccessful,
            currentLoanTermsNotFound: this.props.currentLoanTermsNotFound,
            deleteLoanTermFailed: this.props.deleteLoanTermFailed,
            cashCollections: this.props.cashCollections,
            chequeState: this.props.chequeState,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            deleteExtraFeeLoading: this.props.deleteExtraFeeLoading,
            deleteExtraFeeSuccessful: this.props.deleteExtraFeeSuccessful,
            deleteExtraFeeFailed: this.props.deleteExtraFeeFailed,
            deleteCashCollectionLoading: this.props.deleteCashCollectionLoading,

            localCurrency: "TSH",
        };

        this.renderChequeStatus = this.renderChequeStatus.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteExtraFee = this.onDeleteExtraFee.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
        this.displayExtraFeeCurrency = this.displayExtraFeeCurrency.bind(this);
    }

    componentWillMount() {
        if (!(_.isEmpty(this.state.currentLoanTerms))) {

            _.map(this.state.currentLoanTerms, term => {
                const cheque = term.cheque;
                this.props.pushChequeStatus(cheque)
            });
        }

        this.checkCurrencyFormat();
        this.displayExtraFeeCurrency();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState ({currentLoanTerms: nextProps.currentLoanTerms})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState ({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.deleteLoanTermSuccessful !== this.props.deleteLoanTermSuccessful) {
            this.setState ({deleteLoanTermSuccessful: nextProps.deleteLoanTermSuccessful})
        }

        if (nextProps.deleteLoanTermFailed !== this.props.deleteLoanTermFailed) {
            this.setState ({deleteLoanTermFailed: nextProps.deleteLoanTermFailed})
        }

        if (nextProps.currentLoanTermsNotFound !== this.props.currentLoanTermsNotFound) {
            this.setState ({currentLoanTermsNotFound: nextProps.currentLoanTermsNotFound})
        }

        if (nextProps.cashCollections !== this.props.cashCollections) {
            this.setState ({cashCollections: nextProps.cashCollections})
        }

        if (nextProps.chequeState !== this.props.chequeState) {
            this.setState ({chequeState: nextProps.chequeState})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState ({systemInfo: nextProps.systemInfo})
        }

        if (nextProps.deleteExtraFeeLoading !== this.props.deleteExtraFeeLoading) {
            this.setState ({deleteExtraFeeLoading: nextProps.deleteExtraFeeLoading})
        }

        if (nextProps.deleteExtraFeeSuccessful !== this.props.deleteExtraFeeSuccessful) {
            this.setState ({deleteExtraFeeSuccessful: nextProps.deleteExtraFeeSuccessful})
        }

        if (nextProps.deleteExtraFeeFailed !== this.props.deleteExtraFeeFailed) {
            this.setState ({deleteExtraFeeFailed: nextProps.deleteExtraFeeFailed})
        }

        if (nextProps.deleteCashCollectionLoading !== this.props.deleteCashCollectionLoading) {
            this.setState ({deleteCashCollectionLoading: nextProps.deleteCashCollectionLoading})
        }

    }

    renderExtraFeeDeletionSuccessMessage() {
        const {deleteExtraFeeSuccessful} = this.state;
        //
        if (deleteExtraFeeSuccessful){
            return (
                <div>
                    <p>{message.success("Extra fee deleted successfully")}</p>
                </div>
            );
        }

    }

    renderExtraFeeDeletionFailedMessage() {
        const {deleteExtraFeeFailed} = this.state;
        //
        if (deleteExtraFeeFailed){
            return (
                <div>
                    <p>{message.error("Extra fee deletion failed")}</p>
                </div>
            );
        }
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }


    displayCurrency(value, loanTerm) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if payment object is not empty
        if (!(_.isEmpty(loanTerm))) {
            const loanCurrency = loanTerm.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    displayExtraFeeCurrency(value) {
        //extract state
        const { profile, currentLoan, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency = "USD";

        //check if payment object is not empty
        if (!(_.isEmpty(currentLoan))) {
            const loanCurrency = currentLoan.currency;
            if (loanCurrency === "usd") {
                currency = localCurrency;

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    onDelete(loanTerm) {
        const {currentLoan} = this.state;

        if (!(_.isEmpty(currentLoan))) {
            //
            const customerID = currentLoan.customerID;

            this.props.deleteLoanTerm({currentLoan, customerID, term: loanTerm});
        } else {
            message.warning("Sorry! Please select a to delete");
        }
    }

    onDeleteExtraFee(extraFee) {
        //
        const {currentLoan} = this.state;
        if (!(_.isEmpty(currentLoan))) {
            //
            const customerID = currentLoan.customerID;
            const loanID = currentLoan.loanID;
            const extraFeeID = extraFee.extraFeeID;
            //
            this.props.deleteExtraFee({customerID, loanID, extraFeeID});
        } else {
            message.warning("Sorry! Please select a to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderExtraFee() {
        //extract state
        const {currentLoan} = this.state;

        return(
            <div className={"extraFeeContainer"}>
                {
                    "extraFees" in currentLoan ?
                        <div>
                            <h4>Extra Fees</h4>
                            {
                                _.map(currentLoan.extraFees, extraFee => {

                                    let seconds;
                                    extraFee.extraFeeDate.seconds ? seconds = extraFee.extraFeeDate.seconds : seconds = extraFee.extraFeeDate._seconds;

                                    const extraFeeDate = moment.unix(seconds).format("DD/MM/YYYY");
                                    return(
                                        <div className={"extraFeeBox"} key={extraFee.extraFeeID}>
                                            <h2>{extraFee.extraFeeName}</h2>
                                            <h2>{extraFeeDate}</h2>
                                            <h2>{this.displayExtraFeeCurrency(extraFee.extraFeeAmount, extraFee)}</h2>
                                            <Popconfirm
                                                title="Are you sure to delete this extra fee?"
                                                onConfirm={() => this.onDeleteExtraFee(extraFee)}
                                                onCancel={() => this.cancel()}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                            </Popconfirm>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        : null
                }
            </div>
        )
    }

    renderChequeStatus(loanTerm) {
        // //check if loanTerm is migrated
        // if (loanTerm.migrate) {
        //     //check if its after migration date
        //     if (loanTerm.calculatePenalInterest) {
        //         return <h4>{loanTerm.chequeStatus}</h4>
        //     } else {
        //         //check if its due date is passed
        //         let seconds;
        //         loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;
        //         const dueDate = moment.unix(seconds);
        //         const today = moment();
        //
        //         if((moment(dueDate).isSameOrBefore(today, 'day'))) {
        //             //flip to cleared
        //             return <h4>Nil</h4>;
        //         } else {
        //             return <h4>{loanTerm.chequeStatus}</h4>
        //         }
        //     }
        // } else {
        //     return <h4>{loanTerm.chequeStatus}</h4>
        // }
    }

    renderTermsAlert() {

        const {currentLoanTerms, currentLoan} = this.state;

        if (!(_.isEmpty(currentLoanTerms))) {

            const terms = _.map(currentLoanTerms, term => {
                return term
            });

            //extract loan terms number
            const loanTerms = currentLoan.loanTerms;

            let liquidationDate = "";
            if ("earlyLiquidationDate" in currentLoan) {
                let seconds;
                currentLoan.earlyLiquidationDate.seconds ? seconds = currentLoan.earlyLiquidationDate.seconds : seconds = currentLoan.earlyLiquidationDate._seconds;
                const date = moment.unix(seconds);
                liquidationDate = date.format("DD/MM/YYYY");
            }

            //make loan status readable
            let loanStatus = "";
            if (currentLoan.rescheduleStatus) {
                switch (currentLoan.rescheduleStatus) {
                    case "oldLoan":
                        loanStatus = "an old loan";
                        break;
                    case "rescheduledLoan":
                        loanStatus = "a rescheduled loan";
                        break;
                }
            }


            return (
                <div>
                    <div>
                        {
                            terms.length < loanTerms ?
                                <div style={{ backgroundColor: "#96241C", borderRadius: "0.6rem", padding: "0 1rem 0rem 1rem", margin: "0.5rem" }}>
                                    <p style={{ fontSize: "1.2rem", color: "white", fontFamily: "myriadText" }}>
                                        Please save {loanTerms - terms.length} term(s) to get the required loan statement.
                                        Selected loan must have {loanTerms} loan terms only.
                                    </p>
                                </div>
                                : null
                        }
                    </div>

                    <div>
                        {
                            currentLoan.earlyLiquidationDate ?
                                <div style={{ backgroundColor: "#96241C", borderRadius: "0.6rem", padding: "0 1rem 0rem 1rem", margin: "0.5rem" }}>
                                    <p style={{ fontSize: "1.2rem", color: "white", fontFamily: "myriadText" }}>
                                        This loan was liquidated on {liquidationDate}
                                    </p>
                                </div>
                                : null
                        }
                    </div>

                    <div>
                        {
                            currentLoan.rescheduleStatus ?
                                <div style={{ backgroundColor: "#96241C", borderRadius: "0.6rem", padding: "0 1rem 0rem 1rem", margin: "0.5rem" }}>
                                    <p style={{ fontSize: "1.2rem", color: "white", fontFamily: "myriadText" }}>
                                        This loan is {loanStatus}
                                    </p>
                                </div>
                                : null
                        }
                    </div>
                </div>
            );
        }
        // else {
        //     return <p>Please add all the required loan terms</p>;
        // }
    }

    renderChequeState(chequeStatus) {
        //
        switch (chequeStatus) {
            //
            case "pending":
                return <h4>Pending</h4>;
            case "notDeposited":
                return <h4>Not Deposited</h4>;
            case "bounced":
                return <h4>Bounced</h4>;
            case "cleared":
                return <h4>Cleared</h4>;
            case "held":
                return <h4>Held</h4>;

            default:
                return "";
        }
    }

    renderLoanStatement() {

        const {currentLoanTerms, currentLoan} = this.state;

        if (!(_.isEmpty(currentLoanTerms))) {

            const terms = _.map(currentLoanTerms, term => {
                return term
            });

            return (
                <div>
                    {
                        terms.map(loanTerm => {
                            let seconds;
                            loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;

                            const dueDate = moment.unix(seconds).format("DD/MM/YYYY");

                            
                            if (loanTerm.cheque) {
                                return(
                                    <div>
                                        <div className={"chequeTableValues"}>
                                            <div className={"textValueBox"}><h4>{loanTerm.chequeNumber}</h4></div>
                                            <div className={"textValueBox"}>{this.displayCurrency(loanTerm.amount, loanTerm)}</div>
                                            <div className={"textValueBox"}><h4>{dueDate}</h4></div>
                                            <div className={"textValueBox"}>{this.renderChequeState(loanTerm.chequeStatus)}</div>
                                            <div className={"textValueCommentButton"}>
                                                <CommentCard
                                                    comment={loanTerm.comment}
                                                />
                                            </div>
                                            <div className={"valueDeleteButtonBox"}>
                                                <Popconfirm
                                                    title="Are you sure to delete this loan term?"
                                                    onConfirm={() => this.onDelete(loanTerm)}
                                                    onCancel={this.cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {

                                return(
                                    <div>
                                        <div className={"cashTableValues"}>
                                            <div className={"textValueBox"}>{this.displayCurrency(loanTerm.amount, loanTerm)}</div>
                                            <div className={"textValueBox"}><h4>{dueDate}</h4></div>
                                            <div className={"textValueBox"}><h4>{loanTerm.bankName}</h4></div>
                                            <div className={"textValueCommentButton"}>
                                                <CommentCard
                                                    comment={loanTerm.comment}
                                                />
                                            </div>
                                            <div className={"valueDeleteButtonBox"}>
                                                <Popconfirm
                                                    title="Are you sure to delete this loan term?"
                                                    onConfirm={() => this.onDelete(loanTerm)}
                                                    onCancel={this.cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                                </Popconfirm>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }

                    <div>
                        {this.renderExtraFee()}
                    </div>
                </div>
            );

        } else {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        }
        // else if (this.state.currentLoanTermsNotFound){
        //     return (
        //         <p>Sorry there are no loan term now.</p>
        //     );
        // } else {
        //     return (
        //         <div>
        //             <BeatLoader
        //                 timeOut={500}
        //             />
        //         </div>
        //     );
        // }
    }


    renderCashCollections() {

        const {cashCollections} = this.state;

        const collections = _.map(cashCollections, collection => {

            return collection

        });

        return (
            <div>
                <div className={"collectionCashTableHeaders"}>
                    <div className={"textBox"}><h4>Cash Collection</h4></div>
                    <div className={"textBox"}><h4>Recipient Bank</h4></div>
                    <div className={"textBox"}><h4>Date</h4></div>
                    <div className={"textBox"}><h4>Comments</h4></div>
                    <div className={"textBox"}><h4>Update</h4></div>
                    <div className={"textBox"}><h4>Delete</h4></div>
                </div>
                <div>
                    {
                        this.state.deleteCashCollectionLoading?
                            <BeatLoader/>:
                            <div>
                                {
                                    collections.map(collection => {

                                        return(
                                            <div>
                                                <LoanCashCollectionCard
                                                    collection={collection}
                                                />
                                            </div>
                                        );
                                    })

                                }
                            </div>
                    }
                </div>
            </div>
        );

    }

    renderMessage() {

        const{deleteLoanTermSuccessful} = this.state;

        if (deleteLoanTermSuccessful) {
            return <p>{message.success("Loan term deleted")}</p>
        } else {
            return null
        }
    }

     renderFailedMessage() {

        const{deleteLoanTermFailed} = this.state;

        if (deleteLoanTermFailed) {
            return <p>{message.error("Sorry! Loan term is not deleted")}</p>
        } else {
            return null
        }
    }



    render() {
        const {currentLoan, currentLoanTerms} = this.state;

        const now = moment();
        const today = now.format("DD/MM/YYYY");

        //if loan is cheque or cash
        const terms = _.map(currentLoanTerms, term => {
            return term
        });

        let chequeStatus = false;
        if (terms.length !== 0) {
            const term = terms[0];

            if (term) {
               if(term.cheque) {
                   chequeStatus = true;
               }
            }
        }

        return(
            <div className={"loanStatementContainer"}>
                <h4>{currentLoan.customerName}</h4>
                <h4>Loan Statement as of {today}</h4>
                <div>{this.renderTermsAlert()}</div>
                <div className={"loanStatementBox"}>
                    {
                        chequeStatus ?
                            <div>
                                <div className={"chequeTableHeaders"}>
                                    <div className={"textBox"}><h4>Cheque #</h4></div>
                                    <div className={"textBox"}><h4>Amount</h4></div>
                                    <div className={"textBox"}><h4>Due Date</h4></div>
                                    <div className={"textBox"}><h4>Status</h4></div>
                                    <div className={"textBox"}><h4>Comments</h4></div>
                                    <div className={"textBox"}><h4>Delete</h4></div>
                                </div>
                                {this.renderLoanStatement()}
                            </div>:
                            <div>
                                <div className={"cashPaymentTableHeaders"}>
                                    <div className={"textBox"}><h4>Cash Payment</h4></div>
                                    <div className={"textBox"}><h4>Due Date</h4></div>
                                    <div className={"textBox"}><h4>Recipient Bank</h4></div>
                                    <div className={"textBox"}><h4>Comments</h4></div>
                                    <div className={"textBox"}><h4>Delete</h4></div>
                                </div>
                                {this.renderLoanStatement()}
                            </div>
                    }
                    <div>
                        {this.renderCashCollections()}
                    </div>
                    <div>
                        {this.renderMessage()}
                        {this.renderFailedMessage()}
                    </div>
                    <div>
                        {this.renderExtraFeeDeletionSuccessMessage()}
                        {this.renderExtraFeeDeletionFailedMessage()}
                    </div>
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { currentLoanTerms, loading, currentLoan, chequeState, deleteLoanTermSuccessful, deleteLoanTermFailed, currentLoanTermsNotFound,
             deleteExtraFeeLoading, deleteExtraFeeSuccessful, deleteExtraFeeFailed } = state.loans;
    const { cashCollections, deleteCashCollectionLoading } = state.collections;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    //return object with state properties
    return {
        currentLoanTerms,
        currentLoan,
        cashCollections,
        chequeState,
        deleteLoanTermSuccessful,
        deleteLoanTermFailed,
        currentLoanTermsNotFound,
        loading,
        profile,
        systemInfo,
        deleteExtraFeeLoading,
        deleteExtraFeeSuccessful,
        deleteExtraFeeFailed,
        deleteCashCollectionLoading
    }
};

export default connect(mapStateToProps,{pushChequeStatus, deleteLoanTerm, deleteExtraFee})(LoanStatement);