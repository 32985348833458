import React from 'react';
import { connect } from 'react-redux';


//action imports
import { serviceChargeDetailsChanged, serviceChargeDateChanged, saveServiceCharge } from '../../../actions/ServiceChargeActions';

//components imports
import {Input} from '../../common/Input';
import {TextArea} from '../../common/TextArea';
import {PickDate} from '../../common/PickDate';
import {Button} from '../../common/Button';
import {BeatLoader} from "react-spinners";
import {message, Switch} from 'antd';


class AddServiceCharge extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            saveServiceChargeSuccessful: this.props.saveServiceChargeSuccessful,
            currency: this.props.currency,
            disabled: false,
            serviceChargePaidAmount: this.props.serviceChargePaidAmount,
            serviceChargeLoading: this.props.serviceChargeLoading,
            serviceChargeBankName: this.props.serviceChargeBankName,
            serviceChargeDate: this.props.serviceChargeDate,
            serviceChargeComments: this.props.serviceChargeComments,
            systemInfo: this.props.systemInfo,
        };

        this.onServiceChargeDateChanged = this.onServiceChargeDateChanged.bind(this);
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.serviceChargeLoading !== this.props.serviceChargeLoading) {
            this.setState({serviceChargeLoading: nextProps.serviceChargeLoading})
        }

        if (nextProps.saveServiceChargeSuccessful !== this.props.saveServiceChargeSuccessful) {
            this.setState({saveServiceChargeSuccessful: nextProps.saveServiceChargeSuccessful})
        }

        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }

        if (nextProps.serviceChargePaidAmount !== this.props.serviceChargePaidAmount) {
            this.setState({serviceChargePaidAmount: nextProps.serviceChargePaidAmount})
        }

        if (nextProps.serviceChargeBankName !== this.props.serviceChargeBankName) {
            this.setState({serviceChargeBankName: nextProps.serviceChargeBankName})
        }

        if (nextProps.serviceChargeDate !== this.props.serviceChargeDate) {
            this.setState({serviceChargeDate: nextProps.serviceChargeDate})
        }

        if (nextProps.serviceChargeComments !== this.props.serviceChargeComments) {
            this.setState({serviceChargeComments: nextProps.serviceChargeComments})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({systemInfo: nextProps.systemInfo});
        }
    }

    onServiceChargeDateChanged(date) {
        this.props.serviceChargeDateChanged(date);
    }

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.serviceChargeDetailsChanged({prop: 'currency', value: status})
    }

    onSaveServiceCharge() {

        let {serviceChargePaidAmount, serviceChargeBankName, serviceChargeDate, serviceChargeComments, currency, systemInfo } = this.state;
        
        //disable button
        this.setState({disabled: true});
        setTimeout(() => {
            this.setState({disabled: false});
        }, 5000)
        //determine cash collection is tzs or usd
        let rawCurrency;
        if (currency) {
            rawCurrency = "usd";
        } else {
            rawCurrency = systemInfo.defaultCurrency;
        }

        if (serviceChargePaidAmount && serviceChargeBankName && serviceChargeDate && serviceChargeComments) {

            serviceChargeDate = serviceChargeDate.toDate();
            
            this.props.saveServiceCharge({serviceChargePaidAmount, serviceChargeBankName: serviceChargeBankName.toUpperCase(), serviceChargeDate, serviceChargeComments, currency: rawCurrency});
        } else {
            message.warning("Fill all the required fields");
        }
    }

    renderButton () {
        if (this.state.serviceChargeLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Save'}
                        onPress={() => this.onSaveServiceCharge()}
                        buttonDisabled={this.state.disabled}
                    />
                </div>
            );
        }
    }

    renderMessage() {
        const {saveServiceChargeSuccessful} = this.state;

        if (saveServiceChargeSuccessful) {
            return (
                <p>{message.success("Service charge is saved..")}</p>
            );
        } else {
            return null
        }
    }


    render(){

        const {systemInfo} = this.state;
        const currencyAbv = systemInfo.defaultCurrency.toUpperCase();

        return (
            <div className={"addServiceChargeContainer"}>
                <h4>Add Service Charge</h4>
                <div className={"serviceChargeBox"}>
                    <div className={"termBox"}>
                        <p>Currency</p>
                        <Switch
                            checkedChildren="USD"
                            unCheckedChildren={currencyAbv}
                            onChange={() => this.onCurrencySwitchChange() }
                            checked={this.state.currency}
                        />
                    </div>
                    <div className={"termBox"}>
                        <Input
                            label={'Paid Amount'}
                            type={'number'}
                            onChange={e => this.props.serviceChargeDetailsChanged({prop: 'serviceChargePaidAmount', value: e.target.value})}
                            value={this.state.serviceChargePaidAmount}
                        />
                    </div>

                    <div className={"termBox"}>
                        <Input
                            label={'Bank Name'}
                            type={'text'}
                            onChange={e => this.props.serviceChargeDetailsChanged({prop: 'serviceChargeBankName', value: e.target.value})}
                            value={this.state.serviceChargeBankName}
                        />
                    </div>

                    <div className={"termBox"}>
                        <PickDate
                            label={'Date'}
                            onChange={this.onServiceChargeDateChanged}
                            value={this.props.serviceChargeDate}
                        />
                    </div>

                    <div className={"termBox"}>
                        <TextArea
                            label={'Comments'}
                            onChange={e => this.props.serviceChargeDetailsChanged({prop: 'serviceChargeComments', value: e.target.value})}
                            value={this.props.serviceChargeComments}
                        />
                    </div>
                </div>

                <div className={"unidentifiedBoxButton"}>
                    {this.renderButton()}
                </div>
                <div>
                    {this.renderMessage()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const  {currency, serviceChargePaidAmount, serviceChargeBankName, serviceChargeDate, serviceChargeComments, serviceChargeLoading, saveServiceChargeSuccessful, saveServiceChargeFailed} = state.serviceCharge;
    const { systemInfo } = state.app;

    return{
        currency,
        systemInfo,
        serviceChargePaidAmount,
        serviceChargeBankName,
        serviceChargeDate,
        serviceChargeComments,
        serviceChargeLoading,
        saveServiceChargeSuccessful,
        saveServiceChargeFailed,
    }
};

export default connect(mapStateToProps, {serviceChargeDetailsChanged, serviceChargeDateChanged, saveServiceCharge })(AddServiceCharge);
