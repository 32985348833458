import React from 'react';
import { connect } from 'react-redux';


//components imports
import AddServiceCharge from './subComponents/AddServiceCharge';
import ViewServiceCharge from './subComponents/ViewServiceCharge';

class ServiceCharges extends React.Component {
    
    render(){
        return (
            <div className={"serviceChargeContainer"}>
                <h3>Service Charges</h3>

                <div>
                    <AddServiceCharge />
                </div>

                <div>
                    <ViewServiceCharge />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(ServiceCharges)
