import React from 'react'
import { connect } from 'react-redux';
//import _ from "lodash";
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//...import actions
import { computeIncompleteLoans, manualOverrideAllLoans } from "../../../actions/ManualOverrideActions";
import { calculatePenalInterest } from "../../../actions/AppActions";

//...component imports
import IncompleteLoansCard from '../cards/IncompleteLoansCard';
import {BeatLoader} from "react-spinners";
//import {Button} from "../../common/Button";
import {message, Button} from "antd";

export class IncompleteLoans extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            incompleteLoans: this.props.incompleteLoans,
            incompleteLoansLoading: this.props.incompleteLoansLoading,
            resetAllLoansLoading: this.props.resetAllLoansLoading,
            calculatePenalInterestLoading: this.props.calculatePenalInterestLoading,
            penalBtn: this.props.penalBtn,
        };

        this.onRefresh = this.onRefresh.bind(this);
        this.renderResetBtn = this.renderResetBtn.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onCalculatePenalInterest = this.onCalculatePenalInterest.bind(this);
    }

    componentWillMount(){
        this.props.computeIncompleteLoans();
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.incompleteLoans !== this.props.incompleteLoans){
            this.setState({incompleteLoans: nextProps.incompleteLoans});
        }

        if(nextProps.incompleteLoansLoading !== this.props.incompleteLoansLoading){
            this.setState({incompleteLoansLoading: nextProps.incompleteLoansLoading});
        }

        if(nextProps.resetAllLoansLoading !== this.props.resetAllLoansLoading){
            this.setState({resetAllLoansLoading: nextProps.resetAllLoansLoading});
        }

        if(nextProps.calculatePenalInterestLoading !== this.props.calculatePenalInterestLoading){
            this.setState({calculatePenalInterestLoading: nextProps.calculatePenalInterestLoading});
        }

        if(nextProps.penalBtn !== this.props.penalBtn){
            this.setState({penalBtn: nextProps.penalBtn});
        }
    }

    onRefresh() {
        this.props.computeIncompleteLoans();
        //this.props.resetModulo({ loans: this.state.incompleteLoans});
    }

    onCalculatePenalInterest() {
        //extract state
        const { incompleteLoans } = this.state;

        const sortedLoans = arraySort(incompleteLoans, "customerName");

        if (sortedLoans.length !== 0) {
            const loanIDArray = sortedLoans.map(loan => loan.loanID);
            const batch = loanIDArray.slice(0,9);
            this.props.calculatePenalInterest({ loanIDArray: batch, penalBtn: "incorrect" });
        } else {
            message.info("No Incomplete Loans to compute");
        }
    }

    renderPenalInterestBtn() {
        //extract state
        const { calculatePenalInterestLoading, penalBtn } = this.state;
        let disabled = false;

        if (calculatePenalInterestLoading) {
            if (penalBtn !== "incomplete") {
                disabled = true
            }
        }

        if (calculatePenalInterestLoading && penalBtn === "incomplete") {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <Button
                        disabled={disabled}
                        onClick={() => this.onCalculatePenalInterest()}
                    >
                        <span style={{ color: "#6D66E7" }}>Calculate Penal Interest</span>
                    </Button>
                    <p>Computes 10 loans per batch</p>
                </div>
            )
        }
    }

    onReset() {
        //extract state
        const { incompleteLoans } = this.state;

        this.props.manualOverrideAllLoans({ incompleteLoans });
    }

    renderIncorrectLoans(){
        const { incompleteLoans, incompleteLoansLoading } = this.state;

        const sortedLoans = arraySort(incompleteLoans, "customerName");

        if (sortedLoans.length !== 0) {
            return(
                <div className={'manualOverrideContainer'}>
                    {
                        sortedLoans.map(loan => {
                            return (
                                <IncompleteLoansCard loan={loan} key={loan.loanID} />
                            )
                        })
                    }
                </div>
            );
        } else {
            if (incompleteLoansLoading) {
                return <BeatLoader/>
            } else {
                return <p className={'userAdminsEmptyWarning'}>No Incomplete Loans available!</p>;
            }
        }

    }

    renderRefreshButton() {
        //extract state
        const { incompleteLoansLoading } = this.state;

        if (incompleteLoansLoading) {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={'sync-alt'} className={'refreshIcon'} onClick={ () => this.onRefresh() }/>
                </div>
            )
        }
    }

    renderResetBtn () {
        //extract state
        const { resetAllLoansLoading } = this.state;

        if (resetAllLoansLoading) {
            return <BeatLoader/>
        } else {
            return (
                <Button
                    text={"Reset All Incomplete Loans"}
                    onPress={ () => this.onReset() }
                />
            );
        }
    }


    render() {
        //extract state
        const { incompleteLoans } = this.state;
        const numberOfIncorrectOfLoans = incompleteLoans.length;
        return (
            <div className={'incorrectLoansContainer'}>
                <div>
                    <h4>({numberOfIncorrectOfLoans}) Incomplete Loans</h4>
                </div>

                {/*<div>*/}
                {/*    { this.renderPenalInterestBtn() }*/}
                {/*</div>*/}

                <div>
                    { this.renderResetBtn() }
                </div>

                <div>
                    <hr className="sep-2"/>
                </div>


                <div style={{ textAlign: 'right'}}>
                    {this.renderRefreshButton()}
                </div>


                <div>
                    { this.renderIncorrectLoans() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { incompleteLoans, incompleteLoansLoading, resetAllLoansLoading } = state.manualOverride;
    const { calculatePenalInterestLoading, penalBtn } = state.settings;

    return{
        incompleteLoans,
        incompleteLoansLoading,
        resetAllLoansLoading,
        calculatePenalInterestLoading,
        penalBtn
    }
}


export default connect(mapStateToProps, { computeIncompleteLoans, manualOverrideAllLoans, calculatePenalInterest })(IncompleteLoans);
