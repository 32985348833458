import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from "moment";
import arraySort from "array-sort";

//components import
import DashBoardCard from "./cards/DashBoardCard";

import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            bouncedCheques: this.props.bouncedCheques,
            allBeforeTerms: this.props.allBeforeTerms,
            systemInfo: this.props.systemInfo,
            localCurrency: "TSH",
        };

        this.renderDashBoardCard = this.renderDashBoardCard.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.bouncedCheques !== this.props.bouncedCheques) {
            this.setState({ bouncedCheques: nextProps.bouncedCheques })
        }

        if(nextProps.allBeforeTerms !== this.props.allBeforeTerms) {
            this.setState({ allBeforeTerms: nextProps.allBeforeTerms })
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: this.props.systemInfo });
        }
    }

    componentWillMount(){

        this.checkCurrencyFormat();
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    renderDashBoardCard() {
        //extract state
        const { bouncedCheques, allBeforeTerms } = this.state;

        let dates = {};

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        _.map(bouncedCheques, client => {
            client.values.map(term => {
                //take terms that are before migration date
                // if (!(_.isEmpty(systemInfo))) {
                //     //pull migration date
                //     if ("migrationDate" in systemInfo) {
                //         let migrationSeconds;
                //         systemInfo.migrationDate.seconds ? migrationSeconds = systemInfo.migrationDate.seconds : migrationSeconds = systemInfo.migrationDate._seconds;
                //         const migrationDate = moment.unix(migrationSeconds);
                //
                //         let seconds;
                //         if ("transactionDate" in term) {
                //             term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                //         } else {
                //             term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                //         }
                //         const dueDate = moment.unix(seconds);
                //
                //         if (dueDate.isSameOrAfter(migrationDate, "day")) {
                //             termsStore.push(term);
                //         }
                //     }
                // }
                termsStore.push(term);
            });
        });

        //group all terms into unique dates
        //check that termsStore is not empty
        if (termsStore.length !== 0) {
            termsStore.map(term => {
                //grab the date and change it into a string
                let seconds;
                if ("transactionDate" in term) {
                    if (term.transactionDate) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
                const dueDate = moment.unix(seconds);

                //put term in current month bucket
                //grab the month and year
                const dueDateID = dueDate.format("MMYYYY");

                //check if dueDateID already exists in dates object
                if (`${dueDateID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${dueDateID}`].terms;
                    terms.push(term);

                    dates[`${dueDateID}`].terms = terms;
                } else {
                    //its a new date so create new object for it
                    dates[`${dueDateID}`] = {
                        date: dueDate,
                        terms: [term],
                        termsStore,
                        allBeforeTerms
                    }
                }
            });
        }


        if (!(_.isEmpty(dates))) {
            ///extract date into array so it can be sorted
            const datesArray = _.map(dates, date => date);
            const sortedDates = arraySort(datesArray, "date");
            return(
                <div>
                    {
                        sortedDates.map(date => {
                            return <DashBoardCard date={date}/>;
                        })
                    }
                </div>
            );
        } else {
            return <h3 className={"userAdminsEmptyWarning"}>There is no data to display</h3>;
        }

    }

    renderSumOfDebitAmountLCY() {
        //extract state
        const { localCurrency, allBeforeTerms } = this.state;

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        if (allBeforeTerms.length !== 0) {
            allBeforeTerms.map(term => {
                //filter if they are cleared or not
                if (term.cheque) {
                    if (term.chequeStatus === "bounced") {
                        if (term.currency !== "usd") {
                            termsStore.push(term.amount);
                        }
                    }
                } else {
                    if (term.currency !== "usd") {
                        termsStore.push(term.amount);
                    }
                }
            });
        }

        //calculate the total amount from numbers in the array
        const total = termsStore.reduce((a, b) => a + b, 0);

        return <div><p>{this.displayCurrency({ value: total, currency: localCurrency })}</p></div>;
    }

    renderSumOfDebitAmountUSD() {
        //extract state
        const { allBeforeTerms } = this.state;

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        // _.map(bouncedCheques, client => {
        //     client.values.map(term => {
        //         //filter if they are cleared or not
        //         if (term.cheque) {
        //             if (term.chequeStatus === "bounced") {
        //                 if (term.currency === "usd") {
        //                     termsStore.push(term.amount);
        //                 }
        //             }
        //         } else {
        //             if (term.currency === "usd") {
        //                 termsStore.push(term.amount);
        //             }
        //         }
        //     });
        // });

        if (allBeforeTerms.length !== 0) {
            allBeforeTerms.map(term => {
                //filter if they are cleared or not
                if (term.cheque) {
                    if (term.chequeStatus === "bounced") {
                        if (term.currency === "usd") {
                            termsStore.push(term.amount);
                        }
                    }
                } else {
                    if (term.currency === "usd") {
                        termsStore.push(term.amount);
                    }
                }
            });
        }

        //calculate the total amount from numbers in the array
        const total = termsStore.reduce((a, b) => a + b, 0);

        return <div><p>{this.displayCurrency({ value: total, currency: "USD" })}</p></div>;
    }

    renderSumOfODInUSD() {
        //extract state
        const { bouncedCheques, systemInfo } = this.state;

        if (!(_.isEmpty(systemInfo))) {
            //put all terms in a store
            let termsStore = [];

            // {{ customerID, customerName, values: [{}, {}]}, {}}
            //loop over all client objects and extract its terms
            _.map(bouncedCheques, client => {
                client.values.map(term => {

                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            termsStore.push(amount);
                        } else {
                            //fetch system info exchange rate
                            const exchangeRate = systemInfo.exchangeRate;

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            const convertedAmount = amount/exchangeRate;

                            termsStore.push(convertedAmount);
                        }
                    }
                });
            });

            //calculate the total amount from numbers in the array
            const total = termsStore.reduce((a, b) => a + b, 0);

            return <div><p>{this.displayCurrency({ value: total, currency: "USD" })}</p></div>
        } else {
            return <h3>-</h3>
        }
    }

    renderDistinctCustomersPressable() {
        //extract state
        const { bouncedCheques } = this.state;

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        _.map(bouncedCheques, client => {
            client.values.map(term => {
                termsStore.push(term);
            });
        });

        //compute the number of distinct customers who have uncleared terms
        let distinctCustomers = {};

        termsStore.map(term => {
            if(`${term.customerID}` in distinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = distinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    distinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    distinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        const numberOfCustomers = Object.keys(distinctCustomers).length;

        return <p>{numberOfCustomers}</p>;
    }

    displayCurrency({ value, currency }) {
        //extract state
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p style={{fontFamily: 'Rockwell', fontSize: '0.8rem', wordBreak: 'break-all', width: '100%', }}>{formatter.format(value)}</p>;
    }

    render() {
        return (
            <div className={"dashboardContainer"}>
                <h2>Dash Board</h2>

                <div>
                    <div className={"dashboardHeaders"}>
                        <div><h4>Months</h4></div>
                        <div><h4>Sum of Debit amount LCY</h4></div>
                        <div><h4>Sum of Debit amount $</h4></div>
                        <div><h4>Sum of OD Reporting USD</h4></div>
                        <div><h4>Number of Customers</h4></div>
                        <div><h4>Ageing</h4></div>
                        <div><h4>Bucket</h4></div>
                        <div><h4>Total OD % per Bucket</h4></div>
                    </div>

                    <div>
                        { this.renderDashBoardCard() }
                    </div>
                    
                    <div className={"dashboardHeadersTwo"}>
                        <div><h4>Grand Total</h4></div>
                        <div>{ this.renderSumOfDebitAmountLCY() }</div>
                        <div>{ this.renderSumOfDebitAmountUSD() }</div>
                        <div>{ this.renderSumOfODInUSD() }</div>
                        <div>{ this.renderDistinctCustomersPressable() }</div>
                        <div></div>
                        <div> </div>
                        <div> </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { bouncedCheques, allBeforeTerms } = state.masterListNew;
    const { systemInfo } = state.app;

    return {
        bouncedCheques,
        allBeforeTerms,
        systemInfo,
    }
};

export default connect(mapStateToProps)(Dashboard);







// renderDashBoardCard() {
//     //extract state
//     const { bouncedCheques } = this.state;
//
//     let dates = {};
//
//     //put all terms in a store
//     let termsStore = [];
//
//     // {{ customerID, customerName, values: [{}, {}]}, {}}
//     //loop over all client objects and extract its terms
//     _.map(bouncedCheques, client => {
//         client.values.map(term => {
//             termsStore.push(term);
//         });
//     });
//
//     //group all terms into unique dates
//     //check that termsStore is not empty
//     if (termsStore.length !== 0) {
//         termsStore.map(term => {
//             //grab the date and change it into a string
//             let seconds;
//             if ("transactionDate" in term) {
//                 term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
//             } else {
//                 term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//             }
//             const dueDate = moment.unix(seconds);
//
//             //check if bucket is less than 30, put it on object with current month
//             const today = moment();
//             //find the number of days from today
//             const fromNow = today.diff(dueDate, 'days');
//
//             if (fromNow >= 30) {
//                 //put term in current month bucket
//                 //grab the month and year
//                 const dueDateID = today.format("MMYYYY");
//                 //check if dueDateID already exists in dates object
//                 if (`${dueDateID}` in dates) {
//                     //terms with this date already exist
//                     let terms = dates[`${dueDateID}`].terms;
//                     terms.push(term);
//
//                     dates[`${dueDateID}`].terms = terms;
//                 } else {
//                     //its a new date so create new object for it
//                     dates[`${dueDateID}`] = {
//                         date: dueDate,
//                         terms: [term],
//                         termsStore
//                     }
//                 }
//             } else {
//                 //grab the month and year
//                 const dueDateID = dueDate.format("MMYYYY");
//
//                 //check if dueDateID already exists in dates object
//                 if (`${dueDateID}` in dates) {
//                     //terms with this date already exist
//                     let terms = dates[`${dueDateID}`].terms;
//                     terms.push(term);
//
//                     dates[`${dueDateID}`].terms = terms;
//                 } else {
//                     //its a new date so create new object for it
//                     dates[`${dueDateID}`] = {
//                         date: dueDate,
//                         terms: [term],
//                         termsStore
//                     }
//                 }
//             }
//         });
//     }
//
//     if (!(_.isEmpty(dates))) {
//         ///extract date into array so it can be sorted
//         const datesArray = _.map(dates, date => date);
//         const sortedDates = arraySort(datesArray, "date");
//         return(
//             <div>
//                 {
//                     sortedDates.map(date => {
//                         return <DashBoardCard date={date}/>;
//                     })
//                 }
//             </div>
//         );
//     } else {
//         return <h3>There is no data to display</h3>;
//     }
//
// }