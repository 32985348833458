import React from "react";
import { connect } from 'react-redux';
import {Modal, message, Popconfirm, Button, Switch} from "antd";
import {BeatLoader} from "react-spinners";
import _ from 'lodash';

//actions imports
import { hidePenalInterest, penalInterestChanged, saveCustomPenalInterest } from "../../../../actions/LoanActions";
import { calculateLoanPenalInterest } from "../../../../actions/ManualOverrideActions";
import { penalInterestActivation } from "../../../../actions/SettingsActions";

//component imports
import {Input} from "../../../common/Input";



class PenalInterestCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            systemInfo: this.props.systemInfo,
            visible: this.props.visible,
            penalInterest: false,
            penalInterestSaved: this.props.penalInterestSaved,
            currentLoan: this.props.currentLoan,
            loanPenalInterestLoading: this.props.loanPenalInterestLoading,
            penalInterestStatus: this.props.penalInterestStatus,
        };

        this.onCalculatePenalInterest = this.onCalculatePenalInterest.bind(this);
        this.onSavePenalInterest = this.onSavePenalInterest.bind(this);
        this.onPenalInterestActivationSwitch = this.onPenalInterestActivationSwitch.bind(this);
    }

    componentWillMount() {

        const { systemInfo } = this.state;

        if (!(_.isEmpty(systemInfo))){
            if ('penalInterest' in systemInfo) {
                this.setState({ penalInterestStatus: systemInfo.penalInterest });
            }
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }

        if (nextProps.penalInterest !== this.props.penalInterest) {
            this.setState({penalInterest: nextProps.penalInterest})
        }

        if (nextProps.penalInterestSaved !== this.props.penalInterestSaved) {
            this.setState({penalInterestSaved: nextProps.penalInterestSaved})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.loanPenalInterestLoading !== this.props.loanPenalInterestLoading) {
            this.setState({loanPenalInterestLoading: nextProps.loanPenalInterestLoading})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            if ("penalInterest" in nextProps.systemInfo) {
                this.setState({ penalInterestStatus: nextProps.systemInfo.penalInterest });
            }
            this.setState({systemInfo: nextProps.systemInfo});
        }
    }

    handleOk = () => {
        this.props.hidePenalInterest();
    };

    handleCancel = () => {
        this.props.hidePenalInterest();
    };

    onCancel() {
        message.info("Penal Interest Calculation Canceled");
    }

    onCalculatePenalInterest() {
        //extract state
        const { currentLoan, penalInterestStatus } = this.state;
        const loanID = currentLoan.loanID;
        const customerID = currentLoan.customerID;

        //check if penal interest switch is on
        if (penalInterestStatus){
            //on
            this.props.calculateLoanPenalInterest({ loanID, customerID });
        } else {
            //off
            // penal interest is false
            message.warning("Please switch on penal interest on settings page before you proceed");
        }
    }

    renderButton() {
        //extract state
        const { loanPenalInterestLoading } = this.state;

        if (loanPenalInterestLoading) {
            return <BeatLoader/>
        } else {
            return(
                <Popconfirm
                    title="You are now calculating penal interest on this loan"
                    onConfirm={() => this.onCalculatePenalInterest()}
                    onCancel={() => this.onCancel()}
                    okText="Proceed"
                    cancelText="Cancel"
                >
                    <Button
                        style={{ width: "50%", margin: "1rem" }}
                    >
                        <span>Calculate Penal Interest</span>
                    </Button>
                </Popconfirm>
            );
        }
    }

    onPenalInterestActivationSwitch() {
        //extract state
        const { penalInterest } = this.state;

        this.setState({ penalInterest: !penalInterest });
        this.props.penalInterestActivation({ penalInterest: !penalInterest });
    }

    // renderSwitch() {
    //     //
    //     return (
    //         <div>
    //             <Switch
    //                 checkedChildren="Penal Interest Activated"
    //                 unCheckedChildren="Penal Interest Deactivated"
    //                 onChange={() => this.onPenalInterestActivationSwitch()}
    //                 checked={this.state.penalInterest}
    //             />
    //         </div>
    //     );
    // }

    onSavePenalInterest() {
        //extract state
        const { penalInterest, currentLoan } = this.state;

        //change penalInterest string to number/integer
        const customPenalInterest = parseInt(penalInterest);

        //extract current loan id
        const { loanID, customerID } = currentLoan;

        //check if interest, loanID, customerID is not empty
        if (penalInterest && customerID && loanID) {
            this.props.saveCustomPenalInterest({ customPenalInterest, loanID, customerID });
        } else {
            message.warning("Kindly fill in the custom penal interest");
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title="Penal Interest"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={800}
                >
                    <div id={"penalSwitchInLoan"}>
                        <Switch
                            checkedChildren="Penal Interest Activated"
                            unCheckedChildren="Penal Interest Deactivated"
                            onChange={() => this.onPenalInterestActivationSwitch()}
                            checked={this.state.penalInterest}
                        />
                    </div>
                    <div className={"modalBox"}>
                        { this.renderButton() }
                        <Input
                            label={'Enter Interest'}
                            type={'number'}
                            onChange={e => this.props.penalInterestChanged(e.target.value)}
                            value={this.state.penalInterest}
                        />
                        <Button onClick={() => this.onSavePenalInterest() }>
                            Save
                        </Button>
                    </div>
                    <div>
                        {
                            this.state.penalInterestSaved?
                                <p>{message.success("Penal interest is saved")}</p>: null
                        }
                    </div>

                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { penalInterest, penalInterestSaved } = state.loans;
    const { systemInfo } = state.app;
    const { loanPenalInterestLoading } = state.manualOverride;

    return {
        systemInfo,
        penalInterest,
        penalInterestSaved,
        loanPenalInterestLoading
    }
};

export default connect(mapStateToProps, { hidePenalInterest, penalInterestChanged, saveCustomPenalInterest, calculateLoanPenalInterest, penalInterestActivation })(PenalInterestCard);