import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {Modal} from "antd";
import moment from "moment";

//actions import

//components import
import DistinctCustomersCard from "./DistinctCustomersCard";

import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";

class RollOver1Card extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            overdue: this.props.overdue,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,

        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.overdue !== this.props.overdue) {
            this.setState({ overdue: this.props.overdue });
        }
        if(nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }
        if(nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    render() {
        const {overdue} = this.props;
        return (
            <div className={"rolloverValues"}>
                {
                    overdue.transDate === "Grand Total" ?
                    <div><p><b>{overdue.transDate}  {overdue.transYear}</b></p></div>:
                    <div><p>{overdue.transDate}  {overdue.transYear}</p></div>
                }
                <div><p>{overdue.customerName}</p></div>
                {
                    overdue.transDate === "Grand Total" ?
                    <div><p><b>{overdue.overdue}</b></p></div>:
                    <div><p>{overdue.overdue}</p></div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        profile,
        systemInfo,
    }
};

export default connect(mapStateToProps)(RollOver1Card);