import * as firebase from 'firebase';
import _ from "lodash";
import { message } from "antd";
import moment from "moment";
import {
    CALCULATE_PENAL_INTEREST,
    CALCULATE_PENAL_INTEREST_FAILED,
    CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS, CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_FAILED,
    CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_SUCCESSFUL,
    CALCULATE_PENAL_INTEREST_SUCCESSFUL,
    CHECK_CHEQUE_CLEARANCE,
    CHECK_CHEQUE_CLEARANCE_DIFFERENT_DAY,
    CHECK_CHEQUE_CLEARANCE_FAILED,
    CHECK_CHEQUE_CLEARANCE_SAME_DAY,
    CLEAR_CHEQUES,
    CLEAR_CHEQUES_FAILED,
    CLEAR_CHEQUES_SUCCESSFUL,
    CLEAR_PENAL_INTEREST_MESSAGE,
    FETCH_ADMINS,
    FETCH_ADMINS_FAILED,
    FETCH_ADMINS_SUCCESSFUL,
    FETCH_LOAN_TERMS,
    FETCH_LOAN_TERMS_FAILED,
    FETCH_LOAN_TERMS_SUCCESSFUL,
    FETCH_LOAN_WITH_SURPLUS_SUCCESSFUL,
    FETCH_SYSTEM_INFO,
    FETCH_SYSTEM_INFO_FAILED,
    FETCH_SYSTEM_INFO_SUCCESSFUL,
    SET_INITIAL_SEETINGS,
    SET_INITIAL_SEETINGS_FAILED,
    SET_INITIAL_SEETINGS_SUCCESSFUL,
    VIEW_CURRENCY_IN_USD,
    VIEW_CURRENCY_IN_USD_FAILED,
    VIEW_CURRENCY_IN_USD_SUCCESSFUL,
    FETCH_EXTERNAL_COLLECTORS,
    FETCH_EXTERNAL_COLLECTORS_SUCCESSFUL,
    FETCH_EXTERNAL_COLLECTORS_FAILED,
} from "./Types";
import {fetchIncorrectLoans, fetchPenalInterestLoans} from "./ManualOverrideActions";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

//property declaration
const XLSX = require('xlsx');

export const checkChequeClearance = () => {

    return(dispatch) => {
        //grab app system data
        dispatch({ type: CHECK_CHEQUE_CLEARANCE });
        firebase.firestore().collection("system").doc("info")
            .onSnapshot(function (doc) {
                const data = doc.data();

                if (!(_.isEmpty(data))) {
                    if ('lastChequeClearance' in data) {
                        //check the last update time
                        let seconds;

                        data.lastChequeClearance._seconds ? seconds = data.lastChequeClearance._seconds : seconds =data.lastChequeClearance.seconds;

                        const time = moment.unix(seconds);
                        const now = moment();

                        if((moment(time).isSame(now, 'day'))) {
                            //last cheque clearance was today
                            console.log("same day");
                            dispatch({ type: CHECK_CHEQUE_CLEARANCE_SAME_DAY });
                        } else {
                            console.log("different day");
                            //clear the cheques
                            dispatch({ type: CHECK_CHEQUE_CLEARANCE_DIFFERENT_DAY });
                            clearCheques(dispatch);
                        }
                    } else {
                        dispatch({ type: CHECK_CHEQUE_CLEARANCE_DIFFERENT_DAY });
                        clearCheques(dispatch);
                    }
                } else {
                    console.log("Data fetched is empty");
                }
            }, function (error) {
                console.log("failed to check cheque clearance");
                console.log(error);
                dispatch({ type: CHECK_CHEQUE_CLEARANCE_FAILED });
            })
    };

};

function clearCheques(dispatch) {
    dispatch({ type: CLEAR_CHEQUES });
    const url = `${project.serverUrl}clearCheques`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log("Here's clear cheques response");
            console.log(response);
            dispatch({
                type: CLEAR_CHEQUES_SUCCESSFUL,
            });
        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({ type: CLEAR_CHEQUES_FAILED });
    })

}

export const calculatePenalInterest = ({ loansArray, penalBtn }) => {
    const data = JSON.stringify({
        loansArray
    });

    loansArray.forEach(loan => {
        console.log(`entered ${loan.loanID}`)
    })

    return(dispatch) => {
        dispatch({ type: CALCULATE_PENAL_INTEREST, payload: penalBtn });
        console.log("penal pressed");
        const url = `${project.serverUrl}calculatePenalInterest`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                dispatch({ type: CALCULATE_PENAL_INTEREST_SUCCESSFUL });

                switch (penalBtn) {
                    case "penalInterest":
                        fetchPenalInterestLoans({ dispatch });
                        break;
                    case "incorrect":
                        fetchIncorrectLoans({ dispatch });
                        break;
                    default:
                        break;
                }
            }).catch((error) => {
                dispatch({ type: CALCULATE_PENAL_INTEREST_FAILED });
                switch (penalBtn) {
                    case "penalInterest":
                        fetchPenalInterestLoans({ dispatch });
                        break;
                    case "incorrect":
                        fetchIncorrectLoans({ dispatch });
                        break;
                    default:
                        break;
                }
                console.log("Here's your penal interest error from setting");
                console.log(error);
        })
    };
};

export const fetchAdmins = () => {

    return(dispatch) => {
        getAdmins({ dispatch });
    };
};

export const fetchExternalCollectors = () => {

    return(dispatch) => {
        getCollectors({ dispatch });
    };
};

export function getAdmins({ dispatch }) {
    dispatch({ type: FETCH_ADMINS });
    firebase.firestore().collection("adminBucket")
        .onSnapshot(function(querySnapshot) {
            //create admin array bucket
            const admins = [];

            querySnapshot.forEach(function(doc) {
                const data = doc.data();
                admins.push(data);
            });

            dispatch({
                type: FETCH_ADMINS_SUCCESSFUL,
                payload: admins
            })
        }, function (error) {
            console.log("couldn't fetch admins");
            console.log(error);
            dispatch({ type: FETCH_ADMINS_FAILED })
        });
}


export function getCollectors({ dispatch }) {
    dispatch({ type: FETCH_EXTERNAL_COLLECTORS });
    firebase.firestore().collection("externalCollectors")
        .onSnapshot(function(querySnapshot) {
            //create admin array bucket
            const admins = [];

            querySnapshot.forEach(function(doc) {
                const data = doc.data();
                admins.push(data);
            });

        console.log('externalCollectors', admins)


            dispatch({
                type: FETCH_EXTERNAL_COLLECTORS_SUCCESSFUL,
                payload: admins
            })
        }, function (error) {
            console.log("couldn't fetch admins");
            console.log(error);
            dispatch({ type: FETCH_EXTERNAL_COLLECTORS_FAILED })
        });
}


export const fetchSystemInfo = () => {
    return(dispatch) => {
        firebase.firestore().collection("system").doc("info")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();
                    dispatch({
                        type: FETCH_SYSTEM_INFO_SUCCESSFUL,
                        payload: data
                    });
                } else {
                    dispatch({ type: FETCH_SYSTEM_INFO_FAILED })
                }
            }, function (error) {
                dispatch({ type: FETCH_SYSTEM_INFO_FAILED });
                console.log(error);
            })
    }
};

export const setInitialSettings = ({ exchangeRate }) => {
    return(dispatch) => {
        dispatch({ type: SET_INITIAL_SEETINGS });
        firebase.firestore().collection("system").doc("info")
            .get()
            .then((doc) => {
                if (doc.exists) {
                    firebase.firestore().collection("system").doc("info")
                        .update({ exchangeRate, migration: true })
                        .then(() => {
                            console.log("initial settings update successful");
                            dispatch({
                                type: SET_INITIAL_SEETINGS_SUCCESSFUL
                            })
                        })
                        .catch((error) => {
                            dispatch({ type: SET_INITIAL_SEETINGS_FAILED });
                            console.log("initial settings update failed");
                            console.log(error);
                        })
                } else {
                    firebase.firestore().collection("system").doc("info")
                        .set({ exchangeRate, migration: true })
                        .then(() => {
                            console.log("initial settings set successful");
                            dispatch({
                                type: SET_INITIAL_SEETINGS_SUCCESSFUL
                            })
                        })
                        .catch((error) => {
                            dispatch({ type: SET_INITIAL_SEETINGS_FAILED });
                            console.log("initial settings set failed");
                            console.log(error);
                        })
                }
            })
    };
};


export const grabAnalytics = () => {
    return() => {
        firebase.firestore().collection("loanTerms").get()
            .then(snapshot => {
                let loanTerms = {};
                let aprilLoanTerms = {};
                let aprilUSDTerms = {};
                let aprilTZSTerms = {};
                let aprilCheques = {};
                let aprilDirect = {};
                let pendingCheques = {};

                if(snapshot.size !== 0) {
                    //
                    snapshot.forEach(doc => {
                        //grab data
                        const data = doc.data();

                        //compute due date
                        let seconds;
                        data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                        const dueDateMoment = moment.unix(seconds);
                        const dueDate = dueDateMoment.format("DD/MM/YYYY");

                        loanTerms[doc.id] = {
                            dueDate,
                            loanID: data.loanID,
                            amount: data.amount,
                            currency: data.currency
                        }

                        //grab aprils terms
                        const today = moment();


                        if (dueDateMoment.isBefore(today)) {

                            if (data.cheque) {
                                if (data.customerID === "1013") {
                                    pendingCheques[doc.id] = {
                                        customerID: data.customerID,
                                        loanID: data.loanID,
                                        chequeNo: data.chequeNumber
                                    }
                                }
                            }
                        }

                        if (dueDateMoment.isSame(today, "month")) {
                            aprilLoanTerms[doc.id] = {
                                dueDate,
                                loanID: data.loanID,
                                amount: data.amount,
                                currency: data.currency,
                                customerID: data.customerID,
                                chequeNumber: data.chequeNumber
                            }

                            if (data.currency === "usd") {
                                aprilUSDTerms[doc.id] = {
                                    dueDate,
                                    customerID: data.customerID,
                                    loanID: data.loanID,
                                    amount: data.amount,
                                    currency: data.currency
                                }
                            }

                            if (data.currency === "tzs") {
                                aprilTZSTerms[doc.id] = {
                                    dueDate,
                                    customerID: data.customerID,
                                    loanID: data.loanID,
                                    amount: data.amount,
                                    currency: data.currency
                                }
                            }

                            if (data.cheque) {
                                aprilCheques[doc.id] = {
                                    dueDate,
                                    customerID: data.customerID,
                                    loanID: data.loanID,
                                    amount: data.amount,
                                    currency: data.currency,
                                    chequeNumber: data.chequeNumber
                                }
                            } else {
                                aprilDirect[doc.id] = {
                                    dueDate,
                                    customerID: data.customerID,
                                    loanID: data.loanID,
                                    amount: data.amount,
                                    currency: data.currency
                                }
                            }
                        }
                    });

                    console.log({ aprilLoanTerms, loanTerms });

                    let aprilTermsAmount = {};
                    const totalTzsArray = [];
                    _.map(aprilLoanTerms, term => {
                        if (term.currency === "tzs") {
                            return totalTzsArray.push(term.amount);
                        }
                    });

                    aprilTermsAmount["tzsAmount"] = totalTzsArray.reduce((a, b) => a + b, 0);

                    const totalUSDArray = [];
                    _.map(aprilLoanTerms, term => {
                        if (term.currency === "usd") {
                            return totalUSDArray.push(term.amount);
                        }
                    });

                    aprilTermsAmount["usdAmount"] = totalUSDArray.reduce((a, b) => a + b, 0);

                    let aprilChequesAmount = {};
                    const totalTZSChequesArray = [];
                    const totalUSDChequesArray = [];
                    _.map(aprilCheques, term => {
                        if (term.currency === "tzs") {
                            return totalTZSChequesArray.push(term.amount);
                        } else {
                            return totalUSDChequesArray.push(term.amount);
                        }
                    });

                    aprilChequesAmount["chequesTZSAmount"] = totalTZSChequesArray.reduce((a, b) => a + b, 0);
                    aprilChequesAmount["chequesUSDAmount"] = totalUSDChequesArray.reduce((a, b) => a + b, 0);

                    let aprilDirectAmount = {};
                    const totalTZSDirectArray = [];
                    const totalUSDDirectArray = [];
                    _.map(aprilDirect, term => {
                        if (term.currency === "tzs") {
                            return totalTZSDirectArray.push(term.amount);
                        } else {
                            return totalUSDDirectArray.push(term.amount);
                        }
                    });

                    aprilDirectAmount["directTZSAmount"] = totalTZSDirectArray.reduce((a, b) => a + b, 0);
                    aprilDirectAmount["directUSDAmount"] = totalUSDDirectArray.reduce((a, b) => a + b, 0);

                    console.log({ aprilTermsAmount });

                    console.log({ pendingCheques });

                    console.log({ loanTerms, aprilTZSTerms, aprilUSDTerms });
                    console.log({ loanTermsLength: _.size(loanTerms), usdLength: _.size(aprilUSDTerms), tzsLength: _.size(aprilTZSTerms) });

                    console.log({ aprilCheques, aprilDirect });
                    console.log({ chequeLength: _.size(aprilCheques), directLength: _.size(aprilDirect) });

                    console.log({ aprilChequesAmount, aprilDirectAmount });


                    const report = _.map(aprilLoanTerms, cheque => {
                        return {
                            CURRENCY: cheque.currency,
                            AMOUNT: cheque.amount,
                            CHEQUENO: cheque.chequeNumber,
                            CUSTOMERID: cheque.customerID,
                            DUEDATE: cheque.dueDate,
                            LOANID: cheque.loanID
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CURRENCY","AMOUNT","CHEQUENO", "CUSTOMERID", "DUEDATE", "LOANID"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:15},
                        {wch:15},
                        {wch:15},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `APRIL_TERMS.xlsx`);

                } else {
                    console.log("NO LOAN TERMS FETCHED");
                }
            })
    }
};

export const fetchLoansWithSurplus = () => {
    //
    const surplus = 0;

    let loans = {};

    return(dispatch) => {
        //
        firebase.firestore().collection("loans").where("surplus", ">", surplus)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //
                    loans[doc.id] = doc.data();
                })
                console.log({loans});

                dispatch({
                    type: FETCH_LOAN_WITH_SURPLUS_SUCCESSFUL,
                    payload: loans
                });
            }, function (error) {
                console.log("failed to execute fetch squad");
                console.log(error);
            })

    }
}

export const calculateGeneralPenalInterest = () => {

    return(dispatch) => {
        //
        dispatch({ type: CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS});
        console.log("penal pressed");
        const url = `${project.serverUrl}calculatePenalInterestForAllLoans`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => {
            dispatch({ type: CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_SUCCESSFUL });
            console.log(response);
            dispatch({ type: CLEAR_PENAL_INTEREST_MESSAGE });
        }).catch((error) => {
            dispatch({ type: CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_FAILED });
            console.log("Here's your penal interest error from setting");
            console.log(error);
            dispatch({ type: CLEAR_PENAL_INTEREST_MESSAGE });
        })
    }
};


export const demouSe = () => {
    return async () => {
        const loanTermsRef = firebase.firestore().collection("loanTerms").where("loanID", "==", "112360");
        const snapshot = await loanTermsRef.get();

        snapshot.forEach(doc => {
            const term = doc.data();
            updateLoanTerm(term);
        })
    }
}

async function updateLoanTerm(term) {
    firebase.firestore().collection("loanTerms").doc(term.loanTermID)
        .update({customerName: "LIGHTNESS SCHOOL"})
        .then(() => {
            updateProfileLoanTerm(term);
        }).catch((e) => {
            console.log(e);
        })
}

async function updateProfileLoanTerm(term) {
    firebase.firestore().collection("users").doc("clients").collection("908").doc("public").collection("loanTerms").doc(term.loanTermID)
        .update({customerName: "LIGHTNESS SCHOOL"})
        .then(() => {
            console.log("sccess")
        }).catch((e) => {
            console.log(e);
        })
}


