import React from 'react';
import { connect } from 'react-redux';
import {NavLink} from 'react-router-dom';

//action imports

//component imports

class CurrentLoansContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

    }

    componentWillMount() {

    }

    render() {
        return (
            <div className={"clearImportedLoansBox"}>
                <div className={'penalInterestSettingsCard'}>
                    <div className={"incorrectLoansLinkBox"}>
                        <NavLink to={'/current-paid-loans'}><p>Calculate</p></NavLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {})(CurrentLoansContainer);
