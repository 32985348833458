import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {message, Checkbox } from "antd";
import 'antd/dist/antd.css';

//action import
import {signUpDetailsChanged, signUpUser, roleChanged} from "../../actions/AuthenticationActions";

//component import
import {Input} from "../common/Input";
import UserAccess from "./subComponents/UserAccess";
import {Button} from "../common/Button";


//file imports
import flower from '../../assets/images/flower.png';
import { BeatLoader } from "react-spinners";

class SignUpPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.name,
            username: this.props.username,
            password: this.props.password,
            role: this.props.role,
            userAccess: this.props.userAccess,
            profile: this.props.profile,
            signUpLoading: this.props.signUpLoading,
            error: this.props.error,
            errorMessage: this.props.errorMessage,
        };

        this.onSignUpPress = this.onSignUpPress.bind(this);
        this.renderError = this.renderError.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContent) {

        if (nextProps.name !== this.props.name) {
            this.setState({ name: nextProps.name });
        }

        if (nextProps.username !== this.props.username) {
            this.setState({ username: nextProps.username});
        }

        if (nextProps.password !== this.props.username) {
            this.setState({ password: nextProps.password });
        }

        if (nextProps.role !== this.props.role) {
            this.setState({ role: nextProps.role });
        }

        if (nextProps.userAccess !== this.props.userAccess) {
            this.setState({ userAccess: nextProps.userAccess });
        }

        if (nextProps.error !== this.props.error) {
            this.setState({ error: nextProps.error });
        }

        if (nextProps.errorMessage !== this.props.errorMessage) {
            this.setState({ errorMessage: nextProps.errorMessage });
        }

        if (nextProps.signUpLoading !== this.props.signUpLoading) {
            this.setState({ signUpLoading: nextProps.signUpLoading });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

    }

    onRoleChange() {
        //
        const status = !this.state.role;

        if (status) {
            const role = "collector";
            this.props.roleChanged(role);
        } else {
            const role = "";
            this.props.roleChanged(role);
        }
    }

    renderUserRoleInput() {
        const {role} = this.state;

        if (role !== "collector") {
             return (
                 <div>
                     <Input
                         type={'text'}
                         placeholder={'User Role'}
                         onChange={e => this.props.signUpDetailsChanged({prop: 'role', value: e.target.value}) }
                         value={this.state.role}
                     />
                 </div>
             );
        } else {
            return null;
        }
    }


    onSignUpPress() {
        //handle login

        //fetch credentials
        const { name, username, password, role, userAccess } = this.state;

        //extract props
        const { history } = this.props;

        //check if fields are not empty
        if (name && username && password && role && userAccess.length !== 0) {
            //call login action from actions
            //check that password is more than 6 digits
            if (password.length >= 6) {
                if(isNaN(password)){
                    function validateEmail(email)
                    {
                        const re = /\S+@\S+\.\S+/;
                        return re.test(email);
                    }
                    if(validateEmail(username)) {
                        this.props.signUpUser({ name, username, password, role, userAccess, history });
                    } else {
                        message.warning("Incorrect email format");
                    }
                }else{
                    message.warning("Enter mixed characters as password");
                }
            } else {
                message.warning("Password can't be less than 6 characters");
            }
        } else {
            message.warning('Fill all required fields');
        }
    }

    renderError() {
        //extract state
        const { error, errorMessage } = this.state;

        if (error) {
            return <h3>{errorMessage}</h3>
        }
    }

    renderButton () {

        //extract state
        const { signUpLoading } = this.state;

        if (signUpLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div className={"signUpButtonBox"}>
                    <Button
                        text={'Register'}
                        onPress={()=> this.onSignUpPress()}
                    />
                </div>
            );
        }
    }

    checkUserAccess() {
        //extract state
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "profiles");

        if (access) {
            return(
                <div>
                    <h4>Register users</h4>
                    <div className={"signUpFormBox"}>
                        <div>
                            <Input
                                type={'text'}
                                // label={'Email'}
                                placeholder={'Name'}
                                onChange={e => this.props.signUpDetailsChanged({prop: 'name', value: e.target.value}) }
                                value={this.state.name}
                            />
                        </div>
                        <div>
                            <Input
                                type={'text'}
                                // label={'Email'}
                                placeholder={'Email'}
                                onChange={e => this.props.signUpDetailsChanged({prop: 'username', value: e.target.value}) }
                                value={this.state.username}
                            />
                        </div>
                        <div>
                            <Input
                                type={'password'}
                                placeholder={'Password'}
                                onChange={e => this.props.signUpDetailsChanged({prop: 'password', value: e.target.value}) }
                                value={this.state.password}
                            />
                            <div className={"passInstructions"}>
                                <p>Password has to be</p>
                                <ol>
                                    <li>6 or more characters</li>
                                    <li>Not bare digital digits</li>
                                </ol>
                            </div>
                        </div>
                        <div className={"selectUserRoleBox"}>
                            <div>
                                <Checkbox onChange={() => this.onRoleChange()}><span style={{color: '#4262e9'}}>/</span> Collector <span style={{color: '#4262e9'}}>/</span> </Checkbox>
                            </div>
                            {this.renderUserRoleInput()}
                        </div>
                        <div>
                            <UserAccess
                                value={this.state.userAccess}
                            />
                        </div>
                        <div>
                            { this.renderError() }
                            { this.renderButton() }
                        </div>

                        <div className={"signUpArtwork"}>
                            <img className={"flowerOne"} src={flower} alt="flower artwork" />
                            <img className={"flowerTwo"} src={flower} alt="flower artwork" />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {

        return (
            <div className={"signUpContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { name, role, userAccess, username, password, error, errorMessage, signUpLoading } = state.authentication;
    const { profile } = state.profile;

    //return object with state properties
    return {
        name,
        role,
        userAccess,
        username,
        password,
        signUpLoading,
        profile,
        error,
        errorMessage,
    }
};


export default withRouter(connect(mapStateToProps, {signUpDetailsChanged, signUpUser, roleChanged})(SignUpPage));