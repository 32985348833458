import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from "react-router-dom";

//actions import
import { pushCurrentLoan, customerLoanListChanged, identifyPayment } from "../../../actions/UnidentifiedActions";


//components imports

import ChooseCustomerLoan from "./subCollections/ChooseCustomerLoan";
import {Button} from "../../common/Button";
import {BeatLoader} from "react-spinners";
import {message} from "antd";


class IdentifiedCustomerLoans extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            fetchedCustomer: this.props.fetchedCustomer,
            fetchedCustomerLoans: this.props.fetchedCustomerLoans,
            customerDetails: this.props.customerDetails,
            selectedLoan: this.props.selectedLoan,
            selectedPayment: this.props.selectedPayment,

            updateLoanTerm: this.props.updateLoanTerm,
            successfulIdentified: this.props.successfulIdentified,
        };

        this.onLoanListChange = this.onLoanListChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer) {
            this.setState ({fetchedCustomer: nextProps.fetchedCustomer})
        }

        if (nextProps.fetchedCustomerLoans !== this.props.fetchedCustomerLoans) {
            this.setState ({fetchedCustomerLoans: nextProps.fetchedCustomerLoans})
        }

        if (nextProps.selectedPayment !== this.props.selectedPayment) {
            this.setState ({selectedPayment: nextProps.selectedPayment})
        }

        if (nextProps.selectedLoan !== this.props.selectedLoan) {
            this.setState ({selectedLoan: nextProps.selectedLoan})
        }

        if (nextProps.customerDetails !== this.props.customerDetails) {
            this.setState ({customerDetails: nextProps.customerDetails})
        }

        if (nextProps.updateLoanTerm !== this.props.updateLoanTerm) {
            this.setState ({updateLoanTerm: nextProps.updateLoanTerm})
        }

        if (nextProps.successfulIdentified !== this.props.successfulIdentified) {
            this.setState ({successfulIdentified: nextProps.successfulIdentified})
        }

    }

    onLoanListChange(value) {
        this.props.customerLoanListChanged(value)
    }

    onIdentifyCustomer() {
        //extract from state
        const {fetchedCustomer, selectedLoan, selectedPayment } = this.state;
        console.log(selectedLoan);
        console.log(selectedPayment);


        if (!(_.isEmpty(fetchedCustomer))) {

            if (selectedLoan){

                //extract from props
                const { history } = this.props;

                console.log("fetched customer");
                console.log(fetchedCustomer);

                //extract unIdentified props from selected payment
                const { unidentifiedPaidAmount, unidentifiedBankName, unidentifiedBankDate, unidentifiedComments } = selectedPayment;

                //assign unidentified payment to customer
                const loan = JSON.parse(selectedLoan);
                const customerID = loan.customerID;
                const customerName = fetchedCustomer.customerName;
                const loanID = loan.loanID;

                const unIdentifiedID = selectedPayment.unIdentifiedID;

                //extract the currencies, both loan and payment.
                const unidentifiedPaymentCurrency = selectedPayment.currency;
                const selectedLoanCurrency = loan.currency;

                const paidAmount = unidentifiedPaidAmount;
                const bankName = unidentifiedBankName;
                const bankDate = unidentifiedBankDate.toDate();
                const comment = unidentifiedComments;
                const currency = unidentifiedPaymentCurrency;


                //compare the two extracted currencies
                if (unidentifiedPaymentCurrency === selectedLoanCurrency) {
                    //if loan is selected, assign payment
                    //post to action
                    //check if selected loan belong to fetched customer
                    if (fetchedCustomer.customerID === loan.customerID){
                        //yes
                        const commenting = { timeStamp: new Date(), collectorID: comment.collectorID, comments: comment.comments };
                        this.props.identifyPayment({ customerID, customerName, loanID, unIdentifiedID, paidAmount, bankName, bankDate, comment: commenting, currency, history });
                    } else {
                        message.warn("Sorry! Please refresh the system and try again to save");
                    }
                } else {
                    message.warning("payment currency is different from loan currency. Please delete the payment and convert the currency before you try to save");
                }
            } else {
                message.warning("Please select a loan to save the payment");
            }
        } else {
            message.warning('There is no customer to update loan details');
        }
    }

    renderButton () {
        if (this.state.updateLoanTerm) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Proceed'}
                        onPress={() => this.onIdentifyCustomer()}
                    />
                </div>
            );
        }
    }

    render() {

        return (
            <div className={"identifiedCustomerLoanContainer"}>
                <h4>Select loan to save payment</h4>
                <div>
                    <ChooseCustomerLoan
                        label={'Customer Loans'}
                        placeholder={'select Customer Loan'}
                        onChange={this.onLoanListChange}
                        value={this.props.selectedLoan}
                    />
                </div>
                <div className={"identifiedCustomerLoanButton"}>
                    {this.renderButton()}
                </div>
                <div>{
                    this.state.successfulIdentified?
                        <p>{message.success("Payment is saved")}</p>: null
                }</div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { fetchedCustomer, fetchedCustomerLoans, loading, customerDetails, selectedPayment, selectedLoan, updateLoanTerm, successfulIdentified } = state.unidentified;

    //return object with state properties
    return {
        fetchedCustomer,
        fetchedCustomerLoans,
        customerDetails,
        loading,
        selectedPayment,
        selectedLoan,

        updateLoanTerm,
        successfulIdentified,
    }
};

export default withRouter(connect(mapStateToProps,{ pushCurrentLoan, customerLoanListChanged, identifyPayment })(IdentifiedCustomerLoans));