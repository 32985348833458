import React from 'react';
import { Select } from 'antd';


//action import


class SelectProfile extends React.Component {


    render() {

        const { Option } = Select;

        return (
            <div>
                <div className={"inputLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 190 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    <Option value="individual">Individual</Option>
                    <Option value="sme">SME</Option>
                    <Option value="corporate">Corporate</Option>
                    <Option value="trust">Trust</Option>
                    <Option value="government">Government</Option>
                    <Option value="others">Others</Option>
                </Select>

            </div>
        );
    }
}

export default (SelectProfile);
