import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import {Modal, Button, message} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter,  } from 'react-router-dom';


//actions import
import {additionalInfoChanged, saveAdditionalInfoToLoan} from "../../../../actions/LoanActions";

//components import
import {Input} from "../../../common/Input";
import SelectProfile from "../../../common/SelectProfile";
import SelectTruck from "../../../common/SelectTruck";
import SelectIndustry from "../../../common/SelectIndustry";
import SelectSegment from "../../../common/SelectSegment";
import {BeatLoader} from "react-spinners";
import SelectCharacter from "../../../common/selectCharacter";
import SelectUBA from "../../../common/SelectUBA";
import SelectWA from "../../../common/SelectWA";
import SelectWBNA from "../../../common/SelectWBNA";
import SelectUU from "../../../common/SelectUU";
import Industry from "../../../settings/manage/Industry";
import Segment from '../../../settings/manage/Segment';
import Character from '../../../settings/manage/Character';


class AdditionalInfo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            visible1: false,
            visible2: false,
            visible3: false,
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
            salesExe: this.props.salesExe,
            clientProfile: this.props.clientProfile,
            truck: this.props.truck,
            industry: this.props.industry,
            model: this.props.model,
            segment: this.props.segment,
            clientAction: this.props.clientAction,
            character: this.props.character,

            additionalInfoLoading: this.props.additionalInfoLoading,
            additionalInfoSaved: this.props.additionalInfoSaved,
            additionalInfoFailed: this.props.additionalInfoFailed,
        };

        this.onManageIndustryPath = this.onManageIndustryPath.bind(this);
        this.onSelectProfile = this.onSelectProfile.bind(this);
        this.onSelectTruck = this.onSelectTruck.bind(this);
        this.onSelectIndustry = this.onSelectIndustry.bind(this);
        this.onSelectSegment = this.onSelectSegment.bind(this);
        this.onSelectClientCharacter = this.onSelectClientCharacter.bind(this);
        this.onSelectClientAction = this.onSelectClientAction.bind(this);
    }

    componentWillMount() {
        const {currentLoan} = this.state;

        if (!(_.isEmpty(currentLoan))){
            //
            if ("salesExe" in currentLoan){
                this.setState({salesExe: currentLoan.salesExe})
            }

            if ("clientProfile" in currentLoan){
                this.setState({clientProfile: currentLoan.clientProfile})
            }

            if ("truck" in currentLoan){
                this.setState({truck: currentLoan.truck})
            }

            if ("industry" in currentLoan){
                this.setState({industry: currentLoan.industry})
            }

            if ("model" in currentLoan){
                this.setState({model: currentLoan.model})
            }

            if ("segment" in currentLoan){
                this.setState({segment: currentLoan.segment})
            }

            if ("clientAction" in currentLoan){
                this.setState({clientAction: currentLoan.clientAction})
            }

            if ("character" in currentLoan){
                this.setState({character: currentLoan.character})
            }


        }
     }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({ currentLoan: nextProps.currentLoan })
        }
        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({ currentLoanTerms: nextProps.currentLoanTerms })
        }

        if (nextProps.salesExe !== this.props.salesExe) {
            this.setState({ salesExe: nextProps.salesExe })
        }

        if (nextProps.clientProfile !== this.props.clientProfile) {
            this.setState({ clientProfile: nextProps.clientProfile })
        }

        if (nextProps.truck !== this.props.truck) {
            this.setState({ truck: nextProps.truck })
        }

        if (nextProps.industry !== this.props.industry) {
            this.setState({ industry: nextProps.industry })
        }

        if (nextProps.model !== this.props.model) {
            this.setState({ model: nextProps.model })
        }

        if (nextProps.segment !== this.props.segment) {
            this.setState({ segment: nextProps.segment })
        }

        if (nextProps.clientAction !== this.props.clientAction) {
            this.setState({ clientAction: nextProps.clientAction })
        }

        if (nextProps.character !== this.props.character) {
            this.setState({ character: nextProps.character })
        }

        if (nextProps.additionalInfoLoading !== this.props.additionalInfoLoading) {
            this.setState({ additionalInfoLoading: nextProps.additionalInfoLoading })
        }

        if (nextProps.additionalInfoSaved !== this.props.additionalInfoSaved) {
            this.setState({ additionalInfoSaved: nextProps.additionalInfoSaved })
        }

        if (nextProps.additionalInfoFailed !== this.props.additionalInfoFailed) {
            this.setState({ additionalInfoFailed: nextProps.additionalInfoFailed })
        }
    }

    onSelectProfile(value) {
        this.props.additionalInfoChanged({prop: 'clientProfile', value})
    }

    onSelectTruck(value) {
        this.props.additionalInfoChanged({prop: 'truck', value})
    }

    onSelectIndustry(value) {
        this.props.additionalInfoChanged({prop: 'industry', value})
    }

    onSelectSegment(value) {
        this.props.additionalInfoChanged({prop: 'segment', value})
    }

    onSelectClientCharacter(value) {
        this.props.additionalInfoChanged({prop: 'character', value})
    }

    onSelectClientAction(value) {
        this.props.additionalInfoChanged({prop: 'clientAction', value})
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    showModal1 = () => {
        this.setState({
            visible1: true,
        });
    };

    handleOk1 = () => {
        this.setState({
            visible1: false,
        });

    };

    handleCancel1 = () => {
        this.setState({
            visible1: false,
        });
    };

    showModal2 = () => {
        this.setState({
            visible2: true,
        });
    };

    handleOk2 = () => {
        this.setState({
            visible2: false,
        });

    };

    handleCancel2 = () => {
        this.setState({
            visible2: false,
        });
    };

    showModal3 = () => {
        this.setState({
            visible3: true,
        });
    };

    handleOk3 = () => {
        this.setState({
            visible3: false,
        });

    };

    handleCancel3 = () => {
        this.setState({
            visible3: false,
        });
    };

    renderButton(loanTermsIDs) {
        const {additionalInfoLoading}  = this.state;

        if (additionalInfoLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button onClick={()=>this.onSaveInfo(loanTermsIDs)}>Save</Button>
                </div>
            );
        }
    }

    renderMessage() {
        const {additionalInfoSaved} = this.state;
        
        if (additionalInfoSaved) {
            return <p>{message.success("Additional information are saved")}</p>
        } else {
            return null;
        }
    }
    renderFailedMessage() {
        const {additionalInfoFailed} = this.state;

        if (additionalInfoFailed) {
            return <p>{message.error("Sorry! Additional information are not saved")}</p>
        } else {
            return null;
        }
    }

    onSaveInfo() {

        let {salesExe, clientProfile, truck, industry, model, currentLoan, currentLoanTerms, segment, character, clientAction } = this.state;

        if (!(_.isEmpty(currentLoan))) {
            ///extract customerID and loanID from currentLoan
            const { customerID, loanID } = currentLoan;

           if (!(_.isEmpty(currentLoanTerms))) {

               if (salesExe && clientProfile && truck && industry && model) {

                   if (segment){
                       //check client character
                       if (character && clientAction){
                           this.props.saveAdditionalInfoToLoan({customerID, loanID, salesExe, clientProfile, truck, industry, model, currentLoanTerms, segment, character, clientAction});
                       } else {
                           this.props.saveAdditionalInfoToLoan({customerID, loanID, salesExe, clientProfile, truck, industry, model, currentLoanTerms, segment, character: "", clientAction: ""});
                       }
                   } else {
                       //check client character
                       if (character && clientAction){
                           this.props.saveAdditionalInfoToLoan({customerID, loanID, salesExe, clientProfile, truck, industry, model, currentLoanTerms, segment: "", character, clientAction});
                       } else {
                           this.props.saveAdditionalInfoToLoan({customerID, loanID, salesExe, clientProfile, truck, industry, model, currentLoanTerms, segment: "", character: "", clientAction: ""});
                       }
                   }
               } else {
                   message.warning("Kindly fill in the additional information");
               }

           } else {
               message.warning("Sorry! A loan must have terms to update this info");
           }

        } else {
            message.warning("There is no selected loan to update");
        }
    }

    onManageIndustryPath(){
        const { history } = this.props;
        history.push("/manage-industry");
    }

    onManageSegmentPath(){
        const { history } = this.props;
        history.push("/manage-segment");
    }

    onManageCharacterPath(){
        const { history } = this.props;
        history.push("/manage-characters");
    }

    onManageActionPath(){
        const { history } = this.props;
        history.push("/manage-actions");
    }

    render() {

        return (
            <div>
                <Button type="default" onClick={this.showModal}>
                    Additional info
                </Button>
                <Modal
                    title="Additional information"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    <div>
                        <div className={"modalBox"}>
                            <div>
                                <Input
                                    label={"Sales Officer"}
                                    onChange={e=> this.props.additionalInfoChanged({prop: 'salesExe', value: e.target.value})}
                                    value={this.state.salesExe}
                                />
                            </div>
                            <div>
                                <SelectProfile
                                    label={"Profile"}
                                    onChange={this.onSelectProfile}
                                    value={this.state.clientProfile}
                                />
                            </div>
                            <div>
                                <SelectTruck
                                    label={"Truck"}
                                    onChange={this.onSelectTruck}
                                    value={this.state.truck}
                                />
                            </div>
                            <div>
                                <SelectIndustry
                                    label={"Industry"}
                                    onChange={this.onSelectIndustry}
                                    value={this.state.industry}
                                />
                                
                                 <div className={"manageIndustry"}>
                                    <p onClick={() => this.showModal1() }><span>Manage Industry <FontAwesomeIcon icon="caret-right" size='m'/> </span></p>
                                    <Modal
                                        title="Manage Industry"
                                        visible={this.state.visible1}
                                        onOk={this.handleOk1}
                                        onCancel={this.handleCancel1}
                                        footer={null}
                                        width={900}
                                    >
                                        <Industry/>
                                    </Modal>
                                </div>
                               
                            </div>
                            <div>
                                <SelectSegment
                                    label={"Segment"}
                                    onChange={this.onSelectSegment}
                                    value={this.state.segment}
                                />
                                <div className={"manageSegment"}>
                                    <p onClick={() => this.showModal2() }><span>Manage Segment <FontAwesomeIcon icon="caret-right" size='m'/> </span></p>
                                    <Modal
                                        title="Manage Segement"
                                        visible={this.state.visible2}
                                        onOk={this.handleOk2}
                                        onCancel={this.handleCancel2}
                                        footer={null}
                                        width={900}
                                    >
                                        <Segment/>
                                    </Modal>
                                </div>
                            </div>
                            <div>
                                <Input
                                    label={"Car Model"}
                                    onChange={e=> this.props.additionalInfoChanged({prop: 'model', value: e.target.value})}
                                    value={this.state.model}
                                />
                            </div>
                            <div>
                                <SelectCharacter
                                    label={"Character"}
                                    onChange={this.onSelectClientCharacter}
                                    value={this.state.character}
                                />
                                <div className={"manageSegment"}>
                                    <p onClick={() => this.showModal3() }><span>Manage Characters<FontAwesomeIcon icon="caret-right" size='m'/> </span></p>
                                    <Modal
                                        title="Manage Characters"
                                        visible={this.state.visible3}
                                        onOk={this.handleOk3}
                                        onCancel={this.handleCancel3}
                                        footer={null}
                                        width={900}
                                    >
                                        <Character/>
                                    </Modal>
                                </div>
                            </div>
                            <div>
                                {
                                    this.state.character?
                                        <div>
                                            {
                                                this.state.character === "WA"?
                                                    <SelectWA
                                                        label={"Action"}
                                                        onChange={this.onSelectClientAction}
                                                        value={this.state.clientAction}
                                                    />: null
                                            }
                                            {
                                                this.state.character === "WBNA"?
                                                    <SelectWBNA
                                                        label={"Action"}
                                                        onChange={this.onSelectClientAction}
                                                        value={this.state.clientAction}
                                                    />: null
                                            }
                                            {
                                                this.state.character === "UU"?
                                                    <SelectUU
                                                        label={"Action"}
                                                        onChange={this.onSelectClientAction}
                                                        value={this.state.clientAction}
                                                    />: null
                                            }
                                            {
                                                this.state.character === "UBA"?
                                                    <SelectUBA
                                                        label={"Action"}
                                                        onChange={this.onSelectClientAction}
                                                        value={this.state.clientAction}
                                                    />: null
                                            }
                                            <div className={"manageSegment"}>
                                                <p onClick={() => this.onManageActionPath() }><span>Manage Actions<FontAwesomeIcon icon="caret-right" size='m'/> </span></p>
                                            </div>
                                        </div>: null
                                }
                            </div>

                            <div >
                                {this.renderButton()}
                            </div>
                        </div>
                        
                    </div>
                </Modal>
                <div>
                    {this.renderMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { currentLoan, currentLoanTerms, salesExe, clientProfile, truck, industry, model, segment, clientAction, character, additionalInfoLoading, additionalInfoSaved, additionalInfoFailed, } = state.loans;

    return {
        currentLoan,
        currentLoanTerms,

        salesExe,
        clientProfile,
        truck,
        industry,
        model,
        segment,
        clientAction,
        character,

        additionalInfoLoading,
        additionalInfoSaved,
        additionalInfoFailed,
    }
};

export default withRouter(connect(mapStateToProps, {additionalInfoChanged, saveAdditionalInfoToLoan})(AdditionalInfo));


