import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Modal, message } from 'antd';

//action imports
import { collectorChanged, externalCollectorChanged, assignCollector, hideAssignCollector, collectorDetailsChanged, writeLoanCollector } from "../../../actions/MasterListActions";

//component imports
import SelectCollector from "../../common/SelectCollector";
import SelectExternalCollector from "../../common/SelectExternalCollector";
import {Button} from "../../common/Button";
import {Input} from "../../common/Input";
import moment from "moment";

class  AssignCollector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            collector: this.props.collector,
            externalCollector: this.props.externalCollector,

            terms: this.props.terms,
        };

        this.onCollectorSelect = this.onCollectorSelect.bind(this);
        this.onAssignCollector = this.onAssignCollector.bind(this);
    }

    componentWillMount() {
        //this.setState({ terms: this.props.terms });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.collector !== this.props.collector) {
            this.setState({ collector: nextProps.collector })
        }
        if (nextProps.externalCollector !== this.props.externalCollector) {
            this.setState({ externalCollector: nextProps.externalCollector })
        }

        if (nextProps.visible !== this.props.visible) {
            this.setState({ visible: nextProps.visible })
        }

        if (nextProps.terms !== this.props.terms) {
            this.setState({ terms: nextProps.terms })
        }
    }

    onCollectorSelect(value) {
        //
        this.props.collectorChanged(value);
    }


    onExternalCollectorSelect(value) {
        //
        this.props.externalCollectorChanged(value);
    }


    renderExternalCollector() {
        //
        const {externalCollector, collector} = this.state;

        let collectorValue;

        if (!(_.isEmpty(externalCollector))) {
            //collector is not empty
            collectorValue = externalCollector;
        } else {
            //collector is empty
            collectorValue = "";
        }

        if ((_.isEmpty(collector))) {
            return (
                <div>
                    <p>External Collector</p>
                    <SelectExternalCollector
                        value={collectorValue}
                        onChange={(value) => this.onExternalCollectorSelect(value) }
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    renderInternalCollector() {
        //
        const {externalCollector, collector} = this.state;

        let collectorValue;

        if (!(_.isEmpty(collector))) {
            //collector is not empty
            collectorValue = collector;
        } else {
            //collector is empty
            collectorValue = "";
        }

        if ((_.isEmpty(externalCollector))) {
            return (
                <div>
                    <p>Internal Collector</p>
                    <SelectCollector
                        value={collectorValue}
                        onChange={(value) => this.onCollectorSelect(value) }
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    onAssignCollector() {
        //extract state
        const { collector, externalCollector } = this.state;
        const terms = this.props.loanLoanTerms;

        if (externalCollector) {

            // const ID = moment().unix();
            // const collectorID = ID.toString();
            //grab customer ID from first loan term in terms array
            //check that there is at least one cheque in the terms array
            if (terms.length !== 0) {
                //grab the first cheque and take the customer ID
                const customerID = terms[0].customerID;
                //send to firebase with action
                this.props.writeLoanCollector({ customerID, terms, assignee: JSON.parse(externalCollector) });
            }
            this.handleCancel();

        } else {
            //check collector is not empty
            if (!(_.isEmpty(collector))) {
                //collector is not empty
                //assign
                //grab customer ID from first loan term in terms array
                //check that there is at least one cheque in the terms array
                if (terms.length !== 0) {
                    //grab the first cheque and take the customer ID
                    const customerID = terms[0].customerID;
                    //send to firebase with action
                    this.props.writeLoanCollector({ customerID, terms, assignee: JSON.parse(collector) });
                }
                this.handleCancel();
            } else {
                message.warning("Kindly Select/Enter a collector");
            }
        }

    }

    // handleOk = () => {
    //     this.props.hideAssignCollector()
    // };
    //
    handleCancel = () => {
        this.setState({
            visibleCollector: false,
        });
    };

    render() {

        return (
            <div className={"masterlistAssignCollector"}>
                <div>
                    {/*{ this.renderAssignee() }*/}
                </div>
                    <div className={"masterlistAssignCollectorModal"}>
                        {this.renderInternalCollector()}
                        {this.renderExternalCollector()}

                        <Button
                            text={'Assign Collector'}
                            onPress={() => this.onAssignCollector()}
                        />
                    </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { collector, externalCollector, terms } = state.masterList;

    return {
        collector,
        externalCollector,
        terms
    }

};

export default connect(mapStateToProps, { collectorChanged, externalCollectorChanged, assignCollector, hideAssignCollector, collectorDetailsChanged, writeLoanCollector })(AssignCollector);