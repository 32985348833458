import React from "react";
import {connect} from 'react-redux';
import _ from "lodash";
import {Button, message, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

import { projectArray } from "../../../../env/PROJECTSCONFIGURATIONS";

//actions import
import {deleteCashCollection} from "../../../../actions/CollectionsActions";

//components import
import CommentCard from "./CommentCard";
import moment from "moment";
import UpdateCashCollectionDetailsCard from "./UpdateCashCollectionDetailsCard";
import {BeatLoader} from "react-spinners";



class LoanCashCollectionCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            collection: this.props.collection,
            currentLoan: this.props.currentLoan,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            deleteCashCollectionLoading: this.props.deleteCashCollectionLoading,
            buttonDisabled: false,

            localCurrency: "TSH",
        };

        this.onDelete = this.onDelete.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
    }

    componentWillMount(){

        this.checkCurrencyFormat();
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.collection !== this.props.collection) {
            this.setState({collection: nextProps.collection})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }
        
        if (nextProps.deleteCashCollectionLoading !== this.props.deleteCashCollectionLoading) {
            this.setState({deleteCashCollectionLoading: nextProps.deleteCashCollectionLoading})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({systemInfo: nextProps.systemInfo})
        }

        if (nextProps.buttonDisabled !== this.props.buttonDisabled) {
            this.setState({buttonDisabled: nextProps.buttonDisabled})
        }
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, collection, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if payment object is not empty
        if (!(_.isEmpty(collection))) {
            const loanCurrency = collection.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    onDelete(collection) {
        const {currentLoan} = this.state;
        const customerID = currentLoan.customerID;
        const cashCollectionID = collection.cashCollectionID;
        const loanID = collection.loanID;
        const paidAmount = collection.paidAmount;

        this.setState({buttonDisabled: true});

        if (customerID && loanID && cashCollectionID && paidAmount){
            this.props.deleteCashCollection({customerID, cashCollectionID, loanID, paidAmount});
        } else {
            message.warning("Sorry! Refresh the app and try again");
        }

        setTimeout(() => {
            //do some work
            this.setState({buttonDisabled: false});
        }, 10000);
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderDeleteButton(){
        const {deleteCashCollectionLoading, collection} = this.state;
        if(deleteCashCollectionLoading){
            return(
                <div>
                    <BeatLoader/>
                </div>
            )
        }else{
            return(
                <div>
                    <Popconfirm
                        title="Are you sure to delete this cash collection?"
                        onConfirm={() => this.onDelete(collection)}
                        disabled={this.state.buttonDisabled}
                        onCancel={this.cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                    </Popconfirm>
                </div>
            )
        }
    }


    render() {

        const {collection} = this.state;
        //grab loan term bank date
        //extract timestamp
        const date = collection.bankDate;
        let bankDateSec;
        date.seconds ? bankDateSec = date.seconds : bankDateSec = date._seconds;
        //convert into a moment object
        const bankDate = moment.unix(bankDateSec).format("DD/MM/YYYY");

        return (
            <div>
                <div className={"cashCollectionTableValues"}>
                    <div className={"textValueBox"}>{this.displayCurrency(collection.paidAmount)}</div>
                    <div className={"textValueBox"}><h4>{collection.bankName}</h4></div>
                    <div className={"textValueBox"}><h4>{bankDate}</h4></div>
                    <div className={"textValueCommentButton"}>
                        <CommentCard
                            comment={collection.comment}
                        />
                    </div>
                    <div className={"textValueCommentButton"}>
                        <UpdateCashCollectionDetailsCard
                            collection={collection}
                        />
                    </div>
                    <div className={"valueDeleteButtonBox"}>
                        {this.renderDeleteButton()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { bankName, bankDate, deleteCashCollectionLoading } = state.collections;
    const {currentLoan} = state.loans;
    const {profile} = state.profile;
    const {systemInfo} = state.app;

    return {
        //
        bankName,
        bankDate,
        deleteCashCollectionLoading,
        currentLoan,
        profile,
        systemInfo,
    }
};



export default connect(mapStateToProps,{deleteCashCollection})(LoanCashCollectionCard);