import React from "react";
import { connect } from 'react-redux';
import _, { isEmpty } from "lodash";
import {Button, Modal} from "antd";
import {BeatLoader} from "react-spinners";



//actions import
import { customerDetailsChanged, updateCustomerDetails } from "../../../../actions/CustomerActions";

//components import
import {Input} from "../../../common/Input";
import {message} from "antd";


class AddCustomerPhoneNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedCustomer: this.props.fetchedCustomer,
            phoneNumber: this.props.phoneNumber,
            addPhoneLoading: this.props.addPhoneLoading,
            customerEmail: this.props.customerEmail,
            customerTin: this.props.customerTin,
            customerAddress: this.props.customerAddress,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer){
            this.setState({fetchedCustomer: nextProps.fetchedCustomer})
        }

        // if (nextProps.fetchedCustomer !== this.props.fetchedCustomer){
        //     this.setState({
        //         phoneNumber: this.nextProps.fetchedCustomer.phoneNumber,
        //         customerAddress: this.nextProps.fetchedCustomer.customerAddress,
        //         customerTin: this.nextProps.fetchedCustomer.customerTin,
        //         customerEmail: this.nextProps.fetchedCustomer.customerEmail,
        //     })
        // }

        if (nextProps.phoneNumber !== this.props.phoneNumber){
            this.setState({phoneNumber: nextProps.phoneNumber})
        }
        if (nextProps.addPhoneLoading !== this.props.addPhoneLoading){
            this.setState({addPhoneLoading: nextProps.addPhoneLoading})
        }
        if (nextProps.customerAddress !== this.props.customerAddress){
            this.setState({customerAddress: nextProps.customerAddress})
        }
        if (nextProps.customerEmail !== this.props.customerEmail){
            this.setState({customerEmail: nextProps.customerEmail})
        }
        if (nextProps.customerTin !== this.props.customerTin){
            this.setState({customerTin: nextProps.customerTin})
        }
    }

    onSave() {
        let { phoneNumber, customerEmail, customerAddress, customerTin, fetchedCustomer } = this.state;
        if(phoneNumber === ''){
            if('phoneNumber' in fetchedCustomer){
                phoneNumber = fetchedCustomer.phoneNumber;
            }
        }
        if(customerEmail === ''){
            if('customerEmail' in fetchedCustomer){
                customerEmail = fetchedCustomer.customerEmail;
            }
        }
        if(customerAddress === ''){
            if('customerAddress' in fetchedCustomer){
                customerAddress = fetchedCustomer.customerAddress;
            }
        }
        if(customerTin === ''){
            if('customerTin' in fetchedCustomer){
                customerTin = fetchedCustomer.customerTin;
            }
        }
        // console.log(phoneNumber, customerEmail, customerAddress, customerTin, fetchedCustomer);
        if(phoneNumber === "" && customerEmail === "" && customerAddress === ""  && customerTin === ""){
            message.warning("Kindly fill the required field");

        } else {
            const customerID = fetchedCustomer.customerID;
            console.log({phoneNumber, customerEmail, customerAddress, customerTin});

            this.props.updateCustomerDetails({ customerID, phoneNumber, customerEmail, customerAddress, customerTin});
        }
    }


    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className={"addLoanButton"}>
                <Button type="default" onClick={this.showModal}>
                    Update Details
                </Button>
                <Modal
                    title="Update customer details"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={600}
                >
                    <div className={"customerAddLoanModal"}>
                        <div>
                            <Input
                                // label={'Loan ID'}
                                placeholder={"Phone number"}
                                type={'number'}
                                onChange={e => this.props.customerDetailsChanged({prop: 'phoneNumber', value: e.target.value})}
                                value={this.state.phoneNumber}
                            />
                        </div>
                        <div>
                            <Input
                                // label={'Loan ID'}
                                placeholder={"E-mail"}
                                type={'email'}
                                onChange={e => this.props.customerDetailsChanged({prop: 'customerEmail', value: e.target.value})}
                                value={this.state.customerEmail}
                            />
                        </div>
                        <div>
                            <Input
                                // label={'Loan ID'}
                                placeholder={"TIN #"}
                                type={'text'}
                                onChange={e => this.props.customerDetailsChanged({prop: 'customerTin', value: e.target.value})}
                                value={this.state.customerTin}
                            />
                        </div>
                        <div>
                            <Input
                                // label={'Loan ID'}
                                placeholder={"Address"}
                                type={'text'}
                                onChange={e => this.props.customerDetailsChanged({prop: 'customerAddress', value: e.target.value})}
                                value={this.state.customerAddress}
                            />
                        </div>
                        {
                            this.state.addPhoneLoading ?
                            <div>
                                <BeatLoader />
                            </div> :
                            <div className={"customerSaveLoanButtonBox"}>
                                <Button text={'Save'} onClick={() =>this.onSave()}>SAVE</Button>
                            </div>
                        }

                    </div>
                </Modal>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { phoneNumber, addPhoneLoading, customerEmail, customerAddress, customerTin, fetchedCustomer } = state.customer;

    //return object with state properties
    return {
        phoneNumber,
        addPhoneLoading,
        customerEmail,
        customerTin,
        customerAddress,
        fetchedCustomer
    }
};

export default connect(mapStateToProps,{ customerDetailsChanged, updateCustomerDetails })(AddCustomerPhoneNumber);