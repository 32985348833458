import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";
import _ from "lodash";




//action import
import { fetchIndustryTitles } from "../../actions/SettingsActions";


class SelectIndustry extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            industryTitle: this.props.industryTitle
        }
    }

    componentDidMount() {
        this.props.fetchIndustryTitles();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.industryTitle !== this.props.industryTitle) {
            this.setState({ industryTitle: nextProps.industryTitle });
        }
    }


    render() {
        const {industryTitle } = this.state;

        const { Option } = Select;
        const industry = _.map(industryTitle, tittle => {
            return tittle;
        });

        return (
            <div>
                <div>
                    <p>
                        {this.props.label}
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                     {
                        industry.map(title => {
                            return (
                                <Option value={title.value} key={title.industryID}>{title.value}</Option>
                            );
                        })
                    }
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { industryTitle } = state.settings;

    return {
        industryTitle,
    }
};

export default connect(mapStateToProps, {fetchIndustryTitles})(SelectIndustry);