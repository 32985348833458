import React from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import {message} from "antd";

//actions import
import {fetchHeldCheques, fetchAllHeldCheques, heldDateChanged} from "../../actions/HeldActions";

//components imports
import ViewHeldCheques from "./ViewHeldCheques";
import {PickDate} from "../common/PickDate";
import {Button} from "../common/Button";


class HeldCheques extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            heldCheques: this.props.heldCheques,
            chequeStatus: this.props.chequeStatus,
            date: this.props.date,
            dateChanged: this.props.dateChanged,

            fetched: false,
            fetchedAll: false,
        };

        this.onHeldDateChange = this.onHeldDateChange.bind(this);
    }

    componentWillMount() {

        if (!(_.isEmpty(this.state.date))) {
            const {date} = this.state.date;
            this.props.fetchHeldCheques(date)
        } else {
            const date = null;
            this.props.fetchHeldCheques(date)
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.heldCheques !== this.props.heldCheques) {
            this.setState({heldCheques: nextProps.heldCheques})
        }
        if(nextProps.date !== this.props.date) {
            this.setState({date: nextProps.date})
        }
       if(nextProps.dateChanged !== this.props.dateChanged) {
            this.setState({dateChanged: nextProps.dateChanged})
        }
    }

    onHeldDateChange(date) {
        //
        this.props.heldDateChanged(date)
    }

    onFetchHeldCheques() {

        const {date, dateChanged} = this.state;

        if (date) {

            //check if date is selected
            if (dateChanged) {
                //show clear button
                this.setState({ fetched: true });
                this.props.fetchHeldCheques(date)
            } else {
                message.warning("Please select another date or clear the selected one");
            }
        } else {
            message.warning("Select a date");
        }
    }

    onFetchAllHeldCheques() {

        //show clear button
        this.setState({ fetchedAll: !this.state.fetchedAll });
        this.props.fetchAllHeldCheques();
    }

    onClear() {
        this.setState({ fetched: !this.state.fetched });
        this.setState({ date: "" });

        const date = null;
        this.props.fetchHeldCheques(date)
    }

    onClearAll() {
        this.setState({ fetchedAll: !this.state.fetchedAll });
        const date = null;
        this.props.fetchHeldCheques(date)
    }

    render() {

        const {heldCheques, fetched, fetchedAll} = this.state;

        return (
            <div className={"heldChequeContainer"}>
                <h4>Held Cheques</h4>
                <div>
                    <div className={"heldDateChequeBox"}>
                        <PickDate
                            label={"Select date to filter held cheques"}
                            onChange={this.onHeldDateChange}
                            value={this.state.date}
                        />
                    </div>
                    <div className={"fetchHeldChequeButtonBox"}>
                        <Button
                            text={'Fetch held cheques'}
                            onPress={() => this.onFetchHeldCheques()}
                        />
                        {
                            fetched ?
                                <Button
                                    text={"Clear"}
                                    onPress={() => this.onClear() }
                                /> : null
                        }
                    </div>
                </div>

                <div className={"fetchHeldChequeButtonBox"}>
                    <Button
                        text={'Fetch all Held Cheques'}
                        onPress={() => this.onFetchAllHeldCheques()}
                    />
                    {
                        fetchedAll ?
                        <Button
                            text={"Clear"}
                            onPress={() => this.onClearAll() }
                        /> : null
                    }
                </div>

                <ViewHeldCheques
                    heldCheques={heldCheques}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const {heldCheques, date, dateChanged} = state.heldCheques;

    return {
        heldCheques,
        date,
        dateChanged,
    }
};

export default connect(mapStateToProps, {fetchHeldCheques, heldDateChanged, fetchAllHeldCheques})(HeldCheques);