import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from "lodash";
import { message, Modal } from "antd";
import arraySort from "array-sort";

//action imports
import { exchangeRateChanged, saveExchangeRate, viewCurrencyInUSD, showDefaultCurrency, settingsFieldUpdated, deleteExchangeRate } from "../../../actions/SettingsActions";

//component imports
import { Button } from '../../common/Button';
import {Input} from '../../common/Input';
import {BeatLoader} from "react-spinners";
import {PickDate} from "../../common/PickDate";
import {projectArray} from "../../../env/PROJECTSCONFIGURATIONS";


class CurrencyCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            exchangeRate: this.props.exchangeRate,
            viewInUsdLoading: this.props.viewInUsdLoading,
            defaultCurrencyLoading: this.props.defaultCurrencyLoading,
            profile: this.props.profile,
            exchangeRateDate: this.props.exchangeRateDate,
            exchangeRateOnDate: this.props.exchangeRateOnDate,

            saveExchangeRateLoading: this.props.saveExchangeRateLoading,
            saveExchangeRateSuccess: this.props.saveExchangeRateSuccess,
            saveExchangeRateFailed: this.props.saveExchangeRateFailed,

            exchangeRates: this.props.exchangeRates,
            exchangeRateLoading: this.props.exchangeRateLoading,
            localCurrency: "TSH",

            visible: false,
        };

        this.onViewInUSD = this.onViewInUSD.bind(this);
        this.renderViewInUSD = this.renderViewInUSD.bind(this);
        this.renderDefaultCurrency = this.renderDefaultCurrency.bind(this);
        this.onShowDefaultCurrency = this.onShowDefaultCurrency.bind(this);
        this.renderSaveExchangeRate = this.renderSaveExchangeRate.bind(this);
        this.onSaveExchangeRateInUSD = this.onSaveExchangeRateInUSD.bind(this);
        this.onDeleteExchangeRate = this.onDeleteExchangeRate.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillMount() {
        this.checkCurrencyFormat();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.exchangeRate !== this.props.exchangeRate) {
            this.setState({ exchangeRate: nextProps.exchangeRate })
        }

        if (nextProps.viewInUsdLoading !== this.props.viewInUsdLoading) {
            this.setState({ viewInUsdLoading: nextProps.viewInUsdLoading })
        }

        if (nextProps.defaultCurrencyLoading !== this.props.defaultCurrencyLoading) {
            this.setState({ defaultCurrencyLoading: nextProps.defaultCurrencyLoading })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.exchangeRateDate !== this.props.exchangeRateDate) {
            this.setState({ exchangeRateDate: nextProps.exchangeRateDate })
        }

        if (nextProps.saveExchangeRateLoading !== this.props.saveExchangeRateLoading) {
            this.setState({ saveExchangeRateLoading: nextProps.saveExchangeRateLoading })
        }

        if (nextProps.saveExchangeRateSuccess !== this.props.saveExchangeRateSuccess) {
            this.setState({ saveExchangeRateSuccess: nextProps.saveExchangeRateSuccess })
        }

        if (nextProps.saveExchangeRateFailed !== this.props.saveExchangeRateFailed) {
            this.setState({ saveExchangeRateFailed: nextProps.saveExchangeRateFailed })
        }

        if (nextProps.exchangeRateOnDate !== this.props.exchangeRateOnDate) {
            this.setState({ exchangeRateOnDate: nextProps.exchangeRateOnDate })
        }

        if (nextProps.exchangeRates !== this.props.exchangeRates) {
            this.setState({ exchangeRates: nextProps.exchangeRates })
        }

        if (nextProps.exchangeRateLoading !== this.props.exchangeRateLoading) {
            this.setState({ exchangeRateLoading: nextProps.exchangeRateLoading })
        }
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { localCurrency } = this.state;
        //check if currency is activated in profile

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: localCurrency,
        });

        return <p className={"rateText"}>{formatter.format(value)}</p>;
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onDateChosen({ prop, value }) {
        this.props.settingsFieldUpdated({ prop, value });
    }

    onShowDefaultCurrency() {
        //extract state
        const { profile } = this.state;

        //extract userID
        const { userID } = profile;

        if (profile.viewInUSD) {
            this.props.showDefaultCurrency({ adminID: userID })
        } else {
            message.warning("App is currently displaying the default currency");
        }
    }

    onViewInUSD() {
        //extract state
        const { profile, exchangeRateOnDate } = this.state;

        //extract userID
        const { userID } = profile;

        //check if exchangeRate is not empty
        if (userID) {
            //send to database
            this.props.viewCurrencyInUSD({ adminID: userID, exchangeRateOnDate });
        } else {
            message.warning("Kindly reload the page or sign in");
        }
    }

    onSaveExchangeRateInUSD() {
        //extract state
        const { exchangeRate, profile, exchangeRateDate } = this.state;

        const intExchangeRate = parseInt(exchangeRate);

        //extract userID
        const { userID } = profile;

        //check if exchangeRate is not empty
        if (exchangeRate && userID && exchangeRateDate) {
            //send to database
            this.props.saveExchangeRate({ adminID: userID, exchangeRate: intExchangeRate, exchangeRateDate });
        } else {
            message.warning("Kindly enter today's exchange rate");
        }
    }

    renderDefaultCurrency() {
        //extract state
        const { profile, defaultCurrencyLoading } = this.state;

        if (profile.viewInUSD) {
            //show button to show default currency
            if(this.props.initial) {
                return <div> </div>
            } else {
                return(
                    <div>
                        {
                            defaultCurrencyLoading?
                                <BeatLoader/>:
                                <Button
                                    text={"Show Default Currency"}
                                    onPress={() => this.onShowDefaultCurrency() }
                                />
                        }
                    </div>
                );
            }
        }
    }

    renderViewInUSD() {

        //extract state
        const { viewInUsdLoading } = this.state;

        if(this.props.initial) {
            return <div> </div>
        } else {
            return(
                <div>
                    {
                        viewInUsdLoading?
                            <BeatLoader/>:
                            <Button
                                text={"View in USD"}
                                onPress={() => this.onViewInUSD() }
                            />
                    }
                </div>
            )
        }
    }

    renderSaveExchangeRate() {
        //extract state
        const { saveExchangeRateLoading } = this.state;

        if(this.props.initial) {
            return <div> </div>
        } else {
            return(
                <div>
                    {
                        saveExchangeRateLoading ?
                            <BeatLoader/>:
                            <Button
                                text={"Save Exchange Rate"}
                                onPress={() => this.onSaveExchangeRateInUSD() }
                            />
                    }
                </div>
            )
        }
    }

    onDeleteExchangeRate({ rate }) {
        this.props.deleteExchangeRate({ rate });
    }

    renderSavedExchangeRates() {
        //extract state
        const { exchangeRates, exchangeRateLoading } = this.state;

        if (exchangeRateLoading) {
            return <BeatLoader/>
        } else {
            if (!(_.isEmpty(exchangeRates))) {
                const exchangeRateArray = _.map(exchangeRates, exchangeRate => exchangeRate);
                const sortedExchangeRates = arraySort(exchangeRateArray, "exchangeRateDate", { reverse: true });
                return (
                    <div>
                        {
                            sortedExchangeRates.map(rate => {
                                let seconds;
                                rate.exchangeRateDate.seconds ? seconds = rate.exchangeRateDate.seconds : seconds = rate.exchangeRateDate._seconds;
                                const rateDate = moment.unix(seconds);
                                const rateDateString = rateDate.format("DD / MM / YYYY");
                                return(
                                    <div className={"rateDateExchangeRateCard"}>
                                        <p style={{ fontFamily: "myriadBold"}}>{rateDateString}</p>
                                        { this.displayCurrency(rate.exchangeRate)}
                                        <p onClick={() => this.onDeleteExchangeRate({rate})} className={"deleteRateBtn"}>Delete</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                );
            } else {
                return <p>No saved exchange rates</p>
            }
        }

    }

    renderSuccessMessage() {
        const {saveExchangeRateSuccess} = this.state;
        //
        if (saveExchangeRateSuccess) {
            return (
                <p>{message.success("Exchange rate is saved")}</p>
            );
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {saveExchangeRateFailed} = this.state;
        //
        if (saveExchangeRateFailed) {
            return (
                <p>{message.error("Sorry! Exchange rate is not saved")}</p>
            );
        } else {
            return null;
        }
    }

    render() {
        //extract state
        const { exchangeRate, viewInUsdLoading } = this.state;

        return (
            <div className={"currencyCardBox"}>
                <div className={"changeCurrencyBox"}>
                    <h4>Currency Settings</h4>

                    <div className={'changeCurrencyInfoBox'}>
                        <div>
                            <h5>Save Exchange Rate</h5>
                            <Input
                                placeholder={"Enter exchange rates"}
                                type={'number'}
                                onChange={(e) => this.props.exchangeRateChanged(e.target.value)}
                                value={exchangeRate}
                            />

                            <PickDate
                                label={"Exchange Rate Date"}
                                value={this.state.exchangeRateDate}
                                onChange={(value) => this.onDateChosen({prop: "exchangeRateDate", value})}
                            />

                            { this.renderSaveExchangeRate() }
                        </div>

                        <div>
                            <h5>View in Currency</h5>
                            <div>
                                { this.renderDefaultCurrency() }
                            </div>
                            <div>
                                <PickDate
                                    label={"Pick Exchange Rate from Date"}
                                    value={this.state.exchangeRateOnDate}
                                    onChange={(value) => this.onDateChosen({prop: "exchangeRateOnDate", value})}
                                />
                            </div>
                            { this.renderViewInUSD() }
                        </div>
                        {/* VIEW CURRENCY MODAL */}
                        <div>
                            <div>
                                <Button
                                    text={"View Saved Rates"}
                                    onPress={() => this.showModal() }
                                />
                            </div>
                            <div>
                                <Modal
                                    title="Saved Rates"
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                    width={600}
                                >
                                    <div>
                                        {this.renderSavedExchangeRates()}
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                    
                    {this.renderSuccessMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { exchangeRate, viewInUsdLoading, defaultCurrencyLoading, exchangeRateDate, exchangeRateOnDate, saveExchangeRateLoading,
        saveExchangeRateSuccess, saveExchangeRateFailed, exchangeRates, exchangeRateLoading } = state.settings;
    const { profile } = state.profile;

    return {
        exchangeRate,
        viewInUsdLoading,
        defaultCurrencyLoading,
        profile,
        exchangeRateDate,
        exchangeRateOnDate,

        saveExchangeRateLoading,
        saveExchangeRateSuccess,
        saveExchangeRateFailed,

        exchangeRates,
        exchangeRateLoading
    }

};

export default connect(mapStateToProps, { exchangeRateChanged, saveExchangeRate, viewCurrencyInUSD, showDefaultCurrency,
    settingsFieldUpdated, deleteExchangeRate })(CurrencyCard);
