import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';

const retrievedProject = localStorage.getItem('afclInitializedProject');

//action import


class SelectReportCategory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            profile: this.props.profile
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }
    }


    render() {
        const { profile } = this.state;

        const { Option } = Select;

        let project = {};

        if (retrievedProject) {
            project = JSON.parse(retrievedProject);
        }

        return (
            <div>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 500 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    <Option value="cashCollections">Payment Received Report</Option>
                    <Option value="unIdentifiedCollections">Unidentified Report</Option>
                    <Option value="serviceCharges">Service Charges Report</Option>
                    <Option value="heldCheques">Held Cheques Report</Option>
                    <Option value="earlyClosure">Early Closure Report</Option>
                    <Option value="maturedLoans">Matured Loans Report</Option>
                    <Option value="rescheduledLoans">Rescheduled Loans Report</Option>
                    <Option value="lastPaymentDate">Last Payment Date Report</Option>
                    <Option value="downPayments">Down Payments Report</Option>
                    <Option value="analytics">Analytics Report</Option>

                    {/* <Option value="ageingCustomerWise">Ageing Customer-wise Report</Option> */}
                    <Option value="ageingCustomerWiseNew">Ageing Customer-wise Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="ageingCustomerWiseNew">Ageing Customer-wise Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="ageingGroupBucket">Ageing Group-bucket Report</Option> */}
                    <Option value="ageingGroupBucketNew">Ageing Group-bucket Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="ageingGroupBucketNew">Ageing Group-bucket Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="dashboardReport">Dashboard Report</Option> */}
                    <Option value="dashboardReportNew">Dashboard Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="dashboardReportNew">Dashboard Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="customerOverdueReport">Bulk SMS Report</Option> */}
                    <Option value="customerOverdueReportNew">Bulk SMS Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="customerOverdueReportNew">Bulk SMS Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="characterAndStatusAnalysis">Status Analysis Report</Option> */}
                    <Option value="characterAndStatusAnalysisNew">Status Analysis Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="characterAndStatusAnalysisNew">Status Analysis Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="rollupAnalysis">New Case Report</Option> */}
                    <Option value="rollupAnalysisNew">New Case Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="rollupAnalysisNew">New Case Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="rollupAnalysisFromLastMonth">Roll Over From Last Month Report</Option> */}
                    <Option value="rollupAnalysisFromLastMonthNew">Roll Over From Last Month Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="rollupAnalysisFromLastMonthNew">Roll Over From Last Month Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="bucketGrouped">Analysis Report</Option> */}
                    <Option value="bucketGroupedNew">Analysis Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="bucketGroupedNew">Analysis Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="allReports">AFCL Payment Missed Report</Option> */}
                    <Option value="allReportsNew">AFCL Payment Missed Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="allReportsNew">AFCL Payment Missed Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="provision">Provision Report</Option> */}
                    <Option value="provisionNew">Provision Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="provisionNew">Provision Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="industry">DB Segment Report</Option> */}
                    <Option value="industryNew">DB Segment Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="industryNew">DB Segment Report(NEW)</Option> :
                        null
                    } */}

                    {/* <Option value="collectionTracker">Collection Tracker Report</Option> */}
                    <Option value="collectionTrackerNew">Collection Tracker Report</Option>
                    {/* {
                        (project.privateID === "0001") ?
                        // (profile.username === "admin@firm23.co.tz") ?
                        <Option value="collectionTrackerNew">Collection Tracker Report(NEW)</Option> :
                        null
                    } */}

                    <Option value="monthlyTerms">Monthly Emi's</Option>

                    {/* <Option value="example">Example</Option> */}


                </Select>

            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    const { profile } = state.profile;

    //return object with state properties
    return {
        profile,
    }
};

export default connect(mapStateToProps,{})(SelectReportCategory);