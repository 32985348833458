import React from "react";
import {connect} from "react-redux";
import { Modal, Button } from 'antd';

//components import
import AddNewCustomer from "./AddNewCustomer";

//file imports
import addCustomerArtwork from "../../../assets/images/addCustomerArtwork.png";

class NewCustomer extends React.Component {


    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className={"newCustomerContainer"}>
                <div className={"newCustomerArtworkBox"}>
                    <img src={addCustomerArtwork} alt="add new customer artwork" />
                </div>
                <div className={"newCustomerButtonBox"}>
                    <Button type="default" onClick={this.showModal}>
                        Add New Customer
                    </Button>
                    <h4>Input new customer name and ID</h4>
                    <Modal
                        title="Add New customer"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                    >
                        <div>
                            <AddNewCustomer/>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

export default connect()(NewCustomer);