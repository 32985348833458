import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';


//action import


class selectExternalCollector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            externalCollectors: this.props.externalCollectors,
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.externalCollectors !== this.props.externalCollectors) {
            this.setState ({externalCollectors: nextProps.externalCollectors})
        }

    }


    render() {

        const {externalCollectors} = this.state;

        const collectors = externalCollectors.filter( admin => admin.role === "external collector");

        const { Option } = Select;


        return (
            <div>
                <div className={"selectRoleLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 550 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value= ""> </Option>

                    {
                        collectors.map(collector => {

                            return (

                                <Option value={JSON.stringify({collectorID: collector.collectorID, name: collector.name })} key={collector.collectorID}>{collector.name}</Option>
                            );
                        })
                    }
                </Select>

            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { externalCollectors } = state.app;

    //return object with state properties
    return {
        externalCollectors,
    };
};

export default connect(mapStateToProps)(selectExternalCollector);
