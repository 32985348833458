import React from 'react';
import { connect } from 'react-redux';
import {Switch, message, Button} from 'antd';
import _ from "lodash";
import { withRouter } from "react-router-dom";


//actions import
import {
    showPenalInterest,
    showExtraFee,
    showTotalPaidPreviously,
    showEarlyLiquidation,
    clearUnclearLoan,
    showLegalRepoStatus,
    showRescheduleStatus, reverseLiquidation } from "../../../../actions/LoanActions";
import { loanStatementReport } from "../../../../actions/ReportsActions";

//components imports
import PenalInterestCard from "./PenalInterestCard";
import ExtraFeeCard from "./ExtraFeeCard";
import TotalPaidPreviouslyCard from "./PreviousOverdue";
import EarlyLiquidationCard from "./EarlyLiquidationCard";
import RescheduleStatus from "./RescheduleStatus";
import LoanStatusCard from '../../customerProfile/Cards/LoanStatusCard';
import AdditionalInfo from "./AdditionalInfo";



class CustomerLoanSummaryCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
            systemInfo: this.props.systemInfo,
            profile: this.props.profile,
            showPenalInterestCard: this.props.showPenalInterestCard,
            showExtraFeeCard: this.props.showExtraFeeCard,
            showTotalPaidPreviouslyCard: this.props.showTotalPaidPreviouslyCard,
            showEarlyLiquidationCard: this.props.showEarlyLiquidationCard,
            showLegalRepoStatusCard: this.props.showLegalRepoStatusCard,
            showRescheduleStatusCard: this.props.showRescheduleStatusCard,

            extraFeeSaved: this.props.extraFeeSaved,
            penalInterestSaved: this.props.penalInterestSaved,
            previousOverdueSaved: this.props.previousOverdueSaved,
            earlyLiquidationSaved: this.props.earlyLiquidationSaved,

            loanStatus: false,
            disabled: true,
        };

        this.onManualOverride = this.onManualOverride.bind(this);
    }

    componentWillMount() {
        if (!(_.isEmpty(this.props.currentLoan))) {
            this.setState({ loanStatus: this.props.currentLoan.loanStatus });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({
                currentLoan: nextProps.currentLoan,
                loanStatus: nextProps.currentLoan.loanStatus
            });
        }

        if (nextProps.showPenalInterestCard !== this.props.showPenalInterestCard) {
            this.setState({
                showPenalInterestCard: nextProps.showPenalInterestCard ,
                penalInterestSaved: false
            });
        }
        if (nextProps.showExtraFeeCard !== this.props.showExtraFeeCard) {
            this.setState({
                showExtraFeeCard: nextProps.showExtraFeeCard,
                extraFeeSaved: false
            });
        }
        if (nextProps.showTotalPaidPreviouslyCard !== this.props.showTotalPaidPreviouslyCard) {
            this.setState({
                showTotalPaidPreviouslyCard: nextProps.showTotalPaidPreviouslyCard,
                previousOverdueSaved: false
            });
        }
        if (nextProps.showEarlyLiquidationCard !== this.props.showEarlyLiquidationCard) {
            this.setState({
                showEarlyLiquidationCard: nextProps.showEarlyLiquidationCard,
                earlyLiquidationSaved: false
            });
        }
        if (nextProps.showLegalRepoStatusCard !== this.props.showLegalRepoStatusCard) {
            this.setState({ showLegalRepoStatusCard: nextProps.showLegalRepoStatusCard });
        }

        if (nextProps.showRescheduleStatusCard !== this.props.showRescheduleStatusCard) {
            this.setState({ showRescheduleStatusCard: nextProps.showRescheduleStatusCard });
        }

        if (nextProps.disabled !== this.props.disabled) {
            this.setState({ disabled: nextProps.disabled });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    onLoanStatusSwitch() {
        ///extract state
        const { currentLoan, loanStatus } = this.state;

        if (!(_.isEmpty(currentLoan))){

            const { customerID, loanID } = currentLoan;
            this.setState({ loanStatus: !loanStatus });

            this.props.clearUnclearLoan({ customerID, loanID, loanStatus: !loanStatus });
        } else {
            message.warning("There is no loan to update");
        }
    }

    onManualOverride() {
        const history = this.props.history;
        history.push("/manual-override");
    }

    render() {
        const { currentLoan, profile, systemInfo, showPenalInterestCard, showExtraFeeCard, showTotalPaidPreviouslyCard, showEarlyLiquidationCard, showLegalRepoStatusCard, showRescheduleStatusCard } = this.state;
        const { currentLoanTerms } = this.props;

        return (
            <div>
                <div className={"loanPageCardBox"}>
                    <div className={"loanCardContents"}>
                        <div className={"leftCardBox"}>
                            <div><h4>Loan ID</h4></div>
                            <div><h4>Loan Terms</h4></div>
                        </div>

                        <div className={"rightCardBox"}>
                            <div><h4 id={"loanTermsValue"}>{currentLoan.loanID}</h4></div>
                            <div><h4 id={"loanTermsValue"}>{currentLoan.loanTerms}</h4></div>
                        </div>
                    </div>

                    <div className={"statusSwitchBox"}>
                        <div className={"loanStatusBox"}>
                            <AdditionalInfo/>
                        </div>
                        <div className={"loanStatusBox"}>
                            <Button text={"Loan Status"} onClick={() => this.props.showLegalRepoStatus()}>
                                Status
                            </Button>
                        </div>

                        <div className={"loanStatusBox"}>
                            <Button text={"Reschedule Loan Status"} onClick={() => this.props.showRescheduleStatus()}>
                                Reschedule Loan Status
                            </Button>
                        </div>

                        <div>
                           <LoanStatusCard
                               visible={showLegalRepoStatusCard}
                           />
                        </div>

                        <div>
                            <RescheduleStatus
                                visible={showRescheduleStatusCard}
                            />
                        </div>

                        <div>
                            <Switch 
                                checkedChildren="Cleared"
                                unCheckedChildren="Pending"
                                onChange={() => this.onLoanStatusSwitch()}
                                checked={this.state.loanStatus}
                                disabled={this.state.disabled}
                            />
                        </div>
                    </div>
                    
                </div>
                
                
                <div className={"cardBoxCalculate"}>
                    <div>
                        <Button type="default" onClick={() => this.props.showPenalInterest() }>
                            Penal Interest
                        </Button>
                        <PenalInterestCard
                            visible={showPenalInterestCard}
                            currentLoan={this.state.currentLoan}
                        />
                    </div>

                    <div>
                        <Button type="default" onClick={() => this.props.showExtraFee() }>
                            Extra Fee
                        </Button>
                        <ExtraFeeCard
                            visible={showExtraFeeCard}
                            currentLoan={this.state.currentLoan}
                        />
                    </div>

                    <div>
                        <Button type="default" onClick={() => this.onManualOverride() }>
                            Manual Override
                        </Button>
                    </div>

                    <div>
                        <Button type="default" onClick={() => this.props.showEarlyLiquidation()}>
                            Early Liquidation
                        </Button>
                        <EarlyLiquidationCard
                            visible={showEarlyLiquidationCard}
                            currentLoan={this.state.currentLoan}
                        />
                    </div>
                </div>
                <div className={"cardBoxCalculate"}>
                    <div>
                        <Button type="default" onClick={() => this.props.loanStatementReport({currentLoan, currentLoanTerms, systemInfo, profile}) }>
                            Print Report
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { currentLoan, showPenalInterestCard, showExtraFeeCard, showTotalPaidPreviouslyCard, showEarlyLiquidationCard, showLegalRepoStatusCard, showRescheduleStatusCard} = state.loans;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return{
        currentLoan,
        showPenalInterestCard,
        showExtraFeeCard,
        showTotalPaidPreviouslyCard,
        showEarlyLiquidationCard,
        showLegalRepoStatusCard,
        showRescheduleStatusCard,
        systemInfo,
        profile,
    };
};

export default withRouter(connect(mapStateToProps,{showPenalInterest, loanStatementReport, showExtraFee, showTotalPaidPreviously, showEarlyLiquidation, clearUnclearLoan, showLegalRepoStatus,
    showRescheduleStatus, reverseLiquidation })(CustomerLoanSummaryCard));