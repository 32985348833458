import * as firebase from 'firebase';
import moment from 'moment';
import _ from "lodash";
import arraySort from "array-sort";
import {message} from "antd";
//Types import
import {
    CHEQUE_CASH_SWITCH_CHANGED,
    CHEQUE_STATUS_CHANGED,
    CLEAR_REPORTS_MESSAGE,
    CREATE_MASTER_LIST_REPORT_EMPTY,
    CREATE_MASTER_LIST_REPORT_SUCCESSFUL,
    CREATE_REPORT_DATE_CHANGED,
    CREATE_REPORT_EMPTY,
    CREATE_REPORT,
    CREATE_REPORT_FAILED,
    CREATE_REPORT_SUCCESSFUL,
    DATE_RANGE_CHANGED,
    FETCH_REPORT_TOTAL_VALUE,
    FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL,
    REPORT_CATEGORY_CHANGED,
    RESET_TOTAL_VALUE,
    ALL_REPORT,
    ALL_REPORT_SUCCESSFUL,
    ALL_REPORT_FAILED,
} from "./Types";
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';


//property declaration
const XLSX = require('xlsx');

const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const switchChanged = (status) => {
    return {
        type: CHEQUE_CASH_SWITCH_CHANGED,
        payload: status,
    };
};

export const chequeStatusChanged = (value) => {

    return{
        type: CHEQUE_STATUS_CHANGED,
        payload: value
    }
};

export const reportCategoryChanged = (value) => {

    return{
        type: REPORT_CATEGORY_CHANGED,
        payload: value
    }
};

export const reportDateChanged = ({prop, value}) => {

    return{
        type: CREATE_REPORT_DATE_CHANGED,
        payload: {prop, value}
    }
};


export const dateRangeChanged = (date) => {

    return{
        type: DATE_RANGE_CHANGED,
        payload: date
    }
};

export const resetTotalValue = () => {

    return{
        type: RESET_TOTAL_VALUE
    }
};


export const showChequesTotalValue = ({ chequeStatus, dateRange }) => {

    //extract dates
    const start = dateRange[0];
    const end = dateRange[1];

    const startDate = new Date(start);
    const endDate = new Date(end);

    const data = JSON.stringify({
        chequeStatus,
        startDate,
        endDate
    });

    return(dispatch) => {
        dispatch({ type: FETCH_REPORT_TOTAL_VALUE });
        //invoke custom database function
        const url = `${project.serverUrl}fetchChequesTotalValue`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your cheque value");
                console.log(response);

                dispatch({
                    type: FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            dispatch({ type: CREATE_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: CLEAR_REPORTS_MESSAGE });
        })
    }
};

export const showCashTotalValue = (dateRange) => {

    //extract dates
    const start = dateRange[0];
    const end = dateRange[1];

    const startDate = new Date(start);
    const endDate = new Date(end);

    const data = JSON.stringify({
        startDate,
        endDate
    });

    return(dispatch) => {
        dispatch({ type: FETCH_REPORT_TOTAL_VALUE });
        //invoke custom database function
        const url = `${project.serverUrl}fetchCashTotalValue`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your cash value");
                console.log(response);

                dispatch({
                    type: FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            dispatch({ type: CREATE_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: CLEAR_REPORTS_MESSAGE });
        })
    }
};

export const createReport = (value) => {

    return(dispatch) => {
        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}createReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({ value }),
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {


                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //create banks store
                    let banks = {};

                    //change objects in array to fit excel headers
                    const dataArray = response.map(row => {

                        //change timestamp into date
                        let seconds;
                        row.bankDate.seconds ? seconds = row.bankDate.seconds : seconds = row.bankDate._seconds;

                        const rowDate = moment.unix(seconds);
                        const bankDate = rowDate.toDate();

                        //create row
                        const rowData = {
                            NAME: row.customerName,
                            PAID: row.paidAmount,
                            DATE: bankDate,
                            COMMENT: row.comment.comments
                        };

                        //find if bank name already exists
                        if (`${row.bankName}` in banks) {
                            //bank is there
                            //grab its values array and push in the row
                            let valuesArray = banks[`${row.bankName}`].values;
                            valuesArray.push(rowData);
                            banks[`${row.bankName}`].values = valuesArray;
                        } else {
                            //new bank
                            //create its property in banks object and assign bank name and values array
                            banks[`${row.bankName}`] = {
                                bank: row.bankName,
                                values: [rowData]
                            }
                        }

                        return banks;
                    });

                    //put banks in an array
                    const data = _.map(dataArray[0], bank => bank);

                    //create new sheet for every bank
                    data.forEach(bank => {

                        //change data from objects to sheets
                        const ws = XLSX.utils.json_to_sheet(bank.values, { header:["NAME","PAID","DATE","COMMENT"] });

                        //set the column widths
                        ws['!cols'] = [
                            {wch:55},
                            {wch:25},
                            {wch:25},
                            {wch:45},
                        ];

                        //append the sheet into the workbook
                        XLSX.utils.book_append_sheet(wb, ws, `${bank.bank}`);
                    });

                    //change date to string
                    XLSX.writeFile(wb, `Payment_Received_Report_${today}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                    
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }

};

export const createAnalyticsReport = ({systemInfo}) => {

    return (dispatch) => {
        dispatch({ type: ALL_REPORT });
        const url = `${project.serverUrl}fetchAnalytics`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((data) => {
                console.log("Here's your analytics object");
                console.log(data);
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;
                const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

                if (!(_.isEmpty(data))) {
                    console.log("hitting row");
                    //{ date, totalOverdue }
                    let row = {};

                    //set the date
                    row["date"]  = new Date();

                    _.map(data, (value, key) => {

                        switch (key) {
                            case "totalOverdue":
                                const totalOverdue = value/exchangeRate;
                                row["totalOverdue"] = roundAccurately(totalOverdue, 2);
                                break;
                            case "pdcFtm":
                                const pdcFtm = value/exchangeRate;
                                row["pdcFtm"] = roundAccurately(pdcFtm, 2);
                                break;
                            case "pdcTd":
                                const pdcTd = value/exchangeRate;
                                row["pdcTd"] = roundAccurately(pdcTd, 2);
                                break;
                            case "bouncedTd":
                                const bouncedTd = value/exchangeRate;
                                row["bouncedTd"] = roundAccurately(bouncedTd, 2);
                                break;
                            case "odCollTd":
                                const odCollTd = value/exchangeRate;
                                row["odCollTd"] = roundAccurately(odCollTd, 2);
                                break;
                            case "tc":
                                const tc = value/exchangeRate;
                                row["tc"] = roundAccurately(tc, 2);
                                break;
                            case "totalBook":
                                const totalBook = value/exchangeRate;
                                row["totalBook"] = roundAccurately(totalBook, 2);
                                break;
                            case "nplPercent":
                                row["nplPercent"] = value;
                                break;
                            case "deliquencyRatio":
                                row["deliquencyRatio"] = value;
                                break;
                            case "collectionEfficiency":
                                row["collectionEfficiency"] = value;
                                break;
                            case "overdueCollectionEfficiency":
                                row["overdueCollectionEfficiency"] = value;
                                break;
                            case "dueCollectionPercent":
                                row["dueCollectionPercent"] = value;
                                break;
                            case "tcPercent":
                                row["tcPercent"] = value;
                                break;
                        }
                    });

                    const report = [row];
                    exportAnalysis({report, dispatch});

                } else {
                    //theres no report for this date
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }
            }).catch((error) => {
            dispatch({ type: ALL_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);

        })
    }
};

async function exportAnalysis({report, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let date = moment().format("DD/MM/YYYY");
    let anal = `ANALYTICS REPORT AS OF ${date}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "TOTAL OVERDUE",
            B: "PDC FTM",
            C: "PDC TD",
            D: "BOUNCED TD",
            E: "OD COLL TD",
            F: "TC",
            G: "TOTAL BOOK",
            H: "NPL PERCENT",
            I: "DELIQUENCY RATIO %",
            J: "COLLECTION EFFICIENCY %",
            K: "OD COLLECTION EFFICIENCY %",
            L: "DUE COLLECTIONS PERCENT",
            M: "TOTAL COLLECTIONS PERCENT",
            N: "DATE"
        }
    ];

    if(report.length !== 0){
        report.forEach(data => {
            table1.push({
                A: data.totalOverdue,
                B: data.pdcFtm,
                C: data.pdcTd,
                D: data.bouncedTd,
                E: data.odCollTd,
                F: data.tc,
                G: data.totalBook,
                H: data.nplPercent,
                I: data.deliquencyRatio,
                J: data.collectionEfficiency,
                K: data.overdueCollectionEfficiency,
                L: data.dueCollectionPercent,
                M: data.tcPercent,
                N: data.date
            })
        })
    }

    const finalData1 = [...title, ...table1];

    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    // set the column widths
    ws1['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'Analytics');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'TOTAL OVERDUE' ? headerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:N2',
        tbodyRange: `A2:N${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:N${headerIndex[0] + 1}` : null,

    }

    addStylesAnalytics(workbookBlob, dataInfo, dispatch);
}

const addStylesAnalytics = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'analytics.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const createUnIdentifiedPaymentsReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}unidentifiedReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(payment => {

                        let seconds;
                        payment.unidentifiedBankDate.seconds ? seconds = payment.unidentifiedBankDate.seconds : seconds = payment.unidentifiedBankDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const bankDate = rowDate.toDate();

                        return {
                            CURRENCY: payment.currency,
                            AMOUNT: payment.unidentifiedPaidAmount,
                            BANK: payment.unidentifiedBankName,
                            DATE: bankDate,
                            COMMENT: payment.unidentifiedComments.comments
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CURRENCY","AMOUNT","BANK","DATE","COMMENT"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:10},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Unidentified_Payments_Report_${today}.xlsx`);

                    console.log(response);
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const createServiceChargesReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}serviceChargesReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {


                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(charge => {

                        let seconds;
                        charge.serviceChargeDate.seconds ? seconds = charge.serviceChargeDate.seconds : seconds = charge.serviceChargeDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const serviceChargeDate = rowDate.toDate();

                        return {
                            AMOUNT: charge.serviceChargePaidAmount,
                            BANK: charge.serviceChargeBankName,
                            DATE: serviceChargeDate,
                            COMMENT: charge.serviceChargeComments
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["AMOUNT","BANK","DATE","COMMENT"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Service_Charges_Report_${today}.xlsx`);

                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                    
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {

            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const mhgh = () => {
    return async () => {
        const ref = firebase.firestore().collection('loanTerms');
        const snapshot = await ref.get();
        if(snapshot.size !== 0){
            let cashArray = [];
            snapshot.forEach((doc) => {
                const cheque = doc.data();
                let seconds;
                cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;

                if ("transactionDate" in cheque) {
                    if (cheque.transactionDate) {
                        cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds :  seconds = cheque.transactionDate._seconds;
                    } else {
                        cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds :  seconds = cheque.dueDate._seconds;
                    }
                } else {
                    cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
                }


                const dueDate = moment.unix(seconds);
                const lastMonth = moment().subtract(1, 'month');
                if (dueDate.month() === lastMonth.month() && dueDate.year() === lastMonth.year()) {
                    if (cheque.cheque) {
                        if (cheque.chequeStatus === "bounced") {
                            cashArray.push(cheque);
                        }
                    } else {
                        //check that dueDate month is similar to dueDate month in date object
                        if ("termStatus" in cheque) {
                            if (!cheque.termStatus.status) {
                                if (cheque.termStatus.penalInterest > 0) {
                                    cashArray.push(cheque);
                                }
                            }
                        }
                    }
                }
            })

            if(cashArray.length !== 0){
                createReportForCash(cashArray);
            }else{
                console.log('no cash collected in last month');
            }
        }
    }
}

function createReportForCash(cashArray){
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const bulk = `BOUNCED CHEQUES FOR APRIL`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "DUE DATE",
            C: "CHEQUE NO.",
            D: "CURRENCY",
            E: "AMOUNT",
        }
    ];

    cashArray.forEach(data => {
        let seconds;
        data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
        const date = moment.unix(seconds);
        const dueDate = date.format('DD/MM/YYYY');

        let transactionDate = '';
        if('transactionDate' in data){
            let seconds1;
            data.transactionDate.seconds ? seconds1 = data.transactionDate.seconds : seconds1 = data.transactionDate._seconds;
            const date1 = moment.unix(seconds1);
            transactionDate = date1.format('DD/MM/YYYY');
        }
        if(data.currency === "usd"){
            table1.push({
                A: transactionDate,
                B: dueDate,
                C: data.chequeNumber,
                D: data.currency,
                E: data.paidAmount,
            })
        }
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'April bounced cheques ');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:E${headerIndex[0] + 1}` : null,
    }

    addStylesCashColl(workbookBlob, dataInfo);

}

const addStylesCashColl = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'callicri',
                fontSize: 8,
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'callicri',
                fontSize: 8,
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'bouncedCheq.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
    })
}

export const createHeldChequesReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}heldChequeTermsReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {


                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(cheque => {

                        let seconds;
                        cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const bankDate = rowDate.toDate();

                        let newSeconds;
                        cheque.newDueDate.seconds ? newSeconds = cheque.newDueDate.seconds : newSeconds = cheque.newDueDate._seconds;
                        const newDate = moment.unix(newSeconds);
                        const newDueDate = newDate.toDate();

                        return {
                            NAME: cheque.customerName,
                            AMOUNT: cheque.amount,
                            CHEQUE_NAME: cheque.chequeName,
                            CHEQUE_NUMBER: cheque.chequeNumber,
                            BANK: cheque.bankName,
                            DATE: bankDate,
                            NEW_DATE: newDueDate,
                            STATUS: cheque.chequeStatus
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["NAME","AMOUNT","CHEQUE_NAME","CHEQUE_NUMBER","BANK","DATE","NEW_DATE", "STATUS"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:55},
                        {wch:25},
                        {wch:25},
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Held_Cheques_Report_${today}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL});
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const createEarlyClosureReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}earlyLiquidationReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {


                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(loan => {

                        return {
                            CUSTOMER_NAME: loan.customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: loan.totalPaid,
                            CURRENCY: loan.currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //change date to string
                    XLSX.writeFile(wb, `Early_Closure_Report_${today}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
     
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const createMatureLoansReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}maturedLoanReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log(response);

                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");
                    //structure the data
                    const report = response.map(loan => {
                        const terms = loan.terms;
                        let totalArray = [];
                        let currency = "";
                        let customerName = "";
                        terms.map(term => {
                            currency = term.currency;
                            customerName = term.customerName;
                            totalArray.push(term.amount)
                        })


                        //calculate the total amount from numbers in the array
                        const total = totalArray.reduce((a, b) => a + b, 0);

                        return {
                            CUSTOMER_NAME: customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: total,
                            CURRENCY: currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:35},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Matured_Loans_Report_${today}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {

            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const createRescheduledLoansReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}rescheduledLoanReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {


                if (response.length !== 0) {
                    const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(loan => {

                        return {
                            CUSTOMER_NAME: loan.customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: loan.totalPaid,
                            CURRENCY: loan.currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Rescheduled_Loans_Report_${today}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                    
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED }); 
                }

            }).catch((error) => {
            
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

export const createMasterListReport = ({ bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, profile }) => {

        //{ {customerID: '1000', values: [{}, {}, {}]}, {clientID: '1000', values: [{}, {}, {}]} }
        ///TO
        /*

        {
            1000: {
                clientID: '1000',
                loans: [
                    {
                        loanID: 100001,
                        terms: [],
                    },
                ]
            }


            1001: {
                clientID: '1001',
                loans: [
                    {
                        loanID: 100001,
                        terms: [{}, {}, {}],
                    },
                    {
                        loanID: 100002,
                        terms: [{}, {}, {}],
                    }
                ]
            }
        }

         */

        return (dispatch) => {
            dispatch({type: ALL_REPORT});
            let termsStore = [];

            //filter loan by the bucket chosen
            //create filtered terms bucket
            let filteredTerms = [];
    
            _.map(bouncedCheques, client => {
    
                //loop over all terms and allocate to respective loan
                client.values.map(term => {
                    termsStore.push(term);
                });
    
                //filter by legal/repo status
                //create legal repo filtered loans bucket (array)
                let legalRepoFilteredLoans = [];
    
                if (filteredLegalRepoStatus){
                    legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === filteredLegalRepoStatus);
                } else {
                    legalRepoFilteredLoans = termsStore.map(term => term);
                }
    
                switch (filterBucket) {
                    case "1":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 1);
                        break;
                    case "2":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 2);
                        break;
                    case "3":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 3);
                        break;
                    case "4":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 4);
                        break;
                    case "5":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 5);
                        break;
                    case "6":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 6);
                        break;
                    case "7":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 7);
                        break;
                    case "8":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 8);
                        break;
                    case "9":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 9);
                        break;
                    case "10":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 10);
                        break;
                    case "11":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 11);
                        break;
                    case "12":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 12);
                        break;
                    case "13":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 13);
                        break;
                    default:
                        filteredTerms = legalRepoFilteredLoans.map(term => term);
                        break;
                }
            });
    
            //clients and their loans are computed
            //clients
    
            //check that clients object is not empty
            if (filteredTerms.length !== 0) {
                //create excel report
                const today = moment().format("DD_MM_YYYY");
    
                // /* create a new blank workbook */
                const wb = XLSX.utils.book_new();
    
                //create report bucket to store all the excel rows as objects
                let report = [];
    
                filteredTerms.map(term => {
                    let termRow = {};
    
                    //find comment
                    let comment = "";
                    if("masterListComment" in term){
                        comment = term.masterListComment.newComment;
                    }
    
                    //
                    const formatter = new Intl.NumberFormat('en-US' );
    
                    //check if there are other bounced reasons
                    let otherReason;
                    if ("otherReason" in term) {
                        //
                        otherReason = term.otherReason;
                    } else {
                        otherReason = "";
                    }
    
                    //calculate transaction date
                    let seconds;
                    if ("transactionDate" in term) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                    }
    
                    const dueDate = moment.unix(seconds);
                    //calculate OG Due Date
                    let ogDueDateSeconds;
                    term.dueDate.seconds ? ogDueDateSeconds = term.dueDate.seconds : ogDueDateSeconds = term.dueDate._seconds;
                    const ogDueDate = moment.unix(ogDueDateSeconds);
    
                    //calculate debit amount in tzs and usd
                    let debitAmountTZS;
                    let debitAmountShilling;
                    let debitAmountUSD;
                    let debitAmountDollar;
    
                    if (term.currency === "usd") {
                        //
                        debitAmountDollar = term.amount;
                        //
                        if (debitAmountDollar){
    
                            debitAmountUSD = debitAmountDollar;
                        } else {
                            debitAmountUSD = "";
                        }
    
                    } else {
                        //
                       debitAmountShilling = term.amount;
    
                        //check if debit amount in shillings is not empty then format
                        if (debitAmountShilling){
    
                            debitAmountTZS = debitAmountShilling;
                        } else {
                            debitAmountTZS = "";
                        }
                    }
    
                    //calculate first demand notice
                    let fistDemandNoticeSeconds;
                    let firstDemandNotice;
                    if ("firstDemandNotice" in term) {
                        term.firstDemandNotice.seconds ? fistDemandNoticeSeconds = term.firstDemandNotice.seconds : fistDemandNoticeSeconds = term.firstDemandNotice._seconds;
                        //
                        firstDemandNotice = moment.unix(fistDemandNoticeSeconds).toDate();
                    } else {
                        firstDemandNotice = "";
                    }
    
                    //calculate second demand notice
                    let secondDemandNoticeSeconds;
                    let secondDemandNotice;
                    if ("secondDemandNotice" in term) {
                        term.secondDemandNotice.seconds ? secondDemandNoticeSeconds = term.secondDemandNotice.seconds : secondDemandNoticeSeconds = term.secondDemandNotice._seconds;
                        //
                        secondDemandNotice = moment.unix(secondDemandNoticeSeconds).toDate();
                    } else {
                        secondDemandNotice = "";
                    }
    
                    //assignee
                    let assignee = "";
                    if ("assignee" in term) {
                        assignee = term.assignee.name;
                    }
    
                    //calculate overdue in tzs and usd
                    let overdueTZS;
                    let overdueShilling;
                    let overdueUSD;
                    let overdueDollar;
    
                   if (term.currency === "usd") {
                       //
                       if ("modulo" in term) {
                           //render modulo
                           //extract payment and modulo
                           const modulo = term.modulo;
                           const amount = term.amount;
    
                           overdueDollar = amount - modulo;
                       } else {
                           //render overdue
                           //check if term is cleared or not
                           if (term.termStatus.status) {
                               overdueDollar = 0;
                           } else {
                               overdueDollar = term.amount;
                           }
                       }
                       //
                       if (overdueDollar){
    
                           overdueUSD = overdueDollar;
                       } else {
                           overdueUSD = "";
                       }
                       
                   } else {
                       //
                       if ("modulo" in term) {
                           //render modulo
                           //extract payment and modulo
                           const modulo = term.modulo;
                           const amount = term.amount;
    
                           overdueShilling = amount - modulo;
                       } else {
                           //render overdue
                           //check if term is cleared or not
                           if (term.termStatus.status) {
                               overdueShilling = 0;
                           } else {
                               overdueShilling = term.amount;
                           }
                       }
                       //check if overdue in shillings is not empty then format
                       if (overdueShilling){
    
                           overdueTZS = overdueShilling;
                       } else {
                           overdueTZS = "";
                       }
                   }
    
                    //calculate bucket
                    const now = moment();
                    const fromNow = now.diff(dueDate, 'days');
                    const bucket = Math.ceil(fromNow / 30);
    
                    // //calculate OD SUM
                    let totalOD = 0;
    
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //check if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                totalOD = term.amount - term.modulo;
                            } else {
                                totalOD = term.amount;
                            }
    
                        } else {
                            //fetch system info exchange rate
                            const exchangeRate = systemInfo.exchangeRate;
    
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
    
                            totalOD = amount / exchangeRate;
                        }
                    }
    
                    termRow[`dueDate`] = dueDate.toDate();
                    termRow[`customerName`] = term.customerName;
                    termRow[`assignee`] = assignee;
                    termRow[`salesExe`] = term.salesExe;
                    termRow[`chequeNumber`] = term.chequeNumber;
                    termRow[`debitAmountTZS`] = debitAmountTZS;
                    termRow[`debitAmountUSD`] = debitAmountUSD;
                    termRow[`bouncedReason`] = term.bouncedReason;
                    termRow[`otherReason`] = otherReason;
                    termRow[`overdueTZS`] = overdueTZS;
                    termRow[`overdueUSD`] = overdueUSD;
                    termRow[`character`] = term.character;
                    termRow[`clientAction`] = term.clientAction;
                    termRow[`legalRepoStatus`] = term.legalRepoStatus;
                    termRow[`comment`] = comment;
                    termRow[`fromNow`] = fromNow;
                    termRow[`bucket`] = bucket;
                    termRow[`clientProfile`] = term.clientProfile;
                    termRow[`truck`] = term.truck;
                    termRow[`industry`] = term.industry;
                    termRow[`model`] = term.model;
                    termRow[`segment`] = term.segment;
                    termRow[`totalOD`] = totalOD;
                    termRow[`ogDueDate`] = ogDueDate.toDate();
                    termRow[`firstDemandNotice`] = firstDemandNotice;
                    termRow[`secondDemandNotice`] = secondDemandNotice;
    
                    report.push(termRow);
                });
    
                exportODAging({report, dispatch});
    
            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }
        }
};

async function exportODAging({report, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let date = moment().format("DD/MM/YYYY");
    let anal = `OD AGING REPORT AS OF ${date}`;
    let title = [{A: anal}, {}];
    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "NAME",
            C: "COLLECTOR",
            D: "SALES OFFICER",
            E: "CHEQUE NUMBER",
            F: "DEBIT AMOUNT IN LCY",
            G: "DEBIT AMOUNT IN USD",
            H: "BOUNCED REASON",
            I: "OTHERS SPECIFY",
            J: "OVERDUE IN LCY",
            K: "OVERDUE IN USD",
            L: "CHARACTER",
            M: "ACTION",
            N: "STATUS",
            O: "COMMENT",
            P: "DAYS",
            Q: "AGE",
            R: "PROFILE",
            S: "TRACK",
            T: "INDUSTRY",
            U: "MODEL",
            V: "SEGMENT",
            W: "OD REPORTING USD",
            X: "DUE DATE",
            Y: "FIRST DEMAND NOTICE",
            Z: "SECOND DEMAND NOTICE",
        }
    ];
    if(report.length !== 0){
        report.forEach(data => {
            table1.push(
                {
                    A: data.dueDate,
                    B: data.customerName,
                    C: data.assignee,
                    D: data.salesExe,
                    E: data.chequeNumber,
                    F: data.debitAmountTZS,
                    G: data.debitAmountUSD,
                    H: data.bouncedReason,
                    I: data.otherReason,
                    J: data.overdueTZS,
                    K: data.overdueUSD,
                    L: data.character,
                    M: data.clientAction,
                    N: data.legalRepoStatus,
                    O: data.comment,
                    P: data.fromNow,
                    Q: data.bucket,
                    R: data.clientProfile,
                    S: data.truck,
                    T: data.industry,
                    U: data.model,
                    V: data.segment,
                    W: data.totalOD,
                    X: data.ogDueDate,
                    Y: data.firstDemandNotice,
                    Z: data.secondDemandNotice,
                }
            );
        })
    }

    const finalData1 = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    //set the column widths
    ws1['!cols'] = [
        {wch: 25},
        {wch: 35},
        {wch: 25},
        {wch: 20},
        {wch: 25},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 30},
        {wch: 30},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'OD Aging');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:Z2',
        tbodyRange: `A2:Z${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:Z${headerIndex[0] + 1}` : null,
    }

    addStylesODAging(workbookBlob, dataInfo, dispatch);
}

const addStylesODAging = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'odAging.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


export const createLastPaymentDateReport = () => {
    //lastPaymentDateReport
    return(dispatch) => {
        dispatch({type: ALL_REPORT});
        const url = `${project.serverUrl}lastPaymentDateReport`;
        fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'}
        }).then((response) => response.json())
            .then((response) => {

                //check if our response is not empty
                if(response.length !== 0){

                    const today = moment().format('DD_MM_YYYY');


                    //STRUCTURE THE DATA
                    const report = response.map(loan => {
                        //create row
                        const row = {};

                        if (!(_.isEmpty(loan))) {
                            //find transaction date
                            let seconds;
                            loan.transactionDate.seconds ? seconds = loan.transactionDate.seconds : seconds = loan.transactionDate._seconds;
                            const paymentDate = moment.unix(seconds);
                            const  transactionDate = paymentDate.toDate();

                            let mode;
                            loan.cheque ? mode = "Cheque" : mode = "Cash";


                            row['CUSTOMER NAME'] = loan.customerName;
                            row['LOAN ID'] = loan.loanID;
                            row['AMOUNT'] = loan.paidAmount;
                            row['TRANSACTION DATE'] = transactionDate;
                            row['MODE'] = mode;
                            row['CURRENCY'] = loan.currency;
                        }

                        return row;
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //create excel sheet
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER NAME","LOAN ID","AMOUNT","TRANSACTION DATE", "MODE", "CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');


                    //change date to string
                    XLSX.writeFile(wb, `Last_Payment_Date_Report_${today}.xlsx`);
                    dispatch({type: ALL_REPORT_SUCCESSFUL});

                } else {
                    
                }

            }).catch((error) => {
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED});
        })
    }
};

export const createAgeingCustomerWiseReportNew = ({ bouncedCheques, systemInfo }) => {
    return(dispatch) => {
        let resultArray = [];
        let successArray = [];
        try{
            dispatch({type: ALL_REPORT});
            let clients = {};
            let arr = [];
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            let arr5 = [];
            let arr6 = [];
            let arr7 = [];
            let arr8 = [];
            let arr9 = [];
            let arr10 = [];
            let arr11 = [];
            let arr12 = [];
    
    
            //put all terms in a store
            const exchangeRate = systemInfo.exchangeRate;
            let termsStore = [];
    
            _.map(bouncedCheques, client => {
                client.values.map(term => {
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        termsStore.push(term);
                    }
                });
            });

            let terms = {};
            termsStore.forEach(term => {
    
                if (`${term.customerID}` in clients) {
                    let cterms = clients[`${term.customerID}`].cterms;
                    cterms.push(term);
                    clients[`${term.customerID}`].cterms = cterms;
                }else{
                    clients[`${term.customerID}`] = {
                        cterms: [term],
                        customerID: term.customerID,
                        name: term.customerName
                    }
                }
                
                let seconds;
                if ("transactionDate" in term) {
                    if(term.transactionDate){
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                    }else{
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }


                //grab end of month of next month
                const dueDate = moment.unix(seconds);
                const endMonth = dueDate.endOf('month');

                const today = moment();
                const nextMonth = today.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');
            
                //find the number of days from today
                const fromNow = endOfNextMonth.diff(endMonth, 'days');
            
                const bucket = Math.round(fromNow/30);
            
                let daysRange = "(0)";
                //compute date range depending on bucket
                if(bucket !== 0) {
                    const endDate = bucket * 30;
                    const startDate = endDate - 29;

                    daysRange = `(${startDate}-${endDate})`
                }

                if(bucket !== undefined && bucket !== null){
                    if (`${daysRange}` in terms) {
                        let termBucket = terms[`${daysRange}`].terms;
                        termBucket.push(term);
                        terms[`${daysRange}`].terms = termBucket;
    
                    } else {
                        terms[`${daysRange}`] = {
                            range: daysRange,
                            terms: [term]
                        }
                    }
                }
            })


            const termsArr = _.map(terms, client => client );
            console.log(termsArr)
    
            termsArr.forEach(term => {
                if(term.range === "(1-30)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr.push(amount);
                    })
                }
                
                if(term.range === "(31-60)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr1.push(amount);
                    })
                }
                
                if(term.range === "(61-90)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr2.push(amount);
                    })
                }
                
                if(term.range === "(91-120)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr3.push(amount);
                    })
                }
                
                if(term.range === "(121-150)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr4.push(amount);
                    })
                }
                
                if(term.range === "(151-180)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr5.push(amount);
                    })
                }
                
                if(term.range === "(181-210)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr6.push(amount);
                    })
                }

                if(term.range === "(211-240)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr7.push(amount);
                    })
                }
                
                if(term.range === "(241-270)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr8.push(amount);
                    })
                }

                if(term.range === "(271-300)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr9.push(amount);
                    })
                }
                
                if(term.range === "(301-330)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr10.push(amount);
                    })
                }
                
                if(term.range === "(331-360)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr11.push(amount);
                    })
                } 
                
                if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                    term.terms.forEach(term => {
                        let amount;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                        } else {
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
        
                            amount = amount/exchangeRate;
                        }
        
                        arr12.push(amount);
                    })
                }
            })
    
            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
            let bucket1 = arr.reduce((a, b) => a + b, 0);
            bucket1 = roundAccurately(bucket1, 2);
            let bucket2 = arr1.reduce((a, b) => a + b, 0);
            bucket2 = roundAccurately(bucket2, 2);
            let bucket3 = arr2.reduce((a, b) => a + b, 0);
            bucket3 = roundAccurately(bucket3, 2);
            let bucket4 = arr3.reduce((a, b) => a + b, 0);
            bucket4 = roundAccurately(bucket4, 2);
            let bucket5 = arr4.reduce((a, b) => a + b, 0);
            bucket5 = roundAccurately(bucket5, 2);
            let bucket6 = arr5.reduce((a, b) => a + b, 0);
            bucket6 = roundAccurately(bucket6, 2);
            let bucket7 = arr6.reduce((a, b) => a + b, 0);
            bucket7 = roundAccurately(bucket7, 2);
            let bucket8 = arr7.reduce((a, b) => a + b, 0);
            bucket8 = roundAccurately(bucket8, 2);
            let bucket9 = arr8.reduce((a, b) => a + b, 0);
            bucket9 = roundAccurately(bucket9, 2);
            let bucket10 = arr9.reduce((a, b) => a + b, 0);
            bucket10 = roundAccurately(bucket10, 2);
            let bucket11 = arr10.reduce((a, b) => a + b, 0);
            bucket11 = roundAccurately(bucket11, 2);
            let bucket12 = arr11.reduce((a, b) => a + b, 0);
            bucket12 = roundAccurately(bucket12, 2);
            let bucket13 = arr12.reduce((a, b) => a + b, 0);
            bucket13 = roundAccurately(bucket13, 2);
            const date = moment().format("DD/MM/YYYY");
            const total = bucket1 + bucket2 + bucket3 + bucket4 + bucket5 + bucket6 + bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12 + bucket13;
            resultArray = [{name: date, bucket1, bucket2, bucket3, bucket4, bucket5, bucket6, bucket7, bucket8, bucket9, bucket10, bucket11, bucket12, bucket13, total}];

            if(!(_.isEmpty(clients))){
                _.map(clients, customer => {
                    const res = getCustomerBucket({customer, exchangeRate});
                    successArray.push(res);
                })
            }
        } catch(e){
            console.log(e);
            dispatch({ type: ALL_REPORT_FAILED });
        }

        Promise.all(successArray).then((data) => {
            data = arraySort(data, "name");
            resultArray = resultArray.concat(data);
            exportCustomerWiseNew({resultArray, dispatch});
        })
    }
}

async function exportCustomerWiseNew({resultArray, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment().format("DD/MM/YYYY");
    const bulk = `CUSTOMER WISE REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AGEING",
            B: "1-30",
            C: "31-60",
            D: "61-90",
            E: "91-120",
            F: "121-150",
            G: "151-180",
            H: "181-210",
            I: "211-240",
            J: "241-270",
            K: "271-300",
            L: "301-330",
            M: "331-360",
            N: ">360",
            O: "Grand Total"
        }
    ];

    resultArray.forEach(data => {
        table1.push({
            A: data.name,
            B: data.bucket1,
            C: data.bucket2,
            D: data.bucket3,
            E: data.bucket4,
            F: data.bucket5,
            G: data.bucket6,
            H: data.bucket7,
            I: data.bucket8,
            J: data.bucket9,
            K: data.bucket10,
            L: data.bucket11,
            M: data.bucket12,
            N: data.bucket13,
            O: data.total,
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Customer wise');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AGEING' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:O2',
        tbodyRange: `A2:O${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:O${headerIndex[0] + 1}` : null,
    }

    addStylesCustomerWise(workbookBlob, dataInfo, dispatch);

}

const addStylesCustomerWise = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'customerWise.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

async function getCustomerBucket({customer, exchangeRate}){
    try{

        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = [];
        let arr8 = [];
        let arr9 = [];
        let arr10 = [];
        let arr11 = [];
        let arr12 = [];

        let terms = {};
        customer.cterms.forEach(term => {
            
            let seconds;
            if ("transactionDate" in term) {
                if(term.transactionDate){
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                }else{
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            }


            //grab end of month of next month
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');

            const today = moment();
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
            const bucket = Math.round(fromNow/30);
        
            let daysRange = "(0)";
            //compute date range depending on bucket
            if(bucket !== 0) {
                const endDate = bucket * 30;
                const startDate = endDate - 29;

                daysRange = `(${startDate}-${endDate})`
            }

            if(bucket !== undefined && bucket !== null){
                if (`${daysRange}` in terms) {
                    let termBucket = terms[`${daysRange}`].terms;
                    termBucket.push(term);
                    terms[`${daysRange}`].terms = termBucket;

                } else {
                    terms[`${daysRange}`] = {
                        range: daysRange,
                        terms: [term]
                    }
                }
            }
        })


        const termsArr = _.map(terms, client => client );

        termsArr.forEach(term => {
            if(term.range === "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr.push(amount);
                })
            }
            
            if(term.range === "(31-60)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr1.push(amount);
                })
            }
            
            if(term.range === "(61-90)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr2.push(amount);
                })
            }
            
            if(term.range === "(91-120)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr3.push(amount);
                })
            }
            
            if(term.range === "(121-150)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr4.push(amount);
                })
            }
            
            if(term.range === "(151-180)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr5.push(amount);
                })
            }
            
            if(term.range === "(181-210)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr6.push(amount);
                })
            }

            if(term.range === "(211-240)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr7.push(amount);
                })
            }
            
            if(term.range === "(241-270)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr8.push(amount);
                })
            }

            if(term.range === "(271-300)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr9.push(amount);
                })
            }
            
            if(term.range === "(301-330)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr10.push(amount);
                })
            }
            
            if(term.range === "(331-360)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr11.push(amount);
                })
            } 
            
            if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr12.push(amount);
                })
            }
        })


        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        let bucket1 = arr.reduce((a, b) => a + b, 0);
        bucket1 = roundAccurately(bucket1, 2);
        let bucket2 = arr1.reduce((a, b) => a + b, 0);
        bucket2 = roundAccurately(bucket2, 2);
        let bucket3 = arr2.reduce((a, b) => a + b, 0);
        bucket3 = roundAccurately(bucket3, 2);
        let bucket4 = arr3.reduce((a, b) => a + b, 0);
        bucket4 = roundAccurately(bucket4, 2);
        let bucket5 = arr4.reduce((a, b) => a + b, 0);
        bucket5 = roundAccurately(bucket5, 2);
        let bucket6 = arr5.reduce((a, b) => a + b, 0);
        bucket6 = roundAccurately(bucket6, 2);
        let bucket7 = arr6.reduce((a, b) => a + b, 0);
        bucket7 = roundAccurately(bucket7, 2);
        let bucket8 = arr7.reduce((a, b) => a + b, 0);
        bucket8 = roundAccurately(bucket8, 2);
        let bucket9 = arr8.reduce((a, b) => a + b, 0);
        bucket9 = roundAccurately(bucket9, 2);
        let bucket10 = arr9.reduce((a, b) => a + b, 0);
        bucket10 = roundAccurately(bucket10, 2);
        let bucket11 = arr10.reduce((a, b) => a + b, 0);
        bucket11 = roundAccurately(bucket11, 2);
        let bucket12 = arr11.reduce((a, b) => a + b, 0);
        bucket12 = roundAccurately(bucket12, 2);
        let bucket13 = arr12.reduce((a, b) => a + b, 0);
        bucket13 = roundAccurately(bucket13, 2);
        const total = bucket1 + bucket2 + bucket3 + bucket4 + bucket5 + bucket6 + bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12 + bucket13;
        
        return {name: customer.name, bucket1, bucket2, bucket3, bucket4, bucket5, bucket6, bucket7, bucket8, bucket9, bucket10, bucket11, bucket12, bucket13, total};

    } catch(e){
        console.log(e);
    }
}

export const createAgeingCustomerWiseReportNew1 = ({ bouncedCheques, systemInfo }) => {
    return(dispatch) => {
        dispatch({type: ALL_REPORT});
        //TODO: CREATE STORE BUCKETS
        let clients = {};

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        _.map(bouncedCheques, client => {
            client.values.map(term => {
                //check if term is after deployment date
                // let migrationSeconds;
                // systemInfo.migrationDate.seconds ? migrationSeconds = systemInfo.migrationDate.seconds : migrationSeconds = systemInfo.migrationDate._seconds;
                // const migrationDate = moment.unix(migrationSeconds);
                //
                // let termSeconds;
                // term.dueDate.seconds ? termSeconds = term.dueDate.seconds : termSeconds = term.dueDate._seconds;
                // const termDate = moment.unix(termSeconds);
                //
                // if (termDate.isSameOrAfter(migrationDate)) {
                //     if (term.termStatus.status) {
                //         //term is cleared
                //         //do nothing
                //     } else {
                //         termsStore.push(term);
                //     }
                // }
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    termsStore.push(term);
                }
            });
        });

        //group all terms into unique dates
        //check that termsStore is not empty
        if (termsStore.length !== 0) {
            termsStore.map(term => {
                //grab the date and change it into a string
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
                const dueDate = moment.unix(seconds);

                //check if dueDateID already exists in dates object
                //clients = { customerID: { date: date, terms: { 1: [], 2: [], 13: []}, customerName: "23", customerID: "23" }
                if (`${term.customerID}` in clients) {
                    //terms with this date already exist
                    let terms = clients[`${term.customerID}`].terms;

                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    const today = moment();
                    let fromNow = today.diff(dueDate, 'days');
                    // fromNow = fromNow/30;

                    // //grab end of month of next month
                    // const endOfMonth = dueDate.endOf('month');
                    // const endOfNextMonth = moment().add(1, 'month').endOf('month');
                    // // const endOfNextMonth = nextMonth.endOf('month');
                    // const fromNow = endOfNextMonth.diff(endOfMonth, 'days');


                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    //check if bucket already exists
                    //clients = { customerID: { date: date, terms: { 1: [], 2: [] }, customerID, customerName }}
                    if (`${bucket}` in terms) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = terms[`${bucket}`];

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        terms[`${bucket}`] = termBucket;

                        //assign the new objects as new values of the the existing terms object in the date object
                        clients[`${term.customerID}`].terms = terms;
                    } else {
                        terms[`${bucket}`] = [term];
                        clients[`${term.customerID}`].terms = terms;
                    }
                } else {
                    //its a new date so create new object for it
                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    const today = moment();

                    //find the number of days from today
                    let fromNow = today.diff(dueDate, 'days');
                    // fromNow = fromNow/30;

                    // const endOfMonth = dueDate.endOf('month');
                    // const endOfNextMonth = moment().add(1, 'month').endOf('month');
                    // // const endOfNextMonth = nextMonth.endOf('month');
                    // const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    let terms = {};
                    terms[`${bucket}`] = [term];

                    clients[`${term.customerID}`] = {
                        customerID: term.customerID,
                        name: term.customerName,
                        date: dueDate,
                        terms,
                        termsStore
                    }
                }
            });
        }

        //sort the dates array
        const clientsArray = _.map(clients, client => client);
        const sortedCustomers = arraySort(clientsArray, "customerName");

        //check if our response is not empty
        if(sortedCustomers.length !== 0){

            const today = moment().format('DD/MM/YYYY');

            //STRUCTURE THE DATA
            const report = [];

            //TODO: CREATE THE NORMAL ROWS
                console.log(sortedCustomers)

            sortedCustomers.map(customer => {
                //create row
                let row = {};
                //find transaction date
                _.map(customer.terms, (terms, key) => {
                    switch (key) {
                        case '1':
                            terms.map(term => row = computeHeaderRowAmount({ days: "1-30", totalRow: row, term, systemInfo }));
                            break;
                        case '2':
                            terms.map(term => row = computeHeaderRowAmount({ days: "31-60", totalRow: row, term, systemInfo }));
                            break;
                        case '3':
                            terms.map(term => row = computeHeaderRowAmount({ days: "61-90", totalRow: row, term, systemInfo }));
                            break;
                        case '4':
                            terms.map(term => row = computeHeaderRowAmount({ days: "91-120", totalRow: row, term, systemInfo }));
                            break;
                        case '5':
                            terms.map(term => row = computeHeaderRowAmount({ days: "121-150", totalRow: row, term, systemInfo }));
                            break;
                        case '6':
                            terms.map(term => row = computeHeaderRowAmount({ days: "151-180", totalRow: row, term, systemInfo }));
                            break;
                        case '7':
                            terms.map(term => row = computeHeaderRowAmount({ days: "181-210", totalRow: row, term, systemInfo }));
                            break;
                        case '8':
                            terms.map(term => row = computeHeaderRowAmount({ days: "211-240", totalRow: row, term, systemInfo }));
                            break;
                        case '9':
                            terms.map(term => row = computeHeaderRowAmount({ days: "241-270", totalRow: row, term, systemInfo }));
                            break;
                        case '10':
                            terms.map(term => row = computeHeaderRowAmount({ days: "271-300", totalRow: row, term, systemInfo }));
                            break;
                        case '11':
                            terms.map(term => row = computeHeaderRowAmount({ days: "301-330", totalRow: row, term, systemInfo }));
                            break;
                        case '12':
                            terms.map(term => row = computeHeaderRowAmount({ days: "331-360", totalRow: row, term, systemInfo }));
                            break;
                        default:
                            terms.map(term => row = computeHeaderRowAmount({ days: ">360", totalRow: row, term, systemInfo }));
                            break;
                    }
                });

                //find total of total row
                let rowAmountArray = [];
                _.map(row, amount => {
                    if (amount) {
                        //check that valid amount is not name string
                        if (typeof amount === "number") {
                            rowAmountArray.push(amount);
                        }
                    }
                });

                //calculate the total amount from numbers in the array
                const grandRow = rowAmountArray.reduce((a, b) => a + b, 0);
                const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
                const grandTotal = roundAccurately(grandRow, 2);

                //find oldest bucket in customer terms
                let oldestBucket = 1;
                _.map(customer.terms, (terms, stringKey) => {
                    const key = parseInt(stringKey);
                    if (key >= oldestBucket) {
                        oldestBucket = key;
                    }
                });

                //final row
                let finalRow = {};
                finalRow['Ageing'] = customer.name;
                //return row with sorted days
                finalRow = assignDaysToRow({ oldestBucket, finalRow, grandTotal });

                return report.push(finalRow);
            });

            //TODO: CREATE THE TOTAL ROW
            //let reportTitleRow = {};
            //let todaysDate = moment().format("DD/MM/YYYY");
            //reportTitleRow["Ageing"] = `AGEING - CUSTOMER WISE REPORT - ${todaysDate}`;
            //report.push(reportTitleRow);
            //
            // let sumOfUSDRow = {};
            // sumOfUSDRow["Ageing"] = "Sum of USD";
            // report.push(sumOfUSDRow);

            let totalRow = {};
            totalRow["Ageing"] = new Date();
            report.map(customer => {
                //insert data in row
                //loop over all customer terms
                _.map(customer, (values, key) => {
                    switch (key) {
                        case "1-30":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "31-60":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "61-90":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "91-120":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "121-150":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "151-180":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "181-210":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "211-240":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "241-270":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "271-300":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "301-330":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case "331-360":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`] });
                            break;
                        case ">360":
                            totalRow = computeTotalRow({ days: key, totalRow, amount: customer[`${key}`]});
                            break;
                    }
                });
                // OLD METHOD
                // _.map(customer.terms, (terms, key) => {
                //     switch (key) {
                //         case '1':
                //             //loop over all terms in the bucket
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "31-60", totalRow, term, systemInfo }));
                //             break;
                //         case '2':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "31-60", totalRow, term, systemInfo }));
                //             break;
                //         case '3':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "61-90", totalRow, term, systemInfo }));
                //             break;
                //         case '4':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "91-120", totalRow, term, systemInfo }));
                //             break;
                //         case '5':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "121-150", totalRow, term, systemInfo }));
                //             break;
                //         case '6':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "151-180", totalRow, term, systemInfo }));
                //             break;
                //         case '7':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "181-210", totalRow, term, systemInfo }));
                //             break;
                //         case '8':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "211-240", totalRow, term, systemInfo }));
                //             break;
                //         case '9':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "241-270", totalRow, term, systemInfo }));
                //             break;
                //         case '10':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "271-300", totalRow, term, systemInfo }));
                //             break;
                //         case '11':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "301-330", totalRow, term, systemInfo }));
                //             break;
                //         case '12':
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: "331-360", totalRow, term, systemInfo }));
                //             break;
                //         default:
                //             terms.map(term => totalRow = computeHeaderRowAmount({ days: ">360", totalRow, term, systemInfo }));
                //             break;
                //     }
                // });
            });

            //find total of total row
            let totalRowArray = [];
            _.map(totalRow, amount => {
                if (typeof amount === "number") {
                    console.log({ amount });
                    totalRowArray.push(amount);
                } else {
                    console.log({ nan: amount });
                }
            });

            //calculate the total amount from numbers in the array
            const grandTotalRow = totalRowArray.reduce((a, b) => a + b, 0);
            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
            totalRow["Grand Total"] = roundAccurately(grandTotalRow, 2);

            //put total row as first row in report
            report.unshift(totalRow);

            //TODO: CREATE THE WORKBOOK
            /* create a new blank workbook */
            const wb = XLSX.utils.book_new();

            //create excel sheet
            const ws = XLSX.utils.json_to_sheet(report, { header:["Ageing","1-30","31-60","61-90", "91-120", "121-150", "151-180", "181-210", "211-240", "241-270", "271-300", "301-330", "331-360", ">360", "Grand Total"] });

            //set the column widths
            ws['!cols'] = [
                {wch:30},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
                {wch:15},
            ];


            //append the sheet into the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            //change date to string
            XLSX.writeFile(wb, `Ageing_Customer_Wise_Report_${today}.xlsx`);

            dispatch({type: ALL_REPORT_SUCCESSFUL});
        } else {
            message.info("There is no data to generate report");
            dispatch({ type: ALL_REPORT_FAILED });
        }
    };
};

function computeTotalRow({ days, totalRow, amount }) {
    //check if (1-30)etc. already exists or not in total row
    if (`${days}` in totalRow) {
        //exists
        //grab amount
        let oldAmount = totalRow[`${days}`];
        //add oldAmount with new amount
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        totalRow[days] = roundAccurately((oldAmount + amount), 2);

        return totalRow;
    } else {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        totalRow[days] = roundAccurately(amount, 2);
        return totalRow;
    }
}

function computeHeaderRowAmount({ days, totalRow, term, systemInfo }) {
    //check if (1-30)etc. already exists or not in total row
    if (`${days}` in totalRow) {
        //exists
        //grab amount
        let oldAmount = totalRow[`${days}`];
        //add oldAmount with new amount
        //check if loan is in usd convert the overdue to default currency
        let amount = 0;
        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
        }

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        totalRow[days] = roundAccurately((oldAmount + amount), 2);

        return totalRow;
    } else {
        let amount = 0;

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
        }

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        totalRow[days] = roundAccurately(amount, 2);

        return totalRow;
    }
}

function assignDaysToRow({ oldestBucket, finalRow, grandTotal }) {
    let days;
    let row = finalRow;
    switch (oldestBucket) {
        case 1:
            days = "1-30";
            break;
        case 2:
            days = "31-60";
            break;
        case 3:
            days = "61-90";
            break;
        case 4:
            days = "91-120";
            break;
        case 5:
            days = "121-150";
            break;
        case 6:
            days = "151-180";
            break;
        case 7:
            days = "181-210";
            break;
        case 8:
            days = "211-240";
            break;
        case 9:
            days = "241-270";
            break;
        case 10:
            days = "271-300";
            break;
        case 11:
            days = "301-330";
            break;
        case 12:
            days = "331-360";
            break;
        default:
            days = ">360";
            break;
    }

    row[days] = grandTotal;
    return row;
}

export const createAgeingGroupBucketReportNew = ({bouncedCheques, systemInfo}) => {

    return(dispatch) => {
        dispatch({ type: ALL_REPORT });
        //create object to store all dates
        let dates = {};

        //put all terms in a store
        let termsStore = [];

        // {{ customerID, customerName, values: [{}, {}]}, {}}
        //loop over all client objects and extract its terms
        _.map(bouncedCheques, client => {
            client.values.map(term => {
                //check if term is after deployment date
                // let migrationSeconds;
                // systemInfo.migrationDate.seconds ? migrationSeconds = systemInfo.migrationDate.seconds : migrationSeconds = systemInfo.migrationDate._seconds;
                // const migrationDate = moment.unix(migrationSeconds);
                //
                // let termSeconds;
                // term.dueDate.seconds ? termSeconds = term.dueDate.seconds : termSeconds = term.dueDate._seconds;
                // const termDate = moment.unix(termSeconds);
                //
                // if (termDate.isSameOrAfter(migrationDate)) {
                //     if (term.termStatus.status) {
                //         //term is cleared
                //         //do nothing
                //     } else {
                //         termsStore.push(term);
                //     }
                // }
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    termsStore.push(term);
                }
            });
        });

        //group all terms into unique dates
        //check that termsStore is not empty
        if (termsStore.length !== 0) {
            termsStore.map(term => {
                //grab the date and change it into a string
                let seconds;
                // if ("transactionDate" in term) {
                //     term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                // } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                // }
                const dueDate = moment.unix(seconds);
                const today = moment();
                //grab the month and year
                const dueDateID = today.format("MMYYYY");

                //check if dueDateID already exists in dates object

                //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
                if (`${dueDateID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${dueDateID}`].terms;

                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    //check if bucket already exists
                    //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                    if (`${bucket}` in terms) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = terms[`${bucket}`];

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        terms[`${bucket}`] = termBucket;

                        //assign the new objects as new values of the the existing terms object in the date object
                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        terms[`${bucket}`] = [term];
                        dates[`${dueDateID}`].terms = terms;
                    }


                    //dates[`${dueDateID}`].terms = terms;
                } else {
                    //its a new date so create new object for it
                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    let terms = {};
                    terms[`${bucket}`] = [term];

                    dates[`${dueDateID}`] = {
                        date: dueDate,
                        terms,
                        termsStore
                    }
                }
            });
        }


        //sort the dates array
        const datesArray = _.map(dates, date => date);
        const sortedDates = arraySort(datesArray, "date");

        //check if our response is not empty
        if(sortedDates.length !== 0){

            const today = moment().format('DD_MM_YYYY');

            //STRUCTURE THE DATA
            const report = [];

            let reportTitleRow = {};
            let todaysDate = moment().format("DD/MM/YYYY");
            // reportTitleRow["Ageing"] = `AGEING - BUCKET GROUPED REPORT - ${todaysDate}`;
            // report.push(reportTitleRow);

            // let sumOfUSDRow = {};
            // sumOfUSDRow["Ageing"] = "Sum of USD";
            // report.push(sumOfUSDRow);

            //TODO: CREATE THE NORMAL ROWS
            sortedDates.map(date => {

                //create row
                let row = {};
                //find transaction date
                const dateMoment = moment().endOf('month');
                row['Ageing'] = dateMoment.format("DD/MM/YYYY");
                _.map(date.terms, (terms, key) => {
                    switch (key) {
                        case '1':
                            terms.map(term => row = computeHeaderRowAmount({ days: "1-30", totalRow: row, term, systemInfo }));
                            break;
                        case '2':
                            terms.map(term => row = computeHeaderRowAmount({ days: "31-60", totalRow: row, term, systemInfo }));
                            break;
                        case '3':
                            terms.map(term => row = computeHeaderRowAmount({ days: "61-90", totalRow: row, term, systemInfo }));
                            break;
                        case '4':
                            terms.map(term => row = computeHeaderRowAmount({ days: "91-120", totalRow: row, term, systemInfo }));
                            break;
                        case '5':
                            terms.map(term => row = computeHeaderRowAmount({ days: "121-150", totalRow: row, term, systemInfo }));
                            break;
                        case '6':
                            terms.map(term => row = computeHeaderRowAmount({ days: "151-180", totalRow: row, term, systemInfo }));
                            break;
                        case '7':
                            terms.map(term => row = computeHeaderRowAmount({ days: "181-210", totalRow: row, term, systemInfo }));
                            break;
                        case '8':
                            terms.map(term => row = computeHeaderRowAmount({ days: "211-240", totalRow: row, term, systemInfo }));
                            break;
                        case '9':
                            terms.map(term => row = computeHeaderRowAmount({ days: "241-270", totalRow: row, term, systemInfo }));
                            break;
                        case '10':
                            terms.map(term => row = computeHeaderRowAmount({ days: "271-300", totalRow: row, term, systemInfo }));
                            break;
                        case '11':
                            terms.map(term => row = computeHeaderRowAmount({ days: "301-330", totalRow: row, term, systemInfo }));
                            break;
                        case '12':
                            terms.map(term => row = computeHeaderRowAmount({ days: "331-360", totalRow: row, term, systemInfo }));
                            break;
                        default:
                            terms.map(term => row = computeHeaderRowAmount({ days: ">360", totalRow: row, term, systemInfo }));
                            break;
                    }
                });

                //find total of total row
                let rowAmountArray = [];
                _.map(row, amount => {
                    if (amount) {
                        //check that valid amount is not name string
                        if (typeof amount === "number") {
                            rowAmountArray.push(amount);
                        }
                    }
                });

                //calculate the total amount from numbers in the array
                const grandRow = rowAmountArray.reduce((a, b) => a + b, 0);
                const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
                row["Grand Total"] = roundAccurately(grandRow, 2);

                return report.push(row);
            });


            //find the buckets total row
            //TODO: CREATE THE TOTAL ROW
            let totalRow = {};
            totalRow["Ageing"] = "Grand Total";
            sortedDates.map(date => {
                //insert data in row
                //loop over all customer terms
                _.map(date.terms, (terms, key) => {
                    switch (key) {
                        case '1':
                            //loop over all terms in the bucket
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "1-30", totalRow, term, systemInfo }));
                            break;
                        case '2':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "31-60", totalRow, term, systemInfo }));
                            break;
                        case '3':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "61-90", totalRow, term, systemInfo }));
                            break;
                        case '4':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "91-120", totalRow, term, systemInfo }));
                            break;
                        case '5':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "121-150", totalRow, term, systemInfo }));
                            break;
                        case '6':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "151-180", totalRow, term, systemInfo }));
                            break;
                        case '7':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "181-210", totalRow, term, systemInfo }));
                            break;
                        case '8':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "211-240", totalRow, term, systemInfo }));
                            break;
                        case '9':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "241-270", totalRow, term, systemInfo }));
                            break;
                        case '10':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "271-300", totalRow, term, systemInfo }));
                            break;
                        case '11':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "301-330", totalRow, term, systemInfo }));
                            break;
                        case '12':
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: "331-360", totalRow, term, systemInfo }));
                            break;
                        default:
                            terms.map(term => totalRow = computeHeaderRowAmount({ days: ">360", totalRow, term, systemInfo }));
                            break;
                    }
                });
            });

            //find total of total row
            let totalRowArray = [];
            _.map(totalRow, amount => {
                if (typeof amount === "number") {
                    console.log({ amount });
                    totalRowArray.push(amount);
                } else {
                    console.log({ nan: amount });
                }
            });
            //calculate the total amount from numbers in the array
            const grandTotalRow = totalRowArray.reduce((a, b) => a + b, 0);
            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
            totalRow["Grand Total"] = roundAccurately(grandTotalRow, 2);

            //put total row as first row in report
            report.push(totalRow);
            exportBucketGroupedReport({report, dispatch});

        } else {
            message.info("There is no data to generate report");
            dispatch({ type: ALL_REPORT_FAILED });
        }
    };
};

async function exportBucketGroupedReport({report, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment().format("DD/MM/YYYY");
    const bulk = `BUCKET GROUPED REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AGEING",
            B: "1-30",
            C: "31-60",
            D: "61-90",
            E: "91-120",
            F: "121-150",
            G: "151-180",
            H: "181-210",
            I: "211-240",
            J: "241-270",
            K: "271-300",
            L: "301-330",
            M: "331-360",
            N: ">360",
            O: "GRAND TOTAL"
        }
    ];

    report.forEach(data => {
        table1.push({
            A: data.Ageing,
            B: data["1-30"],
            C: data["31-60"],
            D: data["61-90"],
            E: data["91-120"],
            F: data["121-150"],
            G: data["151-180"],
            H: data["181-210"],
            I: data["211-240"],
            J: data["241-270"],
            K: data["271-300"],
            L: data["301-330"],
            M: data["331-360"],
            N: data[">360"],
            O: data["Grand Total"],
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Bucket grouped');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AGEING' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:O2',
        tbodyRange: `A2:O${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:O${headerIndex[0] + 1}` : null,
    }

    addStylesBucketGrouped(workbookBlob, dataInfo, dispatch);

}

const addStylesBucketGrouped = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'customerWise.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const createLoansWithSurplus = () => {

    return() => {

        const url = `${project.serverUrl}loansWithSurplus`;
        fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'}
        }).then((response) => response.json())
            .then((response) => {

                //check if our response is not empty
                if(response.length !== 0){

                    const today = moment().format('DD_MM_YYYY');


                    //STRUCTURE THE DATA
                    const report = _.map(response, loan => {
                        //create row
                        const row = {};

                        if (!(_.isEmpty(loan))) {
                            row['LOAN ID'] = loan.loanID;
                            row['CUSTOMER NAME'] = loan.customerName;
                            row['CUSTOMER ID'] = loan.customerID;
                            row['SURPLUS'] = loan.surplus;
                        }

                        return row;
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["LOAN ID","CUSTOMER NAME", "CUSTOMER ID","SURPLUS"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:15},
                        {wch:15},
                        {wch:15},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Loans_With_Surplus_Report_${today}.xlsx`);
                } else {
                    console.log("No loans with surplus");
                }

            }).catch((error) => {
            console.log(error);
            console.log("No loans with surplus");
        })
    }
};

export const removeSurplus = () => {

    return () => {
        firebase.firestore().collection("loans").where("surplus", ">", 0)
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot.size !== 0){
                    querySnapshot.forEach(doc => {
                        const loan = doc.data();
                        const loanID = loan.loanID;
                        const customerID = loan.customerID;

                        deleteSurplus({loanID, customerID});
                    })
                }
            }, function (error) {
                console.log(error);
            })
    }
};

function deleteSurplus({loanID, customerID}) {
    firebase.firestore().collection("loans").doc(loanID)
        .update({surplus: 0})
        .then(() => {
            //
            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                .update({surplus: 0})
                .then(() => {

                })
                .catch(e => {
                    console.log(e);
                })
        })
        .catch(e => {
            console.log(e);
        })
}

export const computeSurplus = () => {

    return () => {
        firebase.firestore().collection("loans")
            .onSnapshot(function (querySnapshot) {
                if (querySnapshot.size !== 0){
                    querySnapshot.forEach(doc => {
                        const loan = doc.data();
                        const loanID = loan.loanID;
                        const customerID = loan.customerID;
                        const totalPaid = loan.totalPaid;

                        console.log("one");

                        fetchLoanTerms({loanID, customerID, totalPaid, loan});

                    })
                }
            }, function (error) {
                console.log(error);
            })
    }
};

function fetchLoanTerms({loanID, customerID, totalPaid, loan}){

    console.log("two");

    let loanTerms = {};

    firebase.firestore().collection("loanTerms").where("loanID", "==", loanID)
        .onSnapshot(function (querySnapshot) {

            querySnapshot.forEach(doc => {
                // const data = doc.data();
                loanTerms[doc.id] = doc.data();
            })

            console.log("three");

            calculateSupposeToPay({loanID, customerID, totalPaid, loanTerms, loan});
        })
}

function calculateSupposeToPay({loanID, customerID, totalPaid, loanTerms, loan}) {

    console.log("four");
    //supposed to pay is the total amount to be paid by that date
    let total = 0;

    //check that currentLoanTerms is not empty
    if(!(_.isEmpty(loanTerms))) {
        //filter loan terms that are not passed today
        let totalArray = [];

        _.map(loanTerms, term => {
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;

            const dueDate = moment.unix(seconds);
            const today = moment();

            //check if due date is before today
            if (dueDate.isSameOrBefore(today, "day")) {
                //due date is not passed
                //fetch amount supposed to pay and put into the array
                totalArray.push(term.amount);
            }
        });

        //calculate the total amount from numbers in the array
        //check if there are extra fees
        if ("extraFees" in loan) {
            //loop object and take take extra fee amount from each extra fee
            const extraFees = loan.extraFees;

            _.map(extraFees, extraFee => {
                totalArray.push(extraFee.extraFeeAmount);
            });
        }

        total = totalArray.reduce((a, b) => a + b, 0);

        //check if theres early liquidation
        if (loan.earlyLiquidation) {
            //supposed to pay - early liquidation
            total = loan.earlyLiquidation;
        }

        console.log("five");
        calculateSurplus({loanID, customerID, supposeToPay: total, totalPaid});
    }
}

function calculateSurplus({loanID, customerID, supposeToPay, totalPaid}) {

    const surplus = totalPaid - supposeToPay;

    if (surplus > 0){
        console.log({loanID: loanID, surplus: surplus});

        writeSurplus({loanID, customerID, surplus})
    } else {
        console.log({surplus: surplus});
    }
}

function writeSurplus({loanID, customerID, surplus}) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({surplus: surplus})
        .then(() => {
            //
            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                .update({surplus: surplus})
                .then(() => {
                    console.log("done");
                })
                .catch(e => {
                    console.log(e);
                })
        })
        .catch(e => {
            console.log(e);
        })
}

export const createPdcFtmReport = () => {

    return() => {

        firebase.firestore().collection("loanTerms")
            .get()
            .then(querySnapshot => {
                const terms = {};
                const today = moment().format("DD_MM_YYYY");
                querySnapshot.forEach(doc => {
                    //
                    //here is an individual loan term data
                    const data = doc.data();

                    //check they are not cleared and don't belong to any old rescheduled loan loanStatus && rescheduleStatus: oldLoan
                    let status = true;
                    if ("loanStatus" in data) {
                        if (data.loanStatus) {
                            //status = false;
                        } else {
                            //check if its a old loan
                            if ("rescheduleStatus" in data) {
                                if (data.rescheduleStatus === "oldLoan") {
                                    status = false;
                                }
                            }
                        }
                    } else {
                        //check if its a old loan
                        if ("rescheduleStatus" in data) {
                            if (data.rescheduleStatus === "oldLoan") {
                                status = false;
                            }
                        }
                    }

                    if (status) {
                        //check if term is within this month
                        //grab due date
                        let seconds;
                        data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                        const dueDate = moment.unix(seconds);

                        const today = moment();

                        if(dueDate.isSame(today, "month")) {
                            //
                            terms[doc.id] = data;

                        }
                    }
                })

                const report = _.map(terms, data => {

                    let seconds;
                    data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                    const dueDate = moment.unix(seconds);
                    const bankDate = dueDate.toDate();

                    //structure the data
                    return {
                        CUSTOMER_NAME: data.customerName,
                        CUSTOMER_ID: data.customerID,
                        LOAN_ID: data.loanID,
                        DUE_DATE: bankDate,
                        AMOUNT: data.amount,
                        CURRENCY: data.currency,
                    };
                });

                // /* create a new blank workbook */
                const wb = XLSX.utils.book_new();

                //change data from objects to sheets
                const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME", "CUSTOMER_ID", "LOAN_ID","DUE_DATE","AMOUNT","CURRENCY"] });

                //set the column widths
                ws['!cols'] = [
                    {wch:25},
                    {wch:15},
                    {wch:15},
                    {wch:15},
                    {wch:20},
                    {wch:10},
                ];

                //append the sheet into the workbook
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                //change date to string
                XLSX.writeFile(wb, `PDC_FTM_${today}.xlsx`);
            })
            .catch(e => {
                console.log(e);
            })
    }
};

export const createPdcTdReport = () => {

    return() => {

        firebase.firestore().collection("loanTerms")
            .get()
            .then(querySnapshot => {
                const terms = {};
                const today = moment().format("DD_MM_YYYY");
                querySnapshot.forEach(doc => {
                    //
                    //here is an individual loan term data
                    const data = doc.data();

                    //check they are not cleared and don't belong to any old rescheduled loan loanStatus && rescheduleStatus: oldLoan
                    let status = true;
                    if ("loanStatus" in data) {
                        if (data.loanStatus) {
                            //status = false;
                        } else {
                            //check if its a old loan
                            if ("rescheduleStatus" in data) {
                                if (data.rescheduleStatus === "oldLoan") {
                                    status = false;
                                }
                            }
                        }
                    } else {
                        //check if its a old loan
                        if ("rescheduleStatus" in data) {
                            if (data.rescheduleStatus === "oldLoan") {
                                status = false;
                            }
                        }
                    }

                    if (status) {
                        //check if term is within this month
                        //grab due date
                        let seconds;
                        data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                        const dueDate = moment.unix(seconds);

                        const today = moment();

                        if((dueDate.isSame(today, "month") && (dueDate.isSameOrBefore(today, "day")))) {
                            //
                            terms[doc.id] = data;

                        }
                    }
                })

                const report = _.map(terms, data => {

                    let seconds;
                    data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                    const dueDate = moment.unix(seconds);
                    const bankDate = dueDate.toDate();

                    //structure the data
                    return {
                        CUSTOMER_NAME: data.customerName,
                        CUSTOMER_ID: data.customerID,
                        LOAN_ID: data.loanID,
                        DUE_DATE: bankDate,
                        AMOUNT: data.amount,
                        CURRENCY: data.currency,
                    };
                });

                // /* create a new blank workbook */
                const wb = XLSX.utils.book_new();

                //change data from objects to sheets
                const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME", "CUSTOMER_ID", "LOAN_ID","DUE_DATE","AMOUNT","CURRENCY"] });

                //set the column widths
                ws['!cols'] = [
                    {wch:25},
                    {wch:15},
                    {wch:15},
                    {wch:15},
                    {wch:20},
                    {wch:10},
                ];

                //append the sheet into the workbook
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                //change date to string
                XLSX.writeFile(wb, `PDC_TD_${today}.xlsx`);
            })
            .catch(e => {
                console.log(e);
            })
    }
};

export const createLoansWithSurplusAndModulo = () => {

    return() => {

        const url = `${project.serverUrl}loansWithModuloAndSurplus`;
        fetch(url, {
            method: 'GET',
            mode: "cors",
            headers: {'Content-Type': 'application/json'}
        }).then((response) => response.json())
            .then((response) => {

                //check if our response is not empty
                if(response.length !== 0){

                    const today = moment().format('DD_MM_YYYY');


                    //STRUCTURE THE DATA
                    const report = _.map(response, loan => {
                        //create row
                        const row = {};

                        if (!(_.isEmpty(loan))) {
                            row['LOAN ID'] = loan.loanID;
                            row['CUSTOMER NAME'] = loan.customerName;
                            row['CUSTOMER ID'] = loan.customerID;
                            row['SURPLUS'] = loan.surplus;
                            row['MODULO'] = loan.modulo;
                        }

                        return row;
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["LOAN ID","CUSTOMER NAME", "CUSTOMER ID","SURPLUS", "MODULO"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:15},
                        {wch:30},
                        {wch:15},
                        {wch:25},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Loans_With_Surplus_And_Modulo_${today}.xlsx`);
                } else {
                    console.log("No loans with surplus and modulo");
                }

            }).catch((error) => {
            console.log(error);
            console.log("No loans with surplus and modulo");
        })
    }
};

export const createDashboardReportNew = () => {

    return async (dispatch) => {
        //
        dispatch({type: ALL_REPORT});
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        let successArray = [];

        const today = moment();


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const localCurrency = systemInfo.defaultCurrency;
            const url = `${project.serverUrl}fetchAllBeforeTerms`;

            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((allBeforeTerms) => {

                    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
                    fetch(beforeUrl, {
                        method: 'GET',
                        mode: 'cors',
                        headers: {'Content-Type': 'application/json'},
                    }).then((response) => response.json())
                        .then((response) => {

                            let dates = {};
                            response.forEach(term => {
                                let seconds;
                                if ("transactionDate" in term) {
                                    if (term.transactionDate) {
                                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                    } else {
                                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                    }
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                                const dueDate = moment.unix(seconds);

                                //put term in current month bucket
                                //grab the month and year
                                const dueDateID = dueDate.format("MMYYYY");

                                //check if dueDateID already exists in dates object
                                if (`${dueDateID}` in dates) {
                                    //terms with this date already exist
                                    let terms = dates[`${dueDateID}`].terms;
                                    terms.push(term);

                                    dates[`${dueDateID}`].terms = terms;
                                } else {
                                    //its a new date so create new object for it
                                    dates[`${dueDateID}`] = {
                                        date: dueDate,
                                        terms: [term],
                                        allBeforeTerms,
                                        termsStore: response
                                    }
                                }
                            })


                            //FIND GRAND CONTENTS
                            let totalCustomers;
                            let sumInUsd;
                            let sumLCY;
                            let sumOfODInUSD;

                            //sum of debit in local currency
                            let storeTerms = [];
                            if (allBeforeTerms.length !== 0) {
                                allBeforeTerms.map(term => {
                                    if (term.cheque) {
                                        if (term.chequeStatus === "bounced") {
                                            if (term.currency !== "usd") {
                                                storeTerms.push(term.amount);
                                            }
                                        }
                                    } else {
                                        if (term.currency !== "usd") {
                                            storeTerms.push(term.amount);
                                        }
                                    }
                                });

                                sumLCY = storeTerms.reduce((a, b) => a + b, 0);
                                // if(sumLCY !== 0){
                                //
                                //     const formatter = new Intl.NumberFormat('en-US', {
                                //         style: 'currency',
                                //         currency: localCurrency,
                                //     });
                                //
                                //     sumLCY = formatter.format(sumLCY);
                                // }
                            }

                            //sum of debit in usd format
                            let totalInUSD = [];
                            if (allBeforeTerms.length !== 0) {
                                allBeforeTerms.map(term => {
                                    //filter if they are cleared or not
                                    if (term.cheque) {
                                        if (term.chequeStatus === "bounced") {
                                            if (term.currency === "usd") {
                                                totalInUSD.push(term.amount);
                                            }
                                        }
                                    } else {
                                        if (term.currency === "usd") {
                                            totalInUSD.push(term.amount);
                                        }
                                    }
                                });

                                sumInUsd = totalInUSD.reduce((a, b) => a + b, 0);
                                // const  currency = "USD";
                                //
                                // const formatter = new Intl.NumberFormat('en-US', {
                                //     style: 'currency',
                                //     currency,
                                // });
                                //
                                // sumInUsd = formatter.format(sumInUsd);
                            }

                            //sum of OD in usd
                            let usdTermStore = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    //if loan is in usd convert the overdue to default currency
                                    if (term.currency === "usd") {
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        usdTermStore.push(amount);
                                    } else {
                                        //fetch system info exchange rate
                                        const exchangeRate = systemInfo.exchangeRate;

                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        const convertedAmount = amount/exchangeRate;

                                        usdTermStore.push(convertedAmount);
                                    }
                                }
                            });

                            sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                            // if(sumOfODInUSD !== 0){
                            //     const  currency = "USD";
                            //
                            //     const formatter = new Intl.NumberFormat('en-US', {
                            //         style: 'currency',
                            //         currency,
                            //     });
                            //
                            //     sumOfODInUSD = formatter.format(sumOfODInUSD);
                            // }

                            //sum of customers
                            if(response.length !== 0){
                                let customerStore = [];
                                response.map(term => {
                                    customerStore.push(term);
                                });

                                let distinctCustomers = {};

                                customerStore.map(term => {
                                    if(`${term.customerID}` in distinctCustomers) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomers[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomers[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomers[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomers = Object.keys(distinctCustomers).length;
                            }

                            // console.log(totalCustomers, sumInUsd, sumLCY, sumOfODInUSD )

                            //for examining each cheque contents
                            if (!(_.isEmpty(dates))) {
                                ///extract date into array so it can be sorted
                                const datesArray = _.map(dates, date => date);
                                const sortedDates = arraySort(datesArray, "date");

                                sortedDates.map(data => {
                                    const allBeforeTerms = data.allBeforeTerms;
                                    const date = data.date;
                                    const terms = data.terms;
                                    const termsStore = data.termsStore;

                                    const res = getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency });
                                    successArray.push(res);

                                })

                                Promise.all(successArray).then((dashboardData) => {

                                    // here is our array of dashboard data
                                    if(dashboardData.length !== 0){

                                        dashboardData.push({ endOfMonth: "Grand Total", totalLCY: sumLCY, totalDebitInUSD: sumInUsd, totalInUsd: sumOfODInUSD, numberOfCustomers: totalCustomers, daysRange: "", bucket: "", percent: "" });
                                        exportDashboardData({dashboardData, dispatch});
                                        
                                    }else{
                                        message.info("There is no data to generate report");
                                        dispatch({ type: ALL_REPORT_FAILED });
                                    }
                                })
                            }

                        }).catch((error) => {
                        console.log("Here's your error");
                        console.log(error);
                    })

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED})
            })
        }
    }
};

async function exportDashboardData({dashboardData, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const dashboardData2 = [
        {endOfMonth: 'June', totalLCY: 1500000, totalDebitInUSD: 100000, totalInUsd: 500000, numberOfCustomers: 43, daysRange: '61-90', bucket: 6},
        {endOfMonth: 'July', totalLCY: 1800000, totalDebitInUSD: 190000, totalInUsd: 507000, numberOfCustomers: 40, daysRange: '61-90', bucket: 6}
    ]

    const today = moment().format("DD/MM/YYYY");
    const dash = `DASHBOARD AS OF ${today}`;
    let title = [{A: dash},{}];

    const dash1 = `DASHBOARD II AS OF ${today}`;
    let title1 = [{A: dash1},{}];

    let table1 = [
        {
            A: "MONTHS",
            B: "SUM OF DEBIT AMOUNT LCY",
            C: "SUM OF DEBIT AMOUNT USD",
            D: "SUM OF OD REPORTING USD",
            E: "NUMBER OF CUSTOMERS",
            F: "AGEING",
            G: "BUCKET",
            H: "TOTAL OD PER BUCKET"
        }
    ];

    let table2 = [
        {
            A: "MONTHS",
            B: "SUM OF DEBIT AMOUNT LCY",
            C: "SUM OF DEBIT AMOUNT USD",
            D: "SUM OF OD REPORTING USD",
            E: "NUMBER OF CUSTOMERS",
            F: "AGEING",
            G: "BUCKET",
        }
    ];

    dashboardData.forEach(data => {
        table1.push({
            A: data.endOfMonth,
            B: data.totalLCY,
            C: data.totalDebitInUSD,
            D: data.totalInUsd,
            E: data.numberOfCustomers,
            F: data.daysRange,
            G: data.bucket,
            H: data.percent
        })
    })

    dashboardData2.forEach(data => {
        table2.push({
            A: data.endOfMonth,
            B: data.totalLCY,
            C: data.totalDebitInUSD,
            D: data.totalInUsd,
            E: data.numberOfCustomers,
            F: data.daysRange,
            G: data.bucket,
        })
    })

    const finalData = [...title, ...table1];
    const finalData1 = [...title1, ...table2];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
    ];

    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    ws1['!cols'] = [
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Dashboard');
    XLSX.utils.book_append_sheet(wb, ws1, 'DashboardII');

    const workbookBlob = workbook2blob(wb);

    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'MONTHS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:H2',
        tbodyRange: `A2:H${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:H${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }

    const headerIndexA = [];
    const footerIndexA = [];
    finalData1.forEach((data, index) => data['A'] === 'MONTHS' ? headerIndexA.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndexA.push(index) : null )

    const dataInfo1 = {
        titleCell: 'A2',
        titleRange: 'A1:G2',
        tbodyRange: `A2:G${finalData1.length}`,
        theadRange: headerIndexA.length >= 1 ? `A${headerIndexA[0] + 1}:G${headerIndexA[0] + 1}` : null,
        theadRange1: footerIndexA.length >= 1 ? `A${footerIndexA[0] + 1}:G${footerIndexA[0] + 1}` : null,

    }

    addStylesDashboard(workbookBlob, dataInfo, dataInfo1, dispatch);
    // XLSX.writeFile(wb, "dashboard.xlsx");
    // dispatch({ type: ALL_REPORT_SUCCESSFUL });
}

const addStylesDashboard = (workbookBlob, dataInfo, dataInfo1, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach((sheet, index) => {
            if(index === 0){
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
    
                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
    
                sheet.range(dataInfo.theadRange).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })
    
                sheet.range(dataInfo.theadRange1).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })
            } else if (index === 1) {
                sheet.range(dataInfo1.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
    
                sheet.range(dataInfo1.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                if (dataInfo1.theadRange) {
                    sheet.range(dataInfo1.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange1) {
                    sheet.range(dataInfo1.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
    
                // sheet.range(dataInfo1.theadRange).style({
                //     fill: '808080',
                //     fontColor: 'FFFFFF',
                //     bold: true,
                // })
    
                // sheet.range(dataInfo1.theadRange1).style({
                //     fill: '808080',
                //     fontColor: 'FFFFFF',
                //     bold: true,
                // })
            }
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'dashboard.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

async function getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency }){

    const endOfMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

    const bucket = Math.round(fromNow/30);

    return getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency });

}

async function getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency });
}

async function getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency }){

    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    if(systemDoc.exists){
        const systemInfo = systemDoc.data();

        let store = [];
        let totalStore = [];

        //find total of terms within this month
        terms.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    store.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    store.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);

        //find total of terms within for all overdue loans
        termsStore.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    totalStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    totalStore.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const allTotal = totalStore.reduce((a, b) => a + b, 0);

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const ODPercent = (total/allTotal) * 100;
        let percent = roundAccurately(ODPercent, 2);

        return renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, localCurrency });

    }
}

async function renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, localCurrency }){

    const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

    return renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, localCurrency });
}

async function renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, localCurrency }){
    //grab the end of the month
    const endMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endMonth, 'days');

    const buckets = Math.round(fromNow/30);

    let daysRange = "(0)";

    //compute date range depending on bucket
    if(buckets !== 0) {
        const endDate = buckets * 30;
        const startDate = endDate - 29;

        daysRange = `(${startDate}-${endDate})`
    }

    return renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, daysRange, localCurrency });
}

async function renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, endOfMonth, daysRange, localCurrency }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalInUsd = store.reduce((a, b) => a + b, 0);
    totalInUsd = roundAccurately(totalInUsd, 2);

    if(totalInUsd !== 0){
        // const  currency = "USD";
        //
        // const formatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency,
        // });
        //
        // totalInUsd = formatter.format(totalInUsd);

        return renderDebitInUSD({ totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency });

    } else {
        return renderDebitInUSD({ totalInUsd: "", percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency });
    }
}

async function renderDebitInUSD({ totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency === "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                //check if term has penal interest
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency === "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalDebitInUSD = store.reduce((a, b) => a + b, 0);
    totalDebitInUSD = roundAccurately(totalDebitInUSD, 2);

    if(totalDebitInUSD !== 0){
        // const  currency = "USD";
        //
        // const formatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency,
        // });
        //
        // totalDebitInUSD = formatter.format(totalDebitInUSD);

        return renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency });

    } else {
        return renderDebitAmountLCY({ totalDebitInUSD: "", totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency });
    }
}

async function renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, percent, numberOfCustomers, bucket, date, allBeforeTerms, endOfMonth, daysRange, localCurrency }){
    //create the terms amount store
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    if (term.transactionDate) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency !== "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency !== "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalLCY = store.reduce((a, b) => a + b, 0);
    totalLCY = roundAccurately(totalLCY, 2);

    // if(totalLCY !== 0){
    //
    //     const formatter = new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency: localCurrency,
    //     });
    //
    //     totalLCY = formatter.format(totalLCY);
    // }

    return { totalLCY, totalInUsd, totalDebitInUSD, percent, numberOfCustomers, bucket, endOfMonth, daysRange }
}

export const loanStatementReport = ({ currentLoan, currentLoanTerms, systemInfo, profile }) => {
    return () => {
        // console.log({currentLoan, currentLoanTerms, systemInfo, profile});
        let successArray = [];
        //get loan statements
        if (!(_.isEmpty(currentLoanTerms))) {

            const terms = _.map(currentLoanTerms, term => {
                return term
            });

            terms.map(loanTerm => {
                const res = getStatementData({loanTerm, currentLoan, currentLoanTerms, systemInfo, profile});
                successArray.push(res);
            })
        }

        Promise.all(successArray).then((loanStatement) => {
            //call function to request cash collections data
            getCashCollection({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile});
        });
    }
}


async function getStatementData({loanTerm, currentLoan, currentLoanTerms, systemInfo, profile}){

    let axleObj = {};
    let seconds;
    let comment = "";
    loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;

    const dueDate = moment.unix(seconds).format("DD/MM/YYYY");
    if(loanTerm.cheque){
        const chequeNumber = loanTerm.chequeNumber;
        const amount = loanTerm.amount;

        let localCurrency = systemInfo.defaultCurrency;

        let displayValue;
        //check if currency is activated in profile
        let currency;
    
        //check if payment object is not empty
        if (!(_.isEmpty(loanTerm))) {
            const loanCurrency = loanTerm.currency;
            if (loanCurrency === "usd") {
                currency = "USD";
    
                displayValue = amount;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";
    
                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = amount / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = amount / exchangeRate;
                    }
                } else {
                    currency = localCurrency;
    
                    displayValue = amount;
                }
            }
        }
    
        // const formatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency,
        // });
        // const displayCurrency = formatter.format(displayValue);

        const status = loanTerm.chequeStatus;
        let chequeStatus;
        
        if("comment.comments" in loanTerm){
            comment = loanTerm.comment.comments;
        }


        switch (status) {
            //
            case "pending":
                chequeStatus = "Pending"
                break;
            case "notDeposited":
                chequeStatus = "Not Deposited"
                break;
            case "bounced":
                chequeStatus = "Bounced"
                break;
            case "cleared":
                chequeStatus = "Cleared"
                break;
            case "held":
                chequeStatus = "Held"
                break;
    
            default:
                break;
        }
        axleObj = {chequeNumber, displayCurrency: displayValue, chequeStatus, dueDate, comment};

    } else {
        const amount = loanTerm.amount;
        const localCurrency = systemInfo.defaultCurrency;

        let displayValue;
        //check if currency is activated in profile
        let currency;
    
        //check if payment object is not empty
        if (!(_.isEmpty(loanTerm))) {
            const loanCurrency = loanTerm.currency;
            if (loanCurrency === "usd") {
                currency = "USD";
    
                displayValue = amount;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";
    
                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = amount / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = amount / exchangeRate;
                    }
                } else {
                    currency = localCurrency;
    
                    displayValue = amount;
                }
            }
        }

        if("comment.comments" in loanTerm){
            comment = loanTerm.comment.comments;
        }
    
        // const formatter = new Intl.NumberFormat('en-US', {
        //     style: 'currency',
        //     currency,
        // });
        //
        // const displayCurrency = formatter.format(displayValue);

        const bankName = loanTerm.bankName;
        axleObj = { displayCurrency: displayValue, bankName, dueDate, comment};
    }

    return { ...axleObj };
}

async function getCashCollection({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile}){
    try{
    // console.log({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile })

        let cashCollections = {};
        const cashRef = firebase.firestore().collection("users").doc("clients").collection(currentLoan.customerID).doc("public").collection("cashCollections").where("loanID", "==", currentLoan.loanID)
        const snapshot = await cashRef.get();
        if(snapshot.size !== 0){
            snapshot.forEach(doc => {
                cashCollections[doc.id] = doc.data();
            })

                //manipulate cash collections in order
                getCashCollectionData({ loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollections });
        } else {
                //manipulate cash collections in order
                getCashCollectionData({ loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollections });
        }
    } catch(e){
        console.log(e);
    }
}

async function getCashCollectionData({ loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollections }){

    let successArray = [];
    const collections = _.map(cashCollections, collection => {
        return collection
    });

    collections.map(collection => {
        const res = getCashCollectionDataToRender({collection, currentLoan, currentLoanTerms, systemInfo, profile});
        successArray.push(res);
    })

    Promise.all(successArray).then((cashCollection) => {
        //call function to request loan summary data
        getLoanExtraFees({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollection, cashCollections});
    });
}

async function getCashCollectionDataToRender({collection, currentLoan, currentLoanTerms, systemInfo, profile}){

    const date = collection.bankDate;
    let bankDateSec;
    date.seconds ? bankDateSec = date.seconds : bankDateSec = date._seconds;
    //convert into a moment object
    const bankDate = moment.unix(bankDateSec).format("DD/MM/YYYY");
    const bankName = collection.bankName;
    const amount = collection.paidAmount;
    let comment = "";

    if ("comment" in collection){
        const collectionComment = collection.comment;
        //check if comments is available
        if("comments" in collectionComment){
            comment = collectionComment.comments;
        }
    }

    // let displayValue;
    // const localCurrency = systemInfo.defaultCurrency;
    // //check if currency is activated in profile
    // let currency;
    //
    // //check if payment object is not empty
    // if (!(_.isEmpty(collection))) {
    //     const loanCurrency = collection.currency;
    //     if (loanCurrency === "usd") {
    //         currency = "USD";
    //
    //         displayValue = amount;
    //     } else {
    //         if (profile.viewInUSD) {
    //             //change currency to usd
    //             currency = "USD";
    //
    //             //check if user has selected a specific exchange rate date
    //             if ("exchangeRateOnDate" in profile) {
    //                 //grab the exchange rate from profile
    //                 const { exchangeRateOnDate } = profile;
    //                 displayValue = amount / exchangeRateOnDate;
    //             } else {
    //                 //grab the default exchange rate from system
    //                 const { exchangeRate } = systemInfo;
    //                 displayValue = amount / exchangeRate;
    //             }
    //         } else {
    //             currency = localCurrency;
    //
    //             displayValue = amount;
    //         }
    //     }
    // }

    // const formatter = new Intl.NumberFormat('en-US', {
    //     style: 'currency',
    //     currency,
    // });
    //
    // const paidAmount = formatter.format(displayValue);

    return { paidAmount: amount, bankName, bankDate, comment }

}

async function getLoanExtraFees({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollection, cashCollections}) {
    //
    let successArray = [];

    try {
        //check if there is extra fees on current loan
        if("extraFees" in currentLoan){
            const fees = currentLoan.extraFees;

            _.map(fees, extraFee => {
                const res = getExtraFeeDetails({extraFee});
                successArray.push(res);
            })
        } else {
            const res = getExtraFeeDetails({extraFee: {}});
            successArray.push(res);
        }
    } catch (e) {
        console.log(e);
    }

    Promise.all(successArray).then((extraFees) => {
        //call function to request loan summary data
        getLoanSummary({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollection, cashCollections, extraFees});
    });
}

async function getExtraFeeDetails({extraFee}) {
    //
    let feeName = "";
    let feeAmount;
    let feeDate = "";

    if (!(_.isEmpty(extraFee))){
        //assign variable values
        feeName = extraFee.extraFeeName;
        feeAmount = extraFee.extraFeeAmount;

        // const extraFeeDate
        let seconds;
        extraFee.extraFeeDate.seconds ? seconds = extraFee.extraFeeDate.seconds : seconds = extraFee.extraFeeDate._seconds;

        feeDate = moment.unix(seconds).format("DD/MM/YYYY");
    }

    return {feeName, feeAmount, feeDate};
}

async function getLoanSummary({loanStatement, currentLoan, currentLoanTerms, systemInfo, profile, cashCollection, cashCollections, extraFees}){
    //we calculate total paid, total paid, previously and suppose to pay
    let totalPaidPreviously = 0;
    let totalPaid = 0;
    let supposedToPay = 0;
    let surplusOrDeficit = 0;
    let outStanding = 0;
    let totalOutstanding = 0;
    let penalInterest = 0;
    let totalCashPaid = 0;
    let totalChequePaid = 0;

    //check that currentLoanTerms is not empty
    if(!(_.isEmpty(currentLoanTerms))) {
        //filter loan terms that are not passed today
        let totalArray = [];

        _.map(currentLoanTerms, term => {
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;

            const dueDate = moment.unix(seconds);
            const today = moment();

            //check if due date is before today
            if (dueDate.isSameOrBefore(today, "day")) {
                //due date is not passed
                //fetch amount supposed to pay and put into the array
                totalArray.push(term.amount);
            }
        });

        //calculate the total amount from numbers in the array
        //check if there are extra fees
        if ("extraFees" in currentLoan) {
            //loop object and take take extra fee amount from each extra fee
            const extraFees = currentLoan.extraFees;

            _.map(extraFees, extraFee => {
                totalArray.push(extraFee.extraFeeAmount);
            });
        }
        totalChequePaid = currentLoan.totalChequePaid
        totalCashPaid = currentLoan.totalCashPaid;


        supposedToPay = totalArray.reduce((a, b) => a + b, 0);


        //check if theres early liquidation
        if (currentLoan.earlyLiquidation) {
            //supposed to pay - early liquidation
            supposedToPay = currentLoan.earlyLiquidation;

        }
    }

    //check if amountPaidPreviously exists
    if ("previousOverdue" in currentLoan) {
        //amount paid is present
        totalPaidPreviously = supposedToPay - currentLoan.previousOverdue
    }

    totalPaid = totalPaidPreviously + currentLoan.totalCashPaid + currentLoan.totalChequePaid;

    

    //calculate suplus or deficient
    //check if there are extra fees
    let surplusArray = [];
    if ("extraFees" in currentLoan) {
        //loop object and take take extra fee amount from each extra fee
        const extraFees = currentLoan.extraFees;

        _.map(extraFees, extraFee => {
            surplusArray.push(extraFee.extraFeeAmount);
        });
    }

    const extraFeeTotal = surplusArray.reduce((a, b) => a + b, 0);

    surplusOrDeficit = totalPaid - supposedToPay;
    

    //calculate outstanding
    outStanding = currentLoan.totalAmount - (supposedToPay - extraFeeTotal);


    //calculate total outstanding
    //check if surplus is negative or positive
    if (surplusOrDeficit > 0) {
        //its positive
        totalOutstanding  = outStanding - surplusOrDeficit;

    } else {
        //its negative
        totalOutstanding  = outStanding - surplusOrDeficit;
    }


    //penal interest
    let penalArray = [];
    if (!(_.isEmpty(currentLoanTerms)) && !(_.isEmpty(currentLoan))) {

         _.map(currentLoanTerms, term => {

            if ('termStatus' in term) {

                const amount = term.termStatus.penalInterest;
                penalArray.push(amount);
            }
        });

        penalInterest = penalArray.reduce((a, b) => a + b, 0);
    }

    exportPaymentFormat({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees});
}

async function exportPaymentFormat({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = totalPaid;
        } else {
            //
            currency = localCurrency;

            displayValue = totalPaid;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    totalPaid = formatter.format(displayValue);
    exportPaymentFormat1({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees})
}
async function exportPaymentFormat1({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = supposedToPay;
        } else {
            //
            currency = localCurrency;

            displayValue = supposedToPay;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    supposedToPay = formatter.format(displayValue);
    exportPaymentFormat2({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })

}async function exportPaymentFormat2({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = surplusOrDeficit;
        } else {
            //
            currency = localCurrency;

            displayValue = surplusOrDeficit;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    surplusOrDeficit = formatter.format(displayValue);
    exportPaymentFormat3({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })
}

async function exportPaymentFormat3({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = outStanding;
        } else {
            //
            currency = localCurrency;

            displayValue = outStanding;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    outStanding = formatter.format(displayValue);
    exportPaymentFormat4({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })
}

async function exportPaymentFormat4({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = totalOutstanding;
        } else {
            //
            currency = localCurrency;

            displayValue = totalOutstanding;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    totalOutstanding = formatter.format(displayValue);

    exportPaymentFormat5({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })
}

async function exportPaymentFormat5({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = penalInterest;
        } else {
            //
            currency = localCurrency;

            displayValue = penalInterest;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    penalInterest = formatter.format(displayValue);
    exportPaymentFormat6({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })
}

async function exportPaymentFormat6({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees }){
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = totalCashPaid;
        } else {
            //
            currency = localCurrency;

            displayValue = totalCashPaid;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    totalCashPaid = formatter.format(displayValue);
    exportPaymentFormat7({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees })
}

async function exportPaymentFormat7({ currentLoan, loanStatement, cashCollection, systemInfo, profile, totalPaid, supposedToPay, surplusOrDeficit, outStanding, totalOutstanding, penalInterest, totalCashPaid, totalChequePaid, extraFees}){
    let loanSummary = {};
    let displayValue;
    const localCurrency = systemInfo.defaultCurrency;
    //check if currency is activated in profile
    let currency;

    //check if payment object is not empty
    if (!(_.isEmpty(currentLoan))) {
        const loanCurrency = currentLoan.currency;
        if (loanCurrency === "usd") {
            currency = "USD";

            displayValue = totalChequePaid;
        } else {
            //
            currency = localCurrency;

            displayValue = totalChequePaid;
        }
    }

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
    });

    totalChequePaid = formatter.format(displayValue);
    loanSummary = {
        totalPaid,
        supposedToPay,
        surplusOrDeficit,
        outStanding,
        totalOutstanding,
        penalInterest,
        totalCashPaid,
        totalChequePaid ,
    }

    exportDataToExcelSheet({ loanSummary, loanStatement, cashCollection, currentLoan, extraFees });
}

async function exportDataToExcelSheet({ loanSummary, loanStatement, cashCollection, currentLoan, extraFees }){
    const today = moment().format("DD/MM/YYYY");

    let empty = [{A: ''}];
    let name = [{A: `  ${currentLoan.customerName}`}];
    let title = [{A: ` LOAN STATEMENTS AS OF ${today}`}];

    let table1 = [
        {
            A: "Cheque #",
            B: "Amount",
            C: "Due Date",
            D: "Status",
            E: "Comment"
        }
    ];

    let table2 = [
        {
            A: "Cash Collection",
            B: "Recipient Bank",
            C: "Date",
            D: "Comment"
        }
    ];

    let table4 = [
        {
            A: "Extra Fee Name",
            B: "Extra Fee Amount",
            C: "Date",
        }
    ];

    loanStatement.forEach(data => {
        table1.push({
            A: data.chequeNumber,
            B: data.displayCurrency,
            C: data.dueDate,
            D: data.chequeStatus,
            E: data.comment
        })
    })

    cashCollection.forEach(data => {
        table2.push({
            A: data.paidAmount,
            B: data.bankName,
            C: data.bankDate,
            D: data.comment,
        })
    })

    extraFees.forEach(data => {
        table4.push({
            A: data.feeName,
            B: data.feeAmount,
            C: data.feeDate,
        })
    })

    let summaryArr = [
        {title: "Total Cash Payment", value: loanSummary.totalCashPaid},
        {title: "Total Cheque Payment", value: loanSummary.totalChequePaid},
        {title: "Total Paid", value: loanSummary.totalPaid},
        {title: "Suppose To Pay", value: loanSummary.supposedToPay},
        {title: "Surplus/Deficient", value: loanSummary.surplusOrDeficit},
        {title: "Outstanding Balance", value: loanSummary.outStanding},
        {title: "Total Outstanding Balance", value: loanSummary.totalOutstanding},
        {title: "Generated Penal Interest", value: loanSummary.penalInterest},
    ]

    let table3 = [
        {
            A: "Tittle",
            B: "Amount",
        }
    ]

    summaryArr.forEach(data => {
        table3.push({
            A: data.title,
            B: data.value,
        })
    })

    table1 = (['']).concat([{A: "LOAN STATEMENT"}]).concat(table1).concat(['']).concat(['']).concat(['']).concat([{A: "CASH COLLECTIONS"}]).concat(table2).concat(['']).concat(['']).concat(['']).concat([{A: "EXTRA FEES"}]).concat(table4).concat(['']).concat(['']).concat(['']).concat([{A: "LOAN SUMMARY"}]).concat(table3);;
    const finalData = [...empty, ...name, ...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:20},
        {wch:25},
        {wch:25},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Loan Statement Report');
    XLSX.writeFile(wb, "Loan_Statement_Report.xlsx");
}

export const createCustomerOverdueReportNew = ({reportDate, systemInfo, profile}) => {
    return (dispatch) => {
        dispatch({type: ALL_REPORT});
        getCustomerOverdueReport({reportDate, systemInfo, profile, dispatch});
    }
}

async function getCustomerOverdueReport({systemInfo, profile, reportDate, dispatch}){

    try{
        if (!(_.isEmpty(systemInfo))) {

            const today = moment();
            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    if(response.size !== 0){
                        let costomerObj = {};
                        response.forEach(term => {
                            if(`${term.customerID}` in costomerObj){
                                let terms = costomerObj[`${term.customerID}`].terms;
                                    terms.push(term)

                            }else{
                                costomerObj[`${term.customerID}`] = {
                                    terms: [term],
                                }
                            }

                        })

                        grabCustomerData({costomerObj, systemInfo, profile, reportDate, dispatch});

                    }else{
                        message.info("There is no data to generate report");
                        dispatch({ type: ALL_REPORT_FAILED });
                    }
                }).catch((e) => {
                    console.log(e);
                dispatch({type: ALL_REPORT_FAILED});

                })
        }
    }catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});

    }
}

async function grabCustomerData({costomerObj, systemInfo, profile, reportDate, dispatch}){
    let successArray = [];
    try{

        const customersArr = Object.values(costomerObj);
        customersArr.forEach(customer => {
            const res = grabOverdueAndCustomerName({terms: customer.terms, systemInfo, profile, reportDate, dispatch});
            successArray.push(res);
        })
    }catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED})
    }
    Promise.all(successArray).then((bulkSms) => {
        getCustomerPhoneNumber({bulkSms, dispatch});
    })
}

async function grabOverdueAndCustomerName({terms, systemInfo, profile, reportDate, dispatch}){

    try{
        let customerID = "";
        let customerName = "";
        let overdueArr = [];
        let penalArray = [];
        terms.map(term => {
            let overdueAmount = 0;
            let penalValue = 0;
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                if ("modulo" in term) {
                    overdueAmount = term.amount - term.modulo;
                    customerID = term.customerID;
                    customerName = term.customerName;
                    if(term.currency === "usd"){
                        overdueAmount = overdueAmount * systemInfo.exchangeRate;
                        overdueArr.push(overdueAmount);
                    }else{
                        overdueArr.push(overdueAmount);
                    }
                } else {
                    overdueAmount = term.amount;
                    customerID = term.customerID;
                    customerName = term.customerName;
                    if(term.currency === "usd"){
                        overdueAmount = overdueAmount * systemInfo.exchangeRate;
                        overdueArr.push(overdueAmount);
                    }else{
                        overdueArr.push(overdueAmount);
                    }
                }
                if("uploadDay" in term){
                    if("termStatus" in term){
                        if(!(term.termStatus.status)){
                            const termStatus = term.termStatus;
                            if("penalInterest" in termStatus){
                                penalValue = termStatus.penalInterest;
                                if(term.currency === "usd"){
                                    penalValue = penalValue * systemInfo.exchangeRate;
                                    penalArray.push(penalValue);
                                }else{
                                    penalArray.push(penalValue);
                                }
                            }
                        }
                    }
                }
            }
        })

        const overdue = overdueArr.reduce((a, b) => a + b, 0);
        const penalInterest = penalArray.reduce((a, b) => a + b, 0);

        return {overdue, customerName, customerID, penalInterest};

    }catch(e){
        dispatch({type: ALL_REPORT_FAILED});
        console.log(e);
    }

}

async function getCustomerPhoneNumber({bulkSms, dispatch}){
    let successArray = [];
    try{
        bulkSms.forEach(customer => {
            const res = grabCustomerPhoneNumber({customer, dispatch});
            successArray.push(res);
        })
    }
    catch(e){
        dispatch({type: ALL_REPORT_FAILED})
        console.log(e);
    }
    Promise.all(successArray).then((resultArray) => {
        exportOverDueCustomersToExcell({resultArray, dispatch})
    })
}

async function grabCustomerPhoneNumber({customer, dispatch}){
    try{
        let phoneNumber = "";
        const ref = firebase.firestore().collection("users").doc("clients").collection(customer.customerID).doc("public").collection("account").doc("info");
        const doc = await ref.get();
        if(doc.exists){
            const data = doc.data();
            if("phoneNumber" in data){
                phoneNumber = data.phoneNumber;
            }
        }
        return {phoneNumber, overdue: customer.overdue, penalInterest: customer.penalInterest, customerName: customer.customerName, customerID: customer.customerID}
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});

    }
}

async function exportOverDueCustomersToExcell({resultArray, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let date = moment().format("DD/MM/YYYY");

    let bulk = `BULK SMS REPORT AS OF ${date}`;
    let title = [{A: bulk}, {}];


    let table1 = [
        {
            A: "NAME",
            B: "OD IN DEFAULT CURRENCY",
            C: "PHONE NUMBER",
            D: "PENAL INTEREST"
        }
    ];


    resultArray.forEach(data => {
        if(data.penalInterest === 0){
            table1.push({
                A: data.customerName,
                B: data.overdue,
                C: data.phoneNumber,
                D: ""
            })  
        }else{
            table1.push({
                A: data.customerName,
                B: data.overdue,
                C: data.phoneNumber,
                D: data.penalInterest
            })
        }
    })

    const finalData1 = [...title, ...table1];

    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    ws1['!cols'] = [
        {wch:40},
        {wch:30},
        {wch:25},
        {wch:25},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'Bulk SMS');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    // const footerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'NAME' ? headerIndex.push(index) : null )
    // finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:D2',
        tbodyRange: `A2:D${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:D${headerIndex[0] + 1}` : null,
        // theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }

    addStylesBulkSMS(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "bulk_sms_report.xlsx");
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesBulkSMS = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            // sheet.range(dataInfo.theadRange1).style({
            //     fill: '808080',
            //     fontColor: 'FFFFFF',
            //     bold: true,
            // })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'bulksms.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


export const createCharacterAndStatusAnalysisReportNew = () => {

    return async dispatch => {
        //
        dispatch({type: ALL_REPORT});
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        let successArray = [];

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    let statuses = {};

                    response.forEach(term => {
                        const customerID = term.customerID;
                        const customerName = term.customerName;

                        let amount = 0;
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
                        } else {
                            //grab the total overdue
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            amount = amount / exchangeRate;
                        }


                        //check if dueDateID already exists in dates object
                        if (`${customerID}` in statuses) {
                            //terms with this date already exist
                            let terms = statuses[`${customerID}`].terms;
                            terms.push(amount);

                            statuses[`${customerID}`].terms = terms;
                        } else {
                            //its a new date so create new object for it
                            statuses[`${customerID}`] = {
                                customerID: customerID,
                                customerName: customerName,
                                terms: [amount],
                                termsStore: response
                            }
                        }
                    })

                    //FIND GRAND CONTENTS
                    let sumOfODInUSD;

                    //sum of OD in usd
                    let usdTermStore = [];
                    if (response.length !== 0) {
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount / exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });
                    }

                    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                    sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

                    //for examining each cheque contents
                    if (!(_.isEmpty(statuses))) {
                        ///extract date into array so it can be sorted
                        const datesArray = _.map(statuses, date => date);
                        const sortedDates = arraySort(datesArray, "customerName");

                        sortedDates.map(data => {
                            const customerName = data.customerName;
                            const customerID = data.customerID;
                            const terms = data.terms;

                            const res = grabOverdueAndCustomerNameInUSD({ customerName, terms, customerID });
                            successArray.push(res);

                        })

                        Promise.all(successArray).then((dashboardData) => {

                            // // here is our array of dashboard data
                            if(dashboardData.length !== 0){

                                dashboardData.push({ customerName: "Grand Total", totalODInUsd: sumOfODInUSD });
                                createStatusAnalysisReport({resultArray: dashboardData, dispatch});

                            }
                        })
                    }

                }).catch((error) => {
                    console.log("Here's your error");
                    console.log(error);
                    dispatch({type: ALL_REPORT_FAILED})
                })
        }
    }
}

async function grabOverdueAndCustomerNameInUSD({customerName, terms, customerID}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let totalODInUsd = terms.reduce((a, b) => a + b, 0);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return {customerName, customerID, totalODInUsd};

}


async function createStatusAnalysisReport({resultArray, dispatch}) {

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        let successArray = [];

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    let statuses = {};

                    response.forEach(term => {
                        let statusID;
                        if ("legalRepoStatus" in term) {
                            statusID = term.legalRepoStatus;
                        } else {
                            statusID = "Blank";
                        }

                        //check if dueDateID already exists in dates object
                        if (`${statusID}` in statuses) {
                            //terms with this date already exist
                            let terms = statuses[`${statusID}`].terms;
                            terms.push(term);

                            statuses[`${statusID}`].terms = terms;
                        } else {
                            //its a new date so create new object for it
                            statuses[`${statusID}`] = {
                                status: statusID,
                                terms: [term],
                                termsStore: response
                            }
                        }
                    })

                    //FIND GRAND CONTENTS
                    let totalCustomers;
                    let sumOfODInUSD;

                    //sum of OD in usd
                    let usdTermStore = [];
                    if (response.length !== 0){
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount/exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });
                    }

                    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                    sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);


                    //sum of customers
                    if(response.length !== 0){
                        let customerStore = [];
                        response.map(term => {
                            customerStore.push(term);
                        });

                        let distinctCustomers = {};

                        customerStore.map(term => {
                            if(`${term.customerID}` in distinctCustomers) {
                                //customer already in customers object
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared do nothing
                                } else {
                                    //grab terms from customer object
                                    let terms = distinctCustomers[`${term.customerID}`].terms;
                                    terms.push(term);

                                    distinctCustomers[`${term.customerID}`].terms = terms;
                                }
                            } else {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared do nothing
                                } else {
                                    distinctCustomers[`${term.customerID}`] = {
                                        customerID: term.customerID,
                                        customerName: term.customerName,
                                        terms: [term]
                                    }
                                }
                            }
                        });

                        totalCustomers = Object.keys(distinctCustomers).length;
                    }

                    //for examining each cheque contents
                    if (!(_.isEmpty(statuses))) {
                        ///extract date into array so it can be sorted
                        const datesArray = _.map(statuses, date => date);
                        const sortedDates = arraySort(datesArray, "status");

                        sortedDates.map(data => {
                            const status = data.status;
                            const terms = data.terms;
                            const termsStore = data.termsStore;

                            const res = getNumberOfCustomersWithStatus({ status, terms, termsStore });
                            successArray.push(res);

                        })

                        Promise.all(successArray).then((dashboardData) => {

                            // // here is our array of dashboard data
                            if(dashboardData.length !== 0){

                                dashboardData.push({ status: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });
                                createCharacterAnalysisReport({statusAnalysis: dashboardData, resultArray, dispatch});

                            }
                        })
                    }

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED})
            })
        }
}

async function getNumberOfCustomersWithStatus({ status, terms, termsStore }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getValueWithStatus({ numberOfCustomers, status, terms, termsStore});
}

async function getValueWithStatus({ numberOfCustomers, status, terms, termsStore }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const customerValue = Object.keys(distinctCustomers).length;

    let allDistinctCustomers = {};
        //find total of terms within for all overdue loans
        termsStore.map(term => {
            //
            if(`${term.customerID}` in allDistinctCustomers) {
                //customer already in customers object
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    //grab terms from customer object
                    let terms = allDistinctCustomers[`${term.customerID}`].terms;
                    terms.push(term);

                    allDistinctCustomers[`${term.customerID}`].terms = terms;
                }
            } else {
                //check that term is not cleared
                if (term.termStatus.status) {
                    //term is cleared do nothing
                } else {
                    allDistinctCustomers[`${term.customerID}`] = {
                        customerID: term.customerID,
                        customerName: term.customerName,
                        terms: [term]
                    }
                }
            }
        });

        //total value of distinct number of customers
        const totalValue = Object.keys(allDistinctCustomers).length;

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const numberPercent = (customerValue/totalValue) * 100;
        let countPercent = roundAccurately(numberPercent, 1);

        return getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent });

}

async function getODPercentsWithStatus({ numberOfCustomers, status, terms, termsStore, countPercent }){

    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    if(systemDoc.exists){
        const systemInfo = systemDoc.data();

        let store = [];
        let totalStore = [];

        //find total of terms within this month
        terms.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    store.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    store.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);

        //find total of terms within for all overdue loans
        termsStore.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    totalStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    totalStore.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const allTotal = totalStore.reduce((a, b) => a + b, 0);

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const ODPercent = (total/allTotal) * 100;
        let valuePercent = roundAccurately(ODPercent, 1);

        return getSumOfODInUSD({ valuePercent, systemInfo, numberOfCustomers, status, terms, countPercent  });

    }
}

async function getSumOfODInUSD({ valuePercent, systemInfo, numberOfCustomers, status, terms, countPercent }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, status }
}



async function createCharacterAnalysisReport({statusAnalysis, resultArray, dispatch}) {

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let successArray = [];


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    let characters = {};

                    response.forEach(term => {
                        let characterID;
                        if ("character" in term) {
                            if(term.character) {
                                characterID = term.character;
                            } else {
                                characterID = "Blank"
                            }
                        } else {
                            characterID = "Blank";
                        }

                        //check if characterID already exists in dates object
                        if (`${characterID}` in characters) {
                            //terms with this date already exist
                            let terms = characters[`${characterID}`].terms;
                            terms.push(term);

                            characters[`${characterID}`].terms = terms;
                        } else {
                            //its a new date so create new object for it
                            characters[`${characterID}`] = {
                                character: characterID,
                                terms: [term],
                                termsStore: response
                            }
                        }
                    })

                    //FIND GRAND CONTENTS
                    let totalCustomers;
                    let sumOfODInUSD;

                    //sum of OD in usd
                    let usdTermStore = [];
                    if (response.length !== 0){
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount/exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });
                    }

                    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                    sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

                    //sum of customers
                    if(response.length !== 0){
                        let customerStore = [];
                        response.map(term => {
                            customerStore.push(term);
                        });

                        let distinctCustomers = {};

                        customerStore.map(term => {
                            if(`${term.customerID}` in distinctCustomers) {
                                //customer already in customers object
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared do nothing
                                } else {
                                    //grab terms from customer object
                                    let terms = distinctCustomers[`${term.customerID}`].terms;
                                    terms.push(term);

                                    distinctCustomers[`${term.customerID}`].terms = terms;
                                }
                            } else {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared do nothing
                                } else {
                                    distinctCustomers[`${term.customerID}`] = {
                                        customerID: term.customerID,
                                        customerName: term.customerName,
                                        terms: [term]
                                    }
                                }
                            }
                        });

                        totalCustomers = Object.keys(distinctCustomers).length;
                    }

                    // console.log({sumOfNum: totalCustomers, sumOfOD: sumOfODInUSD})

                    //for examining each cheque contents
                    if (!(_.isEmpty(characters))) {
                        ///extract date into array so it can be sorted
                        const datesArray = _.map(characters, date => date);
                        const sortedDates = arraySort(datesArray, "character");

                        sortedDates.map(data => {
                            const character = data.character;
                            const terms = data.terms;
                            const termsStore = data.termsStore;

                            const res = getNumberOfCustomersWithCharacter({ character, terms, termsStore });
                            successArray.push(res);

                        })

                        Promise.all(successArray).then((dashboardData) => {

                            // here is our array of dashboard data
                            if(dashboardData.length !== 0){

                                dashboardData.push({ character: "Grand Total", numberOfCustomers: totalCustomers, totalODInUsd: sumOfODInUSD, percentOnCount: "", percentOnValue: "", });

                                exportCharacterAndStatusWithOverdueAnalysis({characterAnalysis: dashboardData, statusAnalysis, resultArray, dispatch});

                            }
                        })
                    }

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED})
            })
        }
}

async function exportCharacterAndStatusWithOverdueAnalysis({characterAnalysis, statusAnalysis, resultArray, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment().format("DD/MM/YYYY");
    let anal = `STATUS ANALYSIS REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];


    let table1 = [
        {
            A: "ROW LABELS",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    resultArray.forEach(data => {
        table1.push({
            A: data.customerName,
            B: data.totalODInUsd,
        })
    })

    let table2 = [
        {
            A: "STATUS",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD REPORTING USD",
            D: "% ON COUNT",
            E: "% ON VALUE"
        }
    ];

    statusAnalysis.forEach(data => {
        table2.push({
            A: data.status,
            B: data.numberOfCustomers,
            C: data.totalODInUsd,
            D: data.countPercent,
            E: data.valuePercent
        })
    })

    //character report

    let table3 = [
        {
            A: "CHARACTER",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD REPORTING USD",
            D: "% ON VALUE"
        }
    ];

    characterAnalysis.forEach(data => {
        table3.push({
            A: data.character,
            B: data.numberOfCustomers,
            C: data.totalODInUsd,
            D: data.valuePercent
        })
    })

    table1 = (['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table1)
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:30},
        {wch:30},
        {wch:15},
        {wch:15},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Status analysis');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];
    const headerIndex3 = [];

    finalData.forEach((data, index) => data['A'] === 'STATUS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'CHARACTER' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:E${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:D${headerIndex1[0] + 1}` : null,
        theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
        theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:E${headerIndex3[0] + 1}` : null,
        theadRange4: headerIndex3.length >= 2 ? `A${headerIndex3[1] + 1}:D${headerIndex3[1] + 1}` : null,
        theadRange5: headerIndex3.length >= 3 ? `A${headerIndex3[2] + 1}:B${headerIndex3[2] + 1}` : null,
    }

    addStylesStatusAnalysis(workbookBlob, dataInfo, dispatch);

}

const addStylesStatusAnalysis = (workbookBlob, dataInfo, dispatch) => {
    console.log(dataInfo);
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange5).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'statusAnalysis.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })

            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}

async function getNumberOfCustomersWithCharacter({ character, terms, termsStore }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getValueWithCharacter({ numberOfCustomers, character, terms, termsStore});
}

async function getValueWithCharacter({ numberOfCustomers, character, terms, termsStore }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const customerValue = Object.keys(distinctCustomers).length;

    let allDistinctCustomers = {};
    //find total of terms within for all overdue loans
    termsStore.map(term => {
        //
        if(`${term.customerID}` in allDistinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = allDistinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                allDistinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                allDistinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    //total value of distinct number of customers
    const totalValue = Object.keys(allDistinctCustomers).length;

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const numberPercent = (customerValue/totalValue) * 100;
    let countPercent = roundAccurately(numberPercent, 1);

    return getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent });


}

async function getODPercentsWithCharacter({ numberOfCustomers, character, terms, termsStore, countPercent }){

    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    if(systemDoc.exists){
        const systemInfo = systemDoc.data();

        let store = [];
        let totalStore = [];

        //find total of terms within this month
        terms.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    store.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    store.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);

        //find total of terms within for all overdue loans
        termsStore.map(term => {

            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    totalStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    totalStore.push(convertedAmount);
                }
            }
        });


        //calculate the total amount from numbers in the array
        const allTotal = totalStore.reduce((a, b) => a + b, 0);

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const ODPercent = (total/allTotal) * 100;
        let valuePercent = roundAccurately(ODPercent, 1);

        return getSumOfCharactersWithODInUSD({ valuePercent, systemInfo, numberOfCustomers, character, terms, countPercent  });

    }
}

async function getSumOfCharactersWithODInUSD({ valuePercent, systemInfo, numberOfCustomers, character, terms, countPercent }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, countPercent, numberOfCustomers, valuePercent, character }
}



export const provisionReportNew  = () => {

    const today = moment();

    return(dispatch) => {
        dispatch({type: ALL_REPORT})
        let successArray = [];

        const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
        fetch(beforeUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                let dates = {};
                let tanzania = false;

                if(project.currency === "TSH"){
                    tanzania = true;
                }

                response.forEach(term => {
                    const customerID = term.customerID;
                    const customerName = term.customerName;
                    let legalStatus = 0;


                    if ("legalRepoStatus" in term){
                        const legalRepoStatus = term.legalRepoStatus;
                        const position = legalRepoStatus.search(/legal/i);
                        if (position >= 0){
                            legalStatus = position + 1;
                        }
                    }

                    //check if dueDateID already exists in dates object
                    if (`${customerID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${customerID}`].terms;
                        terms.push(term);

                        const status = dates[`${customerID}`].legalStatus;
                        const newStatus = status + legalStatus;

                        dates[`${customerID}`].terms = terms;
                        dates[`${customerID}`].legalStatus = newStatus;
                    } else {
                        //its a new date so create new object for it
                        dates[`${customerID}`] = {
                            customerID,
                            customerName,
                            terms: [term],
                            termsStore: response,
                            tanzania,
                            legalStatus
                        }
                    }
                })

                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "customerID");

                    sortedDates.map(data => {
                        const customerID = data.customerID;
                        const customerName = data.customerName;
                        const terms = data.terms;
                        const termsStore = data.termsStore;
                        const legalStatus = data.legalStatus;
                        const tanzania = data.tanzania;

                        // console.log({ customerID, customerName, legalStatus, tanzania});

                        const res = findCustomerBucket({ customerID, customerName, terms, termsStore, legalStatus, tanzania });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((dashboardData) => {

                        // here is our array of dashboard data
                        if(dashboardData.length !== 0){
                            dashboardData.push({customerName: "Grand Total", bucket: "", provision: ""})
                            exportProvisionData({ dashboardData, dispatch });
                        }else{
                            message.info("There is no data to generate report");
                            dispatch({ type: ALL_REPORT_FAILED });
                        }
                    })
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED})
        })

    }
}

async function exportProvisionData({ dashboardData, dispatch }){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }
    const today = moment().format("DD/MM/YYYY");
    let anal = `PROVISION REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "CUSTOMER NAME",
            B: "BUCKET",
            C: "PROVISION"
        }
    ];

    dashboardData.forEach(cheque => {
        table1.push({
            A: cheque.customerName,
            B: cheque.bucket,
            C: cheque.provision
        })
    })

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:25},
        {wch:25},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Provision');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];

    finalData.forEach((data, index) => data['A'] === 'CUSTOMER NAME' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex1.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:C${headerIndex1[0] + 1}` : null,
    }

    addStylesProvision(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, `Analysis_Report_${today}.xlsx`);
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesProvision = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {
            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'provision.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })
            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}

async function findCustomerBucket({ customerID, customerName, terms, termsStore, legalStatus, tanzania }) {

    const dueDateArray = terms.map((term) => {
        let seconds;

        if ("transactionDate" in term){
            //
            term.transactionDate.seconds? (seconds = term.transactionDate.seconds): (seconds = term.transactionDate._seconds);
        } else {
            //
            term.dueDate.seconds? (seconds = term.dueDate.seconds): (seconds = term.dueDate._seconds);
        }

        return moment.unix(seconds);
    })

    const earliestMoment = _.min(dueDateArray);
    const earliestDate = earliestMoment.toDate();
    const today = moment();
    //find the number of days from today
    const days = today.diff(earliestDate, 'days');
    // const aging = Math.round(days/30);
    // console.log({days, customerName, customerID});


    return findCustomerBucketGroup({ customerID, customerName, terms, termsStore, days, legalStatus, tanzania });
}

async function findCustomerBucketGroup({ customerID, customerName, terms, termsStore, days, legalStatus, tanzania }) {

    let bucket = "";

    if (tanzania){
        //provision starts with 1-5
        switch (true) {
            case (days >= 1 && days <= 5):
                bucket = "1-5";
                break;
            case (days >= 6 && days <= 30):
                bucket = "6-30";
                break;
            case (days >= 31 && days <= 60):
                bucket = "31-60";
                break;
            case (days >= 61 && days <= 90):
                bucket = "61-90";
                break;
            case (days >= 91 && days <= 120):
                bucket = "91-120";
                break;
            case (days >= 121 && days <= 150):
                bucket = "121-150";
                break;
            case (days >= 151 && days <= 180):
                bucket = "151-180";
                break;
            case (days >= 181 && days <= 210):
                bucket = "181-210";
                break;
            case (days >= 211 && days <= 240):
                bucket = "211-240";
                break;
            case (days >= 241 && days <= 270):
                bucket = "241-270";
                break;
            case (days >= 271 && days <= 300):
                bucket = "271-300";
                break;
            case (days >= 301 && days <= 330):
                bucket = "301-330";
                break;
            case (days >= 331 && days <= 360):
                bucket = "331-360";
                break;
            case (days > 360):
                bucket = ">360";
                break;

            default:
                return null;

        }

    } else {
        //provision starts with 1-30
        switch (true) {
            case (days >= 1 && days <= 30):
                bucket = "1-30";
                break;
            case (days >= 31 && days <= 60):
                bucket = "31-60";
                break;
            case (days >= 61 && days <= 90):
                bucket = "61-90";
                break;
            case (days >= 91 && days <= 120):
                bucket = "91-120";
                break;
            case (days >= 121 && days <= 150):
                bucket = "121-150";
                break;
            case (days >= 151 && days <= 180):
                bucket = "151-180";
                break;
            case (days >= 181 && days <= 210):
                bucket = "181-210";
                break;
            case (days >= 211 && days <= 240):
                bucket = "211-240";
                break;
            case (days >= 241 && days <= 270):
                bucket = "241-270";
                break;
            case (days >= 271 && days <= 300):
                bucket = "271-300";
                break;
            case (days >= 301 && days <= 330):
                bucket = "301-330";
                break;
            case (days >= 331 && days <= 360):
                bucket = "331-360";
                break;
            case (days > 360):
                bucket = ">360";
                break;

            default:
                return null;

        }

    }

    return findCustomerProvision({customerID, customerName, terms, termsStore, bucket, legalStatus, tanzania});
}

async function findCustomerProvision({ customerID, customerName, terms, termsStore, bucket, legalStatus, tanzania }) {

    let provision = "0%";
    //provision starts with 1-5 bucket range
    if (legalStatus > 0){
        //set provision 5 for all customers with legal status
        provision = "5%";
    } else {
        //
        switch (bucket) {
            case "1-5":
                provision = "2%";
                break;
            case "6-30":
                provision = "2%";
                break;
            case "1-30":
                provision = "2%";
                break;
            case "31-60":
                provision = "2%";
                break;
            case "61-90":
                provision = "3%";
                break;
            case "91-120":
                provision = "4%";
                break;
            case "121-150":
                provision = "4%";
                break;
            case "151-180":
                provision = "4%";
                break;
            case "181-210":
                provision = "4%";
                break;
            case "211-240":
                provision = "4%";
                break;
            case "241-270":
                provision = "4%";
                break;
            case "271-300":
                provision = "4%";
                break;
            case "301-330":
                provision = "4%";
                break;
            case "331-360":
                provision = "4%";
                break;
            case ">360":
                provision = "5%";
                break;

            default:
                return null;
        }
    }

    return ({customerID, customerName, terms, termsStore, bucket, provision, tanzania});
}


//roll up
// export const createRollupCustomerOverdueReport = () => {
//     return async dispatch => {

//         let successArray = [];
//         const systemRef = firebase.firestore().collection("system").doc("info");
//         const systemDoc = await systemRef.get();

//         if(systemDoc.exists){
//             const systemInfo = systemDoc.data();
//             const localCurrency = systemInfo.defaultCurrency;

//             let totalAmountUSD = [];
//             let totalAmountLCY = [];
//             let currency;

//             const formatter = new Intl.NumberFormat('en-US', {
//                 style: 'currency',
//                 currency: 'usd',
//             });

//             const formatterLCY = new Intl.NumberFormat('en-US', {
//                 style: 'currency',
//                 currency: localCurrency,
//             });

//             try {
//                 let terms = [];
//                 const loanTermsRef = firebase.firestore().collection("loanTerms").where("loanStatus", "==", false);
//                 const snapshot = await loanTermsRef.get();

//                 if(snapshot.size !== 0){
//                     snapshot.forEach(doc => {
//                         const term = doc.data();
//                         terms.push(term);
//                     })
//                 }

//                 let overdueTerms = [];
//                 terms.forEach( term => {
//                     let seconds;
//                     term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;

//                     const monthArr = ["January","February","March","April","May","June","July","August","September","October","November","December"];
//                     const dueDate = moment.unix(seconds);

//                     let second;
//                     if('transactionDate' in term){
//                         term.transactionDate.seconds ? second = term.transactionDate.seconds : second = term.transactionDate._seconds;
//                     }

//                     const transactionDate = moment.unix(second);
//                     const today = moment().subtract(1, 'days');
//                     const date1 = new Date();
//                     const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1);
//                     const dueDateFormat = dueDate.format('MMM DD YYYY');
//                     const transactionFormat = transactionDate.format('MMM DD YYYY');
//                     const dateFormatFrom = moment(firstDay).format('MMM DD YYYY');
//                     const dateFormatTo = moment(today).format('MMM DD YYYY');

//                     const date = new Date(dueDateFormat);
//                     const month = monthArr[date.getMonth()];

//                     if(term.cheque){
//                         term['emi'] = term.chequeNumber;
//                         term['dueDateFormat'] = dueDateFormat;
//                         term['transactionFormat'] = transactionFormat;
//                     } else {
//                         term['emi'] = "DIRECT TRANSFER";
//                         term['dueDateFormat'] = dueDateFormat;
//                         term['transactionFormat'] = transactionFormat;
//                     }


//                     if (dueDate.isSameOrBefore(dateFormatTo, "day") && dueDate.isSameOrAfter(dateFormatFrom, "day")){
//                         if(term.cheque){
//                             if(term.chequeStatus === "bounced" || term.chequeStatus === "notDeposited"){
//                                 overdueTerms.push(term);
//                             }
//                         } else {
//                             if('termStatus' in term){
//                                 if(!term.termStatus.status){
//                                     overdueTerms.push(term);
//                                 }
//                             }
//                         }
//                     }
//                 })

//                 if(overdueTerms.length !== 0){
//                     overdueTerms.map(data => {

//                         currency = data.currency;

//                         if (currency === "usd"){
//                             totalAmountUSD.push(data.amount);
//                         } else {
//                             totalAmountLCY.push(data.amount);
//                         }

//                         const res = getFormattedAmount({data});
//                         successArray.push(res);
//                     })
//                 } else {

//                 }

//             } catch(e){
//                 console.log(e);
//             }

//             Promise.all(successArray).then((overdues) => {
//                 let amountInFormatUSD = totalAmountUSD.reduce((a, b) => a + b, 0);
//                 let amountInFormatLCY = totalAmountLCY.reduce((a,b) => a + b, 0);

//                 amountInFormatUSD = formatter.format(amountInFormatUSD);
//                 amountInFormatLCY = formatterLCY.format(amountInFormatLCY);
//                 overdues.push({dueDateFormat: "Grand Total", amountInFormatLCY, amountInFormatUSD, customerName: "", loanID: "", emi: ""});
//                 exportCurrentMonthlyOverdue(overdues);
//             });
//         }
//     }
// }


// async function getFormattedAmount({data}){
//     const  currency = data.currency;
//     if (currency === "usd"){
//         //
//         data['amountInFormatUSD'] = data.amount;
//         data['amountInFormatLCY'] = "";

//     } else {
//         data['amountInFormatLCY'] = data.amount;
//         data['amountInFormatUSD'] = "";
//     }

//     return {...data};
// }

export const createRollupCustomerOverdueReportNew = () => {
    return async (dispatch) => {
        try{
            dispatch({type: ALL_REPORT})
            const url = `${project.serverUrl}fetchMasterListNewUpdate`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("Here's your reports object");
                    if(response.length !== 0){
                        let overdueTerms = [];
                        response.forEach(term => {
                            let seconds;
                            if ("transactionDate" in term) {
                                if (term.transactionDate) {
                                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                            } else {
                                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                            }
                            const dueDate = moment.unix(seconds);
                            const today = moment();
                            term["transDate"] = dueDate.format('MMMM');
                            term["transYear"] = dueDate.format('YYYY');
                            if((dueDate.isSame(today, "month"))) {
                            
                                overdueTerms.push(term);
                            }
                        })
                        let costomerObj = {};
                        if(overdueTerms.length !== 0){
                            overdueTerms.map(data => {
                                //grab emi according to customer
                                if(`${data.customerID}` in costomerObj){
                                    let terms = costomerObj[`${data.customerID}`].terms;
                                        terms.push(data)

                                }else{
                                    costomerObj[`${data.customerID}`] = {
                                        terms: [data],
                                    }
                                }
                            })
                        }
                            
                        grabCustomerDataNewCase({costomerObj, dispatch});
                    }else {
                        message.info("There is no data to generate report");
                        dispatch({ type: ALL_REPORT_FAILED });
                    }
    
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: ALL_REPORT_FAILED });
            });
        }
        catch(e){
            console.log(e);
            dispatch({type: ALL_REPORT_FAILED})
        }
    }
}

async function grabCustomerDataNewCase({costomerObj, dispatch}){
    let successArray = [];
    try{
        const customersArr = Object.values(costomerObj);
        customersArr.forEach(customer => {
            const res = grabNewCaseData({termsArr: customer.terms, dispatch});
            successArray.push(res);
        })
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED})
    }

    Promise.all(successArray).then((overdues) => {
        let totalArr = [];
        overdues.forEach(ov => {
            totalArr.push(ov.overdue);
        })
        let overdueTotal = totalArr.reduce((a, b) => a + b, 0);
        const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'usd',
            });
        overdueTotal = formatter.format(overdueTotal);
        overdues.push({transDate: "Grand Total", overdue: overdueTotal, customerName: ""});
        exportCurrentMonthlyOverdue({overdues, dispatch});
    })
}

async function grabNewCaseData({termsArr, dispatch}){
    try{
        let overdueArr = [];
        let customerName = "";
        let transDate = "";
        let transYear = "";
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        if(systemDoc.exists){
            const systemInfo = systemDoc.data();
            termsArr.forEach(term => {
                customerName = term.customerName;
                transDate = term.transDate;
                transYear = term.transYear;
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        overdueArr.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        overdueArr.push(convertedAmount);
                    }
                }
            })
        }

        let overdue = overdueArr.reduce((a, b) => a + b, 0);
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        overdue = roundAccurately(overdue, 2);
        return {overdue, customerName, transDate, transYear}

    }
    catch(e){
        console.log(e);

    }
}

async function exportCurrentMonthlyOverdue({overdues, dispatch}){

    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }
    const month = moment().format('MMMM');
    const year  = moment().format('YYYY');
    const newCase = `NEW CASES - ${month} ${year}`;

    let title = [{A: newCase}, {}];

    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "CUSTOMER NAME",
            C: "OVERDUE IN USD",
        }
    ];

    overdues.forEach(data => {
        let date;
        if(data.transDate === "Grand Total"){
            date = "Grand Total";
        }else{
            date = `${data.transDate} ${data.transYear}`;
        }

        table1.push({
            A: date,
            B: data.customerName,
            C: data.overdue,
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:35},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'New Case');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:C${footerIndex[0] + 1}` : null,

    }

    addStylesNewCase(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "New_Cases_report.xlsx");
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesNewCase = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'newcase.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const createRollupCustomerOverdueReportLastMonthNew = () => {
    return async (dispatch) => {
        try{
            dispatch({type: ALL_REPORT})
            const url = `${project.serverUrl}fetchMasterListNewUpdate`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("Here's your reports object");
                    if(response.length !== 0){
                        let overdueTerms = [];
                        response.forEach(term => {
                            let seconds;
                            if ("transactionDate" in term) {
                                if (term.transactionDate) {
                                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                            } else {
                                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                            }
                            const dueDate = moment.unix(seconds);
                            const previousMonthDate = moment().subtract(1, "months").calendar();
                            const startDay = moment(previousMonthDate).startOf("months");
                            const lastDay = moment(previousMonthDate).endOf("months");
                            term["transDate"] = dueDate.format('MMMM');
                            term["transYear"] = dueDate.format('YYYY');
                            console.log({dueDate, startDay, lastDay})

                            if((dueDate.isSameOrAfter(startDay, "day") && (dueDate.isSameOrBefore(lastDay, "day")))) {
                            
                                overdueTerms.push(term);
                            }
                        })
                        let costomerObj = {};
                        if(overdueTerms.length !== 0){
                            overdueTerms.map(data => {
                                //grab emi according to customer
                                if(`${data.customerID}` in costomerObj){
                                    let terms = costomerObj[`${data.customerID}`].terms;
                                        terms.push(data)

                                }else{
                                    costomerObj[`${data.customerID}`] = {
                                        terms: [data],
                                    }
                                }
                            })
                        }
                            
                            grabCustomerDataRolledOver({costomerObj, dispatch});
                    }else {
                        message.info("There is no data to generate report");
                        dispatch({ type: ALL_REPORT_FAILED });
                    }
    
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: ALL_REPORT_FAILED });
            });
        }
        catch(e){
            console.log(e);
            dispatch({type: ALL_REPORT_FAILED})
        }
    }
}

async function grabCustomerDataRolledOver({costomerObj, dispatch}){
    let successArray = [];
    try{
        const customersArr = Object.values(costomerObj);
        customersArr.forEach(customer => {
            const res = grabNewCaseDataRolledOver({termsArr: customer.terms, dispatch});
            successArray.push(res);
        })
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED})
    }

    Promise.all(successArray).then((overdues) => {
        let totalArr = [];
        overdues.forEach(ov => {
            totalArr.push(ov.overdue);
        })
        let overdueTotal = totalArr.reduce((a, b) => a + b, 0);
        const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'usd',
            });
        overdueTotal = formatter.format(overdueTotal);
        overdues.push({transDate: "Grand Total", overdue: overdueTotal, customerName: ""});
        exportRollOverLastMonth({overdues, dispatch});
    })
}

async function grabNewCaseDataRolledOver({termsArr, dispatch}){
    try{
        let overdueArr = [];
        let customerName = "";
        let transDate = "";
        let transYear = "";
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        if(systemDoc.exists){
            const systemInfo = systemDoc.data();
            termsArr.forEach(term => {
                customerName = term.customerName;
                transDate = term.transDate;
                transYear = term.transYear;
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        overdueArr.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        overdueArr.push(convertedAmount);
                    }
                }
            })
        }

        let overdue = overdueArr.reduce((a, b) => a + b, 0);
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        overdue = roundAccurately(overdue, 2);
        return {overdue, customerName, transDate, transYear}

    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED})

    }
}

async function exportRollOverLastMonth({overdues, dispatch}){

    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }


    const date = new Date();
    const firstD = new Date(date.getFullYear(), date.getMonth() - 1, 2);
    const year = moment(firstD).format('YYYY')
    const month = moment(firstD).format('MMMM')
    const rollover = `ROLL OVER FROM ${month} ${year}`;

    let title = [{A: rollover}, {}];

    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "CUSTOMER NAME",
            C: "OVERDUE IN USD",
        }
    ];

    overdues.forEach(data => {
        let date;
        if(data.transDate === "Grand Total"){
            date = "Grand Total";
        }else{
            date = `${data.transDate} ${data.transYear}`;
        }

        table1.push({
            A: date,
            B: data.customerName,
            C: data.overdue,
        })
    })

    const finalData = [...title, ...table1];

    //create a work book
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:35},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'rolled_over');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:C${footerIndex[0] + 1}` : null,

    }

    addStyles(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "Rolled_Overdue_report.xlsx");
    // dispatch({type: ALL_REPORT_SUCCESSFUL});


}

const addStyles = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'rolled_over.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


export const createDBSegmentReportNew = () => {

    return async (dispatch) => {
        dispatch({type: ALL_REPORT});
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("segment");
        const info = await infoRef.get();

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let successArray = [];

        const today = moment();


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const localCurrency = systemInfo.defaultCurrency;
            const url = `${project.serverUrl}fetchAllBeforeTerms`;

            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((allBeforeTerms) => {

                    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
                    fetch(beforeUrl, {
                        method: 'GET',
                        mode: 'cors',
                        headers: {'Content-Type': 'application/json'},
                    }).then((response) => response.json())
                        .then((response) => {

                            let dates = {};

                            const exchangeRate = systemInfo.exchangeRate;

                            response.forEach(term => {

                                let seconds;
                                if ("transactionDate" in term) {
                                    if (term.transactionDate) {
                                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                    } else {
                                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                    }
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }

                                const dueDate = moment.unix(seconds);
                                const endOfMonth = dueDate.endOf('month').format("DD-MM-YYYY");

                                //put term in current month bucket
                                //grab the month and year
                                const dueDateID = dueDate.format("MMYYYY");
                                const year = dueDate.format("YYYY");
                                // console.log(dueDateID);

                                //if loan is in usd convert the overdue to default currency
                                let termAmount;
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    if ("modulo" in term) {
                                        termAmount = term.amount - term.modulo;
                                    } else {
                                        termAmount = term.amount;
                                    }

                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    if ("modulo" in term) {
                                        termAmount = term.amount - term.modulo;
                                    } else {
                                        termAmount = term.amount;
                                    }

                                    termAmount = termAmount/exchangeRate;
                                    termAmount = roundAccurately(termAmount, 2);
                                }

                                //check if dueDateID already exists in dates object
                                if (`${dueDateID}` in dates) {
                                    //terms with this date already exist
                                    let terms = dates[`${dueDateID}`].terms;
                                    terms.push(term);

                                    let overdueTerms = dates[`${dueDateID}`].overdueTerms;
                                    overdueTerms.push(termAmount);

                                    dates[`${dueDateID}`].terms = terms;
                                    dates[`${dueDateID}`].overdueTerms = overdueTerms;
                                } else {
                                    //its a new date so create new object for it
                                    dates[`${dueDateID}`] = {
                                        dateID: dueDateID,
                                        date: endOfMonth,
                                        terms: [term],
                                        termsStore: response,
                                        year,
                                        overdueTerms: [termAmount]
                                    }
                                }
                            })

                            //FIND GRAND CONTENTS
                            let totalCustomers;
                            let totalCustomerBlank;
                            let totalCustomerDAEWOO;
                            let totalCustomerTML;
                            let sumOfODInUSD;
                            let sumOfODInUSDBlank;
                            let sumOfODInUSDDAEWOO;
                            let sumOfODInUSDTML;


                            //sum of OD in usd
                            let usdTermStore = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    //if loan is in usd convert the overdue to default currency
                                    if (term.currency === "usd") {
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        usdTermStore.push(amount);
                                    } else {
                                        //fetch system info exchange rate
                                        const exchangeRate = systemInfo.exchangeRate;

                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }

                                        const convertedAmount = amount/exchangeRate;

                                        usdTermStore.push(convertedAmount);
                                    }
                                }
                            });

                            sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                            sumOfODInUSD = roundAccurately(sumOfODInUSD, 2);

                            //sum of customers
                            let customerStore = [];
                            if(response.length !== 0){
                                response.map(term => {
                                    customerStore.push(term);
                                });

                                let distinctCustomers = {};

                                customerStore.map(term => {
                                    if(`${term.customerID}` in distinctCustomers) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomers[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomers[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomers[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomers = Object.keys(distinctCustomers).length;
                            }


                            //sum of OD in usd for blank segment
                            let usdTermStoreBlank = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    if("segment" in term) {
                                        //
                                        if(term.segment === ""){
                                            //if loan is in usd convert the overdue to default currency
                                            if (term.currency === "usd") {
                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                amount = roundAccurately(amount, 2);
                                                usdTermStoreBlank.push(amount);
                                            } else {
                                                //fetch system info exchange rate
                                                const exchangeRate = systemInfo.exchangeRate;

                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                let convertedAmount = amount/exchangeRate;
                                                convertedAmount = roundAccurately(convertedAmount, 2);

                                                usdTermStoreBlank.push(convertedAmount);
                                            }
                                        }
                                    } else {
                                        //if loan is in usd convert the overdue to default currency
                                        if (term.currency === "usd") {
                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            amount = roundAccurately(amount, 2);
                                            usdTermStoreBlank.push(amount);
                                        } else {
                                            //fetch system info exchange rate
                                            const exchangeRate = systemInfo.exchangeRate;

                                            //grab the total overdue
                                            let amount;
                                            if ("modulo" in term) {
                                                amount = term.amount - term.modulo;
                                            } else {
                                                amount = term.amount;
                                            }

                                            let convertedAmount = amount/exchangeRate;
                                            convertedAmount = roundAccurately(convertedAmount, 2);

                                            usdTermStoreBlank.push(convertedAmount);
                                        }
                                    }
                                }
                            });

                            sumOfODInUSDBlank = usdTermStoreBlank.reduce((a, b) => a + b, 0);

                            //total customers with blank segment
                            let blankSegment = [];
                            if(response.length !== 0){
                                response.map(term => {
                                    //sort terms based on segment
                                    if("segment" in term) {
                                        //
                                        if(term.segment === ""){
                                            blankSegment.push(term);
                                        }
                                    } else {
                                        blankSegment.push(term);
                                    }
                                });

                                let distinctCustomersWithBlankSegment = {};

                                blankSegment.map(term => {
                                    if(`${term.customerID}` in distinctCustomersWithBlankSegment) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomersWithBlankSegment[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomersWithBlankSegment[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomersWithBlankSegment[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomerBlank = Object.keys(distinctCustomersWithBlankSegment).length;
                            }


                            //sum of OD in usd for tml segment
                            let usdTermStoreTml = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    //sort terms based on segment
                                    if("segment" in term) {
                                        //
                                        if (term.segment === "TML"){
                                            //if loan is in usd convert the overdue to default currency
                                            if (term.currency === "usd") {
                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                usdTermStoreTml.push(amount);
                                            } else {
                                                //fetch system info exchange rate
                                                const exchangeRate = systemInfo.exchangeRate;

                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                let convertedAmount = amount/exchangeRate;
                                                convertedAmount = roundAccurately(convertedAmount, 2);

                                                usdTermStoreTml.push(convertedAmount);
                                            }
                                        }
                                    }
                                }
                            });

                            sumOfODInUSDTML = usdTermStoreTml.reduce((a, b) => a + b, 0);

                            //total customers with tml segment
                            let tmlSegment = [];
                            if(response.length !== 0){
                                response.map(term => {
                                    //sort terms based on segment
                                    if("segment" in term) {
                                        //
                                        if (term.segment === "TML"){
                                            tmlSegment.push(term);
                                        }
                                    }
                                });

                                let distinctCustomersWithTmlSegment = {};

                                tmlSegment.map(term => {
                                    if(`${term.customerID}` in distinctCustomersWithTmlSegment) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomersWithTmlSegment[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomersWithTmlSegment[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomersWithTmlSegment[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomerTML = Object.keys(distinctCustomersWithTmlSegment).length;
                            }

                            //sum of OD in usd for daewoo segment
                            let usdTermStoreDaewoo = [];
                            response.map(term => {
                                //check that term is not cleared
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    if("segment" in term) {
                                        //
                                        if (term.segment === "DAEWOO"){
                                            //if loan is in usd convert the overdue to default currency
                                            if (term.currency === "usd") {
                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                usdTermStoreDaewoo.push(amount);
                                            } else {

                                                //grab the total overdue
                                                let amount;
                                                if ("modulo" in term) {
                                                    amount = term.amount - term.modulo;
                                                } else {
                                                    amount = term.amount;
                                                }

                                                let convertedAmount = amount/exchangeRate;
                                                convertedAmount = roundAccurately(convertedAmount, 2);

                                                usdTermStoreDaewoo.push(convertedAmount);
                                            }
                                        }
                                    }
                                }
                            });

                            sumOfODInUSDDAEWOO = usdTermStoreDaewoo.reduce((a, b) => a + b, 0);

                            //total customers with daewoo segment
                            let daewooSegment = [];
                            if(response.length !== 0){
                                response.map(term => {
                                    //sort terms based on segment
                                    if("segment" in term) {
                                        //
                                        if (term.segment === "DAEWOO"){
                                            daewooSegment.push(term);
                                        }
                                    }
                                });

                                let distinctCustomersWithDaewooSegment = {};

                                daewooSegment.map(term => {
                                    if(`${term.customerID}` in distinctCustomersWithDaewooSegment) {
                                        //customer already in customers object
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            //grab terms from customer object
                                            let terms = distinctCustomersWithDaewooSegment[`${term.customerID}`].terms;
                                            terms.push(term);

                                            distinctCustomersWithDaewooSegment[`${term.customerID}`].terms = terms;
                                        }
                                    } else {
                                        //check that term is not cleared
                                        if (term.termStatus.status) {
                                            //term is cleared do nothing
                                        } else {
                                            distinctCustomersWithDaewooSegment[`${term.customerID}`] = {
                                                customerID: term.customerID,
                                                customerName: term.customerName,
                                                terms: [term]
                                            }
                                        }
                                    }
                                });

                                totalCustomerDAEWOO = Object.keys(distinctCustomersWithDaewooSegment).length;
                            }

                            //for examining each cheque contents
                            if (!(_.isEmpty(dates))) {
                                ///extract date into array so it can be sorted
                                const datesArray = _.map(dates, date => date);
                                // const sortedDates = arraySort(datesArray, "dateID");
                                const sortedDates = datesArray.sort(function(a, b){return a.dateID - b.dateID});
                                const sortedArray = sortedDates.sort(function(a, b){return a.year - b.year});

                                sortedArray.map(data => {
                                    const date = data.date;
                                    const terms = data.terms;
                                    const overdueTerms = data.overdueTerms;

                                    // console.log({ID: data.dateID, terms: terms, date: date});

                                    const res = getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate });
                                    successArray.push(res);

                                })

                                Promise.all(successArray).then((dashboardData) => {

                                    //here is our array of dashboard data
                                    let dbSegmentArray = [];
                                    if(dashboardData.length !== 0){
                                        dashboardData.push({ date: "Grand Total", segments: {Blank: {terms: blankSegment, totalOverdue: usdTermStoreBlank},
                                                DAEWOO: {terms: daewooSegment, totalOverdue: usdTermStoreDaewoo}, TML: {terms: tmlSegment, totalOverdue: usdTermStoreTml}},
                                            customers: totalCustomers, totalOD: sumOfODInUSD});

                                        dashboardData.map(cheque => {

                                            let blankNumber = {};
                                            let daewooNumber = {};
                                            let tmlNumber = {};

                                            const segmentData = cheque.segments;
                                            const blank = segmentData.Blank;
                                            const daewoo = segmentData.DAEWOO;
                                            const tml = segmentData.TML;

                                            if(blank.terms.length !== 0){
                                                blank.terms.forEach((term) => {
                                                    const customerID = term.customerID;

                                                    if (`${customerID}` in blankNumber) {
                                                        //terms with this segment already exist
                                                        let terms = blankNumber[`${customerID}`].terms;
                                                        terms.push(term);

                                                        blankNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        blankNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }

                                            if (daewoo.terms.length !== 0){
                                                daewoo.terms.forEach((term) => {
                                                    const customerID = term.customerID;

                                                    if (`${customerID}` in daewooNumber) {
                                                        //terms with this segment already exist
                                                        let terms = daewooNumber[`${customerID}`].terms;
                                                        terms.push(term);

                                                        daewooNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        daewooNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }

                                            if (tml.terms.length !== 0){
                                                tml.terms.forEach((term) => {
                                                    const customerID = term.customerID;

                                                    if (`${customerID}` in tmlNumber) {
                                                        //terms with this segment already exist
                                                        let terms = tmlNumber[`${customerID}`].terms;
                                                        terms.push(term);

                                                        tmlNumber[`${customerID}`].terms = terms;
                                                    } else {
                                                        tmlNumber[`${customerID}`] = {
                                                            customerID: customerID,
                                                            terms: [term],
                                                        }
                                                    }
                                                });
                                            }

                                            const blankOverdue = blank.totalOverdue.reduce((a, b) => a + b, 0);
                                            const daewooOverdue = daewoo.totalOverdue.reduce((a, b) => a + b, 0);
                                            const tmlOverdue = tml.totalOverdue.reduce((a, b) => a + b, 0);

                                            const blankCustomers = Object.keys(blankNumber).length;
                                            const daewooCustomers = Object.keys(daewooNumber).length;
                                            const tmlCustomers = Object.keys(tmlNumber).length;

                                            dbSegmentArray.push({eqm: cheque.date, numberOfCustomersBlank: blankCustomers, blankODInUSD: blankOverdue, numberOfCustomersDaewoo: daewooCustomers, 
                                                daewooODInUSD: daewooOverdue, numberOfCustomersTML: tmlCustomers, tmlODInUSD: tmlOverdue, distinctCountOfCustomers: cheque.customers,
                                                totalSumOfODInUSD: cheque.totalOD
                                            });
                                        });
                                    }

                                    dbIndustryReport({dbSegmentArray, dispatch});
                                })
                            }

                        }).catch((error) => {
                        console.log("Here's your error");
                        console.log(error);
                        dispatch({type: ALL_REPORT_FAILED});
                    })

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED});

            })
        }
    }
};


async function getSegmentCustomers({ date, terms, info, overdueTerms, exchangeRate }){

    const segmentCustomers = {};

    terms.forEach(term => {

        const customerID = term.customerID;

        //check if customerID already exists in dates object
        if (`${customerID}` in segmentCustomers) {
            //terms with this segment already exist
            let terms = segmentCustomers[`${customerID}`].terms;
            terms.push(term);

            segmentCustomers[`${customerID}`].terms = terms;
        } else {
            segmentCustomers[`${customerID}`] = {
                terms: [term],
            }
        }
    })

    const customers = Object.keys(segmentCustomers).length;

    return getSegment({ date, terms, info, overdueTerms, customers, exchangeRate });

}


async function getSegment({ date, terms, info, overdueTerms, customers, exchangeRate }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    const buckets = {};
    //its a new industry so create new object for it
    buckets[`${"Blank"}`] = {
        segment: "Blank",
        terms: [],
        totalOverdue: [],
    }

    if (info.size !== 0){
        info.forEach(doc => {
            const data = doc.data();
            const value = data.value;

            //its a new industry so create new object for it
            buckets[`${value}`] = {
                segment: value,
                terms: [],
                totalOverdue: [],
            }
        })
    }

    terms.forEach(term => {

        let seconds;
        if ("transactionDate" in term) {
            if (term.transactionDate) {
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const endOfMonth = dueDate.format("DD-MM-YYYY");

        let amount = 0;
        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {

            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
            amount = roundAccurately(amount, 2);
        }

        let segmentID;
        if ("segment" in term) {
            //
            if(term.segment !== ""){
                segmentID = term.segment;
            } else {
                segmentID = "Blank";
            }
        } else {
            segmentID = "Blank";
        }

        //check if segmentID already exists in dates object
        if (`${segmentID}` in buckets) {
            //terms with this segment already exist
            let terms = buckets[`${segmentID}`].terms;
            terms.push(term);

            let overdue = buckets[`${segmentID}`].totalOverdue;
            overdue.push(amount);

            buckets[`${segmentID}`].terms = terms;
            buckets[`${segmentID}`].totalOverdue = overdue;
        } else {
            buckets[`${segmentID}`] = {
                totalOverdue: [amount],
                terms: [term],
            }
        }
    })

    const overdue = overdueTerms.reduce((a, b) => a + b, 0);
    const totalOD = roundAccurately(overdue, 2);

    if(!(_.isEmpty(buckets))) {
        // console.log({date: date, segments: buckets, customers: customers, OD: totalOD});

        return {date, segments: buckets, totalOD, customers};
    }

}


async function dbIndustryReport({dbSegmentArray, dispatch}){
    //

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        const infoRef = firebase.firestore().collection("system").doc("additionalInfo").collection("industry");
        const info = await infoRef.get();

        let successArray = [];

        const today = moment();


        if(systemDoc.exists){

            const systemInfo = systemDoc.data();
            const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;

            fetch(beforeUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    let industries = {};

                    //put industry values into industries object
                    if (info.size !== 0){
                        info.forEach(doc => {
                            const data = doc.data();
                            const value = data.value;

                            //its a new industry so create new object for it
                            industries[`${value}`] = {
                                industry: value,
                                terms: [],
                                termsStore: response
                            }
                        })
                    }

                    response.forEach(term => {
                        let industryID;
                        if ("industry" in term) {
                            industryID = term.industry;
                        } else {
                            industryID = "Blank";
                        }

                        //check if industryID already exists in dates object
                        if (`${industryID}` in industries) {
                            //terms with this industry already exist
                            let terms = industries[`${industryID}`].terms;
                            terms.push(term);

                            industries[`${industryID}`].terms = terms;
                        } else {
                            //its a new industry so create new object for it
                            industries[`${industryID}`] = {
                                industry: industryID,
                                terms: [term],
                                termsStore: response
                            }
                        }
                    })

                    //FIND GRAND CONTENTS
                    let sumOfODInUSD;

                    //sum of OD in usd
                    let usdTermStore = [];
                    if (response.length !== 0){
                        response.map(term => {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared
                                //do nothing
                            } else {
                                //if loan is in usd convert the overdue to default currency
                                if (term.currency === "usd") {
                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    usdTermStore.push(amount);
                                } else {
                                    //fetch system info exchange rate
                                    const exchangeRate = systemInfo.exchangeRate;

                                    //grab the total overdue
                                    let amount;
                                    if ("modulo" in term) {
                                        amount = term.amount - term.modulo;
                                    } else {
                                        amount = term.amount;
                                    }

                                    const convertedAmount = amount/exchangeRate;

                                    usdTermStore.push(convertedAmount);
                                }
                            }
                        });
                    }

                    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);

                    //for examining each cheque contents
                    if (!(_.isEmpty(industries))) {
                        ///extract date into array so it can be sorted
                        const industryArray = _.map(industries, date => date);
                        const sortedIndustries = arraySort(industryArray, "industry");

                        sortedIndustries.map(data => {
                            const industry = data.industry;
                            const terms = data.terms;

                            const res = getSumOfODWithIndustryInUSD({ systemInfo, industry, terms });
                            successArray.push(res);

                        })
                        

                        Promise.all(successArray).then((dashboardData) => {

                            // // here is our array of dashboard data
                            if(dashboardData.length !== 0){
                                dashboardData.push({ industry: "Grand Total", totalODInUsd: sumOfODInUSD });
                            }

                            exportDBSegmentAndIndustryReport({dbSegmentArray, industryDataArray: dashboardData, dispatch})
                        })
                    }

                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({type: ALL_REPORT_FAILED});

            })
        }
}


async function getSumOfODWithIndustryInUSD({ systemInfo, industry, terms }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalODInUsd = store.reduce((a, b) => a + b, 0);

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    totalODInUsd = roundAccurately(totalODInUsd, 2);

    return { totalODInUsd, industry }
}



async function exportDBSegmentAndIndustryReport({dbSegmentArray, industryDataArray, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment().format("DD/MM/YYYY");
    let anal = `DB SEGMENT REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];

   let table1 = [
       {
            A: "EOM",
            B: "NUMBER OF CUSTOMERS BLANK",
            C: "BLANK OD IN USD",
            D: "NUMBER OF CUSTOMERS DAEWOO",
            E: "DAEWOO OD IN USD",
            F: "NUMBER OF CUSTOMERS TML",
            G: "TML OD IN USD",
            H: "DISTINCT COUNT OF OF CUSTOMERS",
            I: "TOTAL SUM OF OD IN USD",
       }
   ];

   let table2 = [
       {
           A: "ROW LABELS",
           B: "SUM OF OD REPORTING USD",
       }
   ];

   if(dbSegmentArray.length !== 0){
        dbSegmentArray.forEach(data => {
            table1.push({
                A: data.eqm, 
                B: data.numberOfCustomersBlank, 
                C: data.blankODInUSD,
                D: data.numberOfCustomersDaewoo,
                E: data.daewooODInUSD, 
                F: data.numberOfCustomersTML, 
                G: data.tmlODInUSD, 
                H: data.distinctCountOfCustomers, 
                I: data.totalSumOfODInUSD, 
            })
        })
   }

   if(industryDataArray.length !== 0){
        industryDataArray.forEach(data => {
            table2.push({
                A: data.industry,
                B: data.totalODInUsd,
            })
        })
    }

   table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2)
   const finalData = [...title, ...table1];

   const wb = XLSX.utils.book_new();
   const ws = XLSX.utils.json_to_sheet(finalData, {
       skipHeader: true,
   })

   ws['!cols'] = [
       {wch:20},
       {wch:35},
       {wch:30},
       {wch:35},
       {wch:30},
       {wch:30},
       {wch:30},
       {wch:35},
       {wch:30},
       {wch:30},
       {wch:30},
   ];

   XLSX.utils.book_append_sheet(wb, ws, 'DB Segment');
   const workbookBlob = workbook2blob(wb);
   const headerIndex = [];
   const headerIndex2 = [];
   const headerIndex3 = [];

   finalData.forEach((data, index) => data['A'] === 'EOM' ? headerIndex.push(index) : null )
   finalData.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2.push(index) : null )
   finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3.push(index) : null )

   const dataInfo = {
    titleCell: 'A2',
    titleRange: 'A1:I2',
    tbodyRange: `A2:I${finalData.length}`,
    theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:I${headerIndex[0] + 1}` : null,
    theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
    theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:I${headerIndex3[0] + 1}` : null,
    theadRange4: headerIndex3.length >= 2 ? `A${headerIndex3[1] + 1}:B${headerIndex3[1] + 1}` : null,
}

    addStylesDbSegment(workbookBlob, dataInfo, dispatch);
}

const addStylesDbSegment = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'dbSegment.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })

            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}


//================================================================================= ANALYSIS GROUPED REPORTS =====================================================================================================

export const analysisGroupedBucketsReportNew = () => {

    const groupedDays = [{bucket: "1-30", ageing: 1}, {bucket: "31-60", ageing: 2}, {bucket: "61-90", ageing: 3}, {bucket: "91-120", ageing: 4},
        {bucket: "121-150", ageing: 5}, {bucket: "151-180", ageing: 6}, {bucket: "181-210", ageing: 7}, {bucket:  "211-240", ageing: 8}, {bucket: "241-270", ageing: 9},
        {bucket: "271-300", ageing: 10}, {bucket: "301-330", ageing: 11}, {bucket: "331-360", ageing: 12}, {bucket: ">360", ageing: 13}];

    return async dispatch => {
        //
        dispatch({type: ALL_REPORT});
        let successArray = [];
        let reportArray = [];

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        const systemInfo = systemDoc.data();

        const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
        fetch(beforeUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                let dates = {};

                response.forEach(term => {
                    //check if dueDateID already exists in dates object
                    if (`${term.customerID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${term.customerID}`].terms;
                        terms.push(term);
                        dates[`${term.customerID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        dates[`${term.customerID}`] = {
                            customerID: term.customerID,
                            terms: [term],
                        }
                    }
                })

                //FIND GRAND CONTENTS
                let totalCustomers;

                //sum of customers
                if(response.length !== 0){
                    let customerStore = [];
                    response.map(term => {
                        customerStore.push(term);
                    });

                    let distinctCustomers = {};

                    customerStore.map(term => {
                        if(`${term.customerID}` in distinctCustomers) {
                            //customer already in customers object
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                //grab terms from customer object
                                let terms = distinctCustomers[`${term.customerID}`].terms;
                                terms.push(term);

                                distinctCustomers[`${term.customerID}`].terms = terms;
                            }
                        } else {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                distinctCustomers[`${term.customerID}`] = {
                                    customerID: term.customerID,
                                    customerName: term.customerName,
                                    terms: [term]
                                }
                            }
                        }
                    });

                    totalCustomers = Object.keys(distinctCustomers).length;
                }


                let groupedReport = {};

                groupedDays.forEach ((data) => {
                    //
                    //check if bucketID already exists in reports object
                    if (`${data.bucket}` in groupedReport) {
                        //customer with this bucket already exist
                    } else {
                        //its a new date so create new object for it
                        groupedReport[`${data.bucket}`] = {
                            bucket: data.bucket,
                            customers: [],
                            ageing: data.ageing,
                        }
                    }
                });


                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "customerID");

                    sortedDates.map((data) => {
                        const customerID = data.customerID;
                        const terms = data.terms;

                        const res = getGroupedEarliestBucket({ customerID, terms });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((customerData) => {

                        // here is our array of dashboard data
                        if(customerData.length !== 0){
                            //
                            customerData.map(customer => {
                                //check if bucketID already exists in reports object
                                if (`${customer.bucket}` in groupedReport) {
                                    //customer with this bucket already exist
                                    let customers = groupedReport[`${customer.bucket}`].customers;
                                    customers.push(customer);
                                    groupedReport[`${customer.bucket}`].customers = customers;
                                } else {
                                    //its a new date so create new object for it
                                    groupedReport[`${customer.bucket}`] = {
                                        bucket: customer.bucket,
                                        customers: [customer],
                                    }
                                }
                            })

                            if (!(_.isEmpty(groupedReport))) {
                                ///extract groupedReport into array so it can be sorted
                                const bucketArray = _.map(groupedReport, report => report);
                                const sortedBuckets = bucketArray.sort(function(a, b){return a.ageing - b.ageing});

                                sortedBuckets.map((data) => {
                                    const bucket = data.bucket;
                                    const customers = data.customers;

                                    const res = getNumberOfCustomersOnGroupedBucket1({ bucket, customers });
                                    reportArray.push(res);

                                })


                                Promise.all(reportArray).then((dashboardData) => {

                                    // here is our array of dashboard data
                                    if(dashboardData.length !== 0){
                                        dashboardData.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers  });
                                    }

                                    callBucketWithOverduePhase2({groupedReportPhase1: dashboardData, response, systemInfo, dispatch});
                                })
                            }

                        }
                    })
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({type: ALL_REPORT_FAILED});
        })
    }
};

async function getGroupedEarliestBucket({customerID, terms}) {

    const dueDateArray = terms.map((term) => {
        let seconds;

        if ("transactionDate" in term){
            //
            term.transactionDate.seconds? (seconds = term.transactionDate.seconds): (seconds = term.transactionDate._seconds);
        } else {
            //
            term.dueDate.seconds? (seconds = term.dueDate.seconds): (seconds = term.dueDate._seconds);
        }

        return moment.unix(seconds);
    })

    const earliestMoment = _.min(dueDateArray);
    const earliestDate = earliestMoment.toDate();
    const today = moment();
    //find the number of days from today
    const days = today.diff(earliestDate, 'days');
    // const ageing = Math.round(days/30);
    // console.log({days, customerName, customerID});

    return getCustomerGroupedBucket({ customerID, terms, days });
}

async function getCustomerGroupedBucket({ customerID, terms, days }){

    let bucket = "";

    //provision starts with 1-30
    switch (true) {
        case (days >= 1 && days <= 30):
            bucket = "1-30";
            break;
        case (days >= 31 && days <= 60):
            bucket = "31-60";
            break;
        case (days >= 61 && days <= 90):
            bucket = "61-90";
            break;
        case (days >= 91 && days <= 120):
            bucket = "91-120";
            break;
        case (days >= 121 && days <= 150):
            bucket = "121-150";
            break;
        case (days >= 151 && days <= 180):
            bucket = "151-180";
            break;
        case (days >= 181 && days <= 210):
            bucket = "181-210";
            break;
        case (days >= 211 && days <= 240):
            bucket = "211-240";
            break;
        case (days >= 241 && days <= 270):
            bucket = "241-270";
            break;
        case (days >= 271 && days <= 300):
            bucket = "271-300";
            break;
        case (days >= 301 && days <= 330):
            bucket = "301-330";
            break;
        case (days >= 331 && days <= 360):
            bucket = "331-360";
            break;
        case (days > 360):
            bucket = ">360";
            break;

    }

    return {customerID, terms, days, bucket}

}

async function getNumberOfCustomersOnGroupedBucket1({ bucket, customers }){

    const numberOfCustomers = customers.length;

    return {numberOfCustomers, groupedBucket: bucket};
}


async function callBucketWithOverduePhase2({groupedReportPhase1, response, systemInfo, dispatch}){

    let successArray = [];

    if(response.length !== 0){
        let termsArray = [];
        let dates = {};

        response.forEach(term => {
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                termsArray.push(term);
            }
        });

        let sumOfODInUSD;


        //sum of OD in usd
        let usdTermStore = [];
        response.map(term => {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared
                //do nothing
            } else {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    usdTermStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;

                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    usdTermStore.push(convertedAmount);
                }
            }
        });

        sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);

        if (termsArray.length !== 0){
            termsArray.map(term => {
                //grab the date and change it into a string
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
                const dueDate = moment.unix(seconds);
                const today = moment();
                //grab the month and year
                const dueDateID = today.format("MMYYYY");

                //check if dueDateID already exists in dates object

                //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
                if (`${dueDateID}` in dates) {
                    //terms with this date already exist
                    let terms = dates[`${dueDateID}`].terms;

                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    //check if bucket already exists
                    //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                    if (`${bucket}` in terms) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = terms[`${bucket}`];

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        terms[`${bucket}`] = termBucket;

                        //assign the new objects as new values of the the existing terms object in the date object
                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        terms[`${bucket}`] = [term];
                        dates[`${dueDateID}`].terms = terms;
                    }


                    //dates[`${dueDateID}`].terms = terms;
                } else {
                    //its a new date so create new object for it
                    //calculate bucket
                    //grab the end of the month
                    //const endOfMonth = dueDate.endOf('month');

                    //grab today
                    //const today = moment();

                    //find the number of days from today
                    const fromNow = today.diff(dueDate, 'days');

                    //find the bucket
                    let bucket = 1;
                    switch (true) {
                        case (fromNow >= 1 && fromNow <= 30):
                            bucket = 1;
                            break;
                        case (fromNow >= 31 && fromNow <= 60):
                            bucket = 2;
                            break;
                        case (fromNow >= 61 && fromNow <= 90):
                            bucket = 3;
                            break;
                        case (fromNow >= 91 && fromNow <= 120):
                            bucket = 4;
                            break;
                        case (fromNow >= 121 && fromNow <= 150):
                            bucket = 5;
                            break;
                        case (fromNow >= 151 && fromNow <= 180):
                            bucket = 6;
                            break;
                        case (fromNow >= 181 && fromNow <= 210):
                            bucket = 7;
                            break;
                        case (fromNow >= 211 && fromNow <= 240):
                            bucket = 8;
                            break;
                        case (fromNow >= 241 && fromNow <= 270):
                            bucket = 9;
                            break;
                        case (fromNow >= 271 && fromNow <= 300):
                            bucket = 10;
                            break;
                        case (fromNow >= 301 && fromNow <= 330):
                            bucket = 11;
                            break;
                        case (fromNow >= 331 && fromNow <= 360):
                            bucket = 12;
                            break;
                        case (fromNow > 360):
                            bucket = 13;
                            break;
                    }

                    let terms = {};
                    terms[`${bucket}`] = [term];

                    dates[`${dueDateID}`] = {
                        date: dueDate,
                        terms,
                        termsArray
                    }
                }
            });
        }

        //sort the dates array
        const datesArray = _.map(dates, date => date);
        const sortedDates = arraySort(datesArray, "date");

        if(sortedDates.length !== 0){
            sortedDates.forEach(date => {
                _.map(date.terms, (terms, key) => {
                    // console.log({ageing: key, terms});
                    const response = findBucketOD({ageing: key, terms, systemInfo});
                    successArray.push(response);
                });
            })
        }

        //
        Promise.all(successArray).then((dashboardData) => {

            //here is our array of dashboard data
            if(dashboardData.length !== 0){
                dashboardData.push({ groupedBucket: "Grand Total", totalOverdue: sumOfODInUSD  });
            }

            callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2: dashboardData, response, systemInfo, dispatch});
        })
    }
}

async function findBucketOD({ageing, terms, systemInfo}) {

    // const dates  = {};
    let amount = 0;
    let overdue = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];

    terms.forEach(term => {

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    overdue = roundAccurately(sumOfODInUSD, 2);

    let bucket = "";

    switch (ageing) {
        case "1":
            bucket = "1-30";
            break;
        case "2":
            bucket = "31-60";
            break;
        case "3":
            bucket = "61-90";
            break;
        case "4":
            bucket = "91-120";
            break;
        case "5":
            bucket = "121-150";
            break;
        case "6":
            bucket = "151-180";
            break;
        case "7":
            bucket = "181-210";
            break;
        case "8":
            bucket = "211-240";
            break;
        case "9":
            bucket = "241-270";
            break;
        case "10":
            bucket = "271-300";
            break;
        case "11":
            bucket = "301-330";
            break;
        case "12":
            bucket = "331-360";
            break;
        case "13":
            bucket = ">360";
            break;
    }

    return {ageing, groupedBucket: bucket, totalOverdue: overdue};
}

async function callBucketGroupedPhase3({groupedReportPhase1, groupedReportPhase2, response, systemInfo, dispatch}) {
        //
        let successArray = [];
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        try {
            let buckets = {};
            response.forEach(term => {
                let seconds;
                if ("transactionDate" in term) {
                    if (term.transactionDate) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }

                const dueDate = moment.unix(seconds);

                const endOfMonth = dueDate.endOf('month');

                //grab end of month of next month
                const today = moment();
                const nextMonth = today.add(1, 'month');
                const endOfNextMonth = nextMonth.endOf('month');

                //find the number of days from today
                let fromNow = endOfNextMonth.diff(endOfMonth, 'days');
                fromNow = Math.round(fromNow/30);

                let bucket = '';
                switch (true) {
                    case (fromNow === 1):
                        bucket = 1;
                        break;
                    case (fromNow === 2):
                        bucket = 2;
                        break;
                    case (fromNow === 3):
                        bucket = 3;
                        break;
                    case (fromNow === 4):
                        bucket = 4;
                        break;
                    case (fromNow === 5):
                        bucket = 5;
                        break;
                    case (fromNow === 6):
                        bucket = 6;
                        break;
                    case (fromNow === 7):
                        bucket = 7;
                        break;
                    case (fromNow === 8):
                        bucket = 8;
                        break;
                    case (fromNow === 9):
                        bucket = 9;
                        break;
                    case (fromNow === 10):
                        bucket = 10;
                        break;
                    case (fromNow === 11):
                        bucket = 11;
                        break;
                    case (fromNow === 12):
                        bucket = 12;
                        break;
                    case (fromNow >= 13):
                        bucket = 13;
                        break;
                }

                if(bucket !== undefined && bucket !== null && bucket !== ''){
                    if (`${bucket}` in buckets) {
                        //grab the bucket from terms object and assign to new array (new array will have existing terms)
                        let termBucket = buckets[`${bucket}`].termBucket;

                        //push new term to new bucket array
                        termBucket.push(term);

                        //assign the bucket property in the original terms object with the new created termBucket
                        buckets[`${bucket}`].termBucket = termBucket;
                    } else {
                        buckets[`${bucket}`] = {
                            bucket: bucket,
                            termBucket: [term]
                        };
                    }
                }
            })

            //FIND GRAND CONTENTS
            let totalCustomers;
            let totalODArr = [];
            if(response.length !== 0){
                let customerStore = [];
                let amount = 0;
                let distinctCustomers = {};
                response.map(term => {
                    //
                    customerStore.push(term);

                    if (term.currency === "usd"){
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        totalODArr.push(amount);

                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        amount = amount/exchangeRate;

                        totalODArr.push(amount);
                    }

                });

                customerStore.map(term => {
                    if(`${term.customerID}` in distinctCustomers) {
                        //customer already in customers object
                        //check that term is not cleared
                        if (term.termStatus.status) {
                            //term is cleared do nothing
                        } else {
                            //grab terms from customer object
                            let terms = distinctCustomers[`${term.customerID}`].terms;

                            distinctCustomers[`${term.customerID}`].terms = terms;
                        }
                    } else {
                        //check that term is not cleared
                        if (term.termStatus.status) {
                            //term is cleared do nothing
                        } else {
                            distinctCustomers[`${term.customerID}`] = {
                                customerID: term.customerID,
                                customerName: term.customerName,
                                terms: [term]
                            }
                        }
                    }
                });

                totalCustomers = Object.keys(distinctCustomers).length;
            }


            let debtTotal = totalODArr.reduce((a, b) => a + b, 0);
            debtTotal = roundAccurately(debtTotal, 2);

            if (!(_.isEmpty(buckets))) {
                //sort the dates array
                const bucketsArray = _.map(buckets, buck => buck);
                const sortedBuckets = arraySort(bucketsArray, "bucket");

                if(sortedBuckets.length !== 0){
                    sortedBuckets.forEach(date => {
                        const response = getGroupedBucket({ageing: date.bucket, terms: date.termBucket, systemInfo});
                        successArray.push(response);
                    })
                }
            }

                Promise.all(successArray).then((dashboardData) => {
                    let value = dashboardData.filter(function (el) { return el != null; });

                    // here is our array of dashboard data
                    if(value.length !== 0){

                        value.push({ groupedBucket: "Grand Total", numberOfCustomers: totalCustomers, debtTotal});
                    }

                    exportBucketGrouped({value, groupedReportPhase1, groupedReportPhase2, dispatch});
                })
        } catch (e) {
            console.log(e);
            dispatch({type: ALL_REPORT_FAILED})
        }
}

async function getGroupedBucket({ ageing, terms, systemInfo }){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    // const dates  = {};
    let amount = 0;
    let sumOfODInUSD = 0;
    let usdTermStore = [];
    let customerBucket = {};

    terms.forEach(term => {

        const customerID = term.customerID;

        if(`${term.customerID}` in customerBucket) {
            //grab terms from customer object
            let terms = customerBucket[`${customerID}`].terms;
            terms.push(term);

            customerBucket[`${customerID}`].terms = terms;
        } else {
            //check that term is not cleared
            customerBucket[`${customerID}`] = {
                customerID: customerID,
                terms: [term]
            }
        }

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            usdTermStore.push(amount);

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;

            usdTermStore.push(amount);
        }
    });

    //find bucket
    let bucket = "";

    switch (ageing) {
        case 1:
            bucket = "1-30";
            break;
        case 2:
            bucket = "31-60";
            break;
        case 3:
            bucket = "61-90";
            break;
        case 4:
            bucket = "91-120";
            break;
        case 5:
            bucket = "121-150";
            break;
        case 6:
            bucket = "151-180";
            break;
        case 7:
            bucket = "181-210";
            break;
        case 8:
            bucket = "211-240";
            break;
        case 9:
            bucket = "241-270";
            break;
        case 10:
            bucket = "271-300";
            break;
        case 11:
            bucket = "301-330";
            break;
        case 12:
            bucket = "331-360";
            break;
        case 13:
            bucket = ">360";
            break;
    }

    sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
    const debtTotal = roundAccurately(sumOfODInUSD, 2);

    const numberOfCustomers = Object.keys(customerBucket).length;

    return { numberOfCustomers, debtTotal, groupedBucket: bucket, terms};

}

async function exportBucketGrouped({value, groupedReportPhase1, groupedReportPhase2, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }
    const today = moment().format("DD/MM/YYYY");
    let anal = `ANALYSIS REPORT ${today}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "BUCKET",
            B: "NUMBER OF CUSTOMERS",
        }
    ];

    let table2 = [
        {
            A: "BUCKET",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    let table3 = [
        {
            A: "BUCKET GROUPED",
            B: "NUMBER OF CUSTOMERS",
        }
    ];

    let table4 = [
        {
            A: "BUCKET GROUPED",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    value.forEach(data => {
        table1.push({
            A: data.groupedBucket,
            B: data.numberOfCustomers,
        })
    })

    value.forEach(data => {
        table2.push({
            A: data.groupedBucket,
            B: data.debtTotal,
        })
    })

    groupedReportPhase1.forEach(data => {
        table3.push({
            A: data.groupedBucket,
            B: data.numberOfCustomers,
        })
    })

    groupedReportPhase2.forEach(data => {
        table4.push({
            A: data.groupedBucket,
            B: data.totalOverdue,
        })
    })


    table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table4);
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Analysis');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];


    finalData.forEach((data, index) => data['A'] === 'BUCKET' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'BUCKET GROUPED' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex2.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:B2',
        tbodyRange: `A2:B${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:B${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex.length >= 2 ? `A${headerIndex[1] + 1}:B${headerIndex[1] + 1}` : null,
        theadRange2: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:B${headerIndex1[0] + 1}` : null,
        theadRange3: headerIndex1.length >= 2 ? `A${headerIndex1[1] + 1}:B${headerIndex1[1] + 1}` : null,
        theadRange4: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
        theadRange5: headerIndex2.length >= 2 ? `A${headerIndex2[1] + 1}:B${headerIndex2[1] + 1}` : null,
        theadRange6: headerIndex2.length >= 3 ? `A${headerIndex2[2] + 1}:B${headerIndex2[2] + 1}` : null,
        theadRange7: headerIndex2.length >= 4 ? `A${headerIndex2[3] + 1}:B${headerIndex2[3] + 1}` : null,
    }

    addStylesAnalysis(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, `Analysis_Report_${today}.xlsx`);
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesAnalysis = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange5).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange6).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange7).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'analysis.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


//==============================================================================================  COLLECTION TRACKER  ===========================================================================================

export const collectionTrackerReportNew = () => {

    return async dispatch => {
        dispatch({type: ALL_REPORT});
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        const systemInfo = systemDoc.data();
        const exchangeRate = systemInfo.exchangeRate;

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        //fetch end of last month overdue terms
        const date = moment().subtract(1, "months").endOf('month').format("DD_MM_YYYY");
        console.log(date);

        //get specific date overdue data
        const reportID = `masterlistNew_${date}`;

        const info = JSON.stringify({ reportID, path: "masterListNew" });

        //invoke custom database function
        const url1 = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url1, {
            method: 'POST',
            mode: 'cors',
            body: info,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                //find the total overdue as of end of last month
                let termsStore = [];

                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        //term has any of repossess status
                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            //if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                termsStore.push(amount);
                            } else {
    
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                const convertedAmount = amount/exchangeRate;
    
                                termsStore.push(convertedAmount);
                            }
                        }
                    });
                })

                //CALCULATE OPENING OD
                let openingOverdue = termsStore.reduce((a, b) => a + b, 0);
                    openingOverdue = roundAccurately(openingOverdue, 2);

                //FETCH ANALYTICS DATA
                const url = `${project.serverUrl}fetchAnalytics`;
                fetch(url, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {'Content-Type': 'application/json'},
                }).then((response) => response.json())
                    .then((data) => {

                        //for examining each cheque contents
                        if (!(_.isEmpty(data))) {

                            console.log({analytics: data});

                            let overdueArray = [];
                            const month = moment().startOf('month').format("DD-MM-YYYY");

                            data["openingOD"] = openingOverdue;
                            console.log(data)

                            overdueArray.push({value: openingOverdue, label: `Opening OD as of ${month}`, key: "1"});

                            _.map(data, (value, key) => {

                                switch (key) {
                                    case "totalOverdue":
                                        let overdue = value/exchangeRate;
                                        overdue = roundAccurately(overdue, 2);
                                        overdueArray.push({value: overdue, label: "Current OD per current month", key: "2"});
                                        break;

                                    case "bouncedTd":
                                        let bouncedOverdue = value/exchangeRate;
                                        bouncedOverdue = roundAccurately(bouncedOverdue, 2);
                                        overdueArray.push({value: bouncedOverdue, label: "Bounces for the month", key: "3"});
                                        break;
                                }
                            });


                            const bouncedTd = data.bouncedTd/exchangeRate;
                            let totalOverdue = openingOverdue + bouncedTd;

                            totalOverdue = roundAccurately(totalOverdue, 2);
                            overdueArray.push({value: totalOverdue, label: "Total OD", key: "4"});

                            // const currentMonthOD = data.totalOverdue/exchangeRate;
                            const monthCollections = data.odCollTd/exchangeRate;

                            // const monthCollections = totalOverdue - currentMonthOD;


                            ///extract date into array so it can be sorted
                            if (overdueArray.length !== 0){
                                overdueArray = arraySort(overdueArray, "key");

                                overdueArray.push({ label: "COLLECTIONS FOR THE MONTH", value: monthCollections });

                            }

                            collectionTrackerReportOne11({collectionTracker: overdueArray, analytics: data, exchangeRate, dispatch});

                        }

                    })

            })
            .catch(e => {
                console.log(e);
                dispatch({type: ALL_REPORT_FAILED});
            })

    }
};

async function collectionTrackerReportOne11({collectionTracker, analytics, exchangeRate, dispatch}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let overdueArray = [];

    //for examining each cheque contents
    if (!(_.isEmpty(analytics))) {

        //initial values
        const currentOD = analytics.totalOverdue/exchangeRate;
        const tc = analytics.tc/exchangeRate;
        const pdcTD = analytics.pdcTd/exchangeRate;
        const bouncedTD = analytics.bouncedTd/exchangeRate;
        const openingOD = analytics.openingOD;
        const pdcFtm  = analytics.pdcFtm/exchangeRate;

        //due collections
        let dueCollection = pdcTD - bouncedTD;
        const dcRatio = (dueCollection/pdcTD) * 100;
        const dueCollectionRatio = roundAccurately(dcRatio, 2);
        dueCollection = roundAccurately(dueCollection, 2);

        overdueArray.push({amount: dueCollection, description: "Due Collections", ratio: `${dueCollectionRatio}%`, key: "1"});

        //overdue collections
        let overdueCollection = analytics.odCollTd/exchangeRate;
        const ocRatio = (overdueCollection/currentOD) * 100;
        const overdueCollectionRatio = roundAccurately(ocRatio, 2);
        overdueCollection = roundAccurately(overdueCollection, 2);

        overdueArray.push({amount: overdueCollection, description: "Overdue Collections", ratio: `${overdueCollectionRatio}%`, key: "2"});

        //collections efficiency
        let collectionEfficiency = analytics.tc/exchangeRate;
        const ceRatio = (tc/pdcTD) * 100;
        const collectionEfficiencyRatio = roundAccurately(ceRatio, 2);
        collectionEfficiency = roundAccurately(collectionEfficiency, 2);

        overdueArray.push({amount: collectionEfficiency, description: "Collections Efficiency", ratio: `${collectionEfficiencyRatio}%`, key: "3"});

        //total collections
        let totalCollection = openingOD + pdcTD;
        const tcRatio = (tc/totalCollection) * 100;
        const totalCollectionRatio = roundAccurately(tcRatio, 2);
        totalCollection = roundAccurately(totalCollection, 2);

        overdueArray.push({amount: totalCollection, description: "Total Collections", ratio: `${totalCollectionRatio}%`, key: "4"});


        ///extract date into array so it can be sorted
        if (overdueArray.length !== 0){
            overdueArray = arraySort(overdueArray, "key");
            overdueArray.push({ description: "", amount: "", ratio: "" });
        }

        // console.log({OD: analytics.openingOD});
        return collectionTrackerReportOne({collectionTracker, collectionTracker1: overdueArray, analytics, exchangeRate, overdueOpening: analytics.openingOD, dispatch});
    }
}

async function collectionTrackerReportOne({collectionTracker, collectionTracker1, analytics, exchangeRate, overdueOpening, dispatch}){

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let report = [];

    if (!(_.isEmpty(analytics))) {
        //{ date, totalOverdue }
        // console.log({analytics: analytics});
        let row = {};

        const today = moment();

        const pdcTD = analytics.pdcTd/exchangeRate;
        const bouncedTD = analytics.bouncedTd/exchangeRate;
        const pdcFTM = analytics.pdcFtm/exchangeRate;
        const openingOD = analytics.openingOD;
        const bouncedForTheMonth = analytics.bouncedTd/exchangeRate;

        let pdcBouncedTD = pdcTD - bouncedTD;
        pdcBouncedTD = roundAccurately(pdcBouncedTD, 2);

        const totalCollReqTarget = roundAccurately(pdcFTM, 2);

        const totalOverdue = openingOD + bouncedForTheMonth;
        let totalODReqTarget = totalOverdue * 0.5;
        totalODReqTarget = roundAccurately(totalODReqTarget, 2);

        row["month"]  = today.endOf('month').format("DD/MM/YYYY");
        row["pdcBouncedTD"] = pdcBouncedTD;
        row["totalODReqTarget"] = totalODReqTarget;
        row["totalCollReqTarget"] = totalCollReqTarget;
        row["tally"] = "";

        _.map(analytics, (value, key) => {

            switch (key) {
                case "totalOverdue":
                    row["totalOverdue"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "pdcFtm":
                    row["pdcFtm"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "pdcTd":
                    row["pdcTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "bouncedTd":
                    row["bouncedTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "odCollTd":
                    row["odCollTd"] = roundAccurately(value/exchangeRate, 2);
                    break;
                case "tc":
                    row["tc"] = roundAccurately(value/exchangeRate, 2);
                    break;
            }
        });

        report = [row];
    }

    return getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2: report, analytics, exchangeRate, overdueOpening, dispatch});
}


async function getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2, analytics, exchangeRate, overdueOpening, dispatch}) {

    let successArray = [];

    const getDaysOfMonth = function() {
        let daysInMonth = moment().format('DD');
        let arrDays = [];

        while(daysInMonth) {
            const current = moment().date(daysInMonth);
            arrDays.push({date: current.format('MM-DD-YYYY'), timeStamp: current});
            daysInMonth--;
        }

        return arrDays;
    };

    const dateList = getDaysOfMonth();

    analytics["currency"] = "localCurrency";

    //fetch current overdue terms
    const beforeUrl = `${project.serverUrl}fetchMasterListNewUpdate`;
    fetch(beforeUrl, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            // console.log(response);
            const overdue = response;

            //check if both current analytics and overdue terms objects are empty
            if (!(_.isEmpty(analytics))) {

                if (dateList.length !== 0){
                    console.log(dateList);
                    //loop date list
                    dateList.forEach((date) => {
                        const response = getCollectionTrackerAnalyticsData({date: date.date, timeStamp: date.timeStamp, analyticsData: analytics, overdueTerms: overdue, exchangeRate, overdueOpening});
                        successArray.push(response);
                    });
                        //
                        Promise.all(successArray).then((dashboardData) => {
                            //here is our array of dashboard data
                            if(dashboardData.length !== 0){
                                // /* create a new blank workbook */
                                const wb = XLSX.utils.book_new();
                                //create report bucket to store all the excel rows as objects
                                let report = [];

                                dashboardData.forEach(day => {
                                    let termRow = {};

                                    termRow["newDate"] = day.newDate;
                                    termRow["totalBook"] = day.totalBook;
                                    termRow["dueCollections"] = day.dueCollections;
                                    termRow["cumulativeCollections"] = day.cumulativeCollections;
                                    termRow["dailyCollections"] = day.dailyCollections;
                                    termRow["collectionEfficiency"] = day.collectionEfficiency;
                                    termRow["repoNumber"] = "";
                                    termRow["repoValue"] = "";
                                    termRow["legalCases"] = day.legalCases;
                                    termRow["legalValue"] = day.legalValue;
                                    termRow["overdue"] = day.overdue;
                                    termRow["deliquencyRate"] = day.deliquencyRate;
                                    termRow["bucket1"] = day.bucket1;
                                    termRow["bucket2"] = day.bucket2;
                                    termRow["bucket3"] = day.bucket3;
                                    termRow["bucket4"] = day.bucket4;
                                    termRow["overdueTarget"] = day.overdueTarget;
                                    termRow["overdueActual"] = day.overdueActual;
                                    termRow["legalRepo"] = day.legalRepo;
                                    termRow["insuranceCase"] = day.insuranceCase;
                                    termRow["repoProgress"] = day.repoProgress;
                                    termRow["promisePay"] = day.promisePay;
                                    termRow["watchlist"] = day.watchlist;
                                    termRow["caseOpening"] = day.caseOpening;
                                    termRow["requestCase"] = day.requestCase;
                                    termRow["noStatusValue"] = day.noStatusValue;
                                    termRow["underFollowUpValue"] = day.underFollowUpValue;
                                    termRow["partialReposessionValue"] = day.partialReposessionValue;
                                    termRow["nonStarterValue"] = day.nonStarterValue;


                                    report.push(termRow);
                                })

                            exportCollectionTrackerData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3: report, dispatch});

                        }
                    })
                }

            } else {
                console.log("There are no data");
            }
        }).catch((error) => {
        console.log("Here's your error 1");
        console.log(error);
        dispatch({type: ALL_REPORT_FAILED})
    })
}

async function getCollectionTrackerAnalyticsData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){

    const today = moment().format("MM-DD-YYYY");
    if (date !== today){
        //get specific date analytics data
        const reportDate = timeStamp.format("DD_MM_YYYY");
        const reportID = `analytics_${reportDate}`;
        const analyticsRef = firebase.firestore().collection('analyticsDailyReports').doc(reportID);
        const doc = await analyticsRef.get();

        if (doc.exists) {
            // console.log({analytics: data});
            analyticsData = doc.data();
        }
    }

    return getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}

async function getCollectionTrackerOverdueData({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}){


    const today = moment().format("MM-DD-YYYY");

    if (date !== today){
        let termsStore = [];
        //get specific date overdue data
        const dateID = timeStamp.format("DD_MM_YYYY");
        const reportID = `masterlistNew_${dateID}`;

        const data = JSON.stringify({ reportID, path: "masterListNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        await fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        termsStore.push(term);
                    });
                })
            })
            .catch(e => {
                console.log(e);
            })

        //assign overdue terms
        if (termsStore.length !== 0){
            overdueTerms = termsStore;
        }
    }

    // console.log({date: date, overdueTerms: overdueTerms});
    return getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}


async function getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let totalBook = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            totalBook = analyticsData.totalBook;
            totalBook = totalBook/exchangeRate;
            totalBook = roundAccurately(totalBook, 2);
        } else {
            totalBook = analyticsData.totalBook;
            totalBook = roundAccurately(totalBook, 2);
        }
    } else {
        totalBook = analyticsData.totalBook;
        totalBook = roundAccurately(totalBook, 2);
    }

    // console.log({date: date, totalBook: totalBook});

    return getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening});
}

async function getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dueCollections = 0;

    if ("currency" in analyticsData){
        if (analyticsData.currency === "localCurrency"){
            dueCollections = analyticsData.pdcTd;
            dueCollections = dueCollections/exchangeRate;
            dueCollections = roundAccurately(dueCollections, 2);
        } else {
            dueCollections = analyticsData.pdcTd;
            dueCollections = roundAccurately(dueCollections, 2);
        }
    } else {
        dueCollections = analyticsData.pdcTd;
        dueCollections = roundAccurately(dueCollections, 2);
    }

    return getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening });
}

async function getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening }) {
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let cumulativeCollections = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = cumulativeCollections/exchangeRate;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        } else {
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        }
    } else {
        cumulativeCollections = analyticsData.tc;
        cumulativeCollections = roundAccurately(cumulativeCollections, 2);
    }


    return getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening});
}

async function getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening}) {
    //get daily collections
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dailyCollections = 0;
    let collectionArray = [];

    const collectionRef = firebase.firestore().collection('cashCollections');
    const snapshot = await collectionRef.get();

    if (snapshot.size !== 0) {
        snapshot.forEach(doc => {
            const data = doc.data();

            let seconds;
            data.bankDate.seconds ? seconds = data.bankDate.seconds :  seconds = data.bankDate._seconds;

            const bankDate = moment.unix(seconds).format("MM-DD-YYYY");
            // const tday = moment();

            // if(date === bankDate){
                if(moment(bankDate).isSame(timeStamp, "day")){
                    console.log(bankDate);
                    if (data.currency === "usd") {
                        //grab the paid amount
                        collectionArray.push(data.paidAmount);
    
                    } else {
    
                        const amount = data.paidAmount/exchangeRate;
                        collectionArray.push(amount);
                    }
                }
            // }
        })
    }

    //find sum of daily collections
    dailyCollections = collectionArray.reduce((a, b) => a + b, 0);
    dailyCollections = roundAccurately(dailyCollections, 2);

    // console.log({date, totalBook: totalBook, dueCollections: dueCollections, cumulativeCollections: cumulativeCollections, collection: dailyCollections});

    return getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening});
}

async function getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening}) {
    //find collection efficiency
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let collectionEfficiency = 0;
    collectionEfficiency = cumulativeCollections/dueCollections;
    collectionEfficiency = roundAccurately(collectionEfficiency, 1);

    return getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening});
}

async function getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening}) {
    //
    let repoNumber = 0;
    let repoBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in repoBucket) {
                        //terms with this customerID already exist
                        let terms = repoBucket[`${customerID}`].terms;
                        terms.push(term);

                        repoBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        repoBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(repoBucket))){
        repoNumber = Object.keys(repoBucket).length;
    }

    // console.log({repoNumber: repoNumber});

    return getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening});
}

async function getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening}) {
    //
    let repoValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        repoValue = termStore.reduce((a, b) => a + b, 0);
        repoValue = roundAccurately(repoValue, 2);
    }

    // console.log({repoValue: repoValue});

    return getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        overdueOpening});
}

async function getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                      overdueOpening}) {
    //
    let legalCases = 0;
    let legalBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                    //term has any of legal status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in legalBucket) {
                        //terms with this customerID already exist
                        let terms = legalBucket[`${customerID}`].terms;
                        terms.push(term);

                        legalBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        legalBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(legalBucket))){
        legalCases = Object.keys(legalBucket).length;
    }

    // console.log({legalCases: legalCases});

    return getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, overdueOpening});
}

async function getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                  legalCases, overdueOpening}) {
   //
    let legalValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        legalValue = termStore.reduce((a, b) => a + b, 0);
        legalValue = roundAccurately(legalValue, 2);
    }

    // console.log({legalValue: legalValue});

    return getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdueOpening});
}

async function getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                   legalCases, legalValue, overdueOpening}) {
   //
    let overdue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if (!term.termStatus.status){
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    termStore.push(amount);
                } else {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    const convertedAmount = amount/exchangeRate;

                    termStore.push(convertedAmount);
                }
            }
        })
    }

    if(termStore.length !== 0){
        overdue = termStore.reduce((a, b) => a + b, 0);
        overdue = roundAccurately(overdue, 2);
    }


    return getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, overdueOpening});
}

async function getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                       legalCases, legalValue, overdue, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    const dRate = overdue/totalBook * 100;
    const deliquencyRate = roundAccurately(dRate, 2);

    return getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, overdueOpening});
}

async function getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                  legalCases, legalValue, overdue, deliquencyRate, overdueOpening}) {
    //
    let b1 = 0;
    let b2 = 0;
    let b3 = 0;
    let b4 = 0;

    let arr = [];
    let arr1 = [];
    let arr2 = [];
    let arr3 = [];
    let arr4 = [];
    let arr5 = [];
    let arr6 = [];
    let arr7 = [];
    let arr8 = [];
    let arr9 = [];
    let arr10 = [];
    let arr11 = [];
    let arr12 = [];

if(overdueTerms.length !== 0){
    let terms = {};
    overdueTerms.forEach(term => {
        
        let seconds;
        if ("transactionDate" in term) {
            if(term.transactionDate){
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
            }else{
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
        }


        //grab end of month of next month
        const dueDate = moment.unix(seconds);
        const endMonth = dueDate.endOf('month');

        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');
    
        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endMonth, 'days');
    
        const bucket = Math.round(fromNow/30);
    
        let daysRange = "(0)";
        //compute date range depending on bucket
        if(bucket !== 0) {
            const endDate = bucket * 30;
            const startDate = endDate - 29;

            daysRange = `(${startDate}-${endDate})`
        }

        if(bucket !== undefined && bucket !== null){
            if (`${daysRange}` in terms) {
                let termBucket = terms[`${daysRange}`].terms;
                termBucket.push(term);
                terms[`${daysRange}`].terms = termBucket;

            } else {
                terms[`${daysRange}`] = {
                    range: daysRange,
                    terms: [term]
                }
            }
        }
    })


    const termsArr = _.map(terms, client => client );

    termsArr.forEach(term => {
        if(term.range === "(1-30)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr.push(amount);
            })
        }
        
        if(term.range === "(31-60)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr1.push(amount);
            })
        }
        
        if(term.range === "(61-90)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr2.push(amount);
            })
        }
        
        if(term.range === "(91-120)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr3.push(amount);
            })
        }
        
        if(term.range === "(121-150)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr4.push(amount);
            })
        }
        
        if(term.range === "(151-180)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr5.push(amount);
            })
        }
        
        if(term.range === "(181-210)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr6.push(amount);
            })
        }

        if(term.range === "(211-240)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr7.push(amount);
            })
        }
        
        if(term.range === "(241-270)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr8.push(amount);
            })
        }

        if(term.range === "(271-300)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr9.push(amount);
            })
        }
        
        if(term.range === "(301-330)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr10.push(amount);
            })
        }
        
        if(term.range === "(331-360)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr11.push(amount);
            })
        } 
        
        if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
            term.terms.forEach(term => {
                let amount;
                if (term.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                } else {
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }

                    amount = amount/exchangeRate;
                }

                arr12.push(amount);
            })
        }
    })

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let bucket1 = arr.reduce((a, b) => a + b, 0);
    bucket1 = roundAccurately(bucket1, 2);
    let bucket2 = arr1.reduce((a, b) => a + b, 0);
    bucket2 = roundAccurately(bucket2, 2);
    let bucket3 = arr2.reduce((a, b) => a + b, 0);
    bucket3 = roundAccurately(bucket3, 2);
    let bucket4 = arr3.reduce((a, b) => a + b, 0);
    bucket4 = roundAccurately(bucket4, 2);
    let bucket5 = arr4.reduce((a, b) => a + b, 0);
    bucket5 = roundAccurately(bucket5, 2);
    let bucket6 = arr5.reduce((a, b) => a + b, 0);
    bucket6 = roundAccurately(bucket6, 2);
    let bucket7 = arr6.reduce((a, b) => a + b, 0);
    bucket7 = roundAccurately(bucket7, 2);
    let bucket8 = arr7.reduce((a, b) => a + b, 0);
    bucket8 = roundAccurately(bucket8, 2);
    let bucket9 = arr8.reduce((a, b) => a + b, 0);
    bucket9 = roundAccurately(bucket9, 2);
    let bucket10 = arr9.reduce((a, b) => a + b, 0);
    bucket10 = roundAccurately(bucket10, 2);
    let bucket11 = arr10.reduce((a, b) => a + b, 0);
    bucket11 = roundAccurately(bucket11, 2);
    let bucket12 = arr11.reduce((a, b) => a + b, 0);
    bucket12 = roundAccurately(bucket12, 2);
    let bucket13 = arr12.reduce((a, b) => a + b, 0);
    bucket13 = roundAccurately(bucket13, 2);

    b1 = bucket1 + bucket2 + bucket3;
    b2 = bucket4 + bucket5 + bucket6;
    b3 = bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12;
    b4 = bucket13;
}

    return getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1: b1, bucket2: b2, bucket3: b3, bucket4: b4, overdueOpening});
}

async function getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueOpening}) {
    //
    let overdueTarget = 0;
    // console.log({overdue: overdueOpening});

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            const bouncedForTheMonth = analyticsData.bouncedTd/exchangeRate;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        } else {
            const bouncedForTheMonth = analyticsData.bouncedTd;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        }
    } else {
        const bouncedForTheMonth = analyticsData.bouncedTd;
        const totalOverdue = overdueOpening + bouncedForTheMonth;
        const totalODReqTarget = totalOverdue * 0.5;
        overdueTarget = roundAccurately(totalODReqTarget, 2);
    }


    return getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget});
}

async function getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                         legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget}) {
    //
    let overdueActual = 0;
    // let termsStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            overdueActual = analyticsData.odCollTd;
            overdueActual = overdueActual/exchangeRate;
            overdueActual = roundAccurately(overdueActual, 2);
        } else {
            overdueActual = analyticsData.odCollTd;
            overdueActual = roundAccurately(overdueActual, 2);
        }
    } else {
        overdueActual = analyticsData.odCollTd;
        overdueActual = roundAccurately(overdueActual, 2);
    }

    return getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual});
}

async function getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                          legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let legalOrRepo = [];
    let watchList = [];
    let insurance = [];
    let promiseToPay = [];
    let repoInProgress = [];
    let caseOpeningInProgress = [];
    let requestToOpenCase = [];
    let underFollowUp = [];
    let noStatus = [];
    let partialReposession = [];
    let nonStarter = [];

    let above90Terms = [];                                        
    overdueTerms.forEach((term) => {
        let seconds;
        if ("transactionDate" in term) {
            if (term.transactionDate) {
                term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
        } else {
            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
        }

        const dueDate = moment.unix(seconds);
        const endMonth = dueDate.endOf('month');

        const today = moment();
        const nextMonth = today.add(1, 'month');
        const endOfNextMonth = nextMonth.endOf('month');
    
        //find the number of days from today
        const fromNow = endOfNextMonth.diff(endMonth, 'days');

        if(fromNow > 90){

            above90Terms.push(term);
        }
    })

    let statusTerms = {};
    above90Terms.forEach(emi => {
        if("legalRepoStatus" in emi){
            if (`${emi.legalRepoStatus}` in statusTerms) {
                let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
                statusBucket.push(emi);
        
                statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
            } else {
                statusTerms[`${emi.legalRepoStatus}`] = [emi];
            }
        }else{
            emi['legalRepoStatus'] = "No Status";
            if (`${emi.legalRepoStatus}` in statusTerms) {
                let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
        
                statusBucket.push(emi);
        
                statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
        
            } else {
                statusTerms[`${emi.legalRepoStatus}`] = [emi];
            }
        }
    })


    let legalRepoStatusArray = [];
    let totalOverdueArr = [];
    const termsArr = _.map(statusTerms, client => client );
    termsArr.forEach((loanTerms) => {
        loanTerms.forEach(loanTerm => {
            let amount;
            if (loanTerm.currency === "usd") {
                //grab the total overdue
                if ("modulo" in loanTerm) {
                    amount = loanTerm.amount - loanTerm.modulo;
                } else {
                    amount = loanTerm.amount;
                }

            } else {
                if ("modulo" in loanTerm) {
                    amount = loanTerm.amount - loanTerm.modulo;
                } else {
                    amount = loanTerm.amount;
                }

                amount = amount/exchangeRate;
            }
            totalOverdueArr.push(amount);
            legalRepoStatusArray.push({name: loanTerm.legalRepoStatus, value: amount});
        })
    })
    console.log({legalRepoStatusArray: legalRepoStatusArray});
    let resultObject = {};

    legalRepoStatusArray.forEach(element => {
        if (resultObject.hasOwnProperty(element.name)) {
            resultObject[element.name] = resultObject[element.name] + element.value;
        } else {
            resultObject[element.name] = element.value;
        }
    });

    let resultArray = [];

    for (let prop in resultObject) {
        resultArray.push({ name: prop, value: resultObject[prop] });
    }

    let totalOv = totalOverdueArr.reduce((a, b) => a + b, 0);
    console.log({totalOv90: totalOv});

    resultArray.forEach(term => {
        const amount = term.value;
        if ("name" in term) {
            const legalRepoStatus = term.name;
            const legalPosition = legalRepoStatus.search(/legal/i);
            const repoPosition = legalRepoStatus.search(/repossessed/i);

            switch (true) {
                case (legalPosition >= 0 || repoPosition >= 0):
                    legalOrRepo.push(amount);
                    break;
                case (legalRepoStatus === "Insurance case"):
                    insurance.push(amount);
                    break;
                case (legalRepoStatus === "Under Follow-up"):
                    underFollowUp.push(amount);
                    break;
                case (legalRepoStatus === "Repossession in progress"):
                    repoInProgress.push(amount)
                    break;
                case (legalRepoStatus === "Promise to pay"):
                    promiseToPay.push(amount);
                    break;
                case (legalRepoStatus === "Watchlist"):
                    watchList.push(amount);
                    break;
                case (legalRepoStatus === "Case Opening in Progress"):
                    caseOpeningInProgress.push(amount);
                    break;
                case (legalRepoStatus === "Partial Repossession"):
                    partialReposession.push(amount);
                    break;
                case (legalRepoStatus === "Request To Open Case"):
                    requestToOpenCase.push(amount);
                    break;
                case (legalRepoStatus === "No Status"):
                    noStatus.push(amount);
                    break;
                case (legalRepoStatus === "Non-Starter"):
                    nonStarter.push(amount);
                    break;
            }
        }
    })

    let legalRepo = legalOrRepo.reduce((a, b) => a + b, 0);
    legalRepo = roundAccurately(legalRepo, 2);

    let insuranceCase = insurance.reduce((a, b) => a + b, 0);
    insuranceCase = roundAccurately(insuranceCase, 2);

    let repoProgress = repoInProgress.reduce((a, b) => a + b, 0);
    repoProgress = roundAccurately(repoProgress, 2);

    let promisePay = promiseToPay.reduce((a, b) => a + b, 0);
    promisePay = roundAccurately(promisePay, 2);

    let watchlist = watchList.reduce((a, b) => a + b, 0);
    watchlist = roundAccurately(watchlist, 2);

    let requestCase = requestToOpenCase.reduce((a, b) => a + b, 0);
    requestCase = roundAccurately(requestCase, 2);

    let caseOpening = caseOpeningInProgress.reduce((a, b) => a + b, 0);
    caseOpening = roundAccurately(caseOpening, 2);

    let noStatusValue = noStatus.reduce((a, b) => a + b, 0);
    noStatusValue = roundAccurately(noStatusValue, 2);

    let partialReposessionValue = partialReposession.reduce((a, b) => a + b, 0);
    partialReposessionValue = roundAccurately(partialReposessionValue, 2);

    let underFollowUpValue = underFollowUp.reduce((a, b) => a + b, 0);
    underFollowUpValue = roundAccurately(underFollowUpValue, 2);

    let nonStarterValue = nonStarter.reduce((a, b) => a + b, 0);
    nonStarterValue = roundAccurately(nonStarterValue, 2);

    const newDate = timeStamp.format("DD-MM-YYYY");

    return {date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual, legalRepo, insuranceCase, watchlist, promisePay, requestCase,
        caseOpening, repoProgress, newDate, noStatusValue, nonStarterValue, underFollowUpValue, partialReposessionValue};
}

async function exportCollectionTrackerData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3, dispatch}){
        //styling sheets
        const sa2b = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for(let i = 0; i !== s.length; ++i){
                view[i] = s.charCodeAt(i);
            }
            return buf;
        }
    
        const workbook2blob = (workbook) => {
            const wopts = {
                bookType: 'xlsx',
                type: 'binary'
            }
    
            const wbout = XLSX.write(workbook, wopts);
            const blob = new Blob([sa2b(wbout)], {
                type: 'application/octet-stream'
            })
    
            return blob;
        }
    
        const today = moment().format("DD/MM/YYYY");
        let anal = `COLLECTION TRACKER AS OF ${today}`;
        let title = [{A: anal}, {}];


    let table1 = [
        {
            A: "COLLECTION FOR THE MONTH",
            B: "VALUE",
        }
    ];

    let table2 = [
        {
            A: "DESCRIPTION",
            B: "AMOUNT",
            C: "RATIO"
        }
    ];

    let table3 = [
        {
            A: "MONTH",
            B: "TOTAL OVERDUE",
            C: "PDC FTM",
            D: "PDC TD",
            E: "PDC TD - BOUNCED TD",
            F: "BOUNCED TD",
            G: "OD COLL TD",
            H: "TC",
            I: "TOTAL COLL REQ TARGET",
            J: "TOTAL OD REQ TARGET",
            K: "TALLY",
        }
    ];

    let table4 = [
        {
            A: "DATE",
            B: "TOTAL BOOK",
            C: "SCHEDULED DUE COLLECTIONS",
            D: "CUMMULATIVE COLLECTIONS",
            E: "DAILY COLLECTIONS",
            F: "COLLECTION EFFICIENCY",
            G: "REPOSSESSED NUMBER",
            H: "REPOSSESSED VALUE",
            I: "LEGAL CASES",
            J: "LEGAL VALUE",
            K: "OVERDUE",
            L: "DELIQUENCY RATE",
            M: "0-90",
            N: "91-180",
            O: "181-360",
            P: ">360",
            Q: "OVERDUE TARGET",
            R: "OVERDUE ACTUAL",
            S: ">90 DAYS LEGAL/REPOSSESSED",
            T: ">90 DAYS INSURANCE CASE",
            U: ">90 DAYS REPOSSESSION IN PROGRESS",
            V: ">90 DAYS PROMISE TO PAY",
            W: ">90 DAYS WATCHLIST",
            X: ">90 DAYS CASE OPENING IN PROGRESS",
            Y: ">90 DAYS REQUEST TO OPEN CASE",
            Z: ">90 DAYS UNDER FOLLOW-UP",
            AA: ">90 DAYS PARTIAL REPOSSESSION",
            AB: ">90 NON STARTER",
            AC: ">90 DAYS BLANK",
        }
    ];

    if(collectionTracker.length !== 0){
        collectionTracker.forEach(data => {
            table1.push({
                A: data.label,
                B: data.value,
            })
        })
    }

    if(collectionTracker1.length !== 0){
        collectionTracker1.forEach(data => {
            table2.push({
                A: data.description,
                B: data.amount,
                C: data.ratio

            })
        })
    }

    if(collectionTracker2.length !== 0){
        collectionTracker2.forEach(data => {
            table3.push({
                A: data.month,
                B: data.totalOverdue,
                C: data.pdcFtm,
                D: data.pdcTd,
                E: data.pdcBouncedTD,
                F: data.bouncedTd,
                G: data.odCollTd,
                H: data.tc,
                I: data.totalCollReqTarget,
                J: data.totalODReqTarget,
                K: data.tally,
            })
        })
    }

    if(collectionTracker3.length !== 0){
        collectionTracker3.forEach(data => {
            table4.push({
                A: data.newDate,
                B: data.totalBook,
                C: data.dueCollections,
                D: data.cumulativeCollections,
                E: data.dailyCollections,
                F: data.collectionEfficiency,
                G: "",
                H: "",
                I: data.legalCases,
                J: data.legalValue,
                K: data.overdue,
                L: data.deliquencyRate,
                M: data.bucket1,
                N: data.bucket2,
                O: data.bucket3,
                P: data.bucket4,
                Q: data.overdueTarget,
                R: data.overdueActual,
                S: data.legalRepo,
                T: data.insuranceCase,
                U: data.repoProgress,
                V: data.promisePay,
                W: data.watchlist,
                X: data.caseOpening,
                Y: data.requestCase,
                Z: data.underFollowUpValue,
                AA: data.partialReposessionValue,
                AB: data.nonStarterValue,
                AC: data.noStatusValue,

            })
        })
    }

    table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table4)
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch: 30},
        {wch: 20},
        {wch: 35},
        {wch: 35},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 25},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Collection Tracker');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];
    const headerIndex3 = [];

    finalData.forEach((data, index) => data['A'] === 'COLLECTION FOR THE MONTH' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'DESCRIPTION' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'MONTH' ? headerIndex2.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'DATE' ? headerIndex3.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:AC2',
        tbodyRange: `A2:AC${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:B${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:C${headerIndex1[0] + 1}` : null,
        theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:K${headerIndex2[0] + 1}` : null,
        theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:AC${headerIndex3[0] + 1}` : null,
    }

    addStylesCollectionTracker(workbookBlob, dataInfo, dispatch);
}

const addStylesCollectionTracker = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                sheet.range(dataInfo.theadRange).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange1).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange2).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange3).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'collectionTracker.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })
            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}


//=========================================================================================================================================================================================================

export const createDownPaymentsReport = () => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        //invoke custom database function
        const url = `${project.serverUrl}downPaymentsReport`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                if (response.length !== 0) {
                    //structure the data
                    const report = response.map(payment => {

                        let seconds;
                        payment.downPaymentDate.seconds ? seconds = payment.downPaymentDate.seconds : seconds = payment.downPaymentDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const downPaymentDate = rowDate.toDate();

                        return {
                            amount: Number(payment.downPaymentPaidAmount),
                            bank: payment.downPaymentBankName,
                            date: downPaymentDate,
                            comment: payment.downPaymentComments,
                            currency: payment.currency,
                        }
                    });

                    exportDownPayment({report, dispatch});
                   
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            dispatch({ type: ALL_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);
        })
    }
};

async function exportDownPayment({report, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment().format("DD/MM/YYYY");
    const bulk = `DOWN PAYMENT REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AMOUNT",
            B: "BANK",
            C: "DATE",
            D: "COMMENT",
            E: "CURRENCY"
        }
    ];

    report.forEach(data => {
        table1.push({
            A: data.amount,
            B: data.bank,
            C: data.date,
            D: data.comment,
            E: data.currency,

        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:15},
        {wch:15},
        {wch:15},
        {wch:35},
        {wch:10},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Down payment');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AMOUNT' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:E${headerIndex[0] + 1}` : null,
    }

    addStylesDownPayment(workbookBlob, dataInfo, dispatch);

}

const addStylesDownPayment = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'downPayment.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const findOpeningOD = (systemInfo) => {
    console.log(systemInfo);
    return async () => {
        try{
            const loanTermsRef = firebase.firestore().collection('loanTerms');
            const snapshot = await loanTermsRef.get();
                if (snapshot.size === 0) {
                    console.log("no loan term found");
                } else {
                    //we have terms here
                    //create loans container
                    const openingTermsArray = [];

                    //loop over individual loan Terms to find overdue terms
                    snapshot.forEach(doc => {
                    //here is an individual term data
                    const term = doc.data();

                    //check they are not cleared and don't belong to any old rescheduled loan loanStatus && rescheduleStatus: oldLoan
                    let status = true;
                    if ("loanStatus" in term) {
                        if (term.loanStatus) {
                            status = false;
                        } else {
                            //check if its a old loan
                            if ("rescheduleStatus" in term) {
                                if (term.rescheduleStatus === "oldLoan") {
                                    status = false;
                                }
                            }
                        }
                    } else {
                        //check if its a old loan
                        if ("rescheduleStatus" in term) {
                            if (term.rescheduleStatus === "oldLoan") {
                                status = false;
                            }
                        }
                    }

                    if (status) {
                        //check that loan is not early liquidated
                        if ("earlyLiquidation" in term) {
                            //do nothing
                        } else {
                            //check if term has is not cleared interest
                            if ("termStatus" in term) {
                                if (!term.termStatus.status) {
                                    if (term.termStatus.penalInterest > 0) {
                                        //find opening total overdue
                                        const firstDay = moment().startOf('month');
                                        let seconds;
                                        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                                        const dueDate = moment.unix(seconds);

                                        if (dueDate.isBefore(firstDay)) {
                                            //grab the total amount of the loan
                                            openingTermsArray.push(term);
                                        }
                                    }
                                }
                            }
                        }
                    }
                });

                let totalOverdueArray = [];

                if (openingTermsArray.length !== 0) {
                    //we have overdue terms
                    //loop all overdue terms
                    openingTermsArray.forEach(term => {
                        //check if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            // const convertedAmount = amount;

                            totalOverdueArray.push(amount);
                        } else {

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }
                            const convertedAmount = amount/systemInfo.exchangeRate;

                            totalOverdueArray.push(convertedAmount);
                        }
                    });
                }

                //calculate the total amount from numbers in the array
                const openingOD = totalOverdueArray.reduce((a, b) => a + b, 0);
                console.log(openingOD);
            }
        }
        catch(e){
            console.log(e);
        }
    }
}

export const fetchMonthlyEmis = ({reportDate, systemInfo}) => {
    return async (dispatch) => {
        try{

            dispatch({ type: ALL_REPORT });

            const loanTermsRef = firebase.firestore().collection('loanTerms');
            const snapshot = await loanTermsRef.get();
            
                if (snapshot.size === 0) {
                    console.log("no loan term found");
                } else {
                    let Emi = [];

                    //we have terms here
                    //loop over individual loan Terms to find overdue terms
                    snapshot.forEach(doc => {
                    //here is an individual term data
                    let term = doc.data();

                    let status = true;
                    if ("rescheduleStatus" in term) {
                        if (term.rescheduleStatus === "oldLoan") {
                            status = false;
                        }
                    }

                    if (status) {
                        //check that loan is not early liquidated
                        if ("earlyLiquidation" in term) {
                            //do nothing
                        } else {

                            let seconds;
                            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                            const dueDate = moment.unix(seconds);
                            const dateFormat = dueDate.format('DD/MM/YYYY');
                            term['dateFormat'] = dateFormat;

                            if (term.loanStatus) {
                                term['isLoanPaid'] = 'PAID';
                            } else {
                                term['isLoanPaid'] = 'NOT PAID';
                            }
        
                            if(moment(reportDate).isSame(dueDate, "month")) {
        
                                if (term.cheque) {
                                    const emiChequeStatus = 'Yes';
                                    term['emiChequeStatus'] = emiChequeStatus;
                                    if ('termStatus' in term) {
                                        if (term.termStatus.status) {
                                            const emiStatus = 'Paid';
                                            term['emiStatus'] = emiStatus;
                                            Emi.push(term);
                                        } else {
                                            const emiStatus = 'Not Paid';
                                            term['emiStatus'] = emiStatus;
                                            Emi.push(term);
                                        }
                                    } else {
                                        const emiStatus = 'Pending';
                                        term['emiStatus'] = emiStatus;
                                        Emi.push(term); 
                                    }
                                } else {
                                    const emiChequeStatus = 'No';
                                    term['emiChequeStatus'] = emiChequeStatus;
                                    if ('termStatus' in term) {
                                        if (term.termStatus.status) {
                                            const emiStatus = 'Paid';
                                            term['emiStatus'] = emiStatus;
                                            Emi.push(term);
                                        } else {
                                            const emiStatus = 'Not Paid';
                                            term['emiStatus'] = emiStatus;
                                            Emi.push(term);
                                        }
                                    } else {
                                        const emiStatus = 'Pending';
                                        term['emiStatus'] = emiStatus;
                                        Emi.push(term); 
                                    }
                                }
                            }
                        }
                    }
                });

                exportEmis({Emi, reportDate, dispatch});

            }
        }
        catch(e){
            console.log(e);
            dispatch({ type: ALL_REPORT_FAILED });
        }
    }
}

async function exportEmis({Emi, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment(reportDate).format("MM/YYYY");
    const bulk = `EMI's REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "DUE DATE",
            B: "CUSTOMER ID",
            C: "CUSTOMER NAME",
            D: "CHEQUE",
            E: "CHEQUE STATUS",
            F: 'CURRENCY',
            G: 'AMOUNT',
            H: 'EMI STATUS',
            I: 'LOAN STATUS'
        }
    ];

    Emi.forEach(data => {
        table1.push({
            A: data.dateFormat,
            B: data.customerID,
            C: data.customerName,
            D: data.emiChequeStatus,
            E: data.chequeStatus,
            F: data.currency,
            G: data.amount,
            H: data.emiStatus,
            I: data.isLoanPaid
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:15},
        {wch:15},
        {wch:35},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Emi list');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'DUE DATE' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:I2',
        tbodyRange: `A2:I${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:I${headerIndex[0] + 1}` : null,
    }

    addStylesEmis(workbookBlob, dataInfo, dispatch, reportDate);

}

const addStylesEmis = (workbookBlob, dataInfo, dispatch, reportDate) => {
    const today = moment(reportDate).format("MM/YYYY");

    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', `emiList${today}.xlsx`);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}