import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { message, Popconfirm, Button} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//action imports
import { changedActionValue, addClientAction, deleteClientAction, fetchClientActions } from '../../../actions/SettingsActions';


//component imports
import { Input } from '../../common/Input';
import {BeatLoader} from "react-spinners";
import SelectCharacter from "../../common/selectCharacter";


class Action extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clientAction: this.props.clientAction,
            character: this.props.character,
            actionLoading: this.props.actionLoading,
            actionSuccess: this.props.actionSuccess,
            actionFailed: this.props.actionFailed,
            clientActions: this.props.clientActions
        };
        this.onAddActionValue = this.onAddActionValue.bind(this);
        this.onDeleteActionValue = this.onDeleteActionValue.bind(this);
        this.onSelectCharacter = this.onSelectCharacter.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.clientAction !== this.props.clientAction) {
            this.setState({ clientAction: nextProps.clientAction });
        }
        if (nextProps.character !== this.props.character) {
            this.setState({ character: nextProps.character });
        }
        if (nextProps.actionLoading !== this.props.actionLoading) {
            this.setState({ actionLoading: nextProps.actionLoading });
        }
        if (nextProps.actionSuccess !== this.props.actionSuccess) {
            this.setState({ actionSuccess: nextProps.actionSuccess });
        }
        if (nextProps.actionFailed !== this.props.actionFailed) {
            this.setState({ actionFailed: nextProps.actionFailed });
        }
        if (nextProps.clientActions !== this.props.clientActions) {
            this.setState({ clientActions: nextProps.clientActions });
        }

    }


    componentDidMount() {
        this.props.fetchClientActions();
    }

    onAddActionValue(){
        const {clientAction, character} = this.state;

        if(clientAction && character){
            //
            this.props.addClientAction({clientAction, character});
        } else {
            message.warning("Kindly enter all fields");
        }
    }

    onSelectCharacter(value){
        this.props.changedActionValue({prop: "character", value});
    }

    renderButton () {
        //extract state
        const { characterLoading } = this.state;

        if (characterLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        }  else {
            return (
                <div className={"masterFileUploadButton"}>
                    <Button onClick={() => this.onAddActionValue()}>Add</Button>
                </div>
            );
        }
    }


    onDeleteActionValue(action) {
        //
        if(action){
            const actionID = action.actionID;
            this.props.deleteClientAction({actionID});
        } else {
            message.warning("There is no action to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderStatuses() {
        const {clientActions} = this.state;

        const actionsArray = _.map(clientActions, action => action);

        if(actionsArray.size !== 0) {
            return (
                <div>
                    {
                        actionsArray.map(action => {
                            const index = actionsArray.indexOf(action);
                            const position = index + 1;
                            return (
                                <div key={action.actionID} className={"industryListValue"}>
                                    <div><p>{position}</p></div>
                                    <div><p>{action.value}</p></div>
                                    <div><p>{action.character}</p></div>
                                    <div>
                                        <Popconfirm
                                            title="Are you sure to delete this action?"
                                            onConfirm={() => this.onDeleteActionValue(action)}
                                            onCancel={() => this.cancel()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return <h4>There is no data found</h4>
        }
    }

    render() {
        return (
            <div className={"industryContainer"}>
                <div>
                    <h2>Enter Action Value</h2>
                    <Input className="inputField"
                           type="text"
                           placeholder="Title"
                           onChange={e => this.props.changedActionValue({ prop: 'clientAction', value: e.target.value })}
                           value={this.state.clientAction}
                    />
                </div>
                <div>
                    <label>Character</label>
                    <SelectCharacter
                        onChange={this.onSelectCharacter}
                        value={this.state.character}
                    />
                </div>
                <div>
                    {this.renderButton()}
                </div>

                <div>
                    <div>
                        <div className={"industryList"}>
                            <div><h5>#</h5></div>
                            <div><h5>Client Action</h5></div>
                            <div><h5>Action Character</h5></div>
                            <div><h5>Delete Action</h5></div>
                        </div>
                        <div>{this.renderStatuses()}</div>
                    </div>
                </div>

            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

    const {clientAction, character, actionLoading, actionSuccess, actionFailed, clientActions } = state.settings

    //return object with state properties
    return {
        clientAction,
        character,
        actionLoading,
        actionSuccess,
        actionFailed,
        clientActions
    }
};


export default connect(mapStateToProps, {changedActionValue, addClientAction, deleteClientAction, fetchClientActions, })(Action);