import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//actions import
import { fetchLoans} from "../../../actions/LoanActions";
import {deleteCustomer} from "../../../actions/CustomerActions";

//components import
import ListOfLoans from "./subComponents/ListOfLoans";
import AddCustomerLoan from "./subComponents/AddCustomerLoan";
import AddCustomerPhoneNumber from "./subComponents/AddCustomerPhoneNumber";
import {Popconfirm, message, Button} from "antd";
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {BeatLoader} from "react-spinners";




//file imports
import Flower from '../../../assets/images/flower.png';

class CustomerProfile extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            customerProfile: this.props.customerProfile,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            deleteCustomerLoading: this.props.deleteCustomerLoading
        }
    }

    componentWillMount() {
        //extract props
        const { customerProfile } = this.props;
        const { systemInfo } = this.state;
        //fetch
        if (!(_.isEmpty(customerProfile))) {
            //if customer profile has been fetched
            const customerID = customerProfile.customerID;

            //fetch loans
            this.props.fetchLoans({ customerID});

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customerProfile !== this.props.customerProfile) {
            if (!(_.isEmpty(nextProps.customerProfile))) {
                //if customer profile has been fetched
                const customerID = nextProps.customerProfile.customerID;

                //fetch loans
                this.props.fetchLoans({ customerID});

            }
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.deleteCustomerLoading !== this.props.deleteCustomerLoading) {
            this.setState({ deleteCustomerLoading: nextProps.deleteCustomerLoading });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }

    }

    onDeleteCustomer({profile, customerProfile}){
        this.props.deleteCustomer({profile, customerProfile})
    }

    checkUserAccess() {
        //extract state
        const {profile, customerProfile} = this.state;

        const access = profile.userAccess.some(access => access === "clientsView");

        if (access) {
            return(
                <div>
                    <h4>Customer Profile</h4>

                    <div className={"customerProfileBox"}>
                        <div className={"customerProfileName"}>
                            <h5><FontAwesomeIcon icon="user-circle" size="lg" color="#707070" /> {this.props.customerProfile.customerName}</h5>
                            <h6><span className={"customerIDSpan"}>Customer ID:</span> <span className={"customerIDNumber"}>{this.props.customerProfile.customerID}</span></h6>
                            {
                                this.props.customerProfile.phoneNumber? 
                                <h6><span className={"customerIDSpan"}>Phone Number:</span> <span className={"customerIDNumber"}>{this.props.customerProfile.phoneNumber}</span></h6> :
                                null
                            }

                        </div>
                        <div className={"addLoanButtonBox"}>
                            <AddCustomerLoan/>
                        </div>
                        {
                            this.state.deleteCustomerLoading ? 
                            <div className={"deleteLoanButtonBox"}><BeatLoader size={14} color="black"/></div> :
                            <div className={"deleteLoanButtonBox"}>
                            <Popconfirm
                                title="Are you sure to delete this Customer?"
                                onConfirm={() => this.onDeleteCustomer({profile, customerProfile})}
                                onCancel={this.cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button><FontAwesomeIcon icon={faTrashAlt} color="#d10000" /> </Button>
                            </Popconfirm>
                        </div>
                        }
                        <div className={"addLoanButtonBox"}>
                            <AddCustomerPhoneNumber/>
                        </div>
                        <div className={"flowerArtworkBox"}>
                            <img src={Flower} alt="flower" />
                        </div>
                    </div>

                    <div>
                        <ListOfLoans/>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {

        return (
            <div className={"customerProfileContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { customerProfile, deleteCustomerLoading } = state.customer;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        //
        customerProfile,
        profile,
        systemInfo,
        deleteCustomerLoading
    }
};

export default connect(mapStateToProps,{fetchLoans, deleteCustomer})(CustomerProfile);