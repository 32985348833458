import {
    FETCH_HELD_CHEQUES,
    FETCH_HELD_CHEQUES_SUCCESSFUL,
    FETCH_ALL_HELD_CHEQUES,
    FETCH_ALL_HELD_CHEQUES_SUCCESSFUL,
    HELD_DATE_CHANGED,
    HELD_STATUS_CHANGED,
    UPDATE_HELD_CHEQUES_STATUS,
    UPDATE_HELD_CHEQUES_STATUS_SUCCESSFUL,
    CLEAR_UPDATE_HELD_CHEQUE_MESSAGE, CLEAR_HELD_CHEQUE_SELECTED_DATE,
} from '../actions/Types';

const INITIAL_STATE = {

    date: '',
    chequeStatus: '',

    heldCheques: {},
    fetchHeldChequesLoading: false,
    fetchHeldChequesSuccessful: false,
    fetchHeldChequesFailed: false,
    dateChanged: false,

    searchedDate: ""
};

const HeldChequesReducer = ( state = INITIAL_STATE, action ) => {
    switch (action.type) {

        //held cheque date reducer
        case HELD_DATE_CHANGED:
            return {...state, date: action.payload, dateChanged: true };

            //held status reducer
        case HELD_STATUS_CHANGED:
            return {...state, chequeStatus: action.payload };

            //fetch held cheques
        case FETCH_HELD_CHEQUES:
            return {...state, fetchHeldChequesLoading: true };
        case FETCH_HELD_CHEQUES_SUCCESSFUL:
            return {...state, heldCheques: action.payload.heldCheques, fetchHeldChequesLoading: false, successfullyUpdate: false, searchedDate: action.payload.date, dateChanged: false };

        case FETCH_ALL_HELD_CHEQUES:
            return {...state, fetchHeldChequesLoading: true };
        case FETCH_ALL_HELD_CHEQUES_SUCCESSFUL:
            return {...state, heldCheques: action.payload, fetchHeldChequesLoading: false, successfullyUpdate: false };

            //update cheque status
        case UPDATE_HELD_CHEQUES_STATUS:
            return {...state, updateLoading: true };
        case UPDATE_HELD_CHEQUES_STATUS_SUCCESSFUL:
            return {...state, updateLoading: false, successfullyUpdate: true };

        case CLEAR_UPDATE_HELD_CHEQUE_MESSAGE:
            return {...state, updateLoading: false, successfullyUpdate: false };
        case CLEAR_HELD_CHEQUE_SELECTED_DATE:
            return {...state, successfullyUpdate: false, date: "" };

        default:
            return state;
    }
};

export default HeldChequesReducer;