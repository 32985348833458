import React from "react";
import { connect } from 'react-redux';
import _, { isEmpty } from "lodash";
import {Button, Modal} from "antd";
import {BeatLoader} from "react-spinners";



//actions import
import { addExternalCollector, customerDetailsChanged } from "../../actions/CustomerActions";

//components import
import {Input} from "../common/Input";
import {message} from "antd";


class AddExternalCollector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            collectorName: this.props.collectorName,
            addCollectorLoading: this.props.addCollectorLoading,
            visible: false
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.collectorName !== this.props.collectorName){
            this.setState({collectorName: nextProps.collectorName})
        }

        if (nextProps.addCollectorLoading !== this.props.addCollectorLoading){
            this.setState({addCollectorLoading: nextProps.addCollectorLoading})
        }
    }

    onSave() {
        const { collectorName } = this.state;

        if(collectorName){
            this.props.addExternalCollector({ collectorName});

        } else {
            message.warning("Kindly fill the required field");
        }
    }


    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className={"addLoanButton"}>
                <Button type="default" onClick={this.showModal}>
                    Add External Collector
                </Button>
                <Modal
                    title="External Collector"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={600}
                >
                    <div className={"customerAddLoanModal"}>
                        <div>
                            <Input
                                placeholder={"Collector Name"}
                                type={'text'}
                                onChange={e => this.props.customerDetailsChanged({prop: 'collectorName', value: e.target.value})}
                                value={this.state.collectorName}
                            />
                        </div>
                        {
                            this.state.addCollectorLoading ?
                            <div>
                                <BeatLoader />
                            </div> :
                            <div className={"customerSaveLoanButtonBox"}>
                                <Button text={'Save'} onClick={() =>this.onSave()}>SAVE</Button>
                            </div>
                        }

                    </div>
                </Modal>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { collectorName, addCollectorLoading } = state.customer;

    //return object with state properties
    return {
        collectorName, 
        addCollectorLoading
    }
};

export default connect(mapStateToProps,{ addExternalCollector, customerDetailsChanged })(AddExternalCollector);