import React from 'react';
import {Tabs} from 'antd';
import moment from 'moment';

//components import
import MasterList from "./MasterList";
import Dashboard from "./Dashboard";
import RollOverNewCases from './RollOverNewCases';
import RollOverPaidCases from "./RollOverPaidCases";

class ODAgingNew extends React.Component {


    render() {
        const { TabPane } = Tabs;
        const month = moment().format('MMMM');
        const year  = moment().format('YYYY');
        const newCase = `New Cases - ${month} ${year}`;
        const rollOver = `Rolled over to ${month} ${year}`;

        return (
            <div>
                <Tabs 
                    defaultActiveKey="1" 
                    type="card" 
                    size={'small'} 
                    tabBarStyle={{
                        fontFamily: 'avenirBlack, sans-serif', 
                        color: '#4262e9',
                    }}
                >
                    <TabPane tab="OD Aging" key="1">
                        <MasterList/>
                    </TabPane>
                    <TabPane tab="Dashboard" key="2">
                        <Dashboard/>
                    </TabPane>
                    <TabPane tab={newCase} key="3">
                        <RollOverNewCases/>
                    </TabPane>
                    <TabPane tab={rollOver} key="4">
                        <RollOverPaidCases/>
                    </TabPane>

                </Tabs>
            </div>
        );
    }
}

export default ODAgingNew;