import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {ClipLoader} from "react-spinners";
import { message, Popconfirm} from "antd";

//action imports
import { fetchTotalBook, fetchOverdue, fetchOutstanding, fetchNPL, fetchPdcFtm, fetchPdcTd, fetchPdcDeposited, fetchBouncedTd, fetchODCollTd,
    fetchLegalOverdue, fetchRepoOverdue, fetchNormalOverdue, fetchMonthlyTarget, deleteMonthlyTarget, fetchAnalytics, fetchTotalODTarget, deleteTotalODTarget } from "../../actions/AnalyticsActions";

//component imports
import MonthlyTarget from "./MonthlyTarget";

import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";
import TotalODTarget from "./TotalODTarget";


class Analytics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            totalBook: 0,
            totalOverdue: this.props.totalOverdue,
            totalOutstanding: this.props.totalOutstanding,
            totalNPL: this.props.totalNPL,

            pdcFtm: this.props.pdcFtm,
            pdcTd: this.props.pdcTd,
            pdcDeposited: this.props.pdcDeposited,
            bouncedTd: this.props.bouncedTd,
            odCollTd: this.props.odCollTd,
            tc: 0,
            legalOverdue: this.props.legalOverdue,
            repoOverdue: this.props.repoOverdue,
            normalOverdue: this.props.normalOverdue,
            dueCollection: this.props.dueCollection,

            totalOverdueLoading: this.props.totalOverdueLoading,
            totalOutstandingLoading: this.props.totalOutstandingLoading,

            pdcFtmLoading: this.props.pdcFtmLoading,
            pdcTdLoading: this.props.pdcTdLoading,
            pdcDepositedLoading: this.props.pdcDepositedLoading,
            bouncedTdLoading: this.props.bouncedTdLoading,
            odCollTdLoading: this.props.odCollTdLoading,

            legalOverdueLoading: this.props.legalOverdueLoading,
            repoOverdueLoading: this.props.repoOverdueLoading,
            normalOverdueLoading: this.props.normalOverdueLoading,
            dueCollectionLoading: this.props.dueCollectionLoading,

            profile: this.props.profile,
            systemInfo: this.props.systemInfo,

            monthlyTarget: this.props.monthlyTarget,
            mTargetSaved: this.props.mTargetSaved,
            monthlyTargetLoading: this.props.monthlyTargetLoading,
            monthlyTargetDeleted: this.props.monthlyTargetDeleted,
            deleteTargetFailed: this.props.deleteTargetFailed,
            mTargetFailed: this.props.mTargetFailed,

            analyticsLoading: this.props.analyticsLoading,
            analytics: this.props.analytics,

            localCurrency: "TSH",

            totalODTarget: this.props.totalODTarget,
            odTargetSaved: this.props.odTargetSaved,
            totalODTargetLoading: this.props.totalODTargetLoading,
            odTargetDeleted: this.props.odTargetDeleted,
            deleteODTargetFailed: this.props.deleteODTargetFailed,
            odTargetFailed: this.props.odTargetFailed,
        };

        //this.showPercentage = this.showPercentage.bind(this);

        this.computeTC = this.computeTC.bind(this);
        this.computeTotalBook = this.computeTotalBook.bind(this);
        this.computeNPL = this.computeNPL.bind(this);
        this.computeDeliquencyRatio = this.computeDeliquencyRatio.bind(this);
        this.computeCollectionEfficiency = this.computeCollectionEfficiency.bind(this);
        this.computeCollectionEfficiency = this.computeCollectionEfficiency.bind(this);
        this.overduePercent = this.overduePercent.bind(this);
        this.computeLegalLoansOverdue = this.computeLegalLoansOverdue.bind(this);
        this.computeRepossessedLoansOverdue = this.computeRepossessedLoansOverdue.bind(this);
        this.computeLegalRepossessedLoansOverdue = this.computeLegalRepossessedLoansOverdue.bind(this);
        this.computeNormalOverdue = this.computeNormalOverdue.bind(this);
        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.onDeleteTotalOD = this.onDeleteTotalOD.bind(this);
    }

    componentWillMount() {
        this.props.fetchAnalytics();
        this.checkCurrencyFormat();
        //this.props.fetchTotalBook();
        // this.props.fetchOverdue();
        // this.props.fetchOutstanding();
        // this.props.fetchNPL();
        //
        // this.props.fetchPdcFtm();
        // this.props.fetchPdcTd();
        // this.props.fetchPdcDeposited();
        // this.props.fetchBouncedTd();
        // this.props.fetchODCollTd();
        // this.props.fetchLegalOverdue();
        // this.props.fetchRepoOverdue();
        // this.props.fetchNormalOverdue();

        //fetch monthly Target
        const month = moment().format("MM");
        const year = moment().format("YYYY");
        this.props.fetchMonthlyTarget({month, year});
        this.props.fetchTotalODTarget({month, year});

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                totalOverdue: nextProps.totalOverdue,
                totalOutstanding: nextProps.totalOutstanding,
                totalNPL: nextProps.totalNPL,
                pdcFtm: nextProps.pdcFtm,
                pdcTd: nextProps.pdcTd,
                pdcDeposited: nextProps.pdcDeposited,
                bouncedTd: nextProps.bouncedTd,
                odCollTd: nextProps.odCollTd,

                legalOverdue: nextProps.legalOverdue,
                repoOverdue: nextProps.repoOverdue,
                normalOverdue: nextProps.normalOverdue,
                dueCollection: nextProps.dueCollection,

                profile: nextProps.profile,
                systemInfo: nextProps.systemInfo,

                totalBookLoading: nextProps.totalBookLoading,
                totalOverdueLoading: nextProps.totalOverdueLoading,
                totalOutstandingLoading: nextProps.totalOutstandingLoading,
                totalNPLLoading: nextProps.totalNPLLoading,

                pdcFtmLoading: nextProps.pdcFtmLoading,
                pdcTdLoading: nextProps.pdcTdLoading,
                pdcDepositedLoading: nextProps.pdcDepositedLoading,
                bouncedTdLoading: nextProps.bouncedTdLoading,
                odCollTdLoading: nextProps.odCollTdLoading,
                legalOverdueLoading: nextProps.legalOverdueLoading,
                repoOverdueLoading: nextProps.repoOverdueLoading,
                normalOverdueLoading: nextProps.normalOverdueLoading,
                dueCollectionLoading: nextProps.dueCollectionLoading,

                monthlyTarget: nextProps.monthlyTarget,
                mTargetSaved: nextProps.mTargetSaved,
                monthlyTargetLoading: nextProps.monthlyTargetLoading,
                monthlyTargetDeleted: nextProps.monthlyTargetDeleted,
                deleteTargetFailed: nextProps.deleteTargetFailed,
                mTargetFailed: nextProps.mTargetFailed,

                analyticsLoading: nextProps.analyticsLoading,
                analytics: nextProps.analytics,

                totalODTarget: nextProps.totalODTarget,
                odTargetSaved: nextProps.odTargetSaved,
                totalODTargetLoading: nextProps.totalODTargetLoading,
                odTargetDeleted: nextProps.odTargetDeleted,
                deleteODTargetFailed: nextProps.deleteODTargetFailed,
                odTargetFailed: nextProps.odTargetFailed,
            });
        }

        //TC
        if (nextProps.pdcTd !== this.props.pdcTd) {
            this.computeTC({ pdcTd: nextProps.pdcTd, bouncedTd: nextProps.bouncedTd, odCollTd: nextProps.odCollTd });
        }if (nextProps.bouncedTd !== this.props.bouncedTd) {
            this.computeTC({ pdcTd: nextProps.pdcTd, bouncedTd: nextProps.bouncedTd, odCollTd: nextProps.odCollTd });
        }if (nextProps.odCollTd !== this.props.odCollTd) {
            this.computeTC({ pdcTd: nextProps.pdcTd, bouncedTd: nextProps.bouncedTd, odCollTd: nextProps.odCollTd });
        }

        //TotalBook
        if (nextProps.totalOverdue !== this.props.totalOverdue) {
            this.computeTotalBook({ totalOverdue: nextProps.totalOverdue, totalOutstanding: nextProps.totalOutstanding });
        }if (nextProps.totalOutstanding !== this.props.totalOutstanding) {
            this.computeTotalBook({ totalOverdue: nextProps.totalOverdue, totalOutstanding: nextProps.totalOutstanding });
        }
    }

    computeTC({ pdcTd, bouncedTd, odCollTd }) {

        if (pdcTd && bouncedTd && odCollTd) {
            const tc = pdcTd - bouncedTd + odCollTd;

            this.setState({ tc });
        }
    }

    computeTotalBook({ totalOverdue, totalOutstanding }) {

        if (totalOverdue && totalOutstanding) {
            const totalBook = totalOverdue + totalOutstanding;

            this.setState({ totalBook });
        }
    }

    computeNPL({totalBook, totalNPL}) {
        //extract from state
        const { totalNPLLoading } = this.state;

        if (totalNPL && totalBook) {
            const nplPercent = this.calculatePercentage({ totalBook, rawValue: totalNPL });
            //this.setState({ nplPercent });
            return <h4>{nplPercent} %</h4>
        } else {
            if (totalNPLLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }

    }

    computeDeliquencyRatio({ totalBook, totalOverdue}) {
        //extract from state
        const { totalOverdueLoading } = this.state;

        if (totalOverdue && totalBook) {
            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

            const number = (totalOverdue/totalBook) * 100;
            const deliquencyRatio = roundAccurately(number, 2);
            //this.setState({ deliquencyRatio })
            return <h4>{deliquencyRatio} %</h4>
        } else {
            if (totalOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    computeCollectionEfficiency({tc, pdcTd}) {
        //extract from state
        const { pdcTdLoading } = this.state;

        let percent = 0;
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        if (pdcTd && tc) {
            const number = tc/pdcTd;
            percent = roundAccurately(number, 2);
            //this.setState({ collectionEfficiency: percent });
            return <h4>{percent} %</h4>
        } else {
            if (pdcTdLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    overduePercent({ totalOverdue, totalBook }) {
        //extract from state
        const { totalOverdueLoading } = this.state;

        if (totalOverdue && totalBook) {

            const overdueLoansPercent = this.calculatePercentage({ totalBook, rawValue: totalOverdue });

            //this.setState({ legalLoansPercent });
            return <h4>{overdueLoansPercent} %</h4>
        } else {
            if (totalOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    computeOverdueCollectionEfficiency({ odCollTd, totalOverdue }) {

        let percent = 0;
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        if (odCollTd && totalOverdue) {
            const number = (odCollTd/totalOverdue) * 100;
            percent = roundAccurately(number, 2);
            //this.setState({ collectionEfficiency: percent });
            return <h4>{percent} %</h4>
        } else {
            return <ClipLoader size={20} css={{marginLeft: 55}}/>;
        }
    }

    computeLegalLoansOverdue({ legalOverdue, totalBook}) {
        //extract from state
        const { legalOverdueLoading } = this.state;

        if (legalOverdue && totalBook) {

            const legalLoansPercent = this.calculatePercentage({ totalBook, rawValue: legalOverdue });

            //this.setState({ legalLoansPercent });
            return <h4>{legalLoansPercent} %</h4>
        } else {
            if (legalOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    computeRepossessedLoansOverdue({ repoOverdue, totalBook }) {
        //extract from state
        const { repoOverdueLoading } = this.state;

        if (repoOverdue && totalBook) {

            const repoLoansPercent = this.calculatePercentage({ totalBook, rawValue: repoOverdue });

            //this.setState({ repoLoansPercent });
            return <h4>{repoLoansPercent} %</h4>
        } else {
            if (repoOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    computeLegalRepossessedLoansOverdue({ legalOverdue, repoOverdue, totalBook }) {
        //extract from state
        const { legalOverdueLoading, repoOverdueLoading } = this.state;

        if ( legalOverdue && repoOverdue && totalBook) {

            const rawValue = legalOverdue + repoOverdue;
            const legalRepoLoansPercent = this.calculatePercentage({ totalBook, rawValue });

            //this.setState({ repoLoansPercent });
            return <h4>{legalRepoLoansPercent} %</h4>
        } else {
            if (legalOverdueLoading && repoOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    computeNormalOverdue({ normalOverdue, totalBook }) {
        //extract from state
        const { normalOverdueLoading } = this.state;

        if (normalOverdue && totalBook) {

            const normalOverduePercent = this.calculatePercentage({ totalBook, rawValue: normalOverdue });

            //this.setState({ normalOverduePercent });
            return <h4>{normalOverduePercent} %</h4>
        } else {
            if (normalOverdueLoading) {
                return <ClipLoader size={20} css={{marginLeft: 55}}/>;
            } else {
                return <h4>-</h4>;
            }
        }
    }

    calculatePercentage({ totalBook, rawValue }) {

        //change totalBook to integer
        const total = parseInt(totalBook);
        const value = parseInt(rawValue);

        //determine total book is loaded
        let percent = 0;

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        if (total !== 0) {
            const number = value * 100 / total;
            percent = roundAccurately(number, 2)
        }

        return percent;
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;
        if (profile.viewInUSD) {
            //change currency to usd
            currency = "USD";

            //check if user has selected a specific exchange rate date
            if ("exchangeRateOnDate" in profile) {
                //grab the exchange rate from profile
                const { exchangeRateOnDate } = profile;
                displayValue = value / exchangeRateOnDate;
            } else {
                //grab the default exchange rate from system
                const { exchangeRate } = systemInfo;
                displayValue = value / exchangeRate;
            }
        } else {
            currency = localCurrency;

            displayValue = value;
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4>{formatter.format(displayValue)}</h4>
    }

    displayMonth() {
        let today = moment().format("Do MMMM YYYY");

        return <h4>{today}</h4>

    }

    onDelete(target) {
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "monthlyTarget");

        if (access) {

            //check if monthly target is not empty
            if (!(_.isEmpty(target))) {
                //extract monthly target ID
                const monthlyTargetID = target.monthlyTargetID;

                this.props.deleteMonthlyTarget(monthlyTargetID);
            } else {
                message.warning("No monthly target to delete");
            }

        } else {
            message.warning("You have no authorization to delete monthly target");
        }
    }

    cancelDelete = () => {
        message.info('Deletion is canceled');
    };

    renderMessage() {

        const {monthlyTargetDeleted} = this.state;

        if (monthlyTargetDeleted) {
            return <p>{message.success("Monthly target is deleted")}</p>;
        } else {
            return null;
        }
    }

    renderSuccessMessage() {

        const {mTargetSaved} = this.state;

        if (mTargetSaved) {
            return <p>{message.success("Monthly target is saved")}</p>;
        } else {
            return null;
        }
    }

    renderSaveFailedMessage() {

        const {mTargetFailed} = this.state;

        if (mTargetFailed) {
            return <p>{message.error("Monthly target is not saved")}</p>;
        } else {
            return null;
        }
    }

    renderDeleteFailedMessage() {

        const {deleteTargetFailed} = this.state;

        if (deleteTargetFailed) {
            return <p>{message.error("Monthly target is not deleted")}</p>;
        } else {
            return null;
        }
    }

    displayTarget() {

        const {monthlyTarget, monthlyTargetLoading} = this.state;

        if (!(_.isEmpty(monthlyTarget))) {

            const target = _.map(monthlyTarget, target => {
                return  target;
            });

            return (
                <div>
                    {
                        target.map(target => {

                            return (
                                <div className={"totalValueBox"}>
                                    <Popconfirm
                                        title="Are you sure to delete this month target?"
                                        onConfirm={() => this.onDelete(target)}
                                        onCancel={() => this.cancelDelete()}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <p style={{color: '#4262e9', textDecoration: 'underline'}}>{this.displayCurrency(target.mTarget)}</p>
                                    </Popconfirm>
                                </div>
                            );
                        })
                    }
                </div>
            );


        } else {

            if (monthlyTargetLoading) {
                return (
                    <div><ClipLoader size={20} css={{marginLeft: 55}}/></div>
                );
            } else {
                return (
                    <div>
                        <MonthlyTarget/>
                    </div>
                );
            }
        }

    }


    onDeleteTotalOD(target) {
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "monthlyTarget");

        if (access) {

            //check if monthly target is not empty
            if (!(_.isEmpty(target))) {
                //extract monthly target ID
                const odTargetID = target.odTargetID;

                this.props.deleteTotalODTarget(odTargetID);
            } else {
                message.warning("No total OD target to delete");
            }

        } else {
            message.warning("You have no authorization to delete total OD target");
        }
    }

    renderODMessage() {

        const {odTargetDeleted} = this.state;

        if (odTargetDeleted) {
            return <p>{message.success("Total OD target is deleted")}</p>;
        } else {
            return null;
        }
    }

    renderODSuccessMessage() {

        const {odTargetSaved} = this.state;

        if (odTargetSaved) {
            return <p>{message.success("Total OD target is saved")}</p>;
        } else {
            return null;
        }
    }

    renderODSaveFailedMessage() {

        const {odTargetFailed} = this.state;

        if (odTargetFailed) {
            return <p>{message.error("Total OD target is not saved")}</p>;
        } else {
            return null;
        }
    }

    renderODDeleteFailedMessage() {

        const {deleteODTargetFailed} = this.state;

        if (deleteODTargetFailed) {
            return <p>{message.error("Total OD target is not deleted")}</p>;
        } else {
            return null;
        }
    }

    displayODTarget() {

        const {totalODTarget, totalODTargetLoading} = this.state;

        if (!(_.isEmpty(totalODTarget))) {

            const target = _.map(totalODTarget, target => {
                return  target;
            });

            return (
                <div>
                    {
                        target.map(target => {

                            return (
                                <div className={"totalValueBox"}>
                                    <Popconfirm
                                        title="Are you sure to delete total OD target?"
                                        onConfirm={() => this.onDeleteTotalOD(target)}
                                        onCancel={() => this.cancelDelete()}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <p style={{color: '#4262e9', textDecoration: 'underline'}}>{this.displayCurrency(target.odTarget)}</p>
                                    </Popconfirm>
                                </div>
                            );
                        })
                    }
                </div>
            );


        } else {

            if (totalODTargetLoading) {
                return (
                    <div><ClipLoader size={20} css={{marginLeft: 55}}/></div>
                );
            } else {
                return (
                    <div>
                        <TotalODTarget/>
                    </div>
                );
            }
        }

    }

    renderDueCollectionRatio(){
        const { analytics } = this.state;
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const dc = analytics.pdcTd - analytics.bouncedTd;
        let dcRatio = dc/analytics.pdcTd * 100;
        dcRatio = roundAccurately(dcRatio, 2);
        
        return dcRatio;
    }

    renderTatalCollectionRatio(){
        const { analytics } = this.state;
        console.log({tc: analytics.tc, od: analytics.openingOD, pdcTd: analytics.pdcTd})
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const totalCollection = analytics.openingOD + analytics.pdcTd;
        let tcRatio = analytics.tc/totalCollection * 100;
        tcRatio = roundAccurately(tcRatio, 2);
        
        return tcRatio;
    }


    checkUserAccess() {
        //extract state
        const { profile, totalBook, totalOverdue, totalOutstanding, totalNPL, pdcFtm,
            pdcTd,
            pdcDeposited,
            bouncedTd,
            odCollTd,
            tc,
            legalOverdue,
            repoOverdue,
            normalOverdue, totalOverdueLoading, totalOutstandingLoading, totalNPLLoading,
            pdcFtmLoading,
            pdcTdLoading,
            pdcDepositedLoading,
            bouncedTdLoading,
            odCollTdLoading,

            analyticsLoading,
            analytics,
        } = this.state;

        //calculate deliquency ratio
        //const deliquencyRatio = totalOverdue/totalBook;

        const access = profile.userAccess.some(access => access === "analytics");

        if (access) {
            return(
                <div className={"analyticsContainer"}>
                    <h1>Collections Snapshot</h1>
                    {
                        analyticsLoading ?
                            <div className={"analyticsLoaderContainer"}>
                                <ClipLoader size={50}/>
                            </div> :
                            <div>
                                <div>
                                    <div className={"analyticsOverallBox"}>
                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>Month</h2></div>
                                            <div className={"totalValueBox"}>
                                                {this.displayMonth()}
                                            </div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>Total Overdue</h2></div>
                                            <div className={"totalValueBox"}>{this.displayCurrency(analytics.totalOverdue)}</div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>PDC FTM</h2></div>
                                            <div>{this.displayCurrency(analytics.pdcFtm)}</div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>PDC TD</h2></div>
                                            <div>{this.displayCurrency(analytics.pdcTd)}</div>
                                        </div>


                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>Bounced TD</h2></div>
                                            <div className={"totalValueBox"}>{this.displayCurrency(analytics.bouncedTd)}</div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>OD COLL TD</h2></div>
                                            <div className={"totalValueBox"}>{this.displayCurrency(analytics.odCollTd)}</div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>TC</h2></div>
                                            <div className={"totalValueBox"}>{this.displayCurrency(analytics.tc)}</div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>TC Target</h2></div>
                                            <div className={"totalValueBox"}>
                                                {this.displayTarget()}
                                                {this.renderMessage()}
                                                {this.renderSuccessMessage()}
                                                {this.renderSaveFailedMessage()}
                                                {this.renderDeleteFailedMessage()}
                                            </div>
                                        </div>

                                        <div className={"totalDetailBox"}>
                                            <div className={"detailHeading"}><h2>OD Target</h2></div>
                                            <div className={"totalValueBox"}>
                                                {this.displayODTarget()}
                                                {this.renderODMessage()}
                                                {this.renderODSuccessMessage()}
                                                {this.renderODSaveFailedMessage()}
                                                {this.renderODDeleteFailedMessage()}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={"analyticsBox"}>
                                    <div className={"totalNPLBox"}>
                                        <h2>Total Book</h2>
                                        <div>{this.displayCurrency(analytics.totalBook)}</div>
                                    </div>

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*<h2>Total Outstanding</h2>*/}
                                    {/*{*/}
                                    {/*totalOutstandingLoading?*/}
                                    {/*<div><Spinner/></div>:*/}
                                    {/*<div>{this.displayCurrency(totalOutstanding)}</div>*/}
                                    {/*}*/}
                                    {/*</div>*/}

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*<h2>Total NPL</h2>*/}
                                    {/*{*/}
                                    {/*totalNPLLoading?*/}
                                    {/*<div><Spinner/></div>:*/}
                                    {/*<div>{this.displayCurrency(totalNPL)}</div>*/}
                                    {/*}*/}
                                    {/*</div>*/}

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*<h2>PDC Deposited</h2>*/}
                                    {/*{*/}
                                    {/*pdcDepositedLoading?*/}
                                    {/*<div><Spinner/></div>:*/}
                                    {/*<div>{this.displayCurrency(pdcDeposited)}</div>*/}
                                    {/*}*/}
                                    {/*</div>        */}

                                    <div className={"totalNPLBox"}>
                                        <h2>NPL %</h2>
                                        <h4>{analytics.nplPercent}</h4>
                                        {/*{ this.computeNPL({totalBook, totalNPL})}*/}
                                    </div>

                                    <div className={"totalNPLBox"}>
                                        <h2>Deliquency Ratio %</h2>
                                        <h4>{analytics.deliquencyRatio}</h4>
                                    </div>

                                    <div className={"totalNPLBox"}>
                                        <h2>Collection Efficiency %</h2>
                                        <h4>{analytics.collectionEfficiency}%</h4>
                                    </div>

                                    {/* Requirements 5 c, f, g */}
                                    <div className={"totalNPLBox"}>
                                        <h2>Overdue Coll. Efficiency %</h2>
                                        <h4>{analytics.overdueCollectionEfficiency}%</h4>
                                    </div>

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*    <h2>Legal Overdue %</h2>*/}
                                    {/*    <h4>{analytics.legalLoansPercent}%</h4>*/}
                                    {/*</div>*/}

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*    <h2>Repo Overdue %</h2>*/}
                                    {/*    <h4>{analytics.repoLoansPercent}%</h4>*/}
                                    {/*</div>*/}

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*    <h2>LegalRepo Overdue %</h2>*/}
                                    {/*    <h4>{analytics.legalRepoLoansPercent}%</h4>*/}
                                    {/*</div>*/}

                                    {/*<div className={"totalNPLBox"}>*/}
                                    {/*    <h2>Normal Overdue %</h2>*/}
                                    {/*    <h4>{analytics.normalOverduePercent}%</h4>*/}
                                    {/*</div>*/}

                                    <div className={"totalNPLBox"}>
                                        <h2>Due Collections %</h2>
                                        <h4>{this.renderDueCollectionRatio()}%</h4>
                                        {/* <h4>{analytics.dueCollectionPercent}%</h4> */}
                                    </div>

                                    <div className={"totalNPLBox"}>
                                        <h2>Total Collections %</h2>
                                        <h4>{this.renderTatalCollectionRatio()}%</h4>
                                        {/* <h4>{analytics.tcPercent}%</h4> */}
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    };

    render() {

        return(
            <div className={"analyticsContainer"}>
                { this.checkUserAccess() }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { totalOverdue, totalOutstanding, totalNPL, totalBookLoading, totalOverdueLoading, totalOutstandingLoading, pdcFtm, pdcTd, pdcDeposited,
        bouncedTd, odCollTd, pdcFtmLoading, pdcTdLoading, pdcDepositedLoading, bouncedTdLoading, odCollTdLoading, legalOverdue, repoOverdue,
        normalOverdue, legalOverdueLoading, repoOverdueLoading, normalOverdueLoading, monthlyTarget, mTargetSaved, monthlyTargetLoading, monthlyTargetDeleted,
        analyticsLoading, analytics, deleteTargetFailed, mTargetFailed, dueCollection, dueCollectionLoading, totalODTarget, odTargetSaved, totalODTargetLoading, odTargetDeleted, deleteODTargetFailed,
        odTargetFailed,
    } = state.analytics;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        analyticsLoading,
        analytics,


        profile,
        systemInfo,
        totalOverdue,
        totalOutstanding,
        totalNPL,

        pdcFtm,
        pdcTd,
        pdcDeposited,
        bouncedTd,
        odCollTd,
        legalOverdue,
        repoOverdue,
        normalOverdue,
        dueCollection,

        totalBookLoading,
        totalOverdueLoading,
        totalOutstandingLoading,

        pdcFtmLoading,
        pdcTdLoading,
        pdcDepositedLoading,
        bouncedTdLoading,
        odCollTdLoading,
        legalOverdueLoading,
        repoOverdueLoading,
        normalOverdueLoading,
        dueCollectionLoading,

        monthlyTarget,
        mTargetSaved,
        monthlyTargetLoading,
        monthlyTargetDeleted,
        deleteTargetFailed,
        mTargetFailed,

        totalODTarget,
        odTargetSaved,
        totalODTargetLoading,
        odTargetDeleted,
        deleteODTargetFailed,
        odTargetFailed,
    };
};

export default connect(mapStateToProps, { fetchTotalBook, fetchOverdue, fetchOutstanding, fetchNPL, fetchPdcFtm, fetchPdcTd, fetchPdcDeposited, fetchBouncedTd,
    fetchODCollTd, fetchLegalOverdue, fetchRepoOverdue, fetchNormalOverdue, fetchMonthlyTarget, deleteMonthlyTarget, fetchAnalytics, fetchTotalODTarget, deleteTotalODTarget })(Analytics);
