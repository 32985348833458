import React from 'react';
// import {connect} from 'react-redux';
import _ from 'lodash';

//actions import

//components import
import CustomerList from "./CustomerList";


class DistinctCustomersCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customers: this.props.customers,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.customers !== this.props.customers) {
            this.setState({customers: nextProps.customers})
        }
    }

    render() {
        const {customers} = this.state;
        console.log(customers);

        const clients = _.map(customers, loan => {
            return loan
        });

        return (
            <div>
                {
                    clients.map(loan => {
                        console.log(loan);

                        return (
                            <div>
                               <CustomerList
                                   loan={loan}
                               />
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}


export default (DistinctCustomersCard);
