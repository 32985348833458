import React from "react";
import {connect} from 'react-redux';
import {Button, message, Modal, Switch} from "antd";

//action imports
import {monthlyTargetDetailsChanged, saveTotalODTarget} from "../../actions/AnalyticsActions";
import {ClipLoader} from "react-spinners";
import moment from "moment";

//components imports
import {Input} from "../common/Input";


class TotalODTarget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            profile: this.props.profile,
            currency: this.props.currency,
            odTarget: this.props.odTarget,
            odTargetLoading: this.props.odTargetLoading,
            odTargetSaved: this.props.odTargetSaved,
        }
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }

        if (nextProps.odTarget !== this.props.odTarget) {
            this.setState({odTarget: nextProps.odTarget})
        }

        if (nextProps.odTargetLoading !== this.props.odTargetLoading) {
            this.setState({odTargetLoading: nextProps.odTargetLoading})
        }

        if (nextProps.odTargetSaved !== this.props.odTargetSaved) {
            this.setState({odTargetSaved: nextProps.odTargetSaved})
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.monthlyTargetDetailsChanged({prop: 'currency', value: status})
    }

    renderButton() {

        const {odTargetLoading} = this.state;

        if (odTargetLoading) {
            return <ClipLoader size={20} css={{marginLeft: 55}}/>;
        } else {
            return (
                <div>
                    <Button onClick={()=> this.onSave()}>Save</Button>
                </div>
            );
        }
    }

    renderMessage() {

        const {odTargetSaved} = this.state;

        if (odTargetSaved) {
            return <p>{message.success("Total Overdue target is saved")}</p>;
        } else {
            return null;
        }
    }

    onSave() {
        let {odTarget, currency, profile} = this.state;

        const access = profile.userAccess.some(access => access === "monthlyTarget");

        if (access) {

            //determine cash collection is tzs or usd
            let rawCurrency;
            if (currency) {
                rawCurrency = "usd";
            } else {
                rawCurrency = "tzs";
            }

            //create timestamp when monthly target was posted
            const timeStamp = new Date();
            const month = moment().format("MM");
            const year = moment().format("YYYY");
            console.log(month, year);

            const now = moment();
            const ID = moment(now).unix();
            const odTargetID = ID.toString();

            if (odTarget) {

                odTarget = parseInt(odTarget);
                this.props.saveTotalODTarget({odTargetID, odTarget, currency: rawCurrency, timeStamp, month, year });
            } else {
                return <p>{message.warning("Please enter total OD target amount")}</p>
            }

        } else {
            message.warning("You have no authorization to save total OD target");
        }
    }


    render() {
        return (
            <div className={"monthlyTargetContainer"}>
                <Button type="default" onClick={this.showModal}>
                    Total OD Target
                </Button>
                <Modal
                    title="Add Total Overdue Target"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    <div className={"modalBox monthlyTargetModal"}>
                        <div>
                            <p>Currency</p>
                            <Switch
                                checkedChildren="USD"
                                unCheckedChildren="TSH"
                                onChange={() => this.onCurrencySwitchChange() }
                                checked={this.state.currency}
                            />
                        </div>
                        <div>
                            <Input
                                label={"Amount"}
                                type={"number"}
                                onChange={e => this.props.monthlyTargetDetailsChanged({prop: 'odTarget', value: e.target.value})}
                                value={this.state.odTarget}
                            />
                        </div>
                        <div>
                            {this.renderButton()}
                        </div>
                        <div>
                            {this.renderMessage()}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const {odTarget, currency, odTargetLoading, odTargetSaved} = state.analytics;
    const { profile } = state.profile;

    return {
        profile,
        odTarget,
        currency,
        odTargetLoading,
        odTargetSaved,
    }
};

export default connect(mapStateToProps, {monthlyTargetDetailsChanged, saveTotalODTarget})(TotalODTarget);