import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'
import _ from "lodash";
import {message} from "antd";

//actions imports
import { pushToCustomerDetails } from "../../../actions/CollectionsActions";

//components imports
import {Button} from "../../common/Button";
import SelectCustomers from "./SelectCustomers";
import {BeatLoader} from "react-spinners";

class ChooseCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedCustomer: this.props.fetchedCustomer,

            cashCollectionSavedSuccessful: this.props.cashCollectionSavedSuccessful,
            cashCollectionSavedFailed: this.props.cashCollectionSavedFailed,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer) {
            this.setState({ fetchedCustomer: nextProps.fetchedCustomer })
        }

        if (nextProps.cashCollectionSavedSuccessful !== this.props.cashCollectionSavedSuccessful) {
            this.setState({cashCollectionSavedSuccessful: nextProps.cashCollectionSavedSuccessful})
        }

        if (nextProps.cashCollectionSavedFailed !== this.props.cashCollectionSavedFailed) {
            this.setState({cashCollectionSavedFailed: nextProps.cashCollectionSavedFailed})
        }
    }


    onSubmit() {
        const { fetchedCustomer } = this.state;
        //check if there's a fetched customer is not empty
        if (!(_.isEmpty(fetchedCustomer))) {
            console.log('there is a customer');
            //there's a fetched customer

            this.props.pushToCustomerDetails({ customerID: fetchedCustomer.customerID });
        } else {
            message.warning('There is no a customer');
        }
    }


    render() {
        const { cashCollectionSavedSuccessful, cashCollectionSavedFailed } = this.state;
        return (
            <div className={"collectionsSearchCustomerContainer"}>
                <div className={"collectionsSearchCustomerHeaderBox"}>
                    <h2>Search for a customer</h2>
                </div>
                <div className={"collectionsSelectCustomer"}>
                    <SelectCustomers
                        placeholder={'Enter customer ID/name'}
                        label={'Select Customer'}
                    />
                </div>
                <div className={"collectionsSearchCustomerButton"}>
                    {
                        this.props.searchCustomerLoading?
                            <BeatLoader/>:
                            <Button
                                text={'Submit'}
                                onPress={ ()=> this.onSubmit() }
                            />
                    }
                </div>
                <div>
                    <div>
                        {
                            cashCollectionSavedSuccessful?
                                <p>{message.success("cash collection is successfully saved")}</p> :
                                null
                        }
                    </div>
                    <div>
                        {
                            cashCollectionSavedFailed?
                                <p>{message.success(" saving cash collection is failed")}</p> :
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { fetchedCustomer, searchCustomerLoading, cashCollectionSavedSuccessful, cashCollectionSavedFailed } = state.collections;

    return {
        //
        fetchedCustomer,
        searchCustomerLoading,

        cashCollectionSavedSuccessful,
        cashCollectionSavedFailed,
    }
};

export default withRouter(connect(mapStateToProps, { pushToCustomerDetails })(ChooseCustomer));