import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { message, Popconfirm, Button} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//action imports
import { addRepoStatusValue, changedRepoStatusValue, deleteRepoStatus } from '../../../actions/SettingsActions';
 
//component imports
import { Input } from '../../common/Input';
import { BeatLoader } from "react-spinners";

//file imports
import {fetchRepoStatus} from "../../../actions/SettingsActions";


class Status extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            repoStatusValue: this.props.repoStatusValue,
            repoStatusLoading: this.props.repoStatusLoading,
            repoStatusSuccess: this.props.repoStatusSuccess,
            repoStatusFailed: this.props.repoStatusFailed,
            reposStatus: this.props.reposStatus,
        };

        this.onAddRepoStatusValue = this.onAddRepoStatusValue.bind(this);
        this.onDeleteRepoStatusValue = this.onDeleteRepoStatusValue.bind(this);
    }

    componentDidMount() {
        this.props.fetchRepoStatus();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.repoStatusValue !== this.props.repoStatusValue) {
            this.setState({ repoStatusValue: nextProps.repoStatusValue });
        }
        if (nextProps.repoStatusLoading !== this.props.repoStatusLoading) {
            this.setState({ repoStatusLoading: nextProps.repoStatusLoading });
        }
        if (nextProps.repoStatusSuccess !== this.props.repoStatusSuccess) {
            this.setState({ repoStatusSuccess: nextProps.repoStatusSuccess });
        }
        if (nextProps.repoStatusFailed !== this.props.repoStatusFailed) {
            this.setState({ repoStatusFailed: nextProps.repoStatusFailed });
        }
        if (nextProps.reposStatus !== this.props.reposStatus) {
            this.setState({ reposStatus: nextProps.reposStatus });
        }

    }

    onAddRepoStatusValue() {
        const { repoStatusValue } = this.state;

        if (repoStatusValue) {
            //
            this.props.addRepoStatusValue({ repoStatusValue })
        } else {
            message.warning("Kindly enter all fields");
        }
    }

    renderButton() {
        //extract state
        const { repoStatusLoading, } = this.state;

        if (repoStatusLoading) {
            return (
                <div>
                    <BeatLoader />
                </div>
            );
        } else {
            return (
                <div className={"masterFileUploadButton"}>
                    <Button onClick={() => this.onAddRepoStatusValue()}>Add</Button>
                </div>
            );
        }
    }


    onDeleteRepoStatusValue(title) {
        //
        if (title){
            const statusID = title.statusID;
            this.props.deleteRepoStatus({statusID});
        } else {
            message.warn("There is no status to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderRepoStatus() {
        const {reposStatus} = this.state;

        const titleArray = _.map(reposStatus, title => title);

        if(titleArray.size !== 0) {
            return (
                <div>
                    {
                        titleArray.map(title => {
                            
                            const index = titleArray.indexOf(title);
                            const position = index + 1;
                            return (
                                <div key={title.statusID} className={"industryListValue"}>
                                    <div><p>{position}</p></div>
                                    <div><p>{title.value}</p></div>

                                        <div>
                                        <Popconfirm
                                                title="Are you sure to delete this status?"
                                                onConfirm={() => this.onDeleteRepoStatusValue(title)}
                                                onCancel={() => this.cancel()}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                            </Popconfirm>
                                        </div>
                                    
                                    </div>
                                    );
                            })
                        }
                </div>
            );
        } else {
            return <h4>There is no data found</h4>
        }
    }

    // takeMeBack(){
    //     window.history.back();
    // }

    render() {
        return (
            <div className={"industryContainer"}>
                {/* <div className={"masterFileUploadButton"}>
                    <Button onClick={() => this.takeMeBack()}>Take Me Back</Button>
                </div> */}
                
                    <h2>Enter Repo Status Value</h2>

                    <Input 
                        type={"text"}
                        placeholder={"Repo status"}
                        onChange={e => this.props.changedRepoStatusValue({ prop: 'repoStatusValue', value: e.target.value })}
                        value={this.state.repoStatusValue}
                    />
                <div>
                    {this.renderButton()}
                </div>

                <div>
                    <div>
                    <div className={"industryList"}>
                        <div><h5>#</h5></div>
                        <div><h5>Status terms</h5></div>
                        <div><h5>Delete terms</h5></div>
                    </div>
                        <div>{this.renderRepoStatus()}</div>
                    </div>
                </div>

            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

    const { repoStatusValue, repoStatusLoading,repoStatusSuccess,repoStatusFailed, reposStatus } = state.settings;

    //return object with state properties
    return {
        repoStatusValue,
        repoStatusLoading,
        repoStatusSuccess,
        repoStatusFailed,
        reposStatus,
    }
};


export default connect(mapStateToProps, {changedRepoStatusValue, addRepoStatusValue, fetchRepoStatus, deleteRepoStatus })(Status);
