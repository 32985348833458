import * as firebase from 'firebase';
import moment from "moment";
import _ from "lodash";

import {
    DELETE_CASH_COLLECTION_FAILED,
    DELETE_CASH_COLLECTION_SUCCESSFUL,
    DELETE_UNIDENTIFIED_PAYMENT,
    DELETE_UNIDENTIFIED_PAYMENT_FAILED,
    DELETE_UNIDENTIFIED_PAYMENT_SUCCESSFUL,
    FETCH_IDENTIFIED_CUSTOMER,
    FETCH_IDENTIFIED_CUSTOMER_FAILED,
    FETCH_IDENTIFIED_CUSTOMER_LOANS,
    FETCH_IDENTIFIED_CUSTOMER_LOANS_FAILED,
    FETCH_IDENTIFIED_CUSTOMER_LOANS_SUCCESSFUL,
    FETCH_IDENTIFIED_CUSTOMER_SUCCESSFUL,
    FETCH_UNIDENTIFIED_PAYMENTS,
    FETCH_UNIDENTIFIED_PAYMENTS_FAILED,
    FETCH_UNIDENTIFIED_PAYMENTS_SUCCESSFUL,
    IDENTIFIED_CUSTOMER_BANK_DATE,
    IDENTIFIED_CUSTOMER_DETAILS_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_LIST_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_TERM_DETAILS_CHANGED,
    IDENTIFY_PAYMENT,
    IDENTIFY_PAYMENT_FAILED,
    IDENTIFY_PAYMENT_SUCCESSFUL,
    PUSH_IDENTIFIED_CUSTOMER_CURRENT_LOAN,
    PUSH_UNIDENTIFIED_PAYMENT,
    RE_FETCH_UNIDENTIFIED_PAYMENTS,
    SAVE_CASH_COLLECTION,
    SAVE_CASH_COLLECTION_FAILED,
    SAVE_CASH_COLLECTION_SUCCESSFUL,
    SAVE_UNIDENTIFIED_PAYMENTS,
    SAVE_UNIDENTIFIED_PAYMENTS_FAILED,
    SAVE_UNIDENTIFIED_PAYMENTS_SUCCESSFUL,
    SEARCH_UNIDENTIFIED_CUSTOMER_FAILED,
    SEARCH_UNIDENTIFIED_CUSTOMER_NO_RECORDS,
    SEARCH_UNIDENTIFIED_CUSTOMER_SUCCESSFUL,
    UNIDENTIFIED_DATE_CHANGED,
    UNIDENTIFIED_DETAILS_CHANGED,
    UNIDENTIFIED_PAYMENT_CURRENCY,
} from "./Types";
import {message} from "antd";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const unidentifiedPaymentDetailsChanged = ({prop, value}) => {

    return{
        type: UNIDENTIFIED_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const unidentifiedPaymentDateChanged = (date) => {

    return{
        type: UNIDENTIFIED_DATE_CHANGED,
        payload: date
    }
};

export const customerDetailsChanged = ({prop, value}) => {

    return{
        type: IDENTIFIED_CUSTOMER_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const customerLoanListChanged = (value) => {

    return{
        type: IDENTIFIED_CUSTOMER_LOAN_LIST_CHANGED,
        payload: value
    }
};

export const customerLoanTermChanged = (value) => {

    return{
        type: IDENTIFIED_CUSTOMER_LOAN_TERM_DETAILS_CHANGED,
        payload: value
    }
};

export const loanTermCardDetailsChanged = ({prop, value}) => {

    return{
        type: IDENTIFIED_CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const bankDateChanged = (date) => {

    return{
        type: IDENTIFIED_CUSTOMER_BANK_DATE,
        payload: date
    }
};

export const unidentifiedPaymentCurrencyChanged = (status) => {

    return{
        type: UNIDENTIFIED_PAYMENT_CURRENCY,
        payload: status
    }
};



export const searchExistingCustomers = (value) => {

    //check if value is not a number (customer name)
    let searchProperty;
    let searchValue;
    if (isNaN(value)) {
        //its a customer name
        //title case the value string
        //searchValue = value.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        searchValue = value.toUpperCase();
        searchProperty = 'customerName'
    } else {
        searchValue = value;
        searchProperty = 'customerID';
    }

    return(dispatch) => {
        firebase.firestore().collection("customerBucket").where(searchProperty, "==", searchValue)
            .get()
            .then(function(querySnapshot) {

                //check if query has no result
                if (querySnapshot.size !== 0) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        const data = doc.data();

                        dispatch({
                            type: SEARCH_UNIDENTIFIED_CUSTOMER_SUCCESSFUL,
                            payload: data
                        })

                    });
                } else {
                    //no record for query
                    dispatch({
                        type: SEARCH_UNIDENTIFIED_CUSTOMER_NO_RECORDS,
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
                dispatch({
                    type: SEARCH_UNIDENTIFIED_CUSTOMER_FAILED,
                })
            });
    }

};


export const pushToCustomerDetails = ({ history, customerID }) => {

    return(dispatch) => {
        //fetch customer profile
        dispatch({ type: FETCH_IDENTIFIED_CUSTOMER});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("account").doc("info")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: FETCH_IDENTIFIED_CUSTOMER_SUCCESSFUL,
                        payload: data
                    });
                    history.push('/identified-customer');
                } else {
                    console.log("account doc doesn't exists");
                    dispatch({ type: FETCH_IDENTIFIED_CUSTOMER_FAILED })
                }
            })

    }
};

export const saveUnidentifiedPayment = ({ unidentifiedPaidAmount, unidentifiedBankName, unidentifiedBankDate, unidentifiedComments, currency }) => {

    const ID = moment().unix();
    const unIdentifiedID = ID.toString();

    return(dispatch) => {
        dispatch({ type: SAVE_UNIDENTIFIED_PAYMENTS });
        firebase.firestore().collection("unidentifiedPayments").doc(unIdentifiedID)
            .set({ unidentifiedPaidAmount, unidentifiedBankName, unidentifiedBankDate, unidentifiedComments, unIdentifiedID , currency})
            .then(() => {
                console.log("unidentified is saved");
                callUnIdentifiedPayments({dispatch});
                dispatch({ type: SAVE_UNIDENTIFIED_PAYMENTS_SUCCESSFUL });
            })
            .catch(error => {
                console.log(error);
                console.log("unidentified is not saved to bucket");
                dispatch({ type: SAVE_UNIDENTIFIED_PAYMENTS_FAILED });
            })
    };
};


export const fetchUnIdentifiedPayments = () => {

    return(dispatch) => {

        callUnIdentifiedPayments({dispatch})
    };
};

function callUnIdentifiedPayments({dispatch}) {

    dispatch({ type: FETCH_UNIDENTIFIED_PAYMENTS });
    //create loans obj
    let unIdentifiedPayments = {};

    firebase.firestore().collection("unidentifiedPayments")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {

                //add loan too loans object
                unIdentifiedPayments[doc.id] = doc.data();

            });

            dispatch({
                type: FETCH_UNIDENTIFIED_PAYMENTS_SUCCESSFUL,
                payload: unIdentifiedPayments
            });

        }, function (error) {
            console.log('could not get unIdentifiedPayments');
            console.log(error);

            dispatch({
                type: FETCH_UNIDENTIFIED_PAYMENTS_FAILED
            });
        })
}

export const fetchLoans = ({ customerID }) => {
    return(dispatch) => {

        dispatch({ type: FETCH_IDENTIFIED_CUSTOMER_LOANS });

        //create loans obj
        let loans = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans")
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    console.log(doc.id, " => ", doc.data());

                    //add loan too loans object
                    loans[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_IDENTIFIED_CUSTOMER_LOANS_SUCCESSFUL,
                    payload: loans
                });
            }, function (error) {
                console.log('could not get loans');
                console.log(error);

                dispatch({
                    type: FETCH_IDENTIFIED_CUSTOMER_LOANS_FAILED
                });
            })

    };
};

export const pushCurrentLoan = (loan) => {

    return{
        type: PUSH_IDENTIFIED_CUSTOMER_CURRENT_LOAN,
        payload: loan
    }
};


export const pushUnIdentifiedPayment = (payment) => {

    return{
        type: PUSH_UNIDENTIFIED_PAYMENT,
        payload: payment
    }
};



export const identifyPayment = ({ customerID, customerName, loanID, unIdentifiedID, paidAmount, bankName, bankDate, comment, currency, history  }) => {

    const cashCollectionID = `${loanID}${unIdentifiedID}`;

    return(dispatch) => {
        dispatch({ type: IDENTIFY_PAYMENT });
        //remove payment from unidentified payments bucket
        firebase.firestore().collection('unidentifiedPayments').doc(unIdentifiedID).delete()
            .then(() => {
                //post payment to customer profile
                // saveCashCollection({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, bankDate, comment, currency, lastEdit, dispatch, history })
                writeCashCollection({customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, comment, currency, bankDate, history, dispatch});
            })
            .catch(error => {
                console.log("Couldn't delete unidentified payment");
                console.log(error);
                dispatch({ type: IDENTIFY_PAYMENT_FAILED });
            })
    };
};

function writeCashCollection({customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, comment, currency, bankDate, history, dispatch}) {

    const data = JSON.stringify({
        customerID,
        customerName,
        loanID,
        cashCollectionID,
        paidAmount,
        bankName,
        comment,
        currency,
        bankDate,
        event: "saveIdentifiedCashCollection"
    });

    const url = `${project.serverUrl}saveCashCollection`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            console.log("payment is identified");
            dispatch({ type: IDENTIFY_PAYMENT_SUCCESSFUL });
            history.push("/unidentified-page");

        }).catch((error) => {
        console.log("payment is identified");
        dispatch({ type: IDENTIFY_PAYMENT_SUCCESSFUL });
        history.push("/unidentified-page");
        console.log(error);
    })
}

// function saveCashCollection ({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName, bankDate, comment, currency, lastEdit, dispatch, history }) {
//     //
//     const logDate = new Date();
//
//     firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("logs").doc(cashCollectionID)
//         .set({ customerID, loanID, logID: cashCollectionID, triggerEventID: cashCollectionID, paidAmount, logDate, event: "saveIdentifiedCashCollection" })
//         .then(() => {
//             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
//                 .set({ cashCollectionID, loanID, paidAmount, bankName, bankDate, comment, currency, lastEdit })
//                 .then(() => {
//                     // save loan term to term bucket
//                     saveCashCollectionToBucket({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit, history, dispatch });
//                 })
//                 .catch(error => {
//                     console.log(error);
//                     console.log("payment is not identified in customer profile");
//                     dispatch({ type: IDENTIFY_PAYMENT_FAILED });
//                 })
//         })
//         .catch(error => {
//             console.log(error);
//             console.log("identified payment is not logged");
//             dispatch({ type: IDENTIFY_PAYMENT_FAILED });
//         })
// }
//
//
// function saveCashCollectionToBucket({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit, history, dispatch }) {
//
//     firebase.firestore().collection("cashCollections").doc(cashCollectionID)
//         .set({ customerID, customerName, loanID, paidAmount, bankName, bankDate, cashCollectionID, comment, currency, lastEdit })
//         .then(() => {
//             console.log("payment is identified");
//             dispatch({ type: IDENTIFY_PAYMENT_SUCCESSFUL });
//             history.push("/unidentified-page");
//             computeCashPayment({ customerID, loanID, paidAmount, cashCollectionID, lastEdit });
//         })
//         .catch(error => {
//             console.log(error);
//             console.log("payment is not identified in bucket");
//             dispatch({ type: IDENTIFY_PAYMENT_FAILED });
//         });
// }
//
// function computeCashPayment({ customerID, loanID, paidAmount, cashCollectionID, lastEdit }) {
//     firebase.firestore().collection("loans").doc(loanID).get()
//         .then((doc) => {
//             if (doc.exists) {
//                 const data = doc.data();
//
//                 const oldTotalCashPaid = data.totalCashPaid;
//                 //calculate total paid
//                 const oldTotalPaid = data.totalPaid;
//
//                 //add paid amount to old total cash paid
//                 const totalCashPaid = paidAmount + oldTotalCashPaid;
//
//                 //add paid amount to old total paid
//                 const totalPaid = paidAmount + oldTotalPaid;
//
//                 //check if loan has been cleared or not
//                 let loanStatus = false;
//                 const totalAmount = data.totalAmount;
//                 //find if there are extra fees and their total
//                 let totalArray = [];
//                 if ("extraFees" in data) {
//                     //there are extra fees, loop over each extra fee and put amount into amountArray
//                     _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
//                 }
//                 let totalFeesAmount = 0;
//                 //calculate the total amount from numbers in the array
//                 //check that total array is not empty
//                 if (totalArray.length > 0) {
//                     totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
//                 }
//                 let debt = totalAmount + totalFeesAmount;
//                 if ("earlyLiquidation" in data) {
//                     debt = data.earlyLiquidation;
//                 }
//                 const clearedAmount = debt - totalPaid;
//                 //if clearedAmount is below zero then debt is cleared
//                 if (clearedAmount <= 0) {
//                     loanStatus = true;
//                     //assign loanStatus true to all terms in this loan
//                     assignClearedLoanStatus({ customerID, loanID });
//                 }
//
//                 //register currentPaid
//                 let oldCurrentPaid = 0;
//                 if ("currentPaid" in data) {
//                     oldCurrentPaid = data.currentPaid;
//                 }
//                 const currentPaid = paidAmount + oldCurrentPaid;
//
//                 //post to loan profile on loan bucket and customer profile
//                 firebase.firestore().collection("loans").doc(loanID).update({ totalCashPaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: lastEdit, cheque: false  } })
//                     .then(() => {
//                         console.log("calculations saved to bucket");
//                         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                             .update({ totalCashPaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: lastEdit, cheque: false  } })
//                             .then(() => {
//                                 console.log("calculations saved to loan in profile");
//                                 storeCurrentPaid({ loanID, cashCollectionID, customerID, paidAmount });
//                             }).catch((error) => {
//                             console.log("error in saving calculations to loan in profile");
//                             console.log(error);
//                         });
//                     }).catch((error) => {
//                     console.log("error in saving calculations to loan in bucket");
//                     console.log(error);
//                 });
//
//             }
//         }).catch(e => e);
// }
//
// function storeCurrentPaid({ loanID, cashCollectionID, customerID, paidAmount }) {
//
//     const currentPaidID = cashCollectionID;
//
//     //save current paid  to bucket
//     firebase.firestore().collection("currentPaidBucket").doc(currentPaidID)
//         .set({ loanID, customerID, paidAmount, currentPaidID })
//         .then(() => {
//             console.log("current paid is saved to bucket");
//             //update current paid amount on loan bucket and user loan path
//
//         }).catch((error) => {
//         console.log("error in saving current paid to loan bucket");
//         console.log(error);
//     });
// }
//
// function assignClearedLoanStatus({ customerID, loanID }) {
//     //fetch loan terms ids
//     //create loan terms obj
//     let loanTerms = {};
//
//     firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
//         .onSnapshot(function(querySnapshot) {
//             querySnapshot.forEach(function(doc) {
//                 // doc.data() is never undefined for query doc snapshots
//                 loanTerms[doc.id] = doc.data();
//             });
//         }, function (error) {
//             console.log("Error getting documents: ", error);
//         });
//
//     //loop over all loan terms
//     _.map(loanTerms, term => {
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
//             .update({ loanStatus: true, [`termStatus.status`]: true  })
//             .then(() => {
//                 firebase.firestore().collection("loanTerms").doc(term.loanTermID)
//                     .update({ loanStatus: true, [`termStatus.status`]: true  })
//                     .then(() => {
//                         //
//                     })
//                     .catch(e => {
//                         console.log(e);
//                     })
//             }).catch(e => console.log(e))
//     })
// }


export const deleteUnidentifiedPayment = ({unIdentifiedID}) => {

    return(dispatch) => {

        dispatch({type: DELETE_UNIDENTIFIED_PAYMENT});
        firebase.firestore().collection("unidentifiedPayments").doc(unIdentifiedID)
            .delete()
            .then(() => {
                console.log("unidentified payment is deleted to bucket");
                dispatch({ type: DELETE_UNIDENTIFIED_PAYMENT_SUCCESSFUL });
                //fetch unidentified payments

                //create loans obj
                let unIdentifiedPayments = {};

                firebase.firestore().collection("unidentifiedPayments")
                    .onSnapshot(function(querySnapshot) {
                        querySnapshot.forEach(function(doc) {

                            //add loan too loans object
                            unIdentifiedPayments[doc.id] = doc.data();

                        });

                        dispatch({
                            type: RE_FETCH_UNIDENTIFIED_PAYMENTS,
                            payload: unIdentifiedPayments
                        });

                    }, function (error) {
                        console.log('could not get unIdentifiedPayments');
                        console.log(error);
                    })
            })
            .catch(error => {
                console.log(error);
                console.log("unidentified payment is NOT deleted to bucket");
                dispatch({ type: DELETE_UNIDENTIFIED_PAYMENT_FAILED });
            })
    }
};
