import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Select, Spin } from 'antd';


//action imports
import { customerDetailsChanged, searchExistingCustomers } from "../../actions/CustomerActions";

//variable declaration
const { Option } = Select;

class SelectCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            customerSearch: this.props.customerSearch,
            fetching: this.props.fetching,

            fetchedCustomer: this.props.fetchedCustomer,
            noRecord: this.props.noRecord,
        };

        this.searchCustomer = this.searchCustomer.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props){
            this.setState({
                customerSearch: nextProps.customerSearch,
                fetching: nextProps.fetching,

                fetchedCustomer: nextProps.fetchedCustomer,
                noRecord: nextProps.noRecord
            })
        }
    }

    handleChange = value => {
        //send to redux
        this.props.customerDetailsChanged({ prop: 'customerSearch', value });
    };

    searchCustomer(value) {
        //clear previous data
        //set fetching to true
        //data returned is a set of objects in array
        this.props.searchExistingCustomers(value);
    }


    render() {

        const { customerSearch, fetching, fetchedCustomer, } = this.state;

        return (
            <div>
                <div className={"selectCustomerLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    labelInValue
                    value={customerSearch}
                    showSearch
                    style={{ width: 460}}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    filterOption={false}
                    onChange={this.handleChange}
                    onSearch={this.searchCustomer}
                >

                    <Option key={fetchedCustomer.customerID}>{fetchedCustomer.customerName}  {fetchedCustomer.customerID}</Option>

                </Select>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { customerSearch, fetching, fetchedCustomer, noRecord } = state.customer;

    return {
        //
        customerSearch,
        fetching,
        fetchedCustomer,
        noRecord
    }
};


export default connect(mapStateToProps, { customerDetailsChanged, searchExistingCustomers })(SelectCustomer);
