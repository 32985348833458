//app types
export const SET_INITIAL_SEETINGS = "set_intial_settings";
export const SET_INITIAL_SEETINGS_SUCCESSFUL = "set_intial_settings_successful";
export const SET_INITIAL_SEETINGS_FAILED = "set_initial_settings_failed";

export const CHECK_CHEQUE_CLEARANCE = "check_cheque_clearance";
export const CHECK_CHEQUE_CLEARANCE_SAME_DAY = "check_cheque_clearance_same_day";
export const CHECK_CHEQUE_CLEARANCE_DIFFERENT_DAY = "check_cheque_clearance_different_day";
export const CHECK_CHEQUE_CLEARANCE_FAILED = "check_cheque_clearance_failed";

export const CLEAR_CHEQUES = "clear_cheque";
export const CLEAR_CHEQUES_SUCCESSFUL = "clear_cheque_successful";
export const CLEAR_CHEQUES_FAILED = "clear_cheque_failed";


export const FETCH_ADMINS = "fetch_admins";
export const FETCH_ADMINS_SUCCESSFUL = "fetch_admins_successful";
export const FETCH_ADMINS_FAILED = "fetch_admins_failed";

export const FETCH_LOAN_WITH_SURPLUS_SUCCESSFUL = "fetch_loan_with_surplus_successful";

export const CREDENTIALS_CHANGED = "credentials_changed";
export const LOGIN_USER = "login_user";
export const LOGIN_SUCCESSFUL = "login_successful";
export const LOGIN_FAILED = "login_failed";
export const LOGOUT_SUCCESSFULLY ="logout_successfully";
export const LOGOUT_FAILED = "logout_failed";
export const CLEAR_LOGOUT_MESSAGES = "clear_logout_messages";

export const FORGOT_PASSWORD_DETAILS_CHANGED = "forgot_password_details_changed";

export const FORGOT_PASSWORD_EMAIL_SENT = "forgot_password_email_sent";
export const FORGOT_PASSWORD_EMAIL_SENT_SUCCESSFUL = "forgot_password_email_sent_successful";
export const FORGOT_PASSWORD_EMAIL_SENT_FAILED = "forgot_password_email_sent_failed";


export const SIGN_UP_DETAILS_CHANGED = "sign_up_details_changed";
export const SIGN_UP_USER = "sign_up_user";
export const SIGN_UP_USER_SUCCESSFUL = "sign_up_user_successful";
export const SIGN_UP_USER_FAILED = "sign_up_user_failed";

export const CLEAR_SIGN_UP_MESSAGE = "clear_sign_up_message";

export const ROLE_CHANGED = "role_changed";
export const USER_ACCESS_CHANGED = "user_access_changed";

export const CUSTOMER_DETAILS_CHANGED = "customer_details_changed";
export const CUSTOMER_DETAILS_SAVED_SUCCESSFUL = "customer_details_saved";
export const CUSTOMER_DETAILS = "customer_details";
export const CUSTOMER_LOAN_DETAILS_CHANGED = "customer_loan_details_changed";

export const COMPUTE_MODULO_AND_CURRENT_PAID = "compute_modulo_and_current_paid";
export const COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL = "compute_modulo_and_current_paid_successful";
export const COMPUTE_MODULO_AND_CURRENT_PAID_FAILED = "compute_modulo_and_current_paid_failed";

//new customer types
export const CREATE_NEW_CUSTOMER = "create_new_customer";
export const CREATE_NEW_CUSTOMER_SUCCESSFUL = "create_new_customer_successful";
export const CREATE_NEW_CUSTOMER_FAILED = "create_new_customer_failed";

export const CLEAR_NEW_CUSTOMER_MESSAGES = "clear_new_customer_message";

//existing customer types
export const SEARCH_EXISTING_CUSTOMER = "search_existing_customer";
export const SEARCH_EXISTING_CUSTOMER_SUCCESSFUL = "search_existing_customer_successful";
export const SEARCH_EXISTING_CUSTOMER_NO_RECORD = "search_existing_customer_no_record";
export const SEARCH_EXISTING_CUSTOMER_FAILED = "search_existing_customer_failed";

export  const CLEAR_EXISTING_CUSTOMER = "clear_existing_customer";

//customer profile types
export const FETCH_CUSTOMER_PROFILE = "fetch_customer";
export const FETCH_CUSTOMER_PROFILE_SUCCESSFUL = "fetch_customer_profile_successful";
export const FETCH_CUSTOMER_PROFILE_FAILED = "fetch_customer_profile_failed";

//loan types
export const CLEAR_LOAN_PAGE = "clear_loan_page";
export const SAVE_LOAN = "save_loan";
export const SAVE_LOAN_SUCCESSFUL = "save_loan_successful";
export const SAVE_LOAN_FAILED = "save_loan_failed";
export const LOAN_ALREADY_EXISTS = "loan_already_exists";

export const LOAN_PAYMENT_METHOD_CHANGED = "loan_payment_method_changed";

export const DELETE_LOAN = "delete_loan";
export const DELETE_LOAN_SUCCESSFUL = "delete_loan_successful";
export const DELETE_LOAN_FAILED = "delete_loan_failed";

export const DELETE_LOAN_TERM = "delete_loan_term";
export const DELETE_LOAN_TERM_SUCCESSFUL = "delete_loan_term_successful";
export const DELETE_LOAN_TERM_FAILED = "delete_loan_term_failed";
export const LOAN_TERM_ALREADY_EXISTS = "loan_term_already_exists";

export const CLEAR_UNCLEAR_LOAN = "clear_unclear_loan";
export const CLEAR_UNCLEAR_LOAN_SUCCESSFUL = "clear_unclear_loan_successful";
export const CLEAR_UNCLEAR_LOAN_FAILED = "clear_unclear_loan_failed";

export const CUSTOMER_PENAL_INTEREST_SAVED_SUCCESSFUL = "customer_penal_saved_successful";
export const EXTRA_FEE_SAVED_SUCCESSFUL = "extra_fee_saved_successful";
export const EXTRA_FEE_SAVED_FAILED = "extra_fee_saved_failed";
export const EXTRA_FEE_SAVED = "extra_fee_saved";
export const PREVIOUS_OVERDUE_SAVED_SUCCESSFUL = "previous_overdue_saved_successful";

export const DELETE_EXTRA_FEE = "delete_extra_fee";
export const DELETE_EXTRA_FEE_SUCCESSFUL = "delete_extra_fee_successful";
export const DELETE_EXTRA_FEE_FAILED = "delete_extra_fee_failed";

export const CLEAR_EXTRA_FEE_MESSAGE = "clear_extra_fee_message";
export const CLEAR_PENAL_INTEREST_MESSAGE = "clear_penal_interest_message";
export const CLEAR_PREVIOUS_OVERDUE_MESSAGE = "clear_previous_overdue_message";
export const CLEAR_LEGAL_REPO_MESSAGE = "clear_legal_repo_message";
export const CLEAR_EARLY_LIQUIDATION_MESSAGE = "clear_early-liquidation_message";
export const CLEAR_UNCLEAR_LOAN_MESSAGE = "clear_unclear_loan_message";
export const CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE = "clear_reschedule_loan_status_message";
export const CLEAR_DELETE_LOAN_MESSAGE = "clear_delete_loan_message";
export const CLEAR_DELETE_LOAN_TERM_MESSAGE = "clear_delete_loan_term_message";
export const CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE = "clear_loan_term_penal_status_message";
export const CLEAR_LOAN_TERM_UPDATE_MESSAGE = "clear_loan_term_update_message";
export const CLEAR_SAVE_LOAN_TERM_MESSAGE = "clear_save_loan_term_message";
export const CLEAR_SAVE_LOAN_MESSAGE = "clear_save_loan_message";
export const CLEAR_UPDATE_HELD_CHEQUE_MESSAGE = "update_held_cheque_message";
export const CLEAR_HELD_CHEQUE_SELECTED_DATE = "clear_held_cheque_selected_date";
export const CLEAR_ADD_NEW_CUSTOMER_MESSAGE = "clear_add_new_customer_message";


export const PUSHED_CURRENT_LOAN = "pushed_current_loan";
export const PUSHED_CURRENT_LOAN_SUCCESSFUL = "pushed_current_loan_successful";
export const PUSHED_CURRENT_LOAN_FAILED = "pushed_current_loan_failed";
export const CURRENT_LOAN_NOT_FOUND = "current_loan_not_found";
export const CURRENT_LOAN_TERMS_NOT_FOUND = "current_loan_terms_not_found";

export  const LOAN_TERM_DETAILS_CHANGED = "loan_term_details_changed";

export const DUE_DATE_CHANGED = "due_date_changed";
export const BANK_DATE_CHANGED = "bank_date_changed";
export const CHEQUE_SWITCH_CHANGED = "cheque_switch_changed";
export const STATUS_SWITCH_CHANGED = "status_switch_changed";
export const LOAN_TERM_CARD_DETAILS_CHANGED = "loan_term_card_details_changed";
export const RECORD_CHEQUE_STATUS = "record_cheque_status";

export const SHOW_LEGAL_REPO_STATUS_CARD = "show_legal_repo_status_card";
export const HIDE_LEGAL_REPO_STATUS_CARD = "hide_legal_repo_status_card";
export const LEGAL_REPO_STATUS_CHANGED = "legal_repo_status_changed";
export const SAVE_LEGAL_REPO_STATUS = "save_legal_repo_status";
export const SAVE_LEGAL_REPO_STATUS_SUCCESSFUL = "save_legal_repo_status_successful";
export const SAVE_LEGAL_REPO_STATUS_FAILED = "save_legal_repo_status_failed";

export const FILTER_LEGAL_REPO_STATUS_CHANGED = "filter_legal_repo_status_changed";
export const CLEAR_LEGAL_REPO_FILTER = "clear_legal_repo_filter";

export const CLEAR_LEGAL_REPO_STATUS = "clear_legal_repo_notice";
export const CLEAR_LEGAL_REPO_STATUS_SUCCESSFUL = "clear_legal_repo_status_successful";
export const CLEAR_LEGAL_REPO_STATUS_FAILED = "clear_legal_repo_status_failed";
export const COLLECTOR_MESSAGE = "collector_message";

export const PENAL_INTEREST_CHANGED = "penal_interest_changed";
export const PREVIOUS_OVERDUE_CHANGED = "previous_overdue_changed";
export const EXTRA_FEE_CHANGED = "extra_fee_changed";

export const EARLY_LIQUIDATION_CHANGED = "early_liquidation_changed";
export const EARLY_LIQUIDATION_SAVED_SUCCESSFUL = "early_liquidation_saved_successful";
export const EARLY_LIQUIDATION_SAVE_FAILED = "early_liquidation_save_failed";

export const REVERSE_LIQUIDATION = "reverse_liquidation";
export const REVERSE_LIQUIDATION_SUCCESSFUL = "reverse_liquidation_successful";
export const REVERSE_LIQUIDATION_FAILED = "reverse_liquidation_failed";

export const LOAN_CURRENCY_CHANGED = "loan_currency_changed";

export const RESCHEDULE_LOAN_STATUS_CHANGED = "reschedule_loan_status_changed";
export const SHOW_RESCHEDULE_LOAN_STATUS_CARD = "show_reschedule_loan_status_card";
export const HIDE_RESCHEDULE_LOAN_STATUS_CARD = "hide_reschedule_loan_status_card";

export const SAVE_RESCHEDULE_STATUS = "save_reschedule_status";
export const SAVE_RESCHEDULE_STATUS_SUCCESSFUL = "save_reschedule_status_successful";
export const SAVE_RESCHEDULE_STATUS_FAILED = "save_reschedule_status_failed";

export const CLEAR_RESCHEDULE_STATUS = "clear_reschedule_status";
export const CLEAR_RESCHEDULE_STATUS_SUCCESSFUL = "clear_reschedule_status_successful";
export const CLEAR_RESCHEDULE_STATUS_FAILED = "clear_reschedule_status_failed";

export const ADDITIONAL_INFO_CHANGED = "additional_info_changed";
export const SAVE_ADDITIONAL_INFO = "save_additional_info";
export const SAVE_ADDITIONAL_INFO_SUCCESSFUL= "save_additional_info_successful";
export const SAVE_ADDITIONAL_INFO_FAILED = "save_additional_info_failed";
export const CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES = "clear_save_additional_info_messages";

//loan term types
export const SAVE_LOAN_TERM = "save_loan_term";
export const SAVE_LOAN_TERM_SUCCESSFUL = "save_loan_term_successful";
export const SAVE_LOAN_TERM_FAILED = "save_loan_term_failed";


export const UPDATE_LOAN_TERM = "update_loan_term";
export const UPDATE_LOAN_TERM_SUCCESSFUL = "update_loan_successful";
export const UPDATE_LOAN_TERM_FAILED = "update_loan_term_failed";
export const PUSH_LOAN_TERM = "push_loan_term";


export const SHOW_EXTRA_FEE_CARD = "show_extra_fee_card";
export const HIDE_EXTRA_FEE_CARD = "hide_extra_fee_card";

export const SHOW_PENAL_INTEREST_CARD = "show_penal_interest_card";
export const HIDE_PENAL_INTEREST_CARD = "hide_penal_interest_card";

export const SHOW_TOTAL_PAID_PREVIOUSLY_CARD = "show_total_paid_previously_card";
export const HIDE_TOTAL_PAID_PREVIOUSLY_CARD = "hide_total_paid_previously_card";

export const SHOW_EARLY_LIQUIDATION_CARD = "show_early_liquidation_card";
export const HIDE_EARLY_LIQUIDATION_CARD = "hide_early_liquidation_card";

export const SAVE_LOAN_TERM__PENAL_STATUS = "save_loan_term_penal_status";
export const SAVE_LOAN_TERM__PENAL_STATUS_SUCCESSFUL = "save_loan_term_penal_status_successful";
export const SAVE_LOAN_TERM__PENAL_STATUS_FAILED = "save_loan_term_penal_status_failed";


//loan fetch types
export const FETCH_LOANS = "fetch_loans";
export const FETCH_LOANS_SUCCESSFUL = "fetch_loans_successful";
export const FETCH_LOANS_FAILED = "fetch_loans_failed";

export const FETCH_LOAN_TERMS = "fetch_loan_terms";
export const FETCH_LOAN_TERMS_SUCCESSFUL = "fetch_loan_terms_successful";
export const FETCH_LOAN_TERMS_FAILED = "fetch_loan_terms_failed";

export const FETCH_LOAN_LOGS = "fetch_loan_logs";
export const FETCH_LOAN_LOGS_SUCCESSFUL = "fetch_loan_logs_successful";
export const FETCH_LOAN_LOGS_FAILED = "fetch_loan_logs_failed";

export const PUSH_CHEQUE_STATUS = "push_cheque_status";

export const ENABLE_UPDATE_TERM_BTN = "enable_update_term_btn";

//analytics types
export const FETCH_ANALYTICS = "fetch_analytics";
export const FETCH_ANALYTICS_SUCCESSFUL = "fetch_analytics_successful";
export const FETCH_ANALYTICS_FAILED = "fetch_analytics_failed";

export const FETCH_TOTAL_BOOK = "fetch_total_book";
export const FETCH_TOTAL_BOOK_SUCCESSFUL = "fetch_total_book_successful";
export const FETCH_TOTAL_BOOK_FAILED = "fetch_total_book_failed";

export const FETCH_TOTAL_OVERDUE = "fetch_total_overdue";
export const FETCH_TOTAL_OVERDUE_SUCCESSFUL = "fetch_total_overdue_successful";
export const FETCH_TOTAL_OVERDUE_FAILED = "fetch_total_overdue_failed";

export const FETCH_TOTAL_OUTSTANDING = "fetch_total_outstanding";
export const FETCH_TOTAL_OUTSTANDING_SUCCESSFUL = "fetch_total_outstanding_successful";
export const FETCH_TOTAL_OUTSTANDING_FAILED = "fetch_total_outstanding_failed";

export const FETCH_TOTAL_NPL = "fetch_total_npl";
export const FETCH_TOTAL_NPL_SUCCESSFUL = "fetch_total_npl_successful";
export const FETCH_TOTAL_NPL_FAILED = "fetch_total_npl_failed";

export const FETCH_PDC_FTM = "fetch_pdc_ftm";
export const FETCH_PDC_FTM_SUCCESSFUL = "fetch_pdc_ftm_successful";
export const FETCH_PDC_FTM_FAILED = "fetch_pdc_ftm_failed";

export const FETCH_PDC_TD = "fetch_pdc_td";
export const FETCH_PDC_TD_SUCCESSFUL = "fetch_pdc_td_successful";
export const FETCH_PDC_TD_FAILED = "fetch_pdc_td_failed";

export const FETCH_PDC_DEPOSITED = "fetch_pdc_deposited";
export const FETCH_PDC_DEPOSITED_SUCCESSFUL = "fetch_pdc_deposited_successful";
export const FETCH_PDC_DEPOSITED_FAILED = "fetch_pdc_deposited_failed";

export const FETCH_BOUNCED_TD = "fetch_bounced_td";
export const FETCH_BOUNCED_TD_SUCCESSFUL = "fetch_bounced_td_successful";
export const FETCH_BOUNCED_TD_FAILED = "fetch_bounced_td_failed";

export const FETCH_OD_COLL_TD = "fetch_od_coll_td";
export const FETCH_OD_COLL_TD_SUCCESSFUL = "fetch_od_coll_td_successful";
export const FETCH_OD_COLL_TD_FAILED = "fetch_od_coll_td_failed";

export const FETCH_LEGAL_OVERDUE = "fetch_legal_overdue";
export const FETCH_LEGAL_OVERDUE_SUCCESSFUL = "fetch_legal_overdue_successful";
export const FETCH_LEGAL_OVERDUE_FAILED = "fetch_legal_overdue_failed";

export const FETCH_REPO_OVERDUE = "fetch_repo_overdue";
export const FETCH_REPO_OVERDUE_SUCCESSFUL = "fetch_repo_overdue_successful";
export const FETCH_REPO_OVERDUE_FAILED = "fetch_repo_overdue_failed";

export const FETCH_NORMAL_OVERDUE = "fetch_normal_overdue";
export const FETCH_NORMAL_OVERDUE_SUCCESSFUL = "fetch_normal_overdue_successful";
export const FETCH_NORMAL_OVERDUE_FAILED = "fetch_normal_overdue_failed";

export const MONTHLY_TARGET_DETAILS_CHANGED = "monthly_target_details_changed";
export const SAVE_MONTHLY_TARGET = "save_monthly_target";
export const SAVE_MONTHLY_TARGET_SUCCESSFUL = "save_monthly_target_successful";
export const SAVE_MONTHLY_TARGET_FAILED = "save_monthly_target_failed";

export const FETCH_MONTHLY_TARGET = "fetch_monthly_target";
export const FETCH_MONTHLY_TARGET_SUCCESSFUL = "fetch_monthly_target_successful";
export const FETCH_MONTHLY_TARGET_FAILED = "fetch_monthly_target_failed";

export const DELETE_MONTHLY_TARGET = "delete_monthly_target";
export const DELETE_MONTHLY_TARGET_SUCCESSFUL = "delete_monthly_target_successful";
export const DELETE_MONTHLY_TARGET_FAILED = "delete_monthly_target_failed";
export const CLEAR_MONTHLY_TARGET_MESSAGES = "clear_monthly_target_messages";

export const SAVE_OD_TARGET = "save_od_target";
export const SAVE_OD_TARGET_SUCCESSFUL = "save_od_target_successful";
export const SAVE_OD_TARGET_FAILED = "save_od_target_failed";

export const FETCH_OD_TARGET = "fetch_od_target";
export const FETCH_OD_TARGET_SUCCESSFUL = "fetch_od_target_successful";
export const FETCH_OD_TARGET_FAILED = "fetch_od_target_failed";

export const DELETE_OD_TARGET = "delete_od_target";
export const DELETE_OD_TARGET_SUCCESSFUL = "delete_od_target_successful";
export const DELETE_OD_TARGET_FAILED = "delete_od_target_failed";
export const CLEAR_OD_TARGET_MESSAGES = "clear_od_target_messages";


//master file types
export const UPLOAD_MASTER_FILE = "upload_master_file";
export const UPLOAD_MASTER_FILE_SUCCESSFUL = "upload_master_file_successful";
export const UPLOAD_MASTER_FILE_FAILED = "upload_master_file_failed";

export const OD_AGING_REPORT_DATE_CHANGED = "od_aging_report_date_changed";

export const CLEAR_MASTER_FILE_MESSAGES = "clear_master_file_messages";

//collections types
export const FETCH_CUSTOMER_DETAILS = "fetch_customer_details";
export const FETCH_CUSTOMER_DETAILS_SUCCESSFUL = "fetch_customer_details_successful";
export const FETCH_CUSTOMER_DETAILS_FAILED = "fetch_customer_details_failed";

export const CUSTOMER_LOAN_TERM_CHANGED = "customer_loan_term_changed";
export const CUSTOMER_LOAN_LIST_CHANGED = "customer_loan_list_changed";
export const CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED = "customer_loan_term_card_details_changed";
export const CUSTOMER_BANK_DATE_CHANGED = "customer_bank_date_changed";
export const UPDATE_CUSTOMER_LOAN_TERM =  "update_customer_loan_term";
export const UPDATE_CUSTOMER_LOAN_TERM_SUCCESSFUL = "update_customer_loan_term_successful";
export const UPDATE_CUSTOMER_LOAN_TERM_FAILED = "update_customer_loan_term_failed";

export const CUSTOMER_COLLECTION_DETAILS_CHANGED = "customer_collection_details_changed";
export const SEARCH_CUSTOMER = "search_customer";
export const SEARCH_CUSTOMER_SUCCESSFUL = "search_customer_successful";
export const SEARCH_CUSTOMER_NO_RECORDS = "search_customer_no_record";
export const SEARCH_CUSTOMER_FAILED = "search_customer_failed";

export const SAVE_CASH_COLLECTION = "save_cash_collection";
export const SAVE_CASH_COLLECTION_SUCCESSFUL = "save_cash_collection_successful";
export const SAVE_CASH_COLLECTION_FAILED = "save_cash_collection_failed";

export const FETCH_CASH_COLLECTIONS = "fetch_cash_collections";
export const FETCH_CASH_COLLECTIONS_SUCCESSFUL = "fetch_cash_collections_successful";
export const FETCH_CASH_COLLECTIONS_FAILED = "fetch_cash_collections_failed";

export const FETCH_COLLECTION_LOANS = "fetch_collection_loans";
export const FETCH_COLLECTION_LOANS_SUCCESSFUL = "fetch_collection_loans_successful";
export const FETCH_COLLECTION_LOANS_FAILED = "fetch_collection_loans_failed";


export const FETCH_COLLECTION_LOAN_TERMS = "fetch_collection_loan_terms";
export const FETCH_COLLECTION_LOAN_TERMS_SUCCESSFUL = "fetch_collection_loan_term_successful";
export const FETCH_COLLECTION_LOAN_TERMS_FAILED = "fetch_collection_loan_terms_failed";

export const CLEAR_SAVED_COLLECTION_MESSAGE = "clear_saved_collection_message";
export const SHOW_CASH_COLLECTION_MESSAGE = "show_cash_collection_message";

export const CASH_COLLECTION_UPDATE_DETAILS_CHANGED = "cash_collection_update_details_changed";
export const UPDATE_CASH_COLLECTION_BANK_DATE_CHANGED = "update_cash_collection_bank_date_changed";

export const UPDATE_CASH_COLLECTION = "update_cash_collection";
export const UPDATE_CASH_COLLECTION_SUCCESSFUL = "update_cash_collection_successful";
export const UPDATE_CASH_COLLECTION_FAILED = "update_cash_collection_failed";

export const DELETE_CASH_COLLECTION = "delete_cash_collection";
export const DELETE_CASH_COLLECTION_SUCCESSFUL = "delete_cash_collection_successful";
export const DELETE_CASH_COLLECTION_FAILED = "delete_cash_collection_failed";

export const COLLECTION_CURRENCY_CHANGED = "collection_currency_changed";


//Reports Types
export const CHEQUE_STATUS_CHANGED = "cheque_status_changed";
export const REPORT_CATEGORY_CHANGED = "report_category_changed";
export const DATE_RANGE_CHANGED = "date_range_changed";

export const RESET_TOTAL_VALUE = "reset_total_value";

export const FETCH_REPORT_TOTAL_VALUE = "fetch_report_total_value";
export const FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL = "fetch_report_total_value_successful";
export const FETCH_REPORT_TOTAL_VALUE_FAILED = "fetch_report_total_value_failed";

export const GENERATE_REPORT = "generate_report";
export const GENERATE_REPORT_SUCCESSFUL = "generate_report_successful";
export const GENERATE_REPORT_FAILED = "generate_report_failed";


//Master List Types
export const MASTER_LIST_DATE_RANGE_CHANGED = 'master_list_date_range_changed';
export const MASTER_LIST_COMMENT_CHANGED = "master_list_comment_changed";

export const MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED = "master_list_comments_date_range_changed";

export const SAVE_MASTER_LIST_COMMENT = "save_master_list_comment";
export const SAVE_MASTER_LIST_COMMENT_SUCCESSFUL = "save_master_list_comment_successful";
export const SAVE_MASTER_LIST_COMMENT_FAILED = "save_master_list_comment_failed";

export const DELETE_MASTER_LIST_COMMENT = "delete_master_list_comment";
export const DELETE_MASTER_LIST_COMMENT_SUCCESSFUL = "delete_master_list_comment_successful";
export const DELETE_MASTER_LIST_COMMENT_FAILED = "delete_master_list_comment_failed";


export const FETCH_MASTER_LIST_COMMENTS = "fetch_master_list_comments";
export const FETCH_MASTER_LIST_COMMENTS_SUCCESSFUL = "fetch_master_list_comments_successful";
export const FETCH_MASTER_LIST_COMMENTS_FAILED = "fetch_master_list_comments_failed";

export const FETCH_RANGED_MASTER_LIST_COMMENTS = "fetch_ranged_master_list_comments";
export const FETCH_RANGED_MASTER_LIST_COMMENTS_SUCCESSFUL = "fetch_ranged_master_list_comments_successful";
export const FETCH_RANGED_MASTER_LIST_COMMENTS_FAILED = "fetch_ranged_master_list_comments_failed";

export const NUMBER_OF_LOANS_TO_ASSIGN_CHANGED = "number_of_loans_to_assign_changed";
export const ASSIGN_COLLECTOR = "assign_collector";
export const ASSIGN_COLLECTOR_SUCCESSFUL = "assign_collector_successful";
export const ASSIGN_COLLECTOR_FAILED = "assign_collector_failed";


export const DELETE_ASSIGNED_COLLECTOR = "delete_assigned_collector";
export const DELETE_ASSIGNED_COLLECTOR_SUCCESSFUL = "delete_assigned_collector_successful";
export const DELETE_ASSIGNED_COLLECTOR_FAILED = "delete_assigned_collector_failed";

export const SHOW_UNASSIGNED_LOANS = "show_unassigned_loans";
export const HIDE_UNASSIGNED_LOANS = "hide_unassigned_loans";

export const FETCH_OVERDUE_TERMS = "fetch_overdue_terms";
export const FETCH_OVERDUE_TERMS_SUCCESSFUL = "fetch_overdue_terms_successful";
export const FETCH_OVERDUE_TERMS_FAILED = "fetch_overdue_terms_failed";

export const FETCH_RANGED_OVERDUE_TERMS = "fetch_ranged_overdue_terms";
export const FETCH_RANGED_OVERDUE_TERMS_SUCCESSFUL = "fetch_ranged_overdue_terms_successful";
export const FETCH_RANGED_OVERDUE_TERMS_FAILED = "fetch_ranged_overdue_terms_failed";

export const FETCH_BOUNCED_CHEQUES = "fetch_bounced_cheques";
export const FETCH_BOUNCED_CHEQUES_SUCCESSFUL = "fetch_bounced_cheques_successful";
export const FETCH_BOUNCED_CHEQUES_FAILED = "fetch_bounced_cheques_failed";

export const FETCH_RANGED_BOUNCED_CHEQUES = "fetch_ranged_bounced_cheques";
export const FETCH_RANGED_BOUNCED_CHEQUES_SUCCESSFUL = "fetch_ranged_bounced_cheques_successful";
export const FETCH_RANGED_BOUNCED_CHEQUES_FAILED = "fetch_ranged_bounced_cheques_failed";


export const DEMAND_NOTICE_DATE_CHANGED = "demand_notice_date_changed";
export const SAVE_DEMAND_NOTICE = "save_demand_notice";
export const SAVE_DEMAND_NOTICE_SUCCESSFULL = "save_demand_notice_successful";
export const SAVE_DEMAND_NOTICE_FAILED = "save_demand_notice_failed";

export const SECOND_DEMAND_NOTICE_DATE_CHANGED = "second_demand_notice_date_changed";
export const SAVE_SECOND_DEMAND_NOTICE = "save_second_demand_notice";
export const SAVE_SECOND_DEMAND_NOTICE_SUCCESSFUL = "save_second_demand_notice_successful";
export const SAVE_SECOND_DEMAND_NOTICE_FAILED = "save_second_demand_notice_failed";

export const CLEAR_DEMAND_NOTICE_MESSAGES = "clear_second_demand_notices_message";

export const UPDATE_DEMAND_NOTICE_DATE_CHANGED = "update_demand_notice_date_changed";

export const UPDATE_DEMAND_NOTICE = "update_demand_notice";
export const UPDATE_DEMAND_NOTICE_SUCCESSFUL = "update_demand_notice_successful";
export const UPDATE_DEMAND_NOTICE_FAILED = "update_demand_notice_failed";

export const SHOW_UPDATE_NOTICES_CARD = "show_update_notices_card";
export const HIDE_UPDATE_NOTICES_CARD = "hide_update_notices_card";

export const FETCH_DEMAND_NOTICES = "fetch_demand_notices";
export const FETCH_DEMAND_NOTICES_SUCCESSFUL = "fetch_demand_notices_successful";
export const FETCH_DEMAND_NOTICES_FAILED = "fetch_demand_notices_failed";

export const FETCH_CURRENT_NOTICE = "fetch_current_notice";

export const SHOW_COMMENT_CARD = "show_comment_card";
export const HIDE_COMMENT_CARD = "hide_comment_card";

export const SHOW_NOTICES_CARD = "show_notices_card";
export const HIDE_NOTICES_CARD = "hide_notices_card";

export const SHOW_ASSIGN_COLLECTOR_CARD = "show_assign_collector_card";
export const HIDE_ASSIGN_COLLECTOR_CARD = "hide_assign_collector_card";

export const DELETE_DEMAND_NOTICES = "delete_demand_notices";
export const DELETE_DEMAND_NOTICES_SUCCESSFUL = "delete_demand_notices_successful";
export const DELETE_DEMAND_NOTICES_FAILED = "delete_demand_notices_failed";

export const DELETE_SECOND_DEMAND_NOTICES = "delete_second_demand_notices";
export const DELETE_SECOND_DEMAND_NOTICES_SUCCESSFUL = "delete_second_demand_notices_successful";
export const DELETE_SECOND_DEMAND_NOTICES_FAILED = "delete_second_demand_notices_failed";

export const RE_FETCH_DEMAND_NOTICES = "re-fetch_demand_notices";

export const FILTER_BUCKET_CHANGED = "filter_bucket_changed";

export const CLEAR_COMMENT_PAGE = "clear_comment_page";

//Unidentified Types
export const UNIDENTIFIED_DETAILS_CHANGED = "unidentified_details_changed";
export const UNIDENTIFIED_DATE_CHANGED = "unidentified_date_changed";

export const IDENTIFIED_CUSTOMER_DETAILS_CHANGED = "identified_details_changed";
export const SEARCH_UNIDENTIFIED_CUSTOMER = "search_unidentified_customer";
export const SEARCH_UNIDENTIFIED_CUSTOMER_SUCCESSFUL = "search_unidentified_customer_successful";
export const SEARCH_UNIDENTIFIED_CUSTOMER_FAILED = "search_unidentified_customer_failed";
export const SEARCH_UNIDENTIFIED_CUSTOMER_NO_RECORDS = "search_unidentified_customer_no_records";

export const FETCH_IDENTIFIED_CUSTOMER = "fetch_identified_customer";
export const FETCH_IDENTIFIED_CUSTOMER_SUCCESSFUL = "fetch_identified_customer_successful";
export const FETCH_IDENTIFIED_CUSTOMER_FAILED = "fetch_identified_payments_failed";

export const FETCH_IDENTIFIED_CUSTOMER_LOANS = "fetch_identified_customer_loans";
export const FETCH_IDENTIFIED_CUSTOMER_LOANS_SUCCESSFUL = "fetch_identified_customer_loans_successful";
export const FETCH_IDENTIFIED_CUSTOMER_LOANS_FAILED = "fetch_identified_payments_loans_failed";

export const PUSH_IDENTIFIED_CUSTOMER_CURRENT_LOAN = "push_identified_customer_current_loan";
export const IDENTIFIED_CUSTOMER_LOAN_LIST_CHANGED = 'identified_customer-loan_list_changed';

export const FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS = "fetch_identified_customer_loan_terms";
export const FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS_SUCCESSFUL = "fetch_identified_customer_loans_terms_successful";
export const FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS_FAILED = "fetch_identified_payments_loans_terms_failed";

export const IDENTIFIED_CUSTOMER_LOAN_TERM_DETAILS_CHANGED = "identified_customer_loan_details_changed";
export const IDENTIFIED_CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED = "identified_customer_loan_card_details_changed";
export const IDENTIFIED_CUSTOMER_BANK_DATE = "identified_customer_bank_date";

export const PUSH_UNIDENTIFIED_PAYMENT = "push_unIdentified_payment";

export const IDENTIFY_PAYMENT = "identify_payment";
export const IDENTIFY_PAYMENT_SUCCESSFUL = "identify_payment_successful";
export const IDENTIFY_PAYMENT_FAILED = "identify_payment_failed";

export const UPDATE_IDENTIFIED_CUSTOMER_LOAN_TERM = "update_identified_customer_loan_term";
export const UPDATE_IDENTIFIED_CUSTOMER_LOAN_TERM_SUCCESSFUL = "update_identified_customer_loan_term_successful";
export const UPDATE_IDENTIFIED_CUSTOMER_LOAN_TERM_FAILED = "update_identified_customer_loan_term_failed";

export const SAVE_UNIDENTIFIED_PAYMENTS = "save_unidentified_payments";
export const SAVE_UNIDENTIFIED_PAYMENTS_SUCCESSFUL = "save_unidentified_payments_successful";
export const SAVE_UNIDENTIFIED_PAYMENTS_FAILED = "save_unidentified_payments_failed";

export const FETCH_UNIDENTIFIED_PAYMENTS = "fetch_unidentified_payments";
export const FETCH_UNIDENTIFIED_PAYMENTS_SUCCESSFUL = "fetch_unidentified_payments_successful";
export const FETCH_UNIDENTIFIED_PAYMENTS_FAILED = "fetch_unidentified_payments_failed";

export const UNIDENTIFIED_PAYMENT_CURRENCY = "unidentified_payment_currency";

export const DELETE_UNIDENTIFIED_PAYMENT = "delete_unidentified_payment";
export const DELETE_UNIDENTIFIED_PAYMENT_SUCCESSFUL = "delete_unidentified_payment_successful";
export const DELETE_UNIDENTIFIED_PAYMENT_FAILED = "delete_unidentified_payment_failed";
export const RE_FETCH_UNIDENTIFIED_PAYMENTS = "re-fetch_unidentified_payments";


//report types
export const CREATE_REPORT = "create_report";
export const CREATE_REPORT_SUCCESSFUL = "create_report_successful";
export const CREATE_REPORT_FAILED = "create_report_failed";
export const CREATE_REPORT_EMPTY ="create_report_empty";
export const CREATE_REPORT_DATE_CHANGED = "create_report_date_changed";

export const CREATE_MASTER_LIST_REPORT = "create_master_list_report";
export const CREATE_MASTER_LIST_REPORT_SUCCESSFUL = "create_master_list_report";
export const CREATE_MASTER_LIST_REPORT_FAILED = "create_master_list_report";
export const CREATE_MASTER_LIST_REPORT_EMPTY = "create_master_list_report";
export const CLEAR_MASTER_LIST_REPORT = "clear_master_list_report";

export const CHEQUE_CASH_SWITCH_CHANGED = "cheque_cash_switch_changed";
export const CLEAR_REPORTS_MESSAGE = "clear_report_message";
export const CREATE_UNIQUE_REPORTS = "create_unique_reports";

//master file types
export const COLLECTOR_CHANGED = "collector_changed";
export const COLLECTOR_DETAILS_CHANGED = "collector_details_changed";

//profile types
export const FETCH_PROFILE = "fetch_profile";
export const FETCH_PROFILE_SUCCESSFUL = "fetch_profile_successful";
export const FETCH_PROFILE_FAILED = "fetch_profile_failed";

export const DELETE_USER = "delete_user";
export const DELETE_USER_SUCCESSFUL = "delete_user_successful";
export const DELETE_USER_FAILED = "delete_user_failed";

export const FETCH_ADMIN_USER = "fetch_admin_user";
export const FETCH_ADMIN_USER_SUCCESSFUL = "fetch_admin_user_successful";
export const FETCH_ADMIN_USER_FAILED = "fetch_admin_user_failed";

export const UPDATE_ADMIN_USER = "update_user_admin";
export const UPDATE_ADMIN_USER_SUCCESSFUL = "update_user_admin_successful";
export const UPDATE_ADMIN_USER_FAILED = "update_user_admin_failed";

export const PASSWORD_CHANGED = "password_changed";

export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_SUCCESSFUL = "reset_password_successful";
export const RESET_PASSWORD_FAILED = "reset_password_failed";

export const CLEAR_CHANGE_PASSWORD_MESSAGES = "clear_change_password_messages";

export const USER_ROLE_CHANGED = "user_role_changed";

//held actions
export const HELD_DATE_CHANGED = "held_date_changed";
export const HELD_STATUS_CHANGED = "held_status_changed";

export const FETCH_HELD_CHEQUES = "fetch_held_cheques";
export const FETCH_HELD_CHEQUES_SUCCESSFUL = "fetch_held_cheques_successful";
export const FETCH_HELD_CHEQUES_FAILED = "fetch_held_cheques_failed";

export const FETCH_ALL_HELD_CHEQUES = "fetch_all_held_cheques";
export const FETCH_ALL_HELD_CHEQUES_SUCCESSFUL = "fetch_all_held_cheques_successful";
export const FETCH_ALL_HELD_CHEQUES_FAILED = "fetch_all_held_cheques_failed";


export const UPDATE_HELD_CHEQUES_STATUS = "update_held_cheques_status";
export const UPDATE_HELD_CHEQUES_STATUS_SUCCESSFUL = "update_held_cheques_status_successful";
export const UPDATE_HELD_CHEQUES_STATUS_FAILED = "update_held_cheques_status_failed";

//settings types
export const EXCHANGE_RATE_CHANGED = "exchange_rate_changed";
export const EXCHANGE_DATE_CHANGED = "exchange_date_changed";

export const SAVE_EXCHANGE_RATE = "save_exchange_rate";
export const SAVE_EXCHANGE_RATE_SUCCESSFUL = "save_exchange_rate_successful";
export const SAVE_EXCHANGE_RATE_FAILED = "save_exchange_rate_failed";
export const VIEW_CURRENCY_IN_USD = "view_currency_in_usd";
export const VIEW_CURRENCY_IN_USD_SUCCESSFUL = "view_currency_in_usd_successful";
export const VIEW_CURRENCY_IN_USD_FAILED = "view_currency_in_usd_failed";

export const SETTINGS_FIELD_UPDATED = "setting_field_updated";
export const SET_MIGRATION_DATE = "set_migration_date";
export const SET_MIGRATION_DATE_SUCCESSFULLY = "set_migration_date_successfully";
export const SET_MIGRATION_DATE_FAILED = "set_migration_date_failed";

export const CALCULATE_PENAL_INTEREST = "calculate_penal_interest";
export const CALCULATE_PENAL_INTEREST_SUCCESSFUL = "calculate_penal_interest_successful";
export const CALCULATE_PENAL_INTEREST_FAILED = "calculate_penal_interest_failed";

export const CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS = "calculate_penal_interest_for_all_loans";
export const CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_SUCCESSFUL = "calculate_penal_interest_for_all_loans_successful";
export const CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_FAILED = "calculate_penal_interest_for_all_loans_failed";

export const CALCULATE_PENAL_INTEREST_SETTINGS = "calculate_penal_interest_setting";
export const CALCULATE_PENAL_INTEREST_SETTINGS_FAILED = "calculate_penal_interest_setting_failed";

export const SHOW_DEFAULT_CURRENCY = "show_default_currency";
export const SHOW_DEFAULT_CURRENCY_SUCCESSFUL = "show_default_currency_successful";
export const SHOW_DEFAULT_CURRENCY_FAILED = "show_default_currency_failed";

export const CLEAR_MIGRATED_CHEQUES = "clear_migrated_cheques";
export const CLEAR_MIGRATED_CHEQUES_SUCCESSFUL = "clear_migrated_cheques_successful";
export const CLEAR_MIGRATED_CHEQUES_FAILED = "clear_migrated_cheques_failed";


export const ACTIVATE_MIGRATION = "activate_migration";
export const ACTIVATE_MIGRATION_SUCCESSFUL = "activate_migration_successful";
export const ACTIVATE_MIGRATION_FAILED = "activate_migration_failed";

export const MIGRATION_ACTIVATION = "migration_activation";
export const MIGRATION_ACTIVATION_SUCCESSFUL = "migration_activation_successful";
export const MIGRATION_ACTIVATION_FAILED = "migration_activation_failed";

export const FETCH_SYSTEM_INFO = "fetch_system_info";
export const FETCH_SYSTEM_INFO_SUCCESSFUL = "fetch_system_info_successful";
export const FETCH_SYSTEM_INFO_FAILED = "fetch_system_info_failed";

export const CLEAR_MIGRATION_MESSAGES = "clear_migration_messages";
export const CLEAR_SETTING_PENAL_INTEREST_MESSAGE = "clear_setting_penal_interest_message";
export const CLEAR_EXCHANGE_RATE_MESSAGE = "clear_exchange_rate_message";
export const CLEAR_SET_MIGRATION_DATE_MESSAGE = "clear_set_migration_date_message";

//service charges types
export const SERVICE_CHARGE_DETAILS_CHANGED = "service_charge_details_changed";
export const SAVE_SERVICE_CHARGE_DETAILS = "save_service_charge_details";
export const SAVE_SERVICE_CHARGE_DETAILS_SUCCESSFUL = "save_service_charge_details_successful";
export const SAVE_SERVICE_CHARGE_DETAILS_FAILED = "save_service_charge_details_successful";

export const SERVICE_CHARGE_DATE_CHANGED = "service_charge_date_changed";

export const FETCH_SERVICE_CHARGES = "fetch_service_charges";
export const FETCH_SERVICE_CHARGES_SUCCESSFUL = "fetch_service_charges_successful";
export const FETCH_SERVICE_CHARGES_FAILED= "fetch_service_charges_failed";

export const DELETE_SERVICE_CHARGE = "delete_service_charge";
export const DELETE_SERVICE_CHARGE_SUCCESSFUL = "delete_service_charge_successful";
export const DELETE_SERVICE_CHARGE_FAILED = "delete_service_charge_failed";

export const CLEAR_SERVICE_CHARGE_MESSAGE = "clear_service_charge_message";

export const CLEAR_PENAL_CHANGED = "clear_penal_changed";
export const CLEAR_COLLECTOR_MESSAGES = "clear_collector_message";

export const FETCH_INDUSTRY_TITLE_SUCCESSFUL = "fetch_industry_title_successful";
export const FETCH_INDUSTRY_TITLE_FAILED = "fetch_industry_title_failed";

export const FETCH_SEGMENT_TITLE_SUCCESSFUL = "fetch_segment_title_successful";
export const FETCH_SEGMENT_TITLE_FAILED = "fetch_segment_title_failed";

export const FETCH_REPOSTATUS_SUCCESSFUL = "fetch_repostatus_successful";
export const FETCH_REPOSTATUS_FAILED = "fetch_repostatus_failed";



//penal interest activation types
export const PENAL_INTEREST_ACTIVATION = "penal_interest_activation";
export const PENAL_INTEREST_ACTIVATION_SUCCESSFUL = "penal_interest_activation_successful";
export const PENAL_INTEREST_ACTIVATION_FAILED = "penal_interest_activation_failed";

export const MANUAL_OVERRIDE = "manual_override";
export const MANUAL_OVERRIDE_SUCCESSFUL = "manual_override_successful";
export const MANUAL_OVERRIDE_FAILED = "manual_override_failed";

export const FIX_LOAN = "fix_loan";
export const FIX_LOAN_SUCCESSFUL = "fix_loan_successful";
export const FIX_LOAN_FAILED = "fix_loan_failed";

export const MANUAL_OVERRIDE_ALL_LOANS = "manual_override_all_loans";
export const MANUAL_OVERRIDE_ALL_LOANS_SUCCESSFUL = "manual_override_all_loans_successful";
export const MANUAL_OVERRIDE_ALL_LOANS_FAILED = "manual_override_all_loans_failed";

export const ENABLE_MANUAL_OVERRIDE_BUTTON = "enable_reset_button";

export const CALCULATE_LOAN_PENAL_INTEREST = "calculate_loan_penal_interest";
export const CALCULATE_LOAN_PENAL_INTEREST_SUCCESSFUL = "calculate_loan_penal_interest_successful";
export const CALCULATE_LOAN_PENAL_INTEREST_FAILED = "calculate_loan_penal_interest_failed";

export const COMPUTE_INCORRECT_LOANS = "compute_incorrect_loans";
export const COMPUTE_INCORRECT_LOANS_SUCCESSFUL = "compute_incorrect_loans_successful";
export const COMPUTE_INCORRECT_LOANS_FAILED = "compute_incorrect_loans_failed";

export const COMPUTE_INCOMPLETE_LOANS = "compute_incomplete_loans";
export const COMPUTE_INCOMPLETE_LOANS_SUCCESSFUL = "compute_incomplete_loans_successful";
export const COMPUTE_INCOMPLETE_LOANS_FAILED = "compute_incomplete_loans_failed";

export const COMPUTE_LOANS_FOR_PENAL_INTEREST = "compute_loans_for_penal_interest";
export const COMPUTE_LOANS_FOR_PENAL_INTEREST_SUCCESSFUL = "compute_loans_for_penal_interest_successful";
export const COMPUTE_LOANS_FOR_PENAL_INTEREST_FAILED = "compute_loans_for_penal_interest_failed";

export const FETCH_LOANS_WITH_CURRENT_PAID = "fetch_loans_with_current_paid";
export const FETCH_LOANS_WITH_CURRENT_PAID_SUCCESSFUL = "fetch_loans_with_current_paid_successful";
export const FETCH_LOANS_WITH_CURRENT_PAID_FAILED = "fetch_loans_with_current_paid_failed";

export const CLEAR_LOAN_TERMS = "clear_loan_terms";
export const CLEAR_LOAN_TERMS_SUCCESSFUL = "clear_loan_terms";
export const CLEAR_LOAN_TERMS_FAILED = "clear_loan_terms_failed";

export const FETCH_SURPLUS_LOANS = "fetch_surplus_loans";
export const FETCH_SURPLUS_LOANS_SUCCESSFUL = "fetch_surplus_loans_successful";
export const FETCH_SURPLUS_LOANS_FAILED = "fetch_surplus_loans_failed";

export const FETCH_ALL_BEFORE_TERMS = "fetch_all_before_terms";
export const FETCH_ALL_BEFORE_TERMS_SUCCESSFUL = "fetch_all_before_terms_successful";
export const FETCH_ALL_BEFORE_TERMS_FAILED = "fetch_all_before_terms_failed";

export const FETCH_EXCHANGE_RATES = "fetch_exchange_rates";
export const FETCH_EXCHANGE_RATES_SUCCESSFUL = "fetch_exchange_rates_successful";
export const FETCH_EXCHANGE_RATES_FAILED = "fetch_exchange_rates_failed";

export const DELETE_EXCHANGE_RATE = "delete_exchange_rate";
export const DELETE_EXCHANGE_RATE_SUCCESSFUL = "delete_exchange_rate_successful";
export const DELETE_EXCHANGE_RATE_FAILED = "delete_exchange_rate_failed";

export const CHANGED_INDUSTRY_DETAILS = "changed_industry_details";
export const INDUSTRY_ADDING = "industry_adding";
export const INDUSTRY_ADDING_SUCCESSFUL = "industry_adding_successful";
export const INDUSTRY_ADDING_FAILED = "industry_adding_failed";

export const CHANGED_SEGMENT_DETAILS = "changed_segment_details";
export const SEGMENT_ADDING = "segment_adding";
export const SEGMENT_ADDING_SUCCESSFUL = "segment_adding_successful";
export const SEGMENT_ADDING_FAILED = "segment_adding_failed";

export const CHANGED_REPOSTATUS_DETAILS = "changed_repostatus_details";
export const REPOSTATUS_ADDING = "repostatus_adding";
export const REPOSTATUS_ADDING_SUCCESSFUL = "repostatus_adding_successful";
export const REPOSTATUS_ADDING_FAILED = "repostatus_adding_failed";

export const CHARACTER_DETAILS_CHANGED = "character_details_changed";
export const SAVE_CLIENT_CHARACTER = "save_client_character";
export const SAVE_CLIENT_CHARACTER_SUCCESSFUL = "save_client_character_successful";
export const SAVE_CLIENT_CHARACTER_FAILED = "save_client_character_failed";

export const CLIENT_ACTION_DETAILS_CHANGED = "client_action_details_changed";
export const SAVE_CLIENT_ACTION = "save_client_action";
export const SAVE_CLIENT_ACTION_SUCCESSFUL = "save_client_action_successful";
export const SAVE_CLIENT_ACTION_FAILED = "save_client_action_failed";

export const FETCH_CLIENT_CHARACTER_SUCCESSFUL = "fetch_client_character_successful";
export const FETCH_CLIENT_CHARACTER_FAILED = "fetch_client_character_failed";

export const FETCH_CLIENT_ACTIONS_SUCCESSFUL = "fetch_client_actions_successful";
export const FETCH_CLIENT_ACTIONS_FAILED = "fetch_client_actions_failed";

export const FETCH_ACTIONS_WA_SUCCESSFUL = "fetch_actions_WA_successful";
export const FETCH_ACTIONS_WA_FAILED = "fetch_actions_WA_failed";

export const FETCH_ACTIONS_WBNA_SUCCESSFUL = "fetch_actions_WBNA_successful";
export const FETCH_ACTIONS_WBNA_FAILED = "fetch_actions_WBNA_failed";

export const FETCH_ACTIONS_UU_SUCCESSFUL = "fetch_actions_UU_successful";
export const FETCH_ACTIONS_UU_FAILED = "fetch_actions_UU_failed";

export const FETCH_ACTIONS_UBA_SUCCESSFUL = "fetch_actions_UBA_successful";
export const FETCH_ACTIONS_UBA_FAILED = "fetch_actions_UBA_failed";

export const RESET_COLLECTORS = "reset_collectors";
export const RESET_COLLECTORS_SUCCESSFUL = "reset_collectors_successful";
export const RESET_COLLECTORS_FAILED = "reset_collectors_failed";

export const ADD_PHONE = "add_phone";
export const ADD_PHONE_SUCCESSFUL = "add_phone_successful";
export const ADD_PHONE_FAILED = "add_phone_failed";

export const FETCH_LAST_COMMENTS_SUCCESSFUL = "fetch_last_comments_successful";

export const FETCH_ROLLOVER = "fetch_rollover";
export const FETCH_ROLLOVER_FAILED = "fetch_rollover_failed";
export const FETCH_ROLLOVER_SUCCESSFUL = "fetch_rollover_successful";

export const FETCH_ROLLOVER_LAST_MONTH = "fetch_rollover_last_month";
export const FETCH_ROLLOVER_LAST_MONTH_SUCCESSFUL = "fetch_rollover_last_month_failed";
export const FETCH_ROLLOVER_LAST_MONTH_FAILED = "fetch_rollover_last_month_successful";

//--------------------------down payment-----------------------------//
export const DOWN_PAYMENT_DETAILS_CHANGED = "down_payment_details_changed";
export const DOWN_PAYMENT_DATE_CHANGED = "down_payment_date_changed";

export const SAVE_DOWN_PAYMENT_DETAILS = "save_down_payment_details";
export const SAVE_DOWN_PAYMENT_DETAILS_SUCCESSFUL = "save_down_payment_details_successful";
export const SAVE_DOWN_PAYMENT_DETAILS_FAILED = "save_down_payment_details_failed";

export const CLEAR_DOWN_PAYMENT_MESSAGE = "clear_down_payment_message";

export const FETCH_DOWN_PAYMENT = "fetch_down_payment";
export const FETCH_DOWN_PAYMENT_SUCCESSFUL = "fetch_down_payment_successful";
export const FETCH_DOWN_PAYMENT_FAILED = "fetch_down_payment_failed";

export const DELETE_DOWN_PAYMENT = "delete_down_payment";
export const DELETE_DOWN_PAYMENT_SUCCESSFUL = "delete_down_payment_successful";
export const DELETE_DOWN_PAYMENT_FAILED = "delete_down_payment_failed";

export const ALL_REPORT = "all_report";
export const ALL_REPORT_SUCCESSFUL = "all_report_successful";
export const ALL_REPORT_FAILED = "all_report_failed";


//-----------------------MasterList New----------------------------------//
export const FETCH_BOUNCED_CHEQUES_NEW = "fetch_bounced_cheques_new";
export const FETCH_BOUNCED_CHEQUES_NEW_SUCCESSFUL = "fetch_bounced_cheques_new_successful";
export const FETCH_BOUNCED_CHEQUES_NEW_FAILED = "fetch_bounced_cheques_new_failed";

export const FETCH_OVERDUE_TERMS_NEW = "fetch_overdue_terms_new";
export const FETCH_OVERDUE_TERMS_NEW_SUCCESSFUL = "fetch_overdue_terms_new_successful";
export const FETCH_OVERDUE_TERMS_NEW_FAILED = "fetch_overdue_terms_new_failed";

export const COLLECTOR_CHANGED_NEW = "collector_changed_new";
export const MASTER_LIST_DATE_RANGE_CHANGED_NEW = "master_list_date_range_changed_new";

export const SAVE_MASTER_LIST_COMMENT_NEW = "save_master_list_comment_new";
export const SAVE_MASTER_LIST_COMMENT_NEW_SUCCESSFUL = "save_master_list_comment_new_successful";
export const SAVE_MASTER_LIST_COMMENT_NEW_FAILED = "save_master_list_comment_new_failed";

export const MASTER_LIST_COMMENT_CHANGED_NEW = "master_list_comment_changed_new";

export const FETCH_MASTER_LIST_COMMENTS_NEW = "fetch_master_list_commeents_new";
export const FETCH_MASTER_LIST_COMMENTS_NEW_SUCCESSFUL = "fetch_master_list_commeents_new_successful";
export const FETCH_MASTER_LIST_COMMENTS_NEW_FAILED = "fetch_master_list_commeents_new_failed";

export const MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED_NEW = "master_list_comments_date_range_changed_new";
export const DEMAND_NOTICE_DATE_CHANGED_NEW = "demand_notice_date_changed_new";

export const SAVE_DEMAND_NOTICE_NEW = "save_semand_notice_new";
export const SAVE_DEMAND_NOTICE_NEW_SUCCESSFULL = "save_semand_notice_new_successful";
export const SAVE_DEMAND_NOTICE_NEW_FAILED = "save_semand_notice_new_failed";

export const FETCH_DEMAND_NOTICES_NEW = "fetch_demand_notices_new";
export const FETCH_DEMAND_NOTICES_NEW_SUCCESSFUL = "fetch_demand_notices_new_successful";
export const FETCH_DEMAND_NOTICES_NEW_FAILED = "fetch_demand_notices_new_failed";

export const SHOW_COMMENT_CARD_NEW = "show_comment_card_new";
export const HIDE_COMMENT_CARD_NEW = "hide_comment_card_new";
export const SHOW_NOTICES_CARD_NEW = "show_notices_card_new";
export const HIDE_NOTICES_CARD_NEW = "hide_notices_card_new";

export const UPDATE_DEMAND_NOTICE_DATE_CHANGED_NEW = "update_demand_notice_date_changed_new";

export const UPDATE_DEMAND_NOTICE_NEW = "update_demand_notice_new";
export const UPDATE_DEMAND_NOTICE_NEW_SUCCESSFUL = "update_demand_notice_new_successful";
export const UPDATE_DEMAND_NOTICE_NEW_FAILED = "update_demand_notice_new_failed";

export const SHOW_UPDATE_NOTICES_CARD_NEW = "show_update_notices_card_new";
export const HIDE_UPDATE_NOTICES_CARD_NEW = "hide_update_notices_card_new";
export const SHOW_ASSIGN_COLLECTOR_CARD_NEW = "show_assign_collector_card_new";
export const HIDE_ASSIGN_COLLECTOR_CARD_NEW = "hide_assign_collector_card_new";

export const FETCH_CURRENT_NOTICE_NEW = "fetch_current_notice_new";
export const DELETE_DEMAND_NOTICES_NEW = "delete_demand_notices_new";
export const DELETE_DEMAND_NOTICES_NEW_SUCCESSFUL = "delete_demand_notices_new_successful";
export const DELETE_DEMAND_NOTICES_NEW_FAILED = "delete_demand_notices_new_failed";
export const FETCH_RANGED_BOUNCED_CHEQUES_NEW = "fetch_ranged_bounced_cheques_new";
export const FETCH_RANGED_BOUNCED_CHEQUES_NEW_SUCCESSFUL = "fetch_ranged_bounced_cheques_new_successful";
export const FETCH_RANGED_BOUNCED_CHEQUES_NEW_FAILED = "fetch_ranged_bounced_cheques_new_failed";

export const NUMBER_OF_LOANS_TO_ASSIGN_CHANGED_NEW = "number_of_loans_to_assign_changed_new";
export const FILTER_LEGAL_REPO_STATUS_CHANGED_NEW = "filter_legal_repo_status_changed_new";
export const CLEAR_LEGAL_REPO_FILTER_NEW = "clear_legal_repo_filter_new";
export const FILTER_BUCKET_CHANGED_NEW = "filter_bucket_changed_new";
export const FETCH_RANGED_MASTER_LIST_COMMENTS_NEW = "fetch_ranged_master_list_comments_new";
export const FETCH_RANGED_MASTER_LIST_COMMENTS_NEW_SUCCESSFUL = "fetch_ranged_master_list_comments_new_successful";
export const FETCH_RANGED_MASTER_LIST_COMMENTS_NEW_FAILED = "fetch_ranged_master_list_comments_new_failed";

export const ASSIGN_COLLECTOR_NEW = "assign_collector_new";
export const ASSIGN_COLLECTOR_NEW_SUCCESSFUL = "assign_collector_new_successful";
export const ASSIGN_COLLECTOR_NEW_FAILED = "assign_collector_new_failed";

export const SHOW_UNASSIGNED_LOANS_NEW = "show_unasssigned_loans_new";
export const HIDE_UNASSIGNED_LOANS_NEW = "hide_unasssigned_loans_new";

export const DELETE_ASSIGNED_COLLECTOR_NEW = "delete_assigned_collector_new";
export const DELETE_ASSIGNED_COLLECTOR_NEW_SUCCESSFUL = "delete_assigned_collector_new_successful";
export const DELETE_ASSIGNED_COLLECTOR_NEW_FAILED = "delete_assigned_collector_new_failed";

export const CLEAR_COMMENT_PAGE_NEW = "clear_comment_page_new";
export const COLLECTOR_DETAILS_CHANGED_NEW = "collector_datails_changed_new";
export const OD_AGING_REPORT_DATE_CHANGED_NEW = "od_aging_report_date_changed_new";

export const SAVE_SECOND_DEMAND_NOTICE_NEW = "save_second_demand_notice_new";
export const SAVE_SECOND_DEMAND_NOTICE_NEW_SUCCESSFUL = "save_second_demand_notice_new_successful";
export const SAVE_SECOND_DEMAND_NOTICE_NEW_FAILED = "save_second_demand_notice_new_failed";

export const CLEAR_DEMAND_NOTICE_MESSAGES_NEW = "clear_demand_notice_messages_new";
export const SECOND_DEMAND_NOTICE_DATE_CHANGED_NEW = "second_demand_notice_date_changed_new";

export const DELETE_SECOND_DEMAND_NOTICES_NEW = "delete_second_demand_notices_new";
export const DELETE_SECOND_DEMAND_NOTICES_NEW_SUCCESSFUL = "delete_second_demand_notices_new_successful";
export const DELETE_SECOND_DEMAND_NOTICES_NEW_FAILED = "delete_second_demand_notices_new_failed";

export const CLEAR_COLLECTOR_MESSAGES_NEW = "clear_collector_messages_new";
export const COLLECTOR_MESSAGE_NEW ="collector_message_new";

export const FETCH_RANGED_OVERDUE_TERMS_NEW = "fetch_ranged_overdue_terms_new";
export const FETCH_RANGED_OVERDUE_TERMS_NEW_SUCCESSFUL = "fetch_ranged_overdue_terms_new_successful";
export const FETCH_RANGED_OVERDUE_TERMS_NEW_FAILED = "fetch_ranged_overdue_terms_new_failed";

export const FETCH_ALL_BEFORE_TERMS_NEW = "fetch_all_before_terms_new";
export const FETCH_ALL_BEFORE_TERMS_NEW_SUCCESSFUL = "fetch_all_before_terms_new_successful";
export const FETCH_ALL_BEFORE_TERMS_NEW_FAILED = "fetch_all_before_terms_new_failed";

export const RESET_COLLECTORS_NEW = "reset_collectors_new";
export const RESET_COLLECTORS_NEW_SUCCESSFUL = "reset_collectors_new_successful";
export const RESET_COLLECTORS_NEW_FAILED = "reset_collectors_new_failed";

export const FETCH_ROLLOVER_NEW = "fetch_rollover_new";
export const FETCH_ROLLOVER_NEW_SUCCESSFUL = "fetch_rollover_new_succesful";
export const FETCH_ROLLOVER_NEW_FAILED = "fetch_rollover_new_failed";

export const FETCH_ROLLOVER_LAST_MONTH_NEW = "fetch_rollover_last_month_new";
export const FETCH_ROLLOVER_LAST_MONTH_NEW_SUCCESSFUL = "fetch_rollover_last_month_new_successful";
export const FETCH_ROLLOVER_LAST_MONTH_NEW_FAILED = "fetch_rollover_last_month_new_failed";

export const FETCH_LAST_COMMENTS_NEW_SUCCESSFUL = "fetch_last_comments_new_successful";
export const RE_FETCH_DEMAND_NOTICES_NEW = "re_fetch_demand_notices_new";
export const FETCH_EXECUTIVE = "fetch_executive";
export const FETCH_EXECUTIVE_SUCCESSFUL = "fetch_executive_successful";
export const FETCH_EXECUTIVE_FAILED = "fetch_executive_failed";
export const FILTER_EXECUTIVE_CHANGED = "filter_executive_changed";
export const RESET_EXECUTIVE = "reset_executive";

export const DELETE_CUSTOMER = "delete_customer";
export const DELETE_CUSTOMER_SUCCESSFUL = "delete_customer_successful";
export const DELETE_CUSTOMER_FAILED = "delete_customer_failed";

export const ADD_EXTERNAL_COLLECTOR = "add_external_collector";
export const ADD_EXTERNAL_COLLECTOR_SUCCESSFUL = "add_external_collector_successful";
export const ADD_EXTERNAL_COLLECTOR_FAILED = "add_external_collector_failed";

export const FETCH_EXTERNAL_COLLECTORS = "fetch_external_collectors";
export const FETCH_EXTERNAL_COLLECTORS_SUCCESSFUL = "fetch_external_collectors_successful";
export const FETCH_EXTERNAL_COLLECTORS_FAILED = "fetch_external_collectors_failed";
export const EXTERNAL_COLLECTOR_CHANGED = "external_collectors_changed";