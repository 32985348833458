import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import {message, Switch, Button} from "antd";

//actions import
import { chequeSwitchChanged, clearPenalChanged, bankDateChanged, loanTermCardDetailsChanged, updateChequeLoanTerm, updateCashLoanTerm, recordChequeStatus, dueDateChanged,
    fetchLoanTerms, fetchLoan, enableLoanTermBtn } from "../../../actions/LoanActions";
import { fetchCashCollections } from "../../../actions/CollectionsActions";

//components import
//import {Button} from "../../common/Button";
import {PickDate} from "../../common/PickDate";
import {TextArea} from "../../common/TextArea";
import SelectChequeStatus from "../../common/SelectChequeStatus";
import {BeatLoader} from "react-spinners";
import {Input} from "../../common/Input";
import SelectBounceOrDrawerReason from "../../common/SelectBounceOrDrawerReason";

import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";



class LoanTermPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            paidAmount: this.props.paidAmount,
            cheque: false,
            clearPenal: false,
            chequeStatus: this.props.chequeStatus,
            transactionDate: this.props.transactionDate,
            bankDate: this.props.bankDate,
            bankName: this.props.bankName,
            chequeName: this.props.chequeName,
            chequeNumber: this.props.chequeNumber,
            dueDate: this.props.dueDate,
            comments: this.props.comments,
            bouncedReason: this.props.bouncedReason,
            otherReason: this.props.otherReason,

            loanTerm: this.props.loanTerm,
            currentLoan: this.props.currentLoan,

            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            disabled: this.props.disabled,

            updateLoanTermFailed: this.props.updateLoanTermFailed,
            updateLoanTermSuccessful: this.props.updateLoanTermSuccessful,

            buttonDisabled: this.props.buttonDisabled,
            penalInterest: this.props.systemInfo.penalInterest,

            localCurrency: "TSH",

        };

        this.onBankDateChanged = this.onBankDateChanged.bind(this);
        this.onChequeSwitchChange = this.onChequeSwitchChange.bind(this);
        this.onChequeStatusChanged = this.onChequeStatusChanged.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
        this.pickTransactionDate = this.pickTransactionDate.bind(this);
        this.onClearPenal = this.onClearPenal.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
    }

    componentWillMount() {

        //enable update button
        this.props.enableLoanTermBtn();

        //extract state
        const { loanTerm } = this.state;
        //check that loan term is not empty
        if (!(_.isEmpty(loanTerm))) {

            // console.log(loanTerm.chequeStatus);

            const { customerID, loanID } = loanTerm;
            this.props.fetchLoanTerms({ customerID, loanID, });
            this.props.fetchCashCollections({ customerID, loanID });
            this.props.fetchLoan({ customerID, loanID });


            //set loan term details to match loan term details
            this.setState({ chequeName: loanTerm.chequeName});
            this.setState({ chequeNumber: loanTerm.chequeNumber});
            this.setState({ bankName: loanTerm.bankName});
            this.setState({ cheque: loanTerm.cheque });
            // this.setState({ chequeStatus: loanTerm.chequeStatus});

            if (this.state.cheque) {
                this.setState({disabled: true})
            } else {
                this.setState({disabled: true})
            }

            if('transactionDate' in loanTerm){
                //extract timestamp
                const tDate = loanTerm.transactionDate;
                let tDateSec;
                tDate.seconds ? tDateSec = tDate.seconds : tDateSec = tDate._seconds;
                //convert into a moment object
                const transactionDate = moment.unix(tDateSec);
                console.log(transactionDate);

                this.setState({
                    transactionDate: transactionDate,
                    bouncedReason: loanTerm.bouncedReason,
                })
            }

            if ('otherReason' in loanTerm){
                //
                this.setState({otherReason: loanTerm.otherReason});
            }
        }

        this.checkCurrencyFormat();

    }



    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.loanTerm !== this.props.loanTerm) {
            this.setState({loanTerm: nextProps.loanTerm})
        }

        if (nextProps.updateLoanTermSuccessful !== this.props.updateLoanTermSuccessful) {
            this.setState({updateLoanTermSuccessful: nextProps.updateLoanTermSuccessful})
        }

        if (nextProps.updateLoanTermFailed !== this.props.updateLoanTermFailed) {
            this.setState({updateLoanTermFailed: nextProps.updateLoanTermFailed})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.paidAmount !== this.props.paidAmount) {
            this.setState({paidAmount: nextProps.paidAmount})
        }

        if (nextProps.cheque !== this.props.cheque) {
            this.setState({cheque: nextProps.cheque})
        }

        if (nextProps.clearPenal !== this.props.clearPenal) {
            this.setState({clearPenal: nextProps.clearPenal})
        }

        if (nextProps.chequeStatus !== this.props.chequeStatus) {
            console.log(nextProps.chequeStatus);
            console.log(this.props.chequeStatus);
            this.setState({chequeStatus: nextProps.chequeStatus})
        }

        if (nextProps.transactionDate !== this.props.transactionDate) {
            this.setState({transactionDate: nextProps.transactionDate})
        }

        if (nextProps.chequeName !== this.props.chequeName) {
            this.setState({chequeName: nextProps.chequeName})
        }

        if (nextProps.chequeNumber !== this.props.chequeNumber) {
            this.setState({chequeNumber: nextProps.chequeNumber})
        }

        if (nextProps.bankName !== this.props.bankName) {
            this.setState({bankName: nextProps.bankName})
        }

        if (nextProps.dueDate !== this.props.dueDate) {
            this.setState({dueDate: nextProps.dueDate})
        }

        if (nextProps.bankDate !== this.props.bankDate) {
            this.setState({bankDate: nextProps.bankDate})
        }

        if (nextProps.comments !== this.props.comments) {
            this.setState({comments: nextProps.comments})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            if ("penalInterest" in nextProps.systemInfo) {
                this.setState({ penalInterest: nextProps.systemInfo.penalInterest });
            }

            this.setState({systemInfo: nextProps.systemInfo})
        }

        if (nextProps.bouncedReason !== this.props.bouncedReason) {
            this.setState({bouncedReason: nextProps.bouncedReason});
        }

        if (nextProps.otherReason !== this.props.otherReason) {
            this.setState({otherReason: nextProps.otherReason});
        }

        if (nextProps.disabled !== this.props.disabled) {
            this.setState({disabled: nextProps.disabled})
        }

        if (nextProps.buttonDisabled !== this.props.buttonDisabled) {
            this.setState({ buttonDisabled: nextProps.buttonDisabled })
        }

    }


    onBankDateChanged(date) {
        this.props.bankDateChanged(date)
    }

    // onDueDateChanged(date) {
    //     this.props.dueDateChanged(date)
    // }

    onChequeSwitchChange() {
        const status = !this.state.cheque;
        this.props.chequeSwitchChanged(status)
    }

    onClearPenal() {
        const status = !this.state.clearPenal;
        this.props.clearPenalChanged(status)
    }

    onChequeStatusChanged(value) {
        //extract state
        const { loanTerm } = this.state;

        //check that new status is cleared
        if (value === "cleared") {
            //check that it is same or before today
            const today = moment();
            let seconds;
            loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;
            const dueDate = moment.unix(seconds);
            if (dueDate.isSameOrBefore(today, "day")) {
                this.props.recordChequeStatus(value);
            } else {
                message.warn("You can only clear a cheque that has reached its due date");
            }
        } else {
            this.props.recordChequeStatus(value);
        }
    }

    pickTransactionDate(value) {
        this.props.loanTermCardDetailsChanged({prop: 'transactionDate', value})
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;
        if (profile.viewInUSD) {
            //change currency to usd
            currency = "USD";

            //check if user has selected a specific exchange rate date
            if ("exchangeRateOnDate" in profile) {
                //grab the exchange rate from profile
                const { exchangeRateOnDate } = profile;
                displayValue = value / exchangeRateOnDate;
            } else {
                //grab the default exchange rate from system
                const { exchangeRate } = systemInfo;
                displayValue = value / exchangeRate;
            }
        } else {
            currency = localCurrency;

            displayValue = value;
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p id={"loanTermsValue"}>{formatter.format(displayValue)}</p>;
    }


    onUpdateLoanTerm() {

        const { loanTerm, clearPenal, penalInterest } = this.state;

        //this.setState({ buttonDisabled: false });

        if (!penalInterest) {
            if (!(_.isEmpty(loanTerm))) {

                let { chequeStatus, transactionDate, chequeName, chequeNumber, bankName, bankDate, comments, profile, bouncedReason, otherReason } = this.state;


                const access = profile.userAccess.some(access => access === "clientsUpdates");

                if (access) {
                    const customerID = loanTerm.customerID;
                    const loanTermID = loanTerm.loanTermID;
                    const cheque = loanTerm.cheque
                    const loanID = loanTerm.loanID;
                    const previousChequeStatus = loanTerm.chequeStatus;
                    const currency = loanTerm.currency;

                    //comment object
                    const timeStamp = new Date();
                    const collectorID = profile.userID;

                    const comment = { timeStamp, collectorID, comments };

                    if (cheque) {
                        //its a cheque
                        //take three variables: comment, chequeStatus, and newDueDate
                        //comment is not mandatory
                        //check if chequeStatus is similar to saved chequeStatus or if its empty
                        let status;
                        if (chequeStatus === "") {
                            //don't update cheque
                            status = loanTerm.chequeStatus;
                        } else {
                            status = chequeStatus;
                        }

                        if(chequeStatus !== loanTerm.chequeStatus){
                            //
                            //check if status is held. if so, bankDate(new due date) is required
                            let paidAmount = loanTerm.amount;
                            switch (status) {
                                case "held":
                                    if (bankDate) {
                                        bankDate = bankDate.toDate();
                                        paidAmount = 0;
                                        //transactionDate is an empty string
                                        this.props.updateChequeLoanTerm({customerID, loanTerm, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                                    } else {
                                        //inform bank date is required
                                        message.warning("Fill in a new due date");
                                    }
                                    break;
                                case "bounced":
                                    if (previousChequeStatus !== "cleared") {
                                        paidAmount = 0;
                                    }
                                    if (transactionDate) {
                                        transactionDate = transactionDate.toDate();
                                        //bankDate is an empty string
                                        this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                                    } else {
                                        //inform transaction date is required
                                        message.warning("Fill in a transaction date");
                                    }
                                    break;
                                case "notDeposited":
                                    const today = moment();

                                    let seconds;
                                    loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;
                                    const termDueDate = moment.unix(seconds);

                                    if (termDueDate.isSameOrBefore(today, "day")) {
                                        //
                                        if (previousChequeStatus !== "cleared") {
                                            paidAmount = 0;
                                        }
                                        //
                                        this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                                    } else {
                                        //
                                        this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                                    }
                                    break;
                                case "cleared":
                                    //status is not held or bounced so go on
                                    //bank date and transaction date are empty strings
                                    this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                                    break;
                                default:
                                    //status is not held or bounced so go on
                                    //bank date and transaction date are empty strings
                                    this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, bankName: bankName.toUpperCase(), previousChequeStatus, bankDate, comment, currency, clearPenal, bouncedReason, otherReason });
                            }
                        } else {
                            //
                            message.warning("Sorry! There is no changes to update");
                        }


                        // if (status === "held") {
                        //
                        // }
                        // //check if status is bounced. if so, transaction date is required
                        // else if (status === "bounced") {
                        //
                        // } else {
                        //     //status is not held or bounced so go on
                        //     //bank date and transaction date are empty strings
                        //     console.log({ paidAmount });
                        //     this.props.updateChequeLoanTerm({customerID, loanID, paidAmount, loanTermID, chequeStatus: status, transactionDate, chequeName, chequeNumber, dueDate, bankName, previousChequeStatus, bankDate, comment, currency });
                        // }

                    } else {
                        //its a cash term
                        //only take the comments
                        //its a cash payment
                        //comment is not mandatory
                        this.props.updateCashLoanTerm({ customerID, loanTermID, comment })
                    }
                } else {
                    message.warning("You're not authorized to make updates");
                }

            } else {
                message.warning("Sorry! There is no selected loan to update");
            }
        } else {
            // penal interest is true
            message.warning("Please switch off penal interest before you proceed to record cash collection");
        }
    }

    renderDueDate(){
        //
        const {loanTerm} = this.state;

        if (!(_.isEmpty(loanTerm))){
            //grab loan term due date
            //extract timestamp
            const date = loanTerm.dueDate;
            let dueDateSec;
            date.seconds ? dueDateSec = date.seconds : dueDateSec = date._seconds;
            //convert into a moment object
            const dueDate = moment.unix(dueDateSec).format("DD/MM/YYYY");

            return <p id={"loanTermIDValue"}>{dueDate}</p>;
        } else {

            return null;
        }

    }

    renderChequeStatus(status) {
        //
        switch (status) {
            //
            case "pending":
                return <p id={"loanTermIDValue"}>Pending</p>;
            case "notDeposited":
                return <p id={"loanTermIDValue"}>Not Deposited</p>;
            case "bounced":
                return <p id={"loanTermIDValue"}>Bounced</p>;
            case "cleared":
                return <p id={"loanTermIDValue"}>Cleared</p>;
            case "held":
                return <p id={"loanTermIDValue"}>Held</p>;

            default:
                return <p id={"loanTermIDValue"}> </p>;
        }
    }

    checkUserAccess() {
        //extract state
        const { profile, loanTerm, updateLoanTermSuccessful, updateLoanTermFailed } = this.state;

        const access = profile.userAccess.some(access => access === "clientsView");

        if (access) {
            return(
                <div>
                    <div className={"loanTermLabelBox"}>
                        <div className={"firstTermLabelBox"}>
                            <div className={"termBox"}>
                                <p>Loan term ID </p>
                                <p id={"loanTermIDValue"}>{loanTerm.loanTermID}</p>
                            </div>

                            <div className={"termBox"}>
                                <p>Amount</p>
                                {this.displayCurrency(loanTerm.amount)}
                            </div>

                            <div className={"termBox"}>
                                <p>Due Date </p>
                                {this.renderDueDate()}
                            </div>

                            <div className={"termBox"}>
                                <p>Payment Method</p>
                                <Switch
                                    checkedChildren="Cheque"
                                    unCheckedChildren="Cash"
                                    onChange={() => this.onChequeSwitchChange() }
                                    checked={this.state.cheque}
                                    disabled={this.state.disabled}
                                />
                            </div>
                        </div>

                        {
                            this.state.cheque?
                                <div className={"chequeTermBox"}>
                                    <div className={"termBox"}>
                                        <Input
                                            label={'Cheque Name'}
                                            type={'text'}
                                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'chequeName', value: e.target.value})}
                                            value={this.state.chequeName}
                                        />
                                    </div>
                                    <div className={"termBox"}>
                                        <Input
                                            label={'Cheque Number'}
                                            type={'text'}
                                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'chequeNumber', value: e.target.value})}
                                            value={this.state.chequeNumber}
                                        />
                                    </div>
                                    <div className={"termBox"}>
                                        <Input
                                            label={'Bank Name'}
                                            type={'text'}
                                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'bankName', value: e.target.value})}
                                            value={this.state.bankName}
                                        />
                                    </div>
                                    <div className={"termBox"}>
                                        <p>Current Cheque Status </p>
                                        {this.renderChequeStatus(loanTerm.chequeStatus)}
                                    </div>
                                    <div className={"termBox"}>
                                        <SelectChequeStatus
                                            label={'Change Cheque Status'}
                                            onChange={this.onChequeStatusChanged}
                                            value={this.state.chequeStatus}
                                            disabled={this.state.buttonDisabled}
                                        />
                                    </div>
                                </div>: null
                        }

                        <div className={"thirdTermLabelBox"}>
                            {
                                this.state.cheque ?
                                    this.state.chequeStatus === "held" ?
                                        <div className={"termBox"}>
                                            <PickDate
                                                label={'New Due Date'}
                                                onChange={this.onBankDateChanged}
                                                value={this.state.bankDate}
                                            />
                                        </div>
                                        : null
                                : null
                            }

                            {
                                this.state.chequeStatus === 'bounced'?
                                    <div className={"termBox"}>
                                        <PickDate
                                            label={'Transaction Date'}
                                            onChange={this.pickTransactionDate}
                                            value={this.state.transactionDate}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                this.state.chequeStatus === 'bounced'?
                                    <div className={"termBox"}>
                                        <p>Bounced Reason</p>
                                        <SelectBounceOrDrawerReason
                                            value={this.state.bouncedReason}
                                            onChange={value => this.props.loanTermCardDetailsChanged({ prop: 'bouncedReason', value })}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                this.state.bouncedReason === 'others'?
                                    <div className={"termBox"}>
                                        <Input
                                            label={'Other Reason'}
                                            type={'text'}
                                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'otherReason', value: e.target.value})}
                                            value={this.state.otherReason}
                                        />
                                    </div>
                                    : null
                            }
                            {
                                this.state.chequeStatus === 'cleared'?
                                    <div className={"termBox"}>
                                        <Switch
                                            checkedChildren="Continue With Penal Interest"
                                            unCheckedChildren="Clear Penal Interest"
                                            onChange={() => this.onClearPenal() }
                                            checked={this.state.clearPenal}
                                        />
                                    </div>
                                    : null
                            }
                            <div className={"termBox"}>
                                <TextArea
                                    label={'Comments'}
                                    type={'text'}
                                    onChange={e => this.props.loanTermCardDetailsChanged({prop: 'comments', value: e.target.value})}
                                    value={this.state.comments}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={"loanTermLabelSaveButton"}>
                        {
                            this.props.updateLoanTermLoading?
                                <BeatLoader/>:
                                <Button
                                    onClick={() => this.onUpdateLoanTerm()}
                                    disabled={this.state.buttonDisabled}
                                >
                                    <span>Update</span>
                                </Button>
                        }
                    </div>
                    <div>
                        <div>
                            {
                                updateLoanTermSuccessful?
                                    <p>{message.success("Loan term is successfully updated")}</p>:
                                    null
                            }
                        </div>
                        <div>
                            {
                                updateLoanTermFailed?
                                    <p>{message.error("Loan term updates failed")}</p>:
                                    null
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }


    render() {
        return (
            <div>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { currentLoan, paidAmount, dueDate, clearPenal, chequeStatus, transactionDate, chequeName, chequeNumber, bankName, bankDate, loanTerm, bouncedReason, otherReason, comments, updateLoanTermLoading, updateLoanTermFailed, updateLoanTermSuccessful, buttonDisabled } = state.loans;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        //
        profile,
        systemInfo,
        currentLoan,
        loanTerm,

        paidAmount,
        dueDate,
        clearPenal,
        chequeStatus,
        transactionDate,
        chequeName,
        chequeNumber,
        bankName,
        bankDate,
        comments,
        bouncedReason,
        otherReason,

        updateLoanTermLoading,
        updateLoanTermFailed,
        updateLoanTermSuccessful,

        buttonDisabled
    }
};


export default connect(mapStateToProps,{chequeSwitchChanged, clearPenalChanged, bankDateChanged, loanTermCardDetailsChanged, updateChequeLoanTerm,
    updateCashLoanTerm, recordChequeStatus, dueDateChanged, fetchCashCollections, fetchLoanTerms, fetchLoan, enableLoanTermBtn })(LoanTermPage);