import {
    CALCULATE_PENAL_INTEREST,
    CALCULATE_PENAL_INTEREST_FAILED,
    CALCULATE_PENAL_INTEREST_SUCCESSFUL,
    CLEAR_MIGRATED_CHEQUES,
    CLEAR_MIGRATED_CHEQUES_FAILED,
    CLEAR_MIGRATED_CHEQUES_SUCCESSFUL,
    EXCHANGE_RATE_CHANGED,
    EXCHANGE_DATE_CHANGED,
    SETTINGS_FIELD_UPDATED,
    SHOW_DEFAULT_CURRENCY,
    SHOW_DEFAULT_CURRENCY_FAILED,
    SHOW_DEFAULT_CURRENCY_SUCCESSFUL,
    VIEW_CURRENCY_IN_USD,
    VIEW_CURRENCY_IN_USD_FAILED,
    VIEW_CURRENCY_IN_USD_SUCCESSFUL,
    SAVE_EXCHANGE_RATE,
    SAVE_EXCHANGE_RATE_SUCCESSFUL,
    SAVE_EXCHANGE_RATE_FAILED,
    CLEAR_EXCHANGE_RATE_MESSAGE,
    CLEAR_MIGRATION_MESSAGES,
    CLEAR_SETTING_PENAL_INTEREST_MESSAGE,
    SET_MIGRATION_DATE,
    SET_MIGRATION_DATE_SUCCESSFULLY,
    SET_MIGRATION_DATE_FAILED,
    CLEAR_SET_MIGRATION_DATE_MESSAGE,
    CLEAR_PENAL_INTEREST_MESSAGE,
    FETCH_EXCHANGE_RATES,
    FETCH_EXCHANGE_RATES_SUCCESSFUL,
    FETCH_EXCHANGE_RATES_FAILED,

    CHANGED_INDUSTRY_DETAILS,
    INDUSTRY_ADDING,
    INDUSTRY_ADDING_SUCCESSFUL,
    INDUSTRY_ADDING_FAILED,

    CHANGED_SEGMENT_DETAILS,
    SEGMENT_ADDING,
    SEGMENT_ADDING_SUCCESSFUL,
    SEGMENT_ADDING_FAILED,

    CHANGED_REPOSTATUS_DETAILS,
    REPOSTATUS_ADDING,
    REPOSTATUS_ADDING_SUCCESSFUL,
    REPOSTATUS_ADDING_FAILED,

    FETCH_INDUSTRY_TITLE_SUCCESSFUL,
    FETCH_INDUSTRY_TITLE_FAILED,
    FETCH_SEGMENT_TITLE_SUCCESSFUL,
    FETCH_SEGMENT_TITLE_FAILED,
    FETCH_REPOSTATUS_SUCCESSFUL,
    FETCH_REPOSTATUS_FAILED,
    CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS,
    CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_SUCCESSFUL,
    CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_FAILED,
    CHARACTER_DETAILS_CHANGED,
    SAVE_CLIENT_CHARACTER,
    SAVE_CLIENT_CHARACTER_SUCCESSFUL,
    SAVE_CLIENT_CHARACTER_FAILED,
    FETCH_CLIENT_CHARACTER_SUCCESSFUL,
    FETCH_ACTIONS_WA_SUCCESSFUL,
    FETCH_ACTIONS_WBNA_SUCCESSFUL,
    FETCH_ACTIONS_UBA_SUCCESSFUL,
    FETCH_ACTIONS_UU_SUCCESSFUL,
    CLIENT_ACTION_DETAILS_CHANGED,
    SAVE_CLIENT_ACTION,
    SAVE_CLIENT_ACTION_SUCCESSFUL, 
    SAVE_CLIENT_ACTION_FAILED, 
    FETCH_CLIENT_ACTIONS_SUCCESSFUL,
    FETCH_EXECUTIVE,
    FETCH_EXECUTIVE_SUCCESSFUL,
    FETCH_EXECUTIVE_FAILED
} from "../actions/Types";

const INITIAL_STATE = {
    exchangeRate: "",

    exchangeDate: "",
    exchangeRateOnDate: "",

    viewInUsdLoading: "",
    defaultCurrencyLoading: "",

    exchangeRateDate: "",
    migrationDate: "",

    industryValue: "",
    industryLoading: false,
    industrySuccess: false,
    industryFailed: false,

    segmentValue: "",
    segmentLoading: false,
    segmentSuccess: false,
    segmentFailed: false,

    repoStatusValue: "",
    repoStatusLoading: false,
    repoStatusSuccess: false,
    repoStatusFailed: false,

    characterValue: "",
    characterLoading: false,
    characterSuccess: false,
    characterFailed: false,

    clientAction: "",
    character: "",
    actionLoading: false,
    actionSuccess: false,
    actionFailed: false,

    //loading state
    clearMigrationLoading: false,
    calculatePenalInterestLoading: false,
    penalBtn: "",
    saveExchangeRateLoading: false,
    setMigrationDateLoading: false,
    //success state
    clearMigrationSuccess: false,
    calculatePenalInterestSuccess: false,
    saveExchangeRateSuccess: false,
    setMigrationDateSuccess: false,
    //failed state
    clearMigrationFailed: false,
    calculatePenalInterestFailed: false,
    saveExchangeRateFailed: false,
    setMigrationDateFailed: false,

    exchangeRates: {},
    exchangeRateLoading: false,

    industryTitle: {},
    segment: {},
    reposStatus: {},
    characters: {},
    clientActions: {},
    actionsWA: {},
    actionsWBNA: {},
    actionsUBA: {},
    actionsUU: {},
    executives: {}

};

const SettingReducer =  (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //exchange rate reducer
        case EXCHANGE_RATE_CHANGED:
            return { ...state, exchangeRate: action.payload };
        case EXCHANGE_DATE_CHANGED:
            return {...state, exchangeDate: action.payload };

        case SAVE_EXCHANGE_RATE:
            return { ...state, saveExchangeRateLoading: true };
        case SAVE_EXCHANGE_RATE_SUCCESSFUL:
            return { ...state, exchangeRateDate: "", exchangeRate: "", saveExchangeRateLoading: false, saveExchangeRateSuccess: true };
        case SAVE_EXCHANGE_RATE_FAILED:
            return { ...state, saveExchangeRateLoading: false, saveExchangeRateFailed: true };
        case CLEAR_EXCHANGE_RATE_MESSAGE:
            return {...state, exchangeRateDate: "", exchangeRate: "", saveExchangeRateLoading: false, saveExchangeRateSuccess: false, saveExchangeRateFailed: false };

        case VIEW_CURRENCY_IN_USD:
            return {...state, viewInUsdLoading: true };
        case VIEW_CURRENCY_IN_USD_SUCCESSFUL:
            return {...state, viewInUsdLoading: false };
        case VIEW_CURRENCY_IN_USD_FAILED:
            return {...state, viewInUsdLoading: false };

        case SHOW_DEFAULT_CURRENCY:
            return {...state, defaultCurrencyLoading: true };
        case SHOW_DEFAULT_CURRENCY_SUCCESSFUL:
            return {...state, exchangeRateOnDate: "", defaultCurrencyLoading: false };
        case SHOW_DEFAULT_CURRENCY_FAILED:
            return {...state, defaultCurrencyLoading: false };

        case SETTINGS_FIELD_UPDATED:
            return { ...state, [action.payload.prop]: action.payload.value };


        case CLEAR_MIGRATED_CHEQUES:
            return { ...state, clearMigrationLoading: true };
        case CLEAR_MIGRATED_CHEQUES_SUCCESSFUL:
            return { ...state, clearMigrationLoading: false, clearMigrationSuccess: true };
        case CLEAR_MIGRATED_CHEQUES_FAILED:
            return { ...state, clearMigrationLoading: false, clearMigrationFailed: true };
        case CLEAR_MIGRATION_MESSAGES:
            return {...state, clearMigrationLoading: false, clearMigrationSuccess: false, clearMigrationFailed: false };

        case CALCULATE_PENAL_INTEREST:
            return { ...state, calculatePenalInterestLoading: true, penalBtn: action.payload };
        case CALCULATE_PENAL_INTEREST_SUCCESSFUL:
            return { ...state, calculatePenalInterestLoading: false, penalBtn: "" };
        case CALCULATE_PENAL_INTEREST_FAILED:
            return { ...state, calculatePenalInterestLoading: false, penalBtn: "" };
        case CLEAR_SETTING_PENAL_INTEREST_MESSAGE:
            return {...state, calculatePenalInterestLoading: false, calculatePenalInterestSuccess: false, calculatePenalInterestFailed: false };
        case CLEAR_PENAL_INTEREST_MESSAGE:
            return {...state, calculatePenalInterestLoading: false, calculatePenalInterestSuccess: false, calculatePenalInterestFailed: false };

        case CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS:
            return {...state, calculatePenalInterestLoading: true};
        case CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_SUCCESSFUL:
            return {...state, calculatePenalInterestLoading: false, calculatePenalInterestSuccess: true, };
        case CALCULATE_PENAL_INTEREST_FOR_ALL_LOANS_FAILED:
            return {...state, calculatePenalInterestLoading: false, calculatePenalInterestFailed: true };

        case SET_MIGRATION_DATE:
            return {...state, setMigrationDateLoading: true };
        case SET_MIGRATION_DATE_SUCCESSFULLY:
            return {...state, migrationDate: "", setMigrationDateLoading: false, setMigrationDateSuccess: true };
        case SET_MIGRATION_DATE_FAILED:
            return {...state, migrationDate: "", setMigrationDateLoading: false, setMigrationDateFailed: true };
        case CLEAR_SET_MIGRATION_DATE_MESSAGE:
            return {...state, migrationDate: "", setMigrationDateLoading: false, setMigrationDateSuccess: false, setMigrationDateFailed: false };


        case FETCH_EXCHANGE_RATES:
            return { ...state, exchangeRateLoading: true };
        case FETCH_EXCHANGE_RATES_SUCCESSFUL:
            return { ...state, exchangeRates: action.payload, exchangeRateLoading: false };
        case FETCH_EXCHANGE_RATES_FAILED:
            return { ...state, exchangeRateLoading: false };

        case CHANGED_INDUSTRY_DETAILS:
            console.log(action.payload)
            return { ...state, [action.payload.prop]: action.payload.value  };
        case INDUSTRY_ADDING:
            return { ...state, industryLoading: true};
        case INDUSTRY_ADDING_SUCCESSFUL:
            return { ...state, industryLoading: false, industrySuccess: true, industryFailed: false, industryValue: ""};
        case INDUSTRY_ADDING_FAILED:
            return { ...state, industryLoading: false, industrySuccess: false, industryFailed: true };


        case FETCH_INDUSTRY_TITLE_SUCCESSFUL: 
            return { ...state, industryTitle: action.payload}

        case FETCH_INDUSTRY_TITLE_FAILED:
            return { ...state, }

        case FETCH_SEGMENT_TITLE_SUCCESSFUL: 
            return { ...state, segment: action.payload}

        case FETCH_SEGMENT_TITLE_FAILED:
            return { ...state, }

        case FETCH_REPOSTATUS_SUCCESSFUL:
            return { ...state, reposStatus: action.payload}
        case FETCH_REPOSTATUS_FAILED:
            return { ...state, }

        case CHANGED_SEGMENT_DETAILS:
            return { ...state, [action.payload.prop]: action.payload.value  };
        case SEGMENT_ADDING:
            return { ...state, segmentLoading: true};
        case SEGMENT_ADDING_SUCCESSFUL:
            return { ...state, segmentLoading: false, segmentSuccess: true, segmentFailed: false, segmentValue: ""};
        case SEGMENT_ADDING_FAILED:
            return { ...state, segmentLoading: false, segmentSuccess: false, segmentFailed: true };

        case CHANGED_REPOSTATUS_DETAILS:
            return { ...state, [action.payload.prop]: action.payload.value  };
        case REPOSTATUS_ADDING:
            return { ...state, repoStatusLoading: true};
        case REPOSTATUS_ADDING_SUCCESSFUL:
            return { ...state, repoStatusLoading: false, repoStatusSuccess: true, repoStatusFailed: false, repoStatusValue: ""};
        case REPOSTATUS_ADDING_FAILED:
            return { ...state, repoStatusLoading: false, repoStatusSuccess: false, repoStatusFailed: true };

        case CHARACTER_DETAILS_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };
        case SAVE_CLIENT_CHARACTER:
            return { ...state, characterLoading: true};
        case SAVE_CLIENT_CHARACTER_SUCCESSFUL:
            return { ...state, characterLoading: false, characterSuccess: true, characterFailed: false, characterValue: ""};
        case SAVE_CLIENT_CHARACTER_FAILED:
            return { ...state, characterLoading: false, characterSuccess: false, characterFailed: true };

        case CLIENT_ACTION_DETAILS_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };
        case SAVE_CLIENT_ACTION:
            return { ...state, actionLoading: true};
        case SAVE_CLIENT_ACTION_SUCCESSFUL:
            return { ...state, actionLoading: false, actionSuccess: true, actionFailed: false, clientAction: "", character: ""};
        case SAVE_CLIENT_ACTION_FAILED:
            return { ...state, actionLoading: false, actionSuccess: false, actionFailed: true };

        case FETCH_CLIENT_CHARACTER_SUCCESSFUL:
            return { ...state, characters: action.payload};

        case FETCH_CLIENT_ACTIONS_SUCCESSFUL:
            return { ...state, clientActions: action.payload};

        case FETCH_ACTIONS_WA_SUCCESSFUL:
            return { ...state, actionsWA: action.payload};

        case FETCH_ACTIONS_WBNA_SUCCESSFUL:
            return { ...state, actionsWBNA: action.payload};

        case FETCH_ACTIONS_UBA_SUCCESSFUL:
            return { ...state, actionsUBA: action.payload};

        case FETCH_ACTIONS_UU_SUCCESSFUL:
            return { ...state, actionsUU: action.payload};

        case FETCH_EXECUTIVE:
            return { ...state, };
        case FETCH_EXECUTIVE_SUCCESSFUL:
            return { ...state, executives: action.payload};
        case FETCH_EXECUTIVE_FAILED:
            return { ...state, };

        default:
            return state;
    }
};

export default SettingReducer;