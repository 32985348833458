import * as firebase from "firebase";

import {
    CLEAR_MIGRATED_CHEQUES,
    CLEAR_MIGRATED_CHEQUES_FAILED,
    CLEAR_MIGRATED_CHEQUES_SUCCESSFUL,
    EXCHANGE_RATE_CHANGED,
    EXCHANGE_DATE_CHANGED,
    MIGRATION_ACTIVATION,
    MIGRATION_ACTIVATION_FAILED,
    MIGRATION_ACTIVATION_SUCCESSFUL,
    SET_MIGRATION_DATE,
    SET_MIGRATION_DATE_FAILED,
    SET_MIGRATION_DATE_SUCCESSFULLY,
    SETTINGS_FIELD_UPDATED,
    SHOW_DEFAULT_CURRENCY,
    SHOW_DEFAULT_CURRENCY_FAILED,
    SHOW_DEFAULT_CURRENCY_SUCCESSFUL,
    VIEW_CURRENCY_IN_USD,
    VIEW_CURRENCY_IN_USD_FAILED,
    VIEW_CURRENCY_IN_USD_SUCCESSFUL,
    SAVE_EXCHANGE_RATE,
    SAVE_EXCHANGE_RATE_SUCCESSFUL,
    SAVE_EXCHANGE_RATE_FAILED,
    CLEAR_EXCHANGE_RATE_MESSAGE,
    CLEAR_MIGRATION_MESSAGES,

    PENAL_INTEREST_ACTIVATION,
    PENAL_INTEREST_ACTIVATION_SUCCESSFUL,
    PENAL_INTEREST_ACTIVATION_FAILED,
    FETCH_EXCHANGE_RATES,
    FETCH_EXCHANGE_RATES_SUCCESSFUL,
    FETCH_EXCHANGE_RATES_FAILED,
    DELETE_EXCHANGE_RATE,
    DELETE_EXCHANGE_RATE_SUCCESSFUL,
    DELETE_EXCHANGE_RATE_FAILED,
    //
    CHANGED_INDUSTRY_DETAILS,
    INDUSTRY_ADDING,
    INDUSTRY_ADDING_SUCCESSFUL,
    INDUSTRY_ADDING_FAILED,

    CHANGED_SEGMENT_DETAILS,
    SEGMENT_ADDING,
    SEGMENT_ADDING_SUCCESSFUL,
    SEGMENT_ADDING_FAILED,

    CHANGED_REPOSTATUS_DETAILS,
    REPOSTATUS_ADDING,
    REPOSTATUS_ADDING_SUCCESSFUL,
    REPOSTATUS_ADDING_FAILED,

    FETCH_INDUSTRY_TITLE_SUCCESSFUL,
    FETCH_INDUSTRY_TITLE_FAILED,

    FETCH_SEGMENT_TITLE_SUCCESSFUL,
    FETCH_SEGMENT_TITLE_FAILED,

    FETCH_REPOSTATUS_SUCCESSFUL,
    FETCH_REPOSTATUS_FAILED,
    FETCH_ACTIONS_WA_SUCCESSFUL,
    FETCH_ACTIONS_WA_FAILED,
    FETCH_ACTIONS_WBNA_SUCCESSFUL,
    FETCH_ACTIONS_WBNA_FAILED,
    FETCH_ACTIONS_UU_SUCCESSFUL,
    FETCH_ACTIONS_UBA_SUCCESSFUL,
    FETCH_ACTIONS_UBA_FAILED,
    FETCH_ACTIONS_UU_FAILED,
    CLIENT_ACTION_DETAILS_CHANGED,
    FETCH_CLIENT_CHARACTER_SUCCESSFUL,
    FETCH_CLIENT_CHARACTER_FAILED,
    FETCH_CLIENT_ACTIONS_SUCCESSFUL,
    FETCH_CLIENT_ACTIONS_FAILED,
    SAVE_CLIENT_ACTION,
    SAVE_CLIENT_ACTION_SUCCESSFUL,
    SAVE_CLIENT_ACTION_FAILED,
    SAVE_CLIENT_CHARACTER_SUCCESSFUL,
    SAVE_CLIENT_CHARACTER_FAILED,
    SAVE_CLIENT_CHARACTER,
    CHARACTER_DETAILS_CHANGED,
    FETCH_EXECUTIVE,
    FETCH_EXECUTIVE_SUCCESSFUL,
    FETCH_EXECUTIVE_FAILED

} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const exchangeRateChanged = (value) => {
    return {
        type: EXCHANGE_RATE_CHANGED,
        payload: value
    }
};


export const settingsFieldUpdated = ({ prop, value }) => {
    return {
        type: SETTINGS_FIELD_UPDATED,
        payload: { prop, value }
    }
};

export const exchangeDateChanged = (value) => {
    return {
        type: EXCHANGE_DATE_CHANGED,
        payload: value
    }
};

export const saveExchangeRate = ({ adminID, exchangeRate, exchangeRateDate }) => {
    return(dispatch) => {
        dispatch({ type: SAVE_EXCHANGE_RATE });
        firebase.firestore().collection("users").doc('admins').collection(adminID).doc("public").collection("account").doc("info")
            .update({ currency: "usd", exchangeRate })
            .then(() => {
                console.log("view currency saved in profile successful");
                setExchangeRate({ exchangeRateDate, exchangeRate, dispatch });
            })
            .catch((error) => {
                dispatch({ type: SAVE_EXCHANGE_RATE_FAILED });
                console.log("view currency saved in profile failed");
                console.log(error);
                dispatch({type: CLEAR_EXCHANGE_RATE_MESSAGE});
            })
    };
};

function setExchangeRate({ exchangeRateDate, exchangeRate, dispatch }) {
    const rateID = exchangeRateDate.format("DDMMYYYY");
    const date = exchangeRateDate.toDate();

    firebase.firestore().collection("exchangeRates").doc(rateID)
        .set({ rateID, exchangeRate, exchangeRateDate: date }, { merge: true })
        .then(() => {
            firebase.firestore().collection("system").doc("info")
                .update({ exchangeRate })
                .then(() => {

                    dispatch({ type: SAVE_EXCHANGE_RATE_SUCCESSFUL });
                    console.log("exchange rate saved successfully");
                    dispatch({type: CLEAR_EXCHANGE_RATE_MESSAGE});
                }).catch(e => console.log(e));
        }).catch(e => {
        dispatch({ type: SAVE_EXCHANGE_RATE_FAILED });
            console.log("exchange rate not saved");
            console.log(e);
            dispatch({type: CLEAR_EXCHANGE_RATE_MESSAGE});
    })
}

export const viewCurrencyInUSD = ({ adminID, exchangeRateOnDate }) => {
    return(dispatch) => {
        dispatch({ type: VIEW_CURRENCY_IN_USD });

        //setup updates object
        let updates = {
            viewInUSD: true
        }

        //check if exchangeRateOnDate is true
        if (exchangeRateOnDate) {
            //grab exchange rate of specified date
            const rateID = exchangeRateOnDate.format("DDMMYYYY");
            firebase.firestore().collection("exchangeRates").doc(rateID)
                .get()
                .then((doc) => {
                    const data = doc.data();

                    //set date into updates objects
                    updates["exchangeRateOnDate"] = data.exchangeRate;
                    showCurrency({ adminID, updates, dispatch });
                }).catch(e => console.log(e));
        } else {
            showCurrency({ adminID, updates, dispatch });
        }
    }
}

function showCurrency({ adminID, updates, dispatch }) {
    firebase.firestore().collection("users").doc("admins").collection(adminID).doc("public").collection("account").doc("info")
        .update(updates)
        .then(() => {
            dispatch({ type: VIEW_CURRENCY_IN_USD_SUCCESSFUL });
            console.log({ updates });
        })
        .catch(e => {
            console.log(e);
            dispatch({ type: VIEW_CURRENCY_IN_USD_FAILED });
        })
}

export const showDefaultCurrency = ({ adminID }) => {
    return (dispatch) => {
        dispatch({ type: SHOW_DEFAULT_CURRENCY });
        firebase.firestore().collection("users").doc("admins").collection(adminID).doc("public").collection("account").doc("info")
            .update({
                viewInUSD: false,
                exchangeRateOnDate: firebase.firestore.FieldValue.delete()
            }).then(() => {
                dispatch({ type: SHOW_DEFAULT_CURRENCY_SUCCESSFUL });
        })
            .catch(error => {
                dispatch({ type: SHOW_DEFAULT_CURRENCY_FAILED });
                console.log(error);
            })
    }
};

export const clearMigratedTerms = () => {

    return(dispatch) => {
        //invoke custom database function
        dispatch({type: CLEAR_MIGRATED_CHEQUES});
        const url = `${project.serverUrl}clearMigratedTermsForAllLoans`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then(() => {
            dispatch({type: CLEAR_MIGRATED_CHEQUES_SUCCESSFUL,});
            console.log("migrated terms cleared");
            dispatch({type: CLEAR_MIGRATION_MESSAGES});
        }).catch((error) => {
            dispatch({type: CLEAR_MIGRATED_CHEQUES_FAILED});
            console.log("migrated terms NOT cleared");
            console.log(error);
            dispatch({type: CLEAR_MIGRATION_MESSAGES});
        });
    }
};

export const migrationActivation = (migration) => {

    return(dispatch) => {
        dispatch({ type: MIGRATION_ACTIVATION });

        firebase.firestore().collection("system").doc("info")
            .update({ migration })
            .then(() => {
                dispatch({ type: MIGRATION_ACTIVATION_SUCCESSFUL });
            }).catch((error) => {
                dispatch({ type: MIGRATION_ACTIVATION_FAILED });
                console.log("migration activation failed");
                console.log(error);
        })
    }

};

export const setMigrationDate = (migrationDate) => {

    return(dispatch) => {
        dispatch({ type: SET_MIGRATION_DATE });
        const date = migrationDate.toDate();
        firebase.firestore().collection("system").doc("info")
            .update({ migrationDate: date })
            .then(() => {
                dispatch({ type: SET_MIGRATION_DATE_SUCCESSFULLY });
            }).catch((error) => {
            dispatch({ type: SET_MIGRATION_DATE_FAILED });
            console.log("migration date set failed");
            console.log(error);
        })
    }

};

export const penalInterestActivation = ({ penalInterest }) => {

    return(dispatch) => {
        dispatch({ type: PENAL_INTEREST_ACTIVATION });

        firebase.firestore().collection("system").doc("info")
            .update({ penalInterest })
            .then(() => {
                dispatch({ type: PENAL_INTEREST_ACTIVATION_SUCCESSFUL });
            }).catch((error) => {
            dispatch({ type: PENAL_INTEREST_ACTIVATION_FAILED });
            console.log("penal interest activation failed");
            console.log(error);
        })
    }

};

export const fetchExchangeRates = () => {
    return(dispatch) => {
        getExchangeRates({ dispatch })
    };
};

function getExchangeRates({ dispatch }) {
    dispatch({ type: FETCH_EXCHANGE_RATES });
    firebase.firestore().collection("exchangeRates").get()
        .then((snapshot) => {
            let exchangeRates = {};
            if (snapshot.size === 0) {
                //
            } else {
                snapshot.forEach(doc => {
                    exchangeRates[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_EXCHANGE_RATES_SUCCESSFUL,
                    payload: exchangeRates
                });
            }
        }, function (e) {
            console.log(e);
            dispatch({
                type: FETCH_EXCHANGE_RATES_FAILED,
            })
        })
}

export const deleteExchangeRate = ({ rate }) => {
    return(dispatch) => {
        dispatch({ type: DELETE_EXCHANGE_RATE });
        firebase.firestore().collection("exchangeRates").doc(rate.rateID).delete()
            .then(() => {
                dispatch({ type: DELETE_EXCHANGE_RATE_SUCCESSFUL });
                getExchangeRates({ dispatch });
            }).catch(e => {
                console.log(e);
                dispatch({ type: DELETE_EXCHANGE_RATE_FAILED });
        })
    }
};

//
export const changedIndustryValue = ({ prop, value }) => {
    return {
      type: CHANGED_INDUSTRY_DETAILS,
      payload: { prop, value },
    };
};

export const addIndustryValue = ({industryValue}) => {

    const industryID = Math.random().toString(36).substring(6).toUpperCase();

    return(dispatch) => {
        dispatch({ type: INDUSTRY_ADDING });

        firebase.firestore().collection("system").doc("additionalInfo")
        .get()
        .then(doc => {
            if(doc.exists){
                //
                addDataToIndustry({industryID, industryValue, dispatch });
            } else{
                firebase.firestore().collection("system").doc("additionalInfo")
                .set({})
                .then(() => {
                    //
                    addDataToIndustry({industryID, industryValue, dispatch });
                })
                .catch(e => {
                    console.log(e);
                })
            }
        })
    }
};

function addDataToIndustry({industryID, industryValue, dispatch }) {

    firebase.firestore().collection("system").doc("additionalInfo").collection("industry").doc(industryID)
        .set({ value: industryValue, industryID }, {merge: true})
        .then(() => {
            fetchedIndustryValue({dispatch});
            console.log("industry value saved successful");
            dispatch({ type: INDUSTRY_ADDING_SUCCESSFUL });
        })
        .catch((error) => {
            dispatch({ type: INDUSTRY_ADDING_FAILED });
            console.log(error);
            console.log("industry value not saved successful");

        })

}

export const changedCharacterValue = ({ prop, value }) => {
    return {
      type: CHARACTER_DETAILS_CHANGED,
      payload: { prop, value },
    };
};

export const addClientCharacter = ({characterValue}) => {

    const characterID = Math.random().toString(36).substring(6).toUpperCase();

    return(dispatch) => {
        dispatch({ type: SAVE_CLIENT_CHARACTER });

        firebase.firestore().collection("system").doc("additionalInfo")
            .get()
            .then(doc => {
                if(doc.exists){
                    addDataToClientCharacters({characterID, characterValue, dispatch});
                } else{
                    firebase.firestore().collection("system").doc("additionalInfo")
                        .set({})
                        .then(() => {
                            addDataToClientCharacters({characterID, characterValue, dispatch});
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
            })
    }

};

function addDataToClientCharacters({characterID, characterValue, dispatch}) {

    firebase.firestore().collection("system").doc("additionalInfo").collection("characters").doc(characterID)
        .set({ value: characterValue, characterID}, {merge: true})
        .then(() => {
            getCharacters({dispatch});
            console.log("client character value saved successful");
            dispatch({ type: SAVE_CLIENT_CHARACTER_SUCCESSFUL });
        })
        .catch((error) => {
        dispatch({ type: SAVE_CLIENT_CHARACTER_FAILED });
            console.log(error);
            console.log("client character value not saved successful");
        })
}

export const changedActionValue = ({ prop, value }) => {
    return {
        type: CLIENT_ACTION_DETAILS_CHANGED,
        payload: { prop, value }
    };
};

export const addClientAction = ({clientAction, character}) => {

    const actionID = Math.random().toString(36).substring(6).toUpperCase();

    return(dispatch) => {
        dispatch({ type: SAVE_CLIENT_ACTION });

        firebase.firestore().collection("system").doc("additionalInfo")
            .get()
            .then(doc => {
                if(doc.exists){
                    addDataToClientActions({actionID, clientAction, character, dispatch});
                } else{
                    firebase.firestore().collection("system").doc("additionalInfo")
                        .set({})
                        .then(() => {
                            addDataToClientActions({actionID, clientAction, character, dispatch});
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
            })
    }

};

function addDataToClientActions({actionID, clientAction, character, dispatch}) {

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions").doc(actionID)
        .set({ value: clientAction, character, actionID}, {merge: true})
        .then(() => {
            getClientActions({dispatch});
            console.log("client action value saved successful");
            dispatch({ type: SAVE_CLIENT_ACTION_SUCCESSFUL });
        })
        .catch((error) => {
            dispatch({ type: SAVE_CLIENT_ACTION_FAILED });
            console.log(error);
            console.log("client action value not saved successful");
        })
}

export const changedSegmentValue = ({ prop, value }) => {
    return {
        type: CHANGED_SEGMENT_DETAILS,
        payload: { prop, value },
    };
};

export const addSegmentValue = ({segmentValue}) => {

    const segmentID = Math.random().toString(36).substring(6).toUpperCase();


    return(dispatch) => {
        dispatch({ type: SEGMENT_ADDING });

        firebase.firestore().collection("system").doc("additionalInfo")
            .get()
            .then(doc => {
                if(doc.exists){
                    addDataToSegment({segmentID, segmentValue, dispatch});
                } else{
                    firebase.firestore().collection("system").doc("additionalInfo")
                        .set({})
                        .then(() => {
                            addDataToSegment({segmentID, segmentValue, dispatch});
                        })
                        .catch(e => {
                            console.log(e);
                        })

                }
            })

    }

};

function addDataToSegment({segmentID, segmentValue, dispatch}) {

    firebase.firestore().collection("system").doc("additionalInfo").collection("segment").doc(segmentID)
        .set({ value: segmentValue, segmentID}, {merge: true})
        .then(() => {
            fetchedSegmentValue({dispatch});
            console.log("segment value saved successful");
            dispatch({ type: SEGMENT_ADDING_SUCCESSFUL });
        })
        .catch((error) => {
            dispatch({ type: SEGMENT_ADDING_FAILED });
            console.log(error);
            console.log("segment value not saved successful");

        })

}


export const changedRepoStatusValue = ({ prop, value }) => {
    return {
      type: CHANGED_REPOSTATUS_DETAILS,
      payload: { prop, value },
    };
};

  export const addRepoStatusValue = ({repoStatusValue}) => {

    const statusID = Math.random().toString(36).substring(6).toUpperCase();


    return(dispatch) => {
        dispatch({ type: REPOSTATUS_ADDING });

        firebase.firestore().collection("system").doc("additionalInfo")
            .get()
            .then(doc => {
                if(doc.exists){
                        addDataToRepoStatus({statusID, repoStatusValue, dispatch});
                } else {
                    firebase.firestore().collection("system").doc("additionalInfo")
                    .set({})
                    .then(() => {
                        addDataToRepoStatus({statusID, repoStatusValue, dispatch});
                    })
                    .catch(e => {
                        console.log(e);
                    })
                }
            })
    }

};

function addDataToRepoStatus({statusID, repoStatusValue, dispatch}) {

    firebase.firestore().collection("system").doc("additionalInfo").collection("status").doc(statusID)
        .set({ value: repoStatusValue, statusID  }, {merge: true})
        .then(() => {
            fetchedRepoStatus({dispatch});
            console.log("segment value saved successful");
            dispatch({ type: REPOSTATUS_ADDING_SUCCESSFUL });
        }).catch((error) => {
        dispatch({ type: REPOSTATUS_ADDING_FAILED });
            console.log(error);
            console.log("segment value not saved successful");
        })
}


export const fetchIndustryTitles = () => {
       return(dispatch) => {

        fetchedIndustryValue({dispatch});
    }
}

function fetchedIndustryValue({dispatch}){
    let industryTitle = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("industry")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            
            industryTitle[doc.id] = doc.data();
        });

        dispatch({
            type: FETCH_INDUSTRY_TITLE_SUCCESSFUL,
            payload: industryTitle
        });


    }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_INDUSTRY_TITLE_FAILED,
            });
        })
}



export const fetchSegments = () => {

    return(dispatch) => {
        fetchedSegmentValue({dispatch});
    }
}

function fetchedSegmentValue({dispatch}) {
    let segment = {}

    firebase.firestore().collection("system").doc("additionalInfo").collection("segment")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            
            segment[doc.id] = doc.data();
        });

        dispatch({
            type: FETCH_SEGMENT_TITLE_SUCCESSFUL,
            payload: segment
        });


    }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_SEGMENT_TITLE_FAILED,
            });
    })
}

export const fetchCharacters = () => {

    return(dispatch) => {
        getCharacters({dispatch});
    }
}

function getCharacters({dispatch}) {
    let characters = {}

    firebase.firestore().collection("system").doc("additionalInfo").collection("characters")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {

                characters[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_CLIENT_CHARACTER_SUCCESSFUL,
                payload: characters
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_CLIENT_CHARACTER_FAILED,
            });
        })
}

export const fetchRepoStatus = () => {

    return(dispatch) => {
        fetchedRepoStatus({dispatch})
    }
}

function fetchedRepoStatus({dispatch}) {
    let repos = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("status")
    .onSnapshot(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            
            repos[doc.id] = doc.data();
        });

        dispatch({
            type: FETCH_REPOSTATUS_SUCCESSFUL,
            payload: repos
        });


    }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_REPOSTATUS_FAILED,
            });
        })
}

export const fetchEcecutives = () => {
    return(dispatch) => {
        let executives = {};
        dispatch({type: FETCH_EXECUTIVE});

        firebase.firestore().collection("externalCollectors")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                
                executives[doc.id] = doc.data();
            });
    
            dispatch({
                type: FETCH_EXECUTIVE_SUCCESSFUL,
                payload: executives
            });
    
        }, function (error) {
                console.log(error);
                dispatch({type: FETCH_EXECUTIVE_FAILED});
            })
    }
}

export const deleteTitleComplete = ({industryID}) => {
    return(dispatch) => {
        firebase.firestore().collection("system").doc("additionalInfo").collection("industry").doc(industryID)
        .delete()
        .then(() => {
            fetchedIndustryValue({dispatch});
            console.log("successful deleted");
        })
        .catch((error) => {
            console.log(error);
        })
    }
}

export const deleteSegmentComplete = ({segmentID}) => {
    return(dispatch) => {
         firebase.firestore().collection("system").doc("additionalInfo").collection("segment").doc(segmentID)
        .delete()
        .then(() => {
            fetchedSegmentValue({dispatch});
            console.log("successful deleted");
        })
        .catch((error) => {
            console.log(error);

        })
    }
}

export const deleteRepoStatus = ({statusID}) => {
    return(dispatch) => {
         firebase.firestore().collection("system").doc("additionalInfo").collection("status").doc(statusID)
        .delete()
        .then(() => {
            fetchedRepoStatus({dispatch});
            console.log("successful deleted");
        })
        .catch((error) => {
            console.log(error);
        })
    }
}

export const deleteClientCharacter = ({characterID}) => {
    return(dispatch) => {
        firebase.firestore().collection("system").doc("additionalInfo").collection("characters").doc(characterID)
            .delete()
            .then(() => {
                getCharacters({dispatch});
                console.log("successful deleted");
            })
            .catch((error) => {
                console.log(error);
            })
    }
}

export const deleteClientAction = ({actionID}) => {
    return(dispatch) => {
        firebase.firestore().collection("system").doc("additionalInfo").collection("actions").doc(actionID)
            .delete()
            .then(() => {
                getClientActions({dispatch});
                console.log("successful deleted");
            })
            .catch((error) => {
                console.log(error);
            })
    }
}


export const fetchActionsWA = () => {

    return(dispatch) => {
        getActionsWA({dispatch});
    }
}

function getActionsWA({dispatch}) {
    let repos = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions").where("character", "==", "WA")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                repos[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_ACTIONS_WA_SUCCESSFUL,
                payload: repos
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_ACTIONS_WA_FAILED,
            });
        })
}

export const fetchActionsWBNA = () => {

    return(dispatch) => {
        getActionsWBNA({dispatch});
    }
}

function getActionsWBNA({dispatch}) {
    let repos = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions").where("character", "==", "WBNA")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                repos[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_ACTIONS_WBNA_SUCCESSFUL,
                payload: repos
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_ACTIONS_WBNA_FAILED,
            });
        })
}


export const fetchActionsUU = () => {

    return(dispatch) => {
        getActionsUU({dispatch});
    }
}

function getActionsUU({dispatch}) {
    let repos = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions").where("character", "==", "UU")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                repos[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_ACTIONS_UU_SUCCESSFUL,
                payload: repos
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_ACTIONS_UU_FAILED,
            });
        })
}


export const fetchActionsUBA = () => {

    return(dispatch) => {
        getActionsUBA({dispatch});
    }
}

function getActionsUBA({dispatch}) {
    let repos = {};

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions").where("character", "==", "UBA")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                repos[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_ACTIONS_UBA_SUCCESSFUL,
                payload: repos
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_ACTIONS_UBA_FAILED,
            });
        })
}


export const fetchClientActions = () => {

    return(dispatch) => {
        getClientActions({dispatch});
    }
}

function getClientActions({dispatch}) {
    let actions = {}

    firebase.firestore().collection("system").doc("additionalInfo").collection("actions")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {

                actions[doc.id] = doc.data();
            });

            dispatch({
                type: FETCH_CLIENT_ACTIONS_SUCCESSFUL,
                payload: actions
            });


        }, function (error) {
            console.log(error);
            dispatch({
                type: FETCH_CLIENT_ACTIONS_FAILED,
            });
        })
}

