import * as firebase from 'firebase';
import moment from "moment";
import _ from "lodash";
import arraySort from "array-sort";

//types
import {
    BANK_DATE_CHANGED,
    CHEQUE_SWITCH_CHANGED,
    CUSTOMER_LOAN_DETAILS_CHANGED,
    DUE_DATE_CHANGED,
    FETCH_LOAN_TERMS,
    FETCH_LOAN_TERMS_FAILED,
    FETCH_LOAN_TERMS_SUCCESSFUL,
    FETCH_LOANS,
    FETCH_LOANS_FAILED,
    FETCH_LOANS_SUCCESSFUL,
    HIDE_EXTRA_FEE_CARD,
    HIDE_PENAL_INTEREST_CARD,
    HIDE_TOTAL_PAID_PREVIOUSLY_CARD,
    LOAN_TERM_CARD_DETAILS_CHANGED,
    LOAN_TERM_DETAILS_CHANGED,
    PUSH_CHEQUE_STATUS,
    PUSH_LOAN_TERM,
    PUSHED_CURRENT_LOAN,
    RECORD_CHEQUE_STATUS,
    SAVE_LOAN,
    SAVE_LOAN_FAILED,
    SAVE_LOAN_SUCCESSFUL,
    SAVE_LOAN_TERM,
    SAVE_LOAN_TERM__PENAL_STATUS,
    SAVE_LOAN_TERM__PENAL_STATUS_FAILED,
    SAVE_LOAN_TERM__PENAL_STATUS_SUCCESSFUL,
    SAVE_LOAN_TERM_FAILED,
    SAVE_LOAN_TERM_SUCCESSFUL,
    SHOW_EXTRA_FEE_CARD,
    SHOW_PENAL_INTEREST_CARD,
    SHOW_TOTAL_PAID_PREVIOUSLY_CARD,
    UPDATE_LOAN_TERM,
    UPDATE_LOAN_TERM_FAILED,
    UPDATE_LOAN_TERM_SUCCESSFUL,
    LEGAL_REPO_STATUS_CHANGED,
    SAVE_LEGAL_REPO_STATUS,
    SAVE_LEGAL_REPO_STATUS_SUCCESSFUL,
    SAVE_LEGAL_REPO_STATUS_FAILED,
    CLEAR_LEGAL_REPO_STATUS,
    CLEAR_LEGAL_REPO_STATUS_SUCCESSFUL,
    CLEAR_LEGAL_REPO_STATUS_FAILED,
    DELETE_LOAN_SUCCESSFUL,
    DELETE_LOAN_FAILED,
    DELETE_LOAN,
    PENAL_INTEREST_CHANGED,
    PREVIOUS_OVERDUE_CHANGED,
    EXTRA_FEE_CHANGED,
    SHOW_EARLY_LIQUIDATION_CARD,
    HIDE_EARLY_LIQUIDATION_CARD,
    EARLY_LIQUIDATION_CHANGED,
    CUSTOMER_PENAL_INTEREST_SAVED_SUCCESSFUL,
    EXTRA_FEE_SAVED_SUCCESSFUL,
    EXTRA_FEE_SAVED,
    LOAN_CURRENCY_CHANGED,
    CLEAR_UNCLEAR_LOAN_SUCCESSFUL,
    CLEAR_UNCLEAR_LOAN,
    CLEAR_UNCLEAR_LOAN_FAILED,
    EARLY_LIQUIDATION_SAVED_SUCCESSFUL,
    EARLY_LIQUIDATION_SAVE_FAILED,
    PREVIOUS_OVERDUE_SAVED_SUCCESSFUL,
    SHOW_LEGAL_REPO_STATUS_CARD,
    HIDE_LEGAL_REPO_STATUS_CARD,
    SAVE_RESCHEDULE_STATUS,
    SAVE_RESCHEDULE_STATUS_SUCCESSFUL,
    SAVE_RESCHEDULE_STATUS_FAILED,
    CLEAR_RESCHEDULE_STATUS,
    CLEAR_RESCHEDULE_STATUS_FAILED,
    CLEAR_RESCHEDULE_STATUS_SUCCESSFUL,
    SHOW_RESCHEDULE_LOAN_STATUS_CARD,
    HIDE_RESCHEDULE_LOAN_STATUS_CARD,
    RESCHEDULE_LOAN_STATUS_CHANGED,
    LOAN_ALREADY_EXISTS,
    LOAN_TERM_ALREADY_EXISTS,
    PUSHED_CURRENT_LOAN_SUCCESSFUL,
    CURRENT_LOAN_NOT_FOUND,
    PUSHED_CURRENT_LOAN_FAILED,
    CLEAR_LOAN_PAGE,
    SAVE_ADDITIONAL_INFO,
    SAVE_ADDITIONAL_INFO_FAILED,
    SAVE_ADDITIONAL_INFO_SUCCESSFUL,
    CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES,
    ADDITIONAL_INFO_CHANGED,
    CLEAR_PENAL_CHANGED,
    CLEAR_EXTRA_FEE_MESSAGE,
    CLEAR_EARLY_LIQUIDATION_MESSAGE,
    CLEAR_UNCLEAR_LOAN_MESSAGE,
    CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,
    CLEAR_PREVIOUS_OVERDUE_MESSAGE,
    CLEAR_PENAL_INTEREST_MESSAGE,
    CLEAR_DELETE_LOAN_MESSAGE,
    CLEAR_LEGAL_REPO_MESSAGE,
    CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE,
    CLEAR_LOAN_TERM_UPDATE_MESSAGE,
    CLEAR_SAVE_LOAN_TERM_MESSAGE,
    CLEAR_SAVE_LOAN_MESSAGE,
    DELETE_LOAN_TERM_SUCCESSFUL,
    CLEAR_DELETE_LOAN_TERM_MESSAGE,
    DELETE_LOAN_TERM_FAILED,
    LOAN_PAYMENT_METHOD_CHANGED,
    EXTRA_FEE_SAVED_FAILED,
    DELETE_CASH_COLLECTION,
    DELETE_CASH_COLLECTION_FAILED,
    DELETE_CASH_COLLECTION_SUCCESSFUL,
    FETCH_CASH_COLLECTIONS_SUCCESSFUL,
    FETCH_CASH_COLLECTIONS_FAILED,
    DELETE_EXTRA_FEE_SUCCESSFUL,
    DELETE_EXTRA_FEE_FAILED,
    ENABLE_UPDATE_TERM_BTN,
    COMPUTE_MODULO_AND_CURRENT_PAID,
    COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL,
    COMPUTE_MODULO_AND_CURRENT_PAID_FAILED,
    COMPUTE_INCORRECT_LOANS_SUCCESSFUL,
    COMPUTE_INCORRECT_LOANS_FAILED,
    FETCH_LOAN_LOGS, FETCH_LOAN_LOGS_SUCCESSFUL, FETCH_LOAN_LOGS_FAILED
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


export const customerLoanDetailsChanged = ({prop, value}) => {

    return{
        type: CUSTOMER_LOAN_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const loanTermDetailsChanged = ({prop, value}) => {

    return{
        type: LOAN_TERM_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const loanTermCardDetailsChanged = ({prop, value}) => {

    return{
        type: LOAN_TERM_CARD_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const dueDateChanged = (date) => {

    return{
        type: DUE_DATE_CHANGED,
        payload: date
    }
};

export const bankDateChanged = (date) => {

    return{
        type: BANK_DATE_CHANGED,
        payload: date
    }
};

export const chequeSwitchChanged = (status) => {
    return{
        type: CHEQUE_SWITCH_CHANGED,
        payload: status
    }
};

export const clearPenalChanged = (status) => {
    return{
        type: CLEAR_PENAL_CHANGED,
        payload: status
    }
};

export const recordChequeStatus = (value) => {
    return{
        type: RECORD_CHEQUE_STATUS,
        payload: value
    }
};

export const legalRepoStatusChange = (value) => {
    return{
        type: LEGAL_REPO_STATUS_CHANGED,
        payload: value
    }
};

export const rescheduleStatusChanged = (value) => {
    return{
        type: RESCHEDULE_LOAN_STATUS_CHANGED,
        payload: value
    }
};


export const pushLoanTerm = (loanTerm) => {
    return{
        type: PUSH_LOAN_TERM,
        payload: loanTerm
    }
};

export const showEarlyLiquidation = () => {
    return{
        type: SHOW_EARLY_LIQUIDATION_CARD
    }
};

export const hideEarlyLiquidation = () => {
    return{
        type: HIDE_EARLY_LIQUIDATION_CARD
    }
};

export const showTotalPaidPreviously = () => {
    return {
        type: SHOW_TOTAL_PAID_PREVIOUSLY_CARD,
    }
};

export const hideTotalPaidPreviously = () => {
    return {
        type: HIDE_TOTAL_PAID_PREVIOUSLY_CARD,
    }
};

export const showPenalInterest = () => {
    return {
        type: SHOW_PENAL_INTEREST_CARD,
    }
};

export const hidePenalInterest = () => {
    return {
        type: HIDE_PENAL_INTEREST_CARD,
    }
};

export const showExtraFee = () => {
    return {
        type: SHOW_EXTRA_FEE_CARD,
    }
};

export const hideExtraFee = () => {
    return {
        type: HIDE_EXTRA_FEE_CARD,
    }
};

export const loanCurrencyChanged = (status) => {

    return {
        type: LOAN_CURRENCY_CHANGED,
        payload: status
    }
};

export const loanChequeChanged = (status) => {

    return {
        type: LOAN_PAYMENT_METHOD_CHANGED,
        payload: status
    }
};


export const showLegalRepoStatus = () => {
    return{
        type: SHOW_LEGAL_REPO_STATUS_CARD,
    }
};

export const hideLegalRepoStatus = () => {
    return {
        type: HIDE_LEGAL_REPO_STATUS_CARD,
    }
};

export const showRescheduleStatus = () => {
    return{
        type: SHOW_RESCHEDULE_LOAN_STATUS_CARD,
    }
};

export const hideRescheduleStatus = () => {
    return {
        type: HIDE_RESCHEDULE_LOAN_STATUS_CARD,
    }
};

export const additionalInfoChanged = ({prop, value}) => {
    return {
        type: ADDITIONAL_INFO_CHANGED,
        payload: {prop, value}
    }
};


export const saveLoan = ({ customerID, customerName, loanID, currency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus }) => {

    return(dispatch) => {

        dispatch({ type: SAVE_LOAN });
        //check if loan exist
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .get()
            .then(doc => {
                if (doc.exists) {
                    dispatch({ type: LOAN_ALREADY_EXISTS });
                } else {
                    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                        .set({ customerID, customerName, loanID, currency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus })
                        .then(() => {
                            //
                            //save loan to loan bucket
                            saveLoanToBucket({ customerID, customerName, loanID, currency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus, dispatch })
                        })
                        .catch(error => {
                            dispatch({ type: SAVE_LOAN_FAILED });
                            console.log(error);
                            console.log("loan is not saved");
                            dispatch({ type: CLEAR_SAVE_LOAN_MESSAGE });
                        })
                }
            })
    }
};

function saveLoanToBucket({ customerID, customerName, loanID, currency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .set({ customerID, customerName, loanID, currency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus })
        .then(() => {
            dispatch({ type: SAVE_LOAN_SUCCESSFUL });
            console.log("loan is saved");
            dispatch({ type: CLEAR_SAVE_LOAN_MESSAGE });
            callFetchLoans({customerID, dispatch});
        })
        .catch(error => {
            dispatch({ type: SAVE_LOAN_FAILED });
            console.log(error);
            console.log("loan is not saved to bucket");
            dispatch({ type: CLEAR_SAVE_LOAN_MESSAGE });
        })
}

export const saveAdditionalInfoToLoan = ({customerID, loanID, salesExe, clientProfile, truck, industry, model, currentLoanTerms, segment, character, clientAction}) => {

    return (dispatch) => {

        dispatch({ type: SAVE_ADDITIONAL_INFO });

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ salesExe, clientProfile, truck, industry, model, segment, character, clientAction })
            .then(() => {

                dispatch({ type: SAVE_ADDITIONAL_INFO_SUCCESSFUL });
                console.log("additional info saved to bucket");
                dispatch({type: CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES});

                console.log("saving additional info to loan is on progress");
                saveAdditionalInfoToLoanToBucket({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, loanID, dispatch});
                saveAdditionalInfoToLoanTerms({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, customerID, currentLoanTerms, dispatch});
                saveAdditionalInfoToLoanTermsBucket({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, customerID, currentLoanTerms, dispatch});

            })
            .catch(error => {
                console.log(error);
                dispatch({ type: SAVE_ADDITIONAL_INFO_FAILED });
                console.log("additional info are not saved");
                dispatch({type: CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES});
            })
    }
};

function saveAdditionalInfoToLoanToBucket({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, loanID, dispatch}) {
    firebase.firestore().collection("loans").doc(loanID)
        .update({ salesExe, clientProfile, truck, industry, model, segment, character, clientAction})
        .then(() => {

        })
        .catch(error => {
            console.log(error);
            dispatch({ type: SAVE_ADDITIONAL_INFO_FAILED });
            console.log("additional info are not saved");
            dispatch({type: CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES});
        })
}


function saveAdditionalInfoToLoanTerms({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, currentLoanTerms, customerID, dispatch}) {

    _.map(currentLoanTerms, term => {

        const loanTermID = term.loanTermID;
        //
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({salesExe, clientProfile, truck, industry, model, segment, character, clientAction})
            .then(() => {
                console.log("Additional info are saved to loan terms");
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: SAVE_ADDITIONAL_INFO_FAILED });
                console.log("additional info are not saved");
                dispatch({type: CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES});
            })
    });

}


function saveAdditionalInfoToLoanTermsBucket({salesExe, clientProfile, truck, industry, model, segment, character, clientAction, customerID, currentLoanTerms, dispatch}) {

    _.map(currentLoanTerms, term => {

        const loanTermID = term.loanTermID;
        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update({ salesExe, clientProfile, truck, industry, model, segment, character, clientAction})
            .then(() => {
                console.log("additional info are saved to loan terms bucket");
                callFetchLoans({customerID, dispatch});
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: SAVE_ADDITIONAL_INFO_FAILED });
                console.log("additional info are not saved to bucket");
                dispatch({type: CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES});
            })
    });

}

export const enableLoanTermBtn = () => {
    return {
        type: ENABLE_UPDATE_TERM_BTN
    }
};


export const saveLoanTerm = ({ currentLoan, customerID, customerName, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus }) => {

    //
    console.log(cheque);
    return(dispatch) => {

        dispatch({ type: SAVE_LOAN_TERM });
        //check that loan term does not exist
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .get()
            .then(doc => {
                if (doc.exists) {
                    //send reducer notifying that loan term already exist
                    dispatch({ type: LOAN_TERM_ALREADY_EXISTS });
                } else {
                    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
                        .set({ customerID, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus })
                        .then(() => {
                            // save loan term to term bucket
                            saveLoanTermToBucket({ customerID, customerName, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus, dispatch });
                            computeTotalLoanTermsAmount({currentLoan, customerID, loanID, amount, dispatch});
                        })
                        .catch(error => {
                            dispatch({ type: SAVE_LOAN_TERM_FAILED });
                            console.log(error);
                            console.log("loan term is not saved to terms");
                            dispatch({ type: CLEAR_SAVE_LOAN_TERM_MESSAGE });
                        })
                }
            })
            .catch(e => {
                console.log(e);
                console.log("could'nt check if loan term already exist");
            });

    };
};

function computeTotalLoanTermsAmount({currentLoan, customerID, loanID, amount, dispatch}) {
    //check if there is totalLoanTermsAmount on loan object
    let totalLoanTermsAmount;
    let tAmount = parseInt(amount);

    if ('totalLoanTermsAmount' in currentLoan) {
        //
        totalLoanTermsAmount = currentLoan.totalLoanTermsAmount + tAmount;
    } else {
        //
        totalLoanTermsAmount = tAmount;
    }

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
        .update({ totalLoanTermsAmount })
        .then(() => {

            console.log(" total loan terms amount is saved to client loan path");
            // save total loan terms amount to loan bucket

            firebase.firestore().collection("loans").doc(loanID)
                .update({ totalLoanTermsAmount })
                .then(() => {
                    //
                    console.log(" total loan terms amount is saved to loan");
                })
                .catch(error => {
                    //
                    console.log(error);
                    console.log(" total loan terms amount is not saved to loan");
                })

        })
        .catch(error => {
            console.log(error);
            console.log(" total loan terms amount is not saved to client loan path");
        })

}

function saveLoanTermToBucket({ customerID, customerName, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus, dispatch }) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .set({ customerID, customerName, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus })
        .then(() => {

            //re fetch loan terms
            //create loan terms obj
            let loanTerms = {};

            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
                .onSnapshot(function(querySnapshot) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        loanTerms[doc.id] = doc.data();
                    });

                    dispatch({
                        type: FETCH_LOAN_TERMS_SUCCESSFUL,
                        payload: loanTerms
                    })
                }, function (error) {
                    console.log("Error getting documents: ", error);
                    dispatch({ type: FETCH_LOAN_TERMS_FAILED });
                });
            dispatch({ type: SAVE_LOAN_TERM_SUCCESSFUL });
            console.log("loan term is saved");
            dispatch({ type: CLEAR_SAVE_LOAN_TERM_MESSAGE });
        })
        .catch(error => {
            dispatch({ type: SAVE_LOAN_TERM_FAILED });
            console.log(error);
            console.log("loan term is not saved to terms");
            dispatch({ type: CLEAR_SAVE_LOAN_TERM_MESSAGE });
        })
}

export const updateChequeLoanTerm = ({customerID, loanTerm, loanID, paidAmount, currency, loanTermID, chequeStatus, transactionDate, chequeName, chequeNumber, bankName, previousChequeStatus, bankDate, comment, clearPenal, bouncedReason, otherReason }) => {

    const lastEdit = new Date();

    let updates = {chequeStatus, transactionDate, chequeName, chequeNumber, bankName, comment, paidAmount, currency, lastEdit, bouncedReason, otherReason};
    if (chequeStatus !== "bounced") {
        updates.transactionDate = firebase.firestore.FieldValue.delete();
        updates.bouncedReason = firebase.firestore.FieldValue.delete();
        updates.otherReason = firebase.firestore.FieldValue.delete();
    }

    console.log('bouncedReason', updates);

    //{ chequeStatus, bankDate, comment, lastEdit }
    //check if cheque status is held so we can update chequeStatus and newDueDate
    if (chequeStatus === "held") {
        updates["oldDueDate"] = loanTerm.dueDate;
        updates["newDueDate"] = bankDate;
        updates["dueDate"] = bankDate;
    }

    if (chequeStatus === "bounced" || chequeStatus === "notDeposited") {
        updates["termStatus.status"] = false;
        updates["loanTermStatus"] = false;
        updates["termStatus.penalInterest"] = 0;
    }

    return(dispatch) => {

        dispatch({ type: UPDATE_LOAN_TERM });
        const logDate = new Date();
        const code = Math.random().toString(36).substring(6).toUpperCase();
        const ID = moment().unix();
        const logIDx = ID.toString();
        const logID = `${code}${logIDx}`;
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("logs").doc(logID)
            .set({ customerID, loanID, logID, triggerEventID: loanTermID, paidAmount, logDate, event: `updating cheque status from ${previousChequeStatus} to ${chequeStatus}` })
            .then(() => {
                firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
                    .update(updates)
                    .then(() => {

                        // save loan term to term bucket
                        updateChequeLoanTermToBucket({ customerID, loanID, paidAmount, currency, loanTermID, updates, chequeStatus, previousChequeStatus, lastEdit, dispatch, clearPenal });
                    })
                    .catch(error => {
                        dispatch({ type: UPDATE_LOAN_TERM_FAILED });
                        console.log(error);
                        console.log("loan term is not updated to terms");
                        dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});
                    })
            })
            .catch(e => {
                console.log(e);
                console.log("could not save log");
            });
    };
};

function updateChequeLoanTermToBucket({ customerID, loanID, paidAmount, loanTermID, updates, chequeStatus, previousChequeStatus, lastEdit, dispatch, clearPenal }) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update(updates)
        .then(() => {
            dispatch({ type: UPDATE_LOAN_TERM_SUCCESSFUL });
            console.log("loan term is updated");
            dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});

            if (chequeStatus === "cleared") {
                //store current paid into bucket
                storeCurrentPaid({loanID, loanTermID, customerID, paidAmount, dispatch});
                //compute cheque payments
                computeChequePayment({ loanID, paidAmount, customerID, lastEdit, clearPenal });
                //if clearPenal is false, clear penal interest in term
                if (clearPenal) {
                    let penalUpdate = {
                        ["termStatus.status"]: true,
                        ["termStatus.penalInterest"]: 0,
                    };

                    firebase.firestore().collection("loanTerms").doc(loanTermID)
                        .update(penalUpdate)
                        .then(()=> {
                            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
                                .update(penalUpdate)
                                .then(() => {
                                    ///
                                }).catch(e => console.log(e))
                        }).catch(e => console.log(e))
                }
            } else {
                if (previousChequeStatus === "cleared") {
                    //if yes, deduct the cleared amounts
                    deductChequePayment({ customerID, loanID, loanTermID, paidAmount, dispatch });
                }
            }
        })
        .catch(error => {
            dispatch({ type: UPDATE_LOAN_TERM_FAILED });
            console.log(error);
            console.log("loan term is not updated to terms");
            dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});
        })
}

function storeCurrentPaid({ loanID, loanTermID, customerID, paidAmount }) {
    // const code = Math.random().toString(36).substring(6).toUpperCase();
    // const currentPaidID = `${loanID}${code}`;
    const currentPaidID = loanTermID;

    //save current paid  to bucket
    firebase.firestore().collection("currentPaidBucket").doc(loanTermID)
        .set({ loanID, customerID, loanTermID, paidAmount, currentPaidID })
        .then(() => {
            console.log("current paid is saved to bucket");
            //update current paid amount on loan bucket and user loan path

        }).catch((error) => {
        console.log("error in saving current paid to loan bucket");
        console.log(error);
    });
}

function computeChequePayment({ customerID, loanID, paidAmount, lastEdit }) {
    firebase.firestore().collection("loans").doc(loanID).get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                const oldTotalChequePaid = data.totalChequePaid;
                //calculate total paid
                const oldTotalPaid = data.totalPaid;

                //add paid amount to old total cheque paid
                const totalChequePaid = paidAmount + oldTotalChequePaid;
                //add paid amount to old total paid
                const totalPaid = paidAmount + oldTotalPaid;

                //check if loan has been cleared or not
                let loanStatus = false;
                const totalAmount = data.totalAmount;
                //find if there are extra fees and their total
                let totalArray = [];
                if ("extraFees" in data) {
                    //there are extra fees, loop over each extra fee and put amount into amountArray
                    _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
                }
                let totalFeesAmount = 0;
                //calculate the total amount from numbers in the array
                //check that total array is not empty
                if (totalArray.length > 0) {
                    totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
                }
                let debt = totalAmount + totalFeesAmount;
                if ("earlyLiquidation" in data) {
                    debt = data.earlyLiquidation;
                }
                const clearedAmount = debt - totalPaid;
                //if clearedAmount is below zero then debt is cleared
                if (clearedAmount <= 0) {
                    loanStatus = true;
                }

                //assign loanStatus true to all terms in this loan
                assignClearedLoanStatus({ customerID, loanID, loanStatus });

                // register currentPaid
                let oldCurrentPaid = 0;
                if ("currentPaid" in data) {
                    oldCurrentPaid = data.currentPaid;
                }
                const currentPaid = paidAmount + oldCurrentPaid;

                //post to loan profile on loan bucket and customer profile
                firebase.firestore().collection("loans").doc(loanID).update({ totalChequePaid, totalPaid, currentPaid, loanStatus, lastPayment: { paidAmount, time: lastEdit, cheque: true  } })
                    .then(() => {
                        console.log("calculations saved to bucket");
                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                            .update({ totalChequePaid, totalPaid, currentPaid, loanStatus, lastPayment: { paidAmount, time: lastEdit, cheque: true  } })
                            .then(() => {
                                console.log("calculations saved to loan in profile");
                            }).catch((error) => {
                                console.log("error in saving calculations to loan in profile");
                                console.log(error);
                        });
                    }).catch((error) => {
                    console.log("error in saving calculations to loan in bucket");
                    console.log(error);
                });

            }
        }).catch(e => e);
}

function assignClearedLoanStatus({ customerID, loanID, loanStatus }) {
    //fetch loan terms ids
    //create loan terms obj
    // let loanTerms = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
        .get()
        .then((querySnapshot) => {

            if (querySnapshot.size !== 0) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    const term = doc.data();
                    updateLoanStatusOnLoanTerm({customerID, loanStatus, term});
                });
            }

        })
        .catch(e => console.log(e));

    // //loop over all loan terms
    // _.map(loanTerms, term => {
    //     console.log({term: term.loanTermID, customerID: customerID})
    //     firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
    //         .update({ loanStatus })
    //         .then(() => {
    //             firebase.firestore().collection("loanTerms").doc(term.loanTermID)
    //                 .update({ loanStatus })
    //                 .then(() => {
    //                     //
    //                 })
    //                 .catch(e => {
    //                     console.log(e);
    //                 })
    //         }).catch(e => console.log(e))
    // })
}

function updateLoanStatusOnLoanTerm({customerID, loanStatus, term}) {

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
        .update({ loanStatus })
        .then(() => {
            firebase.firestore().collection("loanTerms").doc(term.loanTermID)
                .update({ loanStatus })
                .then(() => {
                    //
                })
                .catch(e => {
                    console.log(e);
                })
        }).catch(e => console.log(e))
}


function deductChequePayment({ customerID, loanID, loanTermID, paidAmount, dispatch }) {
    console.log("deduct payment called");
    firebase.firestore().collection("loans").doc(loanID).get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data();

                const oldTotalChequePaid = data.totalChequePaid;
                //calculate total paid
                const oldTotalPaid = data.totalPaid;

                //deduct paid amount to old total cheque paid
                const totalChequePaid = oldTotalChequePaid - paidAmount;
                const totalPaid = oldTotalPaid - paidAmount;

                //check if loan has been cleared or not
                let loanStatus = false;
                const totalAmount = data.totalAmount;
                //find if there are extra fees and their total
                let totalArray = [];
                if ("extraFees" in data) {
                    //there are extra fees, loop over each extra fee and put amount into amountArray
                    _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
                }
                let totalFeesAmount = 0;
                //calculate the total amount from numbers in the array
                //check that total array is not empty
                if (totalArray.length > 0) {
                    totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
                }
                let debt = totalAmount + totalFeesAmount;
                if ("earlyLiquidation" in data) {
                    debt = data.earlyLiquidation;
                }
                const clearedAmount = debt - totalPaid;
                //if clearedAmount is below zero then debt is cleared
                if (clearedAmount <= 0) {
                    loanStatus = true;
                }

                let update = { totalChequePaid, totalPaid, loanStatus };

                if ("currentPaid" in data) {
                    const currentPaid = data.currentPaid;
                    //write to database
                    console.log("we have current paid");
                    console.log({ currentPaid: data.currentPaid });
                    fetchLoanTermCurrentPaid({ customerID, loanID, loanTermID, currentPaid, amount: paidAmount });
                }

                if ("modulo" in data) {
                    //write to database
                    console.log("we have a modulo");
                    computeModuloAndCurrentPaid({ customerID, loanID, loanTermID, paidAmount, dispatch });
                }

                if ("surplus" in data){
                    console.log("we have a surplus");
                    //check if surplus is greater to 0
                    if (data.surplus > 0){
                        //compute new surplus
                        console.log("surplus is greater to zero");
                        let oldSurplus = 0;
                        if ("currentPaid" in data){
                            const currentPaid = data.currentPaid;
                            oldSurplus = data.surplus + currentPaid;

                            //delete current paid
                            firebase.firestore().collection("loans").doc(loanID)
                                .update({currentPaid: firebase.firestore.FieldValue.delete()})
                                .then(() => {
                                    console.log("new surplus saved to loan bucket");
                                    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                                        .update({currentPaid: firebase.firestore.FieldValue.delete()})
                                        .then(() => {

                                        }).catch((error) => {
                                        console.log("error in saving new surplus to loan in profile");
                                        console.log(error);
                                    });
                                }).catch((error) => {
                                console.log("error in saving new surplus to loan bucket");
                                console.log(error);
                            });
                        } else {
                            oldSurplus = data.surplus;
                        }

                        const newSurplus = oldSurplus - paidAmount;


                        //check if new surplus is greater to zero
                        if (newSurplus >= 0){
                            //update surplus on loan
                            firebase.firestore().collection("loans").doc(loanID)
                                .set({surplus: newSurplus}, {merge: true})
                                .then(() => {
                                    console.log("new surplus saved to loan bucket");
                                    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                                        .set({surplus: newSurplus}, {merge: true})
                                        .then(() => {
                                            console.log("new surplus saved to loan in profile");
                                            //check loan term status
                                            checkLoanTermStatus({ customerID, loanID, dispatch });
                                        }).catch((error) => {
                                        console.log("error in saving new surplus to loan in profile");
                                        console.log(error);
                                    });
                                }).catch((error) => {
                                console.log("error in saving new surplus to loan bucket");
                                console.log(error);
                            });
                        } else {
                            //delete surplus on loan and set modulo
                            if ("modulo" in data){
                                //
                                firebase.firestore().collection("loans").doc(loanID)
                                    .update({surplus: firebase.firestore.FieldValue.delete(), modulo: firebase.firestore.FieldValue.increment(oldSurplus)})
                                    .then(() => {
                                        console.log("new surplus deleted to loan bucket");
                                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                                            .update({surplus: firebase.firestore.FieldValue.delete(), modulo: firebase.firestore.FieldValue.increment(oldSurplus)})
                                            .then(() => {
                                                console.log("new surplus deleted to loan in profile");
                                                //check loan term status
                                                checkLoanTermStatus({ customerID, loanID, dispatch });
                                            }).catch((error) => {
                                            console.log("error in deleting new surplus to loan in profile");
                                            console.log(error);
                                        });
                                    }).catch((error) => {
                                    console.log("error in deleting new surplus to loan bucket");
                                    console.log(error);
                                });
                            } else {
                                //
                                firebase.firestore().collection("loans").doc(loanID)
                                    .update({surplus: firebase.firestore.FieldValue.delete(), modulo: oldSurplus })
                                    .then(() => {
                                        console.log("new surplus deleted to loan bucket");
                                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                                            .update({surplus: firebase.firestore.FieldValue.delete(), modulo: oldSurplus })
                                            .then(() => {
                                                console.log("new surplus deleted to loan in profile");
                                                //check loan term status
                                                checkLoanTermStatus({ customerID, loanID, dispatch });
                                            }).catch((error) => {
                                            console.log("error in deleting new surplus to loan in profile");
                                            console.log(error);
                                        });
                                    }).catch((error) => {
                                    console.log("error in deleting new surplus to loan bucket");
                                    console.log(error);
                                });
                            }
                        }

                    } else {
                        //check loan term status
                        checkLoanTermStatus({ customerID, loanID, dispatch });
                    }
                }

                //post to loan profile on loan bucket and customer profile
                firebase.firestore().collection("loans").doc(loanID).update(update)
                    .then(() => {
                        console.log("cheque deduction calculations saved to bucket");
                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                            .update(update)
                            .then(() => {
                                console.log("cheque deduction calculations saved to loan in profile");
                                assignClearedLoanStatus({ customerID, loanID, loanStatus });
                            }).catch((error) => {
                                console.log("error in saving cheque deduction calculations to loan in profile");
                                console.log(error);
                        });
                    }).catch((error) => {
                    console.log("error in saving cheque deduction calculations to loan in bucket");
                    console.log(error);
                });

            }
        }).catch(e => e);
}

function fetchLoanTermCurrentPaid({ customerID, loanID, loanTermID, currentPaid, amount }) {
    console.log("fetching current paid in current paid bucket")
    console.log({ customerID, loanID, loanTermID, currentPaid });
    firebase.firestore().collection("currentPaidBucket").doc(loanTermID).get()
        .then(doc => {
            if (doc.exists) {
                const currentPaidTerm = doc.data();

                //initialize data
                const paidAmount = currentPaidTerm.paidAmount;
                const currentPaidID = currentPaidTerm.currentPaidID;

                //check if loan current paid is bigger or equal to loan term current paid
                if (currentPaid >= paidAmount) {
                    const pay = currentPaid - paidAmount;

                    //check that current paid is not below 0
                    if (pay >= 0) {
                        //write to database
                        updateCurrentPaid({ customerID, loanID, pay, currentPaidID})
                    } else {
                        //dont include in update
                    }
                }

            } else {
                firebase.firestore().collection("currentPaidBucket").where("loanTermID", "==", loanTermID).get()
                    .then(snapshot => {
                        if (snapshot.size === 0) {
                           //is not from loan term

                        } else {
                            let currentPaidTerm = {};
                            snapshot.forEach(doc => {
                                currentPaidTerm = doc.data();
                            });

                            //initialize data
                            const paidAmount = currentPaidTerm.paidAmount;
                            const currentPaidID = currentPaidTerm.currentPaidID;

                            //check if loan current paid is bigger or equal to loan term current paid
                            if (currentPaid >= paidAmount) {
                                const pay = currentPaid - paidAmount;

                                //check that current paid is not 0
                                if (pay >= 0) {
                                    //write to database
                                    updateCurrentPaid({ customerID, loanID, pay, currentPaidID})
                                } else {
                                    //dont include in update
                                }
                            }
                        }
                    })
            }
        }).catch(e => {
            console.log("Error getting documents: ", e);
    })
}

function updateCurrentPaid({customerID, loanID, pay, currentPaidID}) {
    //
    //post to loan profile on loan bucket and customer profile
    let update = {};

    console.log("remaining current paid");
    console.log({ pay });
    if (pay === 0) {
        update["currentPaid"] = firebase.firestore.FieldValue.delete()
    } else {
        update["currentPaid"] = pay;
    }
    firebase.firestore().collection("loans").doc(loanID)
        .update(update)
        .then(() => {
            console.log("cheque current paid saved to bucket");

            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                .update(update)
                .then(() => {
                    console.log("current paid saved to loan in profile");
                    deleteLoanTermCurrentPaid({currentPaidID});

                }).catch((error) => {
                console.log("error in saving update current paid to loan in profile");
                console.log(error);
            });

        }).catch((error) => {
        console.log("error in update current paid to loan in bucket");
        console.log(error);
    });
}

function deleteLoanTermCurrentPaid({currentPaidID}) {
    //
    firebase.firestore().collection("currentPaidBucket").doc(currentPaidID)
        .delete()
        .then(() => {
            //
            console.log("current paid delete from current paid bucket");
        })
        .catch(e => {
            console.log(e);
        })
}

export const updateCashLoanTerm = ({ customerID, loanTermID, dueDate, comment }) => {

    const lastEdit = new Date();

    return(dispatch) => {

        dispatch({ type: UPDATE_LOAN_TERM });
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({ dueDate, comment, lastEdit })
            .then(() => {
                // save loan term to term bucket
                updateCashLoanTermToBucket({ loanTermID, dueDate, comment, lastEdit, dispatch });
            })
            .catch(error => {
                dispatch({ type: UPDATE_LOAN_TERM_FAILED });
                console.log(error);
                console.log("loan term is not updated to terms");
                dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});
            })

    };

};

function updateCashLoanTermToBucket({ loanTermID, dueDate, comment, lastEdit, dispatch }) {
    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ dueDate, comment, lastEdit })
        .then(() => {
            dispatch({ type: UPDATE_LOAN_TERM_SUCCESSFUL });
            console.log("loan term is updated");
            //clear successful notification
            dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});
        })
        .catch(error => {
            dispatch({ type: UPDATE_LOAN_TERM_FAILED });
            console.log(error);
            console.log("loan term is not updated to terms");
            dispatch({type: CLEAR_LOAN_TERM_UPDATE_MESSAGE});
        })
}


export const fetchLoans = ({ customerID }) => {

    return(dispatch) => {

        dispatch({ type: FETCH_LOANS });
        callFetchLoans({customerID, dispatch})
    }
};

async function callFetchLoans({customerID, dispatch}) {

    //create loans obj
    let loans = {};
    let successArray = [];

    try {
        const loansRef = firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans")
        const snapshot = await loansRef.get();
    
        if(snapshot.size !== 0){
            snapshot.forEach(doc => {
                loans[doc.id] = doc.data();
            })
        }

        if (!(_.isEmpty(loans))) {
            _.map(loans, loan => {
                const res = getLoanStartingAndEndingDate({ loans, loan, dispatch, customerID });
                successArray.push(res);
            })
        }else{
            dispatch({
                type: FETCH_LOANS_SUCCESSFUL,
                payload: loans
            })
        }

    } catch(e){
            console.log(e);
            dispatch({
                type: FETCH_LOANS_FAILED
            });
    }

    Promise.all(successArray).then((value) => {
            dispatch({
                type: FETCH_LOANS_SUCCESSFUL,
                payload: value
            })
    })

}

async function getLoanStartingAndEndingDate({ loans, dispatch, loan, customerID }){

    let terms = [];
    let startingDate;
    let endDate;
    let emi = 0;

    try {
        const termsRef = firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loan.loanID)
        const snapshot = await termsRef.get();
        if(snapshot.size !== 0){
            snapshot.forEach(doc => {
                const data = doc.data();
                terms.push(data);
            })
        }else{
            console.log("no loan terms");
        }
    
        terms.map((loanTerm, index) => {
    
            if(index === 0){
                let seconds;
                loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;
    
                startingDate = moment.unix(seconds).format("DD/MM/YYYY");
                emi = loanTerm.amount;
            }
    
            if(index === terms.length - 1){
                let seconds;
                loanTerm.dueDate.seconds ? seconds = loanTerm.dueDate.seconds : seconds = loanTerm.dueDate._seconds;
    
                endDate = moment.unix(seconds).format("DD/MM/YYYY");
            }

        })

    } catch(e){
        console.log(e);
        dispatch({
            type: FETCH_LOANS_FAILED
        });
    }

    return { ...loan, startingDate, endDate, emi };
}

// export const pushCurrentLoan = (loan) => {
//     return{
//         type: PUSHED_CURRENT_LOAN,
//         payload: loan
//     }
// };

export const pushChequeStatus = (cheque) => {

    console.log(cheque);
    return {
        type: PUSH_CHEQUE_STATUS,
        payload: cheque
    }
};


export const fetchLoanTerms = ({ customerID, loanID }) => {

    return(dispatch) => {

        dispatch({ type: FETCH_LOAN_TERMS });

        //create loan terms obj
        let loanTerms = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    loanTerms[doc.id] = doc.data();
                });

                //calculatePenalInterest({ currentLoanTerms: loanTerms, customerID, dispatch });

                dispatch({
                    type: FETCH_LOAN_TERMS_SUCCESSFUL,
                    payload: loanTerms
                })
            }, function (error) {
                console.log("Error getting documents: ", error);
                dispatch({ type: FETCH_LOAN_TERMS_FAILED });
            })

    }
};

export const fetchLoanLogs = ({ customerID, loanID }) => {

    return(dispatch) => {

        dispatch({ type: FETCH_LOAN_LOGS });

        //create loan terms obj
        let loanLogs = {};

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("logs").where("loanID", "==", loanID).limit(30)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    loanLogs[doc.id] = doc.data();
                });

                //calculatePenalInterest({ currentLoanTerms: loanTerms, customerID, dispatch });

                console.log("loan logs fetched");
                dispatch({
                    type: FETCH_LOAN_LOGS_SUCCESSFUL,
                    payload: loanLogs
                })
            }, function (error) {
                console.log("Error getting documents: ", error);
                dispatch({ type: FETCH_LOAN_LOGS_FAILED });
            })

    }
};

export const computeModuloAndCurrentPaid = ({ customerID, loanID, loanTermID, paidAmount, dispatch }) => {

    dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID });

    const data = JSON.stringify({
        customerID,
        loanID,
        paidAmount,
        currentPaidID: loanTermID
    });

    const url = `${project.serverUrl}computeModuloAndCurrentPaid`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL });
            console.log("compute_modulo_and_current_paid_successful");
            console.log(response);
            checkLoanTermStatus({ customerID, loanID, dispatch });

        }).catch((error) => {
        checkLoanTermStatus({ customerID, loanID, dispatch });
        dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_FAILED });
        console.log("compute_modulo_and_current_paid_successful");
        console.log(error);
    })
};

function checkLoanTermStatus({ customerID, loanID, dispatch }) {

    dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID });

    const data = JSON.stringify({
        customerID,
        loanID,
    });

    const url = `${project.serverUrl}fixLoanTermStatus`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL });
            console.log("compute_modulo_and_current_paid_successful");
            console.log(response);

        }).catch((error) => {
        dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_FAILED });
        console.log("compute_modulo_and_current_paid_successful");
        console.log(error);
    })
}

function calculatePenalInterest({ currentLoanTerms, customerID, dispatch }) {
    console.log("penal interest called");

    //only take terms un met due dates
    const loanTermsValue = [];
    let terms = [];

    //check if both currentLoanTerms is not empty
    if (!(_.isEmpty(currentLoanTerms))) {

        _.map(currentLoanTerms, term => {
            let seconds;

            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const dueDate = moment.unix(seconds);
            const today = moment();

            if (dueDate.isBefore(today)) {
                //grab term
                //check if termStatus is initialized

                if ("termStatus" in term) {
                    //ignore
                    if (term.termStatus.status) {
                        //
                    } else {
                        terms.push(term);
                    }
                } else {
                    terms.push(term);
                }

                loanTermsValue.push(term.amount);
            }
        });


        if (terms.length !== 0) {

            const momentTerms = terms.map(term => {
                let seconds;
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                return moment.unix(seconds);
            });
            //find the earliest date (moment objects)
            const earliestDate = _.min(momentTerms);
            const today   = moment();

            ///find difference in days from earliest term to today
            const overdueDays = today.diff(earliestDate, 'days');

            const bucket = Math.ceil(overdueDays/30);
            //old method
            //get number of unpaid months (round to whole number)
            //const bucket = Math.ceil(overDue/monthlyInstallment);


            //clear paid terms
            if (bucket === 0) {
                //clear all terms
                saveLoanTermPenalStatus({ customerID, loanTerms: terms, status: true, dispatch });
            } else {

                //bucket can't be negative unless supposed to pay wasn't calculated
                if (bucket >= 0) {
                    //find the number of cleared terms
                    //clear earliest terms

                    const numberOfClearedTerms = terms.length - bucket;

                    if (numberOfClearedTerms === 0) {
                        saveLoanTermPenalStatus({ customerID, loanTerms: terms, status: false, dispatch });
                    } else {
                        // earliest terms
                        let sortedTerms = arraySort(terms, "dueDate");

                        if (sortedTerms.length === terms.length) {

                            const clearedTerms = sortedTerms.slice(0, numberOfClearedTerms);

                            //cleared terms
                            saveLoanTermPenalStatus({ customerID, loanTerms: clearedTerms, status: true, dispatch });

                            //uncleared terms
                            for (let i=0; i < clearedTerms.length; i++) {
                                sortedTerms.shift();
                            }

                            saveLoanTermPenalStatus({ customerID, loanTerms: sortedTerms, status: false, dispatch });
                        }
                    }
                }
            }
        }
    }
}


// export const updateLoanTerm = ({ customerID, loanTermID, paidAmount, cheque, chequeName, chequeNumber, bankName, paidDate, loanTermStatus, comment, chequeStatus }) => {
//
//     //
//     const bankDate = paidDate.toDate();
//     const lastEdit = new Date();
//
//     return(dispatch) => {
//
//         dispatch({ type: UPDATE_LOAN_TERM });
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
//             .update({ paidAmount, cheque, chequeName, chequeNumber, chequeStatus, bankName, bankDate, loanTermStatus, comment, lastEdit })
//             .then(() => {
//                 // save loan term to term bucket
//                 updateLoanTermToBucket({ paidAmount, cheque, chequeName, chequeNumber, chequeStatus, bankName, bankDate, loanTermID, loanTermStatus, comment, lastEdit, dispatch });
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.log("loan term is not updated to customer profile");
//                 dispatch({ type: UPDATE_LOAN_TERM_FAILED });
//             })
//
//     };
// };
//
// function updateLoanTermToBucket({ paidAmount, cheque, chequeName, chequeNumber, chequeStatus, bankName, bankDate, loanTermID, loanTermStatus, comment, lastEdit, dispatch }) {
//
//     firebase.firestore().collection("loanTerms").doc(loanTermID)
//         .update({ paidAmount, cheque, chequeName, chequeNumber, chequeStatus, bankName, bankDate, loanTermStatus, comment, lastEdit })
//         .then(() => {
//             console.log("loan term is updated");
//             dispatch({ type: UPDATE_LOAN_TERM_SUCCESSFUL });
//         })
//         .catch(error => {
//             console.log(error);
//             console.log("loan term is not updated to terms");
//             dispatch({ type: UPDATE_LOAN_TERM_FAILED });
//         })
// }

function saveLoanTermPenalStatus({ customerID, loanTerms, status, dispatch }) {
    //loan terms is an array of monthly term objects
    //check if loanTerms is array or not
    const arrayStatus = Array.isArray(loanTerms);

    if (arrayStatus) {
        //its an array of terms
        loanTerms.forEach(term => {
            //for every individual term
            saveInterestTerm({ term, customerID, status, dispatch })
        })
    } else {
        //its an individual term
        saveInterestTerm({ term: loanTerms, customerID, status, dispatch })
    }

}

function saveInterestTerm({ term, customerID, status, dispatch }) {
    //check term if true
    if (term) {
        const loanTermID = term.loanTermID;

        const paidDate = new Date();
        const amount = term.amount;
        const interestPerMonth = amount * 0.02;
        const interestPerDay = interestPerMonth/30;

        //find term due date
        let seconds;
        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;

        const dueDate = moment.unix(seconds);

        //find difference in days
        const now = moment();
        const numberOfDays = now.diff(dueDate, 'days');

        const penalInterest = numberOfDays * interestPerDay;

        dispatch({ type: SAVE_LOAN_TERM__PENAL_STATUS });
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({ termStatus: { status, paidDate, penalInterest }})
            .then(() => {
                console.log("penal interest saved to profile");
                saveLoanTermPenalStatusToBucket({ status, loanTermID, paidDate, penalInterest, dispatch })
            })
            .catch(error => {
                dispatch({ type: SAVE_LOAN_TERM__PENAL_STATUS_FAILED });
                console.log("error occurred saving penal status to bucket");
                console.log(error);
                dispatch({ type: CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE });
            })
    }
}

function saveLoanTermPenalStatusToBucket({ status, loanTermID, paidDate, penalInterest, dispatch }) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ termStatus: { status, paidDate, penalInterest }})
        .then(() => {
            dispatch({ type: SAVE_LOAN_TERM__PENAL_STATUS_SUCCESSFUL });
            console.log("penal interest saved to bucket");
            dispatch({ type: CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE });
        })
        .catch(error => {
            dispatch({ type: SAVE_LOAN_TERM__PENAL_STATUS_FAILED });
            console.log("error occurred saving penal status to bucket");
            console.log(error);
            dispatch({ type: CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE });
        })
}


export const fetchLoan = ({ customerID, loanID }) => {
    return(dispatch) => {

        dispatch({type: PUSHED_CURRENT_LOAN});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: PUSHED_CURRENT_LOAN_SUCCESSFUL,
                        payload: data
                    });
                }
                else {
                    console.log("current loan doc doesn't exists");
                    dispatch({ type: CURRENT_LOAN_NOT_FOUND });
                }
            }, function(error) {
                //...
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
                console.log(errorCode);
                console.log(errorCode);
                console.log(errorMessage);
                //...
                dispatch({
                    type: PUSHED_CURRENT_LOAN_FAILED,
                });
            })
    }
};


export const saveLegalRepoStatus = ({legalRepoStatus, customerID, loanID, loanTermsIDs}) => {

    return (dispatch) => {

        dispatch({ type: SAVE_LEGAL_REPO_STATUS });

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
        .update({legalRepoStatus})
        .then(() => {

            console.log("Legal Repo status is saved successful into customer path");
            saveLegalRepoStatusToLoansBucket({legalRepoStatus, loanID, dispatch});
            saveLegalRepoStatusToLoanTerms({legalRepoStatus, customerID, loanTermsIDs, dispatch});
            saveLegalRepoStatusToLoanTermsBucket({legalRepoStatus, loanTermsIDs, dispatch});

        })
        .catch(error => {
            dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
            console.log(error);
            console.log("Legal Repo status is not saved into customer path");
            dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
        })
    }
};

function saveLegalRepoStatusToLoansBucket({legalRepoStatus, loanID, dispatch}) {
    firebase.firestore().collection("loans").doc(loanID)
        .update({ legalRepoStatus})
        .then(() => {
            dispatch({ type: SAVE_LEGAL_REPO_STATUS_SUCCESSFUL,});
            console.log("legal repo status saved to bucket");
            dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
        })
        .catch(error => {
            dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
            console.log("error saving legal repo status to loan terms bucket");
            console.log(error);
            dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
        })
}


function saveLegalRepoStatusToLoanTerms({legalRepoStatus, loanTermsIDs, customerID, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({legalRepoStatus})
            .then(() => {
                console.log("legal repo status saved to loan terms");
            })
            .catch(error => {
                dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
                console.log("error saving legal repo status to loan terms bucket");
                console.log(error);
                dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
            })
    });

}


function saveLegalRepoStatusToLoanTermsBucket({legalRepoStatus, loanTermsIDs, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update({ legalRepoStatus})
            .then(() => {
                console.log("legal repo status saved to loan terms bucket");

            })
            .catch(error => {
                dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
                console.log("error saving legal repo status to loan terms bucket");
                console.log(error);
                dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
            })
    });

}


export const clearLegalRepoStatus = ({legalRepoStatus, customerID, loanID, loanTermsIDs }) => {

    return (dispatch) => {

        dispatch({ type: CLEAR_LEGAL_REPO_STATUS});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
        .update({ legalRepoStatus: firebase.firestore.FieldValue.delete()})
        .then(() => {

            console.log("Legal Repo status is deleted into customer path");

            //delete legal repo status value from bucket
            clearLegalRepoStatusToLoansBucket({legalRepoStatus, loanID, dispatch});
            clearLegalRepoStatusToLoanTerms({legalRepoStatus, loanTermsIDs, customerID, dispatch});
            clearLegalRepoStatusToLoanTermsBucket({legalRepoStatus, loanTermsIDs, dispatch});
        })
        .catch(error => {
            dispatch({ type: CLEAR_LEGAL_REPO_STATUS_FAILED });
            console.log("error clearing legal repo status to loan terms bucket");
            console.log(error);
            dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
        })
    }
};

function clearLegalRepoStatusToLoansBucket({ loanID, dispatch}) {
    
    firebase.firestore().collection("loans").doc(loanID)
    .update({ legalRepoStatus: firebase.firestore.FieldValue.delete()})
    .then(() => {
        dispatch({ type: CLEAR_LEGAL_REPO_STATUS_SUCCESSFUL });
        console.log("legal repo status cleared from bucket");
        dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
    })
    .catch(error => {
        dispatch({ type: CLEAR_LEGAL_REPO_STATUS_FAILED });
        console.log("error clearing legal repo status to loan terms bucket");
        console.log(error);
        dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
    })
}

function clearLegalRepoStatusToLoanTerms({loanTermsIDs, customerID, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({ legalRepoStatus: firebase.firestore.FieldValue.delete()})
            .then(() => {
                console.log("legal repo status cleared successfully to loan terms");
            })
            .catch(error => {
                dispatch({ type: CLEAR_LEGAL_REPO_STATUS_FAILED });
                console.log("error clearing legal repo status to loan terms bucket");
                console.log(error);
                dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
            })
    });

}


function clearLegalRepoStatusToLoanTermsBucket({loanTermsIDs, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update({ legalRepoStatus: firebase.firestore.FieldValue.delete()})
            .then(() => {
                console.log("legal repo status cleared to loan terms bucket");
            })
            .catch(error => {
                dispatch({ type: CLEAR_LEGAL_REPO_STATUS_FAILED });
                console.log("error clearing legal repo status to loan terms bucket");
                console.log(error);
                dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
            })
    });

}





//------------------------------------------------------------ DELETE LOAN ---------------------------------------------------------------//

export const deleteLoan = ({ customerID, loanID }) => {

    return(dispatch) => {
        dispatch({ type: DELETE_LOAN });
        const data = JSON.stringify({ customerID, loanID });

        const url = `${project.serverUrl}deleteLoan`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: DELETE_LOAN_SUCCESSFUL,
                    payload: response
                });

                callFetchLoans({customerID, dispatch});

            }).catch((error) => {
            dispatch({ type: DELETE_LOAN_FAILED });
            console.log("failed to delete loan");
            console.log(error);
            callFetchLoans({customerID, dispatch});
        });
        
        // firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
        //     .delete()
        //     .then(() => {
        //         //
        //         //delete loan in loan bucket
        //         deleteLoanInBucket({ customerID, loanID, dispatch })
        //     })
        //     .catch(error => {
        //         console.log(error);
        //         console.log("loan is not deleted");
        //     })
    }
};

function deleteLoanInBucket({ customerID, loanID, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .delete()
        .then(() => {

            dispatch({ type: DELETE_LOAN_SUCCESSFUL });
            console.log("loan is delete");
            getLoanTermsForDeletion({ customerID, loanID, dispatch });
            getCashCollectionsForDeletion({customerID, loanID, dispatch});
            dispatch({ type: CLEAR_DELETE_LOAN_MESSAGE });
        })
        .catch(error => {
            console.log(error);
            console.log("loan is not deleted in bucket");
        })
}

function getCashCollectionsForDeletion({ customerID, loanID, dispatch }) {

    let cashCollections = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").where("loanID", "==", loanID)
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                cashCollections[doc.id] = doc.data();
            });

            const collections = _.map(cashCollections, collection => collection.cashCollectionID);

            deleteCashCollection({ customerID, collections, dispatch });
            deleteCashCollectionToBucket({ collections, dispatch });
            dispatch({ type: CLEAR_DELETE_LOAN_MESSAGE });

        }, function (error) {
            console.log("Error getting documents: ", error);
            dispatch({ type: FETCH_LOAN_TERMS_FAILED });
        })
}

function deleteCashCollection ({ customerID, collections, dispatch }) {

    collections.forEach(cashCollectionID => {
       //
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
            .delete()
            .then(() => {
                console.log("cash collection is deleted to customer profile");
                // save loan term to term bucket
            })
            .catch(error => {
                console.log(error);
                console.log("cash collection is NOT deleted to customer profile");
                dispatch({ type: DELETE_LOAN_SUCCESSFUL });
            })
    });

}


function deleteCashCollectionToBucket({ collections, dispatch }) {

    collections.forEach(cashCollectionID => {

        firebase.firestore().collection("cashCollections").doc(cashCollectionID)
            .delete()
            .then(() => {
                console.log("cash collection is deleted to bucket");
            })
            .catch(error => {
                console.log(error);
                console.log("cash collection is NOT deleted to bucket");
                dispatch({ type: DELETE_LOAN_SUCCESSFUL });
            })

    });

}



function getLoanTermsForDeletion({ customerID, loanID, dispatch }) {

    let loanTerms = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                loanTerms[doc.id] = doc.data();
            });

            const terms = _.map(loanTerms, term => term.loanTermID);

            deleteLoanTerms({ customerID, terms, loanID, dispatch });
            callFetchLoans({customerID, dispatch});
            dispatch({ type: CLEAR_DELETE_LOAN_MESSAGE });

        }, function (error) {
            console.log("Error getting documents: ", error);
            dispatch({ type: FETCH_LOAN_TERMS_FAILED });
        })
}


export const deleteLoanTerm = ({ currentLoan, customerID, term,  }) => {
    //term
    //grab customerID and loanID from term
    const loanID = term.loanID;

    return(dispatch) => {
        deleteLoanTerms({ currentLoan, customerID, terms: term.loanTermID, loanID, term, dispatch });
    };
};

export const deleteLoanTerms = ({ currentLoan, customerID, terms, loanID, term, dispatch }) => {


    if (Array.isArray(terms)) {
        terms.forEach(loanTermID => {
            deleteTermsInProfile({ customerID, loanTermID, loanID, dispatch });
        })
    } else {
        deleteTermsInProfile({ customerID, loanTermID: terms, loanID, dispatch })
        computeTotalLoanTermsAmountAfterDelete({currentLoan, customerID, loanID, term, dispatch})
    }
};

export const deleteTermsInProfile = ({ customerID, loanTermID, loanID, dispatch }) => {
    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
        .delete()
        .then(() => {
            // delete loan term in term bucket
            deleteLoanTermsInBucket({ customerID, loanTermID, loanID, dispatch });
        })
        .catch(error => {
            console.log(error);
            console.log("loan term is not deleted in customer profile");
        })
};

function deleteLoanTermsInBucket({ customerID, loanTermID, loanID, dispatch }) {


    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .delete()
        .then(() => {
            dispatch({ type: DELETE_LOAN_TERM_SUCCESSFUL });
            console.log("loan term is deleted in bucket");
            dispatch({ type: CLEAR_DELETE_LOAN_TERM_MESSAGE });
            reFetchCurrentLoanTerms({customerID, loanID, dispatch});
        })
        .catch(error => {
            dispatch({ type: DELETE_LOAN_TERM_FAILED });
            console.log(error);
            console.log("loan term is deleted in bucket");
            console.log("loan is deleted");
            dispatch({ type: CLEAR_DELETE_LOAN_TERM_MESSAGE });
        })
}

function reFetchCurrentLoanTerms({customerID, loanID, dispatch}) {

    //create loan terms obj
    let loanTerms = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                loanTerms[doc.id] = doc.data();
            });

            fetchLoan({customerID, loanID});
            dispatch({
                type: FETCH_LOAN_TERMS_SUCCESSFUL,
                payload: loanTerms
            })
        }, function (error) {
            console.log("Error getting documents: ", error);
            dispatch({ type: FETCH_LOAN_TERMS_FAILED });
        })
}

function computeTotalLoanTermsAmountAfterDelete({currentLoan, customerID, loanID, term, dispatch}) {
    //check if there is totalLoanTermsAmount on loan object
    let totalLoanTermsAmount;
    let tAmount = parseInt(term.amount);

    if ('totalLoanTermsAmount' in currentLoan) {
        //
        totalLoanTermsAmount = currentLoan.totalLoanTermsAmount - tAmount;
    } else {
        //
        totalLoanTermsAmount = 0;
    }

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
        .update({ totalLoanTermsAmount })
        .then(() => {

            console.log(" total loan terms amount is saved to client loan path");
            // save total loan terms amount to loan bucket

            firebase.firestore().collection("loans").doc(loanID)
                .update({ totalLoanTermsAmount })
                .then(() => {
                    //
                    console.log(" total loan terms amount is saved to loan");
                })
                .catch(error => {
                    //
                    console.log(error);
                    console.log(" total loan terms amount is not saved to loan");
                })

        })
        .catch(error => {
            console.log(error);
            console.log(" total loan terms amount is not saved to client loan path");
        })

}



//-----------------------------------------------------------------------------------------------------------------------------------------------------------------//

export const penalInterestChanged = (value) => {
    return {
        type: PENAL_INTEREST_CHANGED,
        payload: value
    }
};

export const saveCustomPenalInterest = ({ customerID, customPenalInterest, loanID }) => {

    return(dispatch) => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ customPenalInterest })
            .then(() => {
                console.log("custom penal interest has saved successfully in user profile");
                //save interest to bucket
                saveCustomPenalInterestToBucket({ loanID, customPenalInterest, dispatch });
            })
            .catch(error => {
                console.log("custom penal interest has save failed to save in profile");
                console.log(error);
            })
    }
};

function saveCustomPenalInterestToBucket({ loanID, customPenalInterest, dispatch }) {
    firebase.firestore().collection("loans").doc(loanID)
        .update({ customPenalInterest })
        .then(() => {
            dispatch({type: CUSTOMER_PENAL_INTEREST_SAVED_SUCCESSFUL});
            console.log("custom penal interest has saved successfully in bucket");
            dispatch({type: CLEAR_PENAL_INTEREST_MESSAGE});
        })
        .catch(error => {
            console.log("custom penal interest has save failed to save in bucket");
            console.log(error);
        })
}

export const previousOverdueChanged = (value) => {
    return {
        type: PREVIOUS_OVERDUE_CHANGED,
        payload: value
    }
};



export const savePreviousOverdue = ({ customerID, loanID, previousOverdue }) => {
    return(dispatch) => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({previousOverdue})
            .then(() => {
                console.log("previous overdue saved successful");

                //save previous overdue to bucket\
                saveTotalPreviousOverdueToBucket({loanID, previousOverdue, dispatch});
            })
            .catch((error) => {
                console.log("previous overdue save failed");
                console.log(error);
            })
    }
};


function saveTotalPreviousOverdueToBucket({loanID, previousOverdue, dispatch}) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({previousOverdue})
        .then(() => {
            dispatch({type: PREVIOUS_OVERDUE_SAVED_SUCCESSFUL});
            console.log("previous overdue saved to bucket successful");
            savePreviousOverDueToTerms({ previousOverdue, loanID, dispatch });
            dispatch({type: CLEAR_PREVIOUS_OVERDUE_MESSAGE});
        })
        .catch((error) => {
            console.log("previous overdue save to bucket failed");
            console.log(error);
        })
}

function savePreviousOverDueToTerms({ previousOverdue, loanID, dispatch }) {
    let loanTerms = [];

    firebase.firestore().collection("loanTerms").where("loanID", "==", loanID).get()
        .then(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                // doc.data() is never undefined for query doc snapshots
                //grab the term
                const term = doc.data();

                //grab the term id
                const loanTermID = term.loanTermID;

                //grab the customer ID
                const customerID = term.customerID;

                //check if term's due date hasn't matured
                ///create term bucket
                let terms = {};
                //grab due date
                let seconds;
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                const dueDate = moment.unix(seconds);

                //grab today's date
                const today = moment();

                if (dueDate.isAfter(today)) {
                    //mark term to be calculated penal interest
                    terms[`${loanTermID}`] = term;
                } else {
                    loanTerms.push(term);
                }


                //save previous overdue to loan term in bucket and profile
                firebase.firestore().doc(`loanTerms/${loanTermID}`).update({ previousOverdue })
                    .then(() => {
                        console.log("previous overdue punctured into loan term in bucket");
                        firebase.firestore().doc(`/users/clients/${customerID}/public/loanTerms/${loanTermID}`).update({ previousOverdue })
                            .then(() => {
                                console.log("previous overdue punctured into loan term in profile");
                                //mark terms for penal interest calculations
                                markLoanTermsForPenalInterestCalculations({ customerID, terms })
                            }).catch(err => console.log(err));
                    }).catch(err => console.log(err));

                //compute total paid
                computePreviousOverdueToBucket({ loanID, previousOverdue, loanTerms})

            });
        })
        .catch(function(error) {
            console.log("Error getting loan terms: ", error);
        });
}

function markLoanTermsForPenalInterestCalculations({ customerID, terms }) {
    _.map(terms, term => {

        const loanTermID = term.loanTermID;

        //save previous overdue to loan term in bucket and profile
        firebase.firestore().doc(`loanTerms/${loanTermID}`).update({ calculatePenalInterest: true })
            .then(() => {
                console.log("previous term in bucket updated to calculate penal interest");
                firebase.firestore().doc(`/users/clients/${customerID}/public/loanTerms/${loanTermID}`).update({ calculatePenalInterest: true })
                    .then(() => {
                        console.log("previous term in profile updated to calculate penal interest");
                    }).catch(err => console.log(err));
            }).catch(err => console.log(err));
    });

}

function computePreviousOverdueToBucket({loanID, previousOverdue, loanTerms}) {

    firebase.firestore().collection("loans").doc(loanID)
        .get()
        .then(doc => {
            if (doc.exists) {
                const data = doc.data();
                const totalPaidRaw = data.totalPaid;
                const customerID = data.customerID;

                //create extra fee store
                let extraFeeStore = [];
                let termStore = [];

                //get extra fees
                if ("extraFees" in data) {
                    _.map(data.extraFees, extraFee => {
                        extraFeeStore.push(extraFee.extraFeeAmount);
                    })
                }

                //find total of extra free
                //calculate the total amount from numbers in the array
                const totalExtraFee = extraFeeStore.reduce((a, b) => a + b, 0);

                loanTerms.map(term => termStore.push(term.amount));
                //calculate the total amount from numbers in the array
                const termsTotal = extraFeeStore.reduce((a, b) => a + b, 0);

                const supposedToPay = termsTotal + totalExtraFee;

                const totalPaidPreviously = supposedToPay - previousOverdue;

                const totalPaid = totalPaidRaw + totalPaidPreviously;

                firebase.firestore().collection("loans").doc(loanID)
                    .update({totalPaid})
                    .then(() => {
                        firebase.firestore().doc(`/users/clients/${customerID}/public/loans/${loanID}`).update({ totalPaid })
                            .then(() => {
                                //console.log("previous term in profile updated to calculate penal interest");
                            }).catch(err => console.log(err));
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        })
        .catch((error) => {
            console.log(error);
        })
}


export const extraFeeChanged = ({ prop, value }) => {
    return {
        type: EXTRA_FEE_CHANGED,
        payload: { prop, value }
    }
};

export const saveExtraFee = ({ customerID, loanID, extraFeeName, extraFeeAmount, extraFeeDate }) => {
    //extra fee id
    const extraFeeID = Math.random().toString(36).substring(6).toUpperCase();

    return(dispatch) => {
        dispatch({type: EXTRA_FEE_SAVED});
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ [`extraFees.${extraFeeID}`] : { extraFeeName, extraFeeAmount, extraFeeDate, extraFeeID } })
            .then(() => {
                // console.log("extra fee and name saved successful");
                saveExtraFeeToBucket({ loanID, extraFeeName, extraFeeAmount, extraFeeID, extraFeeDate, dispatch });
            })
            .catch((error) => {
                console.log("extra name and amount failed to save to profile");
                console.log(error);
            })
    }
};

function saveExtraFeeToBucket({ loanID, extraFeeName, extraFeeAmount, extraFeeID, extraFeeDate, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ [`extraFees.${extraFeeID}`] : { extraFeeName, extraFeeAmount, extraFeeDate, extraFeeID } })
        .then(() => {
            dispatch({type: EXTRA_FEE_SAVED_SUCCESSFUL});
            console.log("extra fee and name saved successful");
            dispatch({type: CLEAR_EXTRA_FEE_MESSAGE});
        })
        .catch((error) => {
            dispatch({type: EXTRA_FEE_SAVED_FAILED});
            console.log("extra name and amount failed to save to bucket ");
            console.log(error);
            dispatch({type: CLEAR_EXTRA_FEE_MESSAGE});
        })
}

export const deleteExtraFee = ({ customerID, loanID, extraFeeID }) => {
    
    return(dispatch) => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ [`extraFees.${extraFeeID}`] : firebase.firestore.FieldValue.delete()  })
            .then(() => {
                // console.log("extra fee and name saved successful");
                deleteExtraFeeToBucket({ loanID, extraFeeID, dispatch });
            })
            .catch((error) => {
                console.log("extra name and amount failed to delete to profile");
                console.log(error);
            })
    }
};

function deleteExtraFeeToBucket({ loanID, extraFeeID, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ [`extraFees.${extraFeeID}`] : firebase.firestore.FieldValue.delete() })
        .then(() => {
            dispatch({type: DELETE_EXTRA_FEE_SUCCESSFUL});
            console.log("extra fee and name deleted successful");
            dispatch({type: CLEAR_EXTRA_FEE_MESSAGE});
        })
        .catch((error) => {
            dispatch({type: DELETE_EXTRA_FEE_FAILED});
            console.log("extra name and amount failed to delete to bucket ");
            console.log(error);
            dispatch({type: CLEAR_EXTRA_FEE_MESSAGE});
        })
}

///--------------------------------------------------------------------------------------------------


export const earlyLiquidationChanged = (value) => {
    return {
        type: EARLY_LIQUIDATION_CHANGED,
        payload: value
    }
};

export const saveEarlyLiquidation = ({ customerID, loanID, earlyLiquidation, loanTerms }) => {

    const earlyLiquidationDate = new Date();

    return(dispatch) => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ earlyLiquidation, earlyLiquidationDate, loanStatus: true })
            .then(() => {
                saveEarlyLiquidationToBucket({ loanID, earlyLiquidation, earlyLiquidationDate, loanTerms, dispatch });
            })
            .catch((error) => {
                console.log("early liquidation failed to save to bucket ");
                dispatch({type: EARLY_LIQUIDATION_SAVE_FAILED});
                console.log(error);
                dispatch({type: CLEAR_EARLY_LIQUIDATION_MESSAGE});
            })
    }
};

function saveEarlyLiquidationToBucket({ loanID, earlyLiquidation, earlyLiquidationDate, loanTerms, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ earlyLiquidation, earlyLiquidationDate, loanStatus: true })
        .then(() => {
            saveEarlyLiquidationStatusToTerms({ loanTerms, dispatch });
            //save early liquidation status to loanTerms
            dispatch({type: EARLY_LIQUIDATION_SAVED_SUCCESSFUL});
            console.log("early liquidation saved successful");
            dispatch({type: CLEAR_EARLY_LIQUIDATION_MESSAGE});
        })
        .catch((error) => {
            console.log("early liquidation failed to save to bucket ");
            dispatch({type: EARLY_LIQUIDATION_SAVE_FAILED});
            console.log(error);
            dispatch({type: CLEAR_EARLY_LIQUIDATION_MESSAGE});
        })
}

function saveEarlyLiquidationStatusToTerms({ loanTerms, dispatch }) {
    loanTerms.forEach(term => {
        firebase.firestore().collection("users").doc("clients").collection(term.customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
            .update({ earlyLiquidation: true, loanStatus: true })
            .then(() => {
                saveEarlyLiquidationStatusToTermDB({ term });
            })
            .catch((error) => {
                console.log("early liquidation failed to save to term profile ");
                console.log(error);
            })
    })
}

function saveEarlyLiquidationStatusToTermDB({ term }) {
    firebase.firestore().collection("loanTerms").doc(term.loanTermID)
        .update({ earlyLiquidation: true, loanStatus: true })
        .then(() => {
        })
        .catch((error) => {
            console.log("early liquidation failed to save to bucket ");
            console.log(error);
        })
}


///--------------------------------------------------------------------------------------------------


export const reverseLiquidation = ({ customerID, loanID, loanTerms }) => {

    return(dispatch) => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ earlyLiquidation: firebase.firestore.FieldValue.delete(), earlyLiquidationDate: firebase.firestore.FieldValue.delete(), loanStatus: false   })
            .then(() => {
                reverseLiquidationToBucket({ loanID, loanTerms, dispatch });
            })
            .catch((error) => {
                console.log("early liquidation failed to save to profile");
                console.log(error);
            })
    }
};

function reverseLiquidationToBucket({ loanID, loanTerms, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ earlyLiquidation: firebase.firestore.FieldValue.delete(), earlyLiquidationDate: firebase.firestore.FieldValue.delete(), loanStatus: false  })
        .then(() => {
            console.log({ loanTerms });
            reverseEarlyLiquidationStatusToTerms({ loanTerms, dispatch });
            dispatch({type: EARLY_LIQUIDATION_SAVED_SUCCESSFUL});
            console.log("early liquidation reverse successful");
            dispatch({type: CLEAR_EARLY_LIQUIDATION_MESSAGE});
        })
        .catch((error) => {
            console.log("early liquidation failed to reverse to bucket ");
            dispatch({type: EARLY_LIQUIDATION_SAVE_FAILED});
            console.log(error);
            dispatch({type: CLEAR_EARLY_LIQUIDATION_MESSAGE});
        })
}

function reverseEarlyLiquidationStatusToTerms({ loanTerms }) {
    _.forEach(loanTerms, term => {
        firebase.firestore().collection("users").doc("clients").collection(term.customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
            .update({ earlyLiquidation: firebase.firestore.FieldValue.delete(), loanStatus: false })
            .then(() => {
                reverseEarlyLiquidationStatusToTermDB({ term });
            })
            .catch((error) => {
                console.log("early liquidation failed to reverse to term profile ");
                console.log(error);
            });
    });
}

function reverseEarlyLiquidationStatusToTermDB({ term }) {
    firebase.firestore().collection("loanTerms").doc(term.loanTermID)
        .update({ earlyLiquidation: firebase.firestore.FieldValue.delete(), loanStatus: false })
        .then(() => {
        })
        .catch((error) => {
            console.log("early liquidation failed to reverse to bucket ");
            console.log(error);
        })
}


///--------------------------------------------------------------------------------------------------

export const clearUnclearLoan = ({ customerID, loanID, loanStatus }) => {
    return(dispatch) => {
        dispatch({ type: CLEAR_UNCLEAR_LOAN });
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ loanStatus })
            .then(() => {
                clearUnclearLoanInBucket({ loanID, loanStatus, dispatch })
            })
            .catch((error) => {
                dispatch({ type: CLEAR_UNCLEAR_LOAN_FAILED });
                console.log(error);
                dispatch({type: CLEAR_UNCLEAR_LOAN_MESSAGE});
            })
    }
};

function clearUnclearLoanInBucket({ loanID, loanStatus, dispatch }) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ loanStatus })
        .then(() => {
            dispatch({type: CLEAR_UNCLEAR_LOAN_SUCCESSFUL});
            console.log("early liquidation saved successful");
            dispatch({type: CLEAR_UNCLEAR_LOAN_MESSAGE});
        })
        .catch((error) => {
            dispatch({ type: CLEAR_UNCLEAR_LOAN_FAILED });
            console.log(error);
            dispatch({type: CLEAR_UNCLEAR_LOAN_MESSAGE});
        })
}

///--------------------------------------------------------------------------------------------------

export const saveRescheduleStatus = ({rescheduleStatus, customerID, loanID, loanTermsIDs}) => {

    return (dispatch) => {

        dispatch({ type: SAVE_RESCHEDULE_STATUS });

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({rescheduleStatus})
            .then(() => {

                console.log("reschedule Status is saved successful into customer path");
                saveRescheduleStatusToLoansBucket({rescheduleStatus, loanID, dispatch});
                saveRescheduleStatusToLoanTerms({rescheduleStatus, customerID, loanTermsIDs, dispatch});
                saveRescheduleStatusToLoanTermsBucket({rescheduleStatus, loanTermsIDs, dispatch});

            })
            .catch(error => {
                dispatch({ type: SAVE_RESCHEDULE_STATUS_FAILED,});
                console.log(error);
                console.log("Legal Repo status is not saved into customer path");
                dispatch({ type: CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,});
            })
    }
};

function saveRescheduleStatusToLoansBucket({rescheduleStatus, loanID, dispatch}) {
    firebase.firestore().collection("loans").doc(loanID)
        .update({ rescheduleStatus })
        .then(() => {
            dispatch({ type: SAVE_RESCHEDULE_STATUS_SUCCESSFUL,});
            console.log("reschedule Status saved to bucket");
            dispatch({ type: CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,});
        })
        .catch(error => {
            console.log("error saving reschedule status to bucket");
            console.log(error);
        })
}


function saveRescheduleStatusToLoanTerms({rescheduleStatus, loanTermsIDs, customerID, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({ rescheduleStatus })
            .then(() => {
                console.log("reschedule status saved to loan terms");
            })
            .catch(error => {
                console.log("error saving reschedule status to loan terms");
                console.log(error);
            })
    });

}


function saveRescheduleStatusToLoanTermsBucket({rescheduleStatus, loanTermsIDs, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update({ rescheduleStatus})
            .then(() => {
                console.log("reschedule status saved to loan terms bucket");

            })
            .catch(error => {
                console.log("error saving reschedule status to loan terms bucket");
                console.log(error);
            })
    });

}


export const clearRescheduleStatus = ({ customerID, loanID, loanTermsIDs }) => {

    return (dispatch) => {

        dispatch({ type: CLEAR_RESCHEDULE_STATUS});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
            .update({ rescheduleStatus: firebase.firestore.FieldValue.delete()})
            .then(() => {

                console.log("reschedule status is deleted from customer path");

                //delete legal repo status value from bucket
                clearRescheduleStatusToLoansBucket({ loanID, dispatch});
                clearRescheduleStatusToLoanTerms({ loanTermsIDs, customerID, dispatch});
                clearRescheduleStatusToLoanTermsBucket({ loanTermsIDs, dispatch});
            })
            .catch(error => {
                dispatch({ type: CLEAR_RESCHEDULE_STATUS_FAILED,});
                console.log(error);
                console.log("reschedule status is not deleted into customer path");
                dispatch({ type: CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,});
            })
    }
};

function clearRescheduleStatusToLoansBucket({ loanID, dispatch}) {

    firebase.firestore().collection("loans").doc(loanID)
        .update({ rescheduleStatus: firebase.firestore.FieldValue.delete()})
        .then(() => {
            dispatch({ type: CLEAR_RESCHEDULE_STATUS_SUCCESSFUL,});
            console.log("reschedule status cleared from bucket");
            dispatch({ type: CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,});
        })
        .catch(error => {
            console.log("error clearing reschedule status to bucket");
            console.log(error);
        })
}

function clearRescheduleStatusToLoanTerms({loanTermsIDs, customerID, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
            .update({ rescheduleStatus: firebase.firestore.FieldValue.delete()})
            .then(() => {
                console.log("reschedule status cleared successfully to loan terms");
            })
            .catch(error => {
                console.log("error clearing reschedule status to loan terms");
                console.log(error);
            })
    });

}


function clearRescheduleStatusToLoanTermsBucket({loanTermsIDs, dispatch}) {

    loanTermsIDs.forEach(loanTermID => {

        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update({ rescheduleStatus: firebase.firestore.FieldValue.delete()})
            .then(() => {
                console.log("reschedule status cleared to loan terms bucket");
            })
            .catch(error => {
                console.log("error clearing reschedule status to loan terms bucket");
                console.log(error);
            })
    });

}

//------------------------------------------------------------- CLEAR LOAN PAGE

export const clearLoanPage = () => {
    return {
        type: CLEAR_LOAN_PAGE
    }
};




// export const saveLegalRepoStatus = ({legalRepoStatus, customerID, loanID, loanTermsIDs}) => {

//     return (dispatch) => {

//         dispatch({ type: SAVE_LEGAL_REPO_STATUS });

//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//         .update({legalRepoStatus})
//         .then(() => {

//             console.log("Legal Repo status is saved successful into customer path");
//             saveLegalRepoStatusToLoansBucket({legalRepoStatus, loanID, dispatch});
//             saveLegalRepoStatusToLoanTerms({legalRepoStatus, customerID, loanTermsIDs, dispatch});
//             saveLegalRepoStatusToLoanTermsBucket({legalRepoStatus, loanTermsIDs, dispatch});

//         })
//         .catch(error => {
//             dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
//             console.log(error);
//             console.log("Legal Repo status is not saved into customer path");
//             dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
//         })
//     }
// };

//fetch status  legalRepoStatus

//addRepoStatusValue, 
// export const addLegalRepoStatus = ({legalRepoStatus}) => {

//     return (dispatch) => {

//         dispatch({ type: SAVE_LEGAL_REPO_STATUS });

//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//         .update({legalRepoStatus})
//         .then(() => {

//             console.log("Legal Repo status is saved successful into customer path");
//             saveLegalRepoStatusToLoansBucket({legalRepoStatus, loanID, dispatch});
//             saveLegalRepoStatusToLoanTerms({legalRepoStatus, customerID, loanTermsIDs, dispatch});
//             saveLegalRepoStatusToLoanTermsBucket({legalRepoStatus, loanTermsIDs, dispatch});

//         })
//         .catch(error => {
//             dispatch({ type: SAVE_LEGAL_REPO_STATUS_FAILED });
//             console.log(error);
//             console.log("Legal Repo status is not saved into customer path");
//             dispatch({ type: CLEAR_LEGAL_REPO_MESSAGE });
//         })
//     }
// };



//deleteRepoStatus

