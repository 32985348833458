import React from 'react';
import { Select } from 'antd';


class SelectRescheduleStatus extends React.Component {


    render() {

        const { Option } = Select;

        return (
            <div>
                <div>
                    <p>{ this.props.label }</p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="rescheduledLoan">Rescheduled Loan</Option>
                    <Option value="oldLoan">Old Loan</Option>
                </Select>

            </div>
        );
    }
}

export default (SelectRescheduleStatus);
