import React from 'react';
import { connect } from 'react-redux';
import {message, Switch} from "antd";

//actions import
import { unidentifiedPaymentDateChanged, unidentifiedPaymentDetailsChanged, saveUnidentifiedPayment, unidentifiedPaymentCurrencyChanged} from "../../../actions/UnidentifiedActions";

//components import
import {PickDate} from "../../common/PickDate";
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
import {TextArea} from "../../common/TextArea";
import {BeatLoader} from "react-spinners";



class AddUnidentifiedPayment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            unidentifiedPaidAmount: this.props.unidentifiedPaidAmount,
            unidentifiedBankName: this.props.unidentifiedBankName,
            unidentifiedBankDate: this.props.unidentifiedBankDate,
            unidentifiedComments: this.props.unidentifiedComments,
            currency: this.props.currency,
            disabled: false,
            unidentifiedPaymentLoading: this.props.unidentifiedPaymentLoading,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            saveUnidentifiedPaymentSuccessful: this.props.saveUnidentifiedPaymentSuccessful,
            saveUnidentifiedPaymentFailed: this.props.saveUnidentifiedPaymentFailed,

        };

        this.onBankDateChanged = this.onBankDateChanged.bind(this);
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {


        if (nextProps.unidentifiedPaidAmount !== this.props.unidentifiedPaidAmount) {
            this.setState({unidentifiedPaidAmount: nextProps.unidentifiedPaidAmount})
        }
        if (nextProps.unidentifiedBankName !== this.props.unidentifiedBankName) {
            this.setState({unidentifiedBankName: nextProps.unidentifiedBankName})
        }
        if (nextProps.unidentifiedBankDate !== this.props.unidentifiedBankDate) {
            this.setState({unidentifiedBankDate: nextProps.unidentifiedBankDate})
        }
        if (nextProps.unidentifiedComments !== this.props.unidentifiedComments) {
            this.setState({unidentifiedComments: nextProps.unidentifiedComments})
        }
        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }
        if (nextProps.unidentifiedPaymentLoading !== this.props.unidentifiedPaymentLoading) {
            this.setState({unidentifiedPaymentLoading: nextProps.unidentifiedPaymentLoading})
        }
        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }
        if (nextProps.saveUnidentifiedPaymentSuccessful !== this.props.saveUnidentifiedPaymentSuccessful) {
            this.setState({saveUnidentifiedPaymentSuccessful: nextProps.saveUnidentifiedPaymentSuccessful})
        }
        if (nextProps.saveUnidentifiedPaymentFailed !== this.props.saveUnidentifiedPaymentFailed) {
            this.setState({saveUnidentifiedPaymentFailed: nextProps.saveUnidentifiedPaymentFailed})
        }

        if (nextProps.disabled !== this.props.disabled) {
            this.setState({disabled: nextProps.disabled})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({systemInfo: nextProps.systemInfo});
        }
    }

    onBankDateChanged(date) {
        this.props.unidentifiedPaymentDateChanged(date);
    }

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.unidentifiedPaymentCurrencyChanged(status)
    }

    onSave() {


        let { unidentifiedPaidAmount, unidentifiedBankName, unidentifiedBankDate, unidentifiedComments, currency, profile, systemInfo } = this.state;

        //set button disable mode
        this.setState({disabled: true});
        setTimeout(() => {
            this.setState({disabled: false});
        }, 5000)

        //determine cash collection is tzs or usd
        let rawCurrency;
        if (currency) {
            rawCurrency = "usd";
        } else {
            rawCurrency = systemInfo.defaultCurrency;
        }

        if (unidentifiedPaidAmount && unidentifiedBankName && unidentifiedBankDate && unidentifiedComments) {

            //comment object
            const timeStamp = new Date();
            const collectorID = profile.userID;

            const comment = { timeStamp, collectorID, comments: unidentifiedComments };

            unidentifiedPaidAmount = parseInt(unidentifiedPaidAmount);
            unidentifiedBankDate = unidentifiedBankDate.toDate();

            this.props.saveUnidentifiedPayment({unidentifiedPaidAmount, unidentifiedBankName: unidentifiedBankName.toUpperCase(), unidentifiedBankDate, unidentifiedComments: comment, currency: rawCurrency })

        } else {
            message.warning('Fill all the required fields');
        }
    }

    renderButton () {

        if (this.state.unidentifiedPaymentLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Save'}
                        onPress={() => this.onSave()}
                        buttonDisabled={this.state.disabled}
                    />
                </div>
            );
        }
    }


    render() {

        const {systemInfo} = this.state;
        const currencyAbv = systemInfo.defaultCurrency.toUpperCase();
        const {saveUnidentifiedPaymentSuccessful, saveUnidentifiedPaymentFailed } = this.state;

        return (
            <div className={"addUnidentifiedBox"}>
                <h4>Add Unidentified Payment</h4>
                <div className={"unidentifiedInfoBox"}>
                    <div className={"unidentifiedInputBox"}>
                        <p>Currency</p>
                        <Switch
                            checkedChildren="USD"
                            unCheckedChildren={currencyAbv}
                            onChange={() => this.onCurrencySwitchChange() }
                            checked={this.state.currency}
                        />
                    </div>
                    <div className={"unidentifiedInputBox"}>
                        <Input
                            label={'Paid Amount'}
                            type={'number'}
                            onChange={e => this.props.unidentifiedPaymentDetailsChanged({prop: 'unidentifiedPaidAmount', value: e.target.value})}
                            value={this.state.unidentifiedPaidAmount}
                        />
                    </div>
                    <div className={"unidentifiedInputBox"}>
                        <Input
                            label={'Bank Name'}
                            type={'text'}
                            onChange={e => this.props.unidentifiedPaymentDetailsChanged({prop: 'unidentifiedBankName', value: e.target.value})}
                            value={this.state.unidentifiedBankName}
                        />
                    </div>
                    <div className={"unidentifiedInputBox"}>
                        <PickDate
                            label={'Bank Date'}
                            onChange={this.onBankDateChanged}
                            value={this.props.unidentifiedBankDate}
                        />
                    </div>
                    <div className={"unidentifiedInputBox"}>
                        <TextArea
                            label={'Comments'}
                            onChange={e => this.props.unidentifiedPaymentDetailsChanged({prop: 'unidentifiedComments', value: e.target.value})}
                            value={this.props.unidentifiedComments}
                        />
                    </div>
                </div>
                <div className={"unidentifiedBoxButton"}>
                    {this.renderButton()}
                </div>
                <div>
                    <div>
                        {
                            saveUnidentifiedPaymentSuccessful?
                                <p>{message.success("Unidentified payment is successfully saved")}</p>:
                                null
                        }
                    </div>
                    <div>
                        {
                            saveUnidentifiedPaymentFailed?
                                <p>{message.error("Failed to save an unidentified payment")}</p>:
                                null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { unidentifiedPaidAmount, unidentifiedLoading, unidentifiedBankName, unidentifiedBankDate, unidentifiedComments, unidentifiedPaymentLoading, currency, saveUnidentifiedPaymentSuccessful, saveUnidentifiedPaymentFailed } = state.unidentified;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        //
        profile,
        systemInfo,
        unidentifiedPaidAmount,
        unidentifiedBankName,
        unidentifiedBankDate,
        unidentifiedComments,
        currency,
        unidentifiedLoading,
        unidentifiedPaymentLoading,
        saveUnidentifiedPaymentSuccessful,
        saveUnidentifiedPaymentFailed,
    }
};


export default connect(mapStateToProps,{unidentifiedPaymentDateChanged, unidentifiedPaymentDetailsChanged, saveUnidentifiedPayment, unidentifiedPaymentCurrencyChanged})(AddUnidentifiedPayment);