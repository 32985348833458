import React from 'react';
import { DatePicker, Space } from 'antd';


export class DateRange extends React.Component {


    render() {

        const { RangePicker } = DatePicker;

        return(
            <div>
                <div className={"dateRangeLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <div>
                    <Space direction="vertical" size={12}>
                        <RangePicker
                            onChange={this.props.onChange}
                            value={this.props.value}
                        />
                    </Space>
                </div>
            </div>
        );
    }
}