import React from "react";
import { connect } from 'react-redux';
import firebase from "firebase";
import {Link, withRouter} from "react-router-dom";
import {message} from "antd";
import 'antd/dist/antd.css';

//env imports
import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";

//action import
import {credentialsChanged, userLogin} from "../../actions/AuthenticationActions";

//component import
import {Input} from "../common/Input";
import UserRole from "../common/UserRole";
import {Button} from "../common/Button";


//file imports
import tataLogo from '../../assets/logo/tatalogo.png';
import loginArtwork from '../../assets/images/loginArtwork.png';
import bubble from '../../assets/images/bubble.png';
import {
    BarLoader,
    BeatLoader,
    BounceLoader,
    CircleLoader,
    ClimbingBoxLoader,
    ClockLoader,
    DotLoader, FadeLoader, HashLoader
} from "react-spinners";

class SignInPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: this.props.username,
            privateID: this.props.privateID,
            password: this.props.password,
            signInLoading: false,
            logoutSuccess: this.props.logoutSuccess,
        };

        this.onLoginPress = this.onLoginPress.bind(this);
        this.onReInitialize = this.onReInitialize.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContent) {

        if (nextProps.username !== this.props.username) {
            this.setState({ username: nextProps.username});
        }

        if (nextProps.password !== this.props.username) {
            this.setState({ password: nextProps.password });
        }

        if (nextProps.privateID !== this.props.privateID) {
            this.setState({ privateID: nextProps.privateID });
        }

        if (nextProps.signInLoading !== this.props.signInLoading) {
            this.setState({ signInLoading: nextProps.signInLoading });
        }

        if (nextProps.logoutSuccess !== this.props.logoutSuccess) {
            this.setState({ logoutSuccess: nextProps.logoutSuccess });
        }

    }

    onLoginPress() {
        //handle login

        //fetch credentials
        const { username, password, privateID } = this.state;
        const { history } = this.props;
        const that = this;

        //check if fields are not empty
        if (username && password && privateID) {
            //call login action from actions

            //button loading
            this.setState({signInLoading: true});

            //this.props.userLogin({ username, password, privateID });
            // pick project config files by private ID
            const projectContainer = projectArray.filter(project => project.privateID === privateID);

            //check that filtered project container is not empty
            if (projectContainer.length !== 0) {
                //project container is not empty
                const project = projectContainer[0];

                if (!firebase.apps.length) {
                    console.log("no project exists");
                    console.log("initializing a new project");
                    // initialize firebase
                    firebase.initializeApp(project.config);

                    //sign in
                    firebase.auth().signInWithEmailAndPassword(username, password)
                        .then(() => {
                            console.log("user sign in");

                            // Put the firebase initialization object into storage
                            localStorage.setItem('afclFirebaseInitializationObject', JSON.stringify(project.config));
                            localStorage.setItem('afclInitializedProject', JSON.stringify(project));

                            //make sure to push to new page before reloading
                            history.push('/');
                            //window.location.reload();
                            message.success({
                                content:"Welcome",
                                duration: 3,
                            });
                        })
                        .catch((error) => {
                            that.setState({signInLoading: false});
                            console.log(error);
                            message.error({
                                content: `${error}`,
                                duration: 5,
                            });
                        })
                } else {
                    console.log("a project already exists, deleting project and assigning a new one");
                    this.onReInitialize({ username, password, history, project });
                }


            } else {
                //project container is empty
                message.warning("Sorry! Project private ID does not exist.");
                that.setState({signInLoading: false});
            }


        } else {
            message.warning('Fill all required fields');
        }
    }


    onReInitialize({ username, password, history, project }) {

        //getLoading
        const that = this;

        firebase.app().delete().then(function() {

            firebase.initializeApp(project.config);


            //sign in
            firebase.auth().signInWithEmailAndPassword(username, password)
                .then(() => {
                    console.log("user signed in");

                    // Put the firebase initialization object into storage
                    localStorage.setItem('afclFirebaseInitializationObject', JSON.stringify(project.config));
                    localStorage.setItem('afclInitializedProject', JSON.stringify(project));

                    //make sure to push to new page before reloading
                    history.push('/');
                    window.location.reload();
                    // message.success({
                    //     content:"Welcome",
                    //     duration: 3,
                    // });
                })
                .catch((error) => {
                    that.setState({signInLoading: false});
                    console.log(error);
                    message.error({
                        content:`${error}`,
                        duration: 5,
                    });
                })
        });

    }

    keyPressed = (e) => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            console.log('Enter key is pressed, Logging in....');
            this.onLoginPress();
        }
    };

    renderLogOutMessage(){
        const {logoutSuccess} = this.state;
        //
        if (logoutSuccess){
            return <p>{message.success("Logged Out")}</p>
        } else {

            return null;
        }
    }

    renderButton () {

        const {signInLoading} = this.state;


        if (signInLoading) {
           return (
               <div>
                   <BeatLoader/>
               </div>
           );
        } else {
            return (
                <div className={"signInButtonBox"}>
                    <Button
                        text={'Log In'}
                        onPress={()=> this.onLoginPress()}
                    />
                </div>
            );
        }
    }

    render() {

        return (
            <div className={"signInContainer"}>
                <div className={"signInartwork"}>
                    <div className={"signInLogoBox"}>
                        <h2>Welcome</h2>
                        <img src={tataLogo} alt="tata logo" />
                    </div>

                    <div className={"artworkBox"}>
                        <img src={loginArtwork} alt="login artwork"/>
                    </div>
                </div>

                <div className={"signInFormBox"}>
                    <div>
                        <div className={"signInFormHeader"}>
                            <h4>Please login to your account</h4>
                        </div>

                        <div className={"inputFormBox"}>
                            <div>
                                <Input
                                    type={'text'}
                                    // label={'Email'}
                                    placeholder={'Email'}
                                    onChange={e => this.props.credentialsChanged({prop: 'username', value: e.target.value}) }
                                    value={this.props.username}
                                    onKeyDown={this.keyPressed}
                                />
                            </div>
                            <div>
                                <Input
                                    type={'number'}
                                    placeholder={'PrivateID'}
                                    onChange={e => this.props.credentialsChanged({prop: 'privateID', value: e.target.value}) }
                                    value={this.state.privateID}
                                    onKeyDown={this.keyPressed}
                                />
                            </div>
                            <div>
                                <Input
                                    type={'password'}
                                    // label={'password'}
                                    placeholder={'Password'}
                                    onChange={e => this.props.credentialsChanged({prop: 'password', value: e.target.value}) }
                                    value={this.props.password}
                                    onKeyDown={this.keyPressed}
                                />
                            </div>
                            <div>
                                {this.renderButton()}
                            </div>
                            <Link to={'/forgot-password'}>Forgot password?</Link>
                        </div>
                    </div>
                </div>
                <div>{this.renderLogOutMessage()}</div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { username, password, privateID, logoutSuccess } = state.authentication;

    //return object with state properties
    return {
        username,
        password,
        privateID,
        logoutSuccess,
    }
};


export default withRouter(connect(mapStateToProps, {credentialsChanged, userLogin})(SignInPage));