import firebase from 'firebase';
import moment from 'moment';
import _ from "lodash";
import arraySort from "array-sort";
import {message} from "antd";
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';


//Types import
import {
    CLEAR_REPORTS_MESSAGE, CREATE_MASTER_LIST_REPORT_EMPTY, CREATE_MASTER_LIST_REPORT_SUCCESSFUL,
    CREATE_REPORT,
    CREATE_REPORT_EMPTY,
    CREATE_REPORT_FAILED,
    CREATE_REPORT_SUCCESSFUL,
    ALL_REPORT,
    ALL_REPORT_SUCCESSFUL,
    ALL_REPORT_FAILED,
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


//property declaration
const XLSX = require('xlsx');


// const date = reportDate.format("DD_MM_YYYY");
// const reportID = `cash_collections_${date}`;
//
// const data = JSON.stringify({ reportID, path: "cashCollections" });
//
// //invoke custom database function
//
// fetch('https://us-central1-alliancefinance-23.cloudfunctions.net/downloadGeneratedReport', {
//     method: 'POST',
//     mode: 'cors',
//     body: data,
//     headers: {'Content-Type': 'application/json'},
// }).then((response) => response.json())
//     .then((response) => {
//         console.log("Here's your reports object");
//         console.log(response);
//
//     }).catch((error) => {
//     console.log("Here's your error");
//     console.log(error);
//
// });

export const generateDashboardReportNew = ({reportDate, systemInfo}) => {

    const localCurrency = systemInfo.defaultCurrency;
    const exchangeRate = systemInfo.exchangeRate;

    return(dispatch) => {
        //
        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `dashboardNew_${date}`;

        let successArray = [];

        const data = JSON.stringify({ reportID, path: "dashboardNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                const allBeforeTerms = response.allBeforeTerms;
                const overdueTerms = response.masterListNew;

                let dates = {};

                overdueTerms.forEach(term => {
                    let seconds;
                    if ("transactionDate" in term) {
                        if (term.transactionDate) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                        }
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }

                    const dueDate = moment.unix(seconds);

                    //put term in current month bucket
                    //grab the month and year
                    const dueDateID = dueDate.format("MMYYYY");

                    //check if dueDateID already exists in dates object
                    if (`${dueDateID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${dueDateID}`].terms;
                        terms.push(term);

                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        dates[`${dueDateID}`] = {
                            date: dueDate,
                            terms: [term],
                            allBeforeTerms,
                            termsStore: overdueTerms
                        }
                    }
                })

                //FIND GRAND CONTENTS
                let totalCustomers;
                let sumInUsd;
                let sumLCY;
                let sumOfODInUSD;

                //sum of debit in local currency
                let storeTerms = [];
                if (allBeforeTerms.length !== 0) {
                    allBeforeTerms.map(term => {
                        if (term.cheque) {
                            if (term.chequeStatus === "bounced") {
                                if (term.currency !== "usd") {
                                    storeTerms.push(term.amount);
                                }
                            }
                        } else {
                            if (term.currency !== "usd") {
                                storeTerms.push(term.amount);
                            }
                        }
                    });

                    sumLCY = storeTerms.reduce((a, b) => a + b, 0);
                    if(sumLCY !== 0){

                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: localCurrency,
                        });

                        sumLCY = formatter.format(sumLCY);
                    }
                }

                //sum of debit in usd format
                let totalInUSD = [];
                if (allBeforeTerms.length !== 0) {
                    allBeforeTerms.map(term => {
                        //filter if they are cleared or not
                        if (term.cheque) {
                            if (term.chequeStatus === "bounced") {
                                if (term.currency === "usd") {
                                    totalInUSD.push(term.amount);
                                }
                            }
                        } else {
                            if (term.currency === "usd") {
                                totalInUSD.push(term.amount);
                            }
                        }
                    });

                    sumInUsd = totalInUSD.reduce((a, b) => a + b, 0);
                    const  currency = "USD";

                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency,
                    });

                    sumInUsd = formatter.format(sumInUsd);
                }

                //sum of OD in usd
                let usdTermStore = [];
                overdueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            usdTermStore.push(amount);
                        } else {
                            //fetch system info exchange rate

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            const convertedAmount = amount/exchangeRate;

                            usdTermStore.push(convertedAmount);
                        }
                    }
                });

                sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                if(sumOfODInUSD !== 0){
                    const  currency = "USD";

                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency,
                    });

                    sumOfODInUSD = formatter.format(sumOfODInUSD);
                }

                //sum of customers
                if(overdueTerms.length !== 0){
                    let customerStore = [];
                    overdueTerms.map(term => {
                        customerStore.push(term);
                    });

                    let distinctCustomers = {};

                    customerStore.map(term => {
                        if(`${term.customerID}` in distinctCustomers) {
                            //customer already in customers object
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                //grab terms from customer object
                                let terms = distinctCustomers[`${term.customerID}`].terms;
                                terms.push(term);

                                distinctCustomers[`${term.customerID}`].terms = terms;
                            }
                        } else {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                distinctCustomers[`${term.customerID}`] = {
                                    customerID: term.customerID,
                                    customerName: term.customerName,
                                    terms: [term]
                                }
                            }
                        }
                    });

                    totalCustomers = Object.keys(distinctCustomers).length;
                }

                // console.log(totalCustomers, sumInUsd, sumLCY, sumOfODInUSD )

                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "date");

                    sortedDates.map(data => {
                        const allBeforeTerms = data.allBeforeTerms;
                        const date = data.date;
                        const terms = data.terms;
                        const termsStore = data.termsStore;

                        const res = getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((dashboardData) => {
                        // here is our array of dashboard data
                        if(dashboardData.length !== 0){

                            dashboardData.push({ endOfMonth: "Grand Total", totalLCY: sumLCY, totalDebitInUSD: sumInUsd, totalInUsd: sumOfODInUSD, numberOfCustomers: totalCustomers, daysRange: "", bucket: "", percent: "" });
                            exportDashboardData({dashboardData, dispatch, date});
                            
                        }else{
                            message.info("There is no data to generate report");
                            dispatch({ type: ALL_REPORT_FAILED });
                        }
                    })

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {

            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
}

async function exportDashboardData({dashboardData, dispatch, date}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const dash = `DASHBOARD AS OF ${date}`;
    let title = [{A: dash},{}];

    let table1 = [
        {
            A: "MONTHS",
            B: "SUM OF DEBIT AMOUNT LCY",
            C: "SUM OF DEBIT AMOUNT USD",
            D: "SUM OF OD REPORTING USD",
            E: "NUMBER OF CUSTOMERS",
            F: "AGEING",
            G: "BUCKET",
            H: "TOTAL OD PER BUCKET"
        }
    ];

    dashboardData.forEach(data => {
        table1.push({
            A: data.endOfMonth,
            B: data.totalLCY,
            C: data.totalDebitInUSD,
            D: data.totalInUsd,
            E: data.numberOfCustomers,
            F: data.daysRange,
            G: data.bucket,
            H: data.percent
        })
    })

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Dashboard');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'MONTHS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:H2',
        tbodyRange: `A2:H${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:H${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }

    addStylesDashboard(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "dashboard.xlsx");
    // dispatch({ type: ALL_REPORT_SUCCESSFUL });
}

const addStylesDashboard = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'dashboard.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

// async function getDashBoardReport(){
//
//     const systemRef = firebase.firestore().collection("system").doc("info");
//     const systemDoc = await systemRef.get();
//     let successArray = [];
//
//
//     if(systemDoc.exists){
//
//         const systemInfo = systemDoc.data();
//         const localCurrency = systemInfo.defaultCurrency;
//         const url = `${project.serverUrl}fetchAllBeforeTerms`;
//         fetch(url, {
//             method: 'GET',
//             mode: 'cors',
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((allBeforeTerms) => {
//
//                 const beforeurl = `${project.serverUrl}fetchMasterListNew`;
//                 fetch(beforeurl, {
//                     method: 'GET',
//                     mode: 'cors',
//                     headers: {'Content-Type': 'application/json'},
//                 }).then((response) => response.json())
//                     .then((response) => {
//
//                         let dates = {};
//
//                         response.forEach(term => {
//                             let seconds;
//                             if ("transactionDate" in term) {
//                                 if (term.transactionDate) {
//                                     term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
//                                 } else {
//                                     term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//                                 }
//                             } else {
//                                 term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
//                             }
//
//                             const dueDate = moment.unix(seconds);
//
//                             //put term in current month bucket
//                             //grab the month and year
//                             const dueDateID = dueDate.format("MMYYYY");
//
//                             //check if dueDateID already exists in dates object
//                             if (`${dueDateID}` in dates) {
//                                 //terms with this date already exist
//                                 let terms = dates[`${dueDateID}`].terms;
//                                 terms.push(term);
//
//                                 dates[`${dueDateID}`].terms = terms;
//                             } else {
//                                 //its a new date so create new object for it
//                                 dates[`${dueDateID}`] = {
//                                     date: dueDate,
//                                     terms: [term],
//                                     allBeforeTerms,
//                                     termsStore: response
//                                 }
//                             }
//                         })
//
//                             //FIND GRAND CONTENTS
//                             let totalCustomers;
//                             let sumInUsd;
//                             let sumLCY;
//                             let sumOfODInUSD;
//
//                             //sum of debit in local currency
//                             let storeTerms = [];
//                             if (allBeforeTerms.length !== 0) {
//                                 allBeforeTerms.map(term => {
//                                     if (term.cheque) {
//                                         if (term.chequeStatus === "bounced") {
//                                             if (term.currency !== "usd") {
//                                                 storeTerms.push(term.amount);
//                                             }
//                                         }
//                                     } else {
//                                         if (term.currency !== "usd") {
//                                             storeTerms.push(term.amount);
//                                         }
//                                     }
//                                 });
//
//                                 sumLCY = storeTerms.reduce((a, b) => a + b, 0);
//                                 if(sumLCY !== 0){
//
//                                     const formatter = new Intl.NumberFormat('en-US', {
//                                         style: 'currency',
//                                         currency: localCurrency,
//                                     });
//
//                                     sumLCY = formatter.format(sumLCY);
//                             }
//                         }
//
//                             //sum of debit in usd format
//                             let toalInUSD = [];
//                             if (allBeforeTerms.length !== 0) {
//                                 allBeforeTerms.map(term => {
//                                     //filter if they are cleared or not
//                                     if (term.cheque) {
//                                         if (term.chequeStatus === "bounced") {
//                                             if (term.currency === "usd") {
//                                                 toalInUSD.push(term.amount);
//                                             }
//                                         }
//                                     } else {
//                                         if (term.currency === "usd") {
//                                             toalInUSD.push(term.amount);
//                                         }
//                                     }
//                                 });
//
//                                 sumInUsd = toalInUSD.reduce((a, b) => a + b, 0);
//                                     const  currency = "USD";
//
//                                     const formatter = new Intl.NumberFormat('en-US', {
//                                         style: 'currency',
//                                         currency,
//                                     });
//
//                                     sumInUsd = formatter.format(sumInUsd);
//                         }
//
//                         //sum of OD in usd
//                             let usdTermStore = [];
//                             response.map(term => {
//                                 //check that term is not cleared
//                                 if (term.termStatus.status) {
//                                     //term is cleared
//                                     //do nothing
//                                 } else {
//                                     //if loan is in usd convert the overdue to default currency
//                                     if (term.currency === "usd") {
//                                         //grab the total overdue
//                                         let amount;
//                                         if ("modulo" in term) {
//                                             amount = term.amount - term.modulo;
//                                         } else {
//                                             amount = term.amount;
//                                         }
//
//                                         usdTermStore.push(amount);
//                                     } else {
//                                         //fetch system info exchange rate
//                                         const exchangeRate = systemInfo.exchangeRate;
//
//                                         //grab the total overdue
//                                         let amount;
//                                         if ("modulo" in term) {
//                                             amount = term.amount - term.modulo;
//                                         } else {
//                                             amount = term.amount;
//                                         }
//
//                                         const convertedAmount = amount/exchangeRate;
//
//                                         usdTermStore.push(convertedAmount);
//                                     }
//                                 }
//                             });
//
//                             sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
//                             if(sumOfODInUSD !== 0){
//                                 const  currency = "USD";
//
//                                 const formatter = new Intl.NumberFormat('en-US', {
//                                     style: 'currency',
//                                     currency,
//                                 });
//
//                                 sumOfODInUSD = formatter.format(sumOfODInUSD);
//                             }
//
//                         //sum of customers
//                         if(response.length !== 0){
//                             let customerStore = [];
//                             response.map(term => {
//                                 customerStore.push(term);
//                             });
//
//                             let distinctCustomers = {};
//
//                             customerStore.map(term => {
//                                 if(`${term.customerID}` in distinctCustomers) {
//                                     //customer already in customers object
//                                     //check that term is not cleared
//                                     if (term.termStatus.status) {
//                                         //term is cleared do nothing
//                                     } else {
//                                         //grab terms from customer object
//                                         let terms = distinctCustomers[`${term.customerID}`].terms;
//                                         terms.push(term);
//
//                                         distinctCustomers[`${term.customerID}`].terms = terms;
//                                     }
//                                 } else {
//                                     //check that term is not cleared
//                                     if (term.termStatus.status) {
//                                         //term is cleared do nothing
//                                     } else {
//                                         distinctCustomers[`${term.customerID}`] = {
//                                             customerID: term.customerID,
//                                             customerName: term.customerName,
//                                             terms: [term]
//                                         }
//                                     }
//                                 }
//                             });
//
//                              totalCustomers = Object.keys(distinctCustomers).length;
//                         }
//
//                         // console.log(totalCustomers, sumInUsd, sumLCY, sumOfODInUSD )
//
//                         //for examining each cheque contents
//                         if (!(_.isEmpty(dates))) {
//                             ///extract date into array so it can be sorted
//                             const datesArray = _.map(dates, date => date);
//                             const sortedDates = arraySort(datesArray, "date");
//
//                             sortedDates.map(data => {
//                                 const allBeforeTerms = data.allBeforeTerms;
//                                 const date = data.date;
//                                 const terms = data.terms;
//                                 const termsStore = data.termsStore;
//
//                                 const res = getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency });
//                                 successArray.push(res);
//
//                             })
//
//                             Promise.all(successArray).then((dashboardData) => {
//
//                                 // here is our array of dashboard data
//                                 if(dashboardData.length !== 0){
//
//                                     dashboardData.push({ endOfMonth: "Grand Total", totalLCY: sumLCY, totalDebitInUSD: sumInUsd, totalInUsd: sumOfODInUSD, numberOfCustomers: totalCustomers, daysRange: "", bucket: "", percent: "" });
//
//                                     const report = dashboardData.map(cheque => {
//
//                                         return {
//                                             MONTHS: cheque.endOfMonth,
//                                             SUM_OF_DEBIT_AMOUNT_LCY: cheque.totalLCY,
//                                             SUM_OF_DEBIT_AMOUNT_USD: cheque.totalDebitInUSD,
//                                             SUM_OF_OD_REPORTING_USD: cheque.totalInUsd,
//                                             NUMBER_OF_CUSTOMERS: cheque.numberOfCustomers,
//                                             AGEING: cheque.daysRange,
//                                             BUCKET: cheque.bucket,
//                                             TOTAL_OD_PER_BUCKET: cheque.percent
//                                         }
//                                     });
//
//                                         // /* create a new blank workbook */
//                                         const wb = XLSX.utils.book_new();
//
//                                         //change data from objects to sheets
//                                         const ws = XLSX.utils.json_to_sheet(report, { header:["MONTHS","SUM_OF_DEBIT_AMOUNT_LCY","SUM_OF_DEBIT_AMOUNT_USD","SUM_OF_OD_REPORTING_USD","NUMBER_OF_CUSTOMERS","AGEING","BUCKET", "TOTAL_OD_PER_BUCKET"] });
//
//                                         //set the column widths
//                                         ws['!cols'] = [
//                                             {wch:25},
//                                             {wch:25},
//                                             {wch:25},
//                                             {wch:25},
//                                             {wch:15},
//                                             {wch:15},
//                                             {wch:15},
//                                             {wch:15},
//                                         ];
//
//                                         //append the sheet into the workbook
//                                         XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
//
//                                         XLSX.writeFile(wb, `OD_dashboard.xlsx`);
//                                                     }
//                                                 })
//                         }
//
//                     }).catch((error) => {
//                     console.log("Here's your error");
//                     console.log(error);
//                 })
//
//             }).catch((error) => {
//             console.log("Here's your error");
//             console.log(error);
//         })
//     }
// };

async function getBucket({ date, allBeforeTerms, terms, termsStore, localCurrency }){

    const endOfMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

    const bucket =Math.round(fromNow/30);

    return getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency })

}

async function getNumberOfCustomers({ bucket, date, allBeforeTerms, terms, termsStore, localCurrency }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency })
}

async function getODPercents({ numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency }){

    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    if(systemDoc.exists){
        const systemInfo = systemDoc.data();

        let store = [];
        let totalStore = [];
    
        //find total of terms within this month
        terms.map(term => {
    
            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    store.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;
    
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    const convertedAmount = amount/exchangeRate;
    
                    store.push(convertedAmount);
                }
            }
        });
    
    
        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);
    
        //find total of terms within for all overdue loans
        termsStore.map(term => {
    
            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    totalStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;
    
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    const convertedAmount = amount/exchangeRate;
    
                    totalStore.push(convertedAmount);
                }
            }
        });
    
    
        //calculate the total amount from numbers in the array
        const allTotal = totalStore.reduce((a, b) => a + b, 0);
    
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const ODPercent = (total/allTotal) * 100;
        let percent = roundAccurately(ODPercent, 2);

        return renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency })
        
    }
}

async function renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, localCurrency }){

    const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

    return renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, localCurrency })
}

async function renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, localCurrency }){
    //grab the end of the month
    const endMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endMonth, 'days');

    const buckets = Math.round(fromNow/30);

    let daysRange = "(0)";

    //compute date range depending on bucket
    if(buckets !== 0) {
        const endDate = buckets * 30;
        const startDate = endDate - 29;

        daysRange = `(${startDate}-${endDate})`
    }

    return renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
}

async function renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalInUsd = store.reduce((a, b) => a + b, 0);

    if(totalInUsd !== 0){
        const  currency = "USD";

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });
    
        totalInUsd = formatter.format(totalInUsd);

        return renderDebitInUSD({ totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    } else {
        return renderDebitInUSD({ totalInUsd: "", systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    }
}

async function renderDebitInUSD({ totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
    
    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency === "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                //check if term has penal interest
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency === "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalDebitInUSD = store.reduce((a, b) => a + b, 0);

    if(totalDebitInUSD !== 0){
        const  currency = "USD";

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });
    
        totalDebitInUSD = formatter.format(totalDebitInUSD);

        return renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    } else {
        return renderDebitAmountLCY({ totalDebitInUSD: "", totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    }
}

async function renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
           //create the terms amount store
           let store = [];

           //take all terms with tzs
           allBeforeTerms.map(term => {
               //filter if they are cleared or not
               if (term.cheque) {
                   if (term.chequeStatus === "bounced") {
                       let seconds;
                       if ("transactionDate" in term) {
                           if (term.transactionDate) {
                               term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                           } else {
                               term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                           }
                       } else {
                           term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                       }
                       const termDueDate = moment.unix(seconds);
                       if (termDueDate.isSame(date, "months")) {
                           if (term.currency !== "usd") {
                               store.push(term.amount);
                           }
                       }
                   }
               } else {
                   //check that dueDate month is similar to dueDate month in date object
                   let seconds;
                   term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                   const termDueDate = moment.unix(seconds);
                   if (termDueDate.isSame(date, "months")) {
                       if ("termStatus" in term) {
                           if (!term.termStatus.status) {
                               if (term.termStatus.penalInterest > 0) {
                                   if (term.currency !== "usd") {
                                       store.push(term.amount);
                                   }
                               }
                           }
                       }
                   }
               }
           });
   
           //calculate the total amount from numbers in the array
           let totalLCY = store.reduce((a, b) => a + b, 0);

           if(totalLCY !== 0){
        
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: localCurrency,
                });
            
                totalLCY = formatter.format(totalLCY);
            }

            return { totalLCY, totalInUsd, totalDebitInUSD, percent, numberOfCustomers, bucket, endOfMonth, daysRange }
}

export const DailyCustomerOverdueReportNew = ({reportDate}) => {

    return(dispatch) => {
        dispatch({type: ALL_REPORT})
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `bulk_smsNew_${date}`;

        const data = JSON.stringify({ reportID, path: "bulkSmsNewReport" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                if(response.length !== 0){
                    const resultArray = response.filter(function (el) {
                        return el != null;
                      });
                      exportOverDueCustomersToExcell({resultArray, date, dispatch})
                }else{
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }
            }).catch((error) => {
                dispatch({ type: ALL_REPORT_FAILED });    
                console.log(error);
        })
    }
};

async function exportOverDueCustomersToExcell({resultArray, date, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let bulk = `BULK SMS REPORT AS OF ${date}`;
    let title = [{A: bulk}, {}];

    let table1 = [
        {
            A: "NAME",
            B: "OD IN DEFAULT CURRENCY",
            C: "PHONE NUMBER",
            D: "PENAL INTEREST"
        }
    ];

    if(resultArray.length !== 0){
        resultArray.forEach(data => {
            if(data.penalInterest === 0){
                table1.push({
                    A: data.customerName,
                    B: data.overdue,
                    C: data.phoneNumber,
                    D: ""
                })  
            }else{
                table1.push({
                    A: data.customerName,
                    B: data.overdue,
                    C: data.phoneNumber,
                    D: data.penalInterest
                })
            }
        })
    }

    const finalData1 = [...title, ...table1];

    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    ws1['!cols'] = [
        {wch:40},
        {wch:30},
        {wch:25},
        {wch:25},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'Bulk SMS');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    // const footerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'NAME' ? headerIndex.push(index) : null )
    // finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:D2',
        tbodyRange: `A2:D${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:D${headerIndex[0] + 1}` : null,
        // theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }

    addStylesBulkSMS(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "bulk_sms_report.xlsx");
    // dispatch({ type: ALL_REPORT_SUCCESSFUL });
}

const addStylesBulkSMS = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'bulksms.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const DailyNewCaseReportNew = ({reportDate}) => {

    return(dispatch) => {
        dispatch({type: ALL_REPORT})
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `new_caseNew_${date}`;

        const data = JSON.stringify({ reportID, path: "newCaseDailyNewReport" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                if(response.length !== 0){
                    const overdues = response.filter(function (el) {
                        return el != null;
                      });
                      exportCurrentMonthlyOverdue({overdues, reportDate, dispatch})
                }else{
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }
            }).catch((error) => {  
                console.log(error);
                dispatch({ type: ALL_REPORT_FAILED });
        })
    }
};

async function exportCurrentMonthlyOverdue({overdues, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const year = reportDate.format("YYYY");
    const month = reportDate.format("MMMM");
    const newCase = `NEW CASES - ${month} ${year}`;

    let title = [{A: newCase},{}];

    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "CUSTOMER NAME",
            C: "OVERDUE IN USD",
        }
    ];

    overdues.forEach(data => {
        let date;
        if(data.transDate === "Grand Total"){
            date = "Grand Total";
        }else{
            date = `${data.transDate} ${data.transYear}`;
        }

        table1.push({
            A: date,
            B: data.customerName,
            C: data.overdue,
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:35},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'New Case');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:C${footerIndex[0] + 1}` : null,

    }

    addStylesNewCase(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "new_case_report.xlsx");
    // dispatch({ type: ALL_REPORT_SUCCESSFUL });
}

const addStylesNewCase = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'newcase.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const DailyRolledOverReportNew = ({reportDate}) => {
    return(dispatch) => {
        dispatch({type: ALL_REPORT})
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `rolled_over_last_MonthNew_${date}`;
        console.log(reportID)


        const data = JSON.stringify({ reportID, path: "rolledOverLastMonthNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                if(response.length !== 0){
                    const overdues = response.filter(function (el) {
                        return el != null;
                      });
                      exportRolledOverOverdue({overdues, reportDate, dispatch})
                }else{
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }
            }).catch((error) => {
                dispatch({ type: ALL_REPORT_FAILED });    
                console.log(error);
        })
    }
};

async function exportRolledOverOverdue({overdues, reportDate, dispatch}){

    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const date = new Date(reportDate);
    const firstD = new Date(date.getFullYear(), date.getMonth() - 1, 2);
    const year = moment(firstD).format('YYYY')
    const month = moment(firstD).format('MMMM')

    // const month = reportDate.subtract(1, "month").format('MMMM')
    // const year  = reportDate.format('YYYY');
    const rollover = `ROLL OVER FROM ${month} ${year}`;

    let title = [{A: rollover}, {}];

    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "CUSTOMER NAME",
            C: "OVERDUE IN USD",
        }
    ];

    overdues.forEach(data => {
        let date;
        if(data.transDate === "Grand Total"){
            date = "Grand Total";
        }else{
            date = `${data.transDate} ${data.transYear}`;
        }

        table1.push({
            A: date,
            B: data.customerName,
            C: data.overdue,
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:35},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'rolled_over');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:C${footerIndex[0] + 1}` : null,

    }

    addStyles(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, "rolled_overdue_report.xlsx");
    // dispatch({ type: ALL_REPORT_SUCCESSFUL });
}

const addStyles = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'rolled_over.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


export const generateCreateReport = (reportDate) => {

    return(dispatch) => {
        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `cash_collections_${date}`;

        const data = JSON.stringify({ reportID, path: "cashCollections" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //create banks store
                    let banks = {};

                    //change objects in array to fit excel headers
                    const dataArray = response.map(row => {

                        //change timestamp into date
                        let seconds;
                        row.bankDate.seconds ? seconds = row.bankDate.seconds : seconds = row.bankDate._seconds;

                        const rowDate = moment.unix(seconds);
                        const bankDate = rowDate.toDate();

                        //create row
                        const rowData = {
                            NAME: row.customerName,
                            PAID: row.paidAmount,
                            DATE: bankDate,
                            COMMENT: row.comment.comments
                        };

                        //find if bank name already exists
                        if (`${row.bankName}` in banks) {
                            //bank is there
                            //grab its values array and push in the row
                            let valuesArray = banks[`${row.bankName}`].values;
                            valuesArray.push(rowData);
                            banks[`${row.bankName}`].values = valuesArray;
                        } else {
                            //new bank
                            //create its property in banks object and assign bank name and values array
                            banks[`${row.bankName}`] = {
                                bank: row.bankName,
                                values: [rowData]
                            }
                        }

                        return banks;
                    });

                    //put banks in an array
                    const data = _.map(dataArray[0], bank => bank);

                    //create new sheet for every bank
                    data.forEach(bank => {

                        //change data from objects to sheets
                        const ws = XLSX.utils.json_to_sheet(bank.values, { header:["NAME","PAID","DATE","COMMENT"] });

                        //set the column widths
                        ws['!cols'] = [
                            {wch:55},
                            {wch:25},
                            {wch:25},
                            {wch:45},
                        ];

                        //append the sheet into the workbook
                        XLSX.utils.book_append_sheet(wb, ws, `${bank.bank}`);
                    });

                    //change date to string
                    XLSX.writeFile(wb, `Payment_Received_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                 
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        })
    }

};


export const generateCreateUnIdentifiedPaymentsReport = (reportDate) => {
    return(dispatch) => { 
        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `unidentified_${date}`;

        const data = JSON.stringify({ reportID, path: "unidentifiedReports" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                const url = `${project.serverUrl}unidentifiedReport`;
                //invoke custom database function
                fetch(url, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {'Content-Type': 'application/json'},
                }).then((response) => response.json())
                    .then((response) => {

                        if (response.length !== 0) {

                            //structure the data
                            const report = response.map(payment => {

                                let seconds;
                                payment.unidentifiedBankDate.seconds ? seconds = payment.unidentifiedBankDate.seconds : seconds = payment.unidentifiedBankDate._seconds;
                                const rowDate = moment.unix(seconds);
                                const bankDate = rowDate.toDate();

                                return {
                                    CURRENCY: payment.currency,
                                    AMOUNT: payment.unidentifiedPaidAmount,
                                    BANK: payment.unidentifiedBankName,
                                    DATE: bankDate,
                                    COMMENT: payment.unidentifiedComments.comments
                                }
                            });

                            // /* create a new blank workbook */
                            const wb = XLSX.utils.book_new();

                            //change data from objects to sheets
                            const ws = XLSX.utils.json_to_sheet(report, { header:["CURRENCY","AMOUNT","BANK","DATE","COMMENT"] });

                            //set the column widths
                            ws['!cols'] = [
                                {wch:10},
                                {wch:15},
                                {wch:15},
                                {wch:15},
                                {wch:25},
                            ];

                            //append the sheet into the workbook
                            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                            //change date to string
                            XLSX.writeFile(wb, `Unidentified_Payments_Report_${date}.xlsx`);

                            dispatch({ type: ALL_REPORT_SUCCESSFUL });
                            
                        } else {
                            message.info("There is no data to generate report");
                            dispatch({ type: ALL_REPORT_FAILED });
                        }

                    }).catch((error) => {

                    console.log(error);
                    dispatch({ type: ALL_REPORT_FAILED });
                })
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);

        });
    }
};

export const generateCreateAnalyticsReport = ({reportDate, systemInfo}) => {
    return(dispatch) => {
        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `analytics_${date}`;
        const exchangeRate = systemInfo.exchangeRate;
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        firebase.firestore().collection('analyticsDailyReports').doc(reportID)
            .get()
            .then(doc => {
                if (doc.exists) {
                    const data = doc.data();
                    console.log(data)
                    //{ date, totalOverdue }
                    let row = {};
                    //set the date
                    row["date"]  = reportDate.format("DD/MM/YYYY");

                    _.map(data, (value, key) => {

                        switch (key) {
                            case "totalOverdue":
                                const totalOverdue = value;
                                row["totalOverdue"] = roundAccurately(totalOverdue, 2);
                                break;
                            case "pdcFtm":
                                const pdcFtm = value;
                                row["pdcFtm"] = roundAccurately(pdcFtm, 2);
                                break;
                            case "pdcTd":
                                const pdcTd = value;
                                row["pdcTd"] = roundAccurately(pdcTd, 2);
                                break;
                            case "bouncedTd":
                                const bouncedTd = value;
                                row["bouncedTd"] = roundAccurately(bouncedTd, 2);
                                break;
                            case "odCollTd":
                                const odCollTd = value;
                                row["odCollTd"] = roundAccurately(odCollTd, 2);
                                break;
                            case "tc":
                                const tc = value;
                                row["tc"] = roundAccurately(tc, 2);
                                break;
                            case "totalBook":
                                const totalBook = value;
                                row["totalBook"] = roundAccurately(totalBook, 2);
                                break;
                            case "nplPercent":
                                row["nplPercent"] = value;
                                break;
                            case "deliquencyRatio":
                                row["deliquencyRatio"] = value;
                                break;
                            case "collectionEfficiency":
                                row["collectionEfficiency"] = value;
                                break;
                            case "overdueCollectionEfficiency":
                                row["overdueCollectionEfficiency"] = value;
                                break;
                            case "dueCollectionPercent":
                                row["dueCollectionPercent"] = value;
                                break;
                            case "tcPercent":
                                row["tcPercent"] = value;
                                break;
                        }
                    });

                    const report = [row];
                    exportAnalytics({report, dispatch, reportDate});

                } else {
                    //theres no report for this date
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: ALL_REPORT_FAILED });
            })
    };
};

async function exportAnalytics({report, dispatch, reportDate}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let date = reportDate.format("DD/MM/YYYY");
    let anal = `ANALYTICS REPORT AS OF ${date}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "TOTAL OVERDUE",
            B: "PDC FTM",
            C: "PDC TD",
            D: "BOUNCED TD",
            E: "OD COLL TD",
            F: "TC",
            G: "TOTAL BOOK",
            H: "NPL PERCENT",
            I: "DELIQUENCY RATIO %",
            J: "COLLECTION EFFICIENCY %",
            K: "OD COLLECTION EFFICIENCY %",
            L: "DUE COLLECTIONS PERCENT",
            M: "TOTAL COLLECTIONS PERCENT",
            N: "DATE"
        }
    ];

    if(report.length !== 0){
        report.forEach(data => {
            table1.push({
                A: data.totalOverdue,
                B: data.pdcFtm,
                C: data.pdcTd,
                D: data.bouncedTd,
                E: data.odCollTd,
                F: data.tc,
                G: data.totalBook,
                H: data.nplPercent,
                I: data.deliquencyRatio,
                J: data.collectionEfficiency,
                K: data.overdueCollectionEfficiency,
                L: data.dueCollectionPercent,
                M: data.tcPercent,
                N: data.date
            })
        })
    }

    const finalData1 = [...title, ...table1];

    const wb = XLSX.utils.book_new();

    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    // set the column widths
    ws1['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'Analytics');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'TOTAL OVERDUE' ? headerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:N2',
        tbodyRange: `A2:N${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:N${headerIndex[0] + 1}` : null,
    }

    addStylesAnalytics(workbookBlob, dataInfo, dispatch);
}

const addStylesAnalytics = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'analytics.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const generateCreateServiceChargesReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `service_charge_${date}`;

        const data = JSON.stringify({ reportID, path: "serviceCharges" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    //structure the data
                    const report = response.map(charge => {

                        let seconds;
                        charge.serviceChargeDate.seconds ? seconds = charge.serviceChargeDate.seconds : seconds = charge.serviceChargeDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const serviceChargeDate = rowDate.toDate();

                        return {
                            AMOUNT: charge.serviceChargePaidAmount,
                            BANK: charge.serviceChargeBankName,
                            DATE: serviceChargeDate,
                            COMMENT: charge.serviceChargeComments
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["AMOUNT","BANK","DATE","COMMENT"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Service_Charges_Report_${date}.xlsx`);

                    dispatch({ type: ALL_REPORT_SUCCESSFUL });

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

export const generateCreateHeldChequesReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `held_cheques_${date}`;

        const data = JSON.stringify({ reportID, path: "heldCheques" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    //structure the data
                    const report = response.map(cheque => {

                        let seconds;
                        cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const bankDate = rowDate.toDate();

                        let newSeconds;
                        cheque.newDueDate.seconds ? newSeconds = cheque.newDueDate.seconds : newSeconds = cheque.newDueDate._seconds;
                        const newDate = moment.unix(newSeconds);
                        const newDueDate = newDate.toDate();

                        return {
                            NAME: cheque.customerName,
                            AMOUNT: cheque.amount,
                            CHEQUE_NAME: cheque.chequeName,
                            CHEQUE_NUMBER: cheque.chequeNumber,
                            BANK: cheque.bankName,
                            DATE: bankDate,
                            NEW_DATE: newDueDate,
                            STATUS: cheque.chequeStatus
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["NAME","AMOUNT","CHEQUE_NAME","CHEQUE_NUMBER","BANK","DATE","NEW_DATE", "STATUS"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:55},
                        {wch:25},
                        {wch:25},
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:25},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Held_Cheques_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL});
               
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }


            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

export const generateCreateEarlyClosureReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `early_liquidation_${date}`;

        const data = JSON.stringify({ reportID, path: "earlyLiquidation" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    //structure the data
                    const report = response.map(loan => {

                        return {
                            CUSTOMER_NAME: loan.customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: loan.totalPaid,
                            CURRENCY: loan.currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //change date to string
                    XLSX.writeFile(wb, `Early_Closure_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

export const generateCreateMatureLoansReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: CREATE_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `matured_loan_${date}`;

        const data = JSON.stringify({ reportID, path: "maturedLoans" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    //structure the data
                    const report = response.map(loan => {
                        const terms = loan.terms;
                        let totalArray = [];
                        let currency = "";
                        let customerName = "";
                        terms.map(term => {
                            currency = term.currency;
                            customerName = term.customerName;
                            totalArray.push(term.amount)
                        })


                        //calculate the total amount from numbers in the array
                        const total = totalArray.reduce((a, b) => a + b, 0);

                        return {
                            CUSTOMER_NAME: customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: total,
                            CURRENCY: currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Matured_Loans_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: CREATE_REPORT_SUCCESSFUL });
                    console.log(response);
                    dispatch({ type: CLEAR_REPORTS_MESSAGE });
                } else {
                    dispatch({
                        type: CREATE_REPORT_EMPTY,
                        payload: "No matured loans"
                    });
                    dispatch({ type: CLEAR_REPORTS_MESSAGE });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: CREATE_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: CLEAR_REPORTS_MESSAGE });

        });
    }
};

export const generateCreateRescheduledLoansReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `rescheduled_loan_${date}`;

        const data = JSON.stringify({ reportID, path: "rescheduledLoans" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {
                    //const today = moment().format("DD_MM_YYYY");

                    //structure the data
                    const report = response.map(loan => {

                        return {
                            CUSTOMER_NAME: loan.customerName,
                            LOAN_ID: loan.loanID,
                            AMOUNT_PAID: loan.totalPaid,
                            CURRENCY: loan.currency,
                        }
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //change data from objects to sheets
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:10},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Rescheduled_Loans_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });
       
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }


            }).catch((error) => {
        
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

// export const generateCreateMatureLoansReport = (reportDate) => {

//     return(dispatch) => {
//         dispatch({ type: ALL_REPORT });
//         const date = reportDate.format("DD_MM_YYYY");
//         const reportID = `matured_loan_${date}`;
//
//         const data = JSON.stringify({ reportID, path: "maturedLoans" });
//
//         const data = JSON.stringify({ reportID, path: "maturedLoans" });
//         //invoke custom database function
//         const url = `${project.serverUrl}downloadGeneratedReport`;
//         fetch(url, {
//             method: 'POST',
//             mode: 'cors',
//             body: data,
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((response) => {
//                 console.log("Here's your reports object");
//                 console.log(response);
//
//
//                 if (response.length !== 0) {
//                     //const today = moment().format("DD_MM_YYYY");
//
//                 if (response.length !== 0) {
//                     //const today = moment().format("DD_MM_YYYY");

//                     const report = response.map(loan => {
//                         const terms = loan.loanTerms;
//                         let totalArray = [];
//                         let currency = "";
//                         let customerName = "";
//                         terms.map(term => {
//                             currency = term.currency;
//                             customerName = term.customerName;
//                             totalArray.push(term.amount)
//                         })
//
//
//                         //calculate the total amount from numbers in the array
//                         const total = totalArray.reduce((a, b) => a + b, 0);
//


//                         //calculate the total amount from numbers in the array
//                         const total = totalArray.reduce((a, b) => a + b, 0);

//                         return {
//                             CUSTOMER_NAME: customerName,
//                             LOAN_ID: loan.loanID,
//                             AMOUNT_PAID: total,
//                             CURRENCY: currency,
//                         }
//                     });
//
//                     // /* create a new blank workbook */
//                     const wb = XLSX.utils.book_new();
//
//                     //change data from objects to sheets
//                     const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });
//

//                     // /* create a new blank workbook */
//                     const wb = XLSX.utils.book_new();

//                     //change data from objects to sheets
//                     const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER_NAME","LOAN_ID","AMOUNT_PAID","CURRENCY"] });

//                     //set the column widths
//                     ws['!cols'] = [
//                         {wch:25},
//                         {wch:15},
//                         {wch:15},
//                         {wch:10},
//                     ];
//
//                     //append the sheet into the workbook
//                     XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
//
//                     //change date to string
//                     XLSX.writeFile(wb, `Matured_Loans_Report_${date}.xlsx`);
//

//                     //append the sheet into the workbook
//                     XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

//                     //change date to string
//                     XLSX.writeFile(wb, `Matured_Loans_Report_${date}.xlsx`);
//                     console.log("Here's your report");
//                     dispatch({ type: ALL_REPORT_SUCCESSFUL });
//                 } else {
//                     message.info("There is no data to generate report");
//                     dispatch({ type: ALL_REPORT_FAILED });
//                 }

//             }).catch((error) => {
//             console.log(error);
//             dispatch({ type: ALL_REPORT_FAILED });
//         });
//     }

export const generateCreateMasterListReport = ({ reportDate, filteredLegalRepoStatus, filterBucket, systemInfo }) => {

    return(dispatch) => {
        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `masterlist_${date}`;

        const data = JSON.stringify({ reportID, path: "masterList" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {

                let termsStore = [];

                //filter loan by the bucket chosen
                //create filtered terms bucket
                let filteredTerms = [];

                _.map(bouncedCheques, client => {

                    //loop over all terms and allocate to respective loan
                    client.values.map(term => {
                        termsStore.push(term);
                    });

                    //filter by legal/repo status
                    //create legal repo filtered loans bucket (array)
                    let legalRepoFilteredLoans = [];

                    switch (filteredLegalRepoStatus) {
                        case "legal":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "legal");
                            break;
                        case "repo":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "repo");
                            break;
                        case "legalRepo":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "legalRepo");
                            break;
                        case "released":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "released");
                            break;
                        case "underFollowUp":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "underFollowUp");
                            break;
                        case "customerAbsconding":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "customerAbsconding");
                            break;
                        case "assetNonTraceable":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "assetNonTraceable");
                            break;
                        case "auctioned":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "auctioned");
                            break;
                        case "nonStarter":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "nonStarter");
                            break;
                        case "others":
                            legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "others");
                            break;
                        default:
                            legalRepoFilteredLoans = termsStore.map(term => term);
                            break;
                    }

                    switch (filterBucket) {
                        case "1":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 1);
                            break;
                        case "2":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 2);
                            break;
                        case "3":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 3);
                            break;
                        case "4":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 4);
                            break;
                        case "5":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 5);
                            break;
                        case "6":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 6);
                            break;
                        case "7":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 7);
                            break;
                        case "8":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 8);
                            break;
                        case "9":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 9);
                            break;
                        case "10":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 10);
                            break;
                        case "11":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 11);
                            break;
                        case "12":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 12);
                            break;
                        case "13":
                            filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 13);
                            break;
                        default:
                            filteredTerms = legalRepoFilteredLoans.map(term => term);
                            break;
                    }
                });

                //clients and their loans are computed
                //clients

                //check that clients object is not empty
                if (filteredTerms.length !== 0) {
                    //create excel report
                    const today = moment().format("DD_MM_YYYY");

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //create report bucket to store all the excel rows as objects
                    let report = [];

                    console.log({filteredTerms: filteredTerms.length});
                    filteredTerms.map(term => {
                        let termRow = {};

                        //find comment
                        let comment = "";
                        if("masterListComment" in term){
                            comment = term.masterListComment.newComment;
                        }

                        //
                        const formatter = new Intl.NumberFormat('en-US' );

                        //calculate transaction date
                        let seconds;
                        if ("transactionDate" in term) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                        }

                        const dueDate = moment.unix(seconds);
                        //calculate OG Due Date
                        let ogDueDateSeconds;
                        term.dueDate.seconds ? ogDueDateSeconds = term.dueDate.seconds : ogDueDateSeconds = term.dueDate._seconds;
                        const ogDueDate = moment.unix(ogDueDateSeconds);

                        //calculate debit amount in tzs and usd
                        let debitAmountTZS;
                        let debitAmountShilling;
                        let debitAmountUSD;
                        let debitAmountDollar;

                        if (term.currency === "usd") {
                            //
                            debitAmountDollar = term.amount;
                            //
                            if (debitAmountDollar){

                                debitAmountUSD = formatter.format(debitAmountDollar);
                            } else {
                                debitAmountUSD = "";
                            }

                        } else {
                            //
                            debitAmountShilling = term.amount;

                            //check if debit amount in shillings is not empty then format
                            if (debitAmountShilling){

                                debitAmountTZS = formatter.format(debitAmountShilling);
                            } else {
                                debitAmountTZS = "";
                            }
                        }

                        //calculate first demand notice
                        let fistDemandNoticeSeconds;
                        let firstDemandNotice;
                        if ("firstDemandNotice" in term) {
                            term.firstDemandNotice.seconds ? fistDemandNoticeSeconds = term.firstDemandNotice.seconds : fistDemandNoticeSeconds = term.firstDemandNotice._seconds;
                            //
                            firstDemandNotice = moment.unix(fistDemandNoticeSeconds).toDate();
                        } else {
                            firstDemandNotice = "";
                        }

                        //calculate second demand notice
                        let secondDemandNoticeSeconds;
                        let secondDemandNotice;
                        if ("secondDemandNotice" in term) {
                            term.secondDemandNotice.seconds ? secondDemandNoticeSeconds = term.secondDemandNotice.seconds : secondDemandNoticeSeconds = term.secondDemandNotice._seconds;
                            //
                            secondDemandNotice = moment.unix(secondDemandNoticeSeconds).toDate();
                        } else {
                            secondDemandNotice = "";
                        }

                        let otherReason;
                        if ("otherReason" in term) {
                            //
                            otherReason = term.otherReason;
                        } else {
                            otherReason = "";
                        }

                        //assignee
                        let assignee = "";
                        if ("assignee" in term) {
                            assignee = term.assignee.name;
                        }

                        //calculate overdue in tzs and usd
                        let overdueTZS;
                        let overdueShilling;
                        let overdueUSD;
                        let overdueDollar;

                        if (term.currency === "usd") {
                            //
                            if ("modulo" in term) {
                                //render modulo
                                //extract payment and modulo
                                const modulo = term.modulo;
                                const amount = term.amount;

                                overdueDollar = amount - modulo;
                            } else {
                                //render overdue
                                //check if term is cleared or not
                                if (term.termStatus.status) {
                                    overdueDollar = 0;
                                } else {
                                    overdueDollar = term.amount;
                                }
                            }
                            //
                            if (overdueDollar){

                                overdueUSD = formatter.format(overdueDollar);
                            } else {
                                overdueUSD = "";
                            }

                        } else {
                            //
                            if ("modulo" in term) {
                                //render modulo
                                //extract payment and modulo
                                const modulo = term.modulo;
                                const amount = term.amount;

                                overdueShilling = amount - modulo;
                            } else {
                                //render overdue
                                //check if term is cleared or not
                                if (term.termStatus.status) {
                                    overdueShilling = 0;
                                } else {
                                    overdueShilling = term.amount;
                                }
                            }
                            //check if overdue in shillings is not empty then format
                            if (overdueShilling){

                                overdueTZS = formatter.format(overdueShilling);
                            } else {
                                overdueTZS = "";
                            }
                        }

                        //calculate bucket
                        const now = moment();
                        const fromNow = now.diff(dueDate, 'days');
                        const bucket = Math.ceil(fromNow / 30);

                        // //calculate OD SUM
                        let totalOD = 0;

                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            //check if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                if ("modulo" in term) {
                                    totalOD = term.amount - term.modulo;
                                } else {
                                    totalOD = term.amount;
                                }

                            } else {
                                //fetch system info exchange rate
                                const exchangeRate = systemInfo.exchangeRate;

                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }

                                totalOD = amount / exchangeRate;
                            }
                        }

                        termRow[`dueDate`] = dueDate.toDate();
                        termRow[`customerName`] = term.customerName;
                        termRow[`assignee`] = assignee;
                        termRow[`salesExe`] = term.salesExe;
                        termRow[`chequeNumber`] = term.chequeNumber;
                        termRow[`debitAmountTZS`] = debitAmountTZS;
                        termRow[`debitAmountUSD`] = debitAmountUSD;
                        termRow[`bouncedReason`] = term.bouncedReason;
                        termRow[`otherReason`] = otherReason;
                        termRow[`overdueTZS`] = overdueTZS;
                        termRow[`overdueUSD`] = overdueUSD;
                        termRow[`character`] = term.character;
                        termRow[`clientAction`] = term.clientAction;
                        termRow[`legalRepoStatus`] = term.legalRepoStatus;
                        termRow[`comment`] = comment;
                        termRow[`fromNow`] = fromNow;
                        termRow[`bucket`] = bucket;
                        termRow[`clientProfile`] = term.clientProfile;
                        termRow[`truck`] = term.truck;
                        termRow[`industry`] = term.industry;
                        termRow[`model`] = term.model;
                        termRow[`segment`] = term.segment;
                        termRow[`totalOD`] = totalOD;
                        termRow[`ogDueDate`] = ogDueDate.toDate();
                        termRow[`firstDemandNotice`] = firstDemandNotice;
                        termRow[`secondDemandNotice`] = secondDemandNotice;
        
                        report.push(termRow);
                    });

                exportODAging({report, dispatch, reportDate});

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            dispatch({ type: ALL_REPORT_FAILED });
            console.log("Here's your error");
            console.log(error);
        });
    }
};

async function exportODAging({report, dispatch, reportDate}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    let date = reportDate.format("DD/MM/YYYY");
    let anal = `OD AGING REPORT AS OF ${date}`;
    let title = [{A: anal}, {}];
    let table1 = [
        {
            A: "TRANSACTION DATE",
            B: "NAME",
            C: "COLLECTOR",
            D: "SALES OFFICER",
            E: "CHEQUE NUMBER",
            F: "DEBIT AMOUNT IN LCY",
            G: "DEBIT AMOUNT IN USD",
            H: "BOUNCED REASON",
            I: "OTHERS SPECIFY",
            J: "OVERDUE IN LCY",
            K: "OVERDUE IN USD",
            L: "CHARACTER",
            M: "ACTION",
            N: "STATUS",
            O: "COMMENT",
            P: "DAYS",
            Q: "AGE",
            R: "PROFILE",
            S: "TRACK",
            T: "INDUSTRY",
            U: "MODEL",
            V: "SEGMENT",
            W: "OD REPORTING USD",
            X: "DUE DATE",
            Y: "FIRST DEMAND NOTICE",
            Z: "SECOND DEMAND NOTICE",
        }
    ];
    if(report.length !== 0){
        report.forEach(data => {
            table1.push(
                {
                    A: data.dueDate,
                    B: data.customerName,
                    C: data.assignee,
                    D: data.salesExe,
                    E: data.chequeNumber,
                    F: data.debitAmountTZS,
                    G: data.debitAmountUSD,
                    H: data.bouncedReason,
                    I: data.otherReason,
                    J: data.overdueTZS,
                    K: data.overdueUSD,
                    L: data.character,
                    M: data.clientAction,
                    N: data.legalRepoStatus,
                    O: data.comment,
                    P: data.fromNow,
                    Q: data.bucket,
                    R: data.clientProfile,
                    S: data.truck,
                    T: data.industry,
                    U: data.model,
                    V: data.segment,
                    W: data.totalOD,
                    X: data.ogDueDate,
                    Y: data.firstDemandNotice,
                    Z: data.secondDemandNotice,
                }
            );
        })
    }

    const finalData1 = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    //set the column widths
    ws1['!cols'] = [
        {wch: 25},
        {wch: 35},
        {wch: 25},
        {wch: 20},
        {wch: 25},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 30},
        {wch: 30},
    ];

    XLSX.utils.book_append_sheet(wb, ws1, 'OD Aging');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData1.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:Z2',
        tbodyRange: `A2:Z${finalData1.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:Z${headerIndex[0] + 1}` : null,
    }

    addStylesODAging(workbookBlob, dataInfo, dispatch);
}

const addStylesODAging = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'odAging.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}



export const generateCreateLastPaymentDateReport = (reportDate) => {
    //lastPaymentDateReport
    return(dispatch) => {
        dispatch({ type: ALL_REPORT });

        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `last_payment_${date}`;

        const data = JSON.stringify({ reportID, path: "lastPaymentDate" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                //check if our response is not empty
                if(response.length !== 0){

                    //const today = moment().format('DD_MM_YYYY');


                    //STRUCTURE THE DATA
                    const report = response.map(loan => {
                        //create row
                        const row = {};

                        if (!(_.isEmpty(loan))) {
                            //find transaction date
                            let seconds;
                            loan.transactionDate.seconds ? seconds = loan.transactionDate.seconds : seconds = loan.transactionDate._seconds;
                            const paymentDate = moment.unix(seconds);
                            const  transactionDate = paymentDate.toDate();

                            let mode;
                            loan.cheque ? mode = "Cheque" : mode = "Cash";


                            row['CUSTOMER NAME'] = loan.customerName;
                            row['LOAN ID'] = loan.loanID;
                            row['AMOUNT'] = loan.paidAmount;
                            row['TRANSACTION DATE'] = transactionDate;
                            row['MODE'] = mode;
                            row['CURRENCY'] = loan.currency;
                        }

                        return row;
                    });

                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();

                    //create excel sheet
                    const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER NAME","LOAN ID","AMOUNT","TRANSACTION DATE", "MODE", "CURRENCY"] });

                    //set the column widths
                    ws['!cols'] = [
                        {wch:25},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                        {wch:15},
                    ];

                    //append the sheet into the workbook
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

                    //change date to string
                    XLSX.writeFile(wb, `Last_Payment_Date_Report_${date}.xlsx`);

                    console.log("Here's your report");
                    dispatch({ type: ALL_REPORT_SUCCESSFUL });

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });               
                }

            }).catch((error) => {
   
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

export const generateCreateAgeingCustomerWiseReportNew = ({ reportDate, systemInfo }) => {
    return(dispatch) => {
        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `masterlist_${date}`;

        const data = JSON.stringify({ reportID, path: "masterList" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                console.log(bouncedCheques)
                createAgeingCustomerWiseReport({ bouncedCheques, systemInfo, dispatch, reportDate })
            }).catch((error) => {
            dispatch({ type: ALL_REPORT_FAILED });
            console.log(error);
        });
    }
};

async function createAgeingCustomerWiseReport({ bouncedCheques, systemInfo, reportDate, dispatch }){
    let resultArray = [];
    let successArray = [];
    try{
        dispatch({type: ALL_REPORT});
        let clients = {};
        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = [];
        let arr8 = [];
        let arr9 = [];
        let arr10 = [];
        let arr11 = [];
        let arr12 = [];


        //put all terms in a store
        const exchangeRate = systemInfo.exchangeRate;
        let termsStore = [];


        _.map(bouncedCheques, client => {
            client.values.map(term => {
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    termsStore.push(term);
                }
            });
        });

        let terms = {};
        termsStore.forEach(term => {

            if (`${term.customerID}` in clients) {
                let cterms = clients[`${term.customerID}`].cterms;
                cterms.push(term);
                clients[`${term.customerID}`].cterms = cterms;
            }else{
                clients[`${term.customerID}`] = {
                    cterms: [term],
                    customerID: term.customerID,
                    name: term.customerName
                }
            }
            
            let seconds;
            if ("transactionDate" in term) {
                if(term.transactionDate){
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                }else{
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            }


            //grab end of month of next month
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');

            const today = moment(reportDate);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
            const bucket = Math.round(fromNow/30);
        
            let daysRange = "(0)";
            //compute date range depending on bucket
            if(bucket !== 0) {
                const endDate = bucket * 30;
                const startDate = endDate - 29;

                daysRange = `(${startDate}-${endDate})`
            }

            if(bucket !== undefined && bucket !== null){
                if (`${daysRange}` in terms) {
                    let termBucket = terms[`${daysRange}`].terms;
                    termBucket.push(term);
                    terms[`${daysRange}`].terms = termBucket;

                } else {
                    terms[`${daysRange}`] = {
                        range: daysRange,
                        terms: [term]
                    }
                }
            }
        })


        const termsArr = _.map(terms, client => client );
        console.log(termsArr)

        termsArr.forEach(term => {
            if(term.range === "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr.push(amount);
                })
            }
            
            if(term.range === "(31-60)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr1.push(amount);
                })
            }
            
            if(term.range === "(61-90)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr2.push(amount);
                })
            }
            
            if(term.range === "(91-120)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr3.push(amount);
                })
            }
            
            if(term.range === "(121-150)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr4.push(amount);
                })
            }
            
            if(term.range === "(151-180)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr5.push(amount);
                })
            }
            
            if(term.range === "(181-210)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr6.push(amount);
                })
            }

            if(term.range === "(211-240)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr7.push(amount);
                })
            }
            
            if(term.range === "(241-270)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr8.push(amount);
                })
            }

            if(term.range === "(271-300)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr9.push(amount);
                })
            }
            
            if(term.range === "(301-330)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr10.push(amount);
                })
            }
            
            if(term.range === "(331-360)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr11.push(amount);
                })
            } 
            
            if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr12.push(amount);
                })
            }
        })
   

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        let bucket1 = arr.reduce((a, b) => a + b, 0);
        bucket1 = roundAccurately(bucket1, 2);
        let bucket2 = arr1.reduce((a, b) => a + b, 0);
        bucket2 = roundAccurately(bucket2, 2);
        let bucket3 = arr2.reduce((a, b) => a + b, 0);
        bucket3 = roundAccurately(bucket3, 2);
        let bucket4 = arr3.reduce((a, b) => a + b, 0);
        bucket4 = roundAccurately(bucket4, 2);
        let bucket5 = arr4.reduce((a, b) => a + b, 0);
        bucket5 = roundAccurately(bucket5, 2);
        let bucket6 = arr5.reduce((a, b) => a + b, 0);
        bucket6 = roundAccurately(bucket6, 2);
        let bucket7 = arr6.reduce((a, b) => a + b, 0);
        bucket7 = roundAccurately(bucket7, 2);
        let bucket8 = arr7.reduce((a, b) => a + b, 0);
        bucket8 = roundAccurately(bucket8, 2);
        let bucket9 = arr8.reduce((a, b) => a + b, 0);
        bucket9 = roundAccurately(bucket9, 2);
        let bucket10 = arr9.reduce((a, b) => a + b, 0);
        bucket10 = roundAccurately(bucket10, 2);
        let bucket11 = arr10.reduce((a, b) => a + b, 0);
        bucket11 = roundAccurately(bucket11, 2);
        let bucket12 = arr11.reduce((a, b) => a + b, 0);
        bucket12 = roundAccurately(bucket12, 2);
        let bucket13 = arr12.reduce((a, b) => a + b, 0);
        bucket13 = roundAccurately(bucket13, 2);
        const date = moment(reportDate).format("DD/MM/YYYY");
        const total = bucket1 + bucket2 + bucket3 + bucket4 + bucket5 + bucket6 + bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12 + bucket13;
        resultArray = [{name: date, bucket1, bucket2, bucket3, bucket4, bucket5, bucket6, bucket7, bucket8, bucket9, bucket10, bucket11, bucket12, bucket13, total}];

        if(!(_.isEmpty(clients))){
            _.map(clients, customer => {
                const res = getCustomerBucket({customer, exchangeRate, reportDate});
                successArray.push(res);
            })
        }
    } catch(e){
        console.log(e);
        dispatch({ type: ALL_REPORT_FAILED });
    }

    Promise.all(successArray).then((data) => {
        data = arraySort(data, "name");
        resultArray = resultArray.concat(data);
        exportCustomerWiseNew({resultArray, dispatch, reportDate});
    })
}

async function exportCustomerWiseNew({resultArray, dispatch, reportDate}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment(reportDate).format("DD/MM/YYYY");
    const bulk = `CUSTOMER WISE REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AGEING",
            B: "1-30",
            C: "31-60",
            D: "61-90",
            E: "91-120",
            F: "121-150",
            G: "151-180",
            H: "181-210",
            I: "211-240",
            J: "241-270",
            K: "271-300",
            L: "301-330",
            M: "331-360",
            N: ">360",
            O: "Grand Total"
        }
    ];

    resultArray.forEach(data => {
        table1.push({
            A: data.name,
            B: data.bucket1,
            C: data.bucket2,
            D: data.bucket3,
            E: data.bucket4,
            F: data.bucket5,
            G: data.bucket6,
            H: data.bucket7,
            I: data.bucket8,
            J: data.bucket9,
            K: data.bucket10,
            L: data.bucket11,
            M: data.bucket12,
            N: data.bucket13,
            O: data.total,
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Customer wise');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AGEING' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:O2',
        tbodyRange: `A2:O${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:O${headerIndex[0] + 1}` : null,
    }

    addStylesCustomerWise(workbookBlob, dataInfo, dispatch);

}

const addStylesCustomerWise = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'customerWise.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

async function getCustomerBucket({customer, exchangeRate, reportDate}){
    try{

        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = [];
        let arr8 = [];
        let arr9 = [];
        let arr10 = [];
        let arr11 = [];
        let arr12 = [];

        let terms = {};
        customer.cterms.forEach(term => {
            
            let seconds;
            if ("transactionDate" in term) {
                if(term.transactionDate){
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                }else{
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            }


            //grab end of month of next month
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');

            const today = moment(reportDate);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
            const bucket = Math.round(fromNow/30);
        
            let daysRange = "(0)";
            //compute date range depending on bucket
            if(bucket !== 0) {
                const endDate = bucket * 30;
                const startDate = endDate - 29;

                daysRange = `(${startDate}-${endDate})`
            }

            if(bucket !== undefined && bucket !== null){
                if (`${daysRange}` in terms) {
                    let termBucket = terms[`${daysRange}`].terms;
                    termBucket.push(term);
                    terms[`${daysRange}`].terms = termBucket;

                } else {
                    terms[`${daysRange}`] = {
                        range: daysRange,
                        terms: [term]
                    }
                }
            }
        })


        const termsArr = _.map(terms, client => client );

        termsArr.forEach(term => {
            if(term.range === "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr.push(amount);
                })
            }
            
            if(term.range === "(31-60)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr1.push(amount);
                })
            }
            
            if(term.range === "(61-90)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr2.push(amount);
                })
            }
            
            if(term.range === "(91-120)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr3.push(amount);
                })
            }
            
            if(term.range === "(121-150)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr4.push(amount);
                })
            }
            
            if(term.range === "(151-180)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr5.push(amount);
                })
            }
            
            if(term.range === "(181-210)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr6.push(amount);
                })
            }

            if(term.range === "(211-240)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr7.push(amount);
                })
            }
            
            if(term.range === "(241-270)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr8.push(amount);
                })
            }

            if(term.range === "(271-300)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr9.push(amount);
                })
            }
            
            if(term.range === "(301-330)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr10.push(amount);
                })
            }
            
            if(term.range === "(331-360)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr11.push(amount);
                })
            } 
            
            if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr12.push(amount);
                })
            }
        })

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        let bucket1 = arr.reduce((a, b) => a + b, 0);
        bucket1 = roundAccurately(bucket1, 2);
        let bucket2 = arr1.reduce((a, b) => a + b, 0);
        bucket2 = roundAccurately(bucket2, 2);
        let bucket3 = arr2.reduce((a, b) => a + b, 0);
        bucket3 = roundAccurately(bucket3, 2);
        let bucket4 = arr3.reduce((a, b) => a + b, 0);
        bucket4 = roundAccurately(bucket4, 2);
        let bucket5 = arr4.reduce((a, b) => a + b, 0);
        bucket5 = roundAccurately(bucket5, 2);
        let bucket6 = arr5.reduce((a, b) => a + b, 0);
        bucket6 = roundAccurately(bucket6, 2);
        let bucket7 = arr6.reduce((a, b) => a + b, 0);
        bucket7 = roundAccurately(bucket7, 2);
        let bucket8 = arr7.reduce((a, b) => a + b, 0);
        bucket8 = roundAccurately(bucket8, 2);
        let bucket9 = arr8.reduce((a, b) => a + b, 0);
        bucket9 = roundAccurately(bucket9, 2);
        let bucket10 = arr9.reduce((a, b) => a + b, 0);
        bucket10 = roundAccurately(bucket10, 2);
        let bucket11 = arr10.reduce((a, b) => a + b, 0);
        bucket11 = roundAccurately(bucket11, 2);
        let bucket12 = arr11.reduce((a, b) => a + b, 0);
        bucket12 = roundAccurately(bucket12, 2);
        let bucket13 = arr12.reduce((a, b) => a + b, 0);
        bucket13 = roundAccurately(bucket13, 2);
        const total = bucket1 + bucket2 + bucket3 + bucket4 + bucket5 + bucket6 + bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12 + bucket13;
        
        return {name: customer.name, bucket1, bucket2, bucket3, bucket4, bucket5, bucket6, bucket7, bucket8, bucket9, bucket10, bucket11, bucket12, bucket13, total};

    } catch(e){
        console.log(e);
    }
}

function computeTotalRow({ days, totalRow, amount }) {
    //check if (1-30)etc. already exists or not in total row
    if (`${days}` in totalRow) {
        //exists
        //grab amount
        let oldAmount = totalRow[`${days}`];
        //add oldAmount with new amount
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        totalRow[days] = roundAccurately((oldAmount + amount), 2);

        return totalRow;
    } else {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        totalRow[days] = roundAccurately(amount, 2);
        return totalRow;
    }
}

function computeHeaderRowAmount({ days, totalRow, term, systemInfo }) {
    //check if (1-30)etc. already exists or not in total row
    if (`${days}` in totalRow) {
        //exists
        //grab amount
        let oldAmount = totalRow[`${days}`];
        //add oldAmount with new amount
        //check if loan is in usd convert the overdue to default currency
        let amount = 0;
        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
        }

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        totalRow[days] = roundAccurately((oldAmount + amount), 2);

        return totalRow;
    } else {
        let amount = 0;

        if (term.currency === "usd") {
            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

        } else {
            //fetch system info exchange rate
            const exchangeRate = systemInfo.exchangeRate;

            //grab the total overdue
            if ("modulo" in term) {
                amount = term.amount - term.modulo;
            } else {
                amount = term.amount;
            }

            amount = amount/exchangeRate;
        }

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        totalRow[days] = roundAccurately(amount, 2);

        return totalRow;
    }
}

function assignDaysToRow({ oldestBucket, finalRow, grandTotal }) {
    let days;
    let row = finalRow;
    switch (oldestBucket) {
        case 1:
            days = "1-30";
            break;
        case 2:
            days = "31-60";
            break;
        case 3:
            days = "61-90";
            break;
        case 4:
            days = "91-120";
            break;
        case 5:
            days = "121-150";
            break;
        case 6:
            days = "151-180";
            break;
        case 7:
            days = "181-210";
            break;
        case 8:
            days = "211-240";
            break;
        case 9:
            days = "241-270";
            break;
        case 10:
            days = "271-300";
            break;
        case 11:
            days = "301-330";
            break;
        case 12:
            days = "331-360";
            break;
        default:
            days = ">360";
            break;
    }

    row[days] = grandTotal;
    return row;
}

export const generateCreateAgeingGroupBucketReportNew = ({reportDate, systemInfo}) => {

    return(dispatch) => {
        dispatch({ type: ALL_REPORT });
        const date = moment(reportDate).format("DD_MM_YYYY");
        const reportID = `masterlist_${date}`;

        const data = JSON.stringify({ reportID, path: "masterList" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                console.log("Here's your reports object");
                console.log(bouncedCheques);

                //create object to store all dates
                let dates = {};

                //put all terms in a store
                let termsStore = [];

                // {{ customerID, customerName, values: [{}, {}]}, {}}
                //loop over all client objects and extract its terms
                _.map(bouncedCheques, client => {
                    client.values.map(term => {
                        //check if term is after deployment date
                        // let migrationSeconds;
                        // systemInfo.migrationDate.seconds ? migrationSeconds = systemInfo.migrationDate.seconds : migrationSeconds = systemInfo.migrationDate._seconds;
                        // const migrationDate = moment.unix(migrationSeconds);
                        //
                        // let termSeconds;
                        // term.dueDate.seconds ? termSeconds = term.dueDate.seconds : termSeconds = term.dueDate._seconds;
                        // const termDate = moment.unix(termSeconds);
                        //
                        // if (termDate.isSameOrAfter(migrationDate)) {
                        //     if (term.termStatus.status) {
                        //         //term is cleared
                        //         //do nothing
                        //     } else {
                        //         termsStore.push(term);
                        //     }
                        // }
                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            termsStore.push(term);
                        }
                    });
                });

                //group all terms into unique dates
                //check that termsStore is not empty
                if (termsStore.length !== 0) {
                    termsStore.map(term => {
                        //grab the date and change it into a string
                        let seconds;
                        if ("transactionDate" in term) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                        }
                        const dueDate = moment.unix(seconds);
                        const today = moment(reportDate);
                        //grab the month and year
                        const dueDateID = today.format("MMYYYY");

                        //check if dueDateID already exists in dates object

                        //dateBuckets = { date: { date: date, terms: { 1: [], 2: [], 13: []} }
                        if (`${dueDateID}` in dates) {
                            //terms with this date already exist
                            let terms = dates[`${dueDateID}`].terms;

                            //calculate bucket
                            //grab the end of the month
                            //const endOfMonth = dueDate.endOf('month');

                            //grab today
                            //const today = moment();

                            //find the number of days from today
                            const fromNow = today.diff(dueDate, 'days');

                            //find the bucket
                            let bucket = 1;
                            switch (true) {
                                case (fromNow >= 1 && fromNow <= 30):
                                    bucket = 1;
                                    break;
                                case (fromNow >= 31 && fromNow <= 60):
                                    bucket = 2;
                                    break;
                                case (fromNow >= 61 && fromNow <= 90):
                                    bucket = 3;
                                    break;
                                case (fromNow >= 91 && fromNow <= 120):
                                    bucket = 4;
                                    break;
                                case (fromNow >= 121 && fromNow <= 150):
                                    bucket = 5;
                                    break;
                                case (fromNow >= 151 && fromNow <= 180):
                                    bucket = 6;
                                    break;
                                case (fromNow >= 181 && fromNow <= 210):
                                    bucket = 7;
                                    break;
                                case (fromNow >= 211 && fromNow <= 240):
                                    bucket = 8;
                                    break;
                                case (fromNow >= 241 && fromNow <= 270):
                                    bucket = 9;
                                    break;
                                case (fromNow >= 271 && fromNow <= 300):
                                    bucket = 10;
                                    break;
                                case (fromNow >= 301 && fromNow <= 330):
                                    bucket = 11;
                                    break;
                                case (fromNow >= 331 && fromNow <= 360):
                                    bucket = 12;
                                    break;
                                case (fromNow > 360):
                                    bucket = 13;
                                    break;
                            }

                            //check if bucket already exists
                            //dates = { dateID: { date: date, terms: { 1: [], 2: [] } }
                            if (`${bucket}` in terms) {
                                //grab the bucket from terms object and assign to new array (new array will have existing terms)
                                let termBucket = terms[`${bucket}`];

                                //push new term to new bucket array
                                termBucket.push(term);

                                //assign the bucket property in the original terms object with the new created termBucket
                                terms[`${bucket}`] = termBucket;

                                //assign the new objects as new values of the the existing terms object in the date object
                                dates[`${dueDateID}`].terms = terms;
                            } else {
                                terms[`${bucket}`] = [term];
                                dates[`${dueDateID}`].terms = terms;
                            }


                            //dates[`${dueDateID}`].terms = terms;
                        } else {
                            //its a new date so create new object for it
                            //calculate bucket
                            //grab the end of the month
                            //const endOfMonth = dueDate.endOf('month');

                            //grab today
                            //const today = moment();

                            //find the number of days from today
                            const fromNow = today.diff(dueDate, 'days');

                            //find the bucket
                            let bucket = 1;
                            switch (true) {
                                case (fromNow >= 1 && fromNow <= 30):
                                    bucket = 1;
                                    break;
                                case (fromNow >= 31 && fromNow <= 60):
                                    bucket = 2;
                                    break;
                                case (fromNow >= 61 && fromNow <= 90):
                                    bucket = 3;
                                    break;
                                case (fromNow >= 91 && fromNow <= 120):
                                    bucket = 4;
                                    break;
                                case (fromNow >= 121 && fromNow <= 150):
                                    bucket = 5;
                                    break;
                                case (fromNow >= 151 && fromNow <= 180):
                                    bucket = 6;
                                    break;
                                case (fromNow >= 181 && fromNow <= 210):
                                    bucket = 7;
                                    break;
                                case (fromNow >= 211 && fromNow <= 240):
                                    bucket = 8;
                                    break;
                                case (fromNow >= 241 && fromNow <= 270):
                                    bucket = 9;
                                    break;
                                case (fromNow >= 271 && fromNow <= 300):
                                    bucket = 10;
                                    break;
                                case (fromNow >= 301 && fromNow <= 330):
                                    bucket = 11;
                                    break;
                                case (fromNow >= 331 && fromNow <= 360):
                                    bucket = 12;
                                    break;
                                case (fromNow > 360):
                                    bucket = 13;
                                    break;
                            }

                            let terms = {};
                            terms[`${bucket}`] = [term];

                            dates[`${dueDateID}`] = {
                                date: dueDate,
                                terms,
                                termsStore
                            }
                        }
                    });
                }


                //sort the dates array
                const datesArray = _.map(dates, date => date);
                const sortedDates = arraySort(datesArray, "date");

                //check if our response is not empty
                if(sortedDates.length !== 0){

                    const today = moment().format('DD_MM_YYYY');

                    //STRUCTURE THE DATA
                    const report = [];

                    let reportTitleRow = {};
                    let todaysDate = moment(reportDate).format("DD/MM/YYYY");
                    // reportTitleRow["Ageing"] = `AGEING - BUCKET GROUPED REPORT - ${todaysDate}`;
                    // report.push(reportTitleRow);

                    // let sumOfUSDRow = {};
                    // sumOfUSDRow["Ageing"] = "Sum of USD";
                    // report.push(sumOfUSDRow);

                    //TODO: CREATE THE NORMAL ROWS
                    sortedDates.map(date => {

                        //create row
                        let row = {};
                        //find transaction date
                        const dateMoment = moment(reportDate).endOf('month');
                        row['Ageing'] = dateMoment.format("DD-MM-YYYY");
                        _.map(date.terms, (terms, key) => {
                            switch (key) {
                                case '1':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "1-30", totalRow: row, term, systemInfo }));
                                    break;
                                case '2':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "31-60", totalRow: row, term, systemInfo }));
                                    break;
                                case '3':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "61-90", totalRow: row, term, systemInfo }));
                                    break;
                                case '4':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "91-120", totalRow: row, term, systemInfo }));
                                    break;
                                case '5':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "121-150", totalRow: row, term, systemInfo }));
                                    break;
                                case '6':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "151-180", totalRow: row, term, systemInfo }));
                                    break;
                                case '7':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "181-210", totalRow: row, term, systemInfo }));
                                    break;
                                case '8':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "211-240", totalRow: row, term, systemInfo }));
                                    break;
                                case '9':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "241-270", totalRow: row, term, systemInfo }));
                                    break;
                                case '10':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "271-300", totalRow: row, term, systemInfo }));
                                    break;
                                case '11':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "301-330", totalRow: row, term, systemInfo }));
                                    break;
                                case '12':
                                    terms.map(term => row = computeHeaderRowAmount({ days: "331-360", totalRow: row, term, systemInfo }));
                                    break;
                                default:
                                    terms.map(term => row = computeHeaderRowAmount({ days: ">360", totalRow: row, term, systemInfo }));
                                    break;
                            }
                        });

                        //find total of total row
                        let rowAmountArray = [];
                        _.map(row, amount => {
                            if (amount) {
                                //check that valid amount is not name string
                                if (typeof amount === "number") {
                                    rowAmountArray.push(amount);
                                }
                            }
                        });

                        //calculate the total amount from numbers in the array
                        const grandRow = rowAmountArray.reduce((a, b) => a + b, 0);
                        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
                        row["Grand Total"] = roundAccurately(grandRow, 2);

                        return report.push(row);
                    });


                    //find the buckets total row
                    //TODO: CREATE THE TOTAL ROW
                    let totalRow = {};
                    totalRow["Ageing"] = "Grand Total";
                    sortedDates.map(date => {
                        //insert data in row
                        //loop over all customer terms
                        _.map(date.terms, (terms, key) => {
                            switch (key) {
                                case '1':
                                    //loop over all terms in the bucket
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "1-30", totalRow, term, systemInfo }));
                                    break;
                                case '2':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "31-60", totalRow, term, systemInfo }));
                                    break;
                                case '3':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "61-90", totalRow, term, systemInfo }));
                                    break;
                                case '4':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "91-120", totalRow, term, systemInfo }));
                                    break;
                                case '5':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "121-150", totalRow, term, systemInfo }));
                                    break;
                                case '6':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "151-180", totalRow, term, systemInfo }));
                                    break;
                                case '7':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "181-210", totalRow, term, systemInfo }));
                                    break;
                                case '8':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "211-240", totalRow, term, systemInfo }));
                                    break;
                                case '9':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "241-270", totalRow, term, systemInfo }));
                                    break;
                                case '10':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "271-300", totalRow, term, systemInfo }));
                                    break;
                                case '11':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "301-330", totalRow, term, systemInfo }));
                                    break;
                                case '12':
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: "331-360", totalRow, term, systemInfo }));
                                    break;
                                default:
                                    terms.map(term => totalRow = computeHeaderRowAmount({ days: ">360", totalRow, term, systemInfo }));
                                    break;
                            }
                        });
                    });

                    //find total of total row
                    let totalRowArray = [];
                    _.map(totalRow, amount => {
                        if (typeof amount === "number") {
                            console.log({ amount });
                            totalRowArray.push(amount);
                        } else {
                            console.log({ nan: amount });
                        }
                    });
                    //calculate the total amount from numbers in the array
                    const grandTotalRow = totalRowArray.reduce((a, b) => a + b, 0);
                    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
                    totalRow["Grand Total"] = roundAccurately(grandTotalRow, 2);

                    //put total row as first row in report
                    report.push(totalRow);
                    exportBucketGroupedReport({report, reportDate, dispatch});


                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            dispatch({ type: ALL_REPORT_FAILED });
            console.log(error);

        });
    }

};

async function exportBucketGroupedReport({report, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = moment(reportDate).format("DD/MM/YYYY");
    const bulk = `BUCKET GROUPED REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AGEING",
            B: "1-30",
            C: "31-60",
            D: "61-90",
            E: "91-120",
            F: "121-150",
            G: "151-180",
            H: "181-210",
            I: "211-240",
            J: "241-270",
            K: "271-300",
            L: "301-330",
            M: "331-360",
            N: ">360",
            O: "GRAND TOTAL"
        }
    ];

    report.forEach(data => {
        table1.push({
            A: data.Ageing,
            B: data["1-30"],
            C: data["31-60"],
            D: data["61-90"],
            E: data["91-120"],
            F: data["121-150"],
            G: data["151-180"],
            H: data["181-210"],
            I: data["211-240"],
            J: data["241-270"],
            K: data["271-300"],
            L: data["301-330"],
            M: data["331-360"],
            N: data[">360"],
            O: data["Grand Total"],
        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
        {wch:20},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Bucket grouped');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AGEING' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:O2',
        tbodyRange: `A2:O${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:O${headerIndex[0] + 1}` : null,
    }

    addStylesBucketGrouped(workbookBlob, dataInfo, dispatch);

}

const addStylesBucketGrouped = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'customerWise.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}


//================================================================= PROVISION REPORT ===================================================================================

export const generateProvisionReportNew = (reportDate) => {
    return async (dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `provisionNew_${date}`;

        const body = JSON.stringify({ reportID, path: "provisionNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;


        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                if (response.length !== 0) {
                    exportProvisionData({ dashboardData: response, dispatch, reportDate });
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        });
    }
};

async function exportProvisionData({ dashboardData, dispatch, reportDate }){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = reportDate.format("DD/MM/YYYY");
    let anal = `PROVISION REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "CUSTOMER NAME",
            B: "BUCKET",
            C: "PROVISION"
        }
    ];

    dashboardData.forEach(cheque => {
        table1.push({
            A: cheque.customerName,
            B: cheque.bucket,
            C: cheque.provision
        })
    })

    const finalData = [...title, ...table1];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:25},
        {wch:25},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Provision');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    finalData.forEach((data, index) => data['A'] === 'CUSTOMER NAME' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex1.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:C${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:C${headerIndex1[0] + 1}` : null,
    }

    addStylesProvision(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, `Analysis_Report_${today}.xlsx`);
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesProvision = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                sheet.range(dataInfo.theadRange).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange1).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'provision.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })
            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}

export const generateCreateDownPaymentsReport = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `down_payments_${date}`;

        const data = JSON.stringify({ reportID, path: "downPayments" });
        console.log(data)

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (response.length !== 0) {

                    //structure the data
                    const report = response.map(payment => {

                        let seconds;
                        payment.downPaymentDate.seconds ? seconds = payment.downPaymentDate.seconds : seconds = payment.downPaymentDate._seconds;
                        const rowDate = moment.unix(seconds);
                        const downPaymentDate = rowDate.toDate();

                        return {
                            amount: Number(payment.downPaymentPaidAmount),
                            bank: payment.downPaymentBankName,
                            date: downPaymentDate,
                            comment: payment.downPaymentComments,
                            currency: payment.currency,
                        }
                    });

                    exportDownPayment({report, dispatch, reportDate});


                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        });
    }
};

async function exportDownPayment({report, dispatch, reportDate}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = reportDate.format("DD/MM/YYYY");
    const bulk = `DOWN PAYMENT REPORT AS OF ${today}`;

    let title = [{A: bulk},{}];

    let table1 = [
        {
            A: "AMOUNT",
            B: "BANK",
            C: "DATE",
            D: "COMMENT",
            E: "CURRENCY"
        }
    ];

    report.forEach(data => {
        table1.push({
            A: data.amount,
            B: data.bank,
            C: data.date,
            D: data.comment,
            E: data.currency,

        })
    })

    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:15},
        {wch:15},
        {wch:15},
        {wch:35},
        {wch:10},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Down payment');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'AMOUNT' ? headerIndex.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:E${headerIndex[0] + 1}` : null,
    }

    addStylesDownPayment(workbookBlob, dataInfo, dispatch);

}

const addStylesDownPayment = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'downPayment.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const generateStatusAnalysisReportNew = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `statusAnalysisNew_${date}`;

        const data = JSON.stringify({ reportID, path: "statusAnalysisNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (!(_.isEmpty(response))) {
                    //structure the data
                    const resultArray = response.resultArray;
                    const statusAnalysis = response.statusAnalysis;
                    const characterAnalysis = response.characterAnalysis;
                    exportCharacterAndStatusWithOverdueAnalysis({characterAnalysis, statusAnalysis, resultArray, reportDate, dispatch});

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });

        });
    }
};

async function exportCharacterAndStatusWithOverdueAnalysis({characterAnalysis, statusAnalysis, resultArray, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = reportDate.format("DD/MM/YYYY");
    let anal = `STATUS ANALYSIS REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];


    let table1 = [
        {
            A: "ROW LABELS",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    resultArray.forEach(data => {
        table1.push({
            A: data.customerName,
            B: data.totalODInUsd,
        })
    })

    let table2 = [
        {
            A: "STATUS",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD REPORTING USD",
            D: "% ON COUNT",
            E: "% ON VALUE"
        }
    ];

    statusAnalysis.forEach(data => {
        table2.push({
            A: data.status,
            B: data.numberOfCustomers,
            C: data.totalODInUsd,
            D: data.countPercent,
            E: data.valuePercent
        })
    })

    //character report

    let table3 = [
        {
            A: "CHARACTER",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD REPORTING USD",
            D: "% ON VALUE"
        }
    ];

    characterAnalysis.forEach(data => {
        table3.push({
            A: data.character,
            B: data.numberOfCustomers,
            C: data.totalODInUsd,
            D: data.valuePercent
        })
    })

    table1 = (['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table1)
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:35},
        {wch:30},
        {wch:30},
        {wch:15},
        {wch:15},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Status analysis');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];
    const headerIndex3 = [];

    finalData.forEach((data, index) => data['A'] === 'STATUS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'CHARACTER' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3.push(index) : null )


    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:E${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:D${headerIndex1[0] + 1}` : null,
        theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
        theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:E${headerIndex3[0] + 1}` : null,
        theadRange4: headerIndex3.length >= 2 ? `A${headerIndex3[1] + 1}:D${headerIndex3[1] + 1}` : null,
        theadRange5: headerIndex3.length >= 3 ? `A${headerIndex3[2] + 1}:B${headerIndex3[2] + 1}` : null,
    }

    addStylesStatusAnalysis(workbookBlob, dataInfo, dispatch);

}

const addStylesStatusAnalysis = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange5).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'statusAnalysis.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })

            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}


export const generatesAnalysisReportNew = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `analysisNew_${date}`;

        const data = JSON.stringify({ reportID, path: "analysisNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (!(_.isEmpty(response))) {

                    const value = response.value;
                    const groupedReportPhase1 = response.groupedReportPhase1;
                    const groupedReportPhase2 = response.groupedReportPhase2;

                    exportBucketGrouped({value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch});

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });


        });
    }
};

async function exportBucketGrouped({value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }
    const today = reportDate.format("DD/MM/YYYY");
    let anal = `ANALYSIS REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];

    let table1 = [
        {
            A: "BUCKET",
            B: "NUMBER OF CUSTOMERS",
        }
    ];

    let table2 = [
        {
            A: "BUCKET",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    let table3 = [
        {
            A: "BUCKET GROUPED",
            B: "NUMBER OF CUSTOMERS",
        }
    ];

    let table4 = [
        {
            A: "BUCKET GROUPED",
            B: "SUM OF OD REPORTING USD",
        }
    ];

    value.forEach(data => {
        table1.push({
            A: data.groupedBucket,
            B: data.numberOfCustomers,
        })
    })

    value.forEach(data => {
        table2.push({
            A: data.groupedBucket,
            B: data.debtTotal,
        })
    })

    groupedReportPhase1.forEach(data => {
        table3.push({
            A: data.groupedBucket,
            B: data.numberOfCustomers,
        })
    })

    groupedReportPhase2.forEach(data => {
        table4.push({
            A: data.groupedBucket,
            B: data.totalOverdue,
        })
    })


    table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table4);
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch:30},
        {wch:30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Analysis');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];


    finalData.forEach((data, index) => data['A'] === 'BUCKET' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'BUCKET GROUPED' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex2.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:B2',
        tbodyRange: `A2:B${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:B${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex.length >= 2 ? `A${headerIndex[1] + 1}:B${headerIndex[1] + 1}` : null,
        theadRange2: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:B${headerIndex1[0] + 1}` : null,
        theadRange3: headerIndex1.length >= 2 ? `A${headerIndex1[1] + 1}:B${headerIndex1[1] + 1}` : null,
        theadRange4: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
        theadRange5: headerIndex2.length >= 2 ? `A${headerIndex2[1] + 1}:B${headerIndex2[1] + 1}` : null,
        theadRange6: headerIndex2.length >= 3 ? `A${headerIndex2[2] + 1}:B${headerIndex2[2] + 1}` : null,
        theadRange7: headerIndex2.length >= 4 ? `A${headerIndex2[3] + 1}:B${headerIndex2[3] + 1}` : null,
    }

    addStylesAnalysis(workbookBlob, dataInfo, dispatch);
    // XLSX.writeFile(wb, `Analysis_Report_${today}.xlsx`);
    // dispatch({type: ALL_REPORT_SUCCESSFUL})
}

const addStylesAnalysis = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange1).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange5).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange6).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange7).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', 'analysis.xlsx');
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}

export const generateDbSegmentReportNew = (reportDate) => {
    return(dispatch) => {

        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `dbSegmentNew_${date}`;

        const data = JSON.stringify({ reportID, path: "dbSegmentNew" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                if (!(_.isEmpty(response))) {

                    const dbSegmentArray = response.dbSegmentArray;
                    const industryDataArray = response.industryDataArray;
                    exportDBSegmentAndIndustryReport({dbSegmentArray, industryDataArray, reportDate, dispatch})
                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        });
    }
};

async function exportDBSegmentAndIndustryReport({dbSegmentArray, industryDataArray, reportDate, dispatch}){
    //styling sheets
    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(workbook, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }

    const today = reportDate.format("DD/MM/YYYY");
    let anal = `DB SEGMENT REPORT AS OF ${today}`;
    let title = [{A: anal}, {}];

   let table1 = [
       {
            A: "EOM",
            B: "NUMBER OF CUSTOMERS BLANK",
            C: "BLANK OD IN USD",
            D: "NUMBER OF CUSTOMERS DAEWOO",
            E: "DAEWOO OD IN USD",
            F: "NUMBER OF CUSTOMERS TML",
            G: "TML OD IN USD",
            H: "DISTINCT COUNT OF OF CUSTOMERS",
            I: "TOTAL SUM OF OD IN USD",
       }
   ];

   let table2 = [
       {
           A: "ROW LABELS",
           B: "SUM OF OD REPORTING USD",
       }
   ];

   if(dbSegmentArray.length !== 0){
        dbSegmentArray.forEach(data => {
            table1.push({
                A: data.eqm, 
                B: data.numberOfCustomersBlank, 
                C: data.blankODInUSD,
                D: data.numberOfCustomersDaewoo,
                E: data.daewooODInUSD, 
                F: data.numberOfCustomersTML, 
                G: data.tmlODInUSD, 
                H: data.distinctCountOfCustomers, 
                I: data.totalSumOfODInUSD, 
            })
        })
   }

   if(industryDataArray.length !== 0){
        industryDataArray.forEach(data => {
            table2.push({
                A: data.industry,
                B: data.totalODInUsd,
            })
        })
    }

   table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2)
   const finalData = [...title, ...table1];

   const wb = XLSX.utils.book_new();
   const ws = XLSX.utils.json_to_sheet(finalData, {
       skipHeader: true,
   })

   ws['!cols'] = [
       {wch:20},
       {wch:35},
       {wch:30},
       {wch:35},
       {wch:30},
       {wch:30},
       {wch:30},
       {wch:35},
       {wch:30},
       {wch:30},
       {wch:30},
   ];

   XLSX.utils.book_append_sheet(wb, ws, 'DB Segment');
   const workbookBlob = workbook2blob(wb);
   const headerIndex = [];
   const headerIndex2 = [];
   const headerIndex3 = [];

   finalData.forEach((data, index) => data['A'] === 'EOM' ? headerIndex.push(index) : null )
   finalData.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2.push(index) : null )
   finalData.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3.push(index) : null )

   const dataInfo = {
    titleCell: 'A2',
    titleRange: 'A1:I2',
    tbodyRange: `A2:I${finalData.length}`,
    theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:I${headerIndex[0] + 1}` : null,
    theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:B${headerIndex2[0] + 1}` : null,
    theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:I${headerIndex3[0] + 1}` : null,
    theadRange4: headerIndex3.length >= 2 ? `A${headerIndex3[1] + 1}:B${headerIndex3[1] + 1}` : null,
}

    addStylesDbSegment(workbookBlob, dataInfo, dispatch);
}

const addStylesDbSegment = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {

            sheet.range(dataInfo.titleRange).merged(true).style({
                bold: true,
                verticalAlignment: 'center',
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.tbodyRange).style({
                horizontalAlignment: 'center',
                fontFamily: 'Callibri',
                fontSize: 8
            })

            sheet.range(dataInfo.theadRange).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange2).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange3).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            sheet.range(dataInfo.theadRange4).style({
                fill: '808080',
                fontColor: 'FFFFFF',
                bold: true,
            })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'dbSegment.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })

            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}

export const generateCollectionTrackerNew = (reportDate) => {
    return async (dispatch) => {
        dispatch({type: ALL_REPORT});
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        const systemInfo = systemDoc.data();
        const exchangeRate = systemInfo.exchangeRate;

        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        //fetch end of last month overdue terms
        const date = moment(reportDate).subtract(1, "months").endOf('month').format("DD_MM_YYYY");
        console.log(date);

        //get specific date overdue data
        const reportID = `masterlistNew_${date}`;

        const info = JSON.stringify({ reportID, path: "masterListNew" });

        //invoke custom database function
        const url1 = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url1, {
            method: 'POST',
            mode: 'cors',
            body: info,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((bouncedCheques) => {
                //assign bouncedCheques to overdue terms
                // console.log({bounced: bouncedCheques});
                //find the total overdue as of end of last month
                let termsStore = [];

                _.map(bouncedCheques, client => {
                    //
                    client.values.map(term => {
                        //term has any of repossess status
                        if (term.termStatus.status) {
                            //term is cleared
                            //do nothing
                        } else {
                            //if loan is in usd convert the overdue to default currency
                            if (term.currency === "usd") {
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                termsStore.push(amount);
                            } else {
    
                                //grab the total overdue
                                let amount;
                                if ("modulo" in term) {
                                    amount = term.amount - term.modulo;
                                } else {
                                    amount = term.amount;
                                }
    
                                const convertedAmount = amount/exchangeRate;
    
                                termsStore.push(convertedAmount);
                            }
                        }
                    });
                })

            //CALCULATE OPENING OD
            let openingOverdue = termsStore.reduce((a, b) => a + b, 0);
                openingOverdue = roundAccurately(openingOverdue, 2);

            //FETCH ANALYTICS DATA
            const endMonthDate = moment(reportDate).format("DD_MM_YYYY");
            const analyticID = `analyticsNew_${endMonthDate}`;
            firebase.firestore().collection("analyticsDailyNewReports").doc(analyticID)
            .get()
            .then((doc) => {
                if(doc.exists){
                    let data = doc.data();
                    if (!(_.isEmpty(data))) {


                        let overdueArray = [];
                        const month = moment(reportDate).startOf('month').format("DD-MM-YYYY");

                        data["openingOD"] = openingOverdue;

                        overdueArray.push({value: openingOverdue, label: `Opening OD as of ${month}`, key: "1"});

                        _.map(data, (value, key) => {

                            switch (key) {
                                case "totalOverdue":
                                    let overdue = value;
                                    overdue = roundAccurately(overdue, 2);
                                    overdueArray.push({value: overdue, label: "Current OD per current month", key: "2"});
                                    break;

                                case "bouncedTd":
                                    let bouncedOverdue = value;
                                    bouncedOverdue = roundAccurately(bouncedOverdue, 2);
                                    overdueArray.push({value: bouncedOverdue, label: "Bounces for the month", key: "3"});
                                    break;
                            }
                        });


                        const bouncedTd = data.bouncedTd;
                        let totalOverdue = openingOverdue + bouncedTd;

                        totalOverdue = roundAccurately(totalOverdue, 2);
                        overdueArray.push({value: totalOverdue, label: "Total OD", key: "4"});

                        // const currentMonthOD = data.totalOverdue/exchangeRate;
                        const monthCollections = roundAccurately(data.odCollTd, 2);

                        // const monthCollections = totalOverdue - currentMonthOD;


                        ///extract date into array so it can be sorted
                        if (overdueArray.length !== 0){
                            overdueArray = arraySort(overdueArray, "key");

                            overdueArray.push({ label: "COLLECTIONS FOR THE MONTH", value: monthCollections });

                        }


                        collectionTrackerReportOne11({collectionTracker: overdueArray, analytics: data, exchangeRate, reportDate, dispatch});

                    }
                }else{
                    message.info("There is no analytic data recorded at the end of the month");
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch({type: ALL_REPORT_FAILED});
            })
        })
        .catch(e => {
            console.log(e);
            dispatch({type: ALL_REPORT_FAILED});
        })
    }
}

async function collectionTrackerReportOne11({collectionTracker, analytics, exchangeRate, reportDate, dispatch}){
    try{
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let overdueArray = [];
        if (!(_.isEmpty(analytics))) {

            //initial values
            const currentOD = analytics.totalOverdue;
            const tc = analytics.tc;
            const pdcTD = analytics.pdcTd;
            const bouncedTD = analytics.bouncedTd;
            const openingOD = analytics.openingOD;
            const pdcFtm  = analytics.pdcFtm;

            //due collections
            let dueCollection = pdcTD - bouncedTD;
            const dcRatio = (dueCollection/pdcTD) * 100;
            const dueCollectionRatio = roundAccurately(dcRatio, 2);
            dueCollection = roundAccurately(dueCollection, 2);

            overdueArray.push({amount: dueCollection, description: "Due Collections", ratio: `${dueCollectionRatio}%`, key: "1"});

            //overdue collections
            let overdueCollection = analytics.odCollTd;
            const ocRatio = (overdueCollection/currentOD) * 100;
            const overdueCollectionRatio = roundAccurately(ocRatio, 2);
            overdueCollection = roundAccurately(overdueCollection, 2);

            overdueArray.push({amount: overdueCollection, description: "Overdue Collections", ratio: `${overdueCollectionRatio}%`, key: "2"});

            //collections efficiency
            let collectionEfficiency = analytics.tc;
            const ceRatio = (tc/pdcTD) * 100;
            const collectionEfficiencyRatio = roundAccurately(ceRatio, 2);
            collectionEfficiency = roundAccurately(collectionEfficiency, 2);

            overdueArray.push({amount: collectionEfficiency, description: "Collections Efficiency", ratio: `${collectionEfficiencyRatio}%`, key: "3"});

            //total collections
            let totalCollection = openingOD + pdcTD;
            const tcRatio = (tc/totalCollection) * 100;
            const totalCollectionRatio = roundAccurately(tcRatio, 2);
            totalCollection = roundAccurately(totalCollection, 2);

            overdueArray.push({amount: totalCollection, description: "Total Collections", ratio: `${totalCollectionRatio}%`, key: "4"});


            ///extract date into array so it can be sorted
            if (overdueArray.length !== 0){
                overdueArray = arraySort(overdueArray, "key");
                overdueArray.push({ description: "", amount: "", ratio: "" });
            }

            collectionTrackerReportOne({collectionTracker, collectionTracker1: overdueArray, analytics, exchangeRate, reportDate, overdueOpening: analytics.openingOD, dispatch});
        }
    }catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});
    }
}

async function collectionTrackerReportOne({collectionTracker, collectionTracker1, analytics, exchangeRate, overdueOpening, dispatch, reportDate}){
    try{
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let report = [];
    
        if (!(_.isEmpty(analytics))) {
            //{ date, totalOverdue }
            // console.log({analytics: analytics});
            let row = {};
    
            const today = moment(reportDate);
    
            const pdcTD = analytics.pdcTd;
            const bouncedTD = analytics.bouncedTd;
            const pdcFTM = analytics.pdcFtm;
            const openingOD = analytics.openingOD;
            const bouncedForTheMonth = analytics.bouncedTd;
    
            let pdcBouncedTD = pdcTD - bouncedTD;
            pdcBouncedTD = roundAccurately(pdcBouncedTD, 2);
    
            const totalCollReqTarget = roundAccurately(pdcFTM, 2);
    
            const totalOverdue = openingOD + bouncedForTheMonth;
            let totalODReqTarget = totalOverdue * 0.5;
            totalODReqTarget = roundAccurately(totalODReqTarget, 2);
    
            row["month"]  = today.endOf('month').format("DD/MM/YYYY");
            row["pdcBouncedTD"] = pdcBouncedTD;
            row["totalODReqTarget"] = totalODReqTarget;
            row["totalCollReqTarget"] = totalCollReqTarget;
            row["tally"] = "";
    
            _.map(analytics, (value, key) => {
    
                switch (key) {
                    case "totalOverdue":
                        row["totalOverdue"] = roundAccurately(value, 2);
                        break;
                    case "pdcFtm":
                        row["pdcFtm"] = roundAccurately(value, 2);
                        break;
                    case "pdcTd":
                        row["pdcTd"] = roundAccurately(value, 2);
                        break;
                    case "bouncedTd":
                        row["bouncedTd"] = roundAccurately(value, 2);
                        break;
                    case "odCollTd":
                        row["odCollTd"] = roundAccurately(value, 2);
                        break;
                    case "tc":
                        row["tc"] = roundAccurately(value, 2);
                        break;
                }
            });
    
            report = [row];
        }

        getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2: report, analytics, exchangeRate, overdueOpening, reportDate, dispatch});
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});
    }
}

async function getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2, analytics, reportDate, exchangeRate, overdueOpening, dispatch}) {
    let successArray = [];

    const getDaysOfMonth = function() {
        let daysInMonth = moment(reportDate).format('DD');
        let arrDays = [];

        while(daysInMonth) {
            const current = moment(reportDate).date(parseInt(daysInMonth));

            console.log("current");
            console.log(current);
            arrDays.push({date: current.format('MM-DD-YYYY'), timeStamp: current});
            daysInMonth--;
        }

        return arrDays;
    };

    const dateList = getDaysOfMonth();
    console.log(dateList);
    analytics["currency"] = "localCurrency";

    if (dateList.length !== 0){
        dateList.forEach((date) => {
            const response = getCollectionTrackerAnalyticsData({date: date.date, timeStamp: date.timeStamp, analyticsData: analytics, exchangeRate, overdueOpening});
            successArray.push(response);
        });
            //
            Promise.all(successArray).then((dataArray) => {
                //here is our array of dashboard data
                if(dataArray.length !== 0){
                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();
                    //create report bucket to store all the excel rows as objects
                    let report = [];

                    dataArray.forEach(day => {
                        let termRow = {};

                        termRow["newDate"] = day.newDate;
                        termRow["totalBook"] = day.totalBook;
                        termRow["dueCollections"] = day.dueCollections;
                        termRow["cumulativeCollections"] = day.cumulativeCollections;
                        termRow["dailyCollections"] = day.dailyCollections;
                        termRow["collectionEfficiency"] = day.collectionEfficiency;
                        termRow["repoNumber"] = "";
                        termRow["repoValue"] = "";
                        termRow["legalCases"] = day.legalCases;
                        termRow["legalValue"] = day.legalValue;
                        termRow["overdue"] = day.overdue;
                        termRow["deliquencyRate"] = day.deliquencyRate;
                        termRow["bucket1"] = day.bucket1;
                        termRow["bucket2"] = day.bucket2;
                        termRow["bucket3"] = day.bucket3;
                        termRow["bucket4"] = day.bucket4;
                        termRow["overdueTarget"] = day.overdueTarget;
                        termRow["overdueActual"] = day.overdueActual;
                        termRow["legalRepo"] = day.legalRepo;
                        termRow["insuranceCase"] = day.insuranceCase;
                        termRow["repoProgress"] = day.repoProgress;
                        termRow["promisePay"] = day.promisePay;
                        termRow["watchlist"] = day.watchlist;
                        termRow["caseOpening"] = day.caseOpening;
                        termRow["requestCase"] = day.requestCase;
                        termRow["noStatusValue"] = day.noStatusValue;
                        termRow["underFollowUpValue"] = day.underFollowUpValue;
                        termRow["partialReposessionValue"] = day.partialReposessionValue;
                        termRow["nonStarterValue"] = day.nonStarterValue;


                        report.push(termRow);
                    })

                exportCollectionTrackerData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3: report, dispatch, reportDate});

            }
        })
    }
}

async function getCollectionTrackerAnalyticsData({date, timeStamp, analyticsData, exchangeRate, overdueOpening}){

    //get specific date analytics data
    const reportDate = timeStamp.format("DD_MM_YYYY");
    const reportID = `analyticsNew_${reportDate}`;
    const analyticsRef = firebase.firestore().collection('analyticsDailyNewReports').doc(reportID);
    const doc = await analyticsRef.get();

    if (doc.exists) {
        // console.log({analytics: data});
        analyticsData = doc.data();
    }

    return getCollectionTrackerOverdueData({date, timeStamp, analyticsData, exchangeRate, overdueOpening});
}

async function getCollectionTrackerOverdueData({date, timeStamp, analyticsData, exchangeRate, overdueOpening}){

    let termsStore = [];
    let overdueTerms = [];

    //get specific date overdue data
    const dateID = timeStamp.format("DD_MM_YYYY");
    const reportID = `masterlistNew_${dateID}`;

    const data = JSON.stringify({ reportID, path: "masterListNew" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((bouncedCheques) => {
            console.log('cheques', bouncedCheques);
            //assign bouncedCheques to overdue terms
            // console.log({bounced: bouncedCheques});
            _.map(bouncedCheques, client => {
                //
                client.values.map(term => {
                    termsStore.push(term);
                });
            })
        })
        .catch(e => {
            console.log(e);
        })

    //assign overdue terms
    if (termsStore.length !== 0){
        overdueTerms = termsStore;
    }

    // console.log({date: date, overdueTerms: overdueTerms});
    return getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}

async function getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let totalBook = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            totalBook = analyticsData.totalBook;
            totalBook = totalBook/exchangeRate;
            totalBook = roundAccurately(totalBook, 2);
        } else {
            totalBook = analyticsData.totalBook;
            totalBook = roundAccurately(totalBook, 2);
        }
    } else {
        totalBook = analyticsData.totalBook;
        totalBook = roundAccurately(totalBook, 2);
    }

    // console.log({date: date, totalBook: totalBook});

    return getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening});
}

async function getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dueCollections = 0;

    if ("currency" in analyticsData){
        if (analyticsData.currency === "localCurrency"){
            dueCollections = analyticsData.pdcTd;
            dueCollections = dueCollections/exchangeRate;
            dueCollections = roundAccurately(dueCollections, 2);
        } else {
            dueCollections = analyticsData.pdcTd;
            dueCollections = roundAccurately(dueCollections, 2);
        }
    } else {
        dueCollections = analyticsData.pdcTd;
        dueCollections = roundAccurately(dueCollections, 2);
    }

    return getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening });
}

async function getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening }) {
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let cumulativeCollections = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = cumulativeCollections/exchangeRate;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        } else {
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        }
    } else {
        cumulativeCollections = analyticsData.tc;
        cumulativeCollections = roundAccurately(cumulativeCollections, 2);
    }


    return getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening});
}

async function getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening}) {
    //get daily collections
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dailyCollections = 0;
    let collectionArray = [];

    const collectionRef = firebase.firestore().collection('cashCollections');
    const snapshot = await collectionRef.get();

    if (snapshot.size !== 0) {
        snapshot.forEach(doc => {
            const data = doc.data();

            let seconds;
            data.bankDate.seconds ? seconds = data.bankDate.seconds :  seconds = data.bankDate._seconds;

            const bankDate = moment.unix(seconds).format("MM-DD-YYYY");
            // const tday = moment();

            // if(date === bankDate){
                if(moment(bankDate).isSame(timeStamp, "day")){
                    console.log(bankDate);
                    if (data.currency === "usd") {
                        //grab the paid amount
                        collectionArray.push(data.paidAmount);
    
                    } else {
    
                        const amount = data.paidAmount/exchangeRate;
                        collectionArray.push(amount);
                    }
                }
            // }
        })
    }

    //find sum of daily collections
    dailyCollections = collectionArray.reduce((a, b) => a + b, 0);
    dailyCollections = roundAccurately(dailyCollections, 2);

    // console.log({date, totalBook: totalBook, dueCollections: dueCollections, cumulativeCollections: cumulativeCollections, collection: dailyCollections});

    return getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening});
}

async function getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening}) {
    //find collection efficiency
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let collectionEfficiency = 0;

    collectionEfficiency = cumulativeCollections/dueCollections;
    collectionEfficiency = roundAccurately(collectionEfficiency, 1);

    return getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening});
}

async function getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening}) {
    //
    let repoNumber = 0;
    let repoBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in repoBucket) {
                        //terms with this customerID already exist
                        let terms = repoBucket[`${customerID}`].terms;
                        terms.push(term);

                        repoBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        repoBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(repoBucket))){
        repoNumber = Object.keys(repoBucket).length;
    }

    // console.log({repoNumber: repoNumber});

    return getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening});
}

async function getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening}) {
    //
    let repoValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                        
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        repoValue = termStore.reduce((a, b) => a + b, 0);
        repoValue = roundAccurately(repoValue, 2);
    }

    // console.log({repoValue: repoValue});

    return getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        overdueOpening});
}

async function getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                                      overdueOpening}) {
    //
    let legalCases = 0;
    let legalBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                    //term has any of legal status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in legalBucket) {
                        //terms with this customerID already exist
                        let terms = legalBucket[`${customerID}`].terms;
                        terms.push(term);

                        legalBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        legalBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(legalBucket))){
        legalCases = Object.keys(legalBucket).length;
    }

    // console.log({legalCases: legalCases});

    return getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, overdueOpening});
}

async function getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, overdueOpening}) {
//
    let legalValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                        } else {
                        amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                        } else {
                        amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        legalValue = termStore.reduce((a, b) => a + b, 0);
        legalValue = roundAccurately(legalValue, 2);
    }

// console.log({legalValue: legalValue});

return getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
legalCases, legalValue, overdueOpening});
}

async function getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdueOpening}) {
        let overdue = 0;
        let termStore = [];
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
        if (overdueTerms.length !== 0){
            overdueTerms.forEach((term) => {
    
                if (!term.termStatus.status){
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        termStore.push(amount);
                    } else {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        const convertedAmount = amount/exchangeRate;
    
                        termStore.push(convertedAmount);
                    }
                }
            })
        }
    
        if(termStore.length !== 0){
            overdue = termStore.reduce((a, b) => a + b, 0);
            overdue = roundAccurately(overdue, 2);
        }
    
    
        return getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, overdueOpening});
}

async function getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, overdueOpening}) {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const dRate = overdue/totalBook * 100;
        const deliquencyRate = roundAccurately(dRate, 2);
    
        return getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, deliquencyRate, overdueOpening});
    }

async function getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, overdueOpening}) {
        
        let b1 = 0;
        let b2 = 0;
        let b3 = 0;
        let b4 = 0;

        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = [];
        let arr8 = [];
        let arr9 = [];
        let arr10 = [];
        let arr11 = [];
        let arr12 = [];

    if(overdueTerms.length !== 0){
        let terms = {};
        overdueTerms.forEach(term => {
            
            let seconds;
            if ("transactionDate" in term) {
                if(term.transactionDate){
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                }else{
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            }


            //grab end of month of next month
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');

            const today = moment(date);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
            const bucket = Math.round(fromNow/30);
        
            let daysRange = "(0)";
            //compute date range depending on bucket
            if(bucket !== 0) {
                const endDate = bucket * 30;
                const startDate = endDate - 29;

                daysRange = `(${startDate}-${endDate})`
            }

            if(bucket !== undefined && bucket !== null){
                if (`${daysRange}` in terms) {
                    let termBucket = terms[`${daysRange}`].terms;
                    termBucket.push(term);
                    terms[`${daysRange}`].terms = termBucket;

                } else {
                    terms[`${daysRange}`] = {
                        range: daysRange,
                        terms: [term]
                    }
                }
            }
        })


        const termsArr = _.map(terms, client => client );

        termsArr.forEach(term => {
            if(term.range === "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr.push(amount);
                })
            }
            
            if(term.range === "(31-60)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr1.push(amount);
                })
            }
            
            if(term.range === "(61-90)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr2.push(amount);
                })
            }
            
            if(term.range === "(91-120)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr3.push(amount);
                })
            }
            
            if(term.range === "(121-150)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr4.push(amount);
                })
            }
            
            if(term.range === "(151-180)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr5.push(amount);
                })
            }
            
            if(term.range === "(181-210)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr6.push(amount);
                })
            }

            if(term.range === "(211-240)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr7.push(amount);
                })
            }
            
            if(term.range === "(241-270)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr8.push(amount);
                })
            }

            if(term.range === "(271-300)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr9.push(amount);
                })
            }
            
            if(term.range === "(301-330)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr10.push(amount);
                })
            }
            
            if(term.range === "(331-360)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr11.push(amount);
                })
            } 
            
            if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr12.push(amount);
                })
            }
        })


        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        let bucket1 = arr.reduce((a, b) => a + b, 0);
        bucket1 = roundAccurately(bucket1, 2);
        let bucket2 = arr1.reduce((a, b) => a + b, 0);
        bucket2 = roundAccurately(bucket2, 2);
        let bucket3 = arr2.reduce((a, b) => a + b, 0);
        bucket3 = roundAccurately(bucket3, 2);
        let bucket4 = arr3.reduce((a, b) => a + b, 0);
        bucket4 = roundAccurately(bucket4, 2);
        let bucket5 = arr4.reduce((a, b) => a + b, 0);
        bucket5 = roundAccurately(bucket5, 2);
        let bucket6 = arr5.reduce((a, b) => a + b, 0);
        bucket6 = roundAccurately(bucket6, 2);
        let bucket7 = arr6.reduce((a, b) => a + b, 0);
        bucket7 = roundAccurately(bucket7, 2);
        let bucket8 = arr7.reduce((a, b) => a + b, 0);
        bucket8 = roundAccurately(bucket8, 2);
        let bucket9 = arr8.reduce((a, b) => a + b, 0);
        bucket9 = roundAccurately(bucket9, 2);
        let bucket10 = arr9.reduce((a, b) => a + b, 0);
        bucket10 = roundAccurately(bucket10, 2);
        let bucket11 = arr10.reduce((a, b) => a + b, 0);
        bucket11 = roundAccurately(bucket11, 2);
        let bucket12 = arr11.reduce((a, b) => a + b, 0);
        bucket12 = roundAccurately(bucket12, 2);
        let bucket13 = arr12.reduce((a, b) => a + b, 0);
        bucket13 = roundAccurately(bucket13, 2);

        b1 = bucket1 + bucket2 + bucket3;
        b2 = bucket4 + bucket5 + bucket6;
        b3 = bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12;
        b4 = bucket13;
    }
    
        return getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, deliquencyRate, bucket1: b1, bucket2: b2, bucket3: b3, bucket4: b4, overdueOpening});
    }

async function getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueOpening}) {
        let overdueTarget = 0;
        // console.log({overdue: overdueOpening});
    
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
        if ("currency" in analyticsData){
            //
            if (analyticsData.currency === "localCurrency"){
                const bouncedForTheMonth = analyticsData.bouncedTd/exchangeRate;
                const totalOverdue = overdueOpening + bouncedForTheMonth;
                const totalODReqTarget = totalOverdue * 0.5;
                overdueTarget = roundAccurately(totalODReqTarget, 2);
            } else {
                const bouncedForTheMonth = analyticsData.bouncedTd;
                const totalOverdue = overdueOpening + bouncedForTheMonth;
                const totalODReqTarget = totalOverdue * 0.5;
                overdueTarget = roundAccurately(totalODReqTarget, 2);
            }
        } else {
            const bouncedForTheMonth = analyticsData.bouncedTd;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        }
    
    
        return getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget});
    }

    async function getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget}) {
            let overdueActual = 0;
            // let termsStore = [];
            const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        
            if ("currency" in analyticsData){
                //
                if (analyticsData.currency === "localCurrency"){
                    overdueActual = analyticsData.odCollTd;
                    overdueActual = overdueActual/exchangeRate;
                    overdueActual = roundAccurately(overdueActual, 2);
                } else {
                    overdueActual = analyticsData.odCollTd;
                    overdueActual = roundAccurately(overdueActual, 2);
                }
            } else {
                overdueActual = analyticsData.odCollTd;
                overdueActual = roundAccurately(overdueActual, 2);
            }
        
            return getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
                legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual});
        }

async function getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual}) {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let legalOrRepo = [];
        let watchList = [];
        let insurance = [];
        let promiseToPay = [];
        let repoInProgress = [];
        let caseOpeningInProgress = [];
        let requestToOpenCase = [];
        let underFollowUp = [];
        let noStatus = [];
        let partialReposession = [];
        let nonStarter = [];
    
        let above90Terms = [];                                        
        overdueTerms.forEach((term) => {
            let seconds;
            if ("transactionDate" in term) {
                if (term.transactionDate) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
    
            //grab today moment
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');
    
            const today = moment(date);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
    
            if(fromNow > 90){
    
                above90Terms.push(term);
            }
        })
    
        let statusTerms = {};
        above90Terms.forEach(emi => {
            if("legalRepoStatus" in emi){
                if (`${emi.legalRepoStatus}` in statusTerms) {
                    let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
            
                    statusBucket.push(emi);
            
                    statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
            
                } else {
                    statusTerms[`${emi.legalRepoStatus}`] = [emi];
                }
            }else{
                emi['legalRepoStatus'] = "No Status";
                if (`${emi.legalRepoStatus}` in statusTerms) {
                    let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
            
                    statusBucket.push(emi);
            
                    statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
            
                } else {
                    statusTerms[`${emi.legalRepoStatus}`] = [emi];
                }
            }
        })
    
    
        let legalRepoStatusArray = [];
        let totalOverdueArr = [];
        const termsArr = _.map(statusTerms, client => client );
        termsArr.forEach((loanTerms) => {
            loanTerms.forEach(loanTerm => {
                let amount;
                if (loanTerm.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in loanTerm) {
                        amount = loanTerm.amount - loanTerm.modulo;
                    } else {
                        amount = loanTerm.amount;
                    }
    
                } else {
                    if ("modulo" in loanTerm) {
                        amount = loanTerm.amount - loanTerm.modulo;
                    } else {
                        amount = loanTerm.amount;
                    }
    
                    amount = amount/exchangeRate;
                }
                totalOverdueArr.push(amount);
                legalRepoStatusArray.push({name: loanTerm.legalRepoStatus, value: amount});
            })
        })
        console.log({legalRepoStatusArray: legalRepoStatusArray});
        let resultObject = {};
    
        legalRepoStatusArray.forEach(element => {
            if (resultObject.hasOwnProperty(element.name)) {
                resultObject[element.name] = resultObject[element.name] + element.value;
            } else {
                resultObject[element.name] = element.value;
            }
        });
    
        let resultArray = [];
    
        for (let prop in resultObject) {
            resultArray.push({ name: prop, value: resultObject[prop] });
        }
    
        let totalOv = totalOverdueArr.reduce((a, b) => a + b, 0);
        console.log({totalOv90: totalOv});
    
        resultArray.forEach(term => {
            const amount = term.value;
            if ("name" in term) {
                const legalRepoStatus = term.name;
                const legalPosition = legalRepoStatus.search(/legal/i);
                const repoPosition = legalRepoStatus.search(/repossessed/i);
    
                switch (true) {
                    case (legalPosition >= 0 || repoPosition >= 0):
                        legalOrRepo.push(amount);
                        break;
                    case (legalRepoStatus === "Insurance case"):
                        insurance.push(amount);
                        break;
                    case (legalRepoStatus === "Under Follow-up"):
                        underFollowUp.push(amount);
                        break;
                    case (legalRepoStatus === "Repossession in progress"):
                        repoInProgress.push(amount)
                        break;
                    case (legalRepoStatus === "Promise to pay"):
                        promiseToPay.push(amount);
                        break;
                    case (legalRepoStatus === "Watchlist"):
                        watchList.push(amount);
                        break;
                    case (legalRepoStatus === "Case Opening in Progress"):
                        caseOpeningInProgress.push(amount);
                        break;
                    case (legalRepoStatus === "Partial Repossession"):
                        partialReposession.push(amount);
                        break;
                    case (legalRepoStatus === "Request To Open Case"):
                        requestToOpenCase.push(amount);
                        break;
                    case (legalRepoStatus === "No Status"):
                        noStatus.push(amount);
                        break;
                    case (legalRepoStatus === "Non-Starter"):
                        nonStarter.push(amount);
                        break;
                }
            }
        })
    
        let legalRepo = legalOrRepo.reduce((a, b) => a + b, 0);
        legalRepo = roundAccurately(legalRepo, 2);
    
        let insuranceCase = insurance.reduce((a, b) => a + b, 0);
        insuranceCase = roundAccurately(insuranceCase, 2);
    
        let repoProgress = repoInProgress.reduce((a, b) => a + b, 0);
        repoProgress = roundAccurately(repoProgress, 2);
    
        let promisePay = promiseToPay.reduce((a, b) => a + b, 0);
        promisePay = roundAccurately(promisePay, 2);
    
        let watchlist = watchList.reduce((a, b) => a + b, 0);
        watchlist = roundAccurately(watchlist, 2);
    
        let requestCase = requestToOpenCase.reduce((a, b) => a + b, 0);
        requestCase = roundAccurately(requestCase, 2);
    
        let caseOpening = caseOpeningInProgress.reduce((a, b) => a + b, 0);
        caseOpening = roundAccurately(caseOpening, 2);
    
        let noStatusValue = noStatus.reduce((a, b) => a + b, 0);
        noStatusValue = roundAccurately(noStatusValue, 2);
    
        let partialReposessionValue = partialReposession.reduce((a, b) => a + b, 0);
        partialReposessionValue = roundAccurately(partialReposessionValue, 2);
    
        let underFollowUpValue = underFollowUp.reduce((a, b) => a + b, 0);
        underFollowUpValue = roundAccurately(underFollowUpValue, 2);
    
        let nonStarterValue = nonStarter.reduce((a, b) => a + b, 0);
        nonStarterValue = roundAccurately(nonStarterValue, 2);
    
        const newDate = timeStamp.format("DD-MM-YYYY");
    
        return {date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual, legalRepo, insuranceCase, watchlist, promisePay, requestCase,
            caseOpening, repoProgress, newDate, noStatusValue, nonStarterValue, underFollowUpValue, partialReposessionValue};
    }

    async function exportCollectionTrackerData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3, dispatch, reportDate}){
        //styling sheets
        const sa2b = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for(let i = 0; i !== s.length; ++i){
                view[i] = s.charCodeAt(i);
            }
            return buf;
        }
    
        const workbook2blob = (workbook) => {
            const wopts = {
                bookType: 'xlsx',
                type: 'binary'
            }
    
            const wbout = XLSX.write(workbook, wopts);
            const blob = new Blob([sa2b(wbout)], {
                type: 'application/octet-stream'
            })
    
            return blob;
        }
    
        const today = moment(reportDate).format("DD/MM/YYYY");
        let anal = `COLLECTION TRACKER AS OF ${today}`;
        let title = [{A: anal}, {}];


    let table1 = [
        {
            A: "COLLECTION FOR THE MONTH",
            B: "VALUE",
        }
    ];

    let table2 = [
        {
            A: "DESCRIPTION",
            B: "AMOUNT",
            C: "RATIO"
        }
    ];

    let table3 = [
        {
            A: "MONTH",
            B: "TOTAL OVERDUE",
            C: "PDC FTM",
            D: "PDC TD",
            E: "PDC TD - BOUNCED TD",
            F: "BOUNCED TD",
            G: "OD COLL TD",
            H: "TC",
            I: "TOTAL COLL REQ TARGET",
            J: "TOTAL OD REQ TARGET",
            K: "TALLY",
        }
    ];

    let table4 = [
        {
            A: "DATE",
            B: "TOTAL BOOK",
            C: "SCHEDULED DUE COLLECTIONS",
            D: "CUMMULATIVE COLLECTIONS",
            E: "DAILY COLLECTIONS",
            F: "COLLECTION EFFICIENCY",
            G: "REPOSSESSED NUMBER",
            H: "REPOSSESSED VALUE",
            I: "LEGAL CASES",
            J: "LEGAL VALUE",
            K: "OVERDUE",
            L: "DELIQUENCY RATE",
            M: "0-90",
            N: "91-180",
            O: "181-360",
            P: ">360",
            Q: "OVERDUE TARGET",
            R: "OVERDUE ACTUAL",
            S: ">90 DAYS LEGAL/REPOSSESSED",
            T: ">90 DAYS INSURANCE CASE",
            U: ">90 DAYS REPOSSESSION IN PROGRESS",
            V: ">90 DAYS PROMISE TO PAY",
            W: ">90 DAYS WATCHLIST",
            X: ">90 DAYS CASE OPENING IN PROGRESS",
            Y: ">90 DAYS REQUEST TO OPEN CASE",
            Z: ">90 DAYS UNDER FOLLOW-UP",
            AA: ">90 DAYS PARTIAL REPOSSESSION",
            AB: ">90 NON STARTER",
            AC: ">90 DAYS BLANK",
        }
    ];

    if(collectionTracker.length !== 0){
        collectionTracker.forEach(data => {
            table1.push({
                A: data.label,
                B: data.value,
            })
        })
    }

    if(collectionTracker1.length !== 0){
        collectionTracker1.forEach(data => {
            table2.push({
                A: data.description,
                B: data.amount,
                C: data.ratio

            })
        })
    }

    if(collectionTracker2.length !== 0){
        collectionTracker2.forEach(data => {
            table3.push({
                A: data.month,
                B: data.totalOverdue,
                C: data.pdcFtm,
                D: data.pdcTd,
                E: data.pdcBouncedTD,
                F: data.bouncedTd,
                G: data.odCollTd,
                H: data.tc,
                I: data.totalCollReqTarget,
                J: data.totalODReqTarget,
                K: data.tally,
            })
        })
    }

    if(collectionTracker3.length !== 0){
        collectionTracker3.forEach(data => {
            table4.push({
                A: data.newDate,
                B: data.totalBook,
                C: data.dueCollections,
                D: data.cumulativeCollections,
                E: data.dailyCollections,
                F: data.collectionEfficiency,
                G: "",
                H: "",
                I: data.legalCases,
                J: data.legalValue,
                K: data.overdue,
                L: data.deliquencyRate,
                M: data.bucket1,
                N: data.bucket2,
                O: data.bucket3,
                P: data.bucket4,
                Q: data.overdueTarget,
                R: data.overdueActual,
                S: data.legalRepo,
                T: data.insuranceCase,
                U: data.repoProgress,
                V: data.promisePay,
                W: data.watchlist,
                X: data.caseOpening,
                Y: data.requestCase,
                Z: data.underFollowUpValue,
                AA: data.partialReposessionValue,
                AB: data.nonStarterValue,
                AC: data.noStatusValue,

            })
        })
    }

    table1 = (['']).concat(table1).concat(['']).concat(['']).concat(['']).concat(table2).concat(['']).concat(['']).concat(['']).concat(table3).concat(['']).concat(['']).concat(['']).concat(table4)
    const finalData = [...title, ...table1];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    ws['!cols'] = [
        {wch: 30},
        {wch: 20},
        {wch: 35},
        {wch: 35},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 25},
        {wch: 15},
        {wch: 15},
        {wch: 15},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
        {wch: 30},
    ];

    XLSX.utils.book_append_sheet(wb, ws, 'Collection Tracker');
    const workbookBlob = workbook2blob(wb);
    const headerIndex = [];
    const headerIndex1 = [];
    const headerIndex2 = [];
    const headerIndex3 = [];

    finalData.forEach((data, index) => data['A'] === 'COLLECTION FOR THE MONTH' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'DESCRIPTION' ? headerIndex1.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'MONTH' ? headerIndex2.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'DATE' ? headerIndex3.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:AC2',
        tbodyRange: `A2:AC${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:B${headerIndex[0] + 1}` : null,
        theadRange1: headerIndex1.length >= 1 ? `A${headerIndex1[0] + 1}:C${headerIndex1[0] + 1}` : null,
        theadRange2: headerIndex2.length >= 1 ? `A${headerIndex2[0] + 1}:K${headerIndex2[0] + 1}` : null,
        theadRange3: headerIndex3.length >= 1 ? `A${headerIndex3[0] + 1}:AC${headerIndex3[0] + 1}` : null,
    }

    addStylesCollectionTracker(workbookBlob, dataInfo, dispatch);
}

const addStylesCollectionTracker = (workbookBlob, dataInfo, dispatch) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach(sheet => {
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Calibri',
                    fontSize: '8'
                })

                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Calibri',
                    fontSize: '8'
                })

                sheet.range(dataInfo.theadRange).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange1).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange2).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

                sheet.range(dataInfo.theadRange3).style({
                    fill: '808080',
                    fontColor: 'FFFFFF',
                    bold: true,
                })

            workbook.outputAsync().then(workbookBlob => { 
                const url = URL.createObjectURL(workbookBlob);
                const downloadAnchorNode = document.createElement('a');
                downloadAnchorNode.setAttribute('href', url);
                downloadAnchorNode.setAttribute('download', 'collectionTracker.xlsx');
                downloadAnchorNode.click();
                downloadAnchorNode.remove();
            })
            dispatch({type: ALL_REPORT_SUCCESSFUL});
        })
    })
}