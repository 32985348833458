import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";


import { projectArray } from "../../../../env/PROJECTSCONFIGURATIONS";

//actions import
import { fetchLoanTerms } from "../../../../actions/LoanActions";

class LoanCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            currentLoanTerms: this.props.currentLoanTerms,
            localCurrency:"TSH",
        }

        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({ currentLoanTerms: nextProps.currentLoanTerms });
        }
    }

    componentWillMount(){
        const {loan} = this.props;
        this.props.fetchLoanTerms({customerID: loan.customerID, loanID: loan.loanID });
        this.checkCurrencyFormat();
    }

    
    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;
        const { loan } = this.props;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if payment object is not empty
        if (!(_.isEmpty(loan))) {
            const loanCurrency = loan.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4>Total Amount: <span className={"loanTotalValue"}>{formatter.format(displayValue)}</span></h4>
    }

    displayTermCurrency(value) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;
        const { loan } = this.props;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if payment object is not empty
        if (!(_.isEmpty(loan))) {
            const loanCurrency = loan.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <span className={"loanTotalValue"}>{formatter.format(displayValue)}</span>
    }


    renderCommencementAndEndDate(){
        const { loan } = this.props;
            return (
                <div>
                    {
                        loan.startingDate ?
                        <div><h5>Starting Date: <span className={"loanStatusPendingValue"}>{loan.startingDate}</span></h5></div> : null
                    }
                    {
                        loan.endDate ?
                        <div><h5>End Date: <span className={"loanStatusPendingValue"}>{loan.endDate}</span></h5></div> : null
                    }
                   <div><h5>Emi: {this.displayTermCurrency(loan.emi)}</h5></div>
                </div>
            );
        }

    render() {
        const { loan, currentLoanTerms } = this.props;
        return (
            <div className={"loanCardContents"}>
                <div className={"leftCardBox"}>
                    {
                        loan.loanStatus ?
                            <div><h4>Loan Status: <span className={"loanStatusClearValue"}>CLEARED</span></h4></div>
                         :
                            <div><h4>Loan Status: <span className={"loanStatusPendingValue"}>PENDING</span></h4></div>
                    }
                    
                    <div>{this.displayCurrency(loan.totalAmount)}</div>
                </div>

                <div className={"rightCardBox"}>
                    <div><h4>Loan ID: <span className={"loanIDValue"}> {loan.loanID} </span></h4> </div>
                    <div><h4>Loan Terms: <span className={"loanTermsValue"}> {loan.loanTerms}</span></h4></div>
                </div>

                <div className={"leftCardBox"}>
                    <div >{this.renderCommencementAndEndDate()}</div>
                </div>
                
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { profile } = state.profile;
    const { systemInfo } = state.app;
    const { currentLoanTerms } = state.loans;

    return {
        profile,
        systemInfo,
        currentLoanTerms
    }
};

export default connect(mapStateToProps, { fetchLoanTerms })(LoanCard);