import React from 'react'
//import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//actions import
import { fetchLoanTerms, fetchLoan, fetchLoanLogs } from "../../../actions/LoanActions";
import { fetchCashCollections } from "../../../actions/CollectionsActions";

export class CurrentPaidLoanCard extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loan: this.props.loan,
        };

        this.onFetchLoanTerms = this.onFetchLoanTerms.bind(this);
    }

    onFetchLoanTerms() {
        //extract state
        const { loan } = this.state;
        //extract props
        const { history } = this.props;

        //fetch customer loan terms
        const customerID = loan.customerID;
        const loanID = loan.loanID;

        this.props.fetchLoan({customerID, loanID});
        this.props.fetchLoanTerms({customerID, loanID });
        this.props.fetchLoanLogs({customerID, loanID });
        this.props.fetchCashCollections({customerID, loanID});
        history.push('/loan-page');
    }

    // renderTerm() {
    //     const { firstTerm} = this.state.loan;
    //     if ("firstTerm" in this.state.loan) {
    //         if (!(_.isEmpty(firstTerm))) {
    //             let status = "null";
    //             if (firstTerm.cheque) {
    //                 status = "cheque";
    //             } else {
    //                 status = "cash";
    //             }
    //
    //             return (
    //                 <div className={"incorrectLoanType"}>
    //                     <p>{status}</p>
    //                 </div>
    //             );
    //         }
    //     }
    // }

    render() {
        const { customerID, customerName, loanID } = this.state.loan;

        return (
            <div className={"incorrectCardContainer"} onClick={() => this.onFetchLoanTerms()}>
                <div className={'incorrectLoanHeader'}>
                    <div><h5>ID</h5></div>
                    <div><h5>Customer Name</h5></div>
                    <div><h5>Loan ID</h5></div>
                </div>
                <div className={'incorrectLoanValues'}>
                    <div>{customerID ? <p>{customerID}</p> : null}</div>
                    <div>{customerName ? <p>{customerName}</p> : null}</div>
                    <div>{loanID ? <p>{loanID}</p> : null}</div>
                </div>
                {/*<div>*/}
                {/*    { this.renderTerm() }*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default withRouter(connect(null, { fetchLoanTerms, fetchLoanLogs, fetchLoan, fetchCashCollections })(CurrentPaidLoanCard));
