import React from "react";
import { Modal, Button } from 'antd';

//components import
import ChooseExistingCustomer from "./ChooseExistingCustomer";

//file imports
import existingCustomerArtwork from '../../../assets/images/existingCustomerArtwork.png';

class ExistingCustomer extends React.Component {



    // componentWillUnmount() {
    //     this.props.clearExistingCustomer();
    // }

    state = {
        visible: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        return (
            <div className={"existingCustomerContainer"}>
                <div className={"existingCustomerArtworkBox"}>
                    <img src={existingCustomerArtwork} alt="existing customer artwork" />
                </div>
                <div className={"existingCustomerButtonBox"}>
                    <Button type="default" onClick={this.showModal}>
                        Choose Existing Customer
                    </Button>
                    <h4>Search and choose an already registered customer</h4>
                    <Modal
                        title="Choose Existing Customer"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        width={600}
                    >
                        <div>
                            <ChooseExistingCustomer/>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}


export default ExistingCustomer;
