import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import {message, Switch} from 'antd';

//actions import
import {customerLoanTermChanged, saveCashCollection, bankDateChanged, loanTermCardDetailsChanged, collectionCurrencyChanged} from "../../../actions/CollectionsActions";

//components import
import {PickDate} from "../../common/PickDate";
import {Input} from "../../common/Input";
import {Button} from "../../common/Button";
//import SelectLoanTerm from "../../common/SelectLoanTerm";
import {TextArea} from "../../common/TextArea";
import {BeatLoader} from "react-spinners";
import _ from "lodash";



class SelectedLoan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            paidAmount: this.props.paidAmount,
            bankName: this.props.bankName,
            bankDate: this.props.bankDate,
            comments: this.props.comments,
            currency: this.props.currency,
            disabled: this.props.disabled,

            loanTerm: this.props.loanTerm,
            currentLoan: this.props.currentLoan,
            selectedLoan: this.props.selectedLoan,
            //customerLoanTerm: this.props.customerLoanTerm,

            fetchedCustomer: this.props.fetchedCustomer,

            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            penalInterest: this.props.systemInfo.penalInterest


        };

        this.onBankDateChanged = this.onBankDateChanged.bind(this);
        //this.onLoanTermChange = this.onLoanTermChange.bind(this);
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    }


    componentWillMount() {

        //extract state
        const { selectedLoan, systemInfo } = this.state;

        //check that loan  is not empty
        if (!(_.isEmpty(selectedLoan))) {

            if ('penalInterest' in systemInfo) {
                this.setState({ penalInterest: systemInfo.penalInterest });
            }

            //extract selected loan and currency
            const loan = JSON.parse(selectedLoan);
            const currency = loan.currency;
            const defaultCurrency = systemInfo.defaultCurrency;

            if (currency === defaultCurrency) {
                //set loan currency details to match loan currency details
                this.setState({ currency: false, disabled: true});
            } else {
                //set loan currency details to match loan currency details
                this.setState({ currency: true,  disabled: true});
            }
        }

    }


    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.loanTerm !== this.props.loanTerm) {
            this.setState({loanTerm: nextProps.loanTerm})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.paidAmount !== this.props.paidAmount) {
            this.setState({paidAmount: nextProps.paidAmount})
        }
        if (nextProps.bankName !== this.props.bankName) {
            this.setState({bankName: nextProps.bankName})
        }
        if (nextProps.bankDate !== this.props.bankDate) {
            this.setState({bankDate: nextProps.bankDate})
        }
        if (nextProps.comments !== this.props.comments) {
            this.setState({comments: nextProps.comments})
        }
        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }
        if (nextProps.loanTermStatus !== this.props.loanTermStatus) {
            this.setState({loanTermStatus: nextProps.loanTermStatus})
        }
        if (nextProps.selectedLoan !== this.props.selectedLoan) {
            //check that loan  is not empty
            if (!(_.isEmpty(nextProps.selectedLoan))) {

                const loan = JSON.parse(nextProps.selectedLoan);
                const currency = loan.currency;
                const defaultCurrency = nextProps.systemInfo.defaultCurrency;

                if (currency === defaultCurrency) {
                    //set loan currency details to match loan currency details
                    this.setState({ currency: false});
                } else {
                    //set loan currency details to match loan currency details
                    this.setState({ currency: true});
                }
            }
            this.setState({selectedLoan: nextProps.selectedLoan})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            if ("penalInterest" in nextProps.systemInfo) {
                this.setState({ penalInterest: nextProps.systemInfo.penalInterest });
            }
            this.setState({systemInfo: nextProps.systemInfo});
        }

        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer) {
            this.setState({fetchedCustomer: nextProps.fetchedCustomer})
        }

    }

    onBankDateChanged(date) {
        this.props.bankDateChanged(date)
    }

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.collectionCurrencyChanged(status)
    }

    // onLoanTermChange(value) {
    //     console.log(value);
    //     this.props.customerLoanTermChanged(value)
    // }

    onSaveCashCollection() {

        const { profile, fetchedCustomer, selectedLoan, systemInfo, penalInterest } = this.state;

        let { paidAmount, bankName, bankDate, comments, currency } = this.state;


        if (!(_.isEmpty(systemInfo))){
            //initialize penal interest
            //
            if (!(penalInterest)){
                // not true
                if (!(_.isEmpty( selectedLoan))) {

                    const loan = JSON.parse(selectedLoan);
                    const loanID = loan.loanID;

                    const customerID = fetchedCustomer.customerID;
                    const customerName = fetchedCustomer.customerName;

                    //determine cash collection is local currency or usd
                    let rawCurrency;
                    if (currency) {
                        rawCurrency = "usd";
                    } else {
                        rawCurrency = systemInfo.defaultCurrency;
                    }

                    //TODO: Change date to loan term ID
                    const stringCashCollectionID = moment(bankDate).unix();
                    const cashCollectionString = stringCashCollectionID.toString();
                    const cashCollectionID = `${loanID}${cashCollectionString}`;

                    paidAmount = parseInt(paidAmount);

                    const paidDate = bankDate;

                    //comment object
                    const timeStamp = new Date();
                    const collectorID = profile.userID;


                    //TODO: check that all required fields are filled.
                    if ( customerID && loanID && cashCollectionID && paidAmount && bankName && paidDate ) {
                        if (comments) {
                            const comment = { timeStamp, collectorID, comments };
                            //all properties are fed
                            this.props.saveCashCollection({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName: bankName.toUpperCase(), paidDate, comment, currency: rawCurrency });
                        } else {
                            let comments = null;
                            const comment = { timeStamp, collectorID, comments };
                            //all properties are fed
                            this.props.saveCashCollection({ customerID, customerName, loanID, cashCollectionID, paidAmount, bankName: bankName.toUpperCase(), paidDate, comment, currency: rawCurrency });
                        }
                    } else {
                        //properties are empty
                        message.warning("Fill in all fields");
                    }

                } else {
                    message.warning("There is no loan to update");
                }
            } else {
                // penal interest is true
                message.warning("Please switch off penal interest before you proceed to record cash collection");
            }
        }

    }

    render() {

        const {systemInfo} = this.state;
        const currencyAbv = systemInfo.defaultCurrency.toUpperCase();

        return (
            <div className={"collectionLoanBoxContainer"}>
                <div className={"collectionLoanBox"}>
                    {/*<div className={"termBox"}>*/}
                        {/*<SelectLoanTerm*/}
                            {/*label={'Due Date'}*/}
                            {/*placeholder={'select Due Date'}*/}
                            {/*onChange={this.onLoanTermChange}*/}
                            {/*value={this.state.customerLoanTerm}*/}
                        {/*/>*/}
                    {/*</div>*/}

                    <div className={"termBox"}>
                        <p>Currency</p>
                        <Switch
                            checkedChildren="USD"
                            unCheckedChildren={currencyAbv}
                            disabled={this.state.disabled}
                            onChange={() => this.onCurrencySwitchChange() }
                            checked={this.state.currency}
                        />
                    </div>

                    <div className={"termBox"}>
                        <Input
                            label={'Paid Amount'}
                            type={'number'}
                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'paidAmount', value: e.target.value})}
                            value={this.state.paidAmount}
                        />
                    </div>
                    <div className={"termBox"}>
                        <Input
                            label={'Bank Name'}
                            type={'text'}
                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'bankName', value: e.target.value})}
                            value={this.state.bankName}
                        />
                    </div>
                    <div className={"termBox"}>
                        <PickDate
                            label={'Bank Date'}
                            onChange={this.onBankDateChanged}
                            value={this.props.bankDate}
                        />
                    </div>
                    <div className={"termBox"}>
                        <TextArea
                            label={'Comments'}
                            onChange={e => this.props.loanTermCardDetailsChanged({prop: 'comments', value: e.target.value})}
                            value={this.props.comments}
                        />
                    </div>
                </div>

                <div className={"collectionLoanUpdateBoxButton"}>
                    {
                        this.props.updateCollectionLoading?
                            <BeatLoader/>:
                            <Button
                                text={'Save'}
                                onPress={() => this.onSaveCashCollection()}
                            />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { fetchedCustomer, currentLoanTerms, currentLoan, selectedLoan, paidAmount, bankName, bankDate, comments, currency, loanTerm, updateCollectionLoading } = state.collections;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        //
        fetchedCustomer,
        currentLoanTerms,
        currentLoan,
        loanTerm,
        paidAmount,
        bankName,
        bankDate,
        comments,
        currency,
        profile,
        systemInfo,
        selectedLoan,
        updateCollectionLoading,
    }
};


export default connect(mapStateToProps,{bankDateChanged, loanTermCardDetailsChanged, saveCashCollection, customerLoanTermChanged, collectionCurrencyChanged})(SelectedLoan);