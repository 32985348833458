import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from "moment";
import arraySort from "array-sort";

//components import
import RollOver1Card from "./cards/RollOver1Card";

import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";
import { fetchRolloverOverdue } from "../../actions/MasterListActions";
import {BeatLoader} from "react-spinners";


class RollOverNewCases extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // bouncedCheques: this.props.bouncedCheques,
            rolloverLoading: this.props.rolloverLoading,
            rolloverOverdue: this.props.rolloverOverdue,
            // localCurrency: "TSH",
        };

    }

    componentWillMount(){
        this.props.fetchRolloverOverdue();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.rolloverOverdue !== this.props.rolloverOverdue) {
            this.setState({ rolloverOverdue: nextProps.rolloverOverdue })
        }
        if(nextProps.rolloverLoading !== this.props.rolloverLoading) {
            this.setState({ rolloverLoading: nextProps.rolloverLoading })
        }
    }

    renderRollOverData(){
        const {rolloverOverdue, rolloverLoading} = this.state;

        if(rolloverLoading){
            return <BeatLoader/>
        } else {
            if(rolloverOverdue.length !== 0){
                return (
                    <div>
                        {
                            rolloverOverdue.map(overdue => {
                                return <RollOver1Card overdue={overdue}/>
                            })
                        }
                    </div>
                );
            } else {
                return <h3 className={"userAdminsEmptyWarning"}>There is no data to display</h3>;
            }
        }
    }

    render() {
        const month = moment().format('MMMM');
        const year  = moment().format('YYYY');
        const newCase = `New Cases - ${month} ${year}`;
        return (
            <div className={"dashboardContainer"}>
                <h2>{newCase}</h2>

                <div>
                    <div className={"rolloverHeaders"}>
                        <div><h4>TRANSACTION DATE</h4></div>
                        <div><h4>CUSTOMER NAME</h4></div>
                        <div><h4>OVERDUE IN USD</h4></div>

                    </div>
                    
                        <div>{this.renderRollOverData()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { rolloverOverdue, rolloverLoading } = state.masterList;

    return {
        rolloverOverdue,
        rolloverLoading
    }
};

export default connect(mapStateToProps, {fetchRolloverOverdue})(RollOverNewCases);