import React from "react";
import { connect } from "react-redux";
import { message, Checkbox } from "antd";

//action imports
import { updateAdminUser, userRoleChanged } from "../../actions/ProfileActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

//component imports
import UserAccess from "./subComponents/UserAccess";
import {Button} from "../common/Button";

//file imports
import flower from '../../assets/images/flower.png';
import {ClipLoader} from "react-spinners";
import {Input} from "../common/Input";

class ManageUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userRole: this.props.userRole,
            profile: this.props.profile,
            fetchedAdmin: this.props.fetchedAdmin,
            userAccess: this.props.userAccess,
            loading: this.props.loading,
        };
    }

    componentWillMount() {
        const fetchedAdmin = this.props.fetchedAdmin;

        console.log("user access");
        console.log(fetchedAdmin);

        this.setState({ userAccess: fetchedAdmin.userAccess });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.userRole !== this.props.userRole) {
            this.setState({ userRole: nextProps.userRole });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.fetchedAdmin !== this.props.fetchedAdmin) {
            this.setState({ fetchedAdmin: nextProps.fetchedAdmin });
        }

        if (nextProps.userAccess !== this.props.userAccess) {
            this.setState({ userAccess: nextProps.userAccess });
        }

        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading });
        }
    }

    renderUserRoleInput() {
        const {userRole} = this.state;

        if (userRole !== "collector") {
            return (
                <div>
                    <Input
                        type={'text'}
                        placeholder={'User role'}
                        onChange={e => this.props.userRoleChanged({prop: 'userRole', value: e.target.value}) }
                        value={this.state.userRole}
                    />
                </div>
            );
        } else {
            return null;
        }
    }

    onRoleChange() {
        //
        const status = !this.state.userRole;

        if (status) {
            const userRole = "collector";
            this.props.userRoleChanged({prop: 'userRole', value: userRole});
        } else {
            const userRole = "";
            this.props.userRoleChanged({prop: 'userRole', value: userRole});
        }
    }

    onUpdate() {
        //extract state
        const { fetchedAdmin, userAccess, userRole } = this.state;
        console.log(userRole);

        //check if any change was made
        if (fetchedAdmin.userAccess !== userAccess || userRole) {
            //change was made
            message.success("Change was made"); //warning
            this.props.updateAdminUser({ userID: fetchedAdmin.userID, userAccess , role: userRole});
        } else {
            //no change was made
            message.info("No change was made");
        }
    }

    renderUpdateButton() {
        //extract state
        const { loading } = this.state;

        if (loading) {
            return <ClipLoader size={20} css={{marginLeft: 55}}/>
        } else {
            return(
                <Button
                    text={"Update User Access"}
                    onPress={ () => this.onUpdate() }
                />
            );
        }
    }

    checkUserAccess() {
        //extract state
        const {profile, fetchedAdmin, userAccess} = this.state;

        const access = profile.userAccess.some(access => access === "profiles");

        if (access) {
            return(
                <div className={"manageUserContainer"}>
                    <h2>Manage User</h2>
                    <div className={"manageUserBox"}>
                        <h3><FontAwesomeIcon icon={faUserCircle} /> {fetchedAdmin.name}</h3>
                        <div>
                            <UserAccess
                                value={userAccess}
                            />
                        </div>
                        <div className={"selectUserRoleBox"}>
                            <div>
                                <Checkbox onChange={() => this.onRoleChange()}><span style={{color: '#4262e9'}}>/</span> Collector <span style={{color: '#4262e9'}}>/</span> </Checkbox>
                            </div>
                            {this.renderUserRoleInput()}
                        </div>
                        { this.renderUpdateButton() }
                        <div className={"signUpArtwork"}>
                            <img className={"flowerOne"} src={flower} alt="flower artwork" />
                            <img className={"flowerTwo"} src={flower} alt="flower artwork" />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {
        return(
            <div>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { userAccess } = state.authentication;
    const { userRole, profile, fetchedAdmin, loading } = state.profile;

    return {
        userRole,
        profile,
        fetchedAdmin,
        userAccess,
        loading
    };
};

export default connect(mapStateToProps, { updateAdminUser, userRoleChanged })(ManageUser);