import React from "react";
import { connect } from "react-redux";
import _ from "lodash";

//action imports
import { fetchLoanTerms, fetchLoan, clearLoanPage, fetchLoanLogs } from "../../../actions/LoanActions";
import { fetchCashCollections } from "../../../actions/CollectionsActions";


//components imports
import LoanStatement from "./subComponents/LoanStatement";
import CustomerLoanSummaryCard from "./Cards/CustomerLoanSummaryCard";
import LoanSummaryCard from "./Cards/LoanSummaryCard";
import LoanTermsCard from "./Cards/LoanTermsCard";
import {BeatLoader} from "react-spinners";


class LoanPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            currentLoanTerms: this.props.currentLoanTerms,
            currentLoan: this.props.currentLoan,
            currentLoanNotFound: this.props.currentLoanNotFound,

            fetchedCustomerLoans: this.props.fetchedCustomerLoans,

            profile: this.props.profile,
        };
    }

    componentWillMount() {

        if (!(_.isEmpty(this.state.currentLoan))) {
            const { customerID, loanID } = this.state.currentLoan;
            this.props.fetchLoanTerms({ customerID, loanID, });
            this.props.fetchLoanLogs({ customerID, loanID, });
            this.props.fetchCashCollections({ customerID, loanID });

            this.props.fetchLoan({ customerID, loanID });
        }

    }

    componentWillUnmount() {
        this.props.clearLoanPage();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan});
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.currentLoanNotFound !== this.props.currentLoanNotFound) {
            this.setState({currentLoanNotFound: nextProps.currentLoanNotFound})
        }

    }

    contentsDisplay() {
        const {currentLoan, currentLoanTerms} = this.state;

        //check if current loan is not empty then display contents else display loader
        if (!(_.isEmpty(currentLoan))) {
            return (
                <div>
                    <div>
                        <CustomerLoanSummaryCard
                            currentLoan={currentLoan}
                            currentLoanTerms={currentLoanTerms}
                        />
                    </div>

                    <div>
                        {/*<p>{ currentLoan.modulo ? currentLoan.modulo : null }</p>*/}
                        <LoanStatement
                            currentLoan={currentLoan}
                            currentLoanTerms={currentLoanTerms}
                        />
                    </div>

                    <div>
                        <h4 className={"loanSummaryHeading"}>Loan Summary</h4>
                        <div className={"loanPageCardBox"}>
                            <LoanSummaryCard
                                currentLoan={currentLoan}
                                currentLoanTerms={currentLoanTerms}
                            />
                        </div>
                    </div>

                    <div>
                        <LoanTermsCard
                            currentLoan={currentLoan}
                            currentLoanTerms={currentLoanTerms}
                        />
                    </div>
                </div>
            );
        } else {
            if (this.state.currentLoanNotFound) {
                return (
                    <div>
                        <p>Loan details will be available soon...</p>
                    </div>
                );
            } else {
                return (
                    <div>
                        <BeatLoader/>
                    </div>
                );
            }
        }
    }

    checkUserAccess() {
        //extract state
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "clientsView");

        if (access) {
            return (
                <div>
                    {this.contentsDisplay()}
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {

        return (
            <div className={"loanPageContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { currentLoanTerms, currentLoan, fetchedCustomerLoans, currentLoanNotFound } = state.loans;
    const { profile } = state.profile;

    return {
        //
        currentLoanTerms,
        currentLoan,
        fetchedCustomerLoans,
        currentLoanNotFound,

        profile,
    }
};

export default connect(mapStateToProps, { fetchLoanTerms, fetchLoan, fetchCashCollections, fetchLoanLogs, clearLoanPage })(LoanPage);