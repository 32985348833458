import React from 'react';
import { connect } from 'react-redux';
import {message, Modal} from "antd";

//actions imports
import { filterBucketChanged } from "../../../actions/MasterListActions";

//component imports
import { Button } from '../../common/Button';
import SelectFilterBucket from '../../common/SelectFilterBucket';

class FilterBucketCard extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            visible: false,
            filterBucket: this.props.filterBucket,
        };

        this.onFilterBucket = this.onFilterBucket.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.filterBucket !== this.props.filterBucket) {
            this.setState({ filterBucket: nextProps.filterBucket })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onFilterBucket(value) {
        this.props.filterBucketChanged(value);
        message.success("Filtered");
    }


    render(){

        return (
            <div>
                <Button
                    text={"Filter By Buckets"}
                    onPress={() => this.showModal() }
                />

                <Modal
                    title="Filter Loans by bucket"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    <div className={"modalBox"}>
                        <div className={"filterBucketBox"}>
                            <SelectFilterBucket 
                                label={"Select Bucket Quantity"}
                                onChange={(value) => this.onFilterBucket(value)}
                                value={this.state.filterBucket}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    //fetch state properties from specific reducer
    const { filterBucket } = state.masterList;

    return {
        filterBucket,
    }
};


export default connect(mapStateToProps, {filterBucketChanged})(FilterBucketCard);
