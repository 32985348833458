import React from "react";
import { connect } from "react-redux";
import { Checkbox, Row } from 'antd';

//action imports
import { userAccessChanged } from "../../../actions/AuthenticationActions";

class UserAccess extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        };
    }

    onChange(checkedValues) {
       this.props.userAccessChanged(checkedValues);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.value !== this.props.value) {
            this.setState({ value: nextProps.value })
        }
    }

    render() {
        return(
            <div className={"userAccess"}>
                <h3>Account Page Access</h3>
                <Checkbox.Group className={"userAccessCheckbox"} onChange={(values) => this.onChange(values)} value={this.state.value}>
                    <Row>
                        <Checkbox value="collections">Collections</Checkbox>
                    </Row>
                    <Row>
                        <Checkbox value="unIdentified">Unidentified</Checkbox>
                    </Row>

                    <div className={"masterlistUserAccess"}>
                        <h5>Master List</h5>
                        <Row>
                            <Checkbox value="masterListComments">Comments</Checkbox>
                        </Row>
                        <Row>
                            <Checkbox value="masterListUpdates">Updates</Checkbox>
                        </Row>
                        <Row>
                            <Checkbox value="masterListView">View</Checkbox>
                        </Row>
                    </div>

                    <div className={"clientUserAccess"}>
                        <h5>Clients</h5>
                        <Row>
                            <Checkbox value="clientComments">Comments</Checkbox>
                        </Row>
                        <Row>
                            <Checkbox value="clientsUpdates">Updates</Checkbox>
                        </Row>
                        <Row>
                            <Checkbox value="clientsView">View</Checkbox>
                        </Row>
                    </div>
                    
                    <div className={"clientUserAccess"}>
                        <h5>Analytics</h5>
                        <Row>
                            <Checkbox value="analytics">Analytics</Checkbox>
                        </Row>
                        <Row>
                            <Checkbox value="monthlyTarget">Monthly Target</Checkbox>
                        </Row>
                    </div>

                    <Row>
                        <Checkbox value="reports">Reports</Checkbox>
                    </Row>
                    <Row>
                        <Checkbox value="pdc">PDC</Checkbox>
                    </Row>
                    <Row>
                        <Checkbox value="profiles">Profiles</Checkbox>
                    </Row>
                    <Row>
                        <Checkbox value="settings">Settings</Checkbox>
                    </Row>
                </Checkbox.Group>
            </div>
        );
    }
}

export default connect(null, { userAccessChanged })(UserAccess);