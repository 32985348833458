import React from 'react';
import { DatePicker } from 'antd';


export class PickDate extends React.Component {

    render() {

        return(
            <div>
                <div className={"pickDateLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <div>
                    <DatePicker
                        onChange={this.props.onChange}
                        value={this.props.value}
                    />
                </div>
            </div>
        );
    }
}