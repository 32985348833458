import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'
import _ from "lodash";

 //actions imports
import { pushToCustomerProfile, clearExistingCustomer } from "../../../actions/CustomerActions";

 //components imports
import SelectCustomer from "../../common/SelectCustomer";
 import {Button} from "../../common/Button";

class ChooseExistingCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedCustomer: this.props.fetchedCustomer
        }

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillUnmount() {
        this.props.clearExistingCustomer();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer) {
            this.setState({ fetchedCustomer: nextProps.fetchedCustomer })
        }
    }


    onSubmit() {
        const { fetchedCustomer } = this.state;
        //check if there's a fetched customer is not empty
        if (!(_.isEmpty(fetchedCustomer))) {
            console.log('there is a customer');
            //there's a fetched customer
            const history = this.props.history;
            this.props.pushToCustomerProfile({ history, customerID: fetchedCustomer.customerID });
        } else {
            console.log('there is NO a customer');
        }
    }

    render() {
        return (
            <div className={"existingCustomerModalContainer"}>
                <div className={"existingCustomerModalHeaderBox"}>
                    <h2>Existing Customer</h2>
                    <h4>Search for an existing customer</h4>
                </div>
                <div>
                    <SelectCustomer
                        placeholder={'Enter customer ID/name'}
                        label={'Select Customer'}
                    />
                </div>
                <div className={"existingCustomerModalButton"}>
                    <Button
                        text={'Submit'}
                        onPress={ ()=> this.onSubmit() }
                    />
                </div>
            </div>
        );
    }
}

 const mapStateToProps = state => {
     const { fetchedCustomer } = state.customer;

     return {
         //
         fetchedCustomer,
     }
 };

 export default withRouter(connect(mapStateToProps, { pushToCustomerProfile, clearExistingCustomer })(ChooseExistingCustomer));