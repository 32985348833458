import React from 'react';
import { connect } from "react-redux";
//import {Alert} from "antd";

//import actions
import { fetchExchangeRates } from "../../actions/SettingsActions";

//component imports
import CurrencyCard from './cards/CurrencyCard';
import ClearLoansCard from './cards/ClearLoansCard';
import ActivateMigration from './cards/ActivateMigration';
import IncorrectLoanContainer from "./subComponents/IncorrectLoanContainer";
import CurrentLoansContainer from "./subComponents/CurrentLoansContainer";
//import SurplusLoansContainer from "./subComponents/SurplusLoansContainer";
//import IncompleteLoansContainer from "./subComponents/IncompleteLoansContainer";

class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
        };
    }

    componentWillMount() {
        this.props.fetchExchangeRates()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

    }


    checkUserAccess() {
        //extract state
        const { profile } = this.state;

        const access = profile.userAccess.some(access => access === "settings");

        if (access) {
            return (
                <div>
                    <div>
                        <h4>App Settings</h4>

                        <div>
                            <CurrencyCard
                                initial={false}
                            />
                        </div>

                        <div>
                            <ActivateMigration />
                        </div>

                        <div>
                            <ClearLoansCard />
                        </div>

                        <div>
                            <CurrentLoansContainer/>
                        </div>

                        <div>
                            <IncorrectLoanContainer/>
                        </div>

                        {/*<div>*/}
                        {/*    <IncompleteLoansContainer/>*/}
                        {/*</div>*/}
                        <p style={{ textAlign: "center", fontFamily: "myriadBold"}}>V1.0.8x</p>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render(){
        return (
            <div className={"settingsContainer"}>

                { this.checkUserAccess() }
            </div>
        )
    }
}

const mapStateToProps = state => {

    const { profile } = state.profile;

    return {
        profile,
    }
};

export default connect(mapStateToProps, { fetchExchangeRates })(Settings);
