import {
    BANK_DATE_CHANGED,
    CHEQUE_SWITCH_CHANGED,
    CUSTOMER_LOAN_DETAILS_CHANGED,
    CUSTOMER_LOAN_TERM_CHANGED,
    DUE_DATE_CHANGED,
    FETCH_LOAN_TERMS,
    FETCH_LOAN_TERMS_SUCCESSFUL,
    FETCH_LOANS,
    FETCH_LOANS_FAILED,
    FETCH_LOANS_SUCCESSFUL,
    HIDE_EXTRA_FEE_CARD,
    HIDE_PENAL_INTEREST_CARD,
    HIDE_TOTAL_PAID_PREVIOUSLY_CARD,
    LOAN_TERM_CARD_DETAILS_CHANGED,
    LOAN_TERM_DETAILS_CHANGED,
    PUSH_CHEQUE_STATUS,
    PUSH_LOAN_TERM,
    PUSHED_CURRENT_LOAN,
    RECORD_CHEQUE_STATUS,
    SAVE_LOAN,
    SAVE_LOAN_SUCCESSFUL,
    SAVE_LOAN_TERM,
    SAVE_LOAN_TERM_SUCCESSFUL,
    SHOW_EXTRA_FEE_CARD,
    SHOW_PENAL_INTEREST_CARD,
    SHOW_TOTAL_PAID_PREVIOUSLY_CARD,
    STATUS_SWITCH_CHANGED,
    CUSTOMER_PENAL_INTEREST_SAVED_SUCCESSFUL,
    EXTRA_FEE_SAVED_SUCCESSFUL,
    PREVIOUS_OVERDUE_SAVED_SUCCESSFUL,
    UPDATE_LOAN_TERM,
    UPDATE_LOAN_TERM_SUCCESSFUL,
    UPDATE_LOAN_TERM_FAILED,
    PENAL_INTEREST_CHANGED,
    PREVIOUS_OVERDUE_CHANGED,
    EXTRA_FEE_CHANGED,
    LEGAL_REPO_STATUS_CHANGED,
    SAVE_LEGAL_REPO_STATUS_SUCCESSFUL,
    CLEAR_LEGAL_REPO_STATUS_SUCCESSFUL,
    SHOW_EARLY_LIQUIDATION_CARD,
    HIDE_EARLY_LIQUIDATION_CARD,
    EARLY_LIQUIDATION_CHANGED,
    LOAN_CURRENCY_CHANGED,
    EARLY_LIQUIDATION_SAVED_SUCCESSFUL,
    SHOW_LEGAL_REPO_STATUS_CARD,
    HIDE_LEGAL_REPO_STATUS_CARD,
    SAVE_LEGAL_REPO_STATUS,
    CLEAR_UNCLEAR_LOAN_SUCCESSFUL,
    CLEAR_LEGAL_REPO_STATUS,
    DELETE_LOAN,
    DELETE_LOAN_SUCCESSFUL,
    CLEAR_UNCLEAR_LOAN,
    SHOW_RESCHEDULE_LOAN_STATUS_CARD,
    HIDE_RESCHEDULE_LOAN_STATUS_CARD,
    RESCHEDULE_LOAN_STATUS_CHANGED,
    SAVE_RESCHEDULE_STATUS,
    SAVE_RESCHEDULE_STATUS_SUCCESSFUL,
    CLEAR_RESCHEDULE_STATUS,
    CLEAR_RESCHEDULE_STATUS_SUCCESSFUL,

    PUSHED_CURRENT_LOAN_SUCCESSFUL,
    PUSHED_CURRENT_LOAN_FAILED,
    CURRENT_LOAN_NOT_FOUND,
    CLEAR_LOAN_PAGE,
    ADDITIONAL_INFO_CHANGED,
    SAVE_ADDITIONAL_INFO,
    SAVE_ADDITIONAL_INFO_SUCCESSFUL,
    SAVE_ADDITIONAL_INFO_FAILED,
    CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES,
    CLEAR_PENAL_CHANGED,
    CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE,
    CLEAR_EXTRA_FEE_MESSAGE,
    CLEAR_PENAL_INTEREST_MESSAGE,
    CLEAR_PREVIOUS_OVERDUE_MESSAGE,
    CLEAR_LEGAL_REPO_MESSAGE,
    CLEAR_EARLY_LIQUIDATION_MESSAGE,
    CLEAR_UNCLEAR_LOAN_MESSAGE,
    CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE,
    CLEAR_DELETE_LOAN_MESSAGE,
    CLEAR_SAVE_LOAN_MESSAGE,
    CLEAR_SAVE_LOAN_TERM_MESSAGE,
    CLEAR_LOAN_TERM_UPDATE_MESSAGE,
    DELETE_LOAN_TERM_SUCCESSFUL,
    CLEAR_DELETE_LOAN_TERM_MESSAGE,
    LOAN_PAYMENT_METHOD_CHANGED,
    SAVE_LOAN_FAILED,
    DELETE_LOAN_FAILED,
    DELETE_LOAN_TERM_FAILED,
    CLEAR_LEGAL_REPO_STATUS_FAILED,
    SAVE_RESCHEDULE_STATUS_FAILED,
    CLEAR_RESCHEDULE_STATUS_FAILED,
    SAVE_LEGAL_REPO_STATUS_FAILED,
    SAVE_LOAN_TERM_FAILED,
    EXTRA_FEE_SAVED_FAILED,
    EXTRA_FEE_SAVED,
    CURRENT_LOAN_TERMS_NOT_FOUND,
    ENABLE_UPDATE_TERM_BTN,
    DELETE_EXTRA_FEE,
    DELETE_EXTRA_FEE_SUCCESSFUL,
    DELETE_EXTRA_FEE_FAILED,
    FETCH_LOAN_LOGS_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {

    fetchedCustomerLoans: {},
    currentLoanTerms: {},
    loanLogs: {},
    currentLoan:{},
    loanTerm: {},

    loanID: '',
    totalAmount: '',
    loanTerms: '',

    //loanTerm
    loanTermID: '',
    amount: '',
    dueDate: '',
    paidAmount: '',
    cheque: false,
    clearPenal: false,
    chequeName: '',
    chequeNumber: '',
    chequeStatus: '',
    transactionDate: '',
    bankName: '',
    bankDate: '',
    comments: '',
    currency: false,
    loanTermStatus: false,
    customerLoanTerm: '',
    buttonDisabled: false,

    //additional information
    salesExe: '',
    clientProfile:'',
    truck: '',
    industry: '',
    model: '',
    segment: '',
    clientAction: '',
    character: '',
    additionalInfoLoading: false,
    additionalInfoSaved: false,
    additionalInfoFailed: false,

    //currentLoan
    currentLoanLoading: false,
    currentLoanNotFound: false,

    //loan legal/repo status
    legalRepoStatus: '',
    showLegalRepoStatusCard: false,
    saveLegalRepoStatusLoading: false,
    legalRepoStatusSaved: false,
    legalRepoStatusFailed: false,
    legalRepoStatusCleared: false,
    legalRepoStatusClearFailed: false,

    //reschedule loan status
    rescheduleStatus: '',
    saveRescheduleStatusLoading: false,
    rescheduleStatusCleared: false,
    rescheduleStatusClearFailed: false,
    rescheduleStatusSaved: false,
    rescheduleStatusFailed: false,

    saveLoanLoading: false,
    saveLoanFailed: false,
    loading: false,
    fetchLoanTermsLoading: false,
    saveLoanTermLoading: false,
    currentLoanTermsNotFound: false,
    loanTermSaved: false,
    saveLoanTermFailed: false,

    fetching: false,
    visible: false,
    loanSaved: false,

    chequeState: false,
    bouncedReason: "",
    otherReason: "",

    showPenalInterestCard: false,
    showExtraFeeCard: false,
    showTotalPaidPreviouslyCard: false,

    showEarlyLiquidationCard: false,
    earlyLiquidationLoading: false,


    updateLoanTermLoading: false,
    updateLoanTermFailed: false,
    updateLoanTermSuccessful: false,
    //penal interest state
    penalInterest: 0,
    customPenalInterest: 0,
    penalInterestSaved: false,
    penalInterestFailed: false,

    //total paid previously state
    previousOverdue: 0,
    previousOverdueSaved: false,
    previousOverdueFailed: false,

    //early liquidation state
    earlyLiquidation: 0,
    earlyLiquidationSaved: false,
    earlyLiquidationFailed: false,

    //extra fee state
    extraFeeName: "",
    extraFee: 0,
    extraFeeDate: "",
    extraFeeSaved: false,
    extraFeeFailed: false,
    deleteExtraFeeLoading: false,
    deleteExtraFeeSuccessful: false,
    deleteExtraFeeFailed: false,
    deleteLoanLoading: false,

    deleteLoanSuccessful: false,
    deleteLoanFailed: false,
    deleteLoanTerm: false,
    deleteLoanTermFailed: false,
    clearUnclearLoanLoading: false,
    clearUnclearLoanSuccessful: false,
    extraFeeLoading: false,
};

const LoanReducer = ( state = INITIAL_STATE, action ) => {

    switch (action.type) {

        //save loan
        case CUSTOMER_LOAN_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value};
        case LOAN_PAYMENT_METHOD_CHANGED:
            return {...state, cheque: action.payload };
        case LOAN_CURRENCY_CHANGED:
            return {...state, currency: action.payload };
        case SAVE_LOAN:
            return  {...state, saveLoanLoading: true};
        case SAVE_LOAN_SUCCESSFUL:
            return  {...state, loanID: "", totalAmount: "",  loanTerms: "", cheque: false, saveLoanLoading: false, visible: false, loanSaved: true};
        case SAVE_LOAN_FAILED:
            return {...state,  loanID: "", totalAmount: "",  loanTerms: "", cheque: false, visible: false, saveLoanLoading: false, saveLoanFailed: true };

            //save additional info
        case ADDITIONAL_INFO_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case SAVE_ADDITIONAL_INFO:
            return {...state, additionalInfoLoading: true};
        case SAVE_ADDITIONAL_INFO_SUCCESSFUL:
            return {...state, additionalInfoLoading: false, additionalInfoSaved: true, salesExe: "", clientProfile: "", truck: "", industry: "", model: "", segment: "", character: "", clientAction: ""};
        case SAVE_ADDITIONAL_INFO_FAILED:
            return {...state, additionalInfoLoading: false, salesExe: "", clientProfile: "", truck: "", industry: "", model: "", segment: "", character: "", clientAction: ""};
        case CLEAR_SAVE_ADDITIONAL_INFO_MESSAGES:
            return {...state, additionalInfoLoading: false, additionalInfoSaved: false, salesExe: "", clientProfile: "", truck: "", industry: "", model: "", segment: "", character: "", clientAction: ""};

            //loan legal/repo status
        case SHOW_LEGAL_REPO_STATUS_CARD:
            return {...state, showLegalRepoStatusCard: true, legalRepoStatusSaved: false, legalRepoStatusCleared: false };
        case HIDE_LEGAL_REPO_STATUS_CARD:
            return {...state, showLegalRepoStatusCard: false, legalRepoStatusSaved: false, legalRepoStatusCleared: false };
        case LEGAL_REPO_STATUS_CHANGED:
            return {...state, legalRepoStatus: action.payload, legalRepoStatusSaved: false, legalRepoStatusCleared: false, loanTermSaved: false };
        case SAVE_LEGAL_REPO_STATUS:
            return {...state, saveLegalRepoStatusLoading: true };
        case SAVE_LEGAL_REPO_STATUS_SUCCESSFUL:
            return {...state, legalRepoStatus: "", saveLegalRepoStatusLoading: false, legalRepoStatusSaved: true, legalRepoStatusCleared: false, };
        case SAVE_LEGAL_REPO_STATUS_FAILED:
            return {...state, legalRepoStatus: "", saveLegalRepoStatusLoading: false, legalRepoStatusFailed: true, legalRepoStatusCleared: false, }
        case CLEAR_LEGAL_REPO_STATUS:
            return {...state, legalRepoStatusSaved: false};
        case CLEAR_LEGAL_REPO_STATUS_SUCCESSFUL:
            return {...state, legalRepoStatus: "", legalRepoStatusCleared: true, legalRepoStatusSaved: false, };
        case CLEAR_LEGAL_REPO_STATUS_FAILED:
            return {...state, legalRepoStatus: "", legalRepoStatusClearFailed: true, legalRepoStatusSaved: false, };

            //reschedule loan status
        case SHOW_RESCHEDULE_LOAN_STATUS_CARD:
            return {...state, showRescheduleStatusCard: true, rescheduleStatusSaved: false, rescheduleStatusCleared: false };
        case HIDE_RESCHEDULE_LOAN_STATUS_CARD:
            return {...state, showRescheduleStatusCard: false, rescheduleStatusSaved: false, rescheduleStatusCleared: false };
        case RESCHEDULE_LOAN_STATUS_CHANGED:
            return {...state, rescheduleStatus: action.payload, rescheduleStatusSaved: false, rescheduleStatusCleared: false };
        case SAVE_RESCHEDULE_STATUS:
            return {...state, saveRescheduleStatusLoading: true };
        case SAVE_RESCHEDULE_STATUS_SUCCESSFUL:
            return {...state, rescheduleStatus: "", saveRescheduleStatusLoading: false, rescheduleStatusSaved: true, rescheduleStatusCleared: false, };
        case SAVE_RESCHEDULE_STATUS_FAILED:
            return {...state, rescheduleStatus: "", saveRescheduleStatusLoading: false, rescheduleStatusFailed: true, rescheduleStatusCleared: false, };
        case CLEAR_RESCHEDULE_STATUS:
            return {...state, rescheduleStatusSaved: false};
        case CLEAR_RESCHEDULE_STATUS_SUCCESSFUL:
            return {...state, rescheduleStatus: "", rescheduleStatusCleared: true, rescheduleStatusSaved: false, };
        case CLEAR_RESCHEDULE_STATUS_FAILED:
            return {...state, rescheduleStatus: "", rescheduleStatusClearFailed: true, rescheduleStatusSaved: false, };

        //fetch reducers
        case FETCH_LOANS:
            return {...state, loading: true};
        case FETCH_LOANS_SUCCESSFUL:
            return {...state, fetchedCustomerLoans: action.payload, loading: false };
        case FETCH_LOANS_FAILED:
            return {...state, loading: false };
        case FETCH_LOAN_TERMS:
            return {...state, fetchLoanTermsLoading: true};
        case PUSHED_CURRENT_LOAN:
            return {...state, currentLoanLoading: true };
        case PUSHED_CURRENT_LOAN_SUCCESSFUL:
            return {...state, currentLoan: action.payload, updateLoanTermSuccessful: false, updateLoanTermFailed: false, currentLoanLoading: false };
        case PUSHED_CURRENT_LOAN_FAILED:
            return {...state, currentLoanLoading: false, currentLoanFailed: true };
        case CURRENT_LOAN_NOT_FOUND:
            return {...state, currentLoanLoading: false, currentLoanNotFound: true};
        case FETCH_LOAN_TERMS_SUCCESSFUL:
            return {...state, currentLoanTerms: action.payload, fetchLoanTermsLoading: false, loanTermSaved: false, deleteLoanTermSuccessful: false };
        case CURRENT_LOAN_TERMS_NOT_FOUND:
            return {...state, fetchLoanTermsLoading: false, currentLoanTermsNotFound: true };
        case FETCH_LOAN_LOGS_SUCCESSFUL:
            return { ...state, loanLogs: action.payload };

        //push tp customer loan terms reducer
        case LOAN_TERM_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, loanTermSaved: false, extraFeeSaved: false, updateLoanTermSuccessful: false, };
        case LOAN_TERM_CARD_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, loanTermSaved: false, extraFeeSaved: false, updateLoanTermSuccessful: false, };
        case RECORD_CHEQUE_STATUS:
            return {...state, chequeStatus: action.payload};
        case DUE_DATE_CHANGED:
            return {...state, dueDate: action.payload, updateLoanTermSuccessful: false, loanTermSaved: false};
        case BANK_DATE_CHANGED:
            return {...state, bankDate: action.payload};
        case CHEQUE_SWITCH_CHANGED:
            return {...state, cheque: action.payload, updateLoanTermSuccessful: false, loanTermSaved: false };
        case CLEAR_PENAL_CHANGED:
            return {...state, clearPenal: action.payload, updateLoanTermSuccessful: false, loanTermSaved: false };
        case STATUS_SWITCH_CHANGED:
            return {...state, cheque: action.payload};

        case SAVE_LOAN_TERM:
            return {...state, saveLoanTermLoading: true };
        case SAVE_LOAN_TERM_SUCCESSFUL:
            return {...state, amount: '', dueDate: '', loanTermID: '', bankName: '', chequeName: '', chequeNumber: '', saveLoanTermLoading: false, loanTermSaved: true };
        case SAVE_LOAN_TERM_FAILED:
            return {...state, amount: '', dueDate: '', loanTermID: '', bankName: '', chequeName: '', chequeNumber: '', saveLoanTermLoading: false, saveLoanTermFailed: true };

        case ENABLE_UPDATE_TERM_BTN:
            return { ...state, buttonDisabled: false };

        case UPDATE_LOAN_TERM:
            return {...state, updateLoanTermLoading: true, loanTermSaved: false, updateLoanTermSuccessful: false,};
        case UPDATE_LOAN_TERM_SUCCESSFUL:
            return {...state, paidAmount: "", chequeName: "", chequeNumber: "", chequeStatus: "", transactionDate: "", bankName: "", bankDate: "", comments: "", bouncedReason: "", otherReason: "",  loanTermStatus: false,
                updateLoanTermLoading: false, updateLoanTermSuccessful: true, updateLoanTermFailed: false, buttonDisabled: true };
        case UPDATE_LOAN_TERM_FAILED:
            return {...state, updateLoanTermSuccessful: false, updateLoanTermLoading: false, updateLoanTermFailed: true, buttonDisabled: true };
        case PUSH_LOAN_TERM:
            return {...state, loanTerm: action.payload, transactionDate: "", bouncedReason: "", otherReason: "", chequeStatus: ""};

        case CUSTOMER_LOAN_TERM_CHANGED:
            return {...state, customerLoanTerm: action.payload, loanTermSaved: false};
        case PUSH_CHEQUE_STATUS:
            return {...state, chequeState: action.payload };


            //early liquidation reducer
        case SHOW_EARLY_LIQUIDATION_CARD:
            return {...state, showEarlyLiquidationCard: true, earlyLiquidationSaved: false };
        case HIDE_EARLY_LIQUIDATION_CARD:
            return {...state, showEarlyLiquidationCard: false, earlyLiquidationSaved: false };
        case EARLY_LIQUIDATION_CHANGED: 
            return {...state, earlyLiquidation: action.payload, earlyLiquidationSaved: false };
        case EARLY_LIQUIDATION_SAVED_SUCCESSFUL:
            return {...state, earlyLiquidation: 0, earlyLiquidationSaved: true, showEarlyLiquidationCard: false };

            //penal interest reducer
        case SHOW_PENAL_INTEREST_CARD:
            return {...state, showPenalInterestCard: true, penalInterestSaved: false };
        case HIDE_PENAL_INTEREST_CARD:
            return {...state, showPenalInterestCard: false, penalInterestSaved: false };
        case PENAL_INTEREST_CHANGED:
            return { ...state, penalInterest: action.payload, penalInterestSaved: false };
        case CUSTOMER_PENAL_INTEREST_SAVED_SUCCESSFUL:
            return {...state, penalInterest: 0, penalInterestSaved: true, showPenalInterestCard: false };

            //total paid previously reducer
        case SHOW_TOTAL_PAID_PREVIOUSLY_CARD:
            return {...state, showTotalPaidPreviouslyCard: true, previousOverdueSaved: false };
        case HIDE_TOTAL_PAID_PREVIOUSLY_CARD:
            return {...state, showTotalPaidPreviouslyCard: false, previousOverdueSaved: false, };
        case PREVIOUS_OVERDUE_CHANGED:
            return { ...state, previousOverdue: action.payload, previousOverdueSaved: false };
        case PREVIOUS_OVERDUE_SAVED_SUCCESSFUL:
            return {...state, previousOverdue: 0, previousOverdueSaved: true, showTotalPaidPreviouslyCard: false };

            //extra fee reducer
        case SHOW_EXTRA_FEE_CARD:
            return {...state, showExtraFeeCard: true };
        case HIDE_EXTRA_FEE_CARD:
            return {...state, showExtraFeeCard: false, extraFeeSaved: false, loanTermSaved: false };
        case EXTRA_FEE_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value, extraFeeSaved: false };
        case EXTRA_FEE_SAVED:
            return {...state, extraFeeLoading: true}
        case EXTRA_FEE_SAVED_SUCCESSFUL:
            return {...state, extraFeeLoading: false, extraFeeName: "", extraFeeDate: "", extraFee: 0, extraFeeSaved: true, showExtraFeeCard: false, loanTermSaved: false, rescheduleStatusCleared: false, legalRepoStatusCleared: false };
        case EXTRA_FEE_SAVED_FAILED:
            return {...state, extraFeeLoading: false, extraFeeName: "", extraFeeDate: "", extraFee: 0, showExtraFeeCard: false, extraFeeFailed: true };

        case DELETE_EXTRA_FEE:
            return {...state, deleteExtraFeeLoading: true };
        case DELETE_EXTRA_FEE_SUCCESSFUL:
            return {...state, deleteExtraFeeLoading: false, deleteExtraFeeSuccessful: true };
        case DELETE_EXTRA_FEE_FAILED:
            return {...state, deleteExtraFeeLoading: false, deleteExtraFeeFailed: true };

            //loan status switch
        case CLEAR_UNCLEAR_LOAN:
            return {...state, clearUnclearLoanLoading: true };
        case CLEAR_UNCLEAR_LOAN_SUCCESSFUL:
            return {...state, clearUnclearLoanLoading: false, clearUnclearLoanSuccessful: true, };

            //delete loan/loan term
        case DELETE_LOAN:
            return {...state, deleteLoanLoading: true };
        case DELETE_LOAN_SUCCESSFUL:
            return {...state, deleteLoanLoading: false };
        case DELETE_LOAN_FAILED:
            return {...state, deleteLoanLoading: false };

        case DELETE_LOAN_TERM_SUCCESSFUL:
            return {...state, deleteLoanTermSuccessful: true };
        case DELETE_LOAN_TERM_FAILED:
            return {...state, deleteLoanTermFailed: true };

            //clear loan page reducer
        case CLEAR_LOAN_PAGE:
            return { ...state, currentLoan: {}, currentLoanTerms: {}, loanLogs: {} };

            //clear page operations message alerts
        case CLEAR_EXTRA_FEE_MESSAGE:
            return {...state, extraFeeName: "", extraFeeDate: "", extraFee: 0, extraFeeSaved: false, extraFeeFailed: false, deleteExtraFeeSuccessful: false, deleteExtraFeeFailed: false };
        case CLEAR_PENAL_INTEREST_MESSAGE:
            return {...state, penalInterest: 0, penalInterestSaved: false, showPenalInterestCard: false };
        case CLEAR_PREVIOUS_OVERDUE_MESSAGE:
            return {...state, previousOverdue: 0, previousOverdueSaved: false, showTotalPaidPreviouslyCard: false };
        case CLEAR_LEGAL_REPO_MESSAGE:
            return {...state, legalRepoStatus: "", saveLegalRepoStatusLoading: false, legalRepoStatusSaved: false, legalRepoStatusCleared: false, legalRepoStatusFailed: false };
        case CLEAR_EARLY_LIQUIDATION_MESSAGE:
            return {...state, earlyLiquidation: 0, earlyLiquidationSaved: false, showEarlyLiquidationCard: false };
        case CLEAR_UNCLEAR_LOAN_MESSAGE:
            return {...state, clearUnclearLoanLoading: false, clearUnclearLoanSuccessful: false, };
        case CLEAR_RESCHEDULE_LOAN_STATUS_MESSAGE:
            return {...state, rescheduleStatus: "", saveRescheduleStatusLoading: false, rescheduleStatusCleared: false, rescheduleStatusSaved: false, };
        case CLEAR_DELETE_LOAN_MESSAGE:
            return {...state, deleteLoanSuccessful: false, deleteLoanTermSuccessful: false, deleteLoanFailed: false, deleteLoanTermFailed: false  };
        case CLEAR_DELETE_LOAN_TERM_MESSAGE:
            return {...state, deleteLoanTermSuccessful: false, deleteLoanTermFailed: false };
        case CLEAR_LOAN_TERM_UPDATE_MESSAGE:
            return {...state, paidAmount: "", chequeName: "", chequeNumber: "", chequeStatus: "", transactionDate: "", bankName: "", bankDate: "", comments: "", loanTermStatus: false,
                updateLoanTermLoading: false, updateLoanTermSuccessful: false, updateLoanTermFailed: false, };
        case CLEAR_LOAN_TERM_PENAL_STATUS_MESSAGE:
            return {...state,  };
        case CLEAR_SAVE_LOAN_MESSAGE:
            return {...state, loanID: "", totalAmount: "",  loanTerms: "", saveLoanLoading: false, visible: false, loanSaved: false, saveLoanFailed: false };
        case CLEAR_SAVE_LOAN_TERM_MESSAGE:
            return {...state, amount: '', dueDate: '', loanTermID: '', bankName: '', chequeName: '', chequeNumber: '', saveLoanTermLoading: false, loanTermSaved: false, saveLoanTermFailed: false };


        default:
            return state;
    }
};

export default LoanReducer;