import React from 'react';
import { BrowserRouter, Route, NavLink, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


//components import
import SignInPage from "./authentication/SignInPage";
import ForgetPassword from "./authentication/ForgetPassword";


class Landing extends React.Component {
    //
    constructor(props) {
        super(props);

        this.state = {
            user: null,
        }
    }

    renderSignIn() {

        return (
            <div>
                <Route exact path={"/"} component={SignInPage} />
                <Route exact path={"/forgot-password"} component={ForgetPassword} />
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.renderSignIn()}
            </div>
        );
    }

}

export default  withRouter(connect(null)(Landing));