import * as firebase from 'firebase';
import {projectArray} from "../env/PROJECTSCONFIGURATIONS";
//Types import
import {
    CLEAR_LOGOUT_MESSAGES,
    CREDENTIALS_CHANGED, FORGOT_PASSWORD_DETAILS_CHANGED, LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    LOGIN_USER, LOGOUT_FAILED,
    LOGOUT_SUCCESSFULLY,
    ROLE_CHANGED, SIGN_UP_DETAILS_CHANGED, SIGN_UP_USER,
    SIGN_UP_USER_FAILED,
    SIGN_UP_USER_SUCCESSFUL,
    USER_ACCESS_CHANGED
} from "./Types";

//action imports
import {getAdmins} from "./AppActions";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


export const credentialsChanged = ({ prop, value }) => {
    return {
        type: CREDENTIALS_CHANGED,
        payload: {prop, value}
    }
};

export const signUpDetailsChanged = ({ prop, value }) => {
    return {
        type: SIGN_UP_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const roleChanged = (value) => {
    return {
        type: ROLE_CHANGED,
        payload: value
    }
};

export const userAccessChanged = (value) => {
    return {
        type: USER_ACCESS_CHANGED,
        payload: value
    }
};

export const forgetPasswordChanged = ({prop, value}) => {
    return {
        type: FORGOT_PASSWORD_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const userLogin = ({ username, password }) => {
    const email = username;

    return(dispatch) => {

        dispatch({ type: LOGIN_USER });

        //send login details to firebase
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                //if login is successful execute

                dispatch({ type: LOGIN_SUCCESSFUL });
                console.log("login successful");


            })
            .catch(function(error) {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
                console.log(errorCode);
                console.log(errorMessage);
                console.log("log in error");
                dispatch ({ type: LOGIN_FAILED });
            });
    }
};

export const logOutUser = (history) => {

    //send logout request to firebase
    return(dispatch) => {
        firebase.auth().signOut()
            .then(() => {
                //if log out is successful execute
                dispatch({ type: LOGOUT_SUCCESSFULLY });
                console.log("log out successful");
                dispatch({ type: CLEAR_LOGOUT_MESSAGES });

                history.push('/');
            })
            .catch(function(error) {
                // Handle Errors here.
                dispatch({ type: LOGOUT_FAILED });
                const errorCode = error.code;
                // ...
                console.log(errorCode);
                console.log("log out error");
                dispatch({ type: CLEAR_LOGOUT_MESSAGES });
            });
    }
};


//on sign up check what user is making the signing up
export const signUpUser = ({ name, username, password, role, userAccess, history }) => {
    return (dispatch) => {
        dispatch({ type: SIGN_UP_USER});
        const url = `${project.serverUrl}createUser`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ name, username, password, role, userAccess }),
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                getAdmins({ dispatch });
                history.push("/profiles");
                dispatch({
                    type: SIGN_UP_USER_SUCCESSFUL,
                    payload: response
                });
            }).catch((error)=> signUpUserFail(dispatch, error))
    };
};

const signUpUserFail = (dispatch, error) => {

    // Handle Errors here.
    const errorCode = error.code;
    //const errorMessage = error.message;

    switch (errorCode) {
        case "auth/invalid-email":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Fill in valid email"
            });
            break;
        case "auth/account-exists-with-different-credential":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "account already exists"
            });
            break;
        case "auth/credential-already-in-use":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Credential already in use"
            });
            break;
        case "auth/email-already-in-use":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Email already in use"
            });
            break;
        case "auth/network-request-failed":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "There's a network error"
            });
            break;
        case "auth/too-many-requests":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Detected unusual activity"
            });
            break;
        case "auth/user-token-expired":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Please try again in a moment"
            });
            break;
        case "auth/weak-password":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Password should be at least 6 characters"
            });
            break;
        default:
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Authentication failed"
            });
    }
};
