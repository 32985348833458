import React from "react";
import { connect } from "react-redux";

//actions imports
import {fetchUnIdentifiedPayments} from "../../actions/UnidentifiedActions";

//components imports
import AddUnidentifiedPayment from "./subComponents/AddUnidentifiedPayment";
import ViewUnidentifiedPayments from "./subComponents/ViewUnidentifiedPayments";

class  Unidentified extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile
        };
    }

    componentWillMount() {
        this.props.fetchUnIdentifiedPayments();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    checkUserAccess() {
        //extract state
        const { profile } = this.state;

        const access = profile.userAccess.some(access => access === "unIdentified");

        if (access) {
            return(
                <div>
                    <h3>Unidentified Payments</h3>
                    <div>
                        <AddUnidentifiedPayment/>
                    </div>
                    <div>
                        <ViewUnidentifiedPayments/>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {
        return (
            <div className={"unidentifiedContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile
    };
};

export default connect(mapStateToProps, {fetchUnIdentifiedPayments})(Unidentified);