import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from "lodash";
import CommentCard from "./CommentCard";
import {Button, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import {BeatLoader} from "react-spinners";

import { projectArray } from "../../../../env/PROJECTSCONFIGURATIONS";

//action imports
//import { } from "../../../../actions/LoanActions";

//components imports

class LoanSummaryCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            totalPaidPreviously: 0,
            totalPaid: 0,
            supposedToPay: 0,
            surplusOrDeficit: 0,
            outStanding: 0,
            totalOutstanding: 0,
            penalInterest: 0,

            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,

            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            
            localCurrency: "TSH",
        };

        this.calculateSupposedToPay = this.calculateSupposedToPay.bind(this);
        this.totalPaidPreviously = this.totalPaidPreviously.bind(this);
        this.calculateTotalPaid = this.calculateTotalPaid.bind(this);
        this.calculateSurplusOrDeficit = this.calculateSurplusOrDeficit.bind(this);
        this.showTotalPenalInterest = this.showTotalPenalInterest.bind(this);
        this.kickStartFunctions = this.kickStartFunctions.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillMount() {
        //extract state
        const { currentLoanTerms, currentLoan } = this.state;

        if(!(_.isEmpty(currentLoanTerms))) {
            this.calculateSupposedToPay({currentLoanTerms, currentLoan});
            this.showTotalPenalInterest(currentLoanTerms);
        }

        this.checkCurrencyFormat();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan});
            this.kickStartFunctions({ currentLoan: nextProps.currentLoan });
            this.calculateSupposedToPay({ currentLoanTerms: nextProps.currentLoanTerms, currentLoan: nextProps.currentLoan });
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms});
            this.calculateSupposedToPay({ currentLoanTerms: nextProps.currentLoanTerms, currentLoan: nextProps.currentLoan });
            this.showTotalPenalInterest(nextProps.currentLoanTerms);
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile});
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({systemInfo: nextProps.systemInfo});
        }
    }

    kickStartFunctions({currentLoan}) {
        //extract state
        const { totalPaidPreviously } = this.state;

        const { totalCashPaid, totalChequePaid } = currentLoan;

        const totalPaid = totalPaidPreviously + totalCashPaid + totalChequePaid;

        this.setState({ totalPaid });
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, currentLoan, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency = "USD";

        //check if payment object is not empty
        if (!(_.isEmpty(currentLoan))) {
            const loanCurrency = currentLoan.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                }  else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    showTotalPenalInterest(currentLoanTerms) {

        //extract state
        const { currentLoan } = this.state;

        //
        let totalArray = [];

        //check if both currentLoanTerms and currentLoan is not empty
        if (!(_.isEmpty(currentLoanTerms)) && !(_.isEmpty(currentLoan))) {

             _.map(currentLoanTerms, term => {

                if ('termStatus' in term) {

                    const amount = term.termStatus.penalInterest;
                    totalArray.push(amount);
                }
            });

            //calculate the total amount from numbers in the array
            const penalInterest = totalArray.reduce((a, b) => a + b, 0);

            this.setState({ penalInterest });
        }
    }

    calculateSurplusOrDeficit({ supposedToPay, totalPaid }) {
        //extract state
        const { currentLoan } = this.state;

        //check if there are extra fees
        let totalArray = [];
        if ("extraFees" in currentLoan) {
            //loop object and take take extra fee amount from each extra fee
            const extraFees = currentLoan.extraFees;

            _.map(extraFees, extraFee => {
                totalArray.push(extraFee.extraFeeAmount);
            });
        }

        const extraFeeTotal = totalArray.reduce((a, b) => a + b, 0);

        const surplus = totalPaid - supposedToPay;

        //calculate outstanding
        const outStanding = currentLoan.totalAmount - (supposedToPay - extraFeeTotal);

        //calculate total outstanding
        //check if surplus is negative or positive
        let totalOutstanding;

        if (surplus > 0) {
            //its positive
            totalOutstanding  = outStanding - surplus;
        } else {
            //its negative
            totalOutstanding  = outStanding - surplus;
        }

        //totalOutstanding = totalOutstanding + extraFeeTotal;

        this.setState({
            surplusOrDeficit: surplus,
            outStanding: outStanding,
            totalOutstanding: totalOutstanding,
        });

    }

    calculateTotalPaid({ supposedToPay, totalPaidPreviously}) {
        //previously paid + cashPaid + chequePaid
        //extract state
        const { totalCashPaid, totalChequePaid } = this.state.currentLoan;

        const totalPaid = totalPaidPreviously + totalCashPaid + totalChequePaid;

        this.setState({ totalPaid });

        ///calculate surplus/deficit
        this.calculateSurplusOrDeficit({ supposedToPay, totalPaid });
    }

    totalPaidPreviously({ supposedToPay }) {
        //  supposed to pay - amount paid previously
        //extract supposed to pay (from state) and amount paid previously (from loan)
        //extract state
        const { currentLoan } = this.state;

        let totalPaidPreviously = 0;
        //check if amountPaidPreviously exists
        if ("previousOverdue" in currentLoan) {
            //amount paid is present
            totalPaidPreviously = supposedToPay - currentLoan.previousOverdue
        }

        this.setState({ totalPaidPreviously });

        //calculate total paid
        this.calculateTotalPaid({ supposedToPay, totalPaidPreviously});
    }

    calculateSupposedToPay({ currentLoanTerms, currentLoan }) {
        console.log("supposed to pay called");

        //supposed to pay is the total amount to be paid by that date
        let total = 0;

        //check that currentLoanTerms is not empty
        if(!(_.isEmpty(currentLoanTerms))) {
            //filter loan terms that are not passed today
            let totalArray = [];

            _.map(currentLoanTerms, term => {
                let seconds;
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;

                const dueDate = moment.unix(seconds);
                const today = moment();

                //check if due date is before today
                if (dueDate.isSameOrBefore(today, "day")) {
                    //due date is not passed
                    //fetch amount supposed to pay and put into the array
                    totalArray.push(term.amount);
                }
            });

            //calculate the total amount from numbers in the array
            //check if there are extra fees
            if ("extraFees" in currentLoan) {
                //loop object and take take extra fee amount from each extra fee
                const extraFees = currentLoan.extraFees;

                _.map(extraFees, extraFee => {
                    totalArray.push(extraFee.extraFeeAmount);
                });
            }

            total = totalArray.reduce((a, b) => a + b, 0);

            //check if theres early liquidation
            if (currentLoan.earlyLiquidation) {
                //supposed to pay - early liquidation
                total = currentLoan.earlyLiquidation;
            }
        }

        this.setState({ supposedToPay: total });


        //calculate totalPaidPreviously
        this.totalPaidPreviously({ supposedToPay: total });
    }


    renderLoanSummary() {

        const { currentLoan, currentLoanTerms, totalPaidPreviously, totalPaid, supposedToPay, surplusOrDeficit, outStanding, penalInterest, totalOutstanding } = this.state;

        if (!(_.isEmpty(currentLoanTerms))) {

            const terms = _.map(currentLoanTerms, term => {
                return term
            });

            //extract loan terms number
            const loanTerms = currentLoan.loanTerms;

            if (terms.length === loanTerms) {

                return (
                    <div className={"rightCardBox"}>
                        {/*<div>{this.displayCurrency(totalPaidPreviously)}</div>*/}
                        <div>{this.displayCurrency(currentLoan.totalCashPaid)}</div>
                        <div>{this.displayCurrency(currentLoan.totalChequePaid)}</div>
                        <div>{this.displayCurrency(totalPaid)}</div>
                        <div>{this.displayCurrency(supposedToPay)}</div>

                        {
                            currentLoan.earlyLiquidation ?
                                null :
                                <div>{this.displayCurrency(surplusOrDeficit)}</div>
                        }

                        {
                            currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                                null:
                                <div>{this.displayCurrency(outStanding)}</div>
                        }

                        {
                            currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                                null:
                                <div>{this.displayCurrency(totalOutstanding)}</div>
                        }

                        {
                            currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                                null:
                                <div>{this.displayCurrency(penalInterest)}</div>
                        }

                        {
                            currentLoan.customPenalInterest ?
                                <div>{this.displayCurrency(currentLoan.customPenalInterest)}</div> : null
                        }
                    </div>
                );
            } else if (terms.length < loanTerms) {

                const reminder = loanTerms - terms.length;
                return (
                    <div>
                        <p>Please save {reminder} terms to get a required loan statement</p>
                    </div>
                );

            } else if (terms.length > loanTerms){
                const reminder = terms.length - loanTerms;
                return (
                    <div>
                        <p>Please remove {reminder} terms to get a required loan statement</p>
                    </div>
                );
            }


        } else {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        }
    }


    render() {
        const { currentLoan, totalPaidPreviously, totalPaid, supposedToPay, surplusOrDeficit, outStanding, penalInterest, totalOutstanding } = this.state;

        return (
            <div className={"loanCardContents"}>
                <div className={"leftCardBox"}>
                    {/*<div><h4>Total Paid Previously</h4></div>*/}
                    <div><h4>Total Cash Payment</h4></div>
                    <div><h4>Total Cheque Payment</h4></div>
                    <div><h4>Total Paid</h4></div>
                    <div><h4>Supposed To Pay</h4></div>

                    {
                        currentLoan.earlyLiquidation ?
                            null :
                            <div><h4>Surplus/Deficit</h4></div>
                    }

                    {
                        currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                            null:
                            <div><h4>Outstanding Balance</h4></div>
                    }

                    {
                        currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                            null:
                            <div><h4>Total Outstanding Balance</h4></div>
                    }

                    {
                        currentLoan.earlyLiquidation || currentLoan.earlyLiquidation === 0 ?
                            null:
                            <div><h4>Generated Penal Interest</h4></div>
                    }

                    {
                        currentLoan.customPenalInterest ?
                            <div><h4>Custom Penal Interest</h4></div> : null
                    }
                </div>

                {this.renderLoanSummary()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    //const { currentLoan, currentLoanTerms } = state.loans;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        profile,
        systemInfo,
    };
};

export default connect(mapStateToProps, { })(LoanSummaryCard);