import React from "react";
import { connect } from "react-redux";
import firebase from "firebase";
import {BeatLoader} from "react-spinners";
import {message} from "antd";

//project
import {projectArray} from "../../env/PROJECTSCONFIGURATIONS";

//actions import
import { forgetPasswordChanged } from "../../actions/AuthenticationActions";

//components import
import {Input} from "../common/Input";
import {Button} from "../common/Button";




class ForgetPassword extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            email: this.props.email,
            projectID: this.props.projectID,
        }

        this.onSubmit =  this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.email !== this.props.email){
            this.setState({email: nextProps.email})
        }

        if (nextProps.projectID !== this.props.projectID){
            this.setState({projectID: nextProps.projectID})
        }
    }

    onSubmit() {

        this.setState({loading: true});

        //fetch credentials
        const { email, projectID } = this.state;

        const that = this;

        //check if fields are not empty
        if (email && projectID) {

            // pick project config files by private ID
            const projectContainer = projectArray.filter(project => project.privateID === projectID);

            //check that filtered project container is not empty
            if (projectContainer.length !== 0) {
                //project container is not empty
                const project = projectContainer[0];

                if (!firebase.apps.length) {
                    console.log("no project exists");
                    console.log("initializing a new project");
                    // initialize firebase
                    firebase.initializeApp(project.config);

                    //send an email
                    firebase.auth().sendPasswordResetEmail(email).then(function() {
                        // Email sent.
                        that.setState({loading: false, email: "", projectID: ""});
                        message.success("Reset password email is sent");
                        console.log("email sent");
                    }).catch(function(error) {
                        // An error happened.
                        that.setState({loading: false});
                        message.error(error.message);
                        console.log(error);
                        console.log("email is not sent");
                    });
                } else {
                    console.log("a project already exists, deleting project and assigning a new one");
                    this.onReInitialize({ email, project });
                }

            } else {
                //project container is empty
                message.warning("Sorry! Project private ID does not exist.");
            }


        } else {
            message.warning('Fill all required fields');
        }
    }


    onReInitialize({ email, project }) {

        //getLoading
        const that = this;

        firebase.app().delete().then(function() {

            firebase.initializeApp(project.config);


            //send an email
            firebase.auth().sendPasswordResetEmail(email).then(function() {
                // Email sent.
                that.setState({loading: false, email: "", projectID: ""});
                message.success("Reset password email is sent");
                console.log("email sent");
            }).catch(function(error) {
                // An error happened.
                that.setState({loading: false});
                message.error(error.message);
                console.log(error);
                console.log("email is not sent");
            });
        });

    }

    render() {
        
        return (
            <div>
                <div>
                    <div>
                        <div>
                            <Input
                                label={"Username"}
                                type={"text"}
                                onChange={e => this.props.forgetPasswordChanged({prop: 'email', value: e.target.value})}
                                value={this.state.email}
                            />
                        </div>
                    </div>

                    <div>
                        <div>
                            <Input
                                label={"Private ID"}
                                type={"text"}
                                onChange={e => this.props.forgetPasswordChanged({prop: 'projectID', value: e.target.value})}
                                value={this.state.projectID}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    {
                        this.state.loading?
                            <BeatLoader/>:
                            <Button
                                text={"Submit"}
                                onPress={() => this.onSubmit()}
                            />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    
    const {email, projectID} = state.authentication;
    
    return {
        email,
        projectID
    }
};

export default connect(mapStateToProps, {forgetPasswordChanged, })(ForgetPassword);
