import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { message, Popconfirm, Button} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//action imports
import { changedSegmentValue, addSegmentValue, deleteSegmentComplete, fetchSegments } from '../../../actions/SettingsActions';


//component imports
import { Input } from '../../common/Input';
import {BeatLoader} from "react-spinners";


class Segment extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            segmentValue: this.props.segmentValue,
            segmentLoading: this.props.segmentLoading,
            segmentSuccess: this.props.segmentSuccess,
            segmentFailed: this.props.segmentFailed,
            segment: this.props.segment,
        };
        this.onAddSegmentValue = this.onAddSegmentValue.bind(this);
        this.onDeleteSegmentValue = this.onDeleteSegmentValue.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.segmentValue !== this.props.segmentValue) {
            this.setState({ segmentValue: nextProps.segmentValue });
        }
        if (nextProps.segmentLoading !== this.props.segmentLoading) {
            this.setState({ segmentLoading: nextProps.segmentLoading });
        }
        if (nextProps.segmentSuccess !== this.props.segmentSuccess) {
            this.setState({ segmentSuccess: nextProps.segmentSuccess });
        }
        if (nextProps.segmentFailed !== this.props.segmentFailed) {
            this.setState({ segmentFailed: nextProps.segmentFailed });
        }
        if (nextProps.segment !== this.props.segment) {
            this.setState({ segment: nextProps.segment });
        }

    }


    componentDidMount() {
        this.props.fetchSegments();
    }

    onAddSegmentValue(){
        const {segmentValue} = this.state;

        if(segmentValue){
            //
            this.props.addSegmentValue({segmentValue})
        } else {
            message.warning("Kindly enter all fields");
        }
    }

    renderButton () {
        //extract state
        const { segmentLoading, } = this.state;

        if (segmentLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        }  else {
                return (
                    <div className={"masterFileUploadButton"}>
                        <Button onClick={() => this.onAddSegmentValue()}>Add</Button>
                    </div>
                );
            }
    }


    onDeleteSegmentValue(segt) {
        //
        if(segt){
            const segmentID = segt.segmentID;
            this.props.deleteSegmentComplete({segmentID});
        } else {
            message.warning("There is no segment to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderSegments() {
        const {segment} = this.state;

        const segmentArray = _.map(segment, segt => segt);

        if(segmentArray.size !== 0) {
            return (
                <div>
                    {
                        segmentArray.map(segt => {
                            const index = segmentArray.indexOf(segt);
                            const position = index + 1;
                        return (
                                <div key={segt.segmentID} className={"industryListValue"}>
                                    <div><p>{position}</p></div>
                                    <div><p>{segt.value}</p></div>
                                    <div>
                                    <Popconfirm
                                        title="Are you sure to delete this segment?"
                                        onConfirm={() => this.onDeleteSegmentValue(segt)}
                                        onCancel={() => this.cancel()}
                                        okText="Yes"
                                        cancelText="No"
                                        >
                                        <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                    </Popconfirm>
                                    </div>
                                </div>
                                );
                        })
                    }
                </div>
            );
        } else {
            return <h4>There is no data found</h4>
        }
    }

    render() {
        return (
            <div className={"industryContainer"}>
                <div>
                <h2>Enter Segment Value</h2>

                    <Input className="inputField"
                        type="text"
                        placeholder="Title"
                        onChange={e => this.props.changedSegmentValue({ prop: 'segmentValue', value: e.target.value })}
                        value={this.state.segmentValue}
                    />
                </div>
                <div>
                    {this.renderButton()}
                </div>

                <div>
                    <div>
                    <div className={"industryList"}>
                        <div><h5>#</h5></div>
                        <div><h5>Segment terms</h5></div>
                        <div><h5>Delete terms</h5></div>
                    </div>
                        <div>{this.renderSegments()}</div>
                    </div>
                </div>

            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

const {segmentValue, segmentLoading, segmentSuccess,segmentFailed, segment } = state.settings

    //return object with state properties
    return {
        segmentValue,
        segmentLoading,
        segmentSuccess,
        segmentFailed,
        segment,
    }
};


export default connect(mapStateToProps, {changedSegmentValue, addSegmentValue, deleteSegmentComplete, fetchSegments })(Segment);