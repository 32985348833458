import React from 'react';
import { connect } from 'react-redux';


//action imports
import { downPaymentDetailsChanged, downPaymentDateChanged, saveDownPayment } from '../../../actions/PaymentActions';

//components imports
import {Input} from '../../common/Input';
import {TextArea} from '../../common/TextArea';
import {PickDate} from '../../common/PickDate';
import {Button} from '../../common/Button';
import {BeatLoader} from "react-spinners";
import {message, Switch} from 'antd';


class AddDownPayments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            saveDownPaymentSuccessful: this.props.saveDownPaymentSuccessful,
            currency: this.props.currency,
            disabled: false,
            downPaymentPaidAmount: this.props.downPaymentPaidAmount,
            downPaymentLoading: this.props.downPaymentLoading,
            downPaymentBankName: this.props.downPaymentBankName,
            downPaymentDate: this.props.downPaymentDate,
            downPaymentComments: this.props.downPaymentComments,
            systemInfo: this.props.systemInfo,
        };

        this.onDownPaymentDateChanged = this.onDownPaymentDateChanged.bind(this);
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.downPaymentLoading !== this.props.downPaymentLoading) {
            this.setState({downPaymentLoading: nextProps.downPaymentLoading})
        }

        if (nextProps.saveDownPaymentSuccessful !== this.props.saveDownPaymentSuccessful) {
            this.setState({saveDownPaymentSuccessful: nextProps.saveDownPaymentSuccessful})
        }

        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }

        if (nextProps.downPaymentPaidAmount !== this.props.downPaymentPaidAmount) {
            this.setState({downPaymentPaidAmount: nextProps.downPaymentPaidAmount})
        }

        if (nextProps.downPaymentBankName !== this.props.downPaymentBankName) {
            this.setState({downPaymentBankName: nextProps.downPaymentBankName})
        }

        if (nextProps.downPaymentDate !== this.props.downPaymentDate) {
            this.setState({downPaymentDate: nextProps.downPaymentDate})
        }

        if (nextProps.downPaymentComments !== this.props.downPaymentComments) {
            this.setState({downPaymentComments: nextProps.downPaymentComments})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({systemInfo: nextProps.systemInfo});
        }
    }

    onDownPaymentDateChanged(date) {
        this.props.downPaymentDateChanged(date);
    }

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.downPaymentDetailsChanged({prop: 'currency', value: status})
    }

    onSaveDownPayment() {

        let {downPaymentPaidAmount, downPaymentBankName, downPaymentDate, downPaymentComments, currency, systemInfo } = this.state;
        
        //disable button
        this.setState({disabled: true});
        setTimeout(() => {
            this.setState({disabled: false});
        }, 5000)
        //determine cash collection is tzs or usd
        let rawCurrency;
        if (currency) {
            rawCurrency = "usd";
        } else {
            rawCurrency = systemInfo.defaultCurrency;
        }

        if (downPaymentPaidAmount && downPaymentBankName && downPaymentDate && downPaymentComments) {

            downPaymentDate = downPaymentDate.toDate();
            
            this.props.saveDownPayment({downPaymentPaidAmount, downPaymentBankName: downPaymentBankName.toUpperCase(), downPaymentDate, downPaymentComments, currency: rawCurrency});
        } else {
            message.warning("Fill all the required fields");
        }
    }

    renderButton () {
        if (this.state.downPaymentLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Save'}
                        onPress={() => this.onSaveDownPayment()}
                        buttonDisabled={this.state.disabled}
                    />
                </div>
            );
        }
    }

    renderMessage() {
        const {saveDownPaymentSuccessful} = this.state;

        if (saveDownPaymentSuccessful) {
            return (
                <p>{message.success("Down Payment is saved..")}</p>
            );
        } else {
            return null
        }
    }


    render(){

        const {systemInfo} = this.state;
        const currencyAbv = systemInfo.defaultCurrency.toUpperCase();

        return (
            <div className={"addServiceChargeContainer"}>
                <h4>Add Down Payments</h4>
                <div className={"serviceChargeBox"}>
                    <div className={"termBox"}>
                        <p>Currency</p>
                        <Switch
                            checkedChildren="USD"
                            unCheckedChildren={currencyAbv}
                            onChange={() => this.onCurrencySwitchChange() }
                            checked={this.state.currency}
                        />
                    </div>
                    <div className={"termBox"}>
                        <Input
                            label={'Paid Amount'}
                            type={'number'}
                            onChange={e => this.props.downPaymentDetailsChanged({prop: 'downPaymentPaidAmount', value: e.target.value})}
                            value={this.state.downPaymentPaidAmount}
                        />
                    </div>

                    <div className={"termBox"}>
                        <Input
                            label={'Bank Name'}
                            type={'text'}
                            onChange={e => this.props.downPaymentDetailsChanged({prop: 'downPaymentBankName', value: e.target.value})}
                            value={this.state.downPaymentBankName}
                        />
                    </div>

                    <div className={"termBox"}>
                        <PickDate
                            label={'Date'}
                            onChange={this.onDownPaymentDateChanged}
                            value={this.props.downPaymentDate}
                        />
                    </div>

                    <div className={"termBox"}>
                        <TextArea
                            label={'Comments'}
                            onChange={e => this.props.downPaymentDetailsChanged({prop: 'downPaymentComments', value: e.target.value})}
                            value={this.props.downPaymentComments}
                        />
                    </div>
                </div>

                <div className={"unidentifiedBoxButton"}>
                    {this.renderButton()}
                </div>
                <div>
                    {this.renderMessage()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    const  {currency, downPaymentPaidAmount, downPaymentBankName, downPaymentDate, downPaymentComments, downPaymentLoading, saveDownPaymentSuccessful, saveDownPaymentFailed} = state.payments;
    const { systemInfo } = state.app;

    return{
        currency,
        systemInfo,
        downPaymentPaidAmount,
        downPaymentBankName,
        downPaymentDate,
        downPaymentComments,
        downPaymentLoading,
        saveDownPaymentSuccessful,
        saveDownPaymentFailed,
    }
};

export default connect(mapStateToProps, {downPaymentDetailsChanged, downPaymentDateChanged, saveDownPayment })(AddDownPayments);
