import {
    CLEAR_LOGOUT_MESSAGES,
    CLEAR_SIGN_UP_MESSAGE,
    CREDENTIALS_CHANGED, FORGOT_PASSWORD_DETAILS_CHANGED,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    LOGIN_USER, LOGOUT_FAILED,
    LOGOUT_SUCCESSFULLY,
    ROLE_CHANGED,
    SIGN_UP_DETAILS_CHANGED,
    SIGN_UP_USER,
    SIGN_UP_USER_FAILED,
    SIGN_UP_USER_SUCCESSFUL,
    USER_ACCESS_CHANGED
} from "../actions/Types";


const INITIAL_STATE = {
    name: '',
    username: '',
    password: '',
    privateID: '',
    role: '',
    userAccess: [],

    email: "",
    projectID: "",

    loading: false,
    signInLoading: false,
    loginSuccess: false,
    loginFailed: false,
    logoutSuccess: false,

    signUpLoading: false,
    signUpSuccess: false,
    signUpFailed: false,

    error: false,
    errorMessage: "",
};


const AuthenticationReducer = ( state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CREDENTIALS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, loading: false, loginFailed: false, loginSuccess: false, error: false };
        case LOGIN_USER:
            return  {...state, signInLoading: true};
        case LOGIN_SUCCESSFUL:
            return {...state, username: "", password: "", privateID: "", signInLoading: false, loginSuccess: true, loginFailed: false, error: false};
        case LOGIN_FAILED:
            return  {...state, password: "",  loginFailed: true, signInLoading: false, loginSuccess: false, error: false};

        case LOGOUT_SUCCESSFULLY:
            return {...state, logoutSuccess: true};
        case LOGOUT_FAILED:
            return {...state, logoutFailed: true };
        case CLEAR_LOGOUT_MESSAGES:
            return {...state, logoutSuccess: false, logoutFailed: false };

        case SIGN_UP_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case SIGN_UP_USER:
            return {...state, signUpLoading: true };
        case SIGN_UP_USER_SUCCESSFUL:
            return {...state, name: "", username: "", password: "", role: "", userAccess: [], signUpLoading: false, signUpSuccess: true };
        case SIGN_UP_USER_FAILED:
            return { ...state, error: true, errorMessage: action.payload, ...state, name: "", username: "", password: "", role: "", userAccess: [], signUpLoading: false, signUpFailed: true };

        case CLEAR_SIGN_UP_MESSAGE:
            return {...state, signUpFailed: false, signUpSuccess: false, signUpLoading: false, error: false, errorMessage: "" };

            //sign up reducers
        case ROLE_CHANGED:
            return { ...state, role: action.payload };
        case USER_ACCESS_CHANGED:
            return { ...state, userAccess: action.payload };

            //forgot password
        case FORGOT_PASSWORD_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        default:
            return state
    }
};

export default AuthenticationReducer;