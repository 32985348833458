import {
    CALCULATE_LOAN_PENAL_INTEREST,
    CALCULATE_LOAN_PENAL_INTEREST_FAILED,
    CALCULATE_LOAN_PENAL_INTEREST_SUCCESSFUL,
    CLEAR_LOAN_TERMS,
    CLEAR_LOAN_TERMS_FAILED,
    CLEAR_LOAN_TERMS_SUCCESSFUL,
    COMPUTE_INCOMPLETE_LOANS,
    COMPUTE_INCOMPLETE_LOANS_FAILED,
    COMPUTE_INCOMPLETE_LOANS_SUCCESSFUL,
    COMPUTE_INCORRECT_LOANS,
    COMPUTE_INCORRECT_LOANS_FAILED,
    COMPUTE_INCORRECT_LOANS_SUCCESSFUL,
    COMPUTE_LOANS_FOR_PENAL_INTEREST,
    COMPUTE_LOANS_FOR_PENAL_INTEREST_FAILED,
    COMPUTE_LOANS_FOR_PENAL_INTEREST_SUCCESSFUL,
    ENABLE_MANUAL_OVERRIDE_BUTTON,
    FETCH_LOANS_WITH_CURRENT_PAID,
    FETCH_LOANS_WITH_CURRENT_PAID_FAILED,
    FETCH_LOANS_WITH_CURRENT_PAID_SUCCESSFUL,
    FETCH_SURPLUS_LOANS,
    FETCH_SURPLUS_LOANS_FAILED,
    FETCH_SURPLUS_LOANS_SUCCESSFUL,
    MANUAL_OVERRIDE,
    MANUAL_OVERRIDE_ALL_LOANS,
    MANUAL_OVERRIDE_ALL_LOANS_FAILED,
    MANUAL_OVERRIDE_ALL_LOANS_SUCCESSFUL,
    MANUAL_OVERRIDE_FAILED,
    MANUAL_OVERRIDE_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    manualOverrideLoading: false,
    incorrectLoans: [],
    buttonDisabled: false,
    loanPenalInterestLoading: false,
    incorrectLoansLoading: false,
    resetAllLoansLoading: false,

    incompleteLoans: [],
    incompleteLoansLoading: false,

    loansForPenalInterest: [],
    loansForPenalInterestLoading: false,

    surplusLoans: [],
    surplusLoansLoading: false,

    currentPaidLoans: {},
    currentPaidLoansLoading: false,

    clearLoanTermsLoading: false,
};

const manualOverrideReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MANUAL_OVERRIDE:
            return { ...state, manualOverrideLoading: true };
        case MANUAL_OVERRIDE_SUCCESSFUL:
            return { ...state, manualOverrideLoading: false, buttonDisabled: true  };
        case MANUAL_OVERRIDE_FAILED:
            return { ...state, manualOverrideLoading: false, buttonDisabled: true  };

        case ENABLE_MANUAL_OVERRIDE_BUTTON:
            return { ...state, buttonDisabled: false };

        case CALCULATE_LOAN_PENAL_INTEREST:
            return { ...state, loanPenalInterestLoading: true };
        case CALCULATE_LOAN_PENAL_INTEREST_SUCCESSFUL:
            return { ...state, loanPenalInterestLoading: false };
        case CALCULATE_LOAN_PENAL_INTEREST_FAILED:
            return { ...state, loanPenalInterestLoading: false }


        case COMPUTE_LOANS_FOR_PENAL_INTEREST:
            return { ...state, loansForPenalInterestLoading: true };
        case COMPUTE_LOANS_FOR_PENAL_INTEREST_SUCCESSFUL:
            return { ...state, loansForPenalInterestLoading: false, loansForPenalInterest: action.payload };
        case COMPUTE_LOANS_FOR_PENAL_INTEREST_FAILED:
            return { ...state, loansForPenalInterestLoading: false };

        case COMPUTE_INCORRECT_LOANS:
            return { ...state, incorrectLoansLoading: true };
        case COMPUTE_INCORRECT_LOANS_SUCCESSFUL:
            return { ...state, incorrectLoans: action.payload, incorrectLoansLoading: false };
        case COMPUTE_INCORRECT_LOANS_FAILED:
            return { ...state, incorrectLoansLoading: false };

        case COMPUTE_INCOMPLETE_LOANS:
            return { ...state, incompleteLoansLoading: true };
        case COMPUTE_INCOMPLETE_LOANS_SUCCESSFUL:
            return { ...state, incompleteLoans: action.payload, incompleteLoansLoading: false };
        case COMPUTE_INCOMPLETE_LOANS_FAILED:
            return { ...state, incompleteLoansLoading: false };

        case MANUAL_OVERRIDE_ALL_LOANS:
                return { ...state, resetAllLoansLoading: true };
        case MANUAL_OVERRIDE_ALL_LOANS_SUCCESSFUL:
            return { ...state, resetAllLoansLoading: false };
        case MANUAL_OVERRIDE_ALL_LOANS_FAILED:
            return { ...state, resetAllLoansLoading: false };

        case FETCH_LOANS_WITH_CURRENT_PAID:
            return { ...state, currentPaidLoansLoading: true };
        case FETCH_LOANS_WITH_CURRENT_PAID_SUCCESSFUL:
            return { ...state, currentPaidLoansLoading: false, currentPaidLoans: action.payload };
        case FETCH_LOANS_WITH_CURRENT_PAID_FAILED:
            return { ...state, currentPaidLoansLoading: false };

        case FETCH_SURPLUS_LOANS:
            return { ...state, surplusLoansLoading: true };
        case FETCH_SURPLUS_LOANS_SUCCESSFUL:
            return { ...state, surplusLoansLoading: false, surplusLoans: action.payload };
        case FETCH_SURPLUS_LOANS_FAILED:
            return { ...state, surplusLoansLoading: false };

        case CLEAR_LOAN_TERMS:
            return { ...state, clearLoanTermsLoading: true };
        case CLEAR_LOAN_TERMS_SUCCESSFUL:
            return { ...state, clearLoanTermsLoading: false };
        case CLEAR_LOAN_TERMS_FAILED:
            return { ...state, clearLoanTermsLoading: false };
        default:
            return state;
    }
};

export default manualOverrideReducer;