import {
    FETCH_ADMINS_SUCCESSFUL, 
    FETCH_SYSTEM_INFO_SUCCESSFUL,
    FETCH_EXTERNAL_COLLECTORS,
    FETCH_EXTERNAL_COLLECTORS_SUCCESSFUL,
    FETCH_EXTERNAL_COLLECTORS_FAILED,
} from "../actions/Types";

const INITIAL_STATE = {
    admins: [],
    systemInfo: {},
    externalCollectors: []
};

const AppReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ADMINS_SUCCESSFUL:
            return { ...state, admins: action.payload };
        case FETCH_EXTERNAL_COLLECTORS_SUCCESSFUL:
            return { ...state, externalCollectors: action.payload };
        case FETCH_SYSTEM_INFO_SUCCESSFUL:
            return { ...state, systemInfo: action.payload };
        default:
            return state;
    }
};

export default AppReducer;