import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Modal, Button } from 'antd';
import moment from "moment";


class CommentCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            admins: this.props.admins,
            visible: false,
        };

        this.renderTime = this.renderTime.bind(this);
        this.renderCollector = this.renderCollector.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.admins !== this.props.admins) {
            this.setState({ admins: nextProps.admins })
        }

        if (nextProps.visible !== this.props.visible) {
            this.setState({ visible: nextProps.visible })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderTime() {
        //extract props
        const { comment } = this.props;

        if (!(_.isEmpty(comment))) {
            const seconds = comment.timeStamp.seconds;
            const time = moment.unix(seconds);
            const displayTime = time.format("HH:mm | ddd, DD/MMM/YYYY");

            return <p className={"commentDisplayTime"}>{displayTime}</p>
        }
    }

    renderCollector() {
        //extract state
        const { admins } = this.state;

        //extract props
        const { comment } = this.props;

        if (!(_.isEmpty(comment))) {
            //extract collector ID
            const adminInArray = admins.filter(admin => admin.userID === comment.collectorID);

            const admin = adminInArray[0];


            if(!(_.isEmpty(admin))){
                if ("name" in admin) {
                    return <p className={"commentDisplayName"}>{admin.name ? admin.name : null}</p>
                } else {
                    return <p className={"commentDisplayName"}>Admin</p>
                }
            }
        }
    }

    renderComment() {
        //extract props
        const { comment } = this.props;

        if (comment) {
            return (
                <div className={"commentModal"}>
                    { this.renderTime() }
                    <h4>{comment.comments}</h4>
                    { this.renderCollector() }
                </div>
            );
        }
    }


    render() {

        return (
            <div className={"unidentifiedCommentButton"}>
                <Button type="default" onClick={this.showModal}>
                    View
                </Button>
                <Modal
                    title="Comments"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div>
                        { this.renderComment() }
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {

    //fetch state properties from specific reducer
    const { admins } = state.app;

    return {
        admins
    }
};

export default connect(mapStateToProps)(CommentCard);


