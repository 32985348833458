import React from 'react';
import { connect } from 'react-redux';
import { message, Popconfirm, Switch } from "antd";
import {BeatLoader} from "react-spinners";
import {NavLink} from 'react-router-dom';

//action imports
import { clearMigratedTerms, penalInterestActivation } from "../../../actions/SettingsActions";
import { calculatePenalInterest, calculateGeneralPenalInterest } from "../../../actions/AppActions";

//component imports
import { Button } from '../../common/Button';
import _ from "lodash";

class ClearLoansCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            penalInterest: false,

            clearMigrationLoading: this.props.clearMigrationLoading,
            calculatePenalInterestLoading: this.props.calculatePenalInterestLoading,
            clearMigrationSuccess: this.props.clearMigrationSuccess,
            clearMigrationFailed: this.props.clearMigrationFailed,
            calculatePenalInterestSuccess: this.props.calculatePenalInterestSuccess,
            calculatePenalInterestFailed: this.props.calculatePenalInterestFailed,
            penalInterestStatus: this.props.penalInterestStatus,
        };

        this.onClearMigratedTerms = this.onClearMigratedTerms.bind(this);
        this.onCalculatePenalInterest = this.onCalculatePenalInterest.bind(this);
        this.renderClearMigrateButton = this.renderClearMigrateButton.bind(this);
        this.renderCalculatePenalInterestButton = this.renderCalculatePenalInterestButton.bind(this);
        this.onPenalInterestActivationSwitch = this.onPenalInterestActivationSwitch.bind(this);
    }

    componentWillMount() {

        if (!(_.isEmpty(this.props.systemInfo))) {
            //check if penal interest exists
            if ("penalInterest" in this.props.systemInfo) {
                this.setState({ penalInterest: this.props.systemInfo.penalInterest, penalInterestStatus: this.props.systemInfo.penalInterest });
            }
        }
        //
        this.setState({
            clearMigrationSuccess: false,
            clearMigrationFailed: false,
            calculatePenalInterestSuccess: false,
            calculatePenalInterestFailed: false,
        })
    }



    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.systemInfo !== this.props.systemInfo) {
            //check if penal interest exists
            if ("penalInterest" in nextProps.systemInfo) {
                this.setState({ penalInterest: nextProps.systemInfo.penalInterest, penalInterestStatus: nextProps.systemInfo.penalInterest})
            }
        }

        if (nextProps.clearMigrationLoading !== this.props.clearMigrationLoading) {
            this.setState({ clearMigrationLoading: nextProps.clearMigrationLoading })
        }

        if (nextProps.calculatePenalInterestLoading !== this.props.calculatePenalInterestLoading) {
            this.setState({ calculatePenalInterestLoading: nextProps.calculatePenalInterestLoading })
        }

        if (nextProps.clearMigrationSuccess !== this.props.clearMigrationSuccess) {
            this.setState({ clearMigrationSuccess: nextProps.clearMigrationSuccess })
        }

        if (nextProps.clearMigrationFailed !== this.props.clearMigrationFailed) {
            this.setState({ clearMigrationFailed: nextProps.clearMigrationFailed })
        }

        if (nextProps.calculatePenalInterestSuccess !== this.props.calculatePenalInterestSuccess) {
            this.setState({ calculatePenalInterestSuccess: nextProps.calculatePenalInterestSuccess })
        }

        if (nextProps.calculatePenalInterestFailed !== this.props.calculatePenalInterestFailed) {
            this.setState({ calculatePenalInterestFailed: nextProps.calculatePenalInterestFailed })
        }
    }

    onClearMigratedTerms() {
        this.props.clearMigratedTerms();
    }

    onCalculatePenalInterest() {
        //
        const { penalInterestStatus } = this.state;

        //check if penal interest switch is on
        if (penalInterestStatus){
            //on
            this.props.calculateGeneralPenalInterest();
        } else {
            //off
            // penal interest is false
            message.warning("Please switch on penal interest on settings page before you proceed");
        }
    }

    renderMigrationSuccess() {
        const {clearMigrationSuccess} = this.state;
        //
        if (clearMigrationSuccess) {
            return alert("Migrated terms are cleared successfully");
        } else {
            return null;
        }
    }

    renderMigrationFailed() {
        const {clearMigrationFailed} = this.state;
        //
        if (clearMigrationFailed) {
            return alert("Migrated terms are cleared successfully");
        } else {
            return null;
        }
    }

    renderPenalInterestSuccess() {
        const {calculatePenalInterestSuccess} = this.state;
        //
        if (calculatePenalInterestSuccess) {
            return alert("Penal interest is calculated successfully");
        } else {
            return null;
        }
    }

    renderPenalInterestFailed() {
        const {calculatePenalInterestFailed} = this.state;
        //
        if (calculatePenalInterestFailed) {
            return alert("Penal interest is calculated successfully");
        } else {
            return null;
        }
    }


    onCancel() {
        message.info("Penal interest calculation is canceled");
    }


    renderClearMigrateButton() {
        //extract state
        const { clearMigrationLoading } = this.state;

        if (clearMigrationLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div className={"clearImportedLoansButton"}>
                    <Button
                        text={"Clear all migrated terms"}
                        onPress={() => this.onClearMigratedTerms() }
                    />
                </div>
            );
        }
    }

    onPenalInterestActivationSwitch() {
        //extract state
        const { penalInterest } = this.state;

        this.setState({ penalInterest: !penalInterest });
        this.props.penalInterestActivation({ penalInterest: !penalInterest });
    }

    renderCalculatePenalInterestButton() {
        //extract state
        const { calculatePenalInterestLoading } = this.state;

        if (calculatePenalInterestLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div className={'penalInterestSettingsCard'}>
                    <div>
                        <Switch
                            checkedChildren="Penal Interest Activated"
                            unCheckedChildren="Penal Interest Deactivated"
                            onChange={() => this.onPenalInterestActivationSwitch()}
                            checked={this.state.penalInterest}
                        />
                    </div>
                    <div className={"clearImportedLoansButton"}>
                        <Popconfirm
                            title="Make sure all procedures have been followed before calculating penal interest"
                            onConfirm={() => this.onCalculatePenalInterest()}
                            onCancel={() => this.onCancel()}
                            okText="Proceed"
                            cancelText="Cancel"
                        >
                            <p>Calculate Penal Interest</p>
                        </Popconfirm>
                    </div>
                </div>
            );
        }
    }

    render() {
        return (
            <div className={"clearImportedLoansBox"}>
                { this.renderClearMigrateButton() }

                { this.renderCalculatePenalInterestButton() }

                <div>
                    {this.renderMigrationSuccess()}
                    {this.renderMigrationFailed()}
                    {this.renderPenalInterestSuccess()}
                    {this.renderPenalInterestFailed()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { clearMigrationLoading, calculatePenalInterestLoading, clearMigrationSuccess, clearMigrationFailed, calculatePenalInterestSuccess, calculatePenalInterestFailed } = state.settings;
    const { systemInfo } = state.app;

    return {
        systemInfo,
        clearMigrationLoading,
        calculatePenalInterestLoading,
        clearMigrationSuccess,
        clearMigrationFailed,
        calculatePenalInterestSuccess,
        calculatePenalInterestFailed,
    }
};

export default connect(mapStateToProps, { clearMigratedTerms, calculatePenalInterest, penalInterestActivation, calculateGeneralPenalInterest })(ClearLoansCard);
