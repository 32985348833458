import {
    DOWN_PAYMENT_DETAILS_CHANGED,
    DOWN_PAYMENT_DATE_CHANGED,
    SAVE_DOWN_PAYMENT_DETAILS,
    SAVE_DOWN_PAYMENT_DETAILS_SUCCESSFUL,
    CLEAR_DOWN_PAYMENT_MESSAGE,
    SAVE_DOWN_PAYMENT_DETAILS_FAILED,
    FETCH_DOWN_PAYMENT,
    FETCH_DOWN_PAYMENT_SUCCESSFUL,
    FETCH_DOWN_PAYMENT_FAILED,
    DELETE_DOWN_PAYMENT,
    DELETE_DOWN_PAYMENT_SUCCESSFUL,
    DELETE_DOWN_PAYMENT_FAILED,
} from '../actions/Types';


const INITIAL_STATE = {
    currency: false,
    downPaymentPaidAmount: '',
    downPaymentBankName: '',
    downPaymentDate: '',
    downPaymentComments: '',

    downPayments: {},
    saveDownPaymentSuccessful: false,
    downPaymentLoading: false,
    saveDownPaymentFailed: false,

    deleteDownPaymentSuccessful: false,
    deleteDownPaymentFailed: false,
};

const PaymentReducer =  ( state = INITIAL_STATE, action) => {
    switch(action.type) {

        case DOWN_PAYMENT_DETAILS_CHANGED: 
            return {...state, [action.payload.prop]: action.payload.value};
        case DOWN_PAYMENT_DATE_CHANGED:
            return {...state, downPaymentDate: action.payload};

        case SAVE_DOWN_PAYMENT_DETAILS:
            return {...state, downPaymentLoading: true };
        case SAVE_DOWN_PAYMENT_DETAILS_SUCCESSFUL:
            return {...state, currency: false, downPaymentPaidAmount: "", downPaymentBankName: "", downPaymentDate: "", downPaymentComments: "",  downPaymentLoading: false, };
        case SAVE_DOWN_PAYMENT_DETAILS_FAILED:
            return {...state, downPaymentLoading: false, };

            //fetch reducers
        case FETCH_DOWN_PAYMENT_SUCCESSFUL:
            return { ...state, downPayments: action.payload, saveDownPaymentSuccessful: false, downPaymentLoading: false, saveDownPaymentFailed: false }; 

        case DELETE_DOWN_PAYMENT:
            return {...state, };
        case DELETE_DOWN_PAYMENT_SUCCESSFUL:
            return {...state, deleteDownPaymentSuccessful: true };
        case DELETE_DOWN_PAYMENT_FAILED:
            return {...state, deleteDownPaymentFailed: true };

        default:
            return state;
    }
};

export default PaymentReducer;

