import React from "react";
import { connect } from 'react-redux';
import _ from "lodash";
import {Button, Modal, Switch} from "antd";


//actions import
import {customerLoanDetailsChanged ,saveLoan, loanCurrencyChanged, loanChequeChanged} from "../../../../actions/LoanActions";

//components import
import {Input} from "../../../common/Input";
import {message} from "antd";


class AddCustomerLoan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loanID: this.props.loanID,
            systemInfo: this.props.systemInfo,
            totalAmount: this.props.totalAmount,
            loanTerms: this.props.loanTerms,
            currency: this.props.currency,
            cheque: false,

            fetchedCustomer: this.props.fetchedCustomer,
            loanSaved: this.props.loanSaved,
            saveLoanFailed: this.props.saveLoanFailed,
            visible: this.props.visible,

            profile: this.props.profile,
        }
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer){
            this.setState({fetchedCustomer: nextProps.fetchedCustomer})
        }
        if (nextProps.systemInfo !== this.props.systemInfo){
            this.setState({systemInfo: nextProps.systemInfo})
        }
        if (nextProps.loanSaved !== this.props.loanSaved){
            this.setState({loanSaved: nextProps.loanSaved})
        }
        if (nextProps.loanID !== this.props.loanID){
            this.setState({loanID: nextProps.loanID})
        }
        if (nextProps.totalAmount !== this.props.totalAmount){
            this.setState({totalAmount: nextProps.totalAmount})
        }
        if (nextProps.loanTerms !== this.props.loanTerms){
            this.setState({loanTerms: nextProps.loanTerms})
        }
        if (nextProps.currency !== this.props.currency){
            this.setState({currency: nextProps.currency})
        }
        if (nextProps.cheque !== this.props.cheque){
            this.setState({cheque: nextProps.cheque})
        }
        if (nextProps.visible !== this.props.visible){
            this.setState({visible: nextProps.visible})
        }
        if (nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile})
        }
        if (nextProps.saveLoanFailed !== this.props.saveLoanFailed){
            this.setState({saveLoanFailed: nextProps.saveLoanFailed})
        }
    }

    onSave() {

        let {loanID, totalAmount, loanTerms, currency, cheque, fetchedCustomer, profile} = this.state;

        //check user access rights
        const access = profile.userAccess.some(access => access === "clientsUpdates")

        //determine cash collection is tzs or usd
        let rawCurrency;
        if (currency) {
            rawCurrency = "usd";
        } else {
            rawCurrency = "tzs";
        }

        if (access) {

            if (!(_.isEmpty(fetchedCustomer))) {
                const customerID = fetchedCustomer.customerID;
                const customerName = fetchedCustomer.customerName;
                const loanStatus = false;

                totalAmount = parseInt(totalAmount);
                loanTerms = parseInt(loanTerms);

                if (loanID && totalAmount && loanTerms && customerID && customerName) {
                    //save to own path
                    const totalPaid = 0;
                    const totalOutstanding = 0;
                    const totalCashPaid = 0;
                    const totalChequePaid = 0;
                    const totalOverdue = 0;
                    this.props.saveLoan({customerID, customerName, loanID, currency: rawCurrency, cheque, totalAmount, totalPaid, totalOutstanding, totalCashPaid, totalChequePaid, totalOverdue, loanTerms, loanStatus })

                    this.setState({
                        visible: this.props.visible,
                    })
                } else {
                    message.warning('Fill all the required fields');
                }

            } else {
                message.warning("There is no selected customer");
            }

        } else {
            message.warning("You're not authorized to add a loan");
        }
    }


    state = { visible: false };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.loanCurrencyChanged(status)
    }

    onChequeSwitchChange() {
        const status = !this.state.cheque;
        this.props.loanChequeChanged(status)
    }

    renderMessage() {
        const {loanSaved} = this.state;

        if (loanSaved) {
            return <p>{message.success("Loan is saved successfully")}</p>
        } else {
            return null
        }
    }

    renderFailedMessage() {
        const {saveLoanFailed} = this.state;

        if (saveLoanFailed) {
            return <p>{message.error("Sorry! Loan is not saved ")}</p>
        } else {
            return null
        }
    }

    render() {
        return (
            <div className={"addLoanButton"}>
                <Button type="default" onClick={this.showModal}>
                    Add Loan
                </Button>
                <Modal
                    title="Add Loan"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={600}
                >
                    <div className={"customerAddLoanModal"}>
                        <div>
                            <Input
                                // label={'Loan ID'}
                                placeholder={"Loan ID"}
                                type={'text'}
                                onChange={e => this.props.customerLoanDetailsChanged({prop: 'loanID', value: e.target.value})}
                                value={this.state.loanID}
                            />
                        </div>
                        <div>
                            <Input
                                // label={'Total Loan Amount'}
                                placeholder={"Total Loan Amount"}
                                type={'number'}
                                onChange={e => this.props.customerLoanDetailsChanged({prop: 'totalAmount', value: e.target.value})}
                                value={this.state.totalAmount}
                            />
                        </div>
                        <div>
                            <Input
                                // label={'Loan Term'}
                                placeholder={"Loan Terms"}
                                type={'number'}
                                onChange={e => this.props.customerLoanDetailsChanged({prop: 'loanTerms', value: e.target.value})}
                                value={this.state.loanTerms}
                            />
                        </div>
                        <div className={"addLoanCurrency"}>
                            <p>Currency</p>
                            <Switch
                                checkedChildren="USD"
                                unCheckedChildren={this.state.systemInfo.defaultCurrency}
                                onChange={() => this.onCurrencySwitchChange() }
                                checked={this.state.currency}
                            />
                        </div>
                        <div className={"addLoanCurrency"}>
                            <p>Payment Type</p>
                            <Switch
                                checkedChildren="Cheque"
                                unCheckedChildren="Cash"
                                onChange={() => this.onChequeSwitchChange() }
                                checked={this.state.cheque}
                            />
                        </div>
                        <div className={"customerSaveLoanButtonBox"}>
                            <Button text={'Save'} onClick={() =>this.onSave()}>SAVE</Button>
                        </div>
                    </div>
                </Modal>
                <div>
                    {this.renderMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { loanID, totalAmount, loanTerms, currency, cheque, loading, visible, loanSaved, saveLoanFailed, } = state.loans;
    const { fetchedCustomer } = state.customer;
    const { profile } = state.profile;
    const { systemInfo } = state.app;


    //return object with state properties
    return {
        fetchedCustomer,
        loanSaved,
        loanID,
        totalAmount,
        loanTerms,
        currency,
        cheque,
        loading,
        visible,
        profile,
        saveLoanFailed,
        systemInfo
    }
};

export default connect(mapStateToProps,{customerLoanDetailsChanged, saveLoan, loanCurrencyChanged, loanChequeChanged})(AddCustomerLoan);