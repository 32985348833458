import React from 'react';
import { Select } from 'antd';


class SelectFilterBucket extends React.Component {

    render(){ 

        const { Option } = Select;

        return (
            <div>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    <Option value="1">1</Option>
                    <Option value="2">2</Option>
                    <Option value="3">3</Option>
                    <Option value="4">4</Option>
                    <Option value="5">5</Option>
                    <Option value="6">6</Option>
                    <Option value="7">7</Option>
                    <Option value="8">8</Option>
                    <Option value="9">9</Option>
                    <Option value="10">10 and More</Option>
                </Select>

            </div>
        );
    }
}

export default SelectFilterBucket;