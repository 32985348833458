import React from 'react'
import { connect } from 'react-redux';
//import _ from "lodash";
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//...import actions
import { computeIncorrectLoans, manualOverrideAllLoans } from "../../../actions/ManualOverrideActions";
import { calculatePenalInterest } from "../../../actions/AppActions";

//...component imports
import IncorrectLoanCard from '../cards/IncorrectLoanCard';
import {BeatLoader} from "react-spinners";
//import {Button} from "../../common/Button";
import {message, Button} from "antd";

export class IncorrectLoans extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            incorrectLoans: this.props.incorrectLoans,
            incorrectLoansLoading: this.props.incorrectLoansLoading,
            resetAllLoansLoading: this.props.resetAllLoansLoading,
            calculatePenalInterestLoading: this.props.calculatePenalInterestLoading,
            penalBtn: this.props.penalBtn,
        };

        this.onRefresh = this.onRefresh.bind(this);
        this.renderResetBtn = this.renderResetBtn.bind(this);
        this.onReset = this.onReset.bind(this);
        this.onCalculatePenalInterest = this.onCalculatePenalInterest.bind(this);
    }

    componentWillMount(){
        this.props.computeIncorrectLoans();
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.incorrectLoans !== this.props.incorrectLoans){
            this.setState({incorrectLoans: nextProps.incorrectLoans});
        }

        if(nextProps.incorrectLoansLoading !== this.props.incorrectLoansLoading){
            this.setState({incorrectLoansLoading: nextProps.incorrectLoansLoading});
        }

        if(nextProps.resetAllLoansLoading !== this.props.resetAllLoansLoading){
            this.setState({resetAllLoansLoading: nextProps.resetAllLoansLoading});
        }

        if(nextProps.calculatePenalInterestLoading !== this.props.calculatePenalInterestLoading){
            this.setState({calculatePenalInterestLoading: nextProps.calculatePenalInterestLoading});
        }

        if(nextProps.penalBtn !== this.props.penalBtn){
            this.setState({penalBtn: nextProps.penalBtn});
        }
    }

    onRefresh() {
        this.props.computeIncorrectLoans();
        //this.props.resetModulo({ loans: this.state.incorrectLoans});
    }

    onCalculatePenalInterest() {
        //extract state
        const { incorrectLoans } = this.state;

        const sortedLoans = arraySort(incorrectLoans, "customerName");

        if (sortedLoans.length !== 0) {
            // const loanIDArray = sortedLoans.map(loan => loan.loanID);
            const batch = sortedLoans.slice(0,10);
            console.log(batch)
            this.props.calculatePenalInterest({ loansArray: batch, penalBtn: "incorrect" });
        } else {
            message.info("No Incorrect Loans to compute");
        }
    }

    renderPenalInterestBtn() {
        //extract state
        const { calculatePenalInterestLoading, penalBtn } = this.state;
        let disabled = false;

        if (calculatePenalInterestLoading) {
            if (penalBtn !== "incorrect") {
                disabled = true
            }
        }

        if (calculatePenalInterestLoading && penalBtn === "incorrect") {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <Button
                        disabled={disabled}
                        onClick={() => this.onCalculatePenalInterest()}
                    >
                        <span style={{ color: "#6D66E7" }}>Calculate Penal Interest</span>
                    </Button>
                    <p>Computes 10 loans per batch</p>
                </div>
            )
        }
    }

    onReset() {
        //extract state
        const { incorrectLoans } = this.state;

        this.props.manualOverrideAllLoans({ incorrectLoans });
    }

    renderIncorrectLoans(){
        const { incorrectLoans, incorrectLoansLoading } = this.state;

        const sortedLoans = arraySort(incorrectLoans, "customerName");

        if (sortedLoans.length !== 0) {
            return(
                <div className={'manualOverrideContainer'}>
                    {
                        sortedLoans.map(loan => {
                            return (
                                <IncorrectLoanCard loan={loan} key={loan.loanID} />
                            )
                        })
                    }
                </div>
            );
        } else {
            if (incorrectLoansLoading) {
                return <BeatLoader/>
            } else {
                return <p className={'userAdminsEmptyWarning'}>No Incorrect Loans available!</p>;
            }
        }
                   
    }

    renderRefreshButton() {
        //extract state
        const { incorrectLoansLoading } = this.state;

        if (incorrectLoansLoading) {
            return <BeatLoader/>
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={'sync-alt'} className={'refreshIcon'} onClick={ () => this.onRefresh() }/>
                </div>
            )
        }
    }

    renderResetBtn () {
        //extract state
        const { resetAllLoansLoading } = this.state;

        if (resetAllLoansLoading) {
            return <BeatLoader/>
        } else {
            return (
                <Button
                    text={"Reset All Incorrect Loans"}
                    onPress={ () => this.onReset() }
                />
            );
        }
    }


    render() {
        //extract state
        const { incorrectLoans } = this.state;
        const numberOfIncorrectOfLoans = incorrectLoans.length;
        return (
            <div className={'incorrectLoansContainer'}>
                <div>
                    <h4>({numberOfIncorrectOfLoans}) Incorrect Loans</h4>
                </div>

                <div>
                   { this.renderPenalInterestBtn() }
                </div>

                <div>
                    { this.renderResetBtn() }
                </div>

                <div>
                    <hr className="sep-2"/>
                </div>


                <div style={{ textAlign: 'right'}}>
                    {this.renderRefreshButton()}
                </div>


                <div>
                    { this.renderIncorrectLoans() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { incorrectLoans, incorrectLoansLoading, resetAllLoansLoading } = state.manualOverride;
    const { calculatePenalInterestLoading, penalBtn } = state.settings;

    return{
        incorrectLoans,
        incorrectLoansLoading,
        resetAllLoansLoading,
        calculatePenalInterestLoading,
        penalBtn
    }
}


export default connect(mapStateToProps, { computeIncorrectLoans, manualOverrideAllLoans, calculatePenalInterest })(IncorrectLoans);
