import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';


//action import


class selectCollector extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            admins: this.props.admins,
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.admins !== this.props.admins) {
            this.setState ({admins: nextProps.admins})
        }

    }


    render() {

        const {admins} = this.state;

        const collectors = admins.filter( admin => admin.role === "collector" || admin.role === "collectionsManager" || admin.role === "superAdmin");

        const { Option } = Select;

        return (
            <div>
                <div className={"selectRoleLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 550 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value= ""> </Option>

                    {
                        collectors.map(collector => {

                            return (

                                <Option value={JSON.stringify({userID: collector.userID, name: collector.name })} key={collector.userID}>{collector.name}</Option>
                            );
                        })
                    }
                </Select>

            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { admins } = state.app;

    //return object with state properties
    return {
        admins,
    };
};

export default connect(mapStateToProps)(selectCollector);
