import React from "react";
import {connect} from 'react-redux';
import _ from "lodash";
import moment from "moment";

//actions import
import {updateCashCollectionBankDate, cashCollectionUpdateDetailsChanged, updateCashCollection} from "../../../../actions/CollectionsActions";

//components import
import {PickDate} from "../../../common/PickDate";
import {Input} from "../../../common/Input";
import {Button, Modal} from "antd";
import {BeatLoader} from "react-spinners";


class UpdateCashCollectionDetailsCard extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            collection: this.props.collection,
            bankDate: this.props.bankDate,
            bankName: this.props.bankName,
            updateCashCollectionLoading: this.props.updateCashCollectionLoading,

            currentLoan: this.props.currentLoan,
        };

        this.onDateChange = this.onDateChange.bind(this);
    }

    componentWillMount() {

        //extract state
        const { collection } = this.props;
        //check that loan term is not empty
        if (!(_.isEmpty(collection))) {


            //grab loan term bank date
            //extract timestamp
            const date = collection.bankDate;
            let bankDateSec;
            date.seconds ? bankDateSec = date.seconds : bankDateSec = date._seconds;
            //convert into a moment object
            const bankDate = moment.unix(bankDateSec);

            //set loan term details to match loan term details
            this.setState({ bankName: collection.bankName});
            this.setState({ bankDate: bankDate});
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.collection !== this.props.collection) {
            this.setState({collection: nextProps.collection})
        }

        if (nextProps.bankDate !== this.props.bankDate) {
            this.setState({bankDate: nextProps.bankDate})
        }

        if (nextProps.bankName !== this.props.bankName) {
            this.setState({bankName: nextProps.bankName})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.updateCashCollectionLoading !== this.props.updateCashCollectionLoading) {
            this.setState({updateCashCollectionLoading: nextProps.updateCashCollectionLoading})
        }
    }


    onDateChange(date) {
        this.props.updateCashCollectionBankDate(date)
    }

    onUpdateCashCollection() {

        let {bankName, bankDate, collection, currentLoan} = this.state;
        bankDate = bankDate.toDate();
        const customerID = currentLoan.customerID;
        const cashCollectionID = collection.cashCollectionID;

        this.props.updateCashCollection({ customerID, cashCollectionID, bankDate, bankName: bankName.toUpperCase()})
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };


    render() {
        const {updateCashCollectionLoading} = this.state;
        return (
            <div>
                <Button type="default" onClick={this.showModal}>
                    Update
                </Button>
                <Modal
                    title="Update cash Collection details"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    <div className={"modalBox"}>
                        <div>
                            <PickDate
                                onChange={this.onDateChange}
                                value={this.state.bankDate}
                            />
                        </div>
                        <div>
                            <Input
                                type={'text'}
                                onChange={e => this.props.cashCollectionUpdateDetailsChanged({prop: 'bankName', value: e.target.value})}
                                value={this.state.bankName}
                            />
                        </div>
                        <div>
                            {
                                updateCashCollectionLoading?
                                    <BeatLoader/>:
                                    <Button onClick={()=> this.onUpdateCashCollection()}>Update</Button>
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { bankName, bankDate, updateCashCollectionLoading } = state.collections;
    const {currentLoan} = state.loans;

    return {
        //export default connect(mapStateToProps, {updateCashCollectionBankDate, cashCollectionUpdateDetailsChanged, updateCashCollection})(UpdateCashCollectionDetailsCard);
        bankName,
        bankDate,
        updateCashCollectionLoading,

        currentLoan,
    }
};

export default connect(mapStateToProps, {updateCashCollectionBankDate, cashCollectionUpdateDetailsChanged, updateCashCollection})(UpdateCashCollectionDetailsCard);

