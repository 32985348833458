import React from 'react';
import { Select } from 'antd';


//action import


class SelectChequeStatus extends React.Component {


    render() {

        const { Option } = Select;

        return (
            <div>
                <div className={"inputLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 190 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    disabled={this.props.disabled}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    <Option value="pending">Pending</Option>
                    <Option value="notDeposited">Not Deposited</Option>
                    <Option value="bounced">Bounced</Option>
                    <Option value="cleared">Cleared</Option>
                    <Option value="held">Held</Option>
                </Select>

            </div>
        );
    }
}

export default (SelectChequeStatus);
