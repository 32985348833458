import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'
import _ from "lodash";
import {message} from "antd";

//actions imports
import { pushToCustomerDetails, pushUnIdentifiedPayment } from "../../../actions/UnidentifiedActions";

//components imports
import {Button} from "../../common/Button";
import CustomerSelection from "./CustomerSelection";

class SearchCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedCustomer: this.props.fetchedCustomer
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillMount() {
        //extract payment
        const { payment } = this.props;

        //push selected payment to redux
        this.props.pushUnIdentifiedPayment(payment);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fetchedCustomer !== this.props.fetchedCustomer) {
            this.setState({ fetchedCustomer: nextProps.fetchedCustomer })
        }
    }


    onSubmit() {
        const { fetchedCustomer } = this.state;

        //check if there's a fetched customer is not empty
        if (!(_.isEmpty(fetchedCustomer))) {
            //there's a fetched customer
            const history = this.props.history;
            this.props.pushToCustomerDetails({ history, customerID: fetchedCustomer.customerID });
        } else {
            message.warning('There is no a customer');
        }
    }


    render() {
        return (
            <div className={"collectionsSearchCustomerContainer"}>
                <div className={"collectionsSearchCustomerHeaderBox"}>
                    <h2>Search for a customer</h2>
                </div>
                <div>
                    <CustomerSelection
                        placeholder={'Enter customer ID/name'}
                        label={'Select Customer'}
                    />
                </div>
                <div className={"collectionsSearchCustomerButton"}>
                    <Button
                        text={'Submit'}
                        onPress={ ()=> this.onSubmit() }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { fetchedCustomer } = state.unidentified;

    return {
        //
        fetchedCustomer,
    }
};

export default withRouter(connect(mapStateToProps, { pushToCustomerDetails, pushUnIdentifiedPayment })(SearchCustomer));