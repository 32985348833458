export const projectArray = [
    {
        privateID: "0000",
        currency: "TSH",
        serverUrl: "https://us-central1-alliancefinance-23.cloudfunctions.net/",
        config: {
            apiKey: "AIzaSyAB8S73YqC968X-oJJVuNvbkVO03EWI1Bg",
            authDomain: "alliancefinance-23.firebaseapp.com",
            databaseURL: "https://alliancefinance-23.firebaseio.com",
            projectId: "alliancefinance-23",
            storageBucket: "alliancefinance-23.appspot.com",
            messagingSenderId: "335997039920",
            appId: "1:335997039920:web:16c2f23d827ad4ade884f5",
            measurementId: "G-F50RKTXWY3"
        }
    },
    {
        privateID: "0001",
        serverUrl: "https://us-central1-afcltanzania.cloudfunctions.net/",
        currency: "TSH",
        config: {
            apiKey: "AIzaSyCcaYrlRtbKj39FgWQzZuEzuOj5PW9tH7c",
            authDomain: "afcltanzania.firebaseapp.com",
            projectId: "afcltanzania",
            storageBucket: "afcltanzania.appspot.com",
            messagingSenderId: "628843769749",
            appId: "1:628843769749:web:2062dc50242221d43b509c",
            measurementId: "G-5ZL1KCDRZE"
        }
    },
    {
        privateID: "0002",
        serverUrl: "https://us-central1-afclkenya.cloudfunctions.net/",
        currency: "KES",
        config: {
            apiKey: "AIzaSyBCJDHZtm92Yq3YX3LULHxJow6SB6x6zQI",
            authDomain: "afclkenya.firebaseapp.com",
            projectId: "afclkenya",
            storageBucket: "afclkenya.appspot.com",
            messagingSenderId: "771913204350",
            appId: "1:771913204350:web:9231c9024309800ed40e2f",
            measurementId: "G-N217239VMP"
        }
    },
    {
        privateID: "0003",
        serverUrl: "https://us-central1-afclsenegal.cloudfunctions.net/",
        currency: "CFA",
        config: {
            apiKey: "AIzaSyBlCIy6GtcUY4TFMC2hjYG4QT_8OlppmTw",
            authDomain: "afclsenegal.firebaseapp.com",
            projectId: "afclsenegal",
            storageBucket: "afclsenegal.appspot.com",
            messagingSenderId: "335611092883",
            appId: "1:335611092883:web:5fcd7db4e443f4d745d266",
            measurementId: "G-3Z9H2B7LJR"
        }
    },
    {
        privateID: "0004",
        serverUrl: "https://us-central1-afclnigeria.cloudfunctions.net/",
        currency: "NGN",
        config: {
            apiKey: "AIzaSyBOLi1C5hjnefJTt0-kVnp_EzciWov43Sg",
            authDomain: "afclnigeria.firebaseapp.com",
            projectId: "afclnigeria",
            storageBucket: "afclnigeria.appspot.com",
            messagingSenderId: "705955826020",
            appId: "1:705955826020:web:ac12f41ab2013c58599271",
            measurementId: "G-KXRGVQQKG4"
        }
    },
    {
        privateID: "0005",
        serverUrl: "https://us-central1-afclghana.cloudfunctions.net/",
        currency: "GHC",
        config: {
            apiKey: "AIzaSyBSIONj7kv_ItldYXAxzepNiT2HLPk60JQ",
            authDomain: "afclghana.firebaseapp.com",
            projectId: "afclghana",
            storageBucket: "afclghana.appspot.com",
            messagingSenderId: "463103181800",
            appId: "1:463103181800:web:247a8c9149a51069875a5d",
            measurementId: "G-0WJJBW94RG"
        }
    },
    {
        privateID: "0006",
        serverUrl: "https://us-central1-afclzambia.cloudfunctions.net/",
        currency: "ZMW",
        config: {
            apiKey: "AIzaSyD0EcalpatVrskUzeCj1lXP2BhcIgulEzM",
            authDomain: "afclzambia.firebaseapp.com",
            projectId: "afclzambia",
            storageBucket: "afclzambia.appspot.com",
            messagingSenderId: "897179907072",
            appId: "1:897179907072:web:87f753c108dfc5c73b14e5",
            measurementId: "G-1Y3JFZXE64"
        }
    },
    {
        privateID: "0007",
        serverUrl: "https://us-central1-afclsouthafrica.cloudfunctions.net/",
        currency: "ZAR",
        config: {
            apiKey: "AIzaSyCotiZS8i40BBi8M1YJKc-nXclcV9s6jF8",
            authDomain: "afclsouthafrica.firebaseapp.com",
            projectId: "afclsouthafrica",
            storageBucket: "afclsouthafrica.appspot.com",
            messagingSenderId: "382871951856",
            appId: "1:382871951856:web:351db4eb02d49aea9e6535",
            measurementId: "G-LC0D94C01D"
        }
    },
];

export const generalConfig = {
    privateID: "0000",
    serverUrl: "https://us-central1-alliancefinance-23.cloudfunctions.net/",
    config: {
        apiKey: "AIzaSyAB8S73YqC968X-oJJVuNvbkVO03EWI1Bg",
        authDomain: "alliancefinance-23.firebaseapp.com",
        databaseURL: "https://alliancefinance-23.firebaseio.com",
        projectId: "alliancefinance-23",
        storageBucket: "alliancefinance-23.appspot.com",
        messagingSenderId: "335997039920",
        appId: "1:335997039920:web:16c2f23d827ad4ade884f5",
        measurementId: "G-F50RKTXWY3"
    }
};