import * as firebase from "firebase";

//type imports
import {
    CLEAR_CHANGE_PASSWORD_MESSAGES,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESSFUL,
    FETCH_ADMIN_USER,
    FETCH_ADMIN_USER_FAILED,
    FETCH_ADMIN_USER_SUCCESSFUL,
    FETCH_PROFILE,
    FETCH_PROFILE_FAILED,
    FETCH_PROFILE_SUCCESSFUL,
    PASSWORD_CHANGED, RESET_PASSWORD, RESET_PASSWORD_FAILED, RESET_PASSWORD_SUCCESSFUL,
    UPDATE_ADMIN_USER,
    UPDATE_ADMIN_USER_FAILED,
    UPDATE_ADMIN_USER_SUCCESSFUL, USER_ROLE_CHANGED,
} from "./Types";

//action imports
import {getAdmins} from "./AppActions";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const passwordDetailsChanged = ({prop, value}) => {
    //
    return {
        type: PASSWORD_CHANGED,
        payload: {prop, value}
    }
};

export const userRoleChanged = ({prop, value}) => {
    //
    return {
        type: USER_ROLE_CHANGED,
        payload: {prop, value}
    }
};

export const fetchProfile = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_PROFILE });
        //grab current user
        const user = firebase.auth().currentUser;
        firebase.firestore().collection("users").doc("admins").collection(user.uid).doc("public").collection("account").doc("info")
            .onSnapshot(function(doc) {

                const profile = doc.data();

                dispatch({
                    type: FETCH_PROFILE_SUCCESSFUL,
                    payload: profile
                });
            }, function (error) {
                console.log("couldn't fetch admin");
                console.log(error);
                dispatch({ type: FETCH_PROFILE_FAILED });
            })
    };
};


export const deleteUser = ({ uid }) => {

    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: DELETE_USER });
        const url = `${project.serverUrl}deleteUser`;
        fetch(url, {
            method: 'POST',
            body: JSON.stringify({ uid }),
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("user has been deleted");
                console.log(response);
                dispatch({
                    type: DELETE_USER_SUCCESSFUL,
                    payload: response
                });
                getAdmins({ dispatch });
            }).catch((error) => {
            console.log("Here's your error deleting the user");
            console.log(error);
            dispatch({ type: DELETE_USER_FAILED });
        })
    }
};


export const fetchAdminUser = ({userID, history}) => {
    return (dispatch) => {
        dispatch({ type: FETCH_ADMIN_USER });
        //grab selected admin user
        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("account").doc("info")
            .onSnapshot(function(doc) {
                const profile = doc.data();

                dispatch({
                    type: FETCH_ADMIN_USER_SUCCESSFUL,
                    payload: profile
                });

                history.push("/manage-user");

            }, function (error) {
                console.log("couldn't fetch admin");
                console.log(error);
                dispatch({ type: FETCH_ADMIN_USER_FAILED });
            })
    }
};

export const updateAdminUser = ({ userID, userAccess, role }) => {
    return (dispatch) => {
        dispatch({ type: UPDATE_ADMIN_USER });
        //grab selected admin user
        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("account").doc("info")
            .update({ userAccess, role})
            .then(() => {
                //update user role on user bucket
                firebase.firestore().collection("adminBucket").doc(userID)
                    .update({ role })
                    .then(() => {
                        dispatch({ type: UPDATE_ADMIN_USER_SUCCESSFUL });
                    }).catch((error) => {
                    console.log("couldn't update user role on admin bucket");
                    console.log(error);
                })
            }).catch((error) => {
                console.log("couldn't update user");
                console.log(error);
                dispatch({ type: UPDATE_ADMIN_USER_FAILED})
        })
    }
};

export const reAuthenticateUser = ({oldPassword, newPassword}) => {
//
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
    );
   // check if user is logged in then re authenticate
    if (user){
        //true
        console.log("There is user");
        return (dispatch) => {
            //
            dispatch({ type: RESET_PASSWORD });

            user.reauthenticateWithCredential(credential).then(function() {
                // User re-authenticated.
                console.log("Re-Authenticated");
                //change password
                changeUserPassword({password: newPassword, dispatch})
            }).catch(function(error) {
                // An error happened.
                console.log(error);
                console.log("Not Re-Authenticated");
            });
        }
    }

};

function changeUserPassword({password, dispatch}) {
    //
    const user = firebase.auth().currentUser;

    user.updatePassword(password)
        .then(() => {
            // Update successful.
            dispatch({ type: RESET_PASSWORD_SUCCESSFUL });
            console.log("user password has been updated");
            dispatch({ type: CLEAR_CHANGE_PASSWORD_MESSAGES});

        })
        .catch((error) => {
            // An error happened.
            dispatch({ type: RESET_PASSWORD_FAILED });
            console.log('Error updating user password:', error);
            dispatch({ type: CLEAR_CHANGE_PASSWORD_MESSAGES});
        });
}

