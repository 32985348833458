import React from 'react';

//actions import

//components import
import BouncedChequeCard from "./BouncedChequeCard";


class CustomerList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loan: this.props.loan,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.loan !== this.props.loan) {
            this.setState({loan: nextProps.loan})
        }
    }

    render() {
        const {loan} = this.state;
        console.log(loan);

        return (
            <div>
                {
                    loan.terms.map(cheque => {
                        console.log(cheque);

                        return (
                            <div>
                                <BouncedChequeCard
                                    cheque={cheque}
                                    loan={loan}
                                    key={cheque.loanTermID}
                                />
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}


export default (CustomerList);
