
import * as firebase from 'firebase';
import moment from "moment";

import {
    DOWN_PAYMENT_DETAILS_CHANGED,
    DOWN_PAYMENT_DATE_CHANGED,
    SAVE_DOWN_PAYMENT_DETAILS,
    SAVE_DOWN_PAYMENT_DETAILS_SUCCESSFUL,
    CLEAR_DOWN_PAYMENT_MESSAGE,
    SAVE_DOWN_PAYMENT_DETAILS_FAILED,
    FETCH_DOWN_PAYMENT,
    FETCH_DOWN_PAYMENT_SUCCESSFUL,
    FETCH_DOWN_PAYMENT_FAILED,
    DELETE_DOWN_PAYMENT,
    DELETE_DOWN_PAYMENT_SUCCESSFUL,
    DELETE_DOWN_PAYMENT_FAILED,
} from './Types';

export const downPaymentDetailsChanged = ({prop, value}) => {
    return{
        type: DOWN_PAYMENT_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const downPaymentDateChanged = (date) => {
    return{
        type: DOWN_PAYMENT_DATE_CHANGED,
        payload: date
    }
};

export const saveDownPayment = ({ downPaymentPaidAmount, downPaymentBankName, downPaymentDate, downPaymentComments, currency }) => {
    
    const ID = moment().unix();
    const paymentID = ID.toString();

    return(dispatch) => {
        dispatch({ type: SAVE_DOWN_PAYMENT_DETAILS });
        firebase.firestore().collection("downPayments").doc(paymentID)
            .set({ downPaymentPaidAmount, downPaymentBankName, downPaymentDate, downPaymentComments, paymentID, currency})
            .then(() => {
                //fetch service charges
                callDownPayment({dispatch});
                dispatch({ type: SAVE_DOWN_PAYMENT_DETAILS_SUCCESSFUL });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: SAVE_DOWN_PAYMENT_DETAILS_FAILED });
            })
    };
};

export const fetchDownPayment = () => {
    return(dispatch) => {
        callDownPayment({dispatch});
        // dispatch({ type: CLEAR_DOWN_PAYMENT_MESSAGE });
    };
};

export const deleteDownPayment = (paymentID) => {

    return(dispatch) => {

        dispatch({type: DELETE_DOWN_PAYMENT});
        firebase.firestore().collection("downPayments").doc(paymentID)
            .delete()
            .then(() => {
                //fetch unidentified payments
                callDownPayment({dispatch});
                dispatch({ type: DELETE_DOWN_PAYMENT_SUCCESSFUL });
                dispatch({ type: CLEAR_DOWN_PAYMENT_MESSAGE });
            })
            .catch(error => {
                console.log(error);
                dispatch({ type: DELETE_DOWN_PAYMENT_FAILED });
            })
    }

};


function callDownPayment({dispatch}){

    dispatch({ type: FETCH_DOWN_PAYMENT });

    let downPayments = {};

    firebase.firestore().collection("downPayments")
        .onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc) {
                downPayments[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_DOWN_PAYMENT_SUCCESSFUL,
                payload: downPayments
            });

        }, function (error) {
            console.log(error);

            dispatch({
                type: FETCH_DOWN_PAYMENT_FAILED
            });
        })
}