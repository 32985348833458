import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {message, Switch} from "antd";

//action imports
import { migrationActivation, settingsFieldUpdated, setMigrationDate } from "../../../actions/SettingsActions";
import {PickDate} from "../../common/PickDate";
import {Button} from "../../common/Button";
import {BeatLoader} from "react-spinners";
import moment from "moment";

//component imports
//import { Button } from '../../common/Button';

class ActivateMigration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            migration: false,
            migrationDate: this.props.migrationDate,
            setMigrationDateLoading: this.props.setMigrationDateLoading,
            setMigrationDateSuccess: this.props.setMigrationDateSuccess,
            setMigrationDateFailed: this.props.setMigrationDateFailed,
            systemInfo: this.props.systemInfo,

        };

        this.onMigrationActivationSwitch = this.onMigrationActivationSwitch.bind(this);
        this.onDateChosen = this.onDateChosen.bind(this);
        this.onSaveMigrationDate = this.onSaveMigrationDate.bind(this);
    }

    componentWillMount() {
        if (!(_.isEmpty(this.props.systemInfo))) {
            this.setState({ systemInfo: this.props.systemInfo });
            //check if migration exists
            if ("migration" in this.props.systemInfo) {
                this.setState({ migration: this.props.systemInfo.migration })
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: this.props.systemInfo });
            //check if migration exists
            if ("migration" in nextProps.systemInfo) {
                this.setState({ migration: nextProps.systemInfo.migration })
            }
        }

        if (nextProps.migrationDate !== this.props.migrationDate) {
            this.setState({ migrationDate: nextProps.migrationDate });
        }

        if (nextProps.setMigrationDateLoading !== this.props.setMigrationDateLoading) {
            this.setState({ setMigrationDateLoading: nextProps.setMigrationDateLoading });
        }

        if (nextProps.setMigrationDateSuccess !== this.props.setMigrationDateSuccess) {
            this.setState({ setMigrationDateSuccess: nextProps.setMigrationDateSuccess });
        }

        if (nextProps.setMigrationDateFailed !== this.props.setMigrationDateFailed) {
            this.setState({ setMigrationDateFailed: nextProps.setMigrationDateFailed });
        }
    }

    onMigrationActivationSwitch() {
        //extract state
        const { migration } = this.state;
        this.setState({ migration: !migration });

        this.props.migrationActivation(!migration);
    }

    onDateChosen(value) {
        this.props.settingsFieldUpdated({ prop: "migrationDate", value });
    }

    onSaveMigrationDate() {
        //extract state
        const { migrationDate } = this.state;
        this.props.setMigrationDate(migrationDate);
    }

    renderButton() {
        const {setMigrationDateLoading} = this.state;

        if (setMigrationDateLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={"Save Migration Date"}
                        onPress={() => this.onSaveMigrationDate()}
                    />
                </div>
            );
        }
    }

    renderSuccessMessage() {

        const {setMigrationDateSuccess} = this.state;

        if (setMigrationDateSuccess) {
            return (
                <p>{message.success("Migration date is saved")}</p>
            );
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {setMigrationDateFailed} = this.state;

        if (setMigrationDateFailed) {
            return (
                <p>{message.error("Sorry! Migration date is not saved")}</p>
            );
        } else {
            return null;
        }
    }

    renderMigrationDate() {
        //extract state
        const { systemInfo } = this.state;

        if (!(_.isEmpty(systemInfo))) {
            //pull migration date
            if ("migrationDate" in systemInfo) {
                let migrationSeconds;
                systemInfo.migrationDate.seconds ? migrationSeconds = systemInfo.migrationDate.seconds : migrationSeconds = systemInfo.migrationDate._seconds;
                const migrationDateObj = moment.unix(migrationSeconds);
                const migrationDate = migrationDateObj.format("DD / MM / YYYY");

                return <h4 style={{ textFamily: "myriadBold" }}>{migrationDate}</h4>
            }
        }
    }

    render() {
        return (
            <div className={"migrationBox"}>
                <div style={{ marginBottom: '2rem'}}>
                    <div>
                        <h3>When migrating, kindly follow the following sequence: (Use on files containing loans with old dates, current date or mixed with old, current, and future dates):</h3>
                        <ol>
                            <li>Deactivate Penal Interest</li>
                            <li>Activate Migration</li>
                            <li>Migrate PDC file</li>
                            <li>Manipulate individual loans</li>
                            <li>Clear all migrated terms</li>
                            <li>Calculate Penal Interest</li>
                        </ol>
                    </div>

                    <div>
                        <h3>When uploading, kindly follow the following sequence: (Use on files containing loans with future dates):</h3>
                        <ol>
                            <li>Deactivate Penal Interest</li>
                            <li>Deactivate Migration</li>
                            <li>Upload PDC file</li>
                            <li>Manipulate individual loans</li>
                        </ol>
                    </div>

                    <div>
                        <h3>Resetting and manipulating individual loan with an error:</h3>
                        <ol>
                            <li>Go to "Manual Override"</li>
                            <li>"Reset" loan</li>
                            <li>Manipulate individual loans</li>
                            <li>Clear cheques or migrated terms</li>
                            <li>Calculate Penal Interest within the loan statement</li>
                        </ol>
                    </div>

                    <h3>Failure to observe the sequence might result in in-accurate data</h3>
                </div>

                <hr/>

                <div className={"migrationBox0ne"}>
                    { this.renderMigrationDate() }
                    <PickDate
                        label={"Set Migration Date"}
                        value={this.state.migrationDate}
                        onChange={(value) => this.onDateChosen(value)}
                    />
                    {this.renderButton()}
                </div>

                <div>
                    <Switch
                        checkedChildren="Migration Activated"
                        unCheckedChildren="Migration Deactivated"
                        onChange={() => this.onMigrationActivationSwitch()}
                        checked={this.state.migration}
                    />
                </div>
                <div>
                    {this.renderSuccessMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { migrationDate, setMigrationDateLoading, setMigrationDateSuccess, setMigrationDateFailed, } = state.settings;
    const { systemInfo } = state.app;

    return {
        systemInfo,
        migrationDate,
        setMigrationDateLoading,
        setMigrationDateSuccess,
        setMigrationDateFailed,
    }
};

export default connect(mapStateToProps, { migrationActivation, settingsFieldUpdated, setMigrationDate })(ActivateMigration);
