import React from "react";
import { connect } from 'react-redux';
import {Modal, message} from "antd";


//actions imports
import { hideTotalPaidPreviously, previousOverdueChanged, savePreviousOverdue } from "../../../../actions/LoanActions";
import {Button} from "../../../common/Button";
import {Input} from "../../../common/Input";


class PreviousOverdue extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            previousOverdue: this.props.previousOverdue,
            previousOverdueSaved: this.props.previousOverdueSaved,

            currentLoan: this.props.currentLoan
        };

        this.onSavePreviousOverdue = this.onSavePreviousOverdue.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }
        if (nextProps.previousOverdue !== this.props.previousOverdue) {
            this.setState({previousOverdue: nextProps.previousOverdue})
        }
        if (nextProps.previousOverdueSaved !== this.props.previousOverdueSaved) {
            this.setState({previousOverdueSaved: nextProps.previousOverdueSaved})
        }
        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({ currentLoan: nextProps.currentLoan })
        }
    }

    handleOk = () => {
        this.props.hideTotalPaidPreviously();
    };

    handleCancel = () => {
        this.props.hideTotalPaidPreviously();
    };

    onSavePreviousOverdue() {
        //extract state
        const { previousOverdue, currentLoan } = this.state;
        const totalPreviousOverdue = parseInt(previousOverdue);

        const { loanID, customerID } = currentLoan;

        if(loanID && totalPreviousOverdue && customerID) {
            this.props.savePreviousOverdue({ previousOverdue: totalPreviousOverdue, loanID, customerID });
        } else {
            message.warning("Kindly enter previous overdue")
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title="Previous Overdue"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={800}
                >
                    <div className={"modalBox"}>
                        <Input
                            label={'Enter Previous Overdue'}
                            type={'number'}
                            onChange={e => this.props.previousOverdueChanged(e.target.value)}
                            value={this.state.previousOverdue}
                        />
                        <Button
                            text={"Save"}
                            onPress={() => this.onSavePreviousOverdue() }
                        />
                    </div>
                    <div>
                        {
                            this.state.previousOverdueSaved?
                                <p>{message.success("Previous overdue is saved")}</p>: null
                        }
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { previousOverdue, previousOverdueSaved } = state.loans;

    return {
        previousOverdue,
        previousOverdueSaved,
    }
};

export default connect(mapStateToProps, { hideTotalPaidPreviously, previousOverdueChanged, savePreviousOverdue })(PreviousOverdue);