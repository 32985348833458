import {
    CLEAR_LEGAL_REPO_FILTER,
    COLLECTOR_CHANGED,
    DELETE_DEMAND_NOTICES,
    DELETE_DEMAND_NOTICES_FAILED,
    DELETE_DEMAND_NOTICES_SUCCESSFUL,
    DEMAND_NOTICE_DATE_CHANGED,
    FETCH_BOUNCED_CHEQUES,
    FETCH_BOUNCED_CHEQUES_FAILED,
    FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
    FETCH_CURRENT_NOTICE,
    FETCH_DEMAND_NOTICES,
    FETCH_DEMAND_NOTICES_FAILED,
    FETCH_DEMAND_NOTICES_SUCCESSFUL,
    FETCH_MASTER_LIST_COMMENTS,
    FETCH_MASTER_LIST_COMMENTS_FAILED,
    FETCH_MASTER_LIST_COMMENTS_SUCCESSFUL,
    FETCH_RANGED_BOUNCED_CHEQUES,
    FETCH_RANGED_BOUNCED_CHEQUES_FAILED,
    FETCH_RANGED_BOUNCED_CHEQUES_SUCCESSFUL,
    FILTER_LEGAL_REPO_STATUS_CHANGED,
    HIDE_ASSIGN_COLLECTOR_CARD,
    HIDE_COMMENT_CARD,
    HIDE_NOTICES_CARD,
    HIDE_UPDATE_NOTICES_CARD,
    MASTER_LIST_COMMENT_CHANGED,
    MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED,
    MASTER_LIST_DATE_RANGE_CHANGED,
    NUMBER_OF_LOANS_TO_ASSIGN_CHANGED,
    SAVE_DEMAND_NOTICE,
    SAVE_DEMAND_NOTICE_FAILED,
    SAVE_DEMAND_NOTICE_SUCCESSFULL,
    SAVE_MASTER_LIST_COMMENT,
    SAVE_MASTER_LIST_COMMENT_FAILED,
    SAVE_MASTER_LIST_COMMENT_SUCCESSFUL,
    SHOW_ASSIGN_COLLECTOR_CARD,
    SHOW_COMMENT_CARD,
    SHOW_NOTICES_CARD,
    SHOW_UPDATE_NOTICES_CARD,
    UPDATE_DEMAND_NOTICE,
    UPDATE_DEMAND_NOTICE_DATE_CHANGED,
    UPDATE_DEMAND_NOTICE_FAILED,
    UPDATE_DEMAND_NOTICE_SUCCESSFUL,
    FILTER_BUCKET_CHANGED,
    FETCH_RANGED_MASTER_LIST_COMMENTS,
    FETCH_RANGED_MASTER_LIST_COMMENTS_SUCCESSFUL,
    FETCH_RANGED_MASTER_LIST_COMMENTS_FAILED,
    ASSIGN_COLLECTOR,
    ASSIGN_COLLECTOR_SUCCESSFUL,
    ASSIGN_COLLECTOR_FAILED,
    SHOW_UNASSIGNED_LOANS,
    HIDE_UNASSIGNED_LOANS,
    DELETE_ASSIGNED_COLLECTOR_SUCCESSFUL,
    RE_FETCH_DEMAND_NOTICES,
    CLEAR_COMMENT_PAGE,
    COLLECTOR_DETAILS_CHANGED,
    OD_AGING_REPORT_DATE_CHANGED,
    SAVE_SECOND_DEMAND_NOTICE,
    SAVE_SECOND_DEMAND_NOTICE_SUCCESSFUL,
    SAVE_SECOND_DEMAND_NOTICE_FAILED,
    CLEAR_DEMAND_NOTICE_MESSAGES,
    SECOND_DEMAND_NOTICE_DATE_CHANGED,
    DELETE_SECOND_DEMAND_NOTICES,
    DELETE_SECOND_DEMAND_NOTICES_SUCCESSFUL,
    DELETE_SECOND_DEMAND_NOTICES_FAILED,
    CLEAR_COLLECTOR_MESSAGES,
    COLLECTOR_MESSAGE,
    FETCH_OVERDUE_TERMS,
    FETCH_OVERDUE_TERMS_SUCCESSFUL,
    FETCH_OVERDUE_TERMS_FAILED,
    FETCH_RANGED_OVERDUE_TERMS,
    FETCH_RANGED_OVERDUE_TERMS_SUCCESSFUL,
    FETCH_RANGED_OVERDUE_TERMS_FAILED,
    FETCH_ALL_BEFORE_TERMS_SUCCESSFUL,
    FETCH_ALL_BEFORE_TERMS_FAILED, FETCH_ALL_BEFORE_TERMS,
    RESET_COLLECTORS,
    RESET_COLLECTORS_FAILED,
    RESET_COLLECTORS_SUCCESSFUL,
    FETCH_LAST_COMMENTS_SUCCESSFUL,
    FETCH_ROLLOVER,
    FETCH_ROLLOVER_FAILED,
    FETCH_ROLLOVER_SUCCESSFUL,
    FETCH_ROLLOVER_LAST_MONTH,
    FETCH_ROLLOVER_LAST_MONTH_SUCCESSFUL,
    FETCH_ROLLOVER_LAST_MONTH_FAILED,
    DELETE_MASTER_LIST_COMMENT,
    DELETE_MASTER_LIST_COMMENT_SUCCESSFUL,
    DELETE_MASTER_LIST_COMMENT_FAILED,
    FILTER_EXECUTIVE_CHANGED,
    RESET_EXECUTIVE,
    EXTERNAL_COLLECTOR_CHANGED
} from "../actions/Types";

const INITIAL_STATE = {
    loading: false,
    commentsSaveLoading: false,
    commentsLoading: false,

    bouncedCheques: {},
    allBeforeTerms: [],
    overdueTerms: [],
    collector: {},
    externalCollector: "",

    fetchedComments: {},
    fetchedComment: {},

    reportDate: "",
    dateRange: "",
    commentsDateRange: "",

    comments: "",

    //bounced cheques
    fetchRangedBouncedChequesLoading: false,
    fetchRangedBouncedChequesSuccessful: false,
    fetchRangedBouncedChequesFailed: false,
    fetched: false,

    //demand notices
    demandNoticeDate: "",
    fetchedDemandNotices: {},
    demandNoticesLoading: false,
    demandNoticeSaved: false,
    loanID: "",

    //Second demand notices
    secondDemandNoticeDate: "",
    secondDemandNoticesLoading: false,
    secondDemandNoticeSaveLoading: false,
    secondDemandNoticeSaved: false,

    //update demand notices
    updatedDemandNoticeDate: "",
    demandNoticeUpdateLoading: false,
    demandNoticeUpdated: false,

    //modal visibility
    showCommentCard: false,
    showNoticesCard: false,
    showUpdateNoticesCard: false,
    showAssignCollectorCard: false,

    //current demand notice
    currentNotice: "",
    deleteDemandNoticeLoading: false,
    deleteSecondDemandNoticeLoading: false,
    deleteDemandNotice: false,
    deleteSecondDemandNotice: false,

    //assign assignee
    numberOfLoans: 0,
    showUnAssignedLoansCard: false,
    saveAssigneeLoading: false,
    assigneeSaved: false,

    //delete assignee
    collectorDeleted: false,

    //assign collector to individual loan
    term: [],
    deleteCommentLoading: false,
    //legalRepoStatus
    filteredLegalRepoStatus: '',
    exeOfficer: '',
    resetexecutiveLoading: false,
    //bucket filter
    filterBucket: "",

    //update
    resetCollectorLoading: false,
    lastComment: {},
    rolloverLoading: false,
    rolloverOverdue: [],
    rolloverOverdueLastMonth: [],
    rolloverOverdueLastMonthLoading: false,

    secondDemandLoading: false,
    firstDemandLoading: false,
    demandNoticeSaveLoading: false,
    demandNoticeSaveLoadings: false,
    selectedTermOne: {},
    selectedTermTwo: {}

};

const MasterListReducer = ( state= INITIAL_STATE, action) => {
    switch (action.type) {

        //comment reducers
        case MASTER_LIST_COMMENT_CHANGED:
            return { ...state, comments: action.payload };
        
        case FETCH_ROLLOVER:
            return{...state, rolloverLoading: true}
        case FETCH_ROLLOVER_SUCCESSFUL:
            return{...state, rolloverLoading: false, rolloverOverdue: action.payload}
        case FETCH_ROLLOVER_FAILED:
            return{...state, rolloverLoading: false}

        case FETCH_ROLLOVER_LAST_MONTH:
            return{...state, rolloverOverdueLastMonthLoading: true}
        case FETCH_ROLLOVER_LAST_MONTH_SUCCESSFUL:
            return{...state, rolloverOverdueLastMonthLoading: false, rolloverOverdueLastMonth: action.payload}
        case FETCH_ROLLOVER_LAST_MONTH_FAILED:
            return{...state, rolloverOverdueLastMonthLoading: false}

        case RESET_COLLECTORS:
            return { ...state, resetCollectorLoading: true};
        case RESET_COLLECTORS_SUCCESSFUL:
            return { ...state,  resetCollectorLoading: false};
        case RESET_COLLECTORS_FAILED:
            return { ...state,  resetCollectorLoading: false};

        //date range reducers
        case MASTER_LIST_DATE_RANGE_CHANGED:
            return { ...state, dateRange: action.payload };
        case MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED:
            return { ...state, commentsDateRange: action.payload };
        case DEMAND_NOTICE_DATE_CHANGED:
            return { ...state, demandNoticeDate: action.payload };
        case UPDATE_DEMAND_NOTICE_DATE_CHANGED:
            return {...state, updatedDemandNoticeDate: action.payload };

        case FETCH_LAST_COMMENTS_SUCCESSFUL:
            return {...state, lastComment: action.payload }

        case RESET_EXECUTIVE:
            return {...state, exeOfficer: '' }

        //save reducers
        case SAVE_MASTER_LIST_COMMENT:
            return { ...state, commentsSaveLoading: true };
        case SAVE_MASTER_LIST_COMMENT_SUCCESSFUL:
            return { ...state, commentsSaveLoading: false, comments: "" };
        case SAVE_MASTER_LIST_COMMENT_FAILED:
            return { ...state, commentsSaveLoading: false };

        case DELETE_MASTER_LIST_COMMENT:
            return { ...state, deleteCommentLoading: true };
        case DELETE_MASTER_LIST_COMMENT_SUCCESSFUL:
            return { ...state, deleteCommentLoading: false, };
        case DELETE_MASTER_LIST_COMMENT_FAILED:
            return { ...state, deleteCommentLoading: false };

        case SAVE_DEMAND_NOTICE:
            return { ...state, demandNoticeSaveLoading: true };
        case SAVE_DEMAND_NOTICE_SUCCESSFULL:
            return { ...state, demandNoticeSaveLoading: false, demandNoticeDate: "", demandNoticeSaved: true };
        case SAVE_DEMAND_NOTICE_FAILED:
            return { ...state, demandNoticeSaveLoading: false };
        
        case SAVE_SECOND_DEMAND_NOTICE:
            return {...state, secondDemandNoticeSaveLoading: false, demandNoticeSaveLoadings: true };
        case SAVE_SECOND_DEMAND_NOTICE_SUCCESSFUL:
            return {...state, secondDemandNoticeSaveLoading: false, demandNoticeSaveLoadings: false, secondDemandNoticeDate: "", secondDemandNoticeSaved: true };
        case SAVE_SECOND_DEMAND_NOTICE_FAILED:
            return {...state, secondDemandNoticeSaveLoading: false, demandNoticeSaveLoadings: false };

        case SECOND_DEMAND_NOTICE_DATE_CHANGED:
            return {...state, secondDemandNoticeDate: action.payload};

        case CLEAR_DEMAND_NOTICE_MESSAGES:
            return {...state, demandNoticeSaveLoading: false, demandNoticeDate: "", demandNoticeSaved: false, secondDemandNoticeSaveLoading: false, secondDemandNoticeDate: "", secondDemandNoticeSaved: false };

        case SHOW_NOTICES_CARD:
            return {...state, showNoticesCard: true, loanID: action.payload };
        case HIDE_NOTICES_CARD:
            return {...state, showNoticesCard: false };
        case SHOW_UPDATE_NOTICES_CARD:
            return {...state, showUpdateNoticesCard: true };
        case HIDE_UPDATE_NOTICES_CARD:
            return {...state, showUpdateNoticesCard: false };

        case FETCH_DEMAND_NOTICES:
            return { ...state, demandNoticesLoading: true };
        case FETCH_DEMAND_NOTICES_SUCCESSFUL:
            return { ...state, fetchedDemandNotices: action.payload, demandNoticesLoading: false, demandNoticeUpdated: false, deleteDemandNotice: false, demandNoticeSaved: false };
        case FETCH_DEMAND_NOTICES_FAILED:
            return { ...state, demandNoticesLoading: false };

        //delete notice
        case DELETE_DEMAND_NOTICES:
            return {...state, deleteDemandNoticeLoading: true, firstDemandLoading: true };
        case DELETE_DEMAND_NOTICES_SUCCESSFUL:
            return {...state, deleteDemandNoticeLoading: false, deleteDemandNotice: true, demandNoticesLoading: true, firstDemandLoading: false, selectedTermOne: {}};
        case DELETE_DEMAND_NOTICES_FAILED:
            return {...state,  deleteDemandNoticeLoading: false, deleteDemandNotice: false, firstDemandLoading: false };

        case DELETE_SECOND_DEMAND_NOTICES:
            return {...state, deleteSecondDemandNoticeLoading: true, secondDemandLoading: true };
        case DELETE_SECOND_DEMAND_NOTICES_SUCCESSFUL:
            return {...state, deleteSecondDemandNoticeLoading: false, deleteSecondDemandNotice: true, secondDemandLoading: false, selectedTermTwo: {}};
        case DELETE_SECOND_DEMAND_NOTICES_FAILED:
            return {...state,  deleteSecondDemandNoticeLoading: false, deleteSecondDemandNotice: false, secondDemandLoading: false };

        case RE_FETCH_DEMAND_NOTICES:
            return {...state, fetchedDemandNotices: action.payload, demandNoticesLoading: false };

        case FETCH_CURRENT_NOTICE:
            return {...state, currentNotice: action.payload };

        //fetch reducers
        case FETCH_BOUNCED_CHEQUES:
            return { ...state, loading: true };
        case FETCH_BOUNCED_CHEQUES_SUCCESSFUL:
            return { ...state, bouncedCheques: action.payload, loading: false, dateRange: "", assigneeSaved: false, fetchRangedBouncedChequesLoading: false, };
        case FETCH_BOUNCED_CHEQUES_FAILED:
            return { ...state, loading: false, assigneeSaved: false };

        case FETCH_RANGED_BOUNCED_CHEQUES:
            return {...state, fetchRangedBouncedChequesLoading: true, fetched: true };
        case FETCH_RANGED_BOUNCED_CHEQUES_SUCCESSFUL:
            return {...state, bouncedCheques: action.payload, fetchRangedBouncedChequesLoading: false, commentsDateRange: "" };
        case FETCH_RANGED_BOUNCED_CHEQUES_FAILED:
            return {...state, fetchRangedBouncedChequesLoading: false };

        case FETCH_ALL_BEFORE_TERMS:
            return { ...state, }
        case FETCH_ALL_BEFORE_TERMS_SUCCESSFUL:
            return { ...state, allBeforeTerms: action.payload }
        case FETCH_ALL_BEFORE_TERMS_FAILED:
            return { ...state, }

        //fetch reducers
        case FETCH_OVERDUE_TERMS:
            return { ...state, loading: true };
        case FETCH_OVERDUE_TERMS_SUCCESSFUL:
            return { ...state, overdueTerms: action.payload, loading: false, dateRange: "", assigneeSaved: false, fetchRangedBouncedChequesLoading: false, };
        case FETCH_OVERDUE_TERMS_FAILED:
            return { ...state, loading: false, assigneeSaved: false };

        case FETCH_RANGED_OVERDUE_TERMS:
            return {...state, fetchRangedBouncedChequesLoading: true, fetched: true };
        case FETCH_RANGED_OVERDUE_TERMS_SUCCESSFUL:
            return {...state, overdueTerms: action.payload, fetchRangedBouncedChequesLoading: false, commentsDateRange: "" };
        case FETCH_RANGED_OVERDUE_TERMS_FAILED:
            return {...state, fetchRangedBouncedChequesLoading: false };

        case FETCH_MASTER_LIST_COMMENTS:
            return { ...state, commentsLoading: true };
        case FETCH_MASTER_LIST_COMMENTS_SUCCESSFUL:
            return { ...state, fetchedComment: action.payload, commentsLoading: false, commentsDateRange: "", collector: {} };
        case FETCH_MASTER_LIST_COMMENTS_FAILED:
            return { ...state, commentsLoading: false, commentsDateRange: "", collector: {} };

        case FETCH_RANGED_MASTER_LIST_COMMENTS:
            return {...state, commentsLoading: true};
        case FETCH_RANGED_MASTER_LIST_COMMENTS_SUCCESSFUL:
            return {...state, fetchedComments: action.payload, commentsLoading: false };
        case FETCH_RANGED_MASTER_LIST_COMMENTS_FAILED:
            return {...state, commentsLoading: false };

            //collector changed reducers
        case COLLECTOR_CHANGED:
            return { ...state, collector: action.payload };
        case EXTERNAL_COLLECTOR_CHANGED:
            return { ...state, externalCollector: action.payload}
        case COLLECTOR_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, collectorDeleted: false, assigneeSaved: false };

            //assign collector reducer
        case SHOW_UNASSIGNED_LOANS:
            return {...state, showUnAssignedLoansCard: true, collectorDeleted: false, assigneeSaved: false  };
        case HIDE_UNASSIGNED_LOANS:
            return {...state, showUnAssignedLoansCard: false, collectorDeleted: false, assigneeSaved: false };
        case ASSIGN_COLLECTOR:
            return {...state, saveAssigneeLoading: true };
        case ASSIGN_COLLECTOR_SUCCESSFUL:
            return {...state,  numberOfLoans: 0, collector: {}, externalCollector: "", showUnAssignedLoansCard: false, saveAssigneeLoading: false, };
        case ASSIGN_COLLECTOR_FAILED:
            return {...state, numberOfLoans: 0, saveAssigneeLoading: false, collector: {}, externalCollector: "", showUnAssignedLoansCard: false };
        case NUMBER_OF_LOANS_TO_ASSIGN_CHANGED:
            return { ...state, numberOfLoans: action.payload };

        case DELETE_ASSIGNED_COLLECTOR_SUCCESSFUL:
            return {...state, collectorDeleted: true };
        case CLEAR_COLLECTOR_MESSAGES:
        return {...state, collectorDeleted: false, assigneeSaved: false };
        case COLLECTOR_MESSAGE:
            return {...state, saveAssigneeLoading: false, assigneeSaved: true };


            //modal reducer
        case SHOW_COMMENT_CARD:
            return { ...state, showCommentCard: true };
        case HIDE_COMMENT_CARD:
            return { ...state, showCommentCard: false };
        case SHOW_ASSIGN_COLLECTOR_CARD:
            return {...state, showAssignCollectorCard: true, terms: action.payload };
        case HIDE_ASSIGN_COLLECTOR_CARD:
            return {...state, showAssignCollectorCard: false };

            //legal repo reducer
        case FILTER_LEGAL_REPO_STATUS_CHANGED:
            return { ...state, filteredLegalRepoStatus: action.payload };

        case CLEAR_LEGAL_REPO_FILTER:
            return { ...state, filteredLegalRepoStatus: '', filterBucket: "" };

            //bucket filter reducer
        case FILTER_BUCKET_CHANGED:
            return {...state, filterBucket: action.payload};

            //report date
        case OD_AGING_REPORT_DATE_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };

        case FILTER_EXECUTIVE_CHANGED:
            return {...state, exeOfficer: action.payload };

        case CLEAR_COMMENT_PAGE:
            return {...state, fetchedComments: {}};

        default:
            return state;
    }
};

export default MasterListReducer;