import React from 'react';

class IsMobile extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <p className={"userAdminsEmptyWarning"}>The system is unavailable on mobile devices</p>
                </div>
            </div>
        );
    }
}

export default IsMobile;