import React from "react";
import {connect} from "react-redux";
import _ from 'lodash';
import {message, Switch} from "antd";
import {BeatLoader} from "react-spinners";


//actions imports
import {dueDateChanged, chequeSwitchChanged, saveLoanTerm, loanTermDetailsChanged} from "../../../../actions/LoanActions";

//components imports
import {Input} from "../../../common/Input";
import {PickDate} from "../../../common/PickDate";
import {Button} from "../../../common/Button";
import moment from "moment";


class LoanTermCardInputs extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            amount: this.props.amount,
            dueDate: this.props.dueDate,
            cheque: this.props.cheque,
            chequeName: this.props.chequeName,
            chequeNumber: this.props.chequeNumber,
            bankName: this.props.bankName,
            bankDate: this.props.bankDate,

            disabled: this.props.disabled,

            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
            saveLoanTermLoading: this.props.saveLoanTermLoading,
        };

        this.onDueDateChanged = this.onDueDateChanged.bind(this);
        this.renderChequeInputs = this.renderChequeInputs.bind(this);
    }


    componentWillMount() {
        this.setState({loanTermSaved: false})

        //extract state
        const { currentLoan } = this.state;

        //check that loan  is not empty
        if (!(_.isEmpty(currentLoan))) {

            if ('cheque' in currentLoan) {
                //
                const paymentMethod = currentLoan.cheque;

                if (paymentMethod) {
                    //set loan currency details to match loan currency details
                    this.setState({ cheque: true, disabled: true});
                } else {
                    //set loan currency details to match loan currency details
                    this.setState({ cheque: false,  disabled: true});
                }
            }
        }

    }


    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.currentLoan !== this.props.currentLoan) {

            //check that loan  is not empty
            if (!(_.isEmpty(nextProps.currentLoan))) {

                //extract current loan and cheque
                const cLoan = nextProps.currentLoan;
                const paymentMethod = cLoan.cheque;

                if (paymentMethod) {
                    //set loan currency details to match loan currency details
                    this.setState({ cheque: true, disabled: true});
                } else {
                    //set loan currency details to match loan currency details
                    this.setState({ cheque: false,  disabled: true});
                }
            }

            this.setState({currentLoan: nextProps.currentLoan})
        }
        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }
        if (nextProps.saveLoanTermLoading !== this.props.saveLoanTermLoading) {
            this.setState({saveLoanTermLoading: nextProps.saveLoanTermLoading})
        }
        if (nextProps.amount !== this.props.amount) {
            this.setState({amount: nextProps.amount})
        }
        if (nextProps.dueDate !== this.props.dueDate) {
            this.setState({dueDate: nextProps.dueDate})
        }
        if (nextProps.cheque !== this.props.cheque) {
            this.setState({cheque: nextProps.cheque})
        }
        if (nextProps.chequeName !== this.props.chequeName) {
            this.setState({chequeName: nextProps.chequeName})
        }
        if (nextProps.chequeNumber !== this.props.chequeNumber) {
            this.setState({chequeNumber: nextProps.chequeNumber})
        }
        if (nextProps.bankName !== this.props.bankName) {
            this.setState({bankName: nextProps.bankName})
        }
        if (nextProps.disabled !== this.props.disabled) {
            this.setState({disabled: nextProps.disabled})
        }

    }

    onDueDateChanged(date) {

        this.props.dueDateChanged(date)
    }

    onChequeSwitchChange() {
        const status = !this.state.cheque;
        this.props.chequeSwitchChanged(status)
    }


    renderChequeInputs() {

        const {currentLoan} = this.state;

        if (!(_.isEmpty(currentLoan))) {
            //
            const cheque = currentLoan.cheque;

            if (cheque === true) {

                return (

                    <div className={"profileSecondBox"}>
                        <div className={"loanTermInputBox"}>
                            <Input
                                label={'Cheque Name'}
                                type={'text'}
                                onChange={e => this.props.loanTermDetailsChanged({prop: 'chequeName', value: e.target.value})}
                                value={this.state.chequeName}
                            />
                        </div>
                        <div className={"loanTermInputBox"}>
                            <Input
                                label={'Cheque Number'}
                                type={'text'}
                                onChange={e => this.props.loanTermDetailsChanged({prop: 'chequeNumber', value: e.target.value})}
                                value={this.state.chequeNumber}
                            />
                        </div>
                        <div className={"loanTermInputBox"}>
                            <Input
                                label={'Bank Name'}
                                type={'text'}
                                onChange={e => this.props.loanTermDetailsChanged({prop: 'bankName', value: e.target.value})}
                                value={this.state.bankName}
                            />
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    onSaveLoanTerm() {

        let {currentLoan, amount, dueDate, chequeName, chequeNumber, bankName,} = this.state;
        console.log(currentLoan);

        if (!(_.isEmpty(currentLoan))) {

            const customerID = currentLoan.customerID;
            const customerName = currentLoan.customerName;
            const loanID = currentLoan.loanID;
            const cheque = currentLoan.cheque;
            const loanTermStatus = false;
            const paidAmount = 0;
            const chequeStatus = "pending";
            const currency = currentLoan.currency;
            amount = parseInt(amount);

            //grab due date moment
            dueDate = dueDate.toDate();
            //convert it to unix epoch
            const stringTermID = moment(dueDate).unix();
            //convert unix epoch to string
            const strLoanTermID = stringTermID.toString();
            //concat loan id string with unix epoch string
            const loanTermID = `${loanID}${strLoanTermID}`;

            console.log(dueDate, loanTermID, cheque);

            if (amount && loanTermID && dueDate) {

                if (cheque) {
            this.props.saveLoanTerm({currentLoan, customerID, customerName, loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName: bankName.toUpperCase(), chequeStatus});
                } else {
                    const chequeNumber = null;
                    const chequeName = null;
                    const bankName = null;

                    this.props.saveLoanTerm({ currentLoan, customerID, customerName,
                        loanID, loanTermID, amount, paidAmount, currency, dueDate, loanTermStatus, cheque, chequeName, chequeNumber, bankName, chequeStatus})
                }

            } else {
                message.warning('Fill in all the fields');
            }

        } else {
            message.warning("There is no selected loan to update");
        }
    }

    render() {
        return (
            <div>
                <div className={"loanTermContainer"}>
                    <h4>Loan Term</h4>
                    <div className={"profileLoanTermBox"}>
                        <div className={"profileFirstTermBox"}>
                            <div className={"loanTermInputBox"}>
                                <Input
                                    label={'Monthly Installment'}
                                    type={'number'}
                                    onChange={e => this.props.loanTermDetailsChanged({prop: 'amount', value: e.target.value})}
                                    value={this.state.amount}
                                />
                            </div>

                            <div className={"loanTermInputBox"}>
                                <PickDate
                                    label={'Due Date'}
                                    onChange={this.onDueDateChanged}
                                    value={this.state.dueDate}
                                />
                            </div>

                            <div className={"loanTermInputBox"}>
                                <p>Payment Method</p>
                                <Switch
                                    checkedChildren="Cheque"
                                    unCheckedChildren="Cash"
                                    onChange={() => this.onChequeSwitchChange() }
                                    checked={this.state.cheque}
                                    disabled={this.state.disabled}
                                />
                            </div>
                        </div>

                        {this.renderChequeInputs()}
                    </div>

                    <div className={"loanTermBoxSaveButton"}>
                        {
                            this.state.saveLoanTermLoading?
                                <BeatLoader/>:
                                <Button
                                    text={'Save'}
                                    onPress={() => this.onSaveLoanTerm()}
                                />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { currentLoan, currentLoanTerms, amount, dueDate, chequeName, chequeNumber, bankName, saveLoanTermLoading } = state.loans;

    return{
        currentLoan,
        currentLoanTerms,

        amount,
        dueDate,
        chequeName,
        chequeNumber,
        bankName,
        saveLoanTermLoading,
    };
};

export default connect(mapStateToProps, {dueDateChanged, chequeSwitchChanged, saveLoanTerm, loanTermDetailsChanged})(LoanTermCardInputs);
