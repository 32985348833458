import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";

//actions import
import {deleteUnidentifiedPayment } from "../../../actions/UnidentifiedActions";

//components imports
import CommentCard from "../../clients/customerProfile/Cards/CommentCard";
import IdentifyCustomer from "./IdentifyCustomer";
import {Button, message, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";


class ViewUnidentifiedPayments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            unIdentifiedPayments: this.props.unIdentifiedPayments,
            unidentifiedPaymentDeleted: this.props.unidentifiedPaymentDeleted,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            localCurrency: "TSH",
        }
        this.onDelete = this.onDelete.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.unIdentifiedPayments !== this.props.unIdentifiedPayments) {
            this.setState ({unIdentifiedPayments: nextProps.unIdentifiedPayments})
        }
        if (nextProps.unidentifiedPaymentDeleted !== this.props.unidentifiedPaymentDeleted) {
            this.setState ({unidentifiedPaymentDeleted: nextProps.unidentifiedPaymentDeleted})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState ({systemInfo: nextProps.systemInfo})
        }
    }

    componentWillMount() {

        this.checkCurrencyFormat();
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value, payment) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if payment object is not empty
        if (!(_.isEmpty(payment))) {
            const loanCurrency = payment.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }

            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency,
            });

            return <p>{formatter.format(displayValue)}</p>
        }
    }

    renderTotalValue() {
        //extract state
        const {unIdentifiedPayments} = this.state;

        let totalArray = [];
        let total = 0;

        //check if payments are there
        if(!(_.isEmpty(unIdentifiedPayments))) {
            const payments = _.map(unIdentifiedPayments, payment => payment);

            payments.map(payment => totalArray.push(payment.unidentifiedPaidAmount));

            //calculate the total amount from numbers in the array
            total = totalArray.reduce((a, b) => a + b, 0);

            return this.displayCurrency(total);
        } else {
            return <h3>-</h3>;
        }
    }

    onDelete(payment) {

        //check if payment is not empty
        if (!(_.isEmpty(payment))) {
            //extract unidentified payment ID
            const unIdentifiedID = payment.unIdentifiedID;

            this.props.deleteUnidentifiedPayment({unIdentifiedID})
        } else {
            message.warning("No payment to delete");
        }
    }

    renderUnidentifiedPayments() {

        const {unIdentifiedPayments} = this.state;

        if(!(_.isEmpty(unIdentifiedPayments))) {
            const payments = _.map(unIdentifiedPayments, payment => payment);

            return (
                <div>
                    {
                        payments.map(payment => {

                            const bankDateSec = payment.unidentifiedBankDate.seconds;
                            const bankDate = moment.unix(bankDateSec).format("DD/MM/YYYY");

                            return (
                                <div key={payment.unIdentifiedID} className={"viewUnidentifiedPaymentBox"}>
                                    <div className={"unidentifiedPaymentAmount"}>
                                        {this.displayCurrency(payment.unidentifiedPaidAmount, payment)}
                                    </div>
                                    <div>
                                        <p>{payment.unidentifiedBankName}</p>
                                    </div>
                                    <div>
                                        <p>{bankDate}</p>
                                    </div>
                                    <div>
                                        <CommentCard
                                            comment={payment.unidentifiedComments}
                                        />
                                    </div>
                                    <div>
                                        <IdentifyCustomer
                                            payment={payment}
                                        />
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <Popconfirm
                                            title="Are you sure to delete this unidentified payment?"
                                            onConfirm={() => this.onDelete(payment)}
                                            onCancel={this.cancel}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            );

                        })
                    }
                </div>
            );


        } else {
            return <p className={"userAdminsEmptyWarning"}>There is no unidentified payment</p>;
        }
    }


    render() {

        return (
            <div className={"viewUnidentifiedBox"}>
                <h4>Unidentified Payments</h4>

                {/*<div>*/}
                {/*    <div className={"reportTotalValueBox"}>*/}
                {/*        <div className={"totalValueReportBox"}>*/}
                {/*            <p>Total Value</p>*/}
                {/*            { this.renderTotalValue() }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className={"viewUnidentifiedHeaders"}>
                    <div>
                        <p>Paid Amount</p>
                    </div>
                    <div>
                        <p>Bank Name</p>
                    </div>
                    <div>
                        <p>Bank Date</p>
                    </div>
                    <div>
                        <p>Comment</p>
                    </div>
                    <div>
                        <p>Identify Customer</p>
                    </div>
                    <div></div>
                </div>
                <div>
                    {this.renderUnidentifiedPayments()}
                </div>
                <div>
                    {
                        this.state.unidentifiedPaymentDeleted?
                            <p>{message.success("Unidentified payment is deleted")}</p>: null
                    }
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { unIdentifiedPayments, unidentifiedPaymentDeleted } = state.unidentified;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    //return object with state properties
    return {
        unIdentifiedPayments,
        unidentifiedPaymentDeleted,
        profile,
        systemInfo
    }
};

export default connect(mapStateToProps,{deleteUnidentifiedPayment})(ViewUnidentifiedPayments);