import React from 'react';
import { connect } from 'react-redux';
import { Button } from '../../../common/Button';
import {message, Modal} from "antd";
import { withRouter } from 'react-router-dom';

//action imports
import { legalRepoStatusChange, saveLegalRepoStatus, hideLegalRepoStatus } from '../../../../actions/LoanActions';

//component imports
import SelectLegalRepoStatus from '../../../common/SelectLegalRepoStatus';
import DeleteLegalRepoStatusCard from './DeleteLegalRepoStatusCard';
import _ from "lodash";
import {BeatLoader} from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Status from '../../../settings/manage/Status';



class LoanStatusCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            visible: this.props.visible,
            visible2: false,
            legalRepoStatus: this.props.legalRepoStatus,
            saveLegalRepoStatusLoading: this.props.saveLegalRepoStatusLoading,
            legalRepoStatusCleared: this.props.legalRepoStatusCleared,
            legalRepoStatusSaved: this.props.legalRepoStatusSaved,
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
            legalRepoStatusClearFailed: this.props.legalRepoStatusClearFailed,

        };

        this.onLegalRepoStatusChange = this.onLegalRepoStatusChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({visible: nextProps.visible})
        }

        if (nextProps.legalRepoStatus !== this.props.legalRepoStatus) {
            this.setState({legalRepoStatus: nextProps.legalRepoStatus})
        }

        if (nextProps.saveLegalRepoStatusLoading !== this.props.saveLegalRepoStatusLoading) {
            this.setState({saveLegalRepoStatusLoading: nextProps.saveLegalRepoStatusLoading})
        }

        if (nextProps.legalRepoStatusSaved !== this.props.legalRepoStatusSaved) {
            this.setState({legalRepoStatusSaved: nextProps.legalRepoStatusSaved})
        }

        if (nextProps.legalRepoStatusCleared !== this.props.legalRepoStatusCleared) {
            this.setState({legalRepoStatusCleared: nextProps.legalRepoStatusCleared})
        }

       if (nextProps.legalRepoStatusClearFailed !== this.props.legalRepoStatusClearFailed) {
            this.setState({legalRepoStatusClearFailed: nextProps.legalRepoStatusClearFailed})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }
    }

    handleOk = () => {
        this.props.hideLegalRepoStatus()
    };

    handleCancel = () => {
        this.props.hideLegalRepoStatus()
    };

    onLegalRepoStatusChange(value) {
        this.props.legalRepoStatusChange(value)
    }

    showModal2 = () => {
        this.setState({
            visible2: true,
        });
    };

    handleOk2 = () => {
        this.setState({
            visible2: false,
        });

    };

    handleCancel2 = () => {
        this.setState({
            visible2: false,
        });
    };

    onSave(loanTermsIDs) {
        const { legalRepoStatus, currentLoan, } = this.state;


        //check if there are loan terms in a selected loan

        if (loanTermsIDs.length !== 0) {

            // extract
            const customerID = currentLoan.customerID;
            const loanID = currentLoan.loanID;

            if (legalRepoStatus) {
                this.props.saveLegalRepoStatus({legalRepoStatus, customerID, loanID, loanTermsIDs})
            } else {
                message.warning("Select a loan status");
            }

        } else {
            message.warning("There is no selected loan");
        }
    }

    onManageStatusPath(){

        const { history } = this.props;
        history.push("/manage-loan");
    }

    render() {
        const {currentLoanTerms, currentLoan} = this.state;

        const customerID = currentLoan.customerID;
        const loanID = currentLoan.loanID;

        //extract loan terms IDs

        let loanTermsIDs = [];

        _.map(currentLoanTerms, term => {

            if('loanTermID' in term) {

                const loanTermID = term.loanTermID;
                loanTermsIDs.push(loanTermID);
            }

            return term
        });


        return (
            <div className={"loanStatusCardModal"}>
               <Modal
                   title="Loan Status"
                   visible={this.state.visible}
                   onOk={this.handleOk}
                   onCancel={this.handleCancel}
                   footer={null}
                   width={800}
               >
                  <div>
                      <div className={"modalBox"}>
                          <SelectLegalRepoStatus
                              label={"Select loan status"}
                              onChange={this.onLegalRepoStatusChange}
                              value= {this.state.legalRepoStatus}
                          />
                        <div className={"manageSegment"}>
                            <p onClick={() => this.showModal2() }><span>Manage Status <FontAwesomeIcon icon="caret-right" size='m'/> </span></p>
                            <Modal
                                title="Manage Status"
                                visible={this.state.visible2}
                                onOk={this.handleOk2}
                                onCancel={this.handleCancel2}
                                footer={null}
                                width={900}
                            >
                                <Status/>
                            </Modal>
                        </div>

                          {
                              this.state.saveLegalRepoStatusLoading?
                                  <BeatLoader/>:
                                  <Button
                                      onPress={() => this.onSave(loanTermsIDs)}
                                      text={"Save"}
                                  />
                          }

                            

                      </div>

                      <div className={"legalRepoModalStatusBox"}>
                          <DeleteLegalRepoStatusCard />
                      </div>
                      <div>
                          {
                              this.state.legalRepoStatusCleared?
                                  <p>{message.success("Status is deleted")}</p>: null
                          }
                      </div>
                      <div>
                          {
                              this.state.legalRepoStatusSaved?
                                  <p>{message.success("Status is saved")}</p>: null
                          }
                      </div>
                      <div>
                          {
                              this.state.legalRepoStatusClearFailed?
                          <p>{message.error("Status is not deleted")}</p>: null
                          }
                      </div>
                  </div>
               </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { legalRepoStatus, saveLegalRepoStatusLoading, legalRepoStatusSaved, currentLoan, currentLoanTerms, legalRepoStatusCleared, legalRepoStatusClearFailed } = state.loans;

    return {
        legalRepoStatus,
        saveLegalRepoStatusLoading,
        legalRepoStatusCleared,
        legalRepoStatusSaved,
        currentLoan,
        currentLoanTerms,
        legalRepoStatusClearFailed,
    }
};

export default withRouter(connect(mapStateToProps,{legalRepoStatusChange, saveLegalRepoStatus, hideLegalRepoStatus})(LoanStatusCard));
