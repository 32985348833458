import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';

//actions import
import {  fetchCollectionsLoans} from "../../../actions/CollectionsActions";

//components import



//file imports
import CustomerLoans from "./CustomerLoans";

class CustomerDetails extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            customerDetails: this.props.customerDetails,
        }
    }

    componentWillMount() {
        //fetch
        const {customerDetails} = this.state;

        if (!(_.isEmpty(customerDetails))) {
            //if customer profile has been fetched
            const customerID = customerDetails.customerID;

            //fetch loans
            this.props.fetchCollectionsLoans({ customerID});

        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.customerDetails !== this.props.customerDetails) {
            this.setState({customerDetails: nextProps.customerDetails})
        }

    }

    render() {

        return (
            <div className={"customerProfileContainer"}>
                <div>
                   <CustomerLoans/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { customerDetails } = state.collections;

    return {
        //
        customerDetails,
    }
};

export default connect(mapStateToProps,{fetchCollectionsLoans})(CustomerDetails);