import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";


//components imports

class LogsCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            log: this.props.log
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.log !== this.props.log) {
            this.setState({ log: nextProps.log });
        }
    }

    render(){
        //extract state
        const { log } = this.state;

        let seconds;
        log.logDate.seconds ? seconds = log.logDate.seconds : seconds = log.logDate._seconds;
        const logDate = moment.unix(seconds);
        const dateString = logDate.format("DD/MM/YYYY | HH:mm:ss");


        return (
            <div className={"logCard"}>
                <div>
                    <p className={"manualOverrideCardTitle"}>Time</p>
                    <p>{dateString}</p>
                </div>
                <div>
                    <p className={"manualOverrideCardTitle"}>Log ID</p>
                    <p>{log.logID}</p>
                </div>
                <div>
                    <p className={"manualOverrideCardTitle"}>Trigger Event ID</p>
                    <p>{log.triggerEventID}</p>
                </div>
                <div>
                    <p className={"manualOverrideCardTitle"}>Amount</p>
                    <p>{log.paidAmount}</p>
                </div>
                <div>
                    <p className={"manualOverrideCardTitle"}>Event</p>
                    <p>{log.event}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})



export default connect(mapStateToProps)(LogsCard)
