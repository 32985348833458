import {
    CLEAR_CHANGE_PASSWORD_MESSAGES,
    DELETE_USER,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESSFUL,
    FETCH_ADMIN_USER_SUCCESSFUL,
    FETCH_PROFILE_SUCCESSFUL,
    PASSWORD_CHANGED, RESET_PASSWORD, RESET_PASSWORD_FAILED, RESET_PASSWORD_SUCCESSFUL,
    UPDATE_ADMIN_USER,
    UPDATE_ADMIN_USER_FAILED,
    UPDATE_ADMIN_USER_SUCCESSFUL, USER_ROLE_CHANGED
} from "../actions/Types";

const INITIAL_STATE = {
    profile: {},
    fetchedAdmin: {},

    userEmail: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    loading: false,
    changePasswordLoading: false,
    changePasswordSuccessful: false,
    changePasswordFailed: false,
    changePassword: false,

    userRole: "",
};

const ProfileReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case USER_ROLE_CHANGED :
            return  {...state, [action.payload.prop]: action.payload.value};

        //fetch reducers
        case FETCH_PROFILE_SUCCESSFUL:
            return { ...state, profile: action.payload };
        case FETCH_ADMIN_USER_SUCCESSFUL:
            return { ...state, fetchedAdmin: action.payload };

            //update reducers
        case UPDATE_ADMIN_USER:
            return { ...state, loading: true };
        case UPDATE_ADMIN_USER_SUCCESSFUL:
            return { ...state, loading: false, userRole: "" };
        case UPDATE_ADMIN_USER_FAILED:
            return { ...state, loading: false };

            //delete user reducer
        case DELETE_USER:
            return { ...state, loading: true };
        case DELETE_USER_SUCCESSFUL:
            return { ...state, loading: false };
        case DELETE_USER_FAILED:
            return { ...state, loading: false };

        case PASSWORD_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };


        case RESET_PASSWORD:
            return {...state, changePasswordLoading: true };
        case RESET_PASSWORD_SUCCESSFUL:
            return {...state, newPassword: "", confirmPassword:"", changePasswordLoading: false, changePasswordSuccessful: true, changePassword: false };
        case RESET_PASSWORD_FAILED:
            return {...state, newPassword: "", confirmPassword:"", changePasswordLoading: false, changePasswordFailed: true };
            
        case CLEAR_CHANGE_PASSWORD_MESSAGES:
            return {...state, changePasswordSuccessful: false, changePasswordFailed: false };

        default:
            return state;
    }
};

export default ProfileReducer;