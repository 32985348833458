import React from 'react';
import ReactDOM from 'react-dom';

//redux import
import { createStore, applyMiddleware} from "redux";
import { Provider } from 'react-redux';

//reducer import
import Reducer from './reducers';

//css import
import './index.css';

//components import
import App from './App';
import * as serviceWorker from './serviceWorker';

//property declaration
const ReduxThunk = require('redux-thunk').default;
const store = createStore(Reducer, applyMiddleware(ReduxThunk));


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
