import {
    DELETE_UNIDENTIFIED_PAYMENT, DELETE_UNIDENTIFIED_PAYMENT_SUCCESSFUL,
    FETCH_IDENTIFIED_CUSTOMER,
    FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS,
    FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS_SUCCESSFUL,
    FETCH_IDENTIFIED_CUSTOMER_LOANS,
    FETCH_IDENTIFIED_CUSTOMER_LOANS_FAILED,
    FETCH_IDENTIFIED_CUSTOMER_LOANS_SUCCESSFUL,
    FETCH_IDENTIFIED_CUSTOMER_SUCCESSFUL,
    FETCH_UNIDENTIFIED_PAYMENTS_SUCCESSFUL,
    IDENTIFIED_CUSTOMER_BANK_DATE,
    IDENTIFIED_CUSTOMER_DETAILS_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_LIST_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED,
    IDENTIFIED_CUSTOMER_LOAN_TERM_DETAILS_CHANGED,
    IDENTIFY_PAYMENT,
    IDENTIFY_PAYMENT_FAILED,
    IDENTIFY_PAYMENT_SUCCESSFUL,
    PUSH_IDENTIFIED_CUSTOMER_CURRENT_LOAN,
    PUSH_UNIDENTIFIED_PAYMENT, RE_FETCH_UNIDENTIFIED_PAYMENTS,
    SAVE_UNIDENTIFIED_PAYMENTS, SAVE_UNIDENTIFIED_PAYMENTS_FAILED,
    SAVE_UNIDENTIFIED_PAYMENTS_SUCCESSFUL,
    SEARCH_UNIDENTIFIED_CUSTOMER,
    SEARCH_UNIDENTIFIED_CUSTOMER_FAILED,
    SEARCH_UNIDENTIFIED_CUSTOMER_NO_RECORDS,
    SEARCH_UNIDENTIFIED_CUSTOMER_SUCCESSFUL,
    UNIDENTIFIED_DATE_CHANGED,
    UNIDENTIFIED_DETAILS_CHANGED, UNIDENTIFIED_PAYMENT_CURRENCY,
} from "../actions/Types";


const INITIAL_STATE = {

    customerSearch: '',
    fetchedCustomer: {},
    noRecord: false,
    unidentifiedPaymentLoading: false,

    customerDetails: {},
    fetchedCustomerLoans: {},
    currentLoanTerms: {},
    currentLoan:{},
    loanTerm: {},
    selectedLoan: '',
    customerLoanTerm: {},

    unidentifiedPaidAmount: '',
    unidentifiedBankName: '',
    unidentifiedBankDate: '',
    unidentifiedComments: '',
    currency: false,

    selectedPayment: {},

    dueDate: '',
    paidAmount: '',
    bankName: '',
    bankDate: '',
    comments: '',

    //
    unIdentifiedPayments: {},

    loading: false,
    fetching: false,
    loanFetched: false,
    unidentifiedPaymentLoading: false,
    fetchCustomerLoading: false,
    fetchLoansLoading: false,
    fetchTermsLoading: false,
    updateLoanTermLoading: false,

    saveUnidentifiedPaymentSuccessful: false,
    saveUnidentifiedPaymentFailed: false,
    successfulIdentified: false,
    unidentifiedPaymentDeleted: false,

};

const UnidentifiedReducer =  ( state = INITIAL_STATE, action) => {
    switch(action.type) {

        case UNIDENTIFIED_DATE_CHANGED:
            return {...state, unidentifiedBankDate: action.payload, successfulIdentified: false, unidentifiedPaymentDeleted: false };
        case UNIDENTIFIED_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, successfulIdentified: false, unidentifiedPaymentDeleted: false };
        
        case SAVE_UNIDENTIFIED_PAYMENTS:
            return {...state, unidentifiedPaymentLoading: true };
        case SAVE_UNIDENTIFIED_PAYMENTS_SUCCESSFUL:
            return {...state, unidentifiedPaymentLoading: false, currency: false, unidentifiedPaidAmount: "", unidentifiedBankName: "", unidentifiedBankDate: "", unidentifiedComments: "" };
        case SAVE_UNIDENTIFIED_PAYMENTS_FAILED:
            return {...state, unidentifiedPaymentLoading: false };


         //identify customer
        //search existing customer reducers
        case IDENTIFIED_CUSTOMER_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value, successfulIdentified: false};
        case SEARCH_UNIDENTIFIED_CUSTOMER:
            return { ...state, fetching: true, noRecord: false, fetchedCustomer: {} };
        case SEARCH_UNIDENTIFIED_CUSTOMER_SUCCESSFUL:
            return { ...state, fetchedCustomer: action.payload, fetching: false, successfulIdentified: false };
        case SEARCH_UNIDENTIFIED_CUSTOMER_NO_RECORDS:
            return { ...state, noRecord: true, fetching: false };
        case SEARCH_UNIDENTIFIED_CUSTOMER_FAILED:
            return { ...state, fetching: false };

        //push to customer profile reducers
        case FETCH_IDENTIFIED_CUSTOMER:
            return {...state, fetchCustomerLoading: true };
        case FETCH_IDENTIFIED_CUSTOMER_SUCCESSFUL:
            return { ...state, customerDetails: action.payload, fetchCustomerLoading: false, };

        //push to customer loans reducer
        case FETCH_IDENTIFIED_CUSTOMER_LOANS:
            return { ...state, loading: true };
        case FETCH_IDENTIFIED_CUSTOMER_LOANS_SUCCESSFUL:
            return { ...state, fetchedCustomerLoans: action.payload, loading: false };
        case FETCH_IDENTIFIED_CUSTOMER_LOANS_FAILED:
            return { ...state, loading: false };
        case IDENTIFIED_CUSTOMER_LOAN_LIST_CHANGED:
            return {...state, selectedLoan: action.payload };
        case FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS:
            return {...state, fetchTermsLoading: true};
        case PUSH_IDENTIFIED_CUSTOMER_CURRENT_LOAN:
            return {...state, currentLoan: action.payload};
        case FETCH_IDENTIFIED_CUSTOMER_LOAN_TERMS_SUCCESSFUL:
            return {...state, currentLoanTerms: action.payload, loanFetched: true, fetchTermsLoading: false };


            //save payments
        case IDENTIFIED_CUSTOMER_LOAN_TERM_DETAILS_CHANGED:
            return {...state, customerLoanTerm: action.payload };
        case IDENTIFIED_CUSTOMER_LOAN_TERM_CARD_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case IDENTIFIED_CUSTOMER_BANK_DATE:
            return {...state, bankDate: action.payload };
        case UNIDENTIFIED_PAYMENT_CURRENCY:
            return  {...state, currency: action.payload };
        case IDENTIFY_PAYMENT:
            return {...state, updateLoanTerm: true};
        case IDENTIFY_PAYMENT_SUCCESSFUL:
            return {...state, paidAmount: "", bankName: "", bankDate: "", comments: "", updateLoanTerm: false, fetchedCustomer: {},
                selectedLoan: "", customerDetails: {}, successfulIdentified: true, unidentifiedPaymentDeleted: false, };
        case IDENTIFY_PAYMENT_FAILED:
            return {...state, updateLoanTerm: false };

        case PUSH_UNIDENTIFIED_PAYMENT:
            return { ...state, selectedPayment: action.payload };


            //fetch reducers
        case FETCH_UNIDENTIFIED_PAYMENTS_SUCCESSFUL:
            return { ...state, unIdentifiedPayments: action.payload, unidentifiedPaymentLoading: false, saveUnidentifiedPaymentSuccessful: false, saveUnidentifiedPaymentFailed: false };

            //delete unidentified payment
        case DELETE_UNIDENTIFIED_PAYMENT:
            return {...state, loading: true };
        case DELETE_UNIDENTIFIED_PAYMENT_SUCCESSFUL:
            return {...state, unidentifiedPaymentDeleted: true, successfulIdentified: false, loading: false };
        case RE_FETCH_UNIDENTIFIED_PAYMENTS:
            return {...state, unIdentifiedPayments: action.payload, unidentifiedPaymentDeleted: false };

        default:
            return state
    }
};

export default UnidentifiedReducer;