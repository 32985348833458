import React from 'react'
import { connect } from 'react-redux';
import arraySort from "array-sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//...import actions
import { } from "../../../actions/ManualOverrideActions";

//...component imports
import SurplusLoansCard from '../cards/SurplusLoansCard';
import {BeatLoader} from "react-spinners";

export class SurplusLoans extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            surplusLoans: this.props.surplusLoans,
            surplusLoansLoading: this.props.surplusLoansLoading,
        };

        this.onRefresh = this.onRefresh.bind(this);
    }

    componentWillMount(){
        //this.props.fetchSurplusLoans();
    }

    componentWillReceiveProps(nextProps, nextContext){
        if(nextProps.surplusLoans !== this.props.surplusLoans){
            this.setState({surplusLoans: nextProps.surplusLoans});
        }

        if(nextProps.surplusLoansLoading !== this.props.surplusLoansLoading){
            this.setState({surplusLoansLoading: nextProps.surplusLoansLoading});
        }
    }

    onRefresh() {
        this.props.fetchSurplusLoans();
    }

    renderSurplusLoans(){
        const { surplusLoans, surplusLoansLoading } = this.state;

        const sortedLoans = arraySort(surplusLoans, "customerName");

        if (sortedLoans.length !== 0) {
            return(
                <div className={'manualOverrideContainer'}>
                    {
                        sortedLoans.map(loan => {
                            return (
                                <SurplusLoansCard loan={loan} key={loan.loanID} />
                            )
                        })
                    }
                </div>
            );
        } else {
            if (surplusLoansLoading) {
                return <BeatLoader/>
            } else {
                return <p className={'userAdminsEmptyWarning'}>No Surplus Loans available!</p>;
            }
        }

    }


    render() {
        //extract state
        const { surplusLoans } = this.state;
        const numberOfLoans = surplusLoans.length;
        return (
            <div className={'incorrectLoansContainer'}>
                <div>
                    <h4>({numberOfLoans}) Surplus Loans</h4>
                </div>

                <div style={{ textAlign: 'right'}}><FontAwesomeIcon icon={'sync-alt'} className={'refreshIcon'} onClick={ () => this.onRefresh() }/></div>

                <div>
                    { this.renderSurplusLoans() }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { surplusLoans, surplusLoansLoading } = state.manualOverride;

    return{
        surplusLoans,
        surplusLoansLoading
    }
}


export default connect(mapStateToProps, { })(SurplusLoans);