import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";
import _ from "lodash";


//action import
import { fetchCharacters } from "../../actions/SettingsActions";


class SelectCharacter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            characters: this.props.characters
        }
    }

    componentDidMount() {
        this.props.fetchCharacters();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.characters !== this.props.characters) {
            this.setState({ characters: nextProps.characters });
        }
    }


    render() {

        const {characters } = this.state;

        const { Option } = Select;
        const charactersArray = _.map(characters, tittle => {
            return tittle;
        });

        return (
            <div>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        charactersArray.map(title => {
                            return (
                                <Option value={title.value} key={title.characterID}>{title.value}</Option>
                            );
                        })
                    }
                </Select>

            </div>
        );
    }
}
const mapStateToProps = (state) => {

    const { characters } = state.settings;

    return {
        characters,
    }
};

export default connect(mapStateToProps, {fetchCharacters})(SelectCharacter);