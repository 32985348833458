import {
    SERVICE_CHARGE_DETAILS_CHANGED,
    SAVE_SERVICE_CHARGE_DETAILS,
    SAVE_SERVICE_CHARGE_DETAILS_SUCCESSFUL,
    SAVE_SERVICE_CHARGE_DETAILS_FAILED,
    SERVICE_CHARGE_DATE_CHANGED,
    FETCH_SERVICE_CHARGES_SUCCESSFUL,
    DELETE_SERVICE_CHARGE,
    DELETE_SERVICE_CHARGE_SUCCESSFUL,
    DELETE_SERVICE_CHARGE_FAILED, CLEAR_SERVICE_CHARGE_MESSAGE
} from '../actions/Types';


const INITIAL_STATE = {
    currency: false,
    serviceChargePaidAmount: '',
    serviceChargeBankName: '',
    serviceChargeDate: '',
    serviceChargeComments: '',

    serviceChargesPayments: {},

    serviceChargeLoading: false,

    saveServiceChargeSuccessful: false,
    saveServiceChargeFailed: false,
    deleteServiceChargeSuccessful: false,
    deleteServiceChargeFailed: false,
};

const ServiceChargeReducer =  ( state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SERVICE_CHARGE_DETAILS_CHANGED: 
        return {...state, [action.payload.prop]: action.payload.value};

        case SERVICE_CHARGE_DATE_CHANGED:
            return {...state, serviceChargeDate: action.payload};
        case SAVE_SERVICE_CHARGE_DETAILS:
            return {...state, serviceChargeLoading: true };
        case SAVE_SERVICE_CHARGE_DETAILS_SUCCESSFUL:
            return {...state, currency: false, serviceChargePaidAmount: "", serviceChargeBankName: "", serviceChargeDate: "", serviceChargeComments: "", serviceChargeLoading: false, saveServiceChargeSuccessful: true, saveServiceChargeFailed: false };
        case SAVE_SERVICE_CHARGE_DETAILS_FAILED:
            return {...state, serviceChargeLoading: false, saveServiceChargeFailed: true, saveServiceChargeSuccessful: false};

            //fetch reducers
        case FETCH_SERVICE_CHARGES_SUCCESSFUL:
            return { ...state, serviceChargesPayments: action.payload, serviceChargeLoading: false, saveServiceChargeSuccessful: false, saveServiceChargeFailed: false }; 

        case DELETE_SERVICE_CHARGE:
            return {...state, };
        case DELETE_SERVICE_CHARGE_SUCCESSFUL:
            return {...state, deleteServiceChargeSuccessful: true };
        case DELETE_SERVICE_CHARGE_FAILED:
            return {...state, deleteServiceChargeFailed: true };

        case CLEAR_SERVICE_CHARGE_MESSAGE:
            return {...state, serviceChargeLoading: false, saveServiceChargeSuccessful: false, saveServiceChargeFailed: false, deleteServiceChargeSuccessful: false, deleteServiceChargeFailed: false, };

        default:
            return state;
    }
};

export default ServiceChargeReducer;

