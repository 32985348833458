import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import _ from "lodash";
import { message, Popconfirm } from 'antd';

//action imports
import { fetchAdminUser } from "../../actions/ProfileActions";
import { deleteUser } from "../../actions/ProfileActions";
import {Button} from "../common/Button";
import ChangePassword from "./subComponents/ChangePassword";

//component imports
//import {Button} from "../common/Button";
//import {Spinner} from "../common/Spinner";


class Profiles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            admins: this.props.admins,
            profile: this.props.profile,
            loading: this.props.loading,
        };

        this.renderAdmins = this.renderAdmins.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.admins !== this.props.admins) {
            this.setState({ admins: nextProps.admins });
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading });
        }
    }

    onDeleteUser(admin) {
        //extract state
        const { profile } = this.state;
        const { userID } = admin;

        if (!(_.isEmpty(profile))) {

            if (profile.role === "collectionsManager" || profile.role === "superAdmin") {
                this.props.deleteUser({ uid: userID});
                message.success('Admin Deleted');
            } else {
                message.error('You have no authorization');
            }
        }
    }

    cancelDelete = () => {
        message.error('Canceled');
    };

    onManageUser(admin) {
        //extract props
        const { history } = this.props;

        this.props.fetchAdminUser({ userID: admin.userID, history })
    }

    renderButton(admin) {
        return(
            <Popconfirm
                title="Are you sure to delete this admin?"
                onConfirm={() => this.onDeleteUser(admin) }
                onCancel={() => this.cancelDelete() }
                okText="Yes"
                cancelText="No"
            >
                <h4>Remove</h4>
            </Popconfirm>
        );
    }

    renderAdmins() {
        //extract state
        const { admins } = this.state;

        //check admins is not empty
        if (admins.length !== 0) {
            //is not empty
            return(
                <div>
                    {
                        admins.map(admin => {
                            return(
                                <div className={"userProfileCard"} key={admin.userID}>
                                    <div className={"userProfileName"}>
                                        <h4><FontAwesomeIcon icon={faUserCircle} /> {admin.name}</h4>
                                    </div>

                                    <div className={"manageUserButtonBox"}>
                                        <Button
                                            text={"Manage"}
                                            onPress={() => this.onManageUser(admin) }
                                        />
                                    </div>

                                    <div className={"removeUserButtonBox"}>
                                        { this.renderButton(admin) }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            );
        } else {
            //is empty
            return <h3 className={"userAdminsEmptyWarning"}><FontAwesomeIcon icon="battery-empty" /> Admins not loaded</h3>
        }
    }

    checkUserAccess() {
        //extract state
        const { profile } = this.state;

        const access = profile.userAccess.some(access => access === "profiles");

        if (access) {
            return(
                <div>
                    <div className={"addUserButtonBox"}>
                        <NavLink to={"/sign-up"}><h3>Add User</h3></NavLink>
                    </div>

                    <div>
                        { this.renderAdmins() }
                    </div>
                    <ChangePassword/>
                </div>
            );
        } else {
            return (
                <div>
                    <ChangePassword/>
                </div>
            );
        }
    }

    render() {
        return(
            <div className={"addUserProfileContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { admins } = state.app;
    const { profile, loading } = state.profile;

    return {
        admins,
        profile,
        loading
    };
};

export default withRouter(connect(mapStateToProps, { deleteUser, fetchAdminUser })(Profiles));