import React from "react";
import { connect } from "react-redux";
import {Button, Modal, message} from "antd";
import _ from "lodash";

//actions import
import {passwordDetailsChanged, reAuthenticateUser} from "../../../actions/ProfileActions";

//components import
import {Input} from "../../common/Input";
import {BeatLoader} from "react-spinners";

class ChangePassword extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            oldPassword: this.props.oldPassword,
            newPassword: this.props.newPassword,
            confirmPassword: this.props.confirmPassword,
            changePassword: this.props.changePassword,
            profile: this.props.profile,
            changePasswordLoading: this.props.changePasswordLoading,
            changePasswordSuccessful: this.props.changePasswordSuccessful,
            changePasswordFailed: this.props.changePasswordFailed,

            visible: false,
        }

        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.oldPassword !== this.props.oldPassword){
            this.setState({oldPassword: nextProps.oldPassword})
        }

         if (nextProps.newPassword !== this.props.newPassword){
            this.setState({newPassword: nextProps.newPassword})
        }

        if (nextProps.confirmPassword !== this.props.confirmPassword){
            this.setState({confirmPassword: nextProps.confirmPassword})
        }

        if (nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.changePassword !== this.props.changePassword){
            this.setState({changePassword: nextProps.changePassword})
        }

        if (nextProps.changePasswordLoading !== this.props.changePasswordLoading){
            this.setState({changePasswordLoading: nextProps.changePasswordLoading})
        }

        if (nextProps.changePasswordSuccessful !== this.props.changePasswordSuccessful){
            this.setState({changePasswordSuccessful: nextProps.changePasswordSuccessful})
        }

        if (nextProps.changePasswordFailed !== this.props.changePasswordFailed){
            this.setState({changePasswordFailed: nextProps.changePasswordFailed})
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
            changePassword: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    renderButton() {

        const {changePasswordLoading} = this.state;

        if (changePasswordLoading){
            //
            return <BeatLoader/>;
        } else {
            return (
                <div>
                    <Button onClick={() => this.onSave()}>Submit</Button>
                </div>
            );
        }
    }

    renderSuccessMessage(){
        //
        const {changePasswordSuccessful} = this.state;

        if (changePasswordSuccessful){
            //
            return <p>{message.success("Your password has been changed.")}</p>
        }
    }

    renderFailedMessage(){
        //
        const {changePasswordFailed} = this.state;

        if (changePasswordFailed){
            //
            return <p>{message.error("Failed to change password")}</p>
        }
    }


    onSubmitPassword() {
        //
        const { oldPassword, profile} = this.state;

        if (!(_.isEmpty(profile))) {

            if (oldPassword){
                //yes
                this.setState({changePassword: true})
            } else {
                //no
                message.warning("Fill in the required field");
            }

        } else {
            message.warning("Sorry! There is no user profile");
        }

    }

    onSave() {
        //
        const {newPassword, confirmPassword, oldPassword } = this.state;

        if (newPassword && confirmPassword && oldPassword){
            if (newPassword.length >= 6) {
                if(isNaN(newPassword)){
                    //check if the two password matches
                    if (newPassword === confirmPassword){
                        //
                        this.props.reAuthenticateUser({oldPassword, newPassword});
                    } else {
                        //
                        message.warning("Sorry! The two passwords do not match");
                    }
                }else{
                    message.warning("Enter mixed characters as password");
                }
            } else {
                message.warning("Password can't be less than 6 characters");
            }
        } else {
            message.warning("Fill in all the fields");
        }
    }

    renderProfile() {
        //
        return (
            <div>
                <Input
                    label={"New Password"}
                    type={"password"}
                    onChange={e => this.props.passwordDetailsChanged({prop: 'newPassword', value: e.target.value})}
                    value={this.state.newPassword}
                />
                {
                    this.state.newPassword?
                        <Input
                            label={"Confirm Password"}
                            type={"password"}
                            onChange={e => this.props.passwordDetailsChanged({prop: 'confirmPassword', value: e.target.value})}
                            value={this.state.confirmPassword}
                        />:
                        null
                }
                <div>
                    {this.renderButton()}
                </div>
            </div>
        );
    }


    renderPassword(){
        //
        return (
            <div>
                <Input
                    label={"Old Password"}
                    type={"password"}
                    onChange={e => this.props.passwordDetailsChanged({prop: 'oldPassword', value: e.target.value})}
                    value={this.state.oldPassword}
                />
                <Button onClick={() => this.onSubmitPassword()}>Submit</Button>
            </div>
        );
    }

    render() {

        return (
            <div>
                <Button onClick={this.showModal}>Change Password</Button>
                <Modal
                    title="Change Password"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >

                    {
                        this.state.changePassword?
                            <div>
                                {this.renderProfile()}
                            </div>:
                            <div>
                                {this.renderPassword()}
                            </div>
                    }
                    {this.renderSuccessMessage()}
                    {this.renderFailedMessage()}
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = (state) => {

    const { oldPassword, newPassword, confirmPassword, profile, changePassword, changePasswordLoading, changePasswordSuccessful, changePasswordFailed } = state.profile;
    //
    return {
        oldPassword,
        newPassword,
        confirmPassword,
        profile,
        changePassword,
        changePasswordLoading,
        changePasswordSuccessful,
        changePasswordFailed,
    };
};

export default connect(mapStateToProps, {passwordDetailsChanged, reAuthenticateUser})(ChangePassword);