import React from 'react';
import { connect } from 'react-redux';


//component imports
import { clearLegalRepoStatus } from '../../../../actions/LoanActions';
import { Button } from '../../../common/Button';
import _ from "lodash";
import {message} from "antd";


class DeleteLegalRepoStatusCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentLoan: this.props.currentLoan,
            currentLoanTerms: this.props.currentLoanTerms,
            legalRepoStatusCleared: this.props.legalRepoStatusCleared,
            legalRepoStatusClearFailed: this.props.legalRepoStatusClearFailed,
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState({currentLoanTerms: nextProps.currentLoanTerms})
        }

        if (nextProps.legalRepoStatusCleared !== this.props.legalRepoStatusCleared) {
            this.setState({legalRepoStatusCleared: nextProps.legalRepoStatusCleared})
        }

        if (nextProps.legalRepoStatusClearFailed !== this.props.legalRepoStatusClearFailed) {
            this.setState({legalRepoStatusClearFailed: nextProps.legalRepoStatusClearFailed})
        }
    }


    onDeleteLegalRepoStatus(loanTermsIDs) {
        const { currentLoan } = this.state;

        const legalRepoStatus = currentLoan.legalRepoStatus;
        const loanID = currentLoan.loanID;
        const customerID = currentLoan.customerID;

        if (loanTermsIDs) {
            this.setState({legalRepoStatusSaved: false});
            this.props.clearLegalRepoStatus({legalRepoStatus, loanID, customerID, loanTermsIDs})
        } else {
            message.warning("There are no loan terms in a selected loan");
        }
    }

    renderLegalRepoStatus(value) {

        switch (value) {
            case "legal":
                return (
                    <div>
                        <p id={"clientStatus"}>Legal</p>
                    </div>
                );
            case "repo":
                return (
                    <div>
                        <p id={"clientStatus"}>Repossessed</p>
                    </div>
                );
            case "legalRepo":
                return (
                    <div>
                        <p id={"clientStatus"}>Repossessed & Legal</p>
                    </div>
                );
            case "released":
                return (
                    <div>
                        <p id={"clientStatus"}>Released</p>
                    </div>
                );
            case "underFollowUp":
                return (
                    <div>
                        <p id={"clientStatus"}>Under Follow-Up</p>
                    </div>
                );
            case "customerAbsconding":
                return (
                    <div>
                        <p id={"clientStatus"}>Customer Absconding</p>
                    </div>
                );
            case "assetNonTraceable":
                return (
                    <div>
                        <p id={"clientStatus"}>Asset Non Traceable</p>
                    </div>
                );
            case "auctioned":
                return (
                    <div>
                        <p id={"clientStatus"}>Auctioned</p>
                    </div>
                );
            case "nonStarter":
                return (
                    <div>
                        <p id={"clientStatus"}>Non Starter</p>
                    </div>
                );
            case "others":
                return (
                    <div>
                        <p id={"clientStatus"}>Others</p>
                    </div>
                );

            default:
                return null
        }
    }


    render() {

        const { currentLoan, currentLoanTerms } = this.state;

        //extract loan terms IDs

        let loanTermsIDs = [];

        _.map(currentLoanTerms, term => {

            if('loanTermID' in term) {

                const loanTermID = term.loanTermID;
                loanTermsIDs.push(loanTermID);
            }

        });


        return (
            <div>
                {
                    currentLoan.legalRepoStatus ?

                        <div className={"deleteLoanStatusBox"}>
                            <div className={"deleteLoanStatusCardBox"}>
                                {this.renderLegalRepoStatus(currentLoan.legalRepoStatus)}
                            </div>
                            <div>
                                <Button 
                                    text={"Clear Status"}
                                    onPress={() => this.onDeleteLegalRepoStatus(loanTermsIDs)}
                                />
                                <h3>{currentLoan.legalRepoStatus}</h3>
                            </div>
                        </div> :
                        null

                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const {currentLoan, currentLoanTerms, legalRepoStatusCleared, legalRepoStatusClearFailed, } = state.loans;

    return {
        currentLoan,
        currentLoanTerms,
        legalRepoStatusCleared,
        legalRepoStatusClearFailed,
    }
};

export default connect(mapStateToProps,{clearLegalRepoStatus}) (DeleteLegalRepoStatusCard);
