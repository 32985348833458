import React from "react";
import {connect} from 'react-redux';
import {Button, message, Modal, Switch} from "antd";

//action imports
import {monthlyTargetDetailsChanged, saveMonthlyTarget} from "../../actions/AnalyticsActions";
import {ClipLoader} from "react-spinners";
import moment from "moment";

//components imports
import {Input} from "../common/Input";


class MonthlyTarget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            profile: this.props.profile,
            currency: this.props.currency,
            mTarget: this.props.mTarget,
            mTargetLoading: this.props.mTargetLoading,
            mTargetSaved: this.props.mTargetSaved,
        }
        this.onCurrencySwitchChange = this.onCurrencySwitchChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({profile: nextProps.profile})
        }

        if (nextProps.currency !== this.props.currency) {
            this.setState({currency: nextProps.currency})
        }

        if (nextProps.mTarget !== this.props.mTarget) {
            this.setState({mTarget: nextProps.mTarget})
        }

        if (nextProps.mTargetLoading !== this.props.mTargetLoading) {
            this.setState({mTargetLoading: nextProps.mTargetLoading})
        }

        if (nextProps.mTargetSaved !== this.props.mTargetSaved) {
            this.setState({mTargetSaved: nextProps.mTargetSaved})
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onCurrencySwitchChange() {
        const status = !this.state.currency;
        this.props.monthlyTargetDetailsChanged({prop: 'currency', value: status})
    }

    renderButton() {

        const {mTargetLoading} = this.state;

        if (mTargetLoading) {
            return <ClipLoader size={20} css={{marginLeft: 55}}/>;
        } else {
            return (
                <div>
                    <Button onClick={()=> this.onSave()}>Save</Button>
                </div>
            );
        }
    }

    renderMessage() {

        const {mTargetSaved} = this.state;

        if (mTargetSaved) {
            return <p>{message.success("Monthly target is saved")}</p>;
        } else {
            return null;
        }
    }

    onSave() {
        let {mTarget, currency, profile} = this.state;

        const access = profile.userAccess.some(access => access === "monthlyTarget");

        if (access) {

            //determine cash collection is tzs or usd
            let rawCurrency;
            if (currency) {
                rawCurrency = "usd";
            } else {
                rawCurrency = "tzs";
            }

            //create timestamp when monthly target was posted
            const timeStamp = new Date();
            const month = moment().format("MM");
            const year = moment().format("YYYY");
            console.log(month, year);

            const now = moment();
            const ID = moment(now).unix();
            const monthlyTargetID = ID.toString();

            if (mTarget) {

                mTarget = parseInt(mTarget);
                this.props.saveMonthlyTarget({monthlyTargetID, mTarget, currency: rawCurrency, timeStamp, month, year });
            } else {
                return <p>{message.warning("Please enter monthly target amount")}</p>
            }

        } else {
            message.warning("You have no authorization to save monthly target");
        }
    }


    render() {
        return (
            <div className={"monthlyTargetContainer"}>
                <Button type="default" onClick={this.showModal}>
                    TC Target
                </Button>
                <Modal
                    title="Add Monthly Total Collections Target"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={900}
                >
                    <div className={"modalBox monthlyTargetModal"}>
                        <div>
                            <p>Currency</p>
                            <Switch
                                checkedChildren="USD"
                                unCheckedChildren="TSH"
                                onChange={() => this.onCurrencySwitchChange() }
                                checked={this.state.currency}
                            />
                        </div>
                        <div>
                            <Input
                                label={"Amount"}
                                type={"number"}
                                onChange={e => this.props.monthlyTargetDetailsChanged({prop: 'mTarget', value: e.target.value})}
                                value={this.state.mTarget}
                            />
                        </div>
                        <div>
                            {this.renderButton()}
                        </div>
                        <div>
                            {this.renderMessage()}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const {mTarget, currency, mTargetLoading, mTargetSaved} = state.analytics;
    const { profile } = state.profile;

    return {
        profile,
        mTarget,
        currency,
        mTargetLoading,
        mTargetSaved,
    }
};

export default connect(mapStateToProps, {monthlyTargetDetailsChanged, saveMonthlyTarget})(MonthlyTarget);