import {
    CLEAR_MONTHLY_TARGET_MESSAGES, CLEAR_OD_TARGET_MESSAGES,
    DELETE_MONTHLY_TARGET,
    DELETE_MONTHLY_TARGET_FAILED,
    DELETE_MONTHLY_TARGET_SUCCESSFUL, DELETE_OD_TARGET, DELETE_OD_TARGET_FAILED, DELETE_OD_TARGET_SUCCESSFUL,
    FETCH_ANALYTICS, FETCH_ANALYTICS_FAILED,
    FETCH_ANALYTICS_SUCCESSFUL,
    FETCH_BOUNCED_TD,
    FETCH_BOUNCED_TD_FAILED,
    FETCH_BOUNCED_TD_SUCCESSFUL,
    FETCH_LEGAL_OVERDUE,
    FETCH_LEGAL_OVERDUE_FAILED,
    FETCH_LEGAL_OVERDUE_SUCCESSFUL,
    FETCH_MONTHLY_TARGET,
    FETCH_MONTHLY_TARGET_FAILED,
    FETCH_MONTHLY_TARGET_SUCCESSFUL,
    FETCH_NORMAL_OVERDUE,
    FETCH_NORMAL_OVERDUE_FAILED,
    FETCH_NORMAL_OVERDUE_SUCCESSFUL,
    FETCH_OD_COLL_TD,
    FETCH_OD_COLL_TD_FAILED,
    FETCH_OD_COLL_TD_SUCCESSFUL, FETCH_OD_TARGET, FETCH_OD_TARGET_FAILED, FETCH_OD_TARGET_SUCCESSFUL,
    FETCH_PDC_DEPOSITED,
    FETCH_PDC_DEPOSITED_FAILED,
    FETCH_PDC_DEPOSITED_SUCCESSFUL,
    FETCH_PDC_FTM,
    FETCH_PDC_FTM_FAILED,
    FETCH_PDC_FTM_SUCCESSFUL,
    FETCH_PDC_TD,
    FETCH_PDC_TD_FAILED,
    FETCH_PDC_TD_SUCCESSFUL,
    FETCH_REPO_OVERDUE,
    FETCH_REPO_OVERDUE_FAILED,
    FETCH_REPO_OVERDUE_SUCCESSFUL,
    FETCH_TOTAL_BOOK,
    FETCH_TOTAL_BOOK_FAILED,
    FETCH_TOTAL_BOOK_SUCCESSFUL,
    FETCH_TOTAL_NPL,
    FETCH_TOTAL_NPL_FAILED,
    FETCH_TOTAL_NPL_SUCCESSFUL,
    FETCH_TOTAL_OUTSTANDING,
    FETCH_TOTAL_OUTSTANDING_FAILED,
    FETCH_TOTAL_OUTSTANDING_SUCCESSFUL,
    FETCH_TOTAL_OVERDUE,
    FETCH_TOTAL_OVERDUE_FAILED,
    FETCH_TOTAL_OVERDUE_SUCCESSFUL,
    MONTHLY_TARGET_DETAILS_CHANGED,
    SAVE_MONTHLY_TARGET,
    SAVE_MONTHLY_TARGET_FAILED,
    SAVE_MONTHLY_TARGET_SUCCESSFUL, SAVE_OD_TARGET, SAVE_OD_TARGET_FAILED, SAVE_OD_TARGET_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {
    analyticsLoading: false,
    analytics: {},

    totalBook: 0,
    totalOverdue: 0,
    totalOutstanding: 0,
    totalNPL: 0,
    pdcFtm: 0,
    pdcTd: 0,
    pdcDeposited: 0,
    bouncedTd: 0,
    odCollTd: 0,
    legalOverdue: 0,
    repoOverdue: 0,
    normalOverdue: 0,
    dueCollection: 0,

    totalBookLoading: false,
    totalOverdueLoading: false,
    totalOutstandingLoading: false,
    totalNPLLoading: false,

    pdcFtmLoading: false,
    pdcTdLoading: false,
    pdcDepositedLoading: false,
    bouncedTdLoading: false,
    odCollTdLoading: false,
    legalOverdueLoading: false,
    repoOverdueLoading: false,
    normalOverdueLoading: false,
    dueCollectionLoading: false,

    //monthly target
    mTarget: '',
    currency: false,
    mTargetLoading: false,
    mTargetSaved: false,
    monthlyTarget: {},
    monthlyTargetLoading: false,
    monthlyTargetDeleted: false,
    deleteTargetFailed: false,
    mTargetFailed: false,

    odTarget: "",
    totalODTarget: {},
    odTargetSaved: false,
    odTargetLoading: false,
    odTargetDeleted: false,
    deleteODTargetFailed: false,
    odTargetFailed: false,
    totalODTargetLoading: false,
};

const AnalyticsReducer = ( state = INITIAL_STATE, action ) => {
    switch (action.type) {
        case FETCH_ANALYTICS:
            return { ...state, analyticsLoading: true };
        case FETCH_ANALYTICS_SUCCESSFUL:
            return { ...state, analytics: action.payload, analyticsLoading: false };
        case FETCH_ANALYTICS_FAILED:
            return { ...state, analyticsLoading: false };

        case FETCH_TOTAL_BOOK:
            return {...state, totalBookLoading: true };
        case FETCH_TOTAL_BOOK_SUCCESSFUL:
            return { ...state, totalBook: action.payload, totalBookLoading: false };
        case FETCH_TOTAL_BOOK_FAILED:
            return {...state, totalBookLoading:  false };


        case FETCH_TOTAL_OVERDUE:
            return {...state, totalOverdueLoading: true };
        case FETCH_TOTAL_OVERDUE_SUCCESSFUL:
            return { ...state, totalOverdue: action.payload, totalOverdueLoading: false };
        case FETCH_TOTAL_OVERDUE_FAILED:
            return { ...state, totalOverdueLoading: false };


        case FETCH_TOTAL_OUTSTANDING:
            return {...state, totalOutstandingLoading: true };
        case FETCH_TOTAL_OUTSTANDING_SUCCESSFUL:
            return { ...state, totalOutstanding: action.payload, totalOutstandingLoading: false };
        case FETCH_TOTAL_OUTSTANDING_FAILED:
            return { ...state, totalOutstandingLoading: false };


        case FETCH_TOTAL_NPL:
            return {...state, totalNPLLoading: true };
        case FETCH_TOTAL_NPL_SUCCESSFUL:
            return { ...state, totalNPL: action.payload, totalNPLLoading: false };
        case FETCH_TOTAL_NPL_FAILED:
            return { ...state, totalNPLLoading: false };


        case FETCH_PDC_FTM:
            return { ...state, pdcFtmLoading: true };
        case FETCH_PDC_FTM_SUCCESSFUL:
            return { ...state, pdcFtm: action.payload, pdcFtmLoading: false };
        case FETCH_PDC_FTM_FAILED:
            return { ...state, pdcFtmLoading: false };

        case FETCH_PDC_TD:
            return { ...state, pdcTdLoading: true };
        case FETCH_PDC_TD_SUCCESSFUL:
            return { ...state, pdcTd: action.payload, pdcTdLoading: false };
        case FETCH_PDC_TD_FAILED:
            return { ...state, pdcTdLoading: false };


        case FETCH_PDC_DEPOSITED:
            return { ...state, pdcDepositedLoading: true };
        case FETCH_PDC_DEPOSITED_SUCCESSFUL:
            return { ...state, pdcDeposited: action.payload, pdcDepositedLoading: false };
        case FETCH_PDC_DEPOSITED_FAILED:
            return { ...state, pdcDepositedLoading: false };


        case FETCH_BOUNCED_TD:
            return { ...state, bouncedTdLoading: true };
        case FETCH_BOUNCED_TD_SUCCESSFUL:
            return { ...state, bouncedTd: action.payload, bouncedTdLoading: false };
        case FETCH_BOUNCED_TD_FAILED:
            return { ...state, bouncedTdLoading: false };


        case FETCH_OD_COLL_TD:
            return { ...state, odCollTdLoading: true };
        case FETCH_OD_COLL_TD_SUCCESSFUL:
            return { ...state, odCollTd: action.payload, odCollTdLoading: false };
        case FETCH_OD_COLL_TD_FAILED:
            return { ...state, odCollTdLoading: false };


        case FETCH_LEGAL_OVERDUE:
            return { ...state, legalOverdueLoading: true };
        case FETCH_LEGAL_OVERDUE_SUCCESSFUL:
            return { ...state, legalOverdue: action.payload, legalOverdueLoading: false };
        case FETCH_LEGAL_OVERDUE_FAILED:
            return { ...state, legalOverdueLoading: false };


        case FETCH_REPO_OVERDUE:
            return { ...state, repoOverdueLoading: true };
        case FETCH_REPO_OVERDUE_SUCCESSFUL:
            return { ...state, repoOverdue: action.payload, repoOverdueLoading: false };
        case FETCH_REPO_OVERDUE_FAILED:
            return { ...state, repoOverdueLoading: false };

        case FETCH_NORMAL_OVERDUE:
            return { ...state, normalOverdueLoading: true };
        case FETCH_NORMAL_OVERDUE_SUCCESSFUL:
            return { ...state, normalOverdue: action.payload, normalOverdueLoading: false };
        case FETCH_NORMAL_OVERDUE_FAILED:
            return { ...state, normalOverdueLoading: false };


        case MONTHLY_TARGET_DETAILS_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case SAVE_MONTHLY_TARGET:
            return {...state, mTargetLoading: true };
        case SAVE_MONTHLY_TARGET_SUCCESSFUL:
            return {...state, mTarget: "", mTargetLoading: false, mTargetSaved: true };
        case SAVE_MONTHLY_TARGET_FAILED:
            return {...state, mTargetLoading: false, mTarget: "", mTargetSaved: false, mTargetFailed: true };

        case FETCH_MONTHLY_TARGET:
            return { ...state, monthlyTargetLoading: true };
        case FETCH_MONTHLY_TARGET_SUCCESSFUL:
            return { ...state, monthlyTarget: action.payload, monthlyTargetLoading: false };
        case FETCH_MONTHLY_TARGET_FAILED:
            return { ...state, monthlyTargetLoading: false };

        case DELETE_MONTHLY_TARGET:
            return {...state, };
        case DELETE_MONTHLY_TARGET_SUCCESSFUL:
            return {...state, monthlyTargetDeleted: true };
        case DELETE_MONTHLY_TARGET_FAILED:
            return {...state, deleteTargetFailed: true };
        case CLEAR_MONTHLY_TARGET_MESSAGES:
            return {...state, mTargetLoading: false, monthlyTargetLoading: false, monthlyTargetDeleted: false, mTargetSaved: false, mTarget: "",
                     deleteTargetFailed: false, mTargetFailed: false };

        case SAVE_OD_TARGET:
            return {...state, odTargetLoading: true };
        case SAVE_OD_TARGET_SUCCESSFUL:
            return {...state, odTarget: "", odTargetLoading: false, odTargetSaved: true };
        case SAVE_OD_TARGET_FAILED:
            return {...state, odTargetLoading: false, odTarget: "", odTargetSaved: false, odTargetFailed: true };

        case FETCH_OD_TARGET:
            return { ...state, totalODTargetLoading: true };
        case FETCH_OD_TARGET_SUCCESSFUL:
            return { ...state, totalODTarget: action.payload, totalODTargetLoading: false };
        case FETCH_OD_TARGET_FAILED:
            return { ...state, totalODTargetLoading: false };

        case DELETE_OD_TARGET:
            return {...state, };
        case DELETE_OD_TARGET_SUCCESSFUL:
            return {...state, odTargetDeleted: true };
        case DELETE_OD_TARGET_FAILED:
            return {...state, deleteODTargetFailed: true };
        case CLEAR_OD_TARGET_MESSAGES:
            return {...state, odTargetLoading: false, totalODTargetLoading: false, odTargetDeleted: false, odTargetSaved: false, odTarget: "",
                deleteODTargetFailed: false, odTargetFailed: false };


        default:
            return state;
    }
};

export default AnalyticsReducer;