import React from "react";
import { connect } from "react-redux";
import { message, } from 'antd';

//action imports
import { secondDemandNoticeDateChanged, saveSecondDemandNotice, hideNotices, } from "../../../actions/MasterListActions";

//component imports
import {Button} from "../../common/Button";
import {PickDate} from "../../common/PickDate";
import {BeatLoader} from "react-spinners";


class SecondDemandNotices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            loan: this.props.loan,
            loanTerm: this.props.loanTerm,
            loanID: this.props.loanID,
            overdueTerms: this.props.overdueTerms,
            demandNoticeSaveLoadings: this.props.demandNoticeSaveLoadings,
            secondDemandNoticeDate: this.props.secondDemandNoticeDate,
            secondDemandNoticeSaved: this.props.secondDemandNoticeSaved,
            secondDemandNoticeSaveLoading: this.props.secondDemandNoticeSaveLoading,
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onSaveDemandNotice = this.onSaveDemandNotice.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.loan !== this.props.loan) {
            this.setState({ loan: nextProps.loan })
        }

        if (nextProps.overdueTerms !== this.props.overdueTerms) {
            this.setState({ overdueTerms: nextProps.overdueTerms })
        }

        if (nextProps.loanID !== this.props.loanID) {
            this.setState({ loanID: nextProps.loanID })
        }

        if (nextProps.loanTerm !== this.props.loanTerm) {
            this.setState({ loanTerm: nextProps.loanTerm })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.secondDemandNoticeDate !== this.props.secondDemandNoticeDate) {
            this.setState({ secondDemandNoticeDate: nextProps.secondDemandNoticeDate })
        }

        if (nextProps.secondDemandNoticeSaved !== this.props.secondDemandNoticeSaved) {
            this.setState({ secondDemandNoticeSaved: nextProps.secondDemandNoticeSaved })
        }

        if (nextProps.demandNoticeSaveLoadings !== this.props.demandNoticeSaveLoadings) {
            this.setState({ demandNoticeSaveLoadings: nextProps.demandNoticeSaveLoadings })
        }

    }


    onDateChange(date) {
        this.props.secondDemandNoticeDateChanged(date);
    }

    renderSaveButton() {
        //extract state
        const { demandNoticeSaveLoadings } = this.state;

        if (demandNoticeSaveLoadings) {
            return <BeatLoader/>
        } else {
            return(
                <Button
                    text={"Save Notice"}
                    onPress={() => this.onSaveDemandNotice() }
                />
            );
        }
    }

    onSaveDemandNotice() {
        //extract state
        const { loan, loanTerm, secondDemandNoticeDate, profile, overdueTerms } = this.state;

        const access = profile.userAccess.some(access => access === "masterListUpdates");

        if (access) {

            const terms = overdueTerms.filter(term => term.loanID === loanTerm.loanID);

            //create demand notice id from timestamp
            // const now = moment();
            // const ID = moment(now).unix();
            // const demandNoticeID = ID.toString();

            const loanID = loanTerm.loanID;
            const loanTermID = loanTerm.loanTermID;
            const customerID = loanTerm.customerID;

            //check all variables are true
            if (secondDemandNoticeDate) {
                //change moment obj into date object to save to firebase
                const noticeDate = secondDemandNoticeDate.toDate();
                console.log(loanID, customerID, loanTermID);
                this.props.saveSecondDemandNotice({ demandNoticeDate: noticeDate, customerID, loanTermID, terms });
                // message.success("Demand notice is saved");
            } else {
                message.warning("Pick a date");
            }

        } else {
            message.warning("You have no authorization to save a demand notice");
        }

    }


    onDeleteNotice(notice) {
        this.props.deleteNotice(notice);
        // message.success("Demand notice is deleted");
    }

    render() {
        //extract state
        const { secondDemandNoticeDate } = this.state;

        return (
            <div>
                <div className={"noticesDateBox"}>
                    <PickDate
                        label={"New Notice Date"}
                        onChange={(date) => this.onDateChange(date)}
                        value={secondDemandNoticeDate}
                    />
                    { this.renderSaveButton() }
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { secondDemandNoticeDate, secondDemandNoticeSaved, secondDemandNoticeSaveLoading, loanID, overdueTerms, demandNoticeSaveLoadings } = state.masterList;
    const { profile } = state.profile;

    return {
        profile,
        secondDemandNoticeDate,
        secondDemandNoticeSaved,
        secondDemandNoticeSaveLoading,
        loanID,
        overdueTerms,
        demandNoticeSaveLoadings
    }

};

export default connect(mapStateToProps, { secondDemandNoticeDateChanged, saveSecondDemandNotice, hideNotices,})(SecondDemandNotices);
