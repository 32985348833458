import firebase from "firebase";
import _ from "lodash";
//import moment from "moment";

import {
    CALCULATE_LOAN_PENAL_INTEREST,
    CALCULATE_LOAN_PENAL_INTEREST_FAILED,
    CALCULATE_LOAN_PENAL_INTEREST_SUCCESSFUL,
    CLEAR_LOAN_TERMS,
    CLEAR_LOAN_TERMS_FAILED,
    CLEAR_LOAN_TERMS_SUCCESSFUL,
    COMPUTE_INCOMPLETE_LOANS,
    COMPUTE_INCOMPLETE_LOANS_FAILED,
    COMPUTE_INCOMPLETE_LOANS_SUCCESSFUL,
    COMPUTE_INCORRECT_LOANS,
    COMPUTE_INCORRECT_LOANS_FAILED,
    COMPUTE_INCORRECT_LOANS_SUCCESSFUL,
    COMPUTE_LOANS_FOR_PENAL_INTEREST,
    COMPUTE_LOANS_FOR_PENAL_INTEREST_FAILED,
    COMPUTE_LOANS_FOR_PENAL_INTEREST_SUCCESSFUL,
    ENABLE_MANUAL_OVERRIDE_BUTTON, FIX_LOAN, FIX_LOAN_FAILED, FIX_LOAN_SUCCESSFUL,
    MANUAL_OVERRIDE,
    MANUAL_OVERRIDE_ALL_LOANS,
    MANUAL_OVERRIDE_ALL_LOANS_FAILED,
    MANUAL_OVERRIDE_ALL_LOANS_SUCCESSFUL,
    MANUAL_OVERRIDE_FAILED,
    MANUAL_OVERRIDE_SUCCESSFUL
} from "./Types";
import moment from "moment";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

//property declaration
const XLSX = require('xlsx');

export const enableManualOverrideBtn = () => {
    return {
        type: ENABLE_MANUAL_OVERRIDE_BUTTON
    }
};

export const manualOverrideLoan = ({ customerID, loanID, loanTerms }) => {

    let updates = {
        currentPaid: firebase.firestore.FieldValue.delete(),
        modulo: firebase.firestore.FieldValue.delete(),
        surplus: firebase.firestore.FieldValue.delete(),
        lastPayment: firebase.firestore.FieldValue.delete(),
        bucket: firebase.firestore.FieldValue.delete(),
        extraFees: firebase.firestore.FieldValue.delete(),
        systemPaid: firebase.firestore.FieldValue.delete(),
        totalChequePaid: 0,
        totalCashPaid: 0,
        totalPaid: 0,
        totalOverdue: 0,
        totalOutstanding: 0,
        loanStatus: false
    };

    return(dispatch) => {
        dispatch({ type: MANUAL_OVERRIDE });
        const logDate = new Date();
        const code = Math.random().toString(36).substring(6).toUpperCase();
        const ID = moment().unix();
        const logIDx = ID.toString();
        const logID = `${code}${logIDx}`;
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("logs").doc(logID)
            .set({ customerID, loanID, logID, triggerEventID: `reset01`, paidAmount: 0, logDate, event: `resetting loan` })
            .then(() => {
                firebase.firestore().collection("loans").doc(loanID)
                    .update(updates)
                    .then(() => {
                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                            .update(updates)
                            .then(() => {
                                //delete current paid in bucket
                                clearTermsInCurrentPaidBucket({ customerID, loanID, loanTerms, dispatch });

                            })
                            .catch(e => {
                                console.log("couldn't manually configure loan term in profile");
                                console.log(e);
                                dispatch({
                                    type: MANUAL_OVERRIDE_FAILED
                                });
                            })
                    })
                    .catch(e => {
                        console.log("couldn't manually configure loan in bucket");
                        console.log(e);
                        dispatch({
                            type: MANUAL_OVERRIDE_FAILED
                        });
                    })
            })
            .catch(e => {
                console.log(e);
                console.log("could not save log");
            });
    }
};

function clearTermsInCurrentPaidBucket({ customerID, loanID, loanTerms, dispatch }) {

    //fetch all terms with loanID
    firebase.firestore().collection("currentPaidBucket").where("loanID", "==", loanID)
        .get()
        .then(snapshot => {
            if (snapshot.size === 0) {

            } else {

                snapshot.forEach(doc => {
                    const currentPaid = doc.data();
                    const currentPaidID = currentPaid.currentPaidID

                    //delete file paths
                    firebase.firestore().collection("currentPaidBucket").doc(currentPaidID).delete()
                        .then(() => {
                            //
                        })
                        .catch(e => {
                            console.log("couldn't delete cash paid in cash paid bucket");
                            console.log(e);
                            dispatch({
                                type: MANUAL_OVERRIDE_FAILED
                            });
                        })
                });
            }

            //regardless if cash paid is in bucket or not
            //delete cash collections
            clearCashCollections({ customerID, loanID, loanTerms, dispatch  });
        })
        .catch(e => {
            console.log("couldn't get cash paid in cash paid bucket");
            console.log(e);
            dispatch({
                type: MANUAL_OVERRIDE_FAILED
            });
        })
}

function clearCashCollections({ customerID, loanID, loanTerms, dispatch  }) {

    //fetch all terms with loanID
    firebase.firestore().collection("cashCollections").where("loanID", "==", loanID)
        .get()
        .then(snapshot => {
            if (snapshot.size === 0) {
                //
            } else {
                snapshot.forEach(doc => {
                    const cashCollection = doc.data();
                    const cashCollectionID = cashCollection.cashCollectionID;

                    // //delete cash collection in bucket
                    firebase.firestore().collection("cashCollections").doc(cashCollectionID).delete()
                        .then(() => {
                            //delete cash collection in profile
                            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollectionID)
                                .delete()
                                .then(() => {
                                    //cash collection deleted successfully in profile
                                })
                                .catch(e => {
                                    console.log("couldn't delete cash collection in bucket");
                                    console.log(e);
                                    dispatch({
                                        type: MANUAL_OVERRIDE_FAILED
                                    });
                                })
                        })
                        .catch(e => {
                            console.log("couldn't delete cash collection in bucket");
                            console.log(e);
                            dispatch({
                                type: MANUAL_OVERRIDE_FAILED
                            });
                        })
                });
            }

            //regardless if cash collections are there or not
            //manually override loan terms
            manualOverrideTerms ({ loanTerms, dispatch })
                .then(() => {
                    dispatch({
                        type: MANUAL_OVERRIDE_SUCCESSFUL
                    });
                })
                .catch(e => {
                    console.log("error in manual override loan terms");
                    console.log(e);
                })
        })
        .catch(e => {
            console.log("couldn't get cash paid in cash paid bucket");
            console.log(e);
            dispatch({
                type: MANUAL_OVERRIDE_FAILED
            });
            return "";
        })
}

async function manualOverrideTerms ({ loanTerms, dispatch }) {

    let updates = {
        chequeStatus: "pending",
        modulo: firebase.firestore.FieldValue.delete(),
        termStatus: firebase.firestore.FieldValue.delete(),
        transactionDate: firebase.firestore.FieldValue.delete(),
        loanStatus: firebase.firestore.FieldValue.delete(),
        loanTermStatus: false,
    };

    await _.map(loanTerms, term => {
        const loanTermID = term.loanTermID;

        firebase.firestore().collection("loanTerms").doc(loanTermID)
            .update(updates)
            .then(() => {
                const customerID = term.customerID;
                firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
                    .update(updates)
                    .then(() => {
                        //
                    })
                    .catch(e => {
                        console.log(e);
                        console.log("couldn't manually configure loan term in profile");
                        dispatch({
                            type: MANUAL_OVERRIDE_FAILED
                        });
                    })
            })
            .catch(e => {
                console.log(e);
                console.log("couldn't manually configure loan term in bucket");
                dispatch({
                    type: MANUAL_OVERRIDE_FAILED
                });
            })
    });

    return "";
}


export const manualOverrideAllLoans = ({ incorrectLoans }) => {

    let loanIDArray = [];

    //loop all incorrect loans to capture loan IDs
    _.map(incorrectLoans, loan => {
        loanIDArray.push(loan.loanID);
    })

    const data = JSON.stringify({
        loanIDArray
    });

    return(dispatch) => {
        dispatch({ type: MANUAL_OVERRIDE_ALL_LOANS });

        const url = `${project.serverUrl}manualOverrideAllIncorrectLoans`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({ type: MANUAL_OVERRIDE_ALL_LOANS_SUCCESSFUL });
                console.log("incorrect loans manual override is completed successfully");
                console.log(response);

            }).catch((error) => {
            dispatch({ type: MANUAL_OVERRIDE_ALL_LOANS_FAILED });
            console.log("Here's your manual override error from all incorrect loans");
            console.log(error);
        })
    };
};



export const calculateLoanPenalInterest = ({ loanID, customerID }) => {

    const data = JSON.stringify({
        loanID,
        customerID
    });

    return(dispatch) => {
        dispatch({ type: CALCULATE_LOAN_PENAL_INTEREST });

        const url = `${project.serverUrl}calculateLoanPenalInterest`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({ type: CALCULATE_LOAN_PENAL_INTEREST_SUCCESSFUL });
                console.log("loan penal interest calculated successfully");
                console.log(response)

            }).catch((error) => {
                dispatch({ type: CALCULATE_LOAN_PENAL_INTEREST_FAILED });
                console.log("Here's your penal interest error from loan");
                console.log(error);
        })
    };
};

export const clearLoanMigratedTerms = ({ loanID, customerID }) => {

    const data = JSON.stringify({
        loanID,
        customerID
    });

    console.log({loanID: loanID, customerID: customerID});

    return(dispatch) => {
        dispatch({ type: CLEAR_LOAN_TERMS });

        const url = `${project.serverUrl}clearLoanMigratedTerms`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({ type: CLEAR_LOAN_TERMS_SUCCESSFUL });
                console.log("clear loan migrated terms successfully");
                console.log(response)

            }).catch((error) => {
                dispatch({ type: CLEAR_LOAN_TERMS_FAILED });
                console.log("clear loan migrated terms failed");
                console.log(error);
        })
    };
};

export const clearLoanChequeTerms = ({ loanID, customerID }) => {

    const data = JSON.stringify({
        loanID,
        customerID
    });

    return(dispatch) => {
        dispatch({ type: CLEAR_LOAN_TERMS });

        const url = `${project.serverUrl}clearLoanChequeTerms`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({ type: CLEAR_LOAN_TERMS_SUCCESSFUL });
                console.log("clear loan terms successfully");
                console.log(response)

            }).catch((error) => {
                dispatch({ type: CLEAR_LOAN_TERMS_FAILED });
                console.log("clear loan terms failed");
                console.log(error);
        })
    };
};

export const fixLoan = ({ loanID, customerID }) => {

    const data = JSON.stringify({
        loanID,
        customerID
    });

    return(dispatch) => {
        dispatch({ type: FIX_LOAN });
        dispatch({ type: MANUAL_OVERRIDE });

        const url = `${project.serverUrl}fixLoan`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({ type: FIX_LOAN_SUCCESSFUL });
                dispatch({ type: MANUAL_OVERRIDE_SUCCESSFUL });
                console.log("fix loan successfully");
                console.log(response)

            }).catch((error) => {
            dispatch({ type: FIX_LOAN_FAILED });
            dispatch({ type:MANUAL_OVERRIDE_FAILED });
            console.log("fix loan failed");
            console.log(error);
        })
    };
};

export const computeIncorrectLoans = () => {
    return(dispatch) => {
        fetchIncorrectLoans({ dispatch });
    };
};

export function fetchIncorrectLoans({ dispatch }) {
    dispatch({ type: COMPUTE_INCORRECT_LOANS });

    const url = `${project.serverUrl}computeIncorrectLoans`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({
                type: COMPUTE_INCORRECT_LOANS_SUCCESSFUL,
                payload: response
            });

            //createIncorrectLoansReport ({ response });
            // console.log("incorrect loans computed successful");
            // console.log(response);
        }).catch((error) => {
        dispatch({ type: COMPUTE_INCORRECT_LOANS_FAILED });
        console.log("incorrect loans computing failed");
        console.log(error);
    })
}

export const computeIncompleteLoans = () => {
    return(dispatch) => {
        fetchIncompleteLoans({ dispatch });
    };
};

export function fetchIncompleteLoans({ dispatch }) {
    dispatch({ type: COMPUTE_INCOMPLETE_LOANS });

    const url = `${project.serverUrl}computeIncompleteLoans`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({
                type: COMPUTE_INCOMPLETE_LOANS_SUCCESSFUL,
                payload: response
            });

            //createIncorrectLoansReport ({ response });
            // console.log("incorrect loans computed successful");
            console.log(response);
        }).catch((error) => {
        dispatch({ type: COMPUTE_INCOMPLETE_LOANS_FAILED });
        console.log("incorrect loans computing failed");
        console.log(error);
    })
}

export const computeLoansForPenalInterest = () => {
    return(dispatch) => {
        fetchPenalInterestLoans({ dispatch });
    };
};

export function fetchPenalInterestLoans({ dispatch }) {
    dispatch({ type: COMPUTE_LOANS_FOR_PENAL_INTEREST });

    const url = `${project.serverUrl}computeLoansForPenalInterest`;
    fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({
                type: COMPUTE_LOANS_FOR_PENAL_INTEREST_SUCCESSFUL,
                payload: response
            });

            //createIncorrectLoansReport ({ response });
            // console.log("incorrect loans computed successful");
            // console.log(response);
        }).catch((error) => {
        dispatch({ type: COMPUTE_LOANS_FOR_PENAL_INTEREST_FAILED });
        console.log("computing loans for penal interest failed");
        console.log(error);
    })
}

// export const fetchLoansWithCurrentPaid = () => {
//     return(dispatch) => {
//         dispatch({ type: FETCH_LOANS_WITH_CURRENT_PAID });
//
//         const url = `${project.serverUrl}fetchLoansWithCurrentPaid`;
//         fetch(url, {
//             method: 'GET',
//             mode: 'cors',
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((response) => {
//
//                 dispatch({
//                     type: FETCH_LOANS_WITH_CURRENT_PAID_SUCCESSFUL,
//                     payload: response
//                 });
//
//                 // const responseArray = _.map(response, loan => {
//                 //     alterCurrentPaid({ loan });
//                 // });
//                 // createIncorrectLoansReport ({ response: responseArray });
//                 console.log("current paid loans fetched successful");
//             }).catch((error) => {
//             dispatch({ type: FETCH_LOANS_WITH_CURRENT_PAID_FAILED });
//             console.log("current paid loans fetching failed");
//             console.log(error);
//         })
//     };
// };
//
// export const fetchSurplusLoans = () => {
//     return(dispatch) => {
//         dispatch({ type: FETCH_SURPLUS_LOANS });
//
//         const url = `${project.serverUrl}computeSurplusLoans`;
//         fetch(url, {
//             method: 'GET',
//             mode: 'cors',
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((response) => {
//
//                 dispatch({
//                     type: FETCH_SURPLUS_LOANS_SUCCESSFUL,
//                     payload: response
//                 });
//
//                 // const responseArray = _.map(response, loan => {
//                 //     alterCurrentPaid({ loan });
//                 // });
//                 // createIncorrectLoansReport ({ response: responseArray });
//                 console.log("surplus loans fetched successful");
//             }).catch((error) => {
//             dispatch({ type: FETCH_SURPLUS_LOANS_FAILED });
//             console.log("surplus loans fetching failed");
//             console.log(error);
//         })
//     };
// };

//---------------------------------------------------------- COMPUTATIONS ----------------------------------------------------------//
// export const loanPaidDifference = () => {
//     return() => {
//         console.log("different loans called");
//         firebase.firestore().collection("loans").get()
//             .then(snapshot => {
//                 let loans = [];
//                 console.log({ size: snapshot.size});
//
//                 if (snapshot.size !== 0) {
//                     snapshot.forEach(doc => {
//                         const loan = doc.data();
//                         const totalPaid = loan.totalPaid;
//                         const totalCashPaid = loan.totalCashPaid;
//                         const totalChequePaid = loan.totalChequePaid;
//
//                         firebase.firestore().collection("users").doc("clients").collection(loan.customerID).doc("public").collection("loans").doc(loan.loanID)
//                             .get()
//                             .then(doc => {
//                                 if (doc.exists) {
//                                     const profileLoan = doc.data();
//                                     const profileTotalPaid = profileLoan.totalPaid;
//                                     const profileTotalCashPaid = profileLoan.totalCashPaid;
//                                     const profileTotalChequePaid = profileLoan.totalChequePaid;
//
//                                     if (totalPaid !== profileTotalPaid) {
//                                         console.log(`loan ${loan.loanID}, customer: ${loan.customerID}, total: ${totalPaid}, profile: ${profileTotalPaid}`);
//                                         // firebase.firestore().collection("users").doc("clients").collection(loan.customerID).doc("public").collection("loans").doc(loan.loanID)
//                                         //     .update({ totalPaid, totalCashPaid, totalChequePaid })
//                                         //     .then(() => {})
//                                         //     .catch(e => console.log(e));
//                                     }
//                                 }
//                             })
//                     })
//                 }
//             })
//     };
// };
//

// export const checkForPendingCheques = () => {
//     console.log("pending called");
//     return() => {
//         firebase.firestore().collection("loanTerms").get()
//             .then(snapshot => {
//                 let chequeArray = [];
//                 if (snapshot.size !== 0) {
//                     console.log({size: snapshot.size});
//                     // let totalArray = [];
//                     snapshot.forEach(doc => {
//                         //check if term is a cheque
//                         const term = doc.data();
//                         if (term.cheque) {
//                             if (term.chequeStatus === "bounced") {
//                                 //check if cheque is due today
//                                 //const day = moment("1995-12-25");
//                                 const today = moment("2021-06-04");
//                                 //const today = moment();
//                                 if ("transactionDate" in term) {
//                                     let seconds;
//                                     term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
//                                     const dueDate = moment.unix(seconds);
//
//                                     if (dueDate.isSame(today, 'months')) {
//                                         //flip to cleared
//                                         console.log(`CustomerID: ${term.customerID}, loanID: ${term.loanID}`);
//                                         chequeArray.push(term);
//                                     } else {
//                                         console.log("not this month")
//                                     }
//                                 }
//                             }
//                         }
//                             // else {
//                         //     if ("termStatus" in term) {
//                         //         if (!term.termStatus.status) {
//                         //             let seconds;
//                         //             term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
//                         //             const dueDate = moment.unix(seconds);
//                         //             const today = moment();
//                         //
//                         //             if (dueDate.isSame(today, 'months')) {
//                         //                 //flip to cleared
//                         //                 console.log(`CustomerID: ${term.customerID}, loanID: ${term.loanID}`);
//                         //                 chequeArray.push(term);
//                         //             } else {
//                         //                 console.log("not this month")
//                         //             }
//                         //         }
//                         //     }
//                         // }
//
//
//                         //check they are not cleared and don't belong to any old rescheduled loan loanStatus && rescheduleStatus: oldLoan
//                         //     let status = true;
//                         //     if ("loanStatus" in data) {
//                         //         if (data.loanStatus) {
//                         //             status = false;
//                         //         } else {
//                         //             //check if its a old loan
//                         //             if ("rescheduleStatus" in data) {
//                         //                 if (data.rescheduleStatus === "oldLoan") {
//                         //                     status = false;
//                         //                 }
//                         //             }
//                         //         }
//                         //     } else {
//                         //         //check if its a old loan
//                         //         if ("rescheduleStatus" in data) {
//                         //             if (data.rescheduleStatus === "oldLoan") {
//                         //                 status = false;
//                         //             }
//                         //         }
//                         //     }
//                         //
//                         //     if (status) {
//                         //         //check that loan is not early liquidated
//                         //         if ("earlyLiquidation" in data) {
//                         //             //do nothing
//                         //         } else {
//                         //             //check if term is within this month
//                         //             //grab due date
//                         //             let seconds;
//                         //             data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
//                         //             const dueDate = moment.unix(seconds);
//                         //             if (dueDate.isSame(moment(), "day")) {
//                         //                 chequeArray.push(data);
//                         //             }
//                         //         }
//                         //     }
//                         // })
//
//                         //console.log({ total: totalArray.reduce((a, b) => a + b, 0) })
//                     })
//                 }
//
//                 //console.log({ chequeArray: chequeArray.length });
//
//                 createIncorrectLoansReport ({ response: chequeArray });
//                 //alterCurrentPaid({ chequeArray });
//             })
//             .catch(e => console.log(e));
//     };
// }

// export const assignMigrationCleared = () => {
//     return() => {
//         const url = `${project.serverUrl}assignMigrated`;
//         fetch(url, {
//             method: 'GET',
//             mode: 'cors',
//             headers: {'Content-Type': 'application/json'},
//         }).then((response) => response.json())
//             .then((response) => {
//                 console.log("assign migration term successful");
//             }).catch((error) => {
//             console.log("assign migration term failed");
//             console.log(error);
//         })
//     };
// };

// export const assignMigrationCleared = () => {
//     return() => {
//         firebase.firestore().collection("loanTerms").get()
//             .then(snapshot => {
//                 console.log({ size: snapshot.size });
//                 if (snapshot.size !== 0) {
//                     snapshot.forEach(doc => {
//                         const term = doc.data();
//                         const loanID = term.loanID;
//                         const customerID = term.customerID;
//
//                         firebase.firestore().collection("loanTerms").doc(term.loanTermID)
//                             .update({ migrationCleared: true, migrate: true })
//                             .then(() => {
//                                 console.log(`loan: ${loanID}, ${term.loanTermID}`);
//                                 firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
//                                     .update({ migrationCleared: true, migrate: true })
//                                     .then(() => {
//                                         console.log("successfully updated loan migrationCleared");
//                                     })
//                                     .catch(e => console.log(e));
//                             })
//                             .catch(e => console.log(e));
//                     })
//                 }
//             })
//     }
// }

// function alterCurrentPaid({ chequeArray }) {
//     //fetch total paid
//     chequeArray.forEach(term => {
//         const loanTermID = term.loanTermID;
//         const customerID = term.customerID;
//         const loanID = term.loanID;
//         let totalArray = [];
//         // firebase.firestore().collection("loanTerms").where("loanID", "==", loanID).get()
//         //     .then(snapshot => {
//         //         if(snapshot.size === 0) {
//         //
//         //         } else {
//         //             snapshot.forEach(doc => {
//         //                 const loanTerm = doc.data();
//         //                 if (loanTerm.cheque) {
//         //                     if (loanTerm.chequeStatus === "cleared") {
//         //                         totalArray.push(loanTerm.amount);
//         //                     }
//         //                 }
//         //             });
//         //
//         //             const totalChequePaid = totalArray.reduce((a, b) => a + b, 0);
//         //
//         //             //got the amount post it to loan bucket
//         //             firebase.firestore().collection("loans").doc(loanID)
//         //                 .get()
//         //                 .then((doc) => {
//         //                    if (doc.exists) {
//         //                        const loan = doc.data();
//         //                        const totalCashPaid = loan.totalCashPaid;
//         //                        const totalPaid = totalCashPaid + totalChequePaid;
//         //                        firebase.firestore().collection("loans").doc(loanID)
//         //                            .update({ totalChequePaid, totalPaid })
//         //                            .then(() => {
//         //                                console.log(`Done ${loanID}`);
//         //                            })
//         //                            .catch(e => console.log(e));
//         //                    }
//         //                 })
//         //                 .catch(e => console.log(e));
//         //         }
//         //     })
//         //     .catch(e => console.log(e))
//         // firebase.firestore().collection("currentPaidBucket").doc(loanTermID)
//         //     .delete()
//         //     .then(() => {
//         //         console.log({ loanTermID });
//         //     })
//         //     .catch(e => console.log(e))
//         // firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
//         //     .update({ chequeStatus: "pending"})
//         //     .then(() => {
//         //         firebase.firestore().collection("loanTerms").doc(loanTermID)
//         //             .update({ chequeStatus: "pending" })
//         //             .then(() => {
//         //
//         //             })
//         //             .catch(e => console.log(e));
//         //     }).catch(e => console.log(e));
//         firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//             .update({ systemPaid: true })
//             .then(() => {
//                 firebase.firestore().collection("loans").doc(loanID)
//                     .update({ systemPaid: true })
//                     .then(() => {
//
//                     })
//                     .catch(e => console.log(e));
//             }).catch(e => console.log(e));
//         // firebase.firestore().collection("loans").doc(loanID)
//         //     .get()
//         //     .then((doc) => {
//         //         if (doc.exists) {
//         //             const loan = doc.data();
//         //             const totalPaid = loan.totalPaid;
//         //             const totalChequePaid = loan.totalChequePaid;
//         //             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//         //                 .update({ currentPaid: 0, totalPaid, totalChequePaid })
//         //                 .then(() => {
//         //
//         //                 })
//         //                 .catch(e => console.log(e));
//         //         }
//         //     }).catch(e => console.log(e));
//     });
// }
//
export const fetchSurplus = () => {
    return() => {
        firebase.firestore().collection("loans").get()
            .then(snapshot => {
                if (snapshot.size === 0) {
                    console.log("no loans");
                } else {
                    let loans = [];
                    snapshot.forEach(doc => {
                        const loan = doc.data();

                        if ("surplus" in loan) {
                            if (loan.surplus > 0) {
                                loans.push(loan);
                            }
                        }
                    });

                    createIncorrectLoansReport ({ response: loans });
                }
            })
            .catch(e => {
                console.log(e);
                console.log("can't get loans");
            })
    }
}
//
// export const fetchExtraFeeLoans = () => {
//     return() => {
//         firebase.firestore().collection("loans")
//             .get()
//             .then(snapshot => {
//                 let extraFeeLoans = [];
//                 if (snapshot.size !== 0) {
//                     snapshot.forEach(doc => {
//                         const loan = doc.data();
//
//                         if ("extraFees" in loan) {
//                             extraFeeLoans.push(loan);
//                         }
//                     });
//                 }
//
//                 if (extraFeeLoans.length !== 0) {
//                     extraFeeLoans.forEach(loan => {
//                         //extraFees: { 123: {}, 124: {}};
//                         const fees = loan.extraFees;
//                         _.forEach(fees, (value, key) => {
//                             console.log({ value, key });
//                             const updates = {
//                                 ...value,
//                                 extraFeeID: key
//                             };
//                             // firebase.firestore().collection("loans").doc(loan.loanID)
//                             //     .update({ [`extraFees.${key}`]: updates })
//                             //     .then(() => {
//                             //         firebase.firestore().collection("users").doc("clients").collection(loan.customerID).doc("public").collection("loans").doc(loan.loanID)
//                             //             .update({ [`extraFees.${key}`]: updates })
//                             //             .catch(() => {})
//                             //     })
//                             //     .catch(() => {});
//                         })
//                     })
//                 } else {
//                     console.log("no loans with extra fees");
//                 }
//
//                 console.log({ extraFeeLoans });
//             })
//     }
// }
//

//
function createIncorrectLoansReport ({ response }) {
    if (response.length !== 0) {

        //structure the data
        const report = response.map(loan => {

            return {
                ["CUSTOMER ID"]: loan.customerID,
                ["CUSTOMER NAME"]: loan.customerName,
                ["LOAN ID"]: loan.loanID,
                // ["LOAN TERM ID"]: loan.loanTermID,
                ["SURPLUS AMOUNT"]: loan.surplus,
            }
        });

        // /* create a new blank workbook */
        const wb = XLSX.utils.book_new();

        //change data from objects to sheets
        const ws = XLSX.utils.json_to_sheet(report, { header:["CUSTOMER ID","CUSTOMER NAME","LOAN ID", "SURPLUS AMOUNT"] });

        //set the column widths
        ws['!cols'] = [
            {wch:15},
            {wch:35},
            {wch:15},
            {wch:15},
        ];

        //append the sheet into the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

        //change date to string
        XLSX.writeFile(wb, `incorrect_loans.xlsx`);
    }
}

// export const fetchClearedLoans = () => {
//     return() => {
//         firebase.firestore().collection("loans").get()
//             .then(snapshot => {
//                 if(snapshot.size !== 0) {
//                     snapshot.forEach(loan => {
//                         const data = loan.data();
//                         if (data.loanStatus) {
//                             firebase.firestore().collection("users").doc("clients").collection(data.customerID).doc("public").collection("loans").doc(data.loanID)
//                                 .get()
//                                 .then(doc => {
//                                     if (doc.exists) {
//                                         const profileLoan = doc.data();
//                                         if (data.loanStatus !== profileLoan.loanStatus) {
//                                             console.log(`${data.customerID}, ${data.loanID}`)
//                                         }
//                                     }
//                                 })
//                                 .catch(e => console.log(e));
//                         }
//                     })
//                 }
//             })
//             .catch(e => console.log(e));
//     }
// }
// export const resetModulo = ({ loans }) => {
//     return() => {
//         loans.forEach(loan => {
//             firebase.firestore().collection("users").doc("clients").collection(loan.customerID).doc("public").collection("loans").doc(loan.loanID)
//                 .get()
//                 .then((doc => {
//                     if (doc.exists) {
//                         const data = doc.data();
//                         if ("modulo" in  data) {
//                             const modulo = data.modulo;
//
//                             firebase.firestore().collection("loans").doc(loan.loanID)
//                                 .update({ modulo })
//                                 .then(() => {
//                                     //
//                                 })
//                                 .catch(e => console.log(e));
//                         }
//                     }
//                 }))
//                 .catch(e => console.log(e));
//         })
//     }
// };


//---------------------------------------------------------- AREA 51 ---------------------------------------------------------------//


// export const resetFetchCashCollections = ({ customerID, loanID, loanTerms, totalCashPaid, totalChequePaid, totalPaid }) => {
//
//     console.log({ loanTerms });
//     let updates = {
//         currentPaid: firebase.firestore.FieldValue.delete(),
//         modulo: firebase.firestore.FieldValue.delete(),
//         lastPayment: firebase.firestore.FieldValue.delete(),
//         totalChequePaid: 0,
//         totalCashPaid: 0,
//         totalPaid: 0,
//         totalOverdue: 0,
//         totalOutstanding: 0,
//     };
//
//     return(dispatch) => {
//         firebase.firestore().collection("cashCollections").where("loanID", "==", loanID).get()
//             .then(snapshot => {
//                 let cashCollections = {};
//
//                 if (snapshot.size !== 0) {
//                     snapshot.forEach(cash => {
//                         cashCollections[cash.id] = cash.data();
//                     });
//                 }
//
//                 //check if loan is cheque or direct
//                 let cheque;
//                 _.map(loanTerms, loanTerm => {
//                     cheque = loanTerm.cheque;
//                 });
//
//                 if (cheque) {
//
//                     firebase.firestore().collection("loans").doc(loanID)
//                         .update(updates)
//                         .then(() => {
//                             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                                 .update(updates)
//                                 .then(() => {
//                                     //delete current paid in bucket
//                                     clearTermsInCurrentPaidBucket({ customerID, loanID, loanTerms, dispatch });
//
//                                 })
//                                 .catch(e => {
//                                     console.log("couldn't manually configure loan term in profile");
//                                     console.log(e);
//                                     dispatch({
//                                         type: MANUAL_OVERRIDE_FAILED
//                                     });
//                                 })
//                         })
//                         .catch(e => {
//                             console.log("couldn't manually configure loan in bucket");
//                             console.log(e);
//                             dispatch({
//                                 type: MANUAL_OVERRIDE_FAILED
//                             });
//                         });
//
//                     setTimeout(function(){
//                         //pay cash collections
//                         payCashCollections({ customerID, cheque, loanTerms, cashCollections, loanID, totalCashPaid, totalChequePaid, totalPaid })
//                     }, 30000);
//
//                 } else {
//                     firebase.firestore().collection("loans").doc(loanID)
//                         .update(updates)
//                         .then(() => {
//                             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                                 .update(updates)
//                                 .then(() => {
//                                     //delete current paid in bucket
//                                     clearTermsInCurrentPaidBucket({ customerID, loanID, loanTerms, dispatch });
//
//                                 })
//                                 .catch(e => {
//                                     console.log("couldn't manually configure loan term in profile");
//                                     console.log(e);
//                                     dispatch({
//                                         type: MANUAL_OVERRIDE_FAILED
//                                     });
//                                 })
//                         })
//                         .catch(e => {
//                             console.log("couldn't manually configure loan in bucket");
//                             console.log(e);
//                             dispatch({
//                                 type: MANUAL_OVERRIDE_FAILED
//                             });
//                         });
//
//                     setTimeout(function(){
//                         //pay cash collections
//                         payCashCollections({ customerID, cheque, loanTerms, cashCollections, loanID, totalCashPaid, totalChequePaid, totalPaid })
//                     }, 30000);
//                 }
//             })
//             .catch(e => console.log(e))
//     };
// };
//
// function payCashCollections({ customerID, cheque, loanTerms, cashCollections, loanID, totalCashPaid, totalChequePaid, totalPaid }) {
//     if(!(_.isEmpty(cashCollections))) {
//         console.log({ cashCollections });
//         //loop
//         _.map(cashCollections, cashCollection => {
//             console.log({ cashCollection });
//             firebase.firestore().collection("cashCollections").doc(cashCollection.cashCollectionID)
//                 .set(cashCollection)
//                 .then(() => {
//                     firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("cashCollections").doc(cashCollection.cashCollectionID)
//                         .set(cashCollection)
//                         .then(() => {
//                             //
//                         })
//                         .catch(e => {
//                             console.log("couldn't save cash collection");
//                             console.log(e);
//                         })
//                 })
//                 .catch(e => {
//                     console.log("couldn't save cash collection");
//                     console.log(e);
//                 })
//         });
//
//         //restore
//         if (cheque) {
//             //restore terms
//             restoreTerms({ customerID, loanTerms });
//         } else {
//             alert("Loan has completed restoration");
//         }
//
//     } else {
//         //restore terms
//         if (cheque) {
//             restoreTerms({ customerID, loanTerms });
//         } else {
//             alert("Loan has completed restoration");
//         }
//     }
//
//     restoreLoan({ customerID, loanID, totalCashPaid, totalChequePaid, totalPaid });
// }
//
// function restoreLoan({ customerID, loanID, totalCashPaid, totalChequePaid, totalPaid }) {
//     firebase.firestore().collection("loans").doc(loanID)
//         .update({ totalCashPaid, totalChequePaid, totalPaid, currentPaid: totalPaid })
//         .then(() => {
//             firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
//                 .update({ totalCashPaid, totalChequePaid, totalPaid, currentPaid: totalPaid })
//                 .then(() => {
//
//                 })
//                 .catch(e => {
//                     console.log("couldn't update loan in profile");
//                     console.log(e);
//                 })
//         })
//         .catch(e => {
//             console.log("couldn't update loan");
//             console.log(e);
//         })
// }
//
// function restoreTerms({ customerID, loanTerms }) {
//
//     _.map(loanTerms, loanTerm => {
//         let term;
//         let clearTerm = loanTerm;
//         delete clearTerm.termStatus;
//         delete clearTerm.modulo;
//         term = clearTerm;
//
//         firebase.firestore().collection("loanTerms").doc(term.loanTermID)
//             .update(term)
//             .then(() => {
//                 firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
//                     .update(term)
//                     .then(() => {
//                         //
//                     })
//                     .catch(e => console.log(e))
//             })
//             .catch(e => {
//                 console.log(e);
//             })
//     });
// }









//----------------------------------------------------------------------------------------------------------------------------------//
