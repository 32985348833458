import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from "moment";
// import { Button } from "../common/Button";
import { BeatLoader } from "react-spinners";

//actions import
import { heldChequeStatusChange, updateHeldChequeStatus, clearSelectedDate } from "../../actions/HeldActions";

//component imports;
import HeldChequeCard from "./cards/HeldChequeCard";
//import ChooseChequeStatus from "./subComponents/ChooseChequeStatus";
import { message } from "antd";

import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";


class ViewHeldCheques extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            heldCheques: this.props.heldCheques,
            date: this.props.date,
            chequeStatus: this.props.chequeStatus,
            fetchHeldChequesLoading: this.props.fetchHeldChequesLoading,
            successfullyUpdate: this.props.successfullyUpdate,
            updateLoading: this.props.updateLoading,

            searchedDate: this.props.searchedDate,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,

            localCurrency: "TSH",
        };

        this.onHeldChequeStatusChange = this.onHeldChequeStatusChange.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    }

    componentWillMount() {

        this.setState({ successfullyUpdate: false });
        this.checkCurrencyFormat();

        // const {heldCheques} = this.state;
        //
        // //check that loan term is not empty
        // if (!(_.isEmpty(heldCheques))) {
        //
        //     _.map(heldCheques, cheque => {
        //         //set cheque to match loan term cheque status
        //         this.setState({ chequeStatus: cheque.chequeStatus });
        //     })
        // }
    }

    componentWillUnmount() {
        this.props.clearSelectedDate()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.heldCheques !== this.props.heldCheques) {

            //check that loan term is not empty
            // if (!(_.isEmpty(nextProps.heldCheques))) {
            //
            //     _.map(nextProps.heldCheques, cheque => {
            //         //set cheque to match loan term cheque status
            //         this.setState({ chequeStatus: cheque.chequeStatus });
            //     })
            // }
            this.setState({ heldCheques: nextProps.heldCheques })
        }

        if (nextProps.chequeStatus !== this.props.chequeStatus) {
            this.setState({ chequeStatus: nextProps.chequeStatus })
        }

        if (nextProps.fetchHeldChequesLoading !== this.props.fetchHeldChequesLoading) {
            this.setState({ fetchHeldChequesLoading: nextProps.fetchHeldChequesLoading })
        }

        if (nextProps.successfullyUpdate !== this.props.successfullyUpdate) {
            this.setState({ successfullyUpdate: nextProps.successfullyUpdate })
        }

        if (nextProps.updateLoading !== this.props.updateLoading) {
            this.setState({ updateLoading: nextProps.updateLoading })
        }
        if (nextProps.date !== this.props.date) {
            this.setState({ date: nextProps.date })
        }

        if (nextProps.searchedDate !== this.props.searchedDate) {
            this.setState({ searchedDate: nextProps.searchedDate })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo })
        }
    }


    onHeldChequeStatusChange(value) {
        this.props.heldChequeStatusChange(value)
    }


    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    renderSuccessMessage() {
        const {successfullyUpdate} = this.state;

        if (successfullyUpdate) {
         //
            return message.success("Held cheque status is successfully updated");
        } else {
            return null;
        }
    }

    displayCurrency(value, cheque) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if cheque object is not empty
        if (!(_.isEmpty(cheque))) {
            const loanCurrency = cheque.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p>{formatter.format(displayValue)}</p>;
    }


    onUpdateStatus(cheque) {

        const { chequeStatus } = this.state;

        if (chequeStatus !== "held") {

            const previousChequeStatus = cheque.chequeStatus;
            const customerID = cheque.customerID;
            const loanID = cheque.loanID;
            const loanTermID = cheque.loanTermID;

            let paidAmount = cheque.paidAmount;
            //check if check status is cleared
            if (chequeStatus === "cleared"){
                paidAmount = cheque.amount;
            }

            this.props.updateHeldChequeStatus({ previousChequeStatus, chequeStatus, paidAmount, customerID, loanID, loanTermID })

        } else {
            message.warning("Cheque status is already held, please select another status category")
        }
    }

    renderHeldCheques() {
        //extract state
        const { heldCheques, fetchHeldChequesLoading, profile, systemInfo, localCurrency, updateLoading } = this.state;

        const cheques = _.map(heldCheques, cheques => {
            return cheques
        });

        if (fetchHeldChequesLoading) {
            //fetch is on
            return <BeatLoader />
        } else {
            //data is loaded
            //check heldCheques is not empty
            if (!(_.isEmpty(heldCheques))) {
                return (
                    <div>
                        {
                            _.map(cheques, cheque => {
                               
                                const dateSec = cheque.newDueDate.seconds;
                                const date = moment.unix(dateSec).format("DD/MM/YYYY");


                                return (
                                    <HeldChequeCard
                                        cheque={cheque}
                                        profile={profile}
                                        systemInfo={systemInfo}
                                        localCurrency={localCurrency}
                                        date={date}
                                        updateLoading={updateLoading}
                                    />
                                );
                            })
                        }
                    </div>
                );

            } else {

                const { searchedDate } = this.state;

                if (searchedDate) {
                    const heldDate = moment(searchedDate).format("DD/MM/YYYY");

                    return (
                        <div>
                            <p className={"userAdminsEmptyWarning"}>There are no held cheques for {heldDate}</p>
                        </div>
                    )
                } else {
                    return (
                        <div>
                            <p className={"userAdminsEmptyWarning"}>There are no held cheques for today </p>
                        </div>
                    )
                }
            }
        }

    }

    render() {


        return (
            <div className={"heldChequeContainer"}>
                <div className={"heldChequesHeaders"}>
                    <div><h5>Customer Name</h5></div>
                    <div><h5>Amount</h5></div>
                    <div><h5>Cheque Name</h5></div>
                    <div><h5>Bank Name</h5></div>
                    <div><h5>New Due Date</h5></div>
                    <div><h5>Cheque Status</h5></div>
                </div>
                <div>{this.renderHeldCheques()}</div>
                <div>
                    {this.renderSuccessMessage()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { heldCheques, date, chequeStatus, fetchHeldChequesLoading, successfullyUpdate, updateLoading, searchedDate } = state.heldCheques;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    return {
        heldCheques,
        chequeStatus,
        fetchHeldChequesLoading,
        successfullyUpdate,
        updateLoading,
        date,
        searchedDate,
        profile,
        systemInfo,
    }
};

export default connect(mapStateToProps, { heldChequeStatusChange, updateHeldChequeStatus, clearSelectedDate })(ViewHeldCheques);
