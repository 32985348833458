import firebase from 'firebase';
import moment from 'moment';
import _ from "lodash";
import arraySort from "array-sort";
import {message} from "antd";
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

//Types import
import {
    ALL_REPORT,
    ALL_REPORT_SUCCESSFUL,
    ALL_REPORT_FAILED,
} from "./Types";

//property declaration
const XLSX = require('xlsx');

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


let contry = '';
if (!(_.isEmpty(project))) {
    if (project.privateID === "0001") {
        contry = "TANZANIA";
    } else if (project.privateID === "0002") {
        contry = "KENYA";
    } else if (project.privateID === "0003") {
        contry = "SENEGAL";
    } else if (project.privateID === "0004") {
        contry = "NIGERIA";
    } else if (project.privateID === "0005") {
        contry = "GHANA";
    } else if (project.privateID === "0006") {
        contry = "ZAMBIA";
    } else if (project.privateID === "0007") {
        contry = "S.AFRICA";
    } else if (project.privateID === "0000") {
        contry = "FIRM23";
    }
}

export const generatePaymentMissedReport = ({reportDate, systemInfo, filterBucket, filteredLegalRepoStatus}) => {

    const localCurrency = systemInfo.defaultCurrency;
    const exchangeRate = systemInfo.exchangeRate;

    return(dispatch) => {
        //
        dispatch({ type: ALL_REPORT });
        const date = reportDate.format("DD_MM_YYYY");
        const reportID = `dashboard_${date}`;

        let successArray = [];

        const data = JSON.stringify({ reportID, path: "dashboard" });

        //invoke custom database function
        const url = `${project.serverUrl}downloadGeneratedReport`;
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            body: data,
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your reports object");
                console.log(response);

                const allBeforeTerms = response.allBeforeTerms;
                const overdueTerms = response.masterListNew;

                let dates = {};

                overdueTerms.forEach(term => {
                    let seconds;
                    if ("transactionDate" in term) {
                        if (term.transactionDate) {
                            term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                        } else {
                            term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                        }
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                    }

                    const dueDate = moment.unix(seconds);

                    //put term in current month bucket
                    //grab the month and year
                    const dueDateID = dueDate.format("MMYYYY");

                    //check if dueDateID already exists in dates object
                    if (`${dueDateID}` in dates) {
                        //terms with this date already exist
                        let terms = dates[`${dueDateID}`].terms;
                        terms.push(term);

                        dates[`${dueDateID}`].terms = terms;
                    } else {
                        //its a new date so create new object for it
                        dates[`${dueDateID}`] = {
                            date: dueDate,
                            terms: [term],
                            allBeforeTerms,
                            termsStore: overdueTerms
                        }
                    }
                })

                //FIND GRAND CONTENTS
                let totalCustomers;
                let sumInUsd;
                let sumLCY;
                let sumOfODInUSD;

                //sum of debit in local currency
                let storeTerms = [];
                if (allBeforeTerms.length !== 0) {
                    allBeforeTerms.map(term => {
                        if (term.cheque) {
                            if (term.chequeStatus === "bounced") {
                                if (term.currency !== "usd") {
                                    storeTerms.push(term.amount);
                                }
                            }
                        } else {
                            if (term.currency !== "usd") {
                                storeTerms.push(term.amount);
                            }
                        }
                    });

                    sumLCY = storeTerms.reduce((a, b) => a + b, 0);
                    // if(sumLCY !== 0){

                    //     const formatter = new Intl.NumberFormat('en-US', {
                    //         style: 'currency',
                    //         currency: localCurrency,
                    //     });

                    //     sumLCY = formatter.format(sumLCY);
                    // }
                }

                //sum of debit in usd format
                let totalInUSD = [];
                if (allBeforeTerms.length !== 0) {
                    allBeforeTerms.map(term => {
                        //filter if they are cleared or not
                        if (term.cheque) {
                            if (term.chequeStatus === "bounced") {
                                if (term.currency === "usd") {
                                    totalInUSD.push(term.amount);
                                }
                            }
                        } else {
                            if (term.currency === "usd") {
                                totalInUSD.push(term.amount);
                            }
                        }
                    });

                    sumInUsd = totalInUSD.reduce((a, b) => a + b, 0);
                    // const  currency = "USD";

                    // const formatter = new Intl.NumberFormat('en-US', {
                    //     style: 'currency',
                    //     currency,
                    // });

                    // sumInUsd = formatter.format(sumInUsd);
                }

                //sum of OD in usd
                let usdTermStore = [];
                overdueTerms.map(term => {
                    //check that term is not cleared
                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            usdTermStore.push(amount);
                        } else {
                            //fetch system info exchange rate

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            const convertedAmount = amount/exchangeRate;

                            usdTermStore.push(convertedAmount);
                        }
                    }
                });

                sumOfODInUSD = usdTermStore.reduce((a, b) => a + b, 0);
                // if(sumOfODInUSD !== 0){
                //     const  currency = "USD";

                //     const formatter = new Intl.NumberFormat('en-US', {
                //         style: 'currency',
                //         currency,
                //     });

                //     sumOfODInUSD = formatter.format(sumOfODInUSD);
                // }

                //sum of customers
                if(overdueTerms.length !== 0){
                    let customerStore = [];
                    overdueTerms.map(term => {
                        customerStore.push(term);
                    });

                    let distinctCustomers = {};

                    customerStore.map(term => {
                        if(`${term.customerID}` in distinctCustomers) {
                            //customer already in customers object
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                //grab terms from customer object
                                let terms = distinctCustomers[`${term.customerID}`].terms;
                                terms.push(term);

                                distinctCustomers[`${term.customerID}`].terms = terms;
                            }
                        } else {
                            //check that term is not cleared
                            if (term.termStatus.status) {
                                //term is cleared do nothing
                            } else {
                                distinctCustomers[`${term.customerID}`] = {
                                    customerID: term.customerID,
                                    customerName: term.customerName,
                                    terms: [term]
                                }
                            }
                        }
                    });

                    totalCustomers = Object.keys(distinctCustomers).length;
                }

                // console.log(totalCustomers, sumInUsd, sumLCY, sumOfODInUSD )

                //for examining each cheque contents
                if (!(_.isEmpty(dates))) {
                    ///extract date into array so it can be sorted
                    const datesArray = _.map(dates, date => date);
                    const sortedDates = arraySort(datesArray, "date");

                    sortedDates.map(data => {
                        const allBeforeTerms = data.allBeforeTerms;
                        const date = data.date;
                        const terms = data.terms;
                        const termsStore = data.termsStore;

                        const res = getBucket({ date, reportDate, allBeforeTerms, terms, termsStore, localCurrency });
                        successArray.push(res);

                    })

                    Promise.all(successArray).then((dashboardData) => {
                        // here is our array of dashboard data
                        if(dashboardData.length !== 0){

                            dashboardData.push({ endOfMonth: "Grand Total", totalLCY: sumLCY, totalDebitInUSD: sumInUsd, totalInUsd: sumOfODInUSD, numberOfCustomers: totalCustomers, daysRange: "", bucket: "", percent: "" });
                            generateStatusAnalysisReport({dashboardData, reportDate, dispatch, systemInfo, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket});
                            
                        }else{
                            message.info("There is no data to generate report");
                            dispatch({ type: ALL_REPORT_FAILED });
                        }
                    })

                } else {
                    message.info("There is no data to generate report");
                    dispatch({ type: ALL_REPORT_FAILED });
                }

            }).catch((error) => {

            console.log(error);
            dispatch({ type: ALL_REPORT_FAILED });
        })
    }
}

async function getBucket({ date, reportDate, allBeforeTerms, terms, termsStore, localCurrency }){

    const endOfMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endOfMonth, 'days');

    const bucket =Math.round(fromNow/30);

    return getNumberOfCustomers({ bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency })

}

async function getNumberOfCustomers({ bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency }){

    let distinctCustomers = {};
    terms.map(term => {
        if(`${term.customerID}` in distinctCustomers) {
            //customer already in customers object
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                //grab terms from customer object
                let terms = distinctCustomers[`${term.customerID}`].terms;
                terms.push(term);

                distinctCustomers[`${term.customerID}`].terms = terms;
            }
        } else {
            //check that term is not cleared
            if (term.termStatus.status) {
                //term is cleared do nothing
            } else {
                distinctCustomers[`${term.customerID}`] = {
                    customerID: term.customerID,
                    customerName: term.customerName,
                    terms: [term]
                }
            }
        }
    });

    const numberOfCustomers = Object.keys(distinctCustomers).length;

    return getODPercents({ numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency })
}

async function getODPercents({ numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency }){

    const systemRef = firebase.firestore().collection("system").doc("info");
    const systemDoc = await systemRef.get();

    if(systemDoc.exists){
        const systemInfo = systemDoc.data();

        let store = [];
        let totalStore = [];
    
        //find total of terms within this month
        terms.map(term => {
    
            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    store.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;
    
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    const convertedAmount = amount/exchangeRate;
    
                    store.push(convertedAmount);
                }
            }
        });
    
    
        //calculate the total amount from numbers in the array
        const total = store.reduce((a, b) => a + b, 0);
    
        //find total of terms within for all overdue loans
        termsStore.map(term => {
    
            //check that term is not cleared
            if (!term.termStatus.status) {
                //if loan is in usd convert the overdue to default currency
                if (term.currency === "usd") {
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    totalStore.push(amount);
                } else {
                    //fetch system info exchange rate
                    const exchangeRate = systemInfo.exchangeRate;
    
                    //grab the total overdue
                    let amount;
                    if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                    } else {
                        amount = term.amount;
                    }
    
                    const convertedAmount = amount/exchangeRate;
    
                    totalStore.push(convertedAmount);
                }
            }
        });
    
    
        //calculate the total amount from numbers in the array
        const allTotal = totalStore.reduce((a, b) => a + b, 0);
    
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const ODPercent = (total/allTotal) * 100;
        let percent = roundAccurately(ODPercent, 2);

        return renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency })
        
    }
}

async function renderDate({ percent, systemInfo, numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, localCurrency }){

    const endOfMonth = date.endOf('month').format('DD-MM-YYYY');

    return renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, endOfMonth, localCurrency })
}

async function renderBucketGraph({ percent, systemInfo, numberOfCustomers, bucket, date, reportDate, allBeforeTerms, terms, termsStore, endOfMonth, localCurrency }){
    //grab the end of the month
    const endMonth = date.endOf('month');

    //grab end of month of next month
    const today = moment();
    const nextMonth = today.add(1, 'month');
    const endOfNextMonth = nextMonth.endOf('month');

    //find the number of days from today
    const fromNow = endOfNextMonth.diff(endMonth, 'days');

    const buckets = Math.round(fromNow/30);

    let daysRange = "(0)";

    //compute date range depending on bucket
    if(buckets !== 0) {
        const endDate = buckets * 30;
        const startDate = endDate - 29;

        daysRange = `(${startDate}-${endDate})`
    }

    return renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
}

async function renderSumOfODInUSD({ percent, systemInfo, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
    let store = [];

    terms.map(term => {

        //check that term is not cleared
        if (term.termStatus.status) {
            //term is cleared
            //do nothing
        } else {
            //check if loan is in usd convert the overdue to default currency
            if (term.currency === "usd") {
                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                store.push(amount);
            } else {
                //fetch system info exchange rate
                const exchangeRate = systemInfo.exchangeRate;

                //grab the total overdue
                let amount;
                if ("modulo" in term) {
                    amount = term.amount - term.modulo;
                } else {
                    amount = term.amount;
                }

                const convertedAmount = amount/exchangeRate;

                store.push(convertedAmount);
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalInUsd = store.reduce((a, b) => a + b, 0);

    // if(totalInUsd !== 0){
    //     const  currency = "USD";

    //     const formatter = new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency,
    //     });
    
    //     totalInUsd = formatter.format(totalInUsd);

    //     return renderDebitInUSD({ totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    // } else {
        return renderDebitInUSD({ totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    // }
}

async function renderDebitInUSD({ totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
    
    let store = [];

    //take all terms with tzs
    allBeforeTerms.map(term => {
        //filter if they are cleared or not
        if (term.cheque) {
            if (term.chequeStatus === "bounced") {
                let seconds;
                if ("transactionDate" in term) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                }
                const termDueDate = moment.unix(seconds);
                if (termDueDate.isSame(date, "months")) {
                    if (term.currency === "usd") {
                        store.push(term.amount);
                    }
                }
            }
        } else {
            //check that dueDate month is similar to dueDate month in date object
            let seconds;
            term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            const termDueDate = moment.unix(seconds);
            if (termDueDate.isSame(date, "months")) {
                //check if term has penal interest
                if ("termStatus" in term) {
                    if (!term.termStatus.status) {
                        if (term.termStatus.penalInterest > 0) {
                            if (term.currency === "usd") {
                                store.push(term.amount);
                            }
                        }
                    }
                }
            }
        }
    });

    //calculate the total amount from numbers in the array
    let totalDebitInUSD = store.reduce((a, b) => a + b, 0);

    // if(totalDebitInUSD !== 0){
    //     const  currency = "USD";

    //     const formatter = new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency,
    //     });
    
    //     totalDebitInUSD = formatter.format(totalDebitInUSD);

    //     return renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    // } else {
        return renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency })
    // }
}

async function renderDebitAmountLCY({ totalDebitInUSD, totalInUsd, systemInfo, percent, numberOfCustomers, bucket, date, allBeforeTerms, terms, termsStore, endOfMonth, daysRange, localCurrency }){
           //create the terms amount store
           let store = [];

           //take all terms with tzs
           allBeforeTerms.map(term => {
               //filter if they are cleared or not
               if (term.cheque) {
                   if (term.chequeStatus === "bounced") {
                       let seconds;
                       if ("transactionDate" in term) {
                           if (term.transactionDate) {
                               term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                           } else {
                               term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                           }
                       } else {
                           term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                       }
                       const termDueDate = moment.unix(seconds);
                       if (termDueDate.isSame(date, "months")) {
                           if (term.currency !== "usd") {
                               store.push(term.amount);
                           }
                       }
                   }
               } else {
                   //check that dueDate month is similar to dueDate month in date object
                   let seconds;
                   term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                   const termDueDate = moment.unix(seconds);
                   if (termDueDate.isSame(date, "months")) {
                       if ("termStatus" in term) {
                           if (!term.termStatus.status) {
                               if (term.termStatus.penalInterest > 0) {
                                   if (term.currency !== "usd") {
                                       store.push(term.amount);
                                   }
                               }
                           }
                       }
                   }
               }
           });
   
           //calculate the total amount from numbers in the array
           let totalLCY = store.reduce((a, b) => a + b, 0);

        //    if(totalLCY !== 0){
        
        //         const formatter = new Intl.NumberFormat('en-US', {
        //             style: 'currency',
        //             currency: localCurrency,
        //         });
            
        //         totalLCY = formatter.format(totalLCY);
        //     }

            return { totalLCY, totalInUsd, totalDebitInUSD, percent, numberOfCustomers, bucket, endOfMonth, daysRange }
}

async function generateStatusAnalysisReport({dashboardData, reportDate, dispatch, systemInfo, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket}){
    const date = reportDate.format("DD_MM_YYYY");
    const reportID = `statusAnalysis_${date}`;

    const data = JSON.stringify({ reportID, path: "statusAnalysis" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log("Here's your reports object");
            console.log(response);

            if (!(_.isEmpty(response))) {
                //structure the data
                const resultArray = response.resultArray;
                const statusAnalysis = response.statusAnalysis;
                const characterAnalysis = response.characterAnalysis;
                generateProvisionReport({characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, reportDate, dispatch, filteredLegalRepoStatus, filterBucket, systemInfo});

            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }

        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({ type: ALL_REPORT_FAILED });

    });
};

async function generateProvisionReport({characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, reportDate, dispatch, filteredLegalRepoStatus, filterBucket, systemInfo}){
    const date = reportDate.format("DD_MM_YYYY");
    const reportID = `provision_${date}`;

    const body = JSON.stringify({ reportID, path: "provision" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;


    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log("Here's your reports object");
            if (response.length !== 0) {
                generateDBSegment({ provision: response, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, reportDate, dispatch, filteredLegalRepoStatus, filterBucket, systemInfo });
            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }

        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({ type: ALL_REPORT_FAILED });
    });
}

async function generateDBSegment({provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, reportDate, dispatch, filteredLegalRepoStatus, filterBucket, systemInfo }){
    const date = reportDate.format("DD_MM_YYYY");
    const reportID = `dbSegment_${date}`;

    const data = JSON.stringify({ reportID, path: "dbSegment" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log("Here's your reports object");
            console.log(response);

            if (!(_.isEmpty(response))) {

                const dbSegmentArray = response.dbSegmentArray;
                const industryDataArray = response.industryDataArray;
                generatesAnalysisReport({dbSegmentArray, industryDataArray, reportDate, dispatch, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo})
            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }

        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({ type: ALL_REPORT_FAILED });
    });
};

async function generatesAnalysisReport({dbSegmentArray, industryDataArray, reportDate, dispatch, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}){
    const date = reportDate.format("DD_MM_YYYY");
    const reportID = `analysis_${date}`;

    const data = JSON.stringify({ reportID, path: "analysis" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log("Here's your reports object");
            console.log(response);

            if (!(_.isEmpty(response))) {

                const value = response.value;
                const groupedReportPhase1 = response.groupedReportPhase1;
                const groupedReportPhase2 = response.groupedReportPhase2;

                generateODAgingReport({value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo});

            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }

        }).catch((error) => {
        console.log("Here's your error");
        console.log(error);
        dispatch({ type: ALL_REPORT_FAILED });
    });
};

async function generateODAgingReport({value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}){
    const date = reportDate.format("DD_MM_YYYY");
    const reportID = `masterlist_${date}`;

    const data = JSON.stringify({ reportID, path: "masterList" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((bouncedCheques) => {

            let termsStore = [];

            //filter loan by the bucket chosen
            //create filtered terms bucket
            let filteredTerms = [];

            _.map(bouncedCheques, client => {

                //loop over all terms and allocate to respective loan
                client.values.map(term => {
                    termsStore.push(term);
                });

                //filter by legal/repo status
                //create legal repo filtered loans bucket (array)
                let legalRepoFilteredLoans = [];

                switch (filteredLegalRepoStatus) {
                    case "legal":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "legal");
                        break;
                    case "repo":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "repo");
                        break;
                    case "legalRepo":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "legalRepo");
                        break;
                    case "released":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "released");
                        break;
                    case "underFollowUp":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "underFollowUp");
                        break;
                    case "customerAbsconding":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "customerAbsconding");
                        break;
                    case "assetNonTraceable":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "assetNonTraceable");
                        break;
                    case "auctioned":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "auctioned");
                        break;
                    case "nonStarter":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "nonStarter");
                        break;
                    case "others":
                        legalRepoFilteredLoans = termsStore.filter(term => term.legalRepoStatus === "others");
                        break;
                    default:
                        legalRepoFilteredLoans = termsStore.map(term => term);
                        break;
                }

                switch (filterBucket) {
                    case "1":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 1);
                        break;
                    case "2":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 2);
                        break;
                    case "3":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 3);
                        break;
                    case "4":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 4);
                        break;
                    case "5":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 5);
                        break;
                    case "6":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 6);
                        break;
                    case "7":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 7);
                        break;
                    case "8":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 8);
                        break;
                    case "9":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket === 9);
                        break;
                    case "10":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 10);
                        break;
                    case "11":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 11);
                        break;
                    case "12":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 12);
                        break;
                    case "13":
                        filteredTerms = legalRepoFilteredLoans.filter(loan => loan.bucket >= 13);
                        break;
                    default:
                        filteredTerms = legalRepoFilteredLoans.map(term => term);
                        break;
                }
            });

            //clients and their loans are computed
            //clients

            //check that clients object is not empty
            if (filteredTerms.length !== 0) {
                //create excel report
                const today = moment().format("DD_MM_YYYY");

                // /* create a new blank workbook */
                const wb = XLSX.utils.book_new();

                //create report bucket to store all the excel rows as objects
                let report = [];

                console.log({filteredTerms: filteredTerms.length});
                filteredTerms.map(term => {
                    let termRow = {};

                    //find comment
                    let comment = "";
                    if("masterListComment" in term){
                        comment = term.masterListComment.newComment;
                    }

                    //
                    const formatter = new Intl.NumberFormat('en-US' );

                    //calculate transaction date
                    let seconds;
                    if ("transactionDate" in term) {
                        term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                    } else {
                        term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
                    }

                    const dueDate = moment.unix(seconds);
                    //calculate OG Due Date
                    let ogDueDateSeconds;
                    term.dueDate.seconds ? ogDueDateSeconds = term.dueDate.seconds : ogDueDateSeconds = term.dueDate._seconds;
                    const ogDueDate = moment.unix(ogDueDateSeconds);

                    //calculate debit amount in tzs and usd
                    let debitAmountTZS;
                    let debitAmountShilling;
                    let debitAmountUSD;
                    let debitAmountDollar;

                    if (term.currency === "usd") {
                        //
                        debitAmountDollar = term.amount;
                        //
                        if (debitAmountDollar){

                            debitAmountUSD = formatter.format(debitAmountDollar);
                        } else {
                            debitAmountUSD = "";
                        }

                    } else {
                        //
                        debitAmountShilling = term.amount;

                        //check if debit amount in shillings is not empty then format
                        if (debitAmountShilling){

                            debitAmountTZS = formatter.format(debitAmountShilling);
                        } else {
                            debitAmountTZS = "";
                        }
                    }

                    //calculate first demand notice
                    let fistDemandNoticeSeconds;
                    let firstDemandNotice;
                    if ("firstDemandNotice" in term) {
                        term.firstDemandNotice.seconds ? fistDemandNoticeSeconds = term.firstDemandNotice.seconds : fistDemandNoticeSeconds = term.firstDemandNotice._seconds;
                        //
                        firstDemandNotice = moment.unix(fistDemandNoticeSeconds).toDate();
                    } else {
                        firstDemandNotice = "";
                    }

                    //calculate second demand notice
                    let secondDemandNoticeSeconds;
                    let secondDemandNotice;
                    if ("secondDemandNotice" in term) {
                        term.secondDemandNotice.seconds ? secondDemandNoticeSeconds = term.secondDemandNotice.seconds : secondDemandNoticeSeconds = term.secondDemandNotice._seconds;
                        //
                        secondDemandNotice = moment.unix(secondDemandNoticeSeconds).toDate();
                    } else {
                        secondDemandNotice = "";
                    }

                    let otherReason;
                    if ("otherReason" in term) {
                        //
                        otherReason = term.otherReason;
                    } else {
                        otherReason = "";
                    }

                    //assignee
                    let assignee = "";
                    if ("assignee" in term) {
                        assignee = term.assignee.name;
                    }

                    //calculate overdue in tzs and usd
                    let overdueTZS;
                    let overdueShilling;
                    let overdueUSD;
                    let overdueDollar;

                    if (term.currency === "usd") {
                        //
                        if ("modulo" in term) {
                            //render modulo
                            //extract payment and modulo
                            const modulo = term.modulo;
                            const amount = term.amount;

                            overdueDollar = amount - modulo;
                        } else {
                            //render overdue
                            //check if term is cleared or not
                            if (term.termStatus.status) {
                                overdueDollar = 0;
                            } else {
                                overdueDollar = term.amount;
                            }
                        }
                        //
                        if (overdueDollar){

                            overdueUSD = formatter.format(overdueDollar);
                        } else {
                            overdueUSD = "";
                        }

                    } else {
                        //
                        if ("modulo" in term) {
                            //render modulo
                            //extract payment and modulo
                            const modulo = term.modulo;
                            const amount = term.amount;

                            overdueShilling = amount - modulo;
                        } else {
                            //render overdue
                            //check if term is cleared or not
                            if (term.termStatus.status) {
                                overdueShilling = 0;
                            } else {
                                overdueShilling = term.amount;
                            }
                        }
                        //check if overdue in shillings is not empty then format
                        if (overdueShilling){

                            overdueTZS = formatter.format(overdueShilling);
                        } else {
                            overdueTZS = "";
                        }
                    }

                    //calculate bucket
                    const now = moment(reportDate);
                    const fromNow = now.diff(dueDate, 'days');
                    const bucket = Math.ceil(fromNow / 30);

                    // //calculate OD SUM
                    let totalOD = 0;

                    if (term.termStatus.status) {
                        //term is cleared
                        //do nothing
                    } else {
                        //check if loan is in usd convert the overdue to default currency
                        if (term.currency === "usd") {
                            //grab the total overdue
                            if ("modulo" in term) {
                                totalOD = term.amount - term.modulo;
                            } else {
                                totalOD = term.amount;
                            }

                        } else {
                            //fetch system info exchange rate
                            const exchangeRate = systemInfo.exchangeRate;

                            //grab the total overdue
                            let amount;
                            if ("modulo" in term) {
                                amount = term.amount - term.modulo;
                            } else {
                                amount = term.amount;
                            }

                            totalOD = amount / exchangeRate;
                        }
                    }

                    const dateFormat = dueDate.format("DD/MM/YYYY");
                    const originalDate = ogDueDate.format("DD/MM/YYYY");

                    termRow[`dueDate`] = dateFormat;
                    termRow[`customerName`] = term.customerName;
                    termRow[`assignee`] = assignee;
                    termRow[`salesExe`] = term.salesExe;
                    termRow[`chequeNumber`] = term.chequeNumber;
                    termRow[`debitAmountTZS`] = debitAmountTZS;
                    termRow[`debitAmountUSD`] = debitAmountUSD;
                    termRow[`bouncedReason`] = term.bouncedReason;
                    termRow[`otherReason`] = otherReason;
                    termRow[`overdueTZS`] = overdueTZS;
                    termRow[`overdueUSD`] = overdueUSD;
                    termRow[`character`] = term.character;
                    termRow[`clientAction`] = term.clientAction;
                    termRow[`legalRepoStatus`] = term.legalRepoStatus;
                    termRow[`comment`] = comment;
                    termRow[`fromNow`] = fromNow;
                    termRow[`bucket`] = bucket;
                    termRow[`clientProfile`] = term.clientProfile;
                    termRow[`truck`] = term.truck;
                    termRow[`industry`] = term.industry;
                    termRow[`model`] = term.model;
                    termRow[`segment`] = term.segment;
                    termRow[`totalOD`] = totalOD;
                    termRow[`ogDueDate`] = originalDate;
                    termRow[`firstDemandNotice`] = firstDemandNotice;
                    termRow[`secondDemandNotice`] = secondDemandNotice;
    
                    report.push(termRow);
                });

            generateCollectionTracker({report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo});

            } else {
                message.info("There is no data to generate report");
                dispatch({ type: ALL_REPORT_FAILED });
            }

        }).catch((error) => {
        dispatch({ type: ALL_REPORT_FAILED });
        console.log("Here's your error");
        console.log(error);
    });
};

async function generateCollectionTracker({report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}){
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    //fetch end of last month overdue terms
    const date = moment(reportDate).subtract(1, "months").endOf('month').format("DD_MM_YYYY");
    const dateID = `analytics_${date}`;

    firebase.firestore().collection("analyticsDailyReports").doc(dateID)
        .get()
        .then((endDoc) => {
            if (endDoc.exists) {
                const endMonthData = endDoc.data();
                        //CALCULATE OPENING OD
            let openingOverdue = endMonthData.totalOverdue;
            openingOverdue = roundAccurately(openingOverdue, 2);

            //FETCH ANALYTICS DATA
            const endMonthDate = moment(reportDate).format("DD_MM_YYYY");
            const analyticID = `analytics_${endMonthDate}`;
            firebase.firestore().collection("analyticsDailyReports").doc(analyticID)
            .get()
            .then((doc) => {
                if(doc.exists){
                    let data = doc.data();
                    if (!(_.isEmpty(data))) {


                        let overdueArray = [];
                        const month = moment(reportDate).startOf('month').format("DD-MM-YYYY");

                        data["openingOD"] = openingOverdue;

                        overdueArray.push({value: openingOverdue, label: `Opening OD as of ${month}`, key: "1"});

                        _.map(data, (value, key) => {

                            switch (key) {
                                case "totalOverdue":
                                    let overdue = value;
                                    overdue = roundAccurately(overdue, 2);
                                    overdueArray.push({value: overdue, label: "Current OD per current month", key: "2"});
                                    break;

                                case "bouncedTd":
                                    let bouncedOverdue = value;
                                    bouncedOverdue = roundAccurately(bouncedOverdue, 2);
                                    overdueArray.push({value: bouncedOverdue, label: "Bounces for the month", key: "3"});
                                    break;
                            }
                        });


                        const bouncedTd = data.bouncedTd;
                        let totalOverdue = openingOverdue + bouncedTd;

                        totalOverdue = roundAccurately(totalOverdue, 2);
                        overdueArray.push({value: totalOverdue, label: "Total OD", key: "4"});

                        // const currentMonthOD = data.totalOverdue/exchangeRate;
                        const monthCollections = roundAccurately(data.odCollTd, 2);

                        // const monthCollections = totalOverdue - currentMonthOD;


                        ///extract date into array so it can be sorted
                        if (overdueArray.length !== 0){
                            overdueArray = arraySort(overdueArray, "key");

                            overdueArray.push({ label: "COLLECTIONS FOR THE MONTH", value: monthCollections });

                        }


                        collectionTrackerReportOne11({collectionTracker: overdueArray, analytics: data, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo});

                    }
                }else{
                    message.info("There is no analytic data recorded at the end of the month");
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch({type: ALL_REPORT_FAILED});
            })
            } else {
                console.log("no data for the end of the month");
            }
        })
        .catch((e) => {
            console.log(e);
        })
    .catch(e => {
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});
    })
}

async function collectionTrackerReportOne11({collectionTracker, analytics, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}){
    try{
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let overdueArray = [];
        if (!(_.isEmpty(analytics))) {

            //initial values
            const currentOD = analytics.totalOverdue;
            const tc = analytics.tc;
            const pdcTD = analytics.pdcTd;
            const bouncedTD = analytics.bouncedTd;
            const openingOD = analytics.openingOD;
            const pdcFtm  = analytics.pdcFtm;

            //due collections
            let dueCollection = pdcTD - bouncedTD;
            const dcRatio = (dueCollection/pdcTD) * 100;
            const dueCollectionRatio = roundAccurately(dcRatio, 2);
            dueCollection = roundAccurately(dueCollection, 2);

            overdueArray.push({amount: dueCollection, description: "Due Collections", ratio: `${dueCollectionRatio}%`, key: "1"});

            //overdue collections
            let overdueCollection = analytics.odCollTd;
            const ocRatio = (overdueCollection/currentOD) * 100;
            const overdueCollectionRatio = roundAccurately(ocRatio, 2);
            overdueCollection = roundAccurately(overdueCollection, 2);

            overdueArray.push({amount: overdueCollection, description: "Overdue Collections", ratio: `${overdueCollectionRatio}%`, key: "2"});

            //collections efficiency
            let collectionEfficiency = analytics.tc;
            const ceRatio = (tc/pdcTD) * 100;
            const collectionEfficiencyRatio = roundAccurately(ceRatio, 2);
            collectionEfficiency = roundAccurately(collectionEfficiency, 2);

            overdueArray.push({amount: collectionEfficiency, description: "Collections Efficiency", ratio: `${collectionEfficiencyRatio}%`, key: "3"});

            //total collections
            let totalCollection = openingOD + pdcTD;
            const tcRatio = (tc/totalCollection) * 100;
            const totalCollectionRatio = roundAccurately(tcRatio, 2);
            totalCollection = roundAccurately(totalCollection, 2);

            overdueArray.push({amount: totalCollection, description: "Total Collections", ratio: `${totalCollectionRatio}%`, key: "4"});


            ///extract date into array so it can be sorted
            if (overdueArray.length !== 0){
                overdueArray = arraySort(overdueArray, "key");
                overdueArray.push({ description: "", amount: "", ratio: "" });
            }

            collectionTrackerReportOne({collectionTracker, collectionTracker1: overdueArray, analytics, overdueOpening: analytics.openingOD, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo});
        }
    }catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});
    }
}

async function collectionTrackerReportOne({collectionTracker, collectionTracker1, analytics, overdueOpening, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}){
    try{
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let report1 = [];
    
        if (!(_.isEmpty(analytics))) {
            //{ date, totalOverdue }
            // console.log({analytics: analytics});
            let row = {};
    
            const today = moment(reportDate);
    
            const pdcTD = analytics.pdcTd;
            const bouncedTD = analytics.bouncedTd;
            const pdcFTM = analytics.pdcFtm;
            const openingOD = analytics.openingOD;
            const bouncedForTheMonth = analytics.bouncedTd;
    
            let pdcBouncedTD = pdcTD - bouncedTD;
            pdcBouncedTD = roundAccurately(pdcBouncedTD, 2);
    
            const totalCollReqTarget = roundAccurately(pdcFTM, 2);
    
            const totalOverdue = openingOD + bouncedForTheMonth;
            let totalODReqTarget = totalOverdue * 0.5;
            totalODReqTarget = roundAccurately(totalODReqTarget, 2);
    
            row["month"]  = today.endOf('month').format("DD/MM/YYYY");
            row["pdcBouncedTD"] = pdcBouncedTD;
            row["totalODReqTarget"] = totalODReqTarget;
            row["totalCollReqTarget"] = totalCollReqTarget;
            row["tally"] = "";
    
            _.map(analytics, (value, key) => {
    
                switch (key) {
                    case "totalOverdue":
                        row["totalOverdue"] = roundAccurately(value, 2);
                        break;
                    case "pdcFtm":
                        row["pdcFtm"] = roundAccurately(value, 2);
                        break;
                    case "pdcTd":
                        row["pdcTd"] = roundAccurately(value, 2);
                        break;
                    case "bouncedTd":
                        row["bouncedTd"] = roundAccurately(value, 2);
                        break;
                    case "odCollTd":
                        row["odCollTd"] = roundAccurately(value, 2);
                        break;
                    case "tc":
                        row["tc"] = roundAccurately(value, 2);
                        break;
                }
            });
    
            report1 = [row];
        }

        getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2: report1, analytics, overdueOpening, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo});
    }
    catch(e){
        console.log(e);
        dispatch({type: ALL_REPORT_FAILED});
    }
}

async function getCollectionTracker3({collectionTracker, collectionTracker1, collectionTracker2, analytics, overdueOpening, report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, resultArray, dashboardData, localCurrency, exchangeRate, filteredLegalRepoStatus, filterBucket, systemInfo}) {
    let successArray = [];

    const getDaysOfMonth = function() {
        let daysInMonth = moment(reportDate).format('DD');
        let arrDays = [];

        while(daysInMonth) {
            const current = moment(reportDate).date(parseInt(daysInMonth));

            console.log("current");
            console.log(current);
            arrDays.push({date: current.format('MM-DD-YYYY'), timeStamp: current});
            daysInMonth--;
        }

        return arrDays;
    };

    const dateList = getDaysOfMonth();
    console.log(dateList);
    analytics["currency"] = "localCurrency";

    if (dateList.length !== 0){
        dateList.forEach((date) => {
            const response = getCollectionTrackerAnalyticsData({date: date.date, timeStamp: date.timeStamp, analyticsData: analytics, exchangeRate, overdueOpening});
            successArray.push(response);
        });
            //
            Promise.all(successArray).then((dataRecieved) => {
                //here is our array of dashboard data
                if(dataRecieved.length !== 0){
                    // /* create a new blank workbook */
                    const wb = XLSX.utils.book_new();
                    //create report bucket to store all the excel rows as objects
                    let report1 = [];

                    dataRecieved.forEach(day => {
                        let termRow = {};

                        termRow["newDate"] = day.newDate;
                        termRow["totalBook"] = day.totalBook;
                        termRow["dueCollections"] = day.dueCollections;
                        termRow["cumulativeCollections"] = day.cumulativeCollections;
                        termRow["dailyCollections"] = day.dailyCollections;
                        termRow["collectionEfficiency"] = day.collectionEfficiency;
                        termRow["repoNumber"] = "";
                        termRow["repoValue"] = "";
                        termRow["legalCases"] = day.legalCases;
                        termRow["legalValue"] = day.legalValue;
                        termRow["overdue"] = day.overdue;
                        termRow["deliquencyRate"] = day.deliquencyRate;
                        termRow["bucket1"] = day.bucket1;
                        termRow["bucket2"] = day.bucket2;
                        termRow["bucket3"] = day.bucket3;
                        termRow["bucket4"] = day.bucket4;
                        termRow["overdueTarget"] = day.overdueTarget;
                        termRow["overdueActual"] = day.overdueActual;
                        termRow["legalRepo"] = day.legalRepo;
                        termRow["insuranceCase"] = day.insuranceCase;
                        termRow["repoProgress"] = day.repoProgress;
                        termRow["promisePay"] = day.promisePay;
                        termRow["watchlist"] = day.watchlist;
                        termRow["caseOpening"] = day.caseOpening;
                        termRow["requestCase"] = day.requestCase;
                        termRow["noStatusValue"] = day.noStatusValue;
                        termRow["underFollowUpValue"] = day.underFollowUpValue;
                        termRow["partialReposessionValue"] = day.partialReposessionValue;
                        termRow["nonStarterValue"] = day.nonStarterValue;


                        report1.push(termRow);
                    })

                    exportAllData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3: report1, overdueAging: report, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, customerOD: resultArray, dashboardData});
            }
        })
    }
}

async function getCollectionTrackerAnalyticsData({date, timeStamp, analyticsData, exchangeRate, overdueOpening}){

    //get specific date analytics data
    const reportDate = timeStamp.format("DD_MM_YYYY");
    const reportID = `analytics_${reportDate}`;
    const analyticsRef = firebase.firestore().collection('analyticsDailyReports').doc(reportID);
    const doc = await analyticsRef.get();

    if (doc.exists) {
        // console.log({analytics: data});
        analyticsData = doc.data();
    }

    return getCollectionTrackerOverdueData({date, timeStamp, analyticsData, exchangeRate, overdueOpening});
}


async function getCollectionTrackerOverdueData({date, timeStamp, analyticsData, exchangeRate, overdueOpening}){

    let termsStore = [];
    let overdueTerms = [];

    //get specific date overdue data
    const dateID = timeStamp.format("DD_MM_YYYY");
    const reportID = `masterlist_${dateID}`;

    const data = JSON.stringify({ reportID, path: "masterList" });

    //invoke custom database function
    const url = `${project.serverUrl}downloadGeneratedReport`;
    await fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((bouncedCheques) => {
            //assign bouncedCheques to overdue terms
            // console.log({bounced: bouncedCheques});
            _.map(bouncedCheques, client => {
                //
                client.values.map(term => {
                    termsStore.push(term);
                });
            })
        })
        .catch(e => {
            console.log(e);
        })

    //assign overdue terms
    if (termsStore.length !== 0){
        overdueTerms = termsStore;
    }

    // console.log({date: date, overdueTerms: overdueTerms});
    return getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening});
}

async function getDailyTotalBook({date, timeStamp, analyticsData, overdueTerms, exchangeRate, overdueOpening}) {
    //
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let totalBook = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            totalBook = analyticsData.totalBook;
            totalBook = totalBook/exchangeRate;
            totalBook = roundAccurately(totalBook, 2);
        } else {
            totalBook = analyticsData.totalBook;
            totalBook = roundAccurately(totalBook, 2);
        }
    } else {
        totalBook = analyticsData.totalBook;
        totalBook = roundAccurately(totalBook, 2);
    }

    // console.log({date: date, totalBook: totalBook});

    return getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening});
}

async function getDailyScheduleDueCollection({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, overdueOpening}) {

    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dueCollections = 0;

    if ("currency" in analyticsData){
        if (analyticsData.currency === "localCurrency"){
            dueCollections = analyticsData.pdcTd;
            dueCollections = dueCollections/exchangeRate;
            dueCollections = roundAccurately(dueCollections, 2);
        } else {
            dueCollections = analyticsData.pdcTd;
            dueCollections = roundAccurately(dueCollections, 2);
        }
    } else {
        dueCollections = analyticsData.pdcTd;
        dueCollections = roundAccurately(dueCollections, 2);
    }

    return getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening });
}


async function getDailyCumulativeCollections({date, timeStamp, overdueTerms, analyticsData, exchangeRate, totalBook, dueCollections, overdueOpening }) {
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let cumulativeCollections = 0;

    if ("currency" in analyticsData){
        //
        if (analyticsData.currency === "localCurrency"){
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = cumulativeCollections/exchangeRate;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        } else {
            cumulativeCollections = analyticsData.tc;
            cumulativeCollections = roundAccurately(cumulativeCollections, 2);
        }
    } else {
        cumulativeCollections = analyticsData.tc;
        cumulativeCollections = roundAccurately(cumulativeCollections, 2);
    }


    return getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening});
}


async function getDailyCollections({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, overdueOpening}) {
    //get daily collections
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    let dailyCollections = 0;
    let collectionArray = [];

    const collectionRef = firebase.firestore().collection('cashCollections');
    const snapshot = await collectionRef.get();

    if (snapshot.size !== 0) {
        snapshot.forEach(doc => {
            const data = doc.data();

            let seconds;
            data.bankDate.seconds ? seconds = data.bankDate.seconds :  seconds = data.bankDate._seconds;

            const bankDate = moment.unix(seconds).format("MM-DD-YYYY");
            // const tday = moment();

            // if(date === bankDate){
                if(moment(bankDate).isSame(timeStamp, "day")){
                    console.log(bankDate);
                    if (data.currency === "usd") {
                        //grab the paid amount
                        collectionArray.push(data.paidAmount);
    
                    } else {
    
                        const amount = data.paidAmount/exchangeRate;
                        collectionArray.push(amount);
                    }
                }
            // }
        })
    }

    //find sum of daily collections
    dailyCollections = collectionArray.reduce((a, b) => a + b, 0);
    dailyCollections = roundAccurately(dailyCollections, 2);

    // console.log({date, totalBook: totalBook, dueCollections: dueCollections, cumulativeCollections: cumulativeCollections, collection: dailyCollections});

    return getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening});
}


async function getDailyCollectionEfficiency({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, overdueOpening}) {
    //find collection efficiency
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    let collectionEfficiency = 0;

    collectionEfficiency = cumulativeCollections/dueCollections;
    collectionEfficiency = roundAccurately(collectionEfficiency, 1);

    return getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening});
}

async function getDailyRepossessedNumber({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, overdueOpening}) {
    //
    let repoNumber = 0;
    let repoBucket = {};

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            const customerID = term.customerID;

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    //check if customerID already exists in repos object
                    if (`${customerID}` in repoBucket) {
                        //terms with this customerID already exist
                        let terms = repoBucket[`${customerID}`].terms;
                        terms.push(term);

                        repoBucket[`${customerID}`].terms = terms;
                    } else {
                        //its a customerID so create new object for it
                        repoBucket[`${customerID}`] = {
                            customerID: customerID,
                            terms: [term],
                        }
                    }
                }
            }
        })
    }

    if(!(_.isEmpty(repoBucket))){
        repoNumber = Object.keys(repoBucket).length;
    }

    // console.log({repoNumber: repoNumber});

    return getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening});
}


async function getDailyRepossessedValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, overdueOpening}) {
    //
    let repoValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {

            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/repossess/i);
                if (position >= 0){
                    //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        repoValue = termStore.reduce((a, b) => a + b, 0);
        repoValue = roundAccurately(repoValue, 2);
    }

    // console.log({repoValue: repoValue});

    return getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        overdueOpening});
}


async function getDailyLegalCases({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    overdueOpening}) {
//
let legalCases = 0;
let legalBucket = {};

if (overdueTerms.length !== 0){
overdueTerms.forEach((term) => {
const customerID = term.customerID;

if ("legalRepoStatus" in term){
const legalRepoStatus = term.legalRepoStatus;
const position = legalRepoStatus.search(/legal/i);
if (position >= 0){
//term has any of legal status
//check if customerID already exists in repos object
if (`${customerID}` in legalBucket) {
//terms with this customerID already exist
let terms = legalBucket[`${customerID}`].terms;
terms.push(term);

legalBucket[`${customerID}`].terms = terms;
} else {
//its a customerID so create new object for it
legalBucket[`${customerID}`] = {
customerID: customerID,
terms: [term],
}
}
}
}
})
}

if(!(_.isEmpty(legalBucket))){
legalCases = Object.keys(legalBucket).length;
}

// console.log({legalCases: legalCases});

return getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
legalCases, overdueOpening});
}


async function getDailyLegalValue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, overdueOpening}) {
//
    let legalValue = 0;
    let termStore = [];
    const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

    if (overdueTerms.length !== 0){
        overdueTerms.forEach((term) => {
            if ("legalRepoStatus" in term){
                const legalRepoStatus = term.legalRepoStatus;
                const position = legalRepoStatus.search(/legal/i);
                if (position >= 0){
                //term has any of repossess status
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                        } else {
                        amount = term.amount;
                        }

                        termStore.push(amount);
                    } else {

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                        amount = term.amount - term.modulo;
                        } else {
                        amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        termStore.push(convertedAmount);
                    }
                }
            }
        })
    }

    if(termStore.length !== 0){
        legalValue = termStore.reduce((a, b) => a + b, 0);
        legalValue = roundAccurately(legalValue, 2);
    }

// console.log({legalValue: legalValue});

return getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
legalCases, legalValue, overdueOpening});
}


async function getDailyOverdue({date, timeStamp, analyticsData,  overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdueOpening}) {
        let overdue = 0;
        let termStore = [];
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
        if (overdueTerms.length !== 0){
            overdueTerms.forEach((term) => {
    
                if (!term.termStatus.status){
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        termStore.push(amount);
                    } else {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        const convertedAmount = amount/exchangeRate;
    
                        termStore.push(convertedAmount);
                    }
                }
            })
        }
    
        if(termStore.length !== 0){
            overdue = termStore.reduce((a, b) => a + b, 0);
            overdue = roundAccurately(overdue, 2);
        }
    
    
        return getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, overdueOpening});
}


async function getDailyDeliquencyRate({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, overdueOpening}) {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        const dRate = overdue/totalBook * 100;
        const deliquencyRate = roundAccurately(dRate, 2);
    
        return getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
            legalCases, legalValue, overdue, deliquencyRate, overdueOpening});
}


async function getDailyBucket({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, overdueOpening}) {
        
        let b1 = 0;
        let b2 = 0;
        let b3 = 0;
        let b4 = 0;

        let arr = [];
        let arr1 = [];
        let arr2 = [];
        let arr3 = [];
        let arr4 = [];
        let arr5 = [];
        let arr6 = [];
        let arr7 = [];
        let arr8 = [];
        let arr9 = [];
        let arr10 = [];
        let arr11 = [];
        let arr12 = [];

    if(overdueTerms.length !== 0){
        let terms = {};
        overdueTerms.forEach(term => {
            
            let seconds;
            if ("transactionDate" in term) {
                if(term.transactionDate){
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds : seconds = term.transactionDate._seconds;
                }else{
                        term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds : seconds = term.dueDate._seconds;
            }


            //grab end of month of next month
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');

            const today = moment(date);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
        
            const bucket = Math.round(fromNow/30);
        
            let daysRange = "(0)";
            //compute date range depending on bucket
            if(bucket !== 0) {
                const endDate = bucket * 30;
                const startDate = endDate - 29;

                daysRange = `(${startDate}-${endDate})`
            }

            if(bucket !== undefined && bucket !== null){
                if (`${daysRange}` in terms) {
                    let termBucket = terms[`${daysRange}`].terms;
                    termBucket.push(term);
                    terms[`${daysRange}`].terms = termBucket;

                } else {
                    terms[`${daysRange}`] = {
                        range: daysRange,
                        terms: [term]
                    }
                }
            }
        })


        const termsArr = _.map(terms, client => client );

        termsArr.forEach(term => {
            if(term.range === "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr.push(amount);
                })
            }
            
            if(term.range === "(31-60)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr1.push(amount);
                })
            }
            
            if(term.range === "(61-90)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr2.push(amount);
                })
            }
            
            if(term.range === "(91-120)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr3.push(amount);
                })
            }
            
            if(term.range === "(121-150)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr4.push(amount);
                })
            }
            
            if(term.range === "(151-180)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr5.push(amount);
                })
            }
            
            if(term.range === "(181-210)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr6.push(amount);
                })
            }

            if(term.range === "(211-240)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr7.push(amount);
                })
            }
            
            if(term.range === "(241-270)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr8.push(amount);
                })
            }

            if(term.range === "(271-300)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr9.push(amount);
                })
            }
            
            if(term.range === "(301-330)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr10.push(amount);
                })
            }
            
            if(term.range === "(331-360)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr11.push(amount);
                })
            } 
            
            if(term.range !== "(331-360)" && term.range !== "(301-330)" && term.range !== "(271-300)" && term.range !== "(241-270)" && term.range !== "(211-240)" && term.range !== "(181-210)" && term.range !== "(151-180)" && term.range !== "(121-150)" && term.range !== "(91-120)" && term.range !== "(61-90)" && term.range !== "(31-60)" && term.range !== "(1-30)"){
                term.terms.forEach(term => {
                    let amount;
                    if (term.currency === "usd") {
                        //grab the total overdue
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                    } else {
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }
    
                        amount = amount/exchangeRate;
                    }
    
                    arr12.push(amount);
                })
            }
        })


        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        let bucket1 = arr.reduce((a, b) => a + b, 0);
        bucket1 = roundAccurately(bucket1, 2);
        let bucket2 = arr1.reduce((a, b) => a + b, 0);
        bucket2 = roundAccurately(bucket2, 2);
        let bucket3 = arr2.reduce((a, b) => a + b, 0);
        bucket3 = roundAccurately(bucket3, 2);
        let bucket4 = arr3.reduce((a, b) => a + b, 0);
        bucket4 = roundAccurately(bucket4, 2);
        let bucket5 = arr4.reduce((a, b) => a + b, 0);
        bucket5 = roundAccurately(bucket5, 2);
        let bucket6 = arr5.reduce((a, b) => a + b, 0);
        bucket6 = roundAccurately(bucket6, 2);
        let bucket7 = arr6.reduce((a, b) => a + b, 0);
        bucket7 = roundAccurately(bucket7, 2);
        let bucket8 = arr7.reduce((a, b) => a + b, 0);
        bucket8 = roundAccurately(bucket8, 2);
        let bucket9 = arr8.reduce((a, b) => a + b, 0);
        bucket9 = roundAccurately(bucket9, 2);
        let bucket10 = arr9.reduce((a, b) => a + b, 0);
        bucket10 = roundAccurately(bucket10, 2);
        let bucket11 = arr10.reduce((a, b) => a + b, 0);
        bucket11 = roundAccurately(bucket11, 2);
        let bucket12 = arr11.reduce((a, b) => a + b, 0);
        bucket12 = roundAccurately(bucket12, 2);
        let bucket13 = arr12.reduce((a, b) => a + b, 0);
        bucket13 = roundAccurately(bucket13, 2);

        b1 = bucket1 + bucket2 + bucket3;
        b2 = bucket4 + bucket5 + bucket6;
        b3 = bucket7 + bucket8 + bucket9 + bucket10 + bucket11 + bucket12;
        b4 = bucket13;
    }
    
    return getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1: b1, bucket2: b2, bucket3: b3, bucket4: b4, overdueOpening});
}


async function getDailyOverdueTarget({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueOpening}) {
        let overdueTarget = 0;
        // console.log({overdue: overdueOpening});
    
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
        if ("currency" in analyticsData){
            //
            if (analyticsData.currency === "localCurrency"){
                const bouncedForTheMonth = analyticsData.bouncedTd/exchangeRate;
                const totalOverdue = overdueOpening + bouncedForTheMonth;
                const totalODReqTarget = totalOverdue * 0.5;
                overdueTarget = roundAccurately(totalODReqTarget, 2);
            } else {
                const bouncedForTheMonth = analyticsData.bouncedTd;
                const totalOverdue = overdueOpening + bouncedForTheMonth;
                const totalODReqTarget = totalOverdue * 0.5;
                overdueTarget = roundAccurately(totalODReqTarget, 2);
            }
        } else {
            const bouncedForTheMonth = analyticsData.bouncedTd;
            const totalOverdue = overdueOpening + bouncedForTheMonth;
            const totalODReqTarget = totalOverdue * 0.5;
            overdueTarget = roundAccurately(totalODReqTarget, 2);
        }
    
    
    return getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget});
}


async function getDailyOverdueActual({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget}) {
        let overdueActual = 0;
        // let termsStore = [];
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
        if ("currency" in analyticsData){
            //
            if (analyticsData.currency === "localCurrency"){
                overdueActual = analyticsData.odCollTd;
                overdueActual = overdueActual/exchangeRate;
                overdueActual = roundAccurately(overdueActual, 2);
            } else {
                overdueActual = analyticsData.odCollTd;
                overdueActual = roundAccurately(overdueActual, 2);
            }
        } else {
            overdueActual = analyticsData.odCollTd;
            overdueActual = roundAccurately(overdueActual, 2);
        }
    
    return getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual});
}


async function getDaily90DaysAnalysis({date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
    legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual}) {
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);

        let legalOrRepo = [];
        let watchList = [];
        let insurance = [];
        let promiseToPay = [];
        let repoInProgress = [];
        let caseOpeningInProgress = [];
        let requestToOpenCase = [];
        let underFollowUp = [];
        let noStatus = [];
        let partialReposession = [];
        let nonStarter = [];
    
        let above90Terms = [];                                        
        overdueTerms.forEach((term) => {
            let seconds;
            if ("transactionDate" in term) {
                if (term.transactionDate) {
                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                } else {
                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                }
            } else {
                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
            }
    
            //grab today moment
            const dueDate = moment.unix(seconds);
            const endMonth = dueDate.endOf('month');
    
            const today = moment(date);
            const nextMonth = today.add(1, 'month');
            const endOfNextMonth = nextMonth.endOf('month');
        
            //find the number of days from today
            const fromNow = endOfNextMonth.diff(endMonth, 'days');
    
            if(fromNow > 90){
    
                above90Terms.push(term);
            }
        })
    
        let statusTerms = {};
        above90Terms.forEach(emi => {
            if("legalRepoStatus" in emi){
                if (`${emi.legalRepoStatus}` in statusTerms) {
                    let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
            
                    statusBucket.push(emi);
            
                    statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
            
                } else {
                    statusTerms[`${emi.legalRepoStatus}`] = [emi];
                }
            }else{
                emi['legalRepoStatus'] = "No Status";
                if (`${emi.legalRepoStatus}` in statusTerms) {
                    let statusBucket = statusTerms[`${emi.legalRepoStatus}`];
            
                    statusBucket.push(emi);
            
                    statusTerms[`${emi.legalRepoStatus}`] = statusBucket;
            
                } else {
                    statusTerms[`${emi.legalRepoStatus}`] = [emi];
                }
            }
        })
    
    
        let legalRepoStatusArray = [];
        let totalOverdueArr = [];
        const termsArr = _.map(statusTerms, client => client );
        termsArr.forEach((loanTerms) => {
            loanTerms.forEach(loanTerm => {
                let amount;
                if (loanTerm.currency === "usd") {
                    //grab the total overdue
                    if ("modulo" in loanTerm) {
                        amount = loanTerm.amount - loanTerm.modulo;
                    } else {
                        amount = loanTerm.amount;
                    }
    
                } else {
                    if ("modulo" in loanTerm) {
                        amount = loanTerm.amount - loanTerm.modulo;
                    } else {
                        amount = loanTerm.amount;
                    }
    
                    amount = amount/exchangeRate;
                }
                totalOverdueArr.push(amount);
                legalRepoStatusArray.push({name: loanTerm.legalRepoStatus, value: amount});
            })
        })
        console.log({legalRepoStatusArray: legalRepoStatusArray});
        let resultObject = {};
    
        legalRepoStatusArray.forEach(element => {
            if (resultObject.hasOwnProperty(element.name)) {
                resultObject[element.name] = resultObject[element.name] + element.value;
            } else {
                resultObject[element.name] = element.value;
            }
        });
    
        let resultArray = [];
    
        for (let prop in resultObject) {
            resultArray.push({ name: prop, value: resultObject[prop] });
        }
    
        let totalOv = totalOverdueArr.reduce((a, b) => a + b, 0);
        console.log({totalOv90: totalOv});
    
        resultArray.forEach(term => {
            const amount = term.value;
            if ("name" in term) {
                const legalRepoStatus = term.name;
                const legalPosition = legalRepoStatus.search(/legal/i);
                const repoPosition = legalRepoStatus.search(/repossessed/i);
    
                switch (true) {
                    case (legalPosition >= 0 || repoPosition >= 0):
                        legalOrRepo.push(amount);
                        break;
                    case (legalRepoStatus === "Insurance case"):
                        insurance.push(amount);
                        break;
                    case (legalRepoStatus === "Under Follow-up"):
                        underFollowUp.push(amount);
                        break;
                    case (legalRepoStatus === "Repossession in progress"):
                        repoInProgress.push(amount)
                        break;
                    case (legalRepoStatus === "Promise to pay"):
                        promiseToPay.push(amount);
                        break;
                    case (legalRepoStatus === "Watchlist"):
                        watchList.push(amount);
                        break;
                    case (legalRepoStatus === "Case Opening in Progress"):
                        caseOpeningInProgress.push(amount);
                        break;
                    case (legalRepoStatus === "Partial Repossession"):
                        partialReposession.push(amount);
                        break;
                    case (legalRepoStatus === "Request To Open Case"):
                        requestToOpenCase.push(amount);
                        break;
                    case (legalRepoStatus === "No Status"):
                        noStatus.push(amount);
                        break;
                    case (legalRepoStatus === "Non-Starter"):
                        nonStarter.push(amount);
                        break;
                }
            }
        })
    
        let legalRepo = legalOrRepo.reduce((a, b) => a + b, 0);
        legalRepo = roundAccurately(legalRepo, 2);
    
        let insuranceCase = insurance.reduce((a, b) => a + b, 0);
        insuranceCase = roundAccurately(insuranceCase, 2);
    
        let repoProgress = repoInProgress.reduce((a, b) => a + b, 0);
        repoProgress = roundAccurately(repoProgress, 2);
    
        let promisePay = promiseToPay.reduce((a, b) => a + b, 0);
        promisePay = roundAccurately(promisePay, 2);
    
        let watchlist = watchList.reduce((a, b) => a + b, 0);
        watchlist = roundAccurately(watchlist, 2);
    
        let requestCase = requestToOpenCase.reduce((a, b) => a + b, 0);
        requestCase = roundAccurately(requestCase, 2);
    
        let caseOpening = caseOpeningInProgress.reduce((a, b) => a + b, 0);
        caseOpening = roundAccurately(caseOpening, 2);
    
        let noStatusValue = noStatus.reduce((a, b) => a + b, 0);
        noStatusValue = roundAccurately(noStatusValue, 2);
    
        let partialReposessionValue = partialReposession.reduce((a, b) => a + b, 0);
        partialReposessionValue = roundAccurately(partialReposessionValue, 2);
    
        let underFollowUpValue = underFollowUp.reduce((a, b) => a + b, 0);
        underFollowUpValue = roundAccurately(underFollowUpValue, 2);
    
        let nonStarterValue = nonStarter.reduce((a, b) => a + b, 0);
        nonStarterValue = roundAccurately(nonStarterValue, 2);
    
        const newDate = timeStamp.format("DD-MM-YYYY");
    
    return {date, timeStamp, analyticsData, overdueTerms, exchangeRate, totalBook, dueCollections, cumulativeCollections, dailyCollections, collectionEfficiency, repoNumber, repoValue,
        legalCases, legalValue, overdue, deliquencyRate, bucket1, bucket2, bucket3, bucket4, overdueTarget, overdueActual, legalRepo, insuranceCase, watchlist, promisePay, requestCase,
        caseOpening, repoProgress, newDate, noStatusValue, nonStarterValue, underFollowUpValue, partialReposessionValue};
}







async function exportAllData({collectionTracker, collectionTracker1, collectionTracker2, collectionTracker3, overdueAging, value, groupedReportPhase1, groupedReportPhase2, reportDate, dispatch, dbSegmentArray, industryDataArray, provision, characterAnalysis, statusAnalysis, customerOD, dashboardData}){
    collectionTracker = collectionTracker.filter(function (el) { return el != null; });
    collectionTracker1 = collectionTracker1.filter(function (el) { return el != null; });
    collectionTracker2 = collectionTracker2.filter(function (el) { return el != null; });
    collectionTracker3 = collectionTracker3.filter(function (el) { return el != null; });
    industryDataArray = industryDataArray.filter(function (el) { return el != null; });
    dbSegmentArray = dbSegmentArray.filter(function (el) { return el != null; });
    value = value.filter(function (el) { return el != null; });
    groupedReportPhase1 = groupedReportPhase1.filter(function (el) { return el != null; });
    groupedReportPhase2 = groupedReportPhase2.filter(function (el) { return el != null; });
    provision = provision.filter(function (el) { return el != null; });
    overdueAging = overdueAging.filter(function (el) { return el != null; });
    dashboardData = dashboardData.filter(function (el) { return el != null; });
    statusAnalysis = statusAnalysis.filter(function (el) { return el != null; });
    characterAnalysis = characterAnalysis.filter(function (el) { return el != null; });
    customerOD = customerOD.filter(function (el) { return el != null; });

    const sa2b = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for(let i = 0; i !== s.length; ++i){
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    const workbook2blob = (wb) => {
        const wopts = {
            bookType: 'xlsx',
            type: 'binary'
        }

        const wbout = XLSX.write(wb, wopts);
        const blob = new Blob([sa2b(wbout)], {
            type: 'application/octet-stream'
        })

        return blob;
    }





    const date = moment(reportDate).format("DD/MM/YYYY");
    const today = moment(reportDate).format("DD/MM/YYYY");


    let empty = [{A: ''}];

//----------------dashboard begin--------//
    let title = [{A: ` DASHBOARD REPORT AS OF ${date}`}];
    let table = [
        {
            A: "MONTHS",
            B: "SUM OF DEBIT AMOUNT LCY",
            C: "SUM OF DEBIT AMOUNT USD",
            D: "SUM OF OD REPORTING USD",
            E: "NUMBER OF CUSTOMERS",
            F: "AGEING",
            G: "BUCKET",
            H: "TOTAL OD PER BUCKET"
        }
    ];

    if(dashboardData.length !== 0){
        dashboardData.forEach(cheque => {
            table.push({
                A: cheque.endOfMonth,
                B: cheque.totalLCY,
                C: cheque.totalDebitInUSD,
                D: cheque.totalInUsd,
                E: cheque.numberOfCustomers,
                F: cheque.daysRange,
                G: cheque.bucket,
                H: cheque.percent
            })
        })
    }

    table = (['']).concat(table);
    const finalData = [...empty, ...title, ...table];

//-------------dashboard ending--------//

//--------------------status analysis(status + character + OD analysis report)----begin-----------//
    //create status analysis
    let title1 = [{A: ` STATUS ANALYSIS REPORT AS OF ${date}`}];

    let table1 = [
        {
            A: "STATUS",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD IN USD",
            D: "PERCENT ON COUNT",
            E: "PERCENT ON VALUE"
        }
    ];

    if(statusAnalysis.length !== 0){
        statusAnalysis.forEach(data => {
            table1.push({
                A: data.status,
                B: data.numberOfCustomers,
                C: data.totalODInUsd,
                D: data.countPercent,
                E: data.valuePercent
            })
        })
    }

    table1 = (['']).concat(table1);

    //create character analysis

    let table2 = [
        {
            A: "CHARACTER",
            B: "NUMBER OF CUSTOMER",
            C: "SUM OF OD IN USD",
            D: "PERCENT ON COUNT",
            E: "PERCENT ON VALUE"
        }
    ];

    if(characterAnalysis.length !== 0){
        characterAnalysis.forEach(data => {
            table2.push({
                A: data.character,
                B: data.numberOfCustomers,
                C: data.totalODInUsd,
                D: data.countPercent,
                E: data.valuePercent
            })
        })
    }

    table2 = (['']).concat(table2);

    //create OD report
    let table3 = [
        {
            A: "NAME",
            B: "OD IN USD",
        }
    ];

    if(customerOD.length !== 0){
        customerOD.forEach(data => {
            table3.push({
                A: data.customerName,
                B: data.totalODInUsd,
            })
        })
    }

    table1 = (['']).concat([{A: "STATUS ANALYSIS"}]).concat(table1).concat(['']).concat(['']).concat(['']).concat([{A: "CHARACTER ANALYSIS"}]).concat(table2).concat(['']).concat(['']).concat(['']).concat([{A: "CUSTOMERS OVERDUE"}]).concat(table3);;
    const finalData1 = [...empty, ...title1, ...table1];

    //--------------------status analysis(status + character + OD analysis report)-----ending-----------//

    //--------------------provision report--------------------------------------------begin------------//
    let title4 = [{A: ` PROVISION REPORT AS OF ${date}`}];
    let table4 = [
        {
            A: "CUSTOMER NAME",
            B: "BUCKET GROUP",
            C: "PROVISION",
        }
    ];

    if(provision.length !== 0){
        provision.forEach(cheque => {
            table4.push({
                A: cheque.customerName,
                B: cheque.bucket,
                C: cheque.provision,
            })
        })
    }

    table4 = (['']).concat(table4);
    const finalData2 = [...empty, ...title4, ...table4];
    //--------------------provision report--------------------------------------------ending------------//

    //--------------------DB Segment report--------------------------------------------begin------------//
    let title5 = [{A: ` DB SEGMENT REPORT AS OF ${today}`}];

    let table5 = [
        {
             A: "EOM",
             B: "NUMBER OF CUSTOMERS BLANK",
             C: "BLANK OD IN USD",
             D: "NUMBER OF CUSTOMERS DAEWOO",
             E: "DAEWOO OD IN USD",
             F: "NUMBER OF CUSTOMERS TML",
             G: "TML OD IN USD",
             H: "DISTINCT COUNT OF OF CUSTOMERS",
             I: "TOTAL SUM OF OD IN USD",
        }
    ];
 
    let table6 = [
        {
            A: "ROW LABELS",
            B: "SUM OF OD REPORTING USD",
        }
    ];
 
    if(dbSegmentArray.length !== 0){
         dbSegmentArray.forEach(data => {
             table5.push({
                 A: data.eqm, 
                 B: data.numberOfCustomersBlank, 
                 C: data.blankODInUSD,
                 D: data.numberOfCustomersDaewoo,
                 E: data.daewooODInUSD, 
                 F: data.numberOfCustomersTML, 
                 G: data.tmlODInUSD, 
                 H: data.distinctCountOfCustomers, 
                 I: data.totalSumOfODInUSD, 
             })
         })
    }
 
    if(industryDataArray.length !== 0){
         industryDataArray.forEach(data => {
             table6.push({
                 A: data.industry,
                 B: data.totalODInUsd,
             })
         })
     }
    table5 = (['']).concat(table5).concat(['']).concat(['']).concat(['']).concat(table6)
    const finalData3 = [...empty, ...title5, ...table5];
    //--------------------DB Segment report--------------------------------------------ending------------//

    //--------------------Analysis report--------------------------------------------begin------------//
    let title7 = [{A: ` ANALYSIS REPORT ${today}`}];

    let table7 = [
        {
            A: "BUCKET",
            B: "Number of Customers",
        }
    ];

    let table8 = [
        {
            A: "BUCKET",
            B: "Sum of OD Reporting USD",
        }
    ];

    let table9 = [
        {
            A: "BUCKET GROUPED",
            B: "Number of Customers",
        }
    ];

    let table10 = [
        {
            A: "BUCKET GROUPED",
            B: "Sum of OD Reporting USD",
        }
    ];

    if(value.length !== 0){
        value.forEach(data => {
            table7.push({
                A: data.groupedBucket,
                B: data.numberOfCustomers,
            })
        })
    }

    if(value.length !== 0){
        value.forEach(data => {
            table8.push({
                A: data.groupedBucket,
                B: data.debtTotal,
            })
        })
    }

    if(groupedReportPhase1.length !== 0){
        groupedReportPhase1.forEach(data => {
            table9.push({
                A: data.groupedBucket,
                B: data.numberOfCustomers,
            })
        })
    }

    if(groupedReportPhase2.length !== 0){
        groupedReportPhase2.forEach(data => {
            table10.push({
                A: data.groupedBucket,
                B: data.totalOverdue,
            })
        })
    }


    table7 = (['']).concat(table7).concat(['']).concat(['']).concat(['']).concat(table8).concat(['']).concat(['']).concat(['']).concat(table9).concat(['']).concat(['']).concat(['']).concat(table10);
    const finalData4 = [...empty, ...title7, ...table7];

    //--------------------Analysis report--------------------------------------------ending------------//

    //--------------------Collection Tracker report--------------------------------------------begin------------//

    let title11 = [{A: ` COLLECTION TRACKER AS OF ${today}`}];

    let table11 = [
        {
            A: "COLLECTIONS FOR THE MONTH",
            B: "VALUE",
        }
    ];
 
    let table12 = [
        {
            A: "DESCRIPTION",
            B: "AMOUNT",
            C: "RATIO"
        }
    ];
 
    let table13 = [
         {
             A: "MONTH",
             B: "TOTAL OVERDUE",
             C: "PDC FTM",
             D: "PDC TD",
             E: "PDC TD - BOUNCED TD",
             F: "BOUNCED TD",
             G: "OD COLL TD",
             H: "TC",
             I: "TOTAL COLL REQ TARGET",
             J: "TOTAL OD REQ TARGET",
             K: "TALLY",
         }
     ];

     let table30 = [
        {
            A: "Date",
            B: "Total Book",
            C: "Scheduled Due Collections",
            D: "Cumulative Collections",
            E: "Daily Collections",
            F: "Collection Efficiency",
            G: "Repossessed Number",
            H: "Repossessed Value",
            I: "Legal Cases",
            J: "Legal Value",
            K: "Overdue",
            L: "Deliquency Rate",
            M: "0-90",
            N: "91-180",
            O: "181-360",
            P: ">360",
            Q: "Overdue Target",
            R: "Overdue Actual",
            S: ">90 Days Legal/Repossessed",
            T: ">90 Days Insurance Case",
            U: ">90 Days Repossession In Progress",
            V: ">90 Days Promise To Pay",
            W: ">90 Days Watchlist",
            X: ">90 Days Case Opening In Progress",
            Y: ">90 Days Request To Open Case",
            Z: ">90 Days Under Follow-Up",
            AA: ">90 Days Partial Repossession",
            AB: ">90 Non Starter",
            AC: ">90 Days Blank"
        }
    ];
 
    if(collectionTracker.length !== 0){
         collectionTracker.forEach(data => {
             table11.push({
                 A: data.label,
                 B: data.value,
             })
         })
    }
 
    if(collectionTracker1.length !== 0){
         collectionTracker1.forEach(data => {
             table12.push({
                 A: data.description,
                 B: data.amount,
                 C: data.ratio
 
             })
         })
     }
     
     if(collectionTracker2.length !== 0){
         collectionTracker2.forEach(data => {
             table13.push({
                 A: data.month,
                 B: data.totalOverdue,
                 C: data.pdcFtm,
                 D: data.pdcTd,
                 E: data.pdcBouncedTD,
                 F: data.bouncedTd,
                 G: data.odCollTd,
                 H: data.tc,
                 I: data.totalCollReqTarget,
                 J: data.totalODReqTarget,
                 K: data.tally,
             })
         })
     }

     if(collectionTracker3.length !== 0){
        collectionTracker3.forEach(data => {
            table30.push({
                A: data.newDate,
                B: data.totalBook,
                C: data.dueCollections,
                D: data.cumulativeCollections,
                E: data.dailyCollections,
                F: data.collectionEfficiency,
                G: "",
                H: "",
                // G: data.repoNumber,
                // H: data.repoValue,
                I: data.legalCases,
                J: data.legalValue,
                K: data.overdue,
                L: data.deliquencyRate,
                M: data.bucket1,
                N: data.bucket2,
                O: data.bucket3,
                P: data.bucket4,
                Q: data.overdueTarget,
                R: data.overdueActual,
                S: data.legalRepo,
                T: data.insuranceCase,
                U: data.repoProgress,
                V: data.promisePay,
                W: data.watchlist,
                X: data.caseOpening,
                Y: data.requestCase,
                Z: data.underFollowUpValue,
                AA: data.partialReposessionValue,
                AB: data.nonStarterValue,
                AC: data.noStatusValue,
            })
        })
    }

     table11 = (['']).concat(table11).concat(['']).concat(['']).concat(['']).concat(table12).concat(['']).concat(['']).concat(['']).concat(table13).concat(['']).concat(['']).concat(['']).concat(table30)
     const finalData6 = [...empty, ...title11, ...table11];
    //--------------------Collection Tracker report--------------------------------------------ending------------//


    //--------------------Overdue Aging report--------------------------------------------------begin------------//
    let title20 = [{A: ` OVERDUE AGING REPORT AS OF ${date}`}];
    let table20 = [
        {
            A: "TRANSACTION DATE",
            B: "NAME",
            C: "COLLECTOR",
            D: "SALES OFFICER",
            E: "CHEQUE NUMBER",
            F: "DEBIT AMOUNT IN LCY",
            G: "DEBIT AMOUNT IN USD",
            H: "BOUNCED REASON",
            I: "OTHERS SPECIFY",
            J: "OVERDUE IN LCY",
            K: "OVERDUE IN USD",
            L: "CHARACTER",
            M: "ACTION",
            N: "STATUS",
            O: "COMMENT",
            P: "DAYS",
            Q: "AGE",
            R: "PROFILE",
            S: "TRACK",
            T: "INDUSTRY",
            U: "MODEL",
            V: "SEGMENT",
            W: "OD REPORTING USD",
            X: "DUE DATE",
            Y: "FIRST DEMAND NOTICE",
            Z: "SECOND DEMAND NOTICE",
        }
    ];

    if(overdueAging.length !== 0){
        overdueAging.forEach(data => {
            table20.push({
                A: data.dueDate,
                B: data.customerName,
                C: data.assignee,
                D: data.salesExe,
                E: data.chequeNumber,
                F: data.debitAmountTZS,
                G: data.debitAmountUSD,
                H: data.bouncedReason,
                I: data.otherReason,
                J: data.overdueTZS,
                K: data.overdueUSD,
                L: data.character,
                M: data.clientAction,
                N: data.legalRepoStatus,
                O: data.comment,
                P: data.fromNow,
                Q: data.bucket,
                R: data.clientProfile,
                S: data.truck,
                T: data.industry,
                U: data.model,
                V: data.segment,
                W: data.totalOD,
                X: data.ogDueDate,
                Y: data.firstDemandNotice,
                Z: data.secondDemandNotice,
            })
        })
    }

    table20 = (['']).concat(table20);
    const finalData5 = [...empty, ...title20, ...table20];
    
    //-------------------------Overdue Aging report-------------------------------ending--------------------------//
    

    const wb = XLSX.utils.book_new();

    const w = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
    })

    w['!cols'] = [
        {wch:25},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
        {wch:30},
    ];


    const w1 = XLSX.utils.json_to_sheet(finalData1, {
        skipHeader: true,
    })

    w1['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:20},
        {wch:25},
        {wch:25},
    ];

    const w2 = XLSX.utils.json_to_sheet(finalData2, {
        skipHeader: true,
    })

    w2['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:20},
    ];


    const w3 = XLSX.utils.json_to_sheet(finalData3, {
        skipHeader: true,
    })

    w3['!cols'] = [
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
        {wch:25},
    ];


    const w4 = XLSX.utils.json_to_sheet(finalData4, {
        skipHeader: true,
    })

    w4['!cols'] = [
        {wch:15},
        {wch:25},
    ];

    const w5 = XLSX.utils.json_to_sheet(finalData5, {
        skipHeader: true,
    })


    w5['!cols'] = [
        {wch: 25},
        {wch: 35},
        {wch: 25},
        {wch: 20},
        {wch: 25},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 25},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 20},
        {wch: 30},
        {wch: 30},
    ];


    const w6 = XLSX.utils.json_to_sheet(finalData6, {
        skipHeader: true,
    })

    w6['!cols'] = [
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
        {wch: 25},
    ];



    XLSX.utils.book_append_sheet(wb, w, 'Dashboard');
    XLSX.utils.book_append_sheet(wb, w1, 'Status analysis');
    XLSX.utils.book_append_sheet(wb, w2, 'Provision');
    XLSX.utils.book_append_sheet(wb, w3, 'DB Segment');
    XLSX.utils.book_append_sheet(wb, w4, 'Analysis');
    XLSX.utils.book_append_sheet(wb, w5, 'OD Aging');
    XLSX.utils.book_append_sheet(wb, w6, 'Collection Tracker');

    const workbookBlob = workbook2blob(wb);


    const headerIndex = [];
    const footerIndex = [];
    finalData.forEach((data, index) => data['A'] === 'MONTHS' ? headerIndex.push(index) : null )
    finalData.forEach((data, index) => data['A'] === 'Grand Total' ? footerIndex.push(index) : null )

    const dataInfo = {
        titleCell: 'A2',
        titleRange: 'A1:H2',
        tbodyRange: `A2:H${finalData.length}`,
        theadRange: headerIndex.length >= 1 ? `A${headerIndex[0] + 1}:H${headerIndex[0] + 1}` : null,
        theadRange1: footerIndex.length >= 1 ? `A${footerIndex[0] + 1}:H${footerIndex[0] + 1}` : null,

    }


    const headerIndexA = [];
    const headerIndex1A = [];
    const headerIndex2A = [];
    const headerIndex3A = [];

    finalData1.forEach((data, index) => data['A'] === 'STATUS' ? headerIndexA.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'CHARACTER' ? headerIndex1A.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'NAME' ? headerIndex2A.push(index) : null )
    finalData1.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3A.push(index) : null )


    const dataInfo1 = {
        titleCell: 'A2',
        titleRange: 'A1:E2',
        tbodyRange: `A2:E${finalData1.length}`,
        theadRange: headerIndexA.length >= 1 ? `A${headerIndexA[0] + 1}:E${headerIndexA[0] + 1}` : null,
        theadRange1: headerIndex1A.length >= 1 ? `A${headerIndex1A[0] + 1}:E${headerIndex1A[0] + 1}` : null,
        theadRange2: headerIndex2A.length >= 1 ? `A${headerIndex2A[0] + 1}:B${headerIndex2A[0] + 1}` : null,
        theadRange3: headerIndex3A.length >= 1 ? `A${headerIndex3A[0] + 1}:E${headerIndex3A[0] + 1}` : null,
        theadRange4: headerIndex3A.length >= 2 ? `A${headerIndex3A[1] + 1}:D${headerIndex3A[1] + 1}` : null,
        theadRange5: headerIndex3A.length >= 3 ? `A${headerIndex3A[2] + 1}:B${headerIndex3A[2] + 1}` : null,
    }


    const headerIndexB = [];
    const headerIndex1B = [];

    finalData2.forEach((data, index) => data['A'] === 'CUSTOMER NAME' ? headerIndexB.push(index) : null )
    finalData2.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex1B.push(index) : null )


    const dataInfo2 = {
        titleCell: 'A2',
        titleRange: 'A1:C2',
        tbodyRange: `A2:C${finalData2.length}`,
        theadRange: headerIndexB.length >= 1 ? `A${headerIndexB[0] + 1}:C${headerIndexB[0] + 1}` : null,
        theadRange1: headerIndex1B.length >= 1 ? `A${headerIndex1B[0] + 1}:C${headerIndex1B[0] + 1}` : null,
    }


    const headerIndexC = [];
    const headerIndex2C = [];
    const headerIndex3C = [];

    finalData3.forEach((data, index) => data['A'] === 'EOM' ? headerIndexC.push(index) : null )
    finalData3.forEach((data, index) => data['A'] === 'ROW LABELS' ? headerIndex2C.push(index) : null )
    finalData3.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex3C.push(index) : null )

    const dataInfo3 = {
        titleCell: 'A2',
        titleRange: 'A1:I2',
        tbodyRange: `A2:I${finalData3.length}`,
        theadRange: headerIndexC.length >= 1 ? `A${headerIndexC[0] + 1}:I${headerIndexC[0] + 1}` : null,
        theadRange2: headerIndex2C.length >= 1 ? `A${headerIndex2C[0] + 1}:B${headerIndex2C[0] + 1}` : null,
        theadRange3: headerIndex3C.length >= 1 ? `A${headerIndex3C[0] + 1}:I${headerIndex3C[0] + 1}` : null,
        theadRange4: headerIndex3C.length >= 2 ? `A${headerIndex3C[1] + 1}:B${headerIndex3C[1] + 1}` : null,
    }


    const headerIndexD = [];
    const headerIndex1D = [];
    const headerIndex2D = [];


    finalData4.forEach((data, index) => data['A'] === 'BUCKET' ? headerIndexD.push(index) : null )
    finalData4.forEach((data, index) => data['A'] === 'BUCKET GROUPED' ? headerIndex1D.push(index) : null )
    finalData4.forEach((data, index) => data['A'] === 'Grand Total' ? headerIndex2D.push(index) : null )

    const dataInfo4 = {
        titleCell: 'A2',
        titleRange: 'A1:B2',
        tbodyRange: `A2:B${finalData4.length}`,
        theadRange: headerIndexD.length >= 1 ? `A${headerIndexD[0] + 1}:B${headerIndexD[0] + 1}` : null,
        theadRange1: headerIndexD.length >= 2 ? `A${headerIndexD[1] + 1}:B${headerIndexD[1] + 1}` : null,
        theadRange2: headerIndex1D.length >= 1 ? `A${headerIndex1D[0] + 1}:B${headerIndex1D[0] + 1}` : null,
        theadRange3: headerIndex1D.length >= 2 ? `A${headerIndex1D[1] + 1}:B${headerIndex1D[1] + 1}` : null,
        theadRange4: headerIndex2D.length >= 1 ? `A${headerIndex2D[0] + 1}:B${headerIndex2D[0] + 1}` : null,
        theadRange5: headerIndex2D.length >= 2 ? `A${headerIndex2D[1] + 1}:B${headerIndex2D[1] + 1}` : null,
        theadRange6: headerIndex2D.length >= 3 ? `A${headerIndex2D[2] + 1}:B${headerIndex2D[2] + 1}` : null,
        theadRange7: headerIndex2D.length >= 4 ? `A${headerIndex2D[3] + 1}:B${headerIndex2D[3] + 1}` : null,
    }


    const headerIndexE = [];
    finalData5.forEach((data, index) => data['A'] === 'TRANSACTION DATE' ? headerIndexE.push(index) : null )

    const dataInfo5 = {
        titleCell: 'A2',
        titleRange: 'A1:Z2',
        tbodyRange: `A2:Z${finalData5.length}`,
        theadRange: headerIndexE.length >= 1 ? `A${headerIndexE[0] + 1}:Z${headerIndexE[0] + 1}` : null,
    }


    const headerIndexF = [];
    const headerIndex1F = [];
    const headerIndex2F = [];
    const headerIndex3F = [];

    finalData6.forEach((data, index) => data['A'] === 'COLLECTIONS FOR THE MONTH' ? headerIndexF.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'DESCRIPTION' ? headerIndex1F.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'MONTH' ? headerIndex2F.push(index) : null )
    finalData6.forEach((data, index) => data['A'] === 'Date' ? headerIndex3F.push(index) : null )

    const dataInfo6 = {
        titleCell: 'A2',
        titleRange: 'A1:AC2',
        tbodyRange: `A2:AC${finalData6.length}`,
        theadRange: headerIndexF.length >= 1 ? `A${headerIndexF[0] + 1}:B${headerIndexF[0] + 1}` : null,
        theadRange1: headerIndex1F.length >= 1 ? `A${headerIndex1F[0] + 1}:C${headerIndex1F[0] + 1}` : null,
        theadRange2: headerIndex2F.length >= 1 ? `A${headerIndex2F[0] + 1}:K${headerIndex2F[0] + 1}` : null,
        theadRange3: headerIndex3F.length >= 1 ? `A${headerIndex3F[0] + 1}:AC${headerIndex3F[0] + 1}` : null,
    }


    stylePaymentMissedReport({ workbookBlob, dataInfo, dataInfo1, dataInfo2, dataInfo3, dataInfo4, dataInfo5, dataInfo6, dispatch, today})

}

const stylePaymentMissedReport = ({ workbookBlob, dataInfo, dataInfo1, dataInfo2, dataInfo3, dataInfo4, dataInfo5, dataInfo6, today, dispatch}) =>{
    return XlsxPopulate.fromDataAsync(workbookBlob).then(workbook => {
        workbook.sheets().forEach((sheet, index) => {
            if(index === 0){
                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                });
        
                sheet.range(dataInfo.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                });
        
                if (dataInfo.theadRange) {
                    sheet.range(dataInfo.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    });
                }

                if (dataInfo.theadRange1) {
                    sheet.range(dataInfo.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 1) {
                sheet.range(dataInfo1.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo1.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
                if (dataInfo1.theadRange) {
                    sheet.range(dataInfo1.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange1) {
                    sheet.range(dataInfo1.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange2) {
                    sheet.range(dataInfo1.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange3) {
                    sheet.range(dataInfo1.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
        
                if (dataInfo1.theadRange4) {
                    sheet.range(dataInfo1.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo1.theadRange5) {
                    sheet.range(dataInfo1.theadRange5).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 2) {
                sheet.range(dataInfo2.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo2.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                if (dataInfo2.theadRange) {
                    sheet.range(dataInfo2.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo2.theadRange1) {
                    sheet.range(dataInfo2.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 3) {
                sheet.range(dataInfo3.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo3.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo3.theadRange) {
                    sheet.range(dataInfo3.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange2) {
                    sheet.range(dataInfo3.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange3) {
                    sheet.range(dataInfo3.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo3.theadRange4) {
                    sheet.range(dataInfo3.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 4) {
                sheet.range(dataInfo4.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo4.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo4.theadRange) {
                    sheet.range(dataInfo4.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange1) {
                    sheet.range(dataInfo4.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }


                if (dataInfo4.theadRange2) {
                    sheet.range(dataInfo4.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange3) {
                    sheet.range(dataInfo4.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange4) {
                    sheet.range(dataInfo4.theadRange4).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange5) {
                    sheet.range(dataInfo4.theadRange5).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
        
                if (dataInfo4.theadRange6) {
                    sheet.range(dataInfo4.theadRange6).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo4.theadRange7) {
                    sheet.range(dataInfo4.theadRange7).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 5) {
                sheet.range(dataInfo5.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo5.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })

                if (dataInfo5.theadRange) {
                    sheet.range(dataInfo5.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            } else if (index === 6) { 
                sheet.range(dataInfo6.titleRange).merged(true).style({
                    bold: true,
                    verticalAlignment: 'center',
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                sheet.range(dataInfo6.tbodyRange).style({
                    horizontalAlignment: 'center',
                    fontFamily: 'Callibri',
                    fontSize: 8
                })
        
                if (dataInfo6.theadRange) {
                    sheet.range(dataInfo6.theadRange).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange1) {
                    sheet.range(dataInfo6.theadRange1).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange2) {
                    sheet.range(dataInfo6.theadRange2).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }

                if (dataInfo6.theadRange3) {
                    sheet.range(dataInfo6.theadRange3).style({
                        fill: '808080',
                        fontColor: 'FFFFFF',
                        bold: true,
                    })
                }
            }
        })

        workbook.outputAsync().then(workbookBlob => { 
            const url = URL.createObjectURL(workbookBlob);
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute('href', url);
            downloadAnchorNode.setAttribute('download', `AFCL PAYMENT MISSED REPORT ${contry} ${today}.xlsx`);
            // downloadAnchorNode.setAttribute('download', `paymentMissedReport${today}.xlsx`);
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        })
        dispatch({type: ALL_REPORT_SUCCESSFUL});
    })
}
