import React from 'react';
import { connect } from 'react-redux';
import { Modal, message } from 'antd';

//action imports
import { setInitialSettings } from "../actions/AppActions";

//components import
import CurrencyCard from "./settings/cards/CurrencyCard";
//import ActivateMigration from "./settings/cards/ActivateMigration";
import {Button} from "./common/Button";


class InitialSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible,
            exchangeRate: this.props.exchangeRate
        };

        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.exchangeRate !== this.props.exchangeRate) {
            this.setState({ exchangeRate: nextProps.exchangeRate })
        }

        if (nextProps.visible !== this.props.visible) {
            this.setState({ visible: nextProps.visible })
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    onSave() {
        //extract state
        const { exchangeRate } = this.state;

        if (exchangeRate) {

            this.props.setInitialSettings({ exchangeRate: parseInt(exchangeRate, 10) });
        } else {
            message.info('Kindly enter an exchange rate');
        }
    }

    render() {
        return (
            <div>
                <Modal
                    title="App Settings"
                    visible={this.state.visible}
                    footer={null}
                    closable={false}
                    width={900}
                >
                   <div>
                       <div>
                           <CurrencyCard
                               initial={true}
                           />
                       </div>

                       <div className={"initialSettingsModalButton"}>
                           <Button
                               text={'Save Settings'}
                               onPress={() => this.onSave() }
                           />
                       </div>
                   </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { exchangeRate,  } = state.settings;

    return {
        exchangeRate
    }

};

export default connect(mapStateToProps, { setInitialSettings })(InitialSettings);