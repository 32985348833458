import React from 'react';
import { connect } from 'react-redux';


//components imports
import AddDownPayments from './subComponents/AddDownPayments';
import ViewDownPayments from './subComponents/ViewDownPayments';

class DownPayments extends React.Component {
    
    render(){
        return (
            <div className={"serviceChargeContainer"}>
                <h3>Down Payments</h3>

                <div>
                    <AddDownPayments />
                </div>

                <div>
                    <ViewDownPayments />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})



export default connect(mapStateToProps)(DownPayments)
