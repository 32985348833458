import React from "react";
import { connect } from "react-redux";
import { message, } from 'antd';

//action imports
import { demandNoticeDateChanged, saveDemandNotice, hideNotices, } from "../../../actions/MasterListActions";

//component imports
import {Button} from "../../common/Button";
import {PickDate} from "../../common/PickDate";
import {BeatLoader} from "react-spinners";


class DemandNoticesCard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            loan: this.props.loan,
            loanTerm: this.props.loanTerm,
            loanID: this.props.loanID,

            demandNoticeDate: this.props.demandNoticeDate,
            demandNoticesLoading: this.props.demandNoticesLoading,
            demandNoticeSaveLoading: this.props.demandNoticeSaveLoading,
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onSaveDemandNotice = this.onSaveDemandNotice.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.loan !== this.props.loan) {
            this.setState({ loan: nextProps.loan })
        }

        if (nextProps.loanID !== this.props.loanID) {
            this.setState({ loanID: nextProps.loanID })
        }

        if (nextProps.loanTerm !== this.props.loanTerm) {
            this.setState({ loanTerm: nextProps.loanTerm })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.demandNoticeDate !== this.props.demandNoticeDate) {
            this.setState({ demandNoticeDate: nextProps.demandNoticeDate })
        }

        if (nextProps.demandNoticesLoading !== this.props.demandNoticesLoading) {
            this.setState({ demandNoticesLoading: nextProps.demandNoticesLoading })
        }

        if (nextProps.demandNoticeSaveLoading !== this.props.demandNoticeSaveLoading) {
            this.setState({ demandNoticeSaveLoading: nextProps.demandNoticeSaveLoading })
        }
    }


    onDateChange(date) {
        this.props.demandNoticeDateChanged(date);
    }

    renderSaveButton() {
        //extract state
        const { demandNoticeSaveLoading } = this.state;

        if (demandNoticeSaveLoading) {
            return <BeatLoader/>
        } else {
            return(
                <Button
                    text={"Save Notice"}
                    onPress={() => this.onSaveDemandNotice() }
                />
            );
        }
    }

    onSaveDemandNotice() {
        //extract state
        const { loan, loanTerm, demandNoticeDate, profile } = this.state;

        console.log(loanTerm);


        const access = profile.userAccess.some(access => access === "masterListUpdates");

        if (access) {

            //create demand notice id from timestamp
            // const now = moment();
            // const ID = moment(now).unix();
            // const demandNoticeID = ID.toString();

            const loanID = loanTerm.loanID;
            const loanTermID = loanTerm.loanTermID;
            const customerID = loanTerm.customerID;

            //check all variables are true
            if (demandNoticeDate) {
                //change moment obj into date object to save to firebase
                const noticeDate = demandNoticeDate.toDate();
                console.log(loanID, customerID, loanTermID);
                this.props.saveDemandNotice({ demandNoticeDate: noticeDate, customerID, loanTermID });
                message.success("Demand notice is saved");
            } else {
                message.warning("Pick a date");
            }

        } else {
            message.warning("You have no authorization to save a demand notice");
        }

    }


    render() {
        //extract state
        const { demandNoticeDate } = this.state;

        return (
            <div>
                <div className={"noticesDateBox"}>
                    <PickDate
                        label={"New Notice Date"}
                        onChange={(date) => this.onDateChange(date)}
                        value={demandNoticeDate}
                    />
                    { this.renderSaveButton() }
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const { demandNoticeDate, demandNoticesLoading, demandNoticeSaveLoading, loanID } = state.masterList;
    const { profile } = state.profile;

    return {
        profile,
        demandNoticeDate,
        demandNoticesLoading,
        demandNoticeSaveLoading,
        loanID,
    }

};

export default connect(mapStateToProps, { demandNoticeDateChanged, saveDemandNotice, hideNotices,})(DemandNoticesCard);
