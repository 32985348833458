import {combineReducers} from 'redux';

//reducers import
import AppReducer from "./AppReducer";
import AuthenticationReducer from "./AuthenticationReducer";
import CustomerReducer from "./CustomerReducer";
import LoanReducer from "./LoanReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import CollectionsReducer from "./CollectionsReducer";
import ReportsReducer from "./ReportsReducer";
import UnidentifiedReducer from "./UnidentifiedReducer";
import MasterListReducer from "./MasterListReducer";
import MasterListNewReducer from './MasterListNewReducer';
import MasterFileReducer from "./MasterFileReducer";
import ProfileReducer from "./ProfileReducer";
import HeldChequesReducer from "./HeldChequesReducer";
import SettingsReducer from "./SettingsReducer";
import ServiceChargeReducer from "./ServiceChargeReducer"
import ManualOverrideReducer from "./ManualOverrideReducer";
import PaymentReducer from './PaymentReducer';

export default combineReducers({
    app: AppReducer,
    authentication: AuthenticationReducer,
    customer: CustomerReducer,
    loans: LoanReducer,
    analytics: AnalyticsReducer,
    collections: CollectionsReducer,
    reports: ReportsReducer,
    unidentified: UnidentifiedReducer,
    masterList: MasterListReducer,
    masterListNew: MasterListNewReducer,
    masterFile: MasterFileReducer,
    profile: ProfileReducer,
    heldCheques: HeldChequesReducer,
    settings: SettingsReducer,
    serviceCharge: ServiceChargeReducer,
    manualOverride: ManualOverrideReducer,
    payments: PaymentReducer
});