import React from "react";
import { connect } from 'react-redux';

//actions import
import {customerLoanListChanged, fetchCollectionsLoanTerms} from "../../../actions/CollectionsActions";


//components imports
import ChooseLoan from "../../common/ChooseLoan";
import {Button} from "../../common/Button";
import {message} from "antd";
import {BeatLoader} from "react-spinners";
import _ from "lodash";


class CustomerLoans extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            fetchedCustomerLoans: this.props.fetchedCustomerLoans,
            customerDetails: this.props.customerDetails,
            selectedLoan: this.props.selectedLoan,
        };

        this.onFetchCustomerLoan = this.onFetchCustomerLoan.bind(this);
        this.onLoanListChange = this.onLoanListChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.fetchedCustomerLoans !== this.props.fetchedCustomerLoans) {
            this.setState ({fetchedCustomerLoans: nextProps.fetchedCustomerLoans})
        }

        if (nextProps.selectedLoan !== this.props.selectedLoan) {
            this.setState ({selectedLoan: nextProps.selectedLoan})
        }

        if (nextProps.customerDetails !== this.props.customerDetails) {
            this.setState ({customerDetails: nextProps.customerDetails})
        }

    }

    onLoanListChange(value) {
        this.props.customerLoanListChanged(value)
    }

    onFetchCustomerLoan() {
        //fetch customer loan terms
        const {selectedLoan} = this.state;

        if (!(_.isEmpty(selectedLoan))) {

            const loan = JSON.parse(selectedLoan);
            const customerID = loan.customerID;
            const loanID = loan.loanID;
            console.log(customerID);
            console.log(loanID);

            // this.props.pushCurrentLoan(loan)
            this.props.fetchCollectionsLoanTerms({customerID, loanID})
        } else {
            message.warning('Please select loan');
        }
    }


    render() {


        return (
            <div className={"collectionLoanListContainer"}>
                <h4>List Of Loans</h4>
                <div>
                    <ChooseLoan
                        label={'Customer Loans'}
                        placeholder={'select Customer Loan'}
                        onChange={this.onLoanListChange}
                        value={this.state.selectedLoan}
                    />
                </div>
                <div className={"collectionLoanListButtonBox"}>
                    {
                        this.props.fetchLoanTermsLoading?
                            <BeatLoader/>:
                            <Button
                                text={'Proceed'}
                                onPress={() => this.onFetchCustomerLoan()}
                            />
                    }
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { fetchedCustomerLoans, fetchLoanTermsLoading, customerDetails, selectedLoan } = state.collections;

    //return object with state properties
    return {
        fetchedCustomerLoans,
        customerDetails,
        fetchLoanTermsLoading,
        selectedLoan,
    }
}

export default connect(mapStateToProps,{fetchCollectionsLoanTerms, customerLoanListChanged})(CustomerLoans);