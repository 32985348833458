import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';

//actions import
import { fetchLoans} from "../../../actions/UnidentifiedActions";

//components import
import IdentifiedCustomerLoans from "./IdentifiedCustomerLoans";

class IdentifiedCustomer extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            customerDetails: this.props.customerDetails,
            profile: this.props.profile
        }
    }

    componentWillMount() {
        //fetch
        const {customerDetails} = this.state;

        if (!(_.isEmpty(customerDetails))) {
            //if customer profile has been fetched
            const customerID = customerDetails.customerID;

            //fetch loans
            this.props.fetchLoans({ customerID});
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.customerDetails !== this.props.customerDetails) {
            this.setState({customerDetails: nextProps.customerDetails})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

    }

    checkUserAccess() {
        //extract state
        const { profile } = this.state;

        const access = profile.userAccess.some(access => access === "unIdentified");

        if (access) {
            return(
                <div>
                    <IdentifiedCustomerLoans/>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {
        return (
            <div className={"customerProfileContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { customerDetails } = state.unidentified;
    const { profile } = state.profile;

    return {
        //
        profile,
        customerDetails,
    }
};

export default connect(mapStateToProps,{fetchLoans})(IdentifiedCustomer);