import React from 'react';
import { Select } from 'antd';


//action import


class SelectBounceOrDrawerReason extends React.Component {


    render() {

        const { Option } = Select;

        return (
            <div>
                <div>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    <Option value="insufficientFunds">Insufficient Funds</Option>
                    <Option value="drawer'sSignatureRequired">Drawer's Signature Required</Option>
                    <Option value="staleCheque">Stale Cheque</Option>
                    <Option value="accountClosed">Account Closed</Option>
                    <Option value="dormantAccount">Dormant Account</Option>
                    <Option value="spoiltCheque">Spoilt Cheque</Option>
                    <Option value="drawer'sConfirmationRequired">Drawer's Confirmation Required</Option>
                    <Option value="correctionInCheque">Correction In Cheque</Option>
                    <Option value="chequeNotStamped">Cheque Not Stamped</Option>
                    <Option value="stopPayment">Stop Payment</Option>
                    <Option value="amountInWordsAndFigureDiffer">Amount nn Words And Figure Differ</Option>
                    <Option value="chequeOnHold">Cheque On Hold</Option>
                    <Option value="referToDrawer">Refer To Drawer</Option>
                    <Option value="others">Others</Option>
                </Select>

            </div>
        );
    }
}

export default (SelectBounceOrDrawerReason);