import React from 'react';


export class Input extends React.Component {
    render() {
        return (
            <div>
                <div className={"inputLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <input
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    value={this.props.value}
                    onChange={this.props.onChange}
                    name={ this.props.name }
                    accept={this.props.accept}
                    maxLength={this.props.maxLength}
                    onKeyDown={this.props.onKeyDown}
                />
            </div>
        );
    }
}
