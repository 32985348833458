import * as firebase from "firebase";
import moment from "moment";
import _ from "lodash";

//env imports
import {
    CLEAR_MONTHLY_TARGET_MESSAGES, CLEAR_OD_TARGET_MESSAGES,
    DELETE_MONTHLY_TARGET,
    DELETE_MONTHLY_TARGET_FAILED,
    DELETE_MONTHLY_TARGET_SUCCESSFUL,
    DELETE_OD_TARGET, DELETE_OD_TARGET_FAILED, DELETE_OD_TARGET_SUCCESSFUL,
    FETCH_ANALYTICS,
    FETCH_ANALYTICS_FAILED,
    FETCH_ANALYTICS_SUCCESSFUL,
    FETCH_BOUNCED_TD,
    FETCH_BOUNCED_TD_FAILED,
    FETCH_BOUNCED_TD_SUCCESSFUL,
    FETCH_LEGAL_OVERDUE,
    FETCH_LEGAL_OVERDUE_FAILED,
    FETCH_LEGAL_OVERDUE_SUCCESSFUL,
    FETCH_MONTHLY_TARGET,
    FETCH_MONTHLY_TARGET_FAILED,
    FETCH_MONTHLY_TARGET_SUCCESSFUL,
    FETCH_NORMAL_OVERDUE,
    FETCH_NORMAL_OVERDUE_FAILED,
    FETCH_NORMAL_OVERDUE_SUCCESSFUL,
    FETCH_OD_COLL_TD,
    FETCH_OD_COLL_TD_FAILED,
    FETCH_OD_COLL_TD_SUCCESSFUL, FETCH_OD_TARGET, FETCH_OD_TARGET_FAILED, FETCH_OD_TARGET_SUCCESSFUL,
    FETCH_PDC_DEPOSITED,
    FETCH_PDC_DEPOSITED_FAILED,
    FETCH_PDC_DEPOSITED_SUCCESSFUL,
    FETCH_PDC_FTM,
    FETCH_PDC_FTM_FAILED,
    FETCH_PDC_FTM_SUCCESSFUL,
    FETCH_PDC_TD,
    FETCH_PDC_TD_FAILED,
    FETCH_PDC_TD_SUCCESSFUL,
    FETCH_REPO_OVERDUE,
    FETCH_REPO_OVERDUE_FAILED,
    FETCH_REPO_OVERDUE_SUCCESSFUL,
    FETCH_TOTAL_BOOK,
    FETCH_TOTAL_BOOK_FAILED,
    FETCH_TOTAL_BOOK_SUCCESSFUL,
    FETCH_TOTAL_NPL,
    FETCH_TOTAL_NPL_FAILED,
    FETCH_TOTAL_NPL_SUCCESSFUL,
    FETCH_TOTAL_OUTSTANDING,
    FETCH_TOTAL_OUTSTANDING_FAILED,
    FETCH_TOTAL_OUTSTANDING_SUCCESSFUL,
    FETCH_TOTAL_OVERDUE,
    FETCH_TOTAL_OVERDUE_FAILED,
    FETCH_TOTAL_OVERDUE_SUCCESSFUL,
    MONTHLY_TARGET_DETAILS_CHANGED,
    SAVE_MONTHLY_TARGET,
    SAVE_MONTHLY_TARGET_FAILED,
    SAVE_MONTHLY_TARGET_SUCCESSFUL, SAVE_OD_TARGET, SAVE_OD_TARGET_FAILED, SAVE_OD_TARGET_SUCCESSFUL
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}


export const monthlyTargetDetailsChanged = ({prop, value}) => {
    return {
        type: MONTHLY_TARGET_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const saveMonthlyTarget = ({ monthlyTargetID, mTarget, currency, timeStamp, month, year }) => {

    return(dispatch) => {
        dispatch({ type: SAVE_MONTHLY_TARGET });

        firebase.firestore().collection("monthlyTargets").doc(monthlyTargetID)
            .set({ monthlyTargetID, mTarget, currency, timeStamp, month, year })
            .then(() => {
                //comment post successful
                dispatch({ type: SAVE_MONTHLY_TARGET_SUCCESSFUL });
                console.log("monthly target saved successfully");
                dispatch({ type: CLEAR_MONTHLY_TARGET_MESSAGES });
                callMonthlyTarget({month, year, dispatch});
            })
            .catch(error => {
                dispatch({ type: SAVE_MONTHLY_TARGET_FAILED });
                console.log("failed to save monthlyTarget");
                console.log(error);
                dispatch({ type: CLEAR_MONTHLY_TARGET_MESSAGES });

            })
    }
};


export const fetchMonthlyTarget = ({month, year}) => {

    return(dispatch) => {
       callMonthlyTarget({month, year, dispatch});
    }
};

function callMonthlyTarget({month, year, dispatch}) {

    dispatch({ type: FETCH_MONTHLY_TARGET });

    let fetchedMonthlyTarget = {};

    firebase.firestore().collection("monthlyTargets").where("month", "==", month).where("year", "==", year)
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {

                fetchedMonthlyTarget[doc.id] = doc.data();

            });

            dispatch({
                type: FETCH_MONTHLY_TARGET_SUCCESSFUL,
                payload: fetchedMonthlyTarget
            });

        }, function (error) {
            console.log("Error fetching monthly target");
            console.log(error);
            dispatch({ type: FETCH_MONTHLY_TARGET_FAILED })
        });
}

export const deleteMonthlyTarget = (monthlyTargetID) => {

    // extract month and year for fetching monthly Target
    const month = moment().format("MM");
    const year = moment().format("YYYY");

    return(dispatch) => {

        dispatch({ type: DELETE_MONTHLY_TARGET });

        firebase.firestore().collection("monthlyTargets").doc(monthlyTargetID)
            .delete()
            .then(() => {
                dispatch({ type: DELETE_MONTHLY_TARGET_SUCCESSFUL });
                console.log("monthly target is deleted");
                dispatch({ type: CLEAR_MONTHLY_TARGET_MESSAGES });
                //fetch monthly Target
                callMonthlyTarget({month, year, dispatch});

            })
            .catch(error => {
                dispatch({ type: DELETE_MONTHLY_TARGET_FAILED });
                console.log(error);
                console.log("monthly target is not deleted ");
                dispatch({ type: CLEAR_MONTHLY_TARGET_MESSAGES });
            })

    };
};


export const saveTotalODTarget = ({ odTargetID, odTarget, currency, timeStamp, month, year }) => {

    return(dispatch) => {
        dispatch({ type: SAVE_OD_TARGET });

        firebase.firestore().collection("totalODTargets").doc(odTargetID)
            .set({ odTargetID, odTarget, currency, timeStamp, month, year })
            .then(() => {
                //comment post successful
                dispatch({ type: SAVE_OD_TARGET_SUCCESSFUL });
                console.log("total od target saved successfully");
                dispatch({ type: CLEAR_OD_TARGET_MESSAGES });
                callTotalODTarget({month, year, dispatch});
            })
            .catch(error => {
                dispatch({ type: SAVE_OD_TARGET_FAILED });
                console.log("failed to save total od target");
                console.log(error);
                dispatch({ type: CLEAR_OD_TARGET_MESSAGES });

            })
    }
};


export const fetchTotalODTarget = ({month, year}) => {

    return(dispatch) => {
        callTotalODTarget({month, year, dispatch});
    }
};

function callTotalODTarget({month, year, dispatch}) {

    dispatch({ type: FETCH_OD_TARGET });

    let fetchedTotalODTarget = {};

    firebase.firestore().collection("totalODTargets").where("month", "==", month).where("year", "==", year)
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {

                fetchedTotalODTarget[doc.id] = doc.data();

            });

            dispatch({
                type: FETCH_OD_TARGET_SUCCESSFUL,
                payload: fetchedTotalODTarget
            });

        }, function (error) {
            console.log("Error fetching od target");
            console.log(error);
            dispatch({ type: FETCH_OD_TARGET_FAILED });
        });
}

export const deleteTotalODTarget = (odTargetID) => {

    // extract month and year for fetching total OD Target
    const month = moment().format("MM");
    const year = moment().format("YYYY");

    return(dispatch) => {

        dispatch({ type: DELETE_OD_TARGET });

        firebase.firestore().collection("totalODTargets").doc(odTargetID)
            .delete()
            .then(() => {
                dispatch({ type: DELETE_OD_TARGET_SUCCESSFUL });
                console.log("total od target is deleted");
                dispatch({ type: CLEAR_OD_TARGET_MESSAGES });
                //fetch monthly Target
                callTotalODTarget({month, year, dispatch});

            })
            .catch(error => {
                dispatch({ type: DELETE_OD_TARGET_FAILED });
                console.log(error);
                console.log("od target is not deleted ");
                dispatch({ type: CLEAR_OD_TARGET_MESSAGES });
            })

    };
};

export const fetchAnalytics = () => {

    return async (dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_ANALYTICS });

        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();
        if(systemDoc.exists){
            const systemInfo = systemDoc.data();
            const exchangeRate = systemInfo.exchangeRate;
            const url = `${project.serverUrl}fetchAnalytics`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("Here's your analytics object");
                    const date = moment().subtract(1, "months").endOf('month').format("DD_MM_YYYY");
                    console.log(date);
                    // const reportDate = moment();
                    // const date = moment(reportDate).endOf('month').format("DD_MM_YYYY");
                    //get specific date overdue data
                    const reportID = `masterlist_${date}`;
            
                    const info = JSON.stringify({ reportID, path: "masterList" });
            
                    //invoke custom database function
                    const url1 = `${project.serverUrl}downloadGeneratedReport`;
                    fetch(url1, {
                        method: 'POST',
                        mode: 'cors',
                        body: info,
                        headers: {'Content-Type': 'application/json'},
                    }).then((response) => response.json())
                        .then((bouncedCheques) => {
                            //assign bouncedCheques to overdue terms
                            // console.log({bounced: bouncedCheques});
                            //find the total overdue as of end of last month
                            let termsStore = [];
            
                        _.map(bouncedCheques, client => {
                            //
                            client.values.map(term => {
                                //term has any of repossess status
                                if (term.termStatus.status) {
                                    //term is cleared
                                    //do nothing
                                } else {
                                    //if loan is in usd convert the overdue to default currency
                                    if (term.currency === "usd") {
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }
            
                                        const convertedAmount = amount * exchangeRate;
                                        termsStore.push(convertedAmount);
                                    } else {
            
                                        //grab the total overdue
                                        let amount;
                                        if ("modulo" in term) {
                                            amount = term.amount - term.modulo;
                                        } else {
                                            amount = term.amount;
                                        }
            
                                        termsStore.push(amount);
                                    }
                                }
                            });
                        })
    
                        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    
                        //CALCULATE OPENING OD
                        let openingOverdue = termsStore.reduce((a, b) => a + b, 0);
                            openingOverdue = roundAccurately(openingOverdue, 2);
                            response["openingOD"] = openingOverdue;
                            console.log(response);

                        dispatch({
                            type: FETCH_ANALYTICS_SUCCESSFUL,
                            payload: response
                        });
                    })
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_ANALYTICS_FAILED });
            })
        }
    }
};

export const fetchTotalBook = () => {

    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_TOTAL_BOOK });
        const url = `${project.serverUrl}fetchTotalBook`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
            console.log("Here's your total book");
            console.log(response);
            dispatch({
                type: FETCH_TOTAL_BOOK_SUCCESSFUL,
                payload: response
            });
        }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_TOTAL_BOOK_FAILED });
        })
    }
};

export const fetchOverdue = () => {

    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_TOTAL_OVERDUE });
        const url = `${project.serverUrl}fetchOverdue`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
            console.log("Here's your total overdue");
            console.log(response);
            dispatch({
                type: FETCH_TOTAL_OVERDUE_SUCCESSFUL,
                payload: response
            });
        }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_TOTAL_OVERDUE_FAILED });
        })
    }
};

export const fetchOutstanding = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_TOTAL_OUTSTANDING });
        //invoke custom database function
        const url = `${project.serverUrl}fetchOutstanding`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
            console.log("Here's your total outstanding");
            console.log(response);
            dispatch({
                type: FETCH_TOTAL_OUTSTANDING_SUCCESSFUL,
                payload: response
            });
        }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_TOTAL_OUTSTANDING_FAILED });
        })
    }
};

export const fetchNPL = () => {

    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_TOTAL_NPL });
        const url = `${project.serverUrl}fetchNPL`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
            console.log("Here's your NDL");
            console.log(response);
            dispatch({
                type: FETCH_TOTAL_NPL_SUCCESSFUL,
                payload: response
            });
        }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_TOTAL_NPL_FAILED });
        })
    }
};


export const fetchPdcFtm = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_PDC_FTM });
        const url = `${project.serverUrl}fetchPdcFtm`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your PDC FTM");
                console.log(response);
                dispatch({
                    type: FETCH_PDC_FTM_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_PDC_FTM_FAILED });
        })
    }
};

export const fetchPdcTd = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_PDC_TD });
        const url = `${project.serverUrl}fetchPdcTd`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your PDC TD");
                console.log(response);
                dispatch({
                    type: FETCH_PDC_TD_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_PDC_TD_FAILED });
        })
    }
};

export const fetchPdcDeposited = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_PDC_DEPOSITED });
        const url = `${project.serverUrl}fetchPdcDeposited`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your PDC DEPOSITED");
                console.log(response);
                dispatch({
                    type: FETCH_PDC_DEPOSITED_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_PDC_DEPOSITED_FAILED });
        })
    }
};

export const fetchBouncedTd = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_BOUNCED_TD });
        const url = `${project.serverUrl}fetchBouncedTd`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your BOUNCED TD");
                console.log(response);
                dispatch({
                    type: FETCH_BOUNCED_TD_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_BOUNCED_TD_FAILED });
        })
    }
};

export const fetchODCollTd = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_OD_COLL_TD });
        const url = `${project.serverUrl}fetchODCollTd`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your OD COLL TD");
                console.log(response);
                dispatch({
                    type: FETCH_OD_COLL_TD_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_OD_COLL_TD_FAILED });
        })
    }
};

export const fetchLegalOverdue = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_LEGAL_OVERDUE });
        const url = `${project.serverUrl}fetchLegalOverdue`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your legal overdue");
                console.log(response);
                dispatch({
                    type: FETCH_LEGAL_OVERDUE_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_LEGAL_OVERDUE_FAILED });
        })
    }
};

export const fetchRepoOverdue = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_REPO_OVERDUE });
        const url = `${project.serverUrl}fetchRepoOverdue`;
        fetch(url,{
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your repo overdue");
                console.log(response);
                dispatch({
                    type: FETCH_REPO_OVERDUE_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_REPO_OVERDUE_FAILED });
        })
    }
};

export const fetchNormalOverdue = () => {
    return(dispatch) => {
        //invoke custom database function
        dispatch({ type: FETCH_NORMAL_OVERDUE });
        const url = `${project.serverUrl}fetchNormalOverdue`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log("Here's your normal overdue");
                console.log(response);
                dispatch({
                    type: FETCH_NORMAL_OVERDUE_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_NORMAL_OVERDUE_FAILED });
        })
    }
};