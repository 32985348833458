import React from "react";
import { Modal, Button } from 'antd';

//components import
import SearchCustomer from "./SearchCustomer";



class IdentifyCustomer extends React.Component {


    state = {
        visible: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });

    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        //extract props
        const { payment } = this.props;

        return (
            <div className={"identifyCustomerButton"}>
                <Button type="default" onClick={this.showModal}>
                    Choose Customer
                </Button>
                <Modal
                    title="select customer"
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={700}
                >
                    <div>
                       <SearchCustomer
                           payment={payment}
                       />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default IdentifyCustomer;