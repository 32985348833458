import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import moment from "moment";

//components import
import RollOver1Card from "./cards/RollOver1Card";

import { createRollupCustomerOverdueReportLastMonth } from "../../actions/MasterListActions";
import {BeatLoader} from "react-spinners";


class RollOverPaidCases extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            // bouncedCheques: this.props.bouncedCheques,
            rolloverOverdueLastMonth: this.props.rolloverOverdueLastMonth,
            rolloverOverdueLastMonthLoading: this.props.rolloverOverdueLastMonthLoading,
            // localCurrency: "TSH",
        };

    }

    componentWillMount(){
        this.props.createRollupCustomerOverdueReportLastMonth();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.rolloverOverdueLastMonth !== this.props.rolloverOverdueLastMonth) {
            this.setState({ rolloverOverdueLastMonth: nextProps.rolloverOverdueLastMonth })
        }
        if(nextProps.rolloverOverdueLastMonthLoading !== this.props.rolloverOverdueLastMonthLoading) {
            this.setState({ rolloverOverdueLastMonthLoading: nextProps.rolloverOverdueLastMonthLoading })
        }
    }

    renderRollOverData(){
        const {rolloverOverdueLastMonth, rolloverOverdueLastMonthLoading} = this.state;

        if(rolloverOverdueLastMonthLoading){
            return <BeatLoader/>
        }else{
            if(rolloverOverdueLastMonth.length !== 0){
                return (
                    <div>
                        {
                            rolloverOverdueLastMonth.map(overdue => {
                                return <RollOver1Card overdue={overdue}/>
                            })
                        }
                    </div>
                );

            }else{
                return <h3 className={"userAdminsEmptyWarning"}>There is no data to display</h3>;
            }
        }
    }

    render() {
        const date = new Date();
        const firstD = new Date(date.getFullYear(), date.getMonth() - 1, 2);
        const year = moment(firstD).format('YYYY')
        const month = moment(firstD).format('MMMM')
        const rollover = `Overdue for ${month} ${year}`;

        return (
            <div className={"dashboardContainer"}>
                <h2>{rollover}</h2>

                <div>
                    <div className={"rolloverHeaders"}>
                    <div><h4>TRANSACTION DATE</h4></div>
                        <div><h4>CUSTOMER NAME</h4></div>
                        <div><h4>OVERDUE IN USD</h4></div>
                    </div>
                    
                        <div>{this.renderRollOverData()}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { rolloverOverdueLastMonth, rolloverOverdueLastMonthLoading } = state.masterList;

    return {
        rolloverOverdueLastMonth,
        rolloverOverdueLastMonthLoading
    }
};

export default connect(mapStateToProps, {createRollupCustomerOverdueReportLastMonth})(RollOverPaidCases);