import React from 'react'
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//actions import
import { fetchLoanTerms, fetchLoan, fetchLoanLogs } from "../../../actions/LoanActions";
import { fetchCashCollections } from "../../../actions/CollectionsActions";

export class IncorrectLoanCard extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loan: this.props.loan,
        };

        this.onFetchLoanTerms = this.onFetchLoanTerms.bind(this);
    }

    onFetchLoanTerms() {
        //extract state
        const { loan } = this.state;
        //extract props
        const { history } = this.props;

        //fetch customer loan terms
        const customerID = loan.customerID;
        const loanID = loan.loanID;

        this.props.fetchLoan({customerID, loanID});
        this.props.fetchLoanTerms({customerID, loanID });
        this.props.fetchLoanLogs({customerID, loanID });
        this.props.fetchCashCollections({customerID, loanID});
        history.push('/loan-page');
    }

    renderTerm() {
        const { firstTerm} = this.state.loan;
        if ("firstTerm" in this.state.loan) {
            if (!(_.isEmpty(firstTerm))) {
                let status = "null";
                if (firstTerm.cheque) {
                    status = "cheque";
                } else {
                    status = "cash";
                }

                return (
                    <div className={"incorrectLoanType"}>
                        <p>{status}</p>
                    </div>
                );
            }
        }
    }

    renderModuloTerm() {
        const { unMatchingModulo } = this.state.loan;
        if ("modulo" in this.state.loan) {
            if (unMatchingModulo) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#D0ADBD" }}>modulo</p>
                    </div>
                );
            }
        }
    }

    renderSurplusTerm() {
        const { unMatchingSurplus } = this.state.loan;
        if ("surplus" in this.state.loan) {
            if (unMatchingSurplus) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#D0ADBD" }}>surplus</p>
                    </div>
                );
            }
        }
    }

    renderPenalInterestLoan() {
        const { penalInterest } = this.state.loan;
        if ("penalInterest" in this.state.loan) {
            if (penalInterest) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#3B8066" }}>penal interest</p>
                    </div>
                );
            }
        }
    }

    renderUnclearedTerm() {
        const { unclearedTerm } = this.state.loan;
        if ("unclearedTerm" in this.state.loan) {
            if (unclearedTerm) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#9C9C9C" }}>uncleared term</p>
                    </div>
                );
            }
        }
    }

    renderPendingTerm() {
        const { unclearedTerm } = this.state.loan;
        if ("pendingCheque" in this.state.loan) {
            if (unclearedTerm) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#9C9C9C" }}>Pending Term</p>
                    </div>
                );
            }
        }
    }

    renderCashCollection() {
        const { CashCollections } = this.state.loan;
        if ("CashCollections" in this.state.loan) {
            if (CashCollections) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#9C9C9C" }}>cash collections</p>
                    </div>
                );
            }
        }
    }

    renderLoanStatus() {
        const { loanStatus } = this.state.loan;
        if ("loanStatus" in this.state.loan) {
            if (loanStatus) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#9C9C9C" }}>loan status</p>
                    </div>
                );
            }
        }
    }

    renderPreMatureClear() {
        const { preMatureClear } = this.state.loan;
        if ("preMatureClear" in this.state.loan) {
            if (preMatureClear) {
                return (
                    <div className={"incorrectLoanType"}>
                        <p style={{ color: "#9C9C9C" }}>prem-mature clear</p>
                    </div>
                );
            }
        }
    }

    render() {
        const { customerID, customerName, loanID } = this.state.loan;

        return (
            <div className={"incorrectCardContainer"} onClick={() => this.onFetchLoanTerms()}>
                <div className={'incorrectLoanHeader'}>
                    <div><h5>ID</h5></div>
                    <div><h5>Customer Name</h5></div>
                    <div><h5>Loan ID</h5></div>
                </div>
                <div className={'incorrectLoanValues'}>
                    <div>{customerID ? <p>{customerID}</p> : null}</div>
                    <div>{customerName ? <p>{customerName}</p> : null}</div>
                    <div>{loanID ? <p>{loanID}</p> : null}</div>
                </div>
                <div>
                    { this.renderTerm() }
                    { this.renderModuloTerm() }
                    { this.renderSurplusTerm() }
                    { this.renderPenalInterestLoan() }
                    { this.renderUnclearedTerm() }
                    { this.renderCashCollection() }
                    { this.renderLoanStatus() }
                    { this.renderPendingTerm() }
                    { this.renderPreMatureClear() }
                </div>
            </div>
        )
    }
}

export default withRouter(connect(null, { fetchLoanTerms, fetchLoanLogs, fetchLoan, fetchCashCollections })(IncorrectLoanCard));
