import {
    CHEQUE_CASH_SWITCH_CHANGED,
    CHEQUE_STATUS_CHANGED, CLEAR_MASTER_LIST_REPORT,
    CLEAR_REPORTS_MESSAGE,
    CREATE_MASTER_LIST_REPORT,
    CREATE_MASTER_LIST_REPORT_EMPTY, CREATE_MASTER_LIST_REPORT_FAILED,
    CREATE_MASTER_LIST_REPORT_SUCCESSFUL,
    CREATE_REPORT,
    CREATE_REPORT_DATE_CHANGED,
    CREATE_REPORT_EMPTY,
    CREATE_REPORT_FAILED,
    CREATE_REPORT_SUCCESSFUL,
    CREATE_UNIQUE_REPORTS,
    DATE_RANGE_CHANGED,
    FETCH_REPORT_TOTAL_VALUE,
    FETCH_REPORT_TOTAL_VALUE_FAILED,
    FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL,
    REPORT_CATEGORY_CHANGED,
    RESET_TOTAL_VALUE,
    ALL_REPORT,
    ALL_REPORT_SUCCESSFUL,
    ALL_REPORT_FAILED,
} from "../actions/Types";


const INITIAL_STATE = {

    chequeCashSwitch: true,

    chequeStatus: '',
    dateRange: '',
    reportCategory: '',
    reportDate: '',

    totalValue: '',

    fetchTotalValue: false,
    createReportLoading: false,
    reportGenerated: false,
    reportGenerateFailed: false,
    emptyReport: false,

    //
    masterListReportCreated: false,
    masterListReportEmpty: false,
    masterListReportFailed: false,
    masterListReportLoading: false,

    allReportLoading: false,

};

const ReportsReducer = ( state = INITIAL_STATE, action) => {
    switch(action.type) {

        case CHEQUE_CASH_SWITCH_CHANGED:
            return { ...state, chequeCashSwitch: action.payload, reportGenerated: false, };
        case CHEQUE_STATUS_CHANGED:
            return {...state, chequeStatus: action.payload, reportGenerated: false,};
        case DATE_RANGE_CHANGED:
            return {...state, dateRange: action.payload, reportGenerated: false,};

            //fetch reducers
        case FETCH_REPORT_TOTAL_VALUE:
            return { ...state, fetchTotalValue: true, totalValue: "", reportGenerated: false, };
        case FETCH_REPORT_TOTAL_VALUE_SUCCESSFUL:
            return { ...state, totalValue: action.payload, chequeStatus: "", dateRange: "", fetchTotalValue: false , reportGenerated: false,};
        case FETCH_REPORT_TOTAL_VALUE_FAILED:
            return { ...state, fetchTotalValue: false };

            //report generation
        case REPORT_CATEGORY_CHANGED:
            return {...state, reportCategory: action.payload, reportGenerated: false, };
        case CREATE_REPORT_DATE_CHANGED:
            return {...state, [action.payload.prop]: action.payload.value };
        case CREATE_REPORT:
            console.log("it is starting......");
            return {...state, createReportLoading: true };
        case CREATE_REPORT_SUCCESSFUL:
            console.log("it is ended......");
            return {...state, reportCategory: "", reportDate: "", createReportLoading: false, reportGenerated: true };
        case CREATE_REPORT_FAILED:
            return {...state, createReportLoading: false, reportGenerateFailed: true, reportCategory: "", reportDate: "",  };
        case CREATE_REPORT_EMPTY:
            return {...state, reportCategory: "", reportDate: "", createReportLoading: false, emptyReport: true };

        case ALL_REPORT:
            console.log("it is starting......");
            return {...state, allReportLoading: true };
        case ALL_REPORT_SUCCESSFUL:
            console.log("it is ended......");
            return {...state, allReportLoading: false, reportCategory: "", reportDate: ""};
        case ALL_REPORT_FAILED:
            console.log("it is ended......");
            return {...state, allReportLoading: false};

            //master list report
        case CREATE_MASTER_LIST_REPORT:
            return {...state, masterListReportLoading: true };
        case CREATE_MASTER_LIST_REPORT_SUCCESSFUL:
            return {...state, masterListReportCreated: true, masterListReportLoading: false };
        case CREATE_MASTER_LIST_REPORT_EMPTY:
            return {...state, masterListReportEmpty: true, masterListReportLoading: false };
        case CREATE_MASTER_LIST_REPORT_FAILED:
            return {...state, masterListReportFailed: true, masterListReportLoading: false };
        case CLEAR_MASTER_LIST_REPORT:
            return {...state, masterListReportCreated: false, masterListReportEmpty: false, masterListReportFailed: false, masterListReportLoading: false };

            //reset reducers
        case RESET_TOTAL_VALUE:
            return { ...state, totalValue: "", reportGenerated: false, };

        case CLEAR_REPORTS_MESSAGE:
            return {...state, reportCategory: "", reportDate: "",  reportGenerated: false, reportGenerateFailed: false, createReportLoading: false, emptyReport: false };
        case CREATE_UNIQUE_REPORTS:
            return {...state, createReportLoading: false, reportGenerated: true};

        default:
            return state
    }
};

export default ReportsReducer;