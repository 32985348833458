import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { message } from "antd";
import moment from 'moment';


//actions imports
import { switchChanged, chequeStatusChanged, dateRangeChanged, showChequesTotalValue, showCashTotalValue, createReport, reportCategoryChanged, resetTotalValue,
    createUnIdentifiedPaymentsReport, createServiceChargesReport, createHeldChequesReport, createEarlyClosureReport, createMatureLoansReport,
    createRescheduledLoansReport, createAgeingCustomerWiseReport, createAgeingGroupBucketReport, createLastPaymentDateReport, reportDateChanged, createAnalyticsReport,
    createLoansWithSurplus, removeSurplus, computeSurplus, createPdcFtmReport, createPdcTdReport, createLoansWithSurplusAndModulo, createDashboardReport,
    createCustomerOverdueReport, createCharacterAndStatusAnalysisReport, analysisGroupedBucketsReport, provisionReport, createRollupCustomerOverdueReport,
    collectionTrackerReport, findOpeningOD, createDBSegmentReport, createRollupCustomerOverdueReportLastMonth, createDownPaymentsReport
} from "../../actions/ReportsActions";

import { example} from "../../actions/BackupActions";

import { collectionTrackerReportNew, createDBSegmentReportNew, provisionReportNew, analysisGroupedBucketsReportNew, createRollupCustomerOverdueReportLastMonthNew,
    createRollupCustomerOverdueReportNew,
    createCustomerOverdueReportNew,
    createAgeingGroupBucketReportNew,
    createDashboardReportNew,
    createAgeingCustomerWiseReportNew,
    createCharacterAndStatusAnalysisReportNew, fetchMonthlyEmis} from "../../actions/ReportNewActions";
import { generateCollectionTrackerNew, generateDbSegmentReportNew, generateProvisionReportNew, generatesAnalysisReportNew, DailyRolledOverReportNew, DailyNewCaseReportNew,
    generateStatusAnalysisReportNew, DailyCustomerOverdueReportNew, generateDashboardReportNew, generateCreateAgeingGroupBucketReportNew, generateCreateAgeingCustomerWiseReportNew } from "../../actions/GeneratedReportsNewActions"

import {allReports, checkCashMonthlyPayments} from "../../actions/AllAppReportsActions";
import { allReportsNew } from "../../actions/AllAppReportsNewActions";
import { generatePaymentMissedReportNew } from "../../actions/GeneratePaymentMissedNewReport";
import {updateMigrationCleared} from "../../actions/CollectionsActions";
import { checkPhoneNumber } from "../../actions/CustomerActions";
import {generatePaymentMissedReport} from "../../actions/GeneratePaymentMissedReport";
import { generateCreateReport, generateCreateMasterListReport, generateCreateEarlyClosureReport, generateCreateHeldChequesReport, generateCreateLastPaymentDateReport,
 generateCreateRescheduledLoansReport, generateCreateServiceChargesReport, generateCreateUnIdentifiedPaymentsReport, generateCreateAgeingCustomerWiseReport, generateCreateAgeingGroupBucketReport, generateCreateMatureLoansReport, generateCreateAnalyticsReport, generateDashboardReport, DailyCustomerOverdueReport, DailyNewCaseReport, DailyRolledOverReport,
    generateProvisionReport, generateCreateDownPaymentsReport, generateDbSegmentReport, generatesAnalysisReport, generateStatusAnalysisReport, generateCollectionTracker, fetchCash
} from "../../actions/GeneratedReportsActions";
import { fetchBouncedCheques } from "../../actions/MasterListActions";

//components imports
import SelectReportCategory from "../common/SelectReportCategory";
import {Button} from "../common/Button";
import {BeatLoader} from "react-spinners";
import {PickDate} from "../common/PickDate";


class Reports extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            chequeStatus: this.props.chequeStatus,
            dateRange: this.props.dateRange,
            totalBook: this.props.totalBook,
            totalValue: this.props.totalValue,
            createReportLoading: this.props.createReportLoading,

            reportCategory: this.props.reportCategory,
            reportDate: this.props.reportDate,
            profile: this.props.profile,

            chequeCashSwitch: this.props.chequeCashSwitch,

            reportGenerated: this.props.reportGenerated,
            reportGenerateFailed: this.props.reportGenerateFailed,
            emptyReport: this.props.emptyReport,

            bouncedCheques: this.props.bouncedCheques,
            filteredLegalRepoStatus: this.props.filteredLegalRepoStatus,
            filterBucket: this.props.filterBucket,
            systemInfo: this.props.systemInfo,
            overdueTerms: this.props.overdueTerms,
            allReportLoading: this.props.allReportLoading,
        };

        // this.onChequeStatus = this.onChequeStatus.bind(this);
        // this.onDateRangeChange = this.onDateRangeChange.bind(this);
        // this.showPercentage = this.showPercentage.bind(this);
        // this.onShowValue = this.onShowValue.bind(this);
        // this.displayCurrency = this.displayCurrency.bind(this);
        this.onCreateReport = this.onCreateReport.bind(this);
        this.onReportCategory = this.onReportCategory.bind(this);
        this.onReportDateChanged = this.onReportDateChanged.bind(this);
        // this.totalValueMessage = this.totalValueMessage.bind(this);
    }

    componentWillMount() {
        this.props.fetchBouncedCheques();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.chequeStatus !== this.props.chequeStatus) {
            this.setState ({chequeStatus: nextProps.chequeStatus})
        }

        if (nextProps.dateRange !== this.props.dateRange) {
            this.setState ({dateRange: nextProps.dateRange})
        }

        if (nextProps.totalBook !== this.props.totalBook) {
            this.setState ({totalBook: nextProps.totalBook})
        }

        if (nextProps.totalValue !== this.props.totalValue) {
            this.setState ({totalValue: nextProps.totalValue})
        }

        if (nextProps.reportCategory !== this.props.reportCategory) {
            this.setState ({reportCategory: nextProps.reportCategory})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.chequeCashSwitch !== this.props.chequeCashSwitch) {
            this.setState({ chequeCashSwitch: nextProps.chequeCashSwitch });
        }

        if (nextProps.reportGenerated !== this.props.reportGenerated) {
            this.setState({ reportGenerated: nextProps.reportGenerated });
        }

        if (nextProps.reportGenerateFailed !== this.props.reportGenerateFailed) {
            this.setState({ reportGenerateFailed: nextProps.reportGenerateFailed });
        }

        if(nextProps.bouncedCheques !== this.props.bouncedCheques) {
            this.setState({ bouncedCheques: nextProps.bouncedCheques })
        }

        if (nextProps.filteredLegalRepoStatus !== this.props.filteredLegalRepoStatus) {
            this.setState({ filteredLegalRepoStatus: nextProps.filteredLegalRepoStatus })
        }

        if (nextProps.filterBucket !== this.props.filterBucket) {
            this.setState({ filterBucket: nextProps.filterBucket })
        }

        if(nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo })
        }

        if(nextProps.reportDate !== this.props.reportDate) {
            this.setState({ reportDate: nextProps.reportDate })
        }

        if(nextProps.emptyReport !== this.props.emptyReport) {
            this.setState({ emptyReport: nextProps.emptyReport })
        }
        if(nextProps.overdueTerms !== this.props.overdueTerms) {
            this.setState({ overdueTerms: nextProps.overdueTerms })
        }

        if (nextProps.allReportLoading !== this.props.allReportLoading) {
            this.setState ({allReportLoading: nextProps.allReportLoading})
        }


        //display user message when value is 0
        // this.totalValueMessage(nextProps);

    }

    onReportDateChanged(value) {
        //
        this.props.reportDateChanged({prop: 'reportDate', value});
    }


    // totalValueMessage(nextProps) {
    //     //show message if total value is 0
    //     if (nextProps.totalValue === 0) {
    //         //check if cheque or cash is selected
    //         if (this.state.chequeCashSwitch) {
    //             //cheque is selected
    //             //determine what cheque it is
    //             let cheque;
    //             switch (this.state.chequeStatus) {
    //                 case "pending":
    //                     cheque = "pending";
    //                     break;
    //                 case "notDeposited":
    //                     cheque = "not deposited";
    //                     break;
    //                 case "cleared":
    //                     cheque = "cleared";
    //                     break;
    //                 case "bounced":
    //                     cheque = "bounced";
    //                     break;
    //                 case "held":
    //                     cheque = "held";
    //                     break;
    //                 default:
    //                     cheque = "";
    //                     break;
    //             }
    //             message.info(`There are no ${cheque} cheques in selected dates`)
    //         } else {
    //             //cash is selected
    //             //
    //             message.info("There are no cash collections in selected dates")
    //         }
    //
    //         //reset total value
    //         this.props.resetTotalValue();
    //     }
    // }

    // onChequeStatus(value) {
    //     console.log(value);
    //     this.props.chequeStatusChanged(value)
    // }

    onReportCategory(value) {
        this.props.reportCategoryChanged(value)
    }

    // onDateRangeChange(date){
    //     console.log(date);
    //     this.props.dateRangeChanged(date)
    // }

    // showPercentage(rawValue) {
    //     //extract state
    //     const {totalBook} = this.state;
    //
    //     //change totalBook to integer
    //     const total = parseInt(totalBook);
    //     const value = parseInt(rawValue);
    //
    //     //determine total book is loaded
    //     let percent = 0;
    //
    //     const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
    //
    //     if (total !== 0) {
    //         const number = value * 100 / total;
    //         percent = roundAccurately(number, 2)
    //     }
    //
    //     return <h3>{percent} %</h3>
    //
    // }

    // onShowValue() {
    //     //extract state
    //     const { chequeCashSwitch, chequeStatus, dateRange } = this.state;
    //     //check if user has selected cheque or cash
    //     if (chequeCashSwitch) {
    //         //cheque selected
    //         //check if chequeStatus and date range is selected
    //         if (chequeStatus && dateRange) {
    //             this.props.showChequesTotalValue({chequeStatus, dateRange});
    //         } else {
    //             message.warning("Select the cheque status and date range");
    //         }
    //     } else {
    //         //cash selected
    //         //check if date is selected
    //         if (dateRange) {
    //             this.props.showCashTotalValue(dateRange);
    //         } else {
    //             message.warning("Pick the date range");
    //         }
    //     }
    // }
    //
    // displayCurrency(value) {
    //     //extract state
    //     const { profile } = this.state;
    //
    //     let displayValue;
    //     //check if currency is activated in profile
    //     let currency;
    //     if ("currency" in profile) {
    //         //change currency to usd
    //         currency = "USD";
    //
    //         //grab the exchange rate from profile
    //         const { exchangeRate } = profile;
    //         displayValue = value / exchangeRate;
    //     } else {
    //         currency = "TSH";
    //
    //         displayValue = value;
    //     }
    //
    //     const formatter = new Intl.NumberFormat('en-US', {
    //         style: 'currency',
    //         currency,
    //     });
    //
    //     return <h3>{formatter.format(displayValue)}</h3>
    // }

    onCreateReport() {
        //extract state
        const {reportCategory, overdueTerms, allReportLoading, bouncedCheques, systemInfo, reportDate, profile, filteredLegalRepoStatus, filterBucket} = this.state;

        switch (reportCategory) {
            case 'cashCollections':
                if (reportDate) {
                    this.props.generateCreateReport(reportDate);
                } else {
                    this.props.createReport(reportCategory);
                }
                break;
            case 'customerOverdueReport':
                if (reportDate) {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.DailyCustomerOverdueReport({reportDate});
                    } else {
                        message.info("There is no system info");
                    }
                } else {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.createCustomerOverdueReport({reportDate: '', systemInfo, profile});
                    } else {
                        message.info("There is no system info");
                    }
                }
                break;
            case 'customerOverdueReportNew':
                if (reportDate) {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.DailyCustomerOverdueReportNew({reportDate});
                    } else {
                        message.info("There is no system info");
                    }
                } else {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.createCustomerOverdueReportNew({reportDate: '', systemInfo, profile});
                    } else {
                        message.info("There is no system info");
                    }
                }
                break;
            case 'rollupAnalysis':
                if (reportDate) {
                    this.props.DailyNewCaseReport({reportDate});
                } else {
                    this.props.createRollupCustomerOverdueReport();
                }
                break;
            case 'rollupAnalysisNew':
                if (reportDate) {
                    this.props.DailyNewCaseReportNew({reportDate});
                } else {
                    this.props.createRollupCustomerOverdueReportNew();
                }
                break;
            case 'rollupAnalysisFromLastMonth':
                if (reportDate) {
                    this.props.DailyRolledOverReport({reportDate});
                } else {
                    this.props.createRollupCustomerOverdueReportLastMonth();
                }
                break;
            case 'rollupAnalysisFromLastMonthNew':
                if (reportDate) {
                    this.props.DailyRolledOverReportNew({reportDate});
                } else {
                    this.props.createRollupCustomerOverdueReportLastMonthNew();
                }
                break;
            case 'dashboardReport':
                if (reportDate) {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.generateDashboardReport({reportDate, systemInfo});
                    } else {
                        message.info("Please wait for data to populate the try again");
                    }
                } else {
                    this.props.createDashboardReport(reportCategory);
                }
                break;
            case 'dashboardReportNew':
                if (reportDate) {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.generateDashboardReportNew({reportDate, systemInfo});
                    } else {
                        message.info("Please wait for data to populate the try again");
                    }
                } else {
                    this.props.createDashboardReportNew(reportCategory);
                }
                break;
            case 'unIdentifiedCollections':
                if (reportDate) {
                    this.props.generateCreateUnIdentifiedPaymentsReport(reportDate);
                } else {
                    this.props.createUnIdentifiedPaymentsReport();
                }
                break;
            case 'serviceCharges':
                if (reportDate) {

                    this.props.generateCreateServiceChargesReport(reportDate);
                } else {
                    this.props.createServiceChargesReport();
                }
                break;
            case 'heldCheques':
                if (reportDate) {
                    this.props.generateCreateHeldChequesReport(reportDate);
                } else {
                    this.props.createHeldChequesReport();
                }
                break;
            case 'earlyClosure':
                if (reportDate) {
                    this.props.generateCreateEarlyClosureReport(reportDate);
                } else {
                    this.props.createEarlyClosureReport(systemInfo);
                }
                break;
            case 'maturedLoans':
                if (reportDate) {
                    this.props.generateCreateMatureLoansReport(reportDate);
                } else {
                    this.props.createMatureLoansReport();
                }
                break;
            case 'rescheduledLoans':
                if (reportDate) {
                    this.props.generateCreateRescheduledLoansReport(reportDate);
                } else {
                    this.props.createRescheduledLoansReport();
                }
                break;
            case 'lastPaymentDate':
                if (reportDate) {
                    this.props.generateCreateLastPaymentDateReport(reportDate);
                } else {
                    this.props.createLastPaymentDateReport();
                }
                break;
            case 'analytics':
                if (reportDate) {
                    this.props.generateCreateAnalyticsReport({reportDate, systemInfo});
                } else {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.createAnalyticsReport({systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                }
                break;
            case 'ageingCustomerWise':
                if (reportDate) {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.generateCreateAgeingCustomerWiseReport({reportDate, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createAgeingCustomerWiseReport({bouncedCheques, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                }
                break;
            case 'ageingCustomerWiseNew':
                if (reportDate) {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.generateCreateAgeingCustomerWiseReportNew({reportDate, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createAgeingCustomerWiseReportNew({bouncedCheques, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                }
                break;
            case 'ageingGroupBucket':
                if (reportDate) {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.generateCreateAgeingGroupBucketReport({reportDate, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createAgeingGroupBucketReport({bouncedCheques, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                }
                break;
            case 'ageingGroupBucketNew':
                if (reportDate) {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.generateCreateAgeingGroupBucketReportNew({reportDate, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createAgeingGroupBucketReportNew({bouncedCheques, systemInfo});
                    } else {
                        message.info("Wait for data to populate")
                    }
                }
                break;
            case 'characterAndStatusAnalysis':
                if (reportDate) {
                    // message.info("There is no report based on date");
                    this.props.generateStatusAnalysisReport(reportDate);
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createCharacterAndStatusAnalysisReport({systemInfo, reportDate: "", profile});
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'characterAndStatusAnalysisNew':
                if (reportDate) {
                    // message.info("There is no report based on date");
                    this.props.generateStatusAnalysisReportNew(reportDate);
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createCharacterAndStatusAnalysisReportNew({systemInfo, reportDate: "", profile});
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'provision':
                if (reportDate) {
                    this.props.generateProvisionReport(reportDate);
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.provisionReport();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'provisionNew':
                if (reportDate) {
                    this.props.generateProvisionReportNew(reportDate);
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.provisionReportNew();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'bucketGrouped':
                if (reportDate) {
                    this.props.generatesAnalysisReport(reportDate);
                    // message.info("There is no report based on date");
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.analysisGroupedBucketsReport();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'bucketGroupedNew':
                if (reportDate) {
                    this.props.generatesAnalysisReportNew(reportDate);
                    // message.info("There is no report based on date");
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.analysisGroupedBucketsReportNew();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'industry':
                if (reportDate) {
                    this.props.generateDbSegmentReport(reportDate);
                    // message.info("There is no report based on date");
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createDBSegmentReport();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'industryNew':
                if (reportDate) {
                    this.props.generateDbSegmentReportNew(reportDate);
                    // message.info("There is no report based on date");
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.createDBSegmentReportNew();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'allReports':
                if (reportDate) {
                    this.props.generatePaymentMissedReport({reportDate, systemInfo, filterBucket, filteredLegalRepoStatus});
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.allReports({bouncedCheques, filteredLegalRepoStatus, filterBucket, profile})
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'allReportsNew':
                if (reportDate) {
                    this.props.generatePaymentMissedReportNew({reportDate, systemInfo, filterBucket, filteredLegalRepoStatus});
                } else {
                    if (!(_.isEmpty(bouncedCheques)) && !(_.isEmpty(systemInfo))) {
                        this.props.allReportsNew({bouncedCheques, filteredLegalRepoStatus, filterBucket, profile})
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'collectionTracker':
                if(reportDate){
                    const selectedDate = moment(reportDate);
                    const today = moment();
                    if(selectedDate.isBefore(today, "month")) {
                        console.log("data for the previous month");
                        this.props.generateCollectionTracker(reportDate);
                    }

                    if(selectedDate.isSame(today, "month")) {
                        if(selectedDate.isSameOrBefore(today, "day")) {
                            if (!(_.isEmpty(systemInfo))) {
                                this.props.collectionTrackerReport();
                            } else {
                                message.info("There is no data to generate report");
                            }
                        }else{
                            message.info("There is no report based on this date");
                        }
                    }

                    if(selectedDate.isAfter(today, "month")) {
                        message.info("There is no report based on this date");
                    }
                }else{
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.collectionTrackerReport();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'collectionTrackerNew':
                if(reportDate){
                    const selectedDate = moment(reportDate);
                    const today = moment();
                    if(selectedDate.isBefore(today, "month")) {
                        console.log("data for the previous month");
                        this.props.generateCollectionTrackerNew(reportDate);
                    }

                    if(selectedDate.isSame(today, "month")) {
                        if(selectedDate.isSameOrBefore(today, "day")) {
                            if (!(_.isEmpty(systemInfo))) {
                                this.props.collectionTrackerReportNew();
                            } else {
                                message.info("There is no data to generate report");
                            }
                        }else{
                            message.info("There is no report based on this date");
                        }
                    }

                    if(selectedDate.isAfter(today, "month")) {
                        message.info("There is no report based on this date");
                    }
                }else{
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.collectionTrackerReportNew();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'downPayments':
                if (reportDate) {
                    // this.props.generateCreateDownPaymentsReport(reportDate);
                    this.props.findOpeningOD({systemInfo, reportDate});

                } else {
                    if (!(_.isEmpty(systemInfo))) {
                        this.props.createDownPaymentsReport();
                    } else {
                        message.info("There is no data to generate report");
                    }
                }
                break;
            case 'monthlyTerms':
                if (reportDate) {
                    this.props.fetchMonthlyEmis({reportDate, systemInfo})
                } else {
                    message.info("Please select a date to generate EMI's");
                }
                break;
            case 'example':
                this.props.example(systemInfo);
                break;
            default:
                message.warning("Please choose report type");
                break;
        }
    }

    // onSwitchChange() {
    //     const status = !this.state.chequeCashSwitch;
    //     this.props.switchChanged(status)
    // }

    // renderValueButton () {
    //
    //     if (this.props.fetchTotalValue) {
    //         return (
    //             <div>
    //                 <BeatLoader/>
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className={"generateChequeValueButton"}>
    //                 <Button
    //                     text={'Show Value'}
    //                     onPress={() => this.onShowValue()}
    //                 />
    //             </div>
    //         );
    //     }
    // }

    renderButton () {

        if (this.state.allReportLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button
                        text={'Generate Report'}
                        onPress={() => this.onCreateReport() }
                    />
                </div>
            );
        }
    }

    renderMessage() {
        const {reportGenerated} = this.state;

        if (reportGenerated) {
            return <p>{message.success("Report is successfully generated")}</p>
        } else {
            return null
        }
    }

    renderFailedMessage() {
        const {reportGenerateFailed} = this.state;

        if (reportGenerateFailed) {
            return <p>{message.error("Report is not generated")}</p>
        } else {
            return null
        }
    }

    renderEmptyMessage() {
        const {emptyReport} = this.state;

        if (emptyReport) {
            return <p>{message.info("Sorry! No report to generate")}</p>
        } else {
            return null
        }
    }

    checkUserAccess() {
        //extract state
        const { profile, chequeCashSwitch, chequeStatus, dateRange, totalValue, reportCategory, reportGenerated } = this.state;

        const access = profile.userAccess.some(access => access === "reports");

        if (access) {
            return(
                <div>
                    <h4>Reports</h4>
                    {/*<div className={"categoryDateRangeBox"}>*/}
                    {/*    <div className={"reportBox"}>*/}
                    {/*        <div style={{ marginBottom: '1rem' }}>*/}
                    {/*            <Switch*/}
                    {/*                checkedChildren="Cheque"*/}
                    {/*                unCheckedChildren="Cash"*/}
                    {/*                onChange={() => this.onSwitchChange() }*/}
                    {/*                checked={this.state.chequeCashSwitch}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*        {*/}
                    {/*            chequeCashSwitch ?*/}
                    {/*                <SelectChequeCategory*/}
                    {/*                    label={'Cheque status'}*/}
                    {/*                    placeholder={'select cheque status'}*/}
                    {/*                    onChange={this.onChequeStatus}*/}
                    {/*                    value={chequeStatus}*/}
                    {/*                /> : <h3>Cash Collections</h3>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*    <div className={"dateRangeBox"}>*/}
                    {/*        <DateRange*/}
                    {/*            label={'Date Range'}*/}
                    {/*            onChange={this.onDateRangeChange}*/}
                    {/*            value={dateRange}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className={"generateChequeValueButton"}>*/}
                    {/*    {this.renderValueButton()}*/}
                    {/*</div>*/}

                    {/*<div className={"reportPercentBox"}>*/}
                    {/*    <div className={"reportTotalValueBox"}>*/}
                    {/*        <div className={"totalValueReportBox"}>*/}
                    {/*            <p>Total Value</p>*/}
                    {/*            { totalValue ? this.displayCurrency(totalValue) : <h3>-</h3> }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className={"percentageTotalBookBox"}>*/}
                    {/*        <p>Percentage Against Total Book</p>*/}
                    {/*        { totalValue ? this.showPercentage(totalValue) : <h3>%</h3>}*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className={"generateReportBox"}>
                        <div className={"selectReportBox"}>
                            <div style={{marginBottom: '0.5rem'}}>
                                <SelectReportCategory
                                    label={'Choose Report To Generate'}
                                    placeholder={'select report'}
                                    onChange={this.onReportCategory}
                                    value={reportCategory}
                                />
                            </div>
                            <div>
                                <PickDate
                                    label={"Choose Report Date"}
                                    onChange={this.onReportDateChanged}
                                    value={this.state.reportDate}
                                />
                            </div>
                        </div>
                        <div className={"generateReportButton"}>
                            {this.renderButton()}
                        </div>
                        {/*<div>*/}
                        {/*    <Button*/}
                        {/*        text={'LOANS WITH SURPLUS'}*/}
                        {/*        onPress={() => this.props.createPdcFtmReport()}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>

                    <div>
                        {this.renderMessage()}
                        {this.renderFailedMessage()}
                        {this.renderEmptyMessage()}
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }


    render() {

        return (
            <div className={"reportsContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { chequeStatus, dateRange, totalValue, reportCategory, reportDate, fetchTotalValue, createReportLoading, reportGenerated, emptyReport, reportGenerateFailed, chequeCashSwitch, allReportLoading } = state.reports;
    const { totalBook } = state.analytics;
    const { bouncedCheques, filteredLegalRepoStatus, filterBucket, overdueTerms } = state.masterList;
    const { profile } = state.profile;
    const { systemInfo } = state.app;

    //return object with state properties
    return {
        profile,
        allReportLoading,
        chequeStatus,
        dateRange,
        totalBook,
        totalValue,

        bouncedCheques,
        filteredLegalRepoStatus,
        filterBucket,
        overdueTerms,

        emptyReport,
        reportCategory,
        reportDate,
        fetchTotalValue,

        createReportLoading,
        reportGenerated,
        reportGenerateFailed,

        chequeCashSwitch,

        systemInfo
    }
};

export default connect(mapStateToProps,{ checkPhoneNumber, allReportsNew, generatePaymentMissedReportNew, findOpeningOD, allReports, switchChanged, chequeStatusChanged, dateRangeChanged, showChequesTotalValue, showCashTotalValue, createReport,
    reportCategoryChanged, resetTotalValue, createUnIdentifiedPaymentsReport, createServiceChargesReport, createHeldChequesReport, createEarlyClosureReport,
    createMatureLoansReport, createRescheduledLoansReport, createAgeingCustomerWiseReport, createAgeingGroupBucketReport, createLastPaymentDateReport,
    fetchBouncedCheques, reportDateChanged, generateCreateReport, generateCreateMasterListReport, generateCreateEarlyClosureReport, generateCreateHeldChequesReport, generateCreateLastPaymentDateReport,
    generateCreateRescheduledLoansReport, generateCreateServiceChargesReport, generateCreateUnIdentifiedPaymentsReport, generateCreateAgeingCustomerWiseReport,
    generateCreateAgeingGroupBucketReport, generateCreateMatureLoansReport, createAnalyticsReport, generateCreateAnalyticsReport, createLoansWithSurplus,
    removeSurplus, updateMigrationCleared, computeSurplus, createPdcFtmReport, createPdcTdReport, createLoansWithSurplusAndModulo, createDashboardReport, generateDashboardReport,
     provisionReport, createCustomerOverdueReport, createCharacterAndStatusAnalysisReport, createRollupCustomerOverdueReport, analysisGroupedBucketsReport, createDownPaymentsReport,
     collectionTrackerReport, collectionTrackerReportNew, generateCollectionTrackerNew, createDBSegmentReportNew, provisionReportNew, analysisGroupedBucketsReportNew,
     generatesAnalysisReportNew,createRollupCustomerOverdueReportLastMonthNew, DailyRolledOverReportNew,
     generateProvisionReportNew, DailyNewCaseReportNew, createRollupCustomerOverdueReportNew, generateStatusAnalysisReportNew,
     createCharacterAndStatusAnalysisReportNew, DailyCustomerOverdueReportNew, generateCreateAgeingGroupBucketReportNew,
     createAgeingGroupBucketReportNew, generateCreateAgeingCustomerWiseReportNew,
     createAgeingCustomerWiseReportNew,
     createCustomerOverdueReportNew, generateDashboardReportNew,
     createDashboardReportNew,
     generateDbSegmentReportNew, createDBSegmentReport, createRollupCustomerOverdueReportLastMonth, DailyCustomerOverdueReport, DailyNewCaseReport, DailyRolledOverReport, generateProvisionReport,
    generateCreateDownPaymentsReport, generateDbSegmentReport, generatesAnalysisReport, generateStatusAnalysisReport, generateCollectionTracker,generatePaymentMissedReport,
    checkCashMonthlyPayments, fetchCash, example, fetchMonthlyEmis
})(Reports);
