import React from "react";
import { connect } from "react-redux";
import 'antd/dist/antd.css';

//components import
import ExistingCustomer from "./subComponents/ExistingCustomer";
import NewCustomer from "./subComponents/NewCustomer";

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
    }

    checkUserAccess() {
        //extract state
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "clientsView");

        if (access) {
            return (
                <div>
                    <h2>Manage Customer</h2>
                    <div className={"homeClientsBox"}>
                        {/*<div>*/}
                        {/*    <NewCustomer/>*/}
                        {/*</div>*/}
                        <div>
                            <ExistingCustomer/>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {
        return (
            <div className={"homeContainer"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

const mapStateToProps = state => {

    const { profile } = state.profile;

    return {
        profile
    };

};

export default connect(mapStateToProps)(Home);