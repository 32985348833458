import {
    CLEAR_ADD_NEW_CUSTOMER_MESSAGE,
    CLEAR_EXISTING_CUSTOMER, CREATE_NEW_CUSTOMER, CREATE_NEW_CUSTOMER_FAILED, CREATE_NEW_CUSTOMER_SUCCESSFUL,
    CUSTOMER_DETAILS,
    CUSTOMER_DETAILS_CHANGED,
    CUSTOMER_DETAILS_SAVED_SUCCESSFUL,
    FETCH_CUSTOMER_PROFILE_SUCCESSFUL,
    SEARCH_EXISTING_CUSTOMER,
    SEARCH_EXISTING_CUSTOMER_FAILED,
    SEARCH_EXISTING_CUSTOMER_NO_RECORD,
    SEARCH_EXISTING_CUSTOMER_SUCCESSFUL,
    ADD_PHONE,
    ADD_PHONE_FAILED,
    ADD_PHONE_SUCCESSFUL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESSFUL,
    DELETE_CUSTOMER_FAILED,
    ADD_EXTERNAL_COLLECTOR,
    ADD_EXTERNAL_COLLECTOR_SUCCESSFUL,
    ADD_EXTERNAL_COLLECTOR_FAILED

} from "../actions/Types";


const INITIAL_STATE = {
    customerName: '',
    customerID: '',
    phoneNumber: '',
    addPhoneLoading: false,
    customerEmail: '',
    customerTin: '',
    customerAddress: '',

    customerSearch: '',
    fetchedCustomer: {},
    noRecord: false,

    customerProfile: {},
    fetchedCustomerLoans: {},
    collectorName: '',

    addCollectorLoading: false,
    loading: false,
    fetching: false,
    visible: false,
    loanSaved: false,
    saveCustomerLoading: false,
    customerSaved: false,
    saveCustomerFailed: false,
    deleteCustomerLoading: false

};


const CustomerReducer = ( state = INITIAL_STATE, action) => {
    switch(action.type) {
        case CUSTOMER_DETAILS_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value};
        case CUSTOMER_DETAILS:
            return  {...state, loading: true};
        case CUSTOMER_DETAILS_SAVED_SUCCESSFUL:
            return {...state, customerName: "", customerID: "", loading: false, };

        case ADD_EXTERNAL_COLLECTOR:
            return {...state, addCollectorLoading: true };
        case ADD_EXTERNAL_COLLECTOR_SUCCESSFUL:
            return {...state, collectorName: "", addCollectorLoading: false, };
        case ADD_EXTERNAL_COLLECTOR_FAILED:
            return {...state, addCollectorLoading: false, };


        case DELETE_CUSTOMER:
            return {...state, deleteCustomerLoading: true}
        case DELETE_CUSTOMER_SUCCESSFUL:
            return {...state, deleteCustomerLoading: false}
        case DELETE_CUSTOMER_FAILED:
            return {...state, deleteCustomerLoading: false}
        
        case ADD_PHONE:
            return { ...state, addPhoneLoading: true}
        case ADD_PHONE_FAILED:
            return { ...state, addPhoneLoading: false}
        case ADD_PHONE_SUCCESSFUL:
            return { ...state, addPhoneLoading: false, phoneNumber: '', customerEmail: '', customerAddress: '', customerTin: ''}

        case CREATE_NEW_CUSTOMER:
            return  {...state, saveCustomerLoading: true};
        case CREATE_NEW_CUSTOMER_SUCCESSFUL:
            return {...state, customerName: "", customerID: "", saveCustomerLoading: false, customerSaved: true};
        case CREATE_NEW_CUSTOMER_FAILED:
            return {...state, saveCustomerLoading: false, saveCustomerFailed: true };
        case CLEAR_ADD_NEW_CUSTOMER_MESSAGE:
            return {...state,  customerName: "", customerID: "", saveCustomerLoading: false, customerSaved: false, saveCustomerFailed: false };


            //search existing customer reducers
        case SEARCH_EXISTING_CUSTOMER:
            return { ...state, fetching: true, noRecord: false, fetchedCustomer: {} };
        case SEARCH_EXISTING_CUSTOMER_SUCCESSFUL:
            return { ...state, fetchedCustomer: action.payload, fetching: false, };
        case SEARCH_EXISTING_CUSTOMER_NO_RECORD:
            return { ...state, noRecord: true, fetching: false };
        case SEARCH_EXISTING_CUSTOMER_FAILED:
            return { ...state, fetching: false };


            //push to customer profile reducers
        case FETCH_CUSTOMER_PROFILE_SUCCESSFUL:
            return { ...state, customerSearch: "", customerProfile: action.payload };

        case CLEAR_EXISTING_CUSTOMER:
            return {...state, customerSearch: "", };


        default:
            return state
    }
};

export default CustomerReducer;