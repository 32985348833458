import * as firebase from 'firebase';
import moment from "moment";
import { Switch, message } from "antd";


import {
    COLLECTOR_CHANGED,
    MASTER_LIST_DATE_RANGE_CHANGED,
    FETCH_BOUNCED_CHEQUES,
    FETCH_BOUNCED_CHEQUES_FAILED,
    FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
    SAVE_MASTER_LIST_COMMENT_SUCCESSFUL,
    SAVE_MASTER_LIST_COMMENT,
    SAVE_MASTER_LIST_COMMENT_FAILED,
    DELETE_MASTER_LIST_COMMENT,
    DELETE_MASTER_LIST_COMMENT_SUCCESSFUL,
    DELETE_MASTER_LIST_COMMENT_FAILED,
    MASTER_LIST_COMMENT_CHANGED,
    FETCH_MASTER_LIST_COMMENTS_FAILED,
    FETCH_MASTER_LIST_COMMENTS_SUCCESSFUL,
    FETCH_MASTER_LIST_COMMENTS,
    MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED,
    DEMAND_NOTICE_DATE_CHANGED,
    SAVE_DEMAND_NOTICE,
    SAVE_DEMAND_NOTICE_SUCCESSFULL,
    SAVE_DEMAND_NOTICE_FAILED,
    FETCH_DEMAND_NOTICES,
    FETCH_DEMAND_NOTICES_SUCCESSFUL,
    FETCH_DEMAND_NOTICES_FAILED,
    SHOW_COMMENT_CARD,
    HIDE_COMMENT_CARD,
    SHOW_NOTICES_CARD,
    HIDE_NOTICES_CARD,
    UPDATE_DEMAND_NOTICE_DATE_CHANGED,
    UPDATE_DEMAND_NOTICE,
    UPDATE_DEMAND_NOTICE_SUCCESSFUL,
    UPDATE_DEMAND_NOTICE_FAILED,
    SHOW_UPDATE_NOTICES_CARD,
    HIDE_UPDATE_NOTICES_CARD,
    SHOW_ASSIGN_COLLECTOR_CARD,
    HIDE_ASSIGN_COLLECTOR_CARD,
    FETCH_CURRENT_NOTICE,
    DELETE_DEMAND_NOTICES,
    DELETE_DEMAND_NOTICES_SUCCESSFUL,
    FETCH_RANGED_BOUNCED_CHEQUES,
    FETCH_RANGED_BOUNCED_CHEQUES_SUCCESSFUL,
    FETCH_RANGED_BOUNCED_CHEQUES_FAILED,
    DELETE_DEMAND_NOTICES_FAILED,
    NUMBER_OF_LOANS_TO_ASSIGN_CHANGED,
    FILTER_LEGAL_REPO_STATUS_CHANGED,
    CLEAR_LEGAL_REPO_FILTER,
    FILTER_BUCKET_CHANGED,
    FETCH_RANGED_MASTER_LIST_COMMENTS,
    FETCH_RANGED_MASTER_LIST_COMMENTS_SUCCESSFUL,
    FETCH_RANGED_MASTER_LIST_COMMENTS_FAILED,
    ASSIGN_COLLECTOR,
    ASSIGN_COLLECTOR_FAILED,
    ASSIGN_COLLECTOR_SUCCESSFUL,
    SHOW_UNASSIGNED_LOANS,
    HIDE_UNASSIGNED_LOANS,
    DELETE_ASSIGNED_COLLECTOR,
    DELETE_ASSIGNED_COLLECTOR_FAILED,
    DELETE_ASSIGNED_COLLECTOR_SUCCESSFUL,
    CLEAR_COMMENT_PAGE,
    COLLECTOR_DETAILS_CHANGED,
    OD_AGING_REPORT_DATE_CHANGED,
    SAVE_SECOND_DEMAND_NOTICE,
    SAVE_SECOND_DEMAND_NOTICE_FAILED,
    CLEAR_DEMAND_NOTICE_MESSAGES,
    SAVE_SECOND_DEMAND_NOTICE_SUCCESSFUL,
    SECOND_DEMAND_NOTICE_DATE_CHANGED,
    DELETE_SECOND_DEMAND_NOTICES,
    DELETE_SECOND_DEMAND_NOTICES_FAILED,
    DELETE_SECOND_DEMAND_NOTICES_SUCCESSFUL,
    CLEAR_COLLECTOR_MESSAGES,
    CALCULATE_PENAL_INTEREST_SUCCESSFUL,
    CALCULATE_PENAL_INTEREST_FAILED,
    COLLECTOR_MESSAGE,
    FETCH_OVERDUE_TERMS,
    FETCH_OVERDUE_TERMS_SUCCESSFUL,
    FETCH_OVERDUE_TERMS_FAILED,
    FETCH_RANGED_OVERDUE_TERMS,
    FETCH_RANGED_OVERDUE_TERMS_SUCCESSFUL,
    FETCH_RANGED_OVERDUE_TERMS_FAILED,
    FETCH_ALL_BEFORE_TERMS,
    FETCH_ALL_BEFORE_TERMS_SUCCESSFUL,
    FETCH_ALL_BEFORE_TERMS_FAILED,
    RESET_COLLECTORS,
    RESET_COLLECTORS_FAILED,
    RESET_COLLECTORS_SUCCESSFUL,
    FETCH_LAST_COMMENTS_SUCCESSFUL,
    FETCH_ROLLOVER,
    FETCH_ROLLOVER_FAILED,
    FETCH_ROLLOVER_SUCCESSFUL,
    FETCH_ROLLOVER_LAST_MONTH,
    FETCH_ROLLOVER_LAST_MONTH_SUCCESSFUL,
    FETCH_ROLLOVER_LAST_MONTH_FAILED,
    HIDE_TOTAL_PAID_PREVIOUSLY_CARD,
    FILTER_EXECUTIVE_CHANGED,
    RESET_EXECUTIVE,
    EXTERNAL_COLLECTOR_CHANGED
} from "./Types";
import _ from "lodash";
import { ConsoleView } from 'react-device-detect';

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const dateRangeChanged = (date) => {
    return{
        type: MASTER_LIST_DATE_RANGE_CHANGED,
        payload: date
    }
};

export const odAgingReportDateChanged = ({prop, value}) => {
    return{
        type: OD_AGING_REPORT_DATE_CHANGED,
        payload: {prop, value}
    }
};

export const checkLoanTermsNo = () => {
    return async () => {
        const loanTermRef = firebase.firestore().collection('loanTerms');
        const snapshot = await loanTermRef.get();
        console.log(snapshot.size);
    }
}

export const fetchBouncedCheques = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_BOUNCED_CHEQUES });
        const url = `${project.serverUrl}fetchMasterList`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
        })
    };
};


export const fetchOverdueTerms = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_OVERDUE_TERMS });
        const url = `${project.serverUrl}fetchMasterListNew`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: FETCH_OVERDUE_TERMS_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_OVERDUE_TERMS_FAILED });
        })
    };
};

export const eraseAlCollectors = ({ collectorsArray }) => {
    return(dispatch) => {
        dispatch({ type: RESET_COLLECTORS });

        for (let i = 0; i < collectorsArray.length; i++) {
            const end = collectorsArray.length - 1;
            collectorsArray.forEach((cheque, index) => {
                firebase.firestore().collection("loanTerms").doc(cheque.loanTermID)
                .update({ "assignee": firebase.firestore.FieldValue.delete()})
                .then(() => {
                    //comment post successful
                    if(index === end){
                        dispatch({ type: RESET_COLLECTORS_SUCCESSFUL });
                    }
                })
                .catch(error => {
                    console.log("failed to post comment");
                    console.log(error);
                    dispatch({ type: RESET_COLLECTORS_FAILED });
                })
            })
        }
    }

}

export const fetchAllBeforeTerms = () => {

    return(dispatch) => {

        dispatch({ type: FETCH_ALL_BEFORE_TERMS });

        const url = `${project.serverUrl}fetchAllBeforeTerms`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: FETCH_ALL_BEFORE_TERMS_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_ALL_BEFORE_TERMS_FAILED });
        })
    };
};

export const fetchRangedBouncedCheques = (dateRange) => {

    const data = JSON.stringify({
        startDate: dateRange[0],
        endDate: dateRange[1],
    });

    return(dispatch) => {
        dispatch({ type: FETCH_RANGED_BOUNCED_CHEQUES });
        const url = `${project.serverUrl}fetchRangedMasterList`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: FETCH_RANGED_BOUNCED_CHEQUES_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Error getting bouncing ranged master list");
            console.log(error);
            dispatch({ type: FETCH_RANGED_BOUNCED_CHEQUES_FAILED });
        })
    };
};

export const fetchRangedOverdueTerms = (dateRange) => {

    const data = JSON.stringify({
        startDate: dateRange[0],
        endDate: dateRange[1],
    });

    return(dispatch) => {
        dispatch({ type: FETCH_RANGED_OVERDUE_TERMS });
        const url = `${project.serverUrl}fetchRangedMasterListNew`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: FETCH_RANGED_OVERDUE_TERMS_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Error getting bouncing ranged master list");
            console.log(error);
            dispatch({ type: FETCH_RANGED_OVERDUE_TERMS_FAILED });
        })
    };
};

export const refreshMasterList = () => {
    //call penal interest
    return(dispatch) => {
        dispatch({ type: FETCH_BOUNCED_CHEQUES });

        const bouncedUrl = `${project.serverUrl}fetchMasterList`;
        fetch(bouncedUrl, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
        });

        const url = `${project.serverUrl}fetchMasterListNew`;
        fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                dispatch({
                    type: FETCH_OVERDUE_TERMS_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            dispatch({ type: FETCH_OVERDUE_TERMS_FAILED });
        })

    }
};

export const collectorChanged = (value) => {
    return {
        type: COLLECTOR_CHANGED,
        payload: value
    }
};

export const externalCollectorChanged = (value) => {
    return {
        type: EXTERNAL_COLLECTOR_CHANGED,
        payload: value
    }
};

export const showComments = () => {
    return {
        type: SHOW_COMMENT_CARD,
    }
};

export const hideComments = () => {
    return {
        type: HIDE_COMMENT_CARD,
    }
};

export const showNotices = (loanID) => {
    return {
        type: SHOW_NOTICES_CARD,
        payload: loanID
    }
};

export const hideNotices = () => {
    return {
        type: HIDE_NOTICES_CARD,
    }
};

export const showUpdateNotices = () => {
    return {
        type: SHOW_UPDATE_NOTICES_CARD,
    }
};

export const hideUpdateNotices = () => {
    return {
        type: HIDE_UPDATE_NOTICES_CARD,
    }
};

export const showAssignCollector = (cheque) => {
    return {
        type: SHOW_ASSIGN_COLLECTOR_CARD,
        payload: cheque
    }
};

export const hideAssignCollector = () => {
    return {
        type: HIDE_ASSIGN_COLLECTOR_CARD,
    }
};

export const showUnAssignedLoans = () => {
    return {
        type: SHOW_UNASSIGNED_LOANS,
    }
};

export const hideUnAssignedLoans = () => {
    return {
        type: HIDE_UNASSIGNED_LOANS,
    }
};

export const fetchCurrentNotice = (notice) => {
    return {
        type: FETCH_CURRENT_NOTICE,
        payload: notice
    }
};

export const commentsChanged = (value) => {
    return {
        type: MASTER_LIST_COMMENT_CHANGED,
        payload: value
    }
};

export const collectorDetailsChanged = ({prop, value}) => {
    return {
        type: COLLECTOR_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const commentsDateRangeChanged = (date) => {

    return{
        type: MASTER_LIST_COMMENTS_DATE_RANGE_CHANGED,
        payload: date
    }
};

export const demandNoticeDateChanged = (date) => {
    return {
        type: DEMAND_NOTICE_DATE_CHANGED,
        payload: date
    }
};

export const secondDemandNoticeDateChanged = (date) => {
    return {
        type: SECOND_DEMAND_NOTICE_DATE_CHANGED,
        payload: date
    }
};

export const clearPage = () => {
    return {
        type: CLEAR_COMMENT_PAGE,
    }
};

export const saveComments = ({ commentID, comments, collectorID, loanID, loanTermID }) => {

    const data = JSON.stringify({
        commentID, comments, collectorID, loanID, loanTermID
    });

    console.log({ commentID, comments, collectorID, loanID, loanTermID });

    return(dispatch) => {
        dispatch({ type: SAVE_MASTER_LIST_COMMENT });
        const url = `${project.serverUrl}saveComments`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {

                dispatch({
                    type: SAVE_MASTER_LIST_COMMENT_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Error saving master list comment");
            console.log(error);
            dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
        })
    };
}

export const deleteComment = ({ commentID, loanID }) => {

    const data = JSON.stringify({
        commentID,loanID
    });

    console.log({ commentID, loanID });

    return(dispatch) => {
        dispatch({ type: DELETE_MASTER_LIST_COMMENT });
        const url = `${project.serverUrl}deleteComments`;
        fetch(url, {
            method: 'POST',
            body: data,
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
        }).then((response) => response.json())
            .then((response) => {
                console.log(response);
                console.log("comment deleted successful");

                dispatch({
                    type: DELETE_MASTER_LIST_COMMENT_SUCCESSFUL,
                    payload: response
                });
            }).catch((error) => {
            console.log("Error deleting master list comment");
            console.log(error);
            dispatch({ type: DELETE_MASTER_LIST_COMMENT_FAILED });
        })
    };
}

// export const saveComments = ({ commentID, comments, collectorID, loanID, loanTermID }) => {
//
//     //create timestamp when comment was posted
//     const timeStamp = new Date();
//
//     return(dispatch) => {
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT });
//         firebase.firestore().collection("masterListComments").where("loanID", "==", loanID)
//         .get()
//             .then(function(querySnapshot) {
//                 if(querySnapshot.size !== 0){
//                     querySnapshot.forEach(function(doc) {
//                         const data = doc.data();
//                         updateMasterListComment({commentID, data, comments, collectorID, loanID, loanTermID, timeStamp, dispatch})
//                     });
//                 }else{
//                     uploadSaveComment({commentID, comments, collectorID, loanID, loanTermID, timeStamp, dispatch});
//                 }
//             })
//             .catch(function (error) {
//                 console.log(error);
//                 dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//             })
//     }
// };
//
// function updateMasterListComment({commentID, data, comments, collectorID, loanID, loanTermID, timeStamp, dispatch}){
//     firebase.firestore().collection("masterListComments").doc(data.commentID)
//     .delete()
//     .then(() => {
//         //comment post successful
//         uploadSaveComment({commentID, comments, collectorID, loanID, loanTermID, timeStamp, dispatch});
//     })
//     .catch(error => {
//         console.log("failed to post comment");
//         console.log(error);
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//     })
// }
//
// function uploadSaveComment({commentID, comments, collectorID, loanID, loanTermID, timeStamp, dispatch}){
//     firebase.firestore().collection("masterListComments").doc(commentID)
//     .set({ commentID, comments, collectorID, timeStamp, loanID, loanTermID })
//     .then(() => {
//         //comment post successful
//         updateCommentToAllLoanTerms({ commentID, comments, loanID, loanTermID, dispatch });
//     })
//     .catch(error => {
//         console.log("failed to post comment");
//         console.log(error);
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//     })
// }
//
// function updateCommentToAllLoanTerms({ commentID, comments, loanID, dispatch }){
//     firebase.firestore().collection("loanTerms").where("loanID", "==", loanID)
//     .onSnapshot(function(querySnapshot) {
//         querySnapshot.forEach(function(doc) {
//             const data = doc.data();
//             const loanTermID = data.loanTermID;
//             const customerID = data.customerID;
//             writeCommentToLoanTerm({ commentID, comments, loanID, loanTermID, customerID, dispatch });
//
//         });
//
//         getMasterListComment({loanID, dispatch});
//
//     }, function (error) {
//         console.log(error);
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//     });
// }
//
// function writeCommentToLoanTerm({ commentID, comments, loanID, loanTermID, customerID, dispatch }){
//     const masterListComment = {commentID, newComment: comments}
//
//     firebase.firestore().collection("loanTerms").doc(loanTermID)
//     .update({ masterListComment: masterListComment})
//     .then(() => {
//         getMasterListComment({loanID, dispatch});
//     })
//     .catch(error => {
//         console.log(error);
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//     })
// }

// function writeCommentToLoanTermToCustomerProfile({ commentID, comments, loanID, loanTermID, customerID, dispatch }){
//     const masterListComment = {commentID, newComment: comments}

//     firebase.firestore().collection("user").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
//     .update({ masterListComment: masterListComment})
//     .then(() => {
//         getMasterListComment({loanID, dispatch});
//     })
//     .catch(error => {
//         console.log(error);
//         dispatch({ type: SAVE_MASTER_LIST_COMMENT_FAILED });
//     })
// }

export const fetchComments = ({loanID}) => {

    return(dispatch) => {
        getMasterListComment({dispatch, loanID});
    }
};

function getMasterListComment({dispatch, loanID}){
    let fetchedComments = {};
    firebase.firestore().collection("masterListComments").where("loanID", "==", loanID)
        .onSnapshot(function(querySnapshot) {

            querySnapshot.forEach(function(doc) {

                fetchedComments = doc.data();

            });

            dispatch({
                type: FETCH_MASTER_LIST_COMMENTS_SUCCESSFUL,
                payload: fetchedComments
            });
            dispatch({ type: SAVE_MASTER_LIST_COMMENT_SUCCESSFUL });
    }, function(error) {
        console.log(error);
        dispatch({ type: FETCH_MASTER_LIST_COMMENTS_FAILED })
    })
}


// export const deleteComment = ({ commentID, loanID }) => {
//     return(dispatch) => {
//         firebase.firestore().collection("masterListComments").doc(commentID)
//         .delete()
//         .then(() => {
//             fetchAllLoanTerms({ loanID, dispatch });
//         })
//         .catch(error => {
//             console.log(error);
//         })
//     }
// }

// async function fetchAllLoanTerms({ loanID, dispatch }){
//     // const colSnapshot = await colRef.get()
//     // const delField = {masterListComment: firebase.firestore.FieldValue.delete()}
//     // const updates = colSnapshot.docs.map((doc) => colRef.doc(doc.id).update(delField))
//     // await Promise.all(updates).then(() => {
//     //     console.log("comment deleted successful, please refresh ur page");
//     // })
//     firebase.firestore().collection("loanTerms").where("loanID", "==", loanID)
//     .get()
//         .then(function(querySnapshot) {

//             querySnapshot.forEach(function(doc) {
//                 const data = doc.data();
//                 const loanTermID = data.loanTermID;
//                 deleteCommentToLoanTerm({ loanTermID, loanID });
//             });

//         })
//         .catch(function (error) {
//             console.log(error);
//         })
// }


// function deleteCommentToLoanTerm({ loanTermID }){
//     firebase.firestore().collection("loanTerms").doc(loanTermID)
//         .update({"masterListComment" : firebase.firestore.FieldValue.delete()})
//         .then(() => {
//             console.log("comment deleted successful");
//         })
//         .catch(error => {
//             console.log(error);
//         });
// }

export const fetchRangedComments = ({ loanID, loanTermID, dateRange, collectorID }) => {

    //create empty store
    let fetchedComments = {};

    const startDate = dateRange[0].subtract(1, 'days');
    const endDate = dateRange[1];

    return(dispatch) => {
        dispatch({ type: FETCH_RANGED_MASTER_LIST_COMMENTS });

        firebase.firestore().collection("masterListComments").where("loanID", "==", loanID).where("loanTermID", "==", loanTermID)
            .onSnapshot(function(querySnapshot) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots

                    const data = doc.data();

                    //check if collectorID is not empty
                    if (collectorID) {
                        //take comments from requested collector ID only
                        if (collectorID === data.collectorID) {

                            let seconds;

                            data.timeStamp._seconds ? seconds = data.timeStamp._seconds : seconds = data.timeStamp.seconds;
                            const timeStamp = moment.unix(seconds);

                            //within the range of the start and end date
                            if (moment(timeStamp).isSameOrAfter(startDate) && moment(timeStamp).isSameOrBefore(endDate)) {
                                //matches dates
                                fetchedComments[doc.id] = doc.data();

                            } else {
                                // doesn't match dates do nothing
                            }
                        }
                    } else {
                        //return fetch results without check collector matches
                        let seconds;

                        data.timeStamp._seconds ? seconds = data.timeStamp._seconds : seconds = data.timeStamp.seconds;
                        const timeStamp = moment.unix(seconds);

                        //within the range of the start and end date
                        if (moment(timeStamp).isSameOrAfter(startDate) && moment(timeStamp).isSameOrBefore(endDate)) {
                            //matches dates
                            fetchedComments[doc.id] = doc.data();
                        } else {
                            // doesn't match dates do nothing
                        }
                    }
                });

                dispatch({
                    type: FETCH_RANGED_MASTER_LIST_COMMENTS_SUCCESSFUL,
                    payload: fetchedComments
                });
            }, function (error) {
                console.log("Error getting ranged comments", error);

                dispatch({ type: FETCH_RANGED_MASTER_LIST_COMMENTS_FAILED })
            })

    }
};

export const saveDemandNotice = ({ demandNoticeDate, customerID, loanTermID, terms }) => {


    return(dispatch) => {
        dispatch({ type: SAVE_DEMAND_NOTICE });
        terms.forEach(term => {
            saveDemandNoticeToProfile({demandNoticeDate, customerID, loanTermID: term.loanTermID, dispatch});
        })
    }
};

function saveDemandNoticeToProfile({demandNoticeDate, customerID, loanTermID, dispatch}){

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
    .update({ firstDemandNotice: demandNoticeDate })
    .then(() => {
        //demand notice post successful
        saveDemandNoticeToBucket({demandNoticeDate, loanTermID, dispatch})
    })
    .catch(error => {
        dispatch({ type: SAVE_DEMAND_NOTICE_FAILED });
        console.log("failed to save demand notice");
        console.log(error);
        dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });
    })
}

function saveDemandNoticeToBucket({demandNoticeDate, loanTermID, dispatch}) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ firstDemandNotice: demandNoticeDate })
        .then(() => {

            dispatch({ type: SAVE_DEMAND_NOTICE_SUCCESSFULL });
            dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });

            //Re-fetching master list
            const url = `${project.serverUrl}fetchMasterList`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("master list fetched");

                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            })
        })
}

export const saveSecondDemandNotice = ({ demandNoticeDate, customerID, loanTermID, terms }) => {

    return(dispatch) => {
        dispatch({ type: SAVE_SECOND_DEMAND_NOTICE });

        terms.forEach(term => {
            saveSecondDemandNoticeToProfile({demandNoticeDate, customerID, loanTermID: term.loanTermID, dispatch});
        })
    }
};

function saveSecondDemandNoticeToProfile({demandNoticeDate, customerID, loanTermID, dispatch}){

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
    .update({ secondDemandNotice: demandNoticeDate })
    .then(() => {
        //demand notice post successful
        saveSecondDemandNoticeToBucket({demandNoticeDate, loanTermID, dispatch})
    })
    .catch(error => {
        dispatch({ type: SAVE_SECOND_DEMAND_NOTICE_FAILED });
        console.log("failed to save demand notice");
        console.log(error);
        dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });
    })
}

function saveSecondDemandNoticeToBucket({demandNoticeDate, loanTermID, dispatch}) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ secondDemandNotice: demandNoticeDate })
        .then(() => {

            dispatch({ type: SAVE_SECOND_DEMAND_NOTICE_SUCCESSFUL });
            dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });

            //Re-fetching master list
            const url = `${project.serverUrl}fetchMasterList`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            })
        })
}


export const deleteDemandNotice = ({ customerID, loanTermID, terms }) => {

    return(dispatch) => {
        dispatch({ type: DELETE_DEMAND_NOTICES });
        terms.forEach(term => {
            deleteDemandNoticeToProfile({ loanTermID: term.loanTermID, dispatch, customerID})
        })
    }
};

function deleteDemandNoticeToProfile({ loanTermID, dispatch, customerID}) {
    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
    .update({ firstDemandNotice: firebase.firestore.FieldValue.delete() })
    .then(() => {
        //demand notice post successful
        deleteDemandNoticeToBucket({ loanTermID, dispatch});
    })
    .catch(error => {
        dispatch({ type: DELETE_DEMAND_NOTICES_FAILED });
        console.log("failed to delete demand notice");
        console.log(error);
        dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });
    })
}



function deleteDemandNoticeToBucket({ loanTermID, dispatch}) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ firstDemandNotice: firebase.firestore.FieldValue.delete() })
        .then(() => {

            dispatch({ type: DELETE_DEMAND_NOTICES_SUCCESSFUL });

            //Re-fetching master list
            const url = `${project.serverUrl}fetchMasterList`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            })
        })
}

export const deleteSecondDemandNotice = ({ customerID, loanTermID, terms }) => {
    return(dispatch) => {
        dispatch({ type: DELETE_SECOND_DEMAND_NOTICES });
        terms.forEach(term => {
            deleteSecondDemandNoticeToProfile({ loanTermID: term.loanTermID, dispatch, customerID})
        })
    }
};

function deleteSecondDemandNoticeToProfile({ loanTermID, customerID, dispatch}) {
    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
    .update({ secondDemandNotice: firebase.firestore.FieldValue.delete() })
    .then(() => {
        //demand notice post successful
        deleteSecondDemandNoticeToBucket({ loanTermID, dispatch});
    })
    .catch(error => {
        dispatch({ type: DELETE_SECOND_DEMAND_NOTICES_FAILED });
        console.log("failed to delete demand notice");
        console.log(error);
        dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });
    })
}

function deleteSecondDemandNoticeToBucket({ loanTermID, dispatch}) {

    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update({ secondDemandNotice: firebase.firestore.FieldValue.delete() })
        .then(() => {

            dispatch({ type: DELETE_SECOND_DEMAND_NOTICES_SUCCESSFUL });
            dispatch({ type: CLEAR_DEMAND_NOTICE_MESSAGES });

            //Re-fetching master list
            const url = `${project.serverUrl}fetchMasterList`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {

                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            })
        })
}


export const fetchDemandNotices = ({loanID, loanTermID}) => {

    return(dispatch) => {
        dispatch({ type: FETCH_DEMAND_NOTICES });

        let fetchedDemandNotices = {};

        firebase.firestore().collection("masterListDemandNotices").where("loanID", "==", loanID).where("loanTermID", "==", loanTermID)
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {

                    fetchedDemandNotices[doc.id] = doc.data();

                });

                dispatch({
                    type: FETCH_DEMAND_NOTICES_SUCCESSFUL,
                    payload: fetchedDemandNotices
                });

            }, function (error) {
                console.log("Error fetching demand notices");
                console.log(error);
                dispatch({ type: FETCH_DEMAND_NOTICES_FAILED })
            });
    }
};


export const updateDemandNoticeDateChanged = (date) => {
    return {
        type: UPDATE_DEMAND_NOTICE_DATE_CHANGED,
        payload: date
    }
};

export const updateDemandNotice = ({ demandNoticeID, demandNoticeDate }) => {

    return(dispatch) => {
        dispatch({ type: UPDATE_DEMAND_NOTICE });
        firebase.firestore().collection("masterListDemandNotices").doc(demandNoticeID)
            .update({ demandNoticeDate })
            .then(() => {
                //comment post successful
                dispatch({ type: UPDATE_DEMAND_NOTICE_SUCCESSFUL });
            })
            .catch(error => {
                console.log("failed to update demand notice");
                console.log(error);
                dispatch({ type: UPDATE_DEMAND_NOTICE_FAILED });
            })
    }
};

// export const deleteNotice = ({demandNoticeID, loanID, }) => {
//
//     return(dispatch) => {
//
//         dispatch({ type: DELETE_DEMAND_NOTICES });
//
//         firebase.firestore().collection("masterListDemandNotices").doc(demandNoticeID)
//             .delete()
//             .then(() => {
//                 console.log("demand notice is deleted");
//                 dispatch({ type: DELETE_DEMAND_NOTICES_SUCCESSFUL });
//
//
//             })
//             .catch(error => {
//                 console.log(error);
//                 console.log("loan term is not deleted to customer profile");
//                 dispatch({ type: DELETE_DEMAND_NOTICES_FAILED });
//             })
//
//     };
// };

export const numberOfLoansToAssignChanged = (value) => {
    return {
        type: NUMBER_OF_LOANS_TO_ASSIGN_CHANGED,
        payload: value
    }
};

export const writeLoanCollector = ({ customerID, terms, assignee }) => {

    let successArray = [];

    return(dispatch) => {

        dispatch({ type: ASSIGN_COLLECTOR });

        _.forEach(terms, term => {

            const loanTermID = term.loanTermID;

            const res = assignCollectorInProfile({ customerID, loanTermID, assignee });
            successArray.push(res);
        });

        Promise.all(successArray).then(() => {
            dispatch({ type: ASSIGN_COLLECTOR_SUCCESSFUL });

            dispatch({ type: FETCH_BOUNCED_CHEQUES });

            const bouncedUrl = `${project.serverUrl}fetchMasterList`;
            fetch(bouncedUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            });

            const url = `${project.serverUrl}fetchMasterListNew`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_OVERDUE_TERMS_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_OVERDUE_TERMS_FAILED });
            })

        })
    };
};

export const assignCollector = ({ selectedLoans, assignee }) => {

    let successArray = [];

    return (dispatch) => {
        //
        dispatch({ type: ASSIGN_COLLECTOR });

        //loop over the loans individually and assign the collector
        selectedLoans.map(loan => {

            const terms = loan.terms;
            //grab the first cheque and take the customer ID
            const customerID = terms[0].customerID;
            //write to each loan

            const res = assignLoanCollector({customerID, terms, assignee});
            successArray.push(res);
        });


        Promise.all(successArray).then(() => {
            dispatch({type: COLLECTOR_MESSAGE});
            dispatch({ type: ASSIGN_COLLECTOR_SUCCESSFUL });
            dispatch({type: CLEAR_COLLECTOR_MESSAGES });

            dispatch({ type: FETCH_BOUNCED_CHEQUES });

            const bouncedUrl = `${project.serverUrl}fetchMasterList`;
            fetch(bouncedUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            });

            const url = `${project.serverUrl}fetchMasterListNew`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_OVERDUE_TERMS_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_OVERDUE_TERMS_FAILED });
            })
        })

    };
};


async function assignLoanCollector({customerID, terms, assignee}) {

    let successArray = [];

    try {
        //
        _.forEach(terms, term => {

            const loanTermID = term.loanTermID;

            const res = assignCollectorInProfile({ customerID, loanTermID, assignee });
            successArray.push(res);
        });
    } catch (e) {
        console.log(e);
    }

    return Promise.all(successArray).then((values) => {
        return values[0];
    })
}

async function assignCollectorInProfile({ customerID, loanTermID, assignee }) {

    let successArray = [];

    try {
        const loanTermRef = firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID);
        const res = await loanTermRef.update({ assignee });
        successArray.push(res);
    } catch (e) {
        console.log(e);
    }

    return Promise.all(successArray).then(() => {
        return updateLoanTermToBucket({ loanTermID, assignee });
    })
}

async function updateLoanTermToBucket({ loanTermID, assignee }) {

    let successArray = [];
    try {
        const loanTermsRef = firebase.firestore().collection("loanTerms").doc(loanTermID);
        const res = await loanTermsRef.update({ assignee });
        successArray.push(res);
    } catch (e) {
        console.log(e);
    }

    return Promise.all(successArray).then(() => {
        return "";
    })
}

export const deleteAssignee = ({ customerID, loanTerms }) => {

    let successArray = [];
    return(dispatch) => {

        dispatch({ type: DELETE_ASSIGNED_COLLECTOR });

        loanTerms.forEach(term => {
            try {
                const loanTermID = term.loanTermID;
                const res = deleteAssigneeFromLoanTermProfile({ customerID, loanTermID });
                successArray.push(res);
            } catch (e) {
                console.log(e);
                dispatch({ type: DELETE_ASSIGNED_COLLECTOR_FAILED });
                console.log("loan term is not updated to customer profile");
                dispatch({type: CLEAR_COLLECTOR_MESSAGES });
            }
        });

        Promise.all(successArray).then(() => {
            dispatch({ type: DELETE_ASSIGNED_COLLECTOR_SUCCESSFUL });
            dispatch({type: CLEAR_COLLECTOR_MESSAGES });

            dispatch({ type: FETCH_BOUNCED_CHEQUES });

            const bouncedUrl = `${project.serverUrl}fetchMasterList`;
            fetch(bouncedUrl, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_BOUNCED_CHEQUES_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_BOUNCED_CHEQUES_FAILED });
            });

            const url = `${project.serverUrl}fetchMasterListNew`;
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    dispatch({
                        type: FETCH_OVERDUE_TERMS_SUCCESSFUL,
                        payload: response
                    });
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_OVERDUE_TERMS_FAILED });
            })
        })
    };
};

async function deleteAssigneeFromLoanTermProfile({ customerID, loanTermID }) {
    let successArray = [];
    try {
        const loanTermRef = firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID);
        const res = await loanTermRef.update({ assignee: firebase.firestore.FieldValue.delete() });
        successArray.push(res);
    } catch (e) {
        console.log(e);
        console.log("failed to delete assignee from loan term profile");
    }

    Promise.all(successArray).then(() => {
        return deleteAssigneeFromTermBucket ({ loanTermID });
    })
}

async function deleteAssigneeFromTermBucket ({ loanTermID }) {
    let successArray = [];

    try {
        const loanTermRef = firebase.firestore().collection("loanTerms").doc(loanTermID);
        const res = await loanTermRef.update({ assignee: firebase.firestore.FieldValue.delete() });
        successArray.push(res);
    } catch (e) {
        console.log(e);
        console.log("loan term is not updated to in term buckets");
    }

    Promise.all(successArray).then(() => {
        return "";
    })
}

export const filterLegalRepoStatusChange = (value) => {
    return {
        type: FILTER_LEGAL_REPO_STATUS_CHANGED,
        payload: value
    }
};

export const resetExecutive = () => {
    return {
        type: RESET_EXECUTIVE,
    }
}

export const exeOfficerChange = (value) => {
    return {
        type: FILTER_EXECUTIVE_CHANGED,
        payload: value
    }
}

export const clearLegalRepoFilter = () => {
    return {
        type: CLEAR_LEGAL_REPO_FILTER
    }
};

export const filterBucketChanged = (value) => {
    return{
        type: FILTER_BUCKET_CHANGED,
        payload: value
    }
};

export const fetchRolloverOverdue = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_ROLLOVER});

        try{
            const url = `${project.serverUrl}fetchMasterListNew`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("Here's your reports object");
                    if(response.length !== 0){
                        let overdueTerms = [];
                        response.forEach(term => {
                            let seconds;
                            if ("transactionDate" in term) {
                                if (term.transactionDate) {
                                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                            } else {
                                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                            }
                            const dueDate = moment.unix(seconds);
                            const today = moment();
                            term["transDate"] = dueDate.format('MMMM');
                            term["transYear"] = dueDate.format('YYYY');
                            if((dueDate.isSame(today, "month"))) {
                            
                                overdueTerms.push(term);
                            }
                        })
                        let costomerObj = {};
                        if(overdueTerms.length !== 0){
                            overdueTerms.map(data => {
                                //grab emi according to customer
                                if(`${data.customerID}` in costomerObj){
                                    let terms = costomerObj[`${data.customerID}`].terms;
                                        terms.push(data)

                                }else{
                                    costomerObj[`${data.customerID}`] = {
                                        terms: [data],
                                    }
                                }
                            })
                        }
                            
                        grabCustomerData({costomerObj, dispatch});
                    }
    
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_ROLLOVER_FAILED });
            });
        }
        catch(e){
            console.log(e);
        dispatch({type: FETCH_ROLLOVER_FAILED});

        }
    }
}

async function grabCustomerData({costomerObj, dispatch}){
    let successArray = [];
    try{
        const customersArr = Object.values(costomerObj);
        customersArr.forEach(customer => {
            const res = grabNewCaseData({termsArr: customer.terms, dispatch});
            successArray.push(res);
        })
    }
    catch(e){
        console.log(e);
        dispatch({type: FETCH_ROLLOVER_FAILED});

    }

    Promise.all(successArray).then((overdues) => {
        let totalArr = [];
        overdues.forEach(ov => {
            totalArr.push(ov.overdue);
        })
        let overdueTotal = totalArr.reduce((a, b) => a + b, 0);
        const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'usd',
            });
        overdueTotal = formatter.format(overdueTotal);
        overdues.push({transDate: "Grand Total", overdue: overdueTotal, customerName: ""});
        dispatch({ type: FETCH_ROLLOVER_SUCCESSFUL, payload: overdues});
    })
}

async function grabNewCaseData({termsArr, dispatch}){
    try{
        let overdueArr = [];
        let customerName = "";
        let transDate = "";
        let transYear = "";
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        if(systemDoc.exists){
            const systemInfo = systemDoc.data();
            termsArr.forEach(term => {
                customerName = term.customerName;
                transDate = term.transDate;
                transYear = term.transYear;
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        overdueArr.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        overdueArr.push(convertedAmount);
                    }
                }
            })
        }

        let overdue = overdueArr.reduce((a, b) => a + b, 0);
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        overdue = roundAccurately(overdue, 2);
        return {overdue, customerName, transDate, transYear}

    }
    catch(e){
        console.log(e);
        dispatch({type: FETCH_ROLLOVER_FAILED});

    }
}

export const createRollupCustomerOverdueReportLastMonth = () => {
    return async (dispatch) => {
        dispatch({type: FETCH_ROLLOVER_LAST_MONTH})

        try{
            const url = `${project.serverUrl}fetchMasterListNew`;
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'},
            }).then((response) => response.json())
                .then((response) => {
                    console.log("Here's your reports object");
                    if(response.length !== 0){
                        let overdueTerms = [];
                        response.forEach(term => {
                            let seconds;
                            if ("transactionDate" in term) {
                                if (term.transactionDate) {
                                    term.transactionDate.seconds ? seconds = term.transactionDate.seconds :  seconds = term.transactionDate._seconds;
                                } else {
                                    term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                                }
                            } else {
                                term.dueDate.seconds ? seconds = term.dueDate.seconds :  seconds = term.dueDate._seconds;
                            }
                            const dueDate = moment.unix(seconds);
                            const previousMonthDate = moment().subtract(1, "months").calendar();
                            const startDay = moment(previousMonthDate).startOf("months");
                            const lastDay = moment(previousMonthDate).endOf("months");
                            term["transDate"] = dueDate.format('MMMM');
                            term["transYear"] = dueDate.format('YYYY');
                            console.log({dueDate, startDay, lastDay})

                            if((dueDate.isSameOrAfter(startDay, "day") && (dueDate.isSameOrBefore(lastDay, "day")))) {
                            
                                overdueTerms.push(term);
                            }
                        })
                        let costomerObj = {};
                        if(overdueTerms.length !== 0){
                            overdueTerms.map(data => {
                                //grab emi according to customer
                                if(`${data.customerID}` in costomerObj){
                                    let terms = costomerObj[`${data.customerID}`].terms;
                                        terms.push(data)

                                }else{
                                    costomerObj[`${data.customerID}`] = {
                                        terms: [data],
                                    }
                                }
                            })
                        }
                            
                            grabCustomerDataRolledOver({costomerObj, dispatch});
                    }
                }).catch((error) => {
                console.log("Here's your error");
                console.log(error);
                dispatch({ type: FETCH_ROLLOVER_LAST_MONTH_FAILED });
            });
        }
        catch(e){
            console.log(e);
        dispatch({type: FETCH_ROLLOVER_LAST_MONTH_FAILED});

        }
    }
}

async function grabCustomerDataRolledOver({costomerObj, dispatch}){
    let successArray = [];
    try{
        const customersArr = Object.values(costomerObj);
        customersArr.forEach(customer => {
            const res = grabNewCaseDataRolledOver({termsArr: customer.terms, dispatch});
            successArray.push(res);
        })
    }
    catch(e){
        console.log(e);
        dispatch({type: FETCH_ROLLOVER_LAST_MONTH_FAILED});

    }

    Promise.all(successArray).then((overdues) => {
        let totalArr = [];
        overdues.forEach(ov => {
            totalArr.push(ov.overdue);
        })
        let overdueTotal = totalArr.reduce((a, b) => a + b, 0);
        const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'usd',
            });
        overdueTotal = formatter.format(overdueTotal);
        overdues.push({transDate: "Grand Total", overdue: overdueTotal, customerName: ""});
        dispatch({type: FETCH_ROLLOVER_LAST_MONTH_SUCCESSFUL, payload: overdues});
    })
}

async function grabNewCaseDataRolledOver({termsArr, dispatch}){
    try{
        let overdueArr = [];
        let customerName = "";
        let transDate = "";
        let transYear = "";
        const systemRef = firebase.firestore().collection("system").doc("info");
        const systemDoc = await systemRef.get();

        if(systemDoc.exists){
            const systemInfo = systemDoc.data();
            termsArr.forEach(term => {
                customerName = term.customerName;
                transDate = term.transDate;
                transYear = term.transYear;
                if (term.termStatus.status) {
                    //term is cleared
                    //do nothing
                } else {
                    //if loan is in usd convert the overdue to default currency
                    if (term.currency === "usd") {
                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        overdueArr.push(amount);
                    } else {
                        //fetch system info exchange rate
                        const exchangeRate = systemInfo.exchangeRate;

                        //grab the total overdue
                        let amount;
                        if ("modulo" in term) {
                            amount = term.amount - term.modulo;
                        } else {
                            amount = term.amount;
                        }

                        const convertedAmount = amount/exchangeRate;

                        overdueArr.push(convertedAmount);
                    }
                }
            })
        }

        let overdue = overdueArr.reduce((a, b) => a + b, 0);
        const roundAccurately = (number, decimalPlaces) => Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces);
        overdue = roundAccurately(overdue, 2);
        return {overdue, customerName, transDate, transYear}

    }
    catch(e){
        console.log(e);
        dispatch({type: FETCH_ROLLOVER_LAST_MONTH_FAILED});

    }
}