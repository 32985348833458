import * as firebase from 'firebase';
import {message} from "antd";

//Types import
import {
    CLEAR_EXISTING_CUSTOMER,
    CREATE_NEW_CUSTOMER,
    CREATE_NEW_CUSTOMER_FAILED,
    CREATE_NEW_CUSTOMER_SUCCESSFUL,
    CUSTOMER_DETAILS_CHANGED,
    FETCH_CUSTOMER_PROFILE, FETCH_CUSTOMER_PROFILE_FAILED, FETCH_CUSTOMER_PROFILE_SUCCESSFUL,
    SEARCH_EXISTING_CUSTOMER_FAILED,
    SEARCH_EXISTING_CUSTOMER_NO_RECORD,
    SEARCH_EXISTING_CUSTOMER_SUCCESSFUL,
    ADD_PHONE_FAILED,
    ADD_PHONE,
    ADD_PHONE_SUCCESSFUL,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESSFUL,
    DELETE_CUSTOMER_FAILED,
    ADD_EXTERNAL_COLLECTOR,
    ADD_EXTERNAL_COLLECTOR_SUCCESSFUL,
    ADD_EXTERNAL_COLLECTOR_FAILED

} from "./Types";
import moment from 'moment';
import { fetchEcecutives } from './SettingsActions';
const XLSX = require('xlsx');

export const customerDetailsChanged = ({prop, value}) => {

    return{
        type: CUSTOMER_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const addExternalCollector = ({ collectorName }) => {
    const collectorID = Math.random().toString(36).substring(6).toUpperCase();
    return(dispatch) => {
        dispatch({ type: ADD_EXTERNAL_COLLECTOR });

        firebase.firestore().collection('externalCollectors').doc(collectorID)
        .set({name: collectorName, collectorID, role: "external collector"}, {merge: true})
        .then(() => {
            dispatch({ type: ADD_EXTERNAL_COLLECTOR_SUCCESSFUL });
            fetchEcecutives();
        })
        .catch(error => {
            console.log('failed to save phone number');
            console.log(error);
            dispatch({ type: ADD_EXTERNAL_COLLECTOR_FAILED });
        });
    }
}

export const deleteCustomer = ({profile, customerProfile}) => {
    return async (dispatch) => {
        dispatch({type: DELETE_CUSTOMER});
        console.log({profile, customerProfile});
        grabCustomerLoans({customerProfile, dispatch});
    }
}

async function grabCustomerLoans({customerProfile, dispatch}){
    let successArray = [];
    try{
        const loansRef =  firebase.firestore().collection("loans").where("customerID", "==", customerProfile.customerID);
        const snapshot = await loansRef.get();
        if(snapshot.size !== 0){
            snapshot.forEach(doc => {
                const loan = doc.data();
                const res = grabLoanTermsForTheLoan({loan, customerProfile});
                successArray.push(res);
            })
        }else{
            successArray.push(null);
        }
    }
    catch(e){
        console.log(e);
        dispatch({type: DELETE_CUSTOMER_FAILED});

    }

    Promise.all(successArray).then(() => {
        deleteCustomerProfile({customerProfile, dispatch});
     });
}

async function grabLoanTermsForTheLoan({loan, customerProfile}){
    let successArray = [];
    try{
        const loansRef =  firebase.firestore().collection("loanTerms").where("loanID", "==", loan.loanID);
        const snapshot = await loansRef.get();
        if(snapshot.size !== 0){
            snapshot.forEach(doc => {
                const loanTerm = doc.data();
                const res = deleteLoanTermsForTheLoan({loanTerm, customerProfile});
                successArray.push(res);
            })
        }else{
            successArray.push(null);
        }

    }
    catch(e){
        console.log(e);
    }

    Promise.all(successArray).then(() => {
        deleteLoan({loan, customerProfile});
     });
}

async function deleteLoanTermsForTheLoan({loanTerm, customerProfile}){
    let successArray = [];
    try{
        const loanTermRef =  firebase.firestore().collection("loanTerms").doc(loanTerm.loanTermID);
        const doc = await loanTermRef.get();
        if(doc.exists){
            const res = loanTermRef.delete();
            console.log('customer deleted on loanterms bucket');
            successArray.push(res);
        }else{
            successArray.push(null);
        }
    }
    catch(e){
        console.log(e);
    }

    Promise.all(successArray).then(() => {
        return ""
     });
}

async function deleteLoan({loan, customerProfile}){
    let successArray = [];
    try{
        const loanRef =  firebase.firestore().collection("loans").doc(loan.loanID);
        const doc = await loanRef.get();
        if(doc.exists){
            const res = loanRef.delete();
            console.log('customer deleted on loan bucket');
            successArray.push(res);
        }else{
            successArray.push(null);
        }
    }
    catch(e){
        console.log(e);
    }

    Promise.all(successArray).then(() => {
        return ""
     });
}

async function deleteCustomerProfile({customerProfile, dispatch}){

    firebase.firestore().collection("users").doc("clients").collection(customerProfile.customerID)
    .get()
    .then((querySnapshot) => {
        if(querySnapshot.size !== 0){
            querySnapshot.forEach((element) => {
                element.ref.delete();
            });
        }

        deleteCustomerBucket({customerProfile, dispatch});
        console.log('customer deleted on customer profile');
        
    }).catch((e) => {
        console.log(e);
    })
}

async function deleteCustomerBucket({customerProfile, dispatch}){
    let successArray = [];
    try{
        const custRef =  firebase.firestore().collection("customerBucket").doc(customerProfile.customerID);
        const doc = await custRef.get();
        if(doc.exists){
            const res = custRef.delete();
            console.log('customer deleted on customer bucket');
            dispatch({type: DELETE_CUSTOMER_SUCCESSFUL});
            successArray.push(res);
        }
    }
    catch(e){
        console.log(e);
        dispatch({type: DELETE_CUSTOMER_FAILED});
    }

    Promise.all(successArray).then(() => {
        return ""
    });
}

export const checkPhoneNumber = (systemInfo) => {
    return async () => {
        try{
            let totalArray = [];
            const loanTermsRef = firebase.firestore().collection('loanTerms');
            const pdcSnapshot = await loanTermsRef.get();
    
            if (pdcSnapshot.size === 0) {
                //return res.status(404);
            } else {
                //loop over individual loan terms
                pdcSnapshot.forEach(doc => {
                    //here is an individual loan term data
                    const data = doc.data();
    
                    //check they are not cleared and don't belong to any old rescheduled loan loanStatus && rescheduleStatus: oldLoan
                    let status = true;
                    if ("loanStatus" in data) {
                        if (data.loanStatus) {
                            //status = false;
                            //check if its a old loan
                            if ("rescheduleStatus" in data) {
                                if (data.rescheduleStatus === "oldLoan") {
                                    status = false;
                                }
                            }
                        } else {
                            //check if its a old loan
                            if ("rescheduleStatus" in data) {
                                if (data.rescheduleStatus === "oldLoan") {
                                    status = false;
                                }
                            }
                        }
                    } else {
                        //check if its a old loan
                        if ("rescheduleStatus" in data) {
                            if (data.rescheduleStatus === "oldLoan") {
                                status = false;
                            }
                        }
                    }
    
                    if (status) {
                        //check that loan is not early liquidated
                        if ("earlyLiquidation" in data) {
                            //do nothing
                        } else {
                            //check if term is within this month
                            //grab due date
                            let seconds;
                            data.dueDate.seconds ? seconds = data.dueDate.seconds : seconds = data.dueDate._seconds;
                            const dueDate = moment.unix(seconds);
    
                            const today = moment();
                            const date1 = moment(dueDate).format("DD/MM/YYYY");
    
                            //find pdc ftm
                            if(dueDate.isSame(today, "month")) {
                                if (data.currency === "usd") {
                                    const exchangeRate = systemInfo.exchangeRate;
                                    //grab the total amount of the loan term
                                    const totalAmount = data.amount;
                                    const convertedTotalAmount = totalAmount * exchangeRate;
                                    data['ov'] = convertedTotalAmount;
                                    data['date'] = date1;
    
                                    totalArray.push(data);
                                } else {
                                    //grab the total amount of the loan
                                    const totalAmount = data.amount;
                                    data['ov'] = totalAmount;
                                    data['date'] = date1;

                                    totalArray.push(data);
                                }
                            }
                        }
                    }
                })

                let empty = [{A: ''}];
                let title = [{A: "EMI THAT HAVE JANUARY 2023 DUE DATE(KENYA)"}];
            
                let table1 = [
                    {
                        A: "NAME",
                        B: "DUE DATE",
                        C: "AMOUNT",
                    }
                ];
            
                totalArray.forEach(data => {
                    table1.push({
                        A: data.customerName,
                        B: data.date,
                        C: data.ov,
                    })  
                })
            
                table1 = (['']).concat(table1);
                const finalData1 = [...empty, ...title, ...table1];
            
                const wb = XLSX.utils.book_new();
            
                const ws1 = XLSX.utils.json_to_sheet(finalData1, {
                    skipHeader: true,
                })
            
                ws1['!cols'] = [
                    {wch:35},
                    {wch:25},
                    {wch:25},
                ];
            
                XLSX.utils.book_append_sheet(wb, ws1, 'January2023EMI');
            
                XLSX.writeFile(wb, "jan2023OD.xlsx");
            }
        }
        catch(e){
            console.log(e);
        }
    }
}



export const updateCustomerDetails = ({ customerID, phoneNumber, customerEmail, customerAddress, customerTin }) => {
    console.log(phoneNumber)
    return(dispatch) => {
        dispatch({ type: ADD_PHONE });

        firebase.firestore().collection('customerBucket').doc(customerID)
        .update({phoneNumber: phoneNumber, customerEmail: customerEmail, customerAddress: customerAddress, customerTin: customerTin})
        .then(() => {
            updateCustomerProfileInfo({customerID, phoneNumber, customerEmail, customerAddress, customerTin, dispatch});
        })
        .catch(error => {
            console.log('failed to save phone number');
            console.log(error);
            dispatch({ type: ADD_PHONE_FAILED });
        });
    }
}


function updateCustomerProfileInfo({ customerID, phoneNumber, customerEmail, customerAddress, customerTin, dispatch}){
    firebase.firestore().collection('users').doc('clients').collection(customerID).doc('public').collection('account').doc('info')
    .update({phoneNumber: phoneNumber, customerEmail: customerEmail, customerAddress: customerAddress, customerTin: customerTin})
    .then(() => {
        message.success("customer details updated successful");
        dispatch({ type: ADD_PHONE_SUCCESSFUL });
    }).catch((e) => {
        console.log(e);
        dispatch({ type: ADD_PHONE_FAILED });
    })
}




export const addNewCustomer = ({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue }) => {

    return(dispatch) => {
        dispatch({ type:CREATE_NEW_CUSTOMER });
        // 1. CREATE CUSTOMER DATABASE
        //check if clients doc is created
        firebase.firestore().collection('users').doc('clients').get()
            .then((doc) => {
                if (doc.exists) {
                    console.log('Added');
                    //client doc exists
                    //create and set customer details
                    addCustomerToDatabase({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue, dispatch })

                } else {
                    //clients doc doesn't exists so create it
                    //create clients doc
                    firebase.firestore().collection('users').doc('clients').set({})
                        .then(() => {
                            //create and set customer details
                            addCustomerToDatabase({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue, dispatch })
                        })
                        .catch(error => {
                            console.log("couldn't create clients path doc");
                            console.log(error);
                        })
                }
            })
            .catch(error => {
                console.log('error getting clients doc');
                console.log(error);
            });
    };
};

function addCustomerToDatabase({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue, dispatch }) {

    //create customer collection with public doc
    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public")
        .set({})
        .then(() => {
            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("account").doc("info")
                .set({ customerName, customerID, totalCashPaid, totalChequePaid, totalOutstanding, totalOverdue })
                .then(() => {
                    //2. SAVE CUSTOMER TO CUSTOMER BUCKET
                    firebase.firestore().collection("customerBucket").doc(customerID)
                        .set({ customerName, customerID })
                        .then(() => {
                            //client creation successful
                            dispatch({ type: CREATE_NEW_CUSTOMER_SUCCESSFUL});
                        })
                        .catch((error) => {
                            console.log('add customer to customer bucket failed');
                            console.log(error);
                            dispatch({ type: CREATE_NEW_CUSTOMER_FAILED, payload: `Failed to add ${customerName} to customer bucket` })
                        });
                }).catch((error) => {
                console.log("customer path creation failed");
                console.log(error);
                dispatch({ type: CREATE_NEW_CUSTOMER_FAILED, payload: `Failed to create ${customerName}'s account` })
            });
        })

}

export const searchExistingCustomers = (value) => {

    //check if value is not a number (customer name)
    let searchProperty;
    let searchValue;
    if (isNaN(value)) {
        //its a customer name
        //title case the value string
        //searchValue = value.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
        searchValue = value.toUpperCase();
        searchProperty = 'customerName'
    } else {
        searchValue = value;
        searchProperty = 'customerID';
    }

    return(dispatch) => {
        firebase.firestore().collection("customerBucket").where(searchProperty, "==", searchValue)
            .get()
            .then(function(querySnapshot) {

                //check if query has no result
                if (querySnapshot.size !== 0) {
                    querySnapshot.forEach(function(doc) {
                        // doc.data() is never undefined for query doc snapshots
                        const data = doc.data();

                        dispatch({
                            type: SEARCH_EXISTING_CUSTOMER_SUCCESSFUL,
                            payload: data
                        })

                    });
                } else {
                    //no record for query
                    dispatch({
                        type: SEARCH_EXISTING_CUSTOMER_NO_RECORD,
                    })
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
                dispatch({
                    type: SEARCH_EXISTING_CUSTOMER_FAILED,
                })
            });
    }

};

export const pushToCustomerProfile = ({ history, customerID }) => {

    return(dispatch) => {
        //fetch customer profile
        dispatch({ type: FETCH_CUSTOMER_PROFILE});

        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("account").doc("info")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    const data = doc.data();

                    dispatch({
                        type: FETCH_CUSTOMER_PROFILE_SUCCESSFUL,
                        payload: data
                    });

                    //push to customer profile page
                    history.push('/customer-page');

                } else {
                    console.log("account doc doesn't exists");
                    dispatch({ type: FETCH_CUSTOMER_PROFILE_FAILED })
                }
            })

    }
};

export const clearExistingCustomer = () => {
    return {
        type: CLEAR_EXISTING_CUSTOMER
    }
};