import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import {BeatLoader} from "react-spinners";
import {message, Button, Modal} from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {List} from 'react-virtualized';
//import { FixedSizeList as List } from 'react-window';
import ReactList from 'react-list';
import arraySort from "array-sort";

//actions imports
import { fetchBouncedCheques, fetchOverdueTerms, dateRangeChanged, fetchRangedBouncedCheques, fetchRangedOverdueTerms ,clearLegalRepoFilter, filterLegalRepoStatusChange,
    showUnAssignedLoans, odAgingReportDateChanged, refreshMasterList, showNotices, fetchDemandNotices, fetchAllBeforeTerms, eraseAlCollectors } from "../../actions/MasterListNewActions";
import { generateCreateMasterListReport } from "../../actions/GeneratedReportsActions";
import { createMasterListReport } from "../../actions/ReportsActions";
import { exeOfficerChange, resetExecutive } from "../../actions/MasterListActions";
import { projectArray } from "../../env/PROJECTSCONFIGURATIONS";

//components imports
import { DateRange } from "../common/DateRange";
import UnAssignedCheques from "./subCollections/UnAssignedLoans";
//import ClientCard from "./cards/ClientCard";
import SelectLegalRepoStatus from '../common/SelectLegalRepoStatus';
import FilterBucketCard from './cards/FilterBucketCard';
import {PickDate} from "../common/PickDate";
import moment from "moment";
import BouncedChequeCard from "./cards/BouncedChequeCard";
import AddExternalCollector from '../common/AddExternalCollector';
import SelectExecative from '../common/SelectExecative';


class MasterList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            overdueTerms: this.props.overdueTerms,
            loading: this.props.loading,
            profile: this.props.profile,

            reportDate: this.props.reportDate,
            dateRange: this.props.dateRange,
            fetched: this.props.fetched,

            systemInfo: this.props.systemInfo,

            filteredLegalRepoStatus: this.props.filteredLegalRepoStatus,
            filterBucket: this.props.filterBucket,
            bouncedCheques: this.props.bouncedCheques,

            showUnAssignedLoansCard:this.props.showUnAssignedLoansCard,
            assigneeSaved:this.props.assigneeSaved,
            resetCollectorLoading: this.props.resetCollectorLoading,
            collectorDeleted: this.props.collectorDeleted,
            fetchRangedBouncedChequesLoading: this.props.fetchRangedBouncedChequesLoading,
            resetexecutiveLoading: this.props.resetexecutiveLoading,
            exeOfficer: this.props.exeOfficer,
            masterListReportCreated: this.props.masterListReportCreated,
            masterListReportEmpty: this.props.masterListReportEmpty,
            masterListReportFailed: this.props.masterListReportFailed,
            masterListReportLoading: this.props.masterListReportLoading,
            allReportLoading: this.props.allReportLoading,

            currentLoan: this.props.currentLoan,

            localCurrency: "TSH",

        };

        this.onDateRangeChange = this.onDateRangeChange.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onFilterLegalRepoStatus = this.onFilterLegalRepoStatus.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
        this.onCreateMasterListReport = this.onCreateMasterListReport.bind(this);
        this.onReportDate = this.onReportDate.bind(this);
        this.onSelectExecative = this.onSelectExecative.bind(this);
        this.onRefreshButton = this.onRefreshButton.bind(this);
        this.displayCurrency = this.displayCurrency.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
        this.onShowNotices = this.onShowNotices.bind(this);
        this.onShowUnAssignedLoans = this.onShowUnAssignedLoans.bind(this);

    }

    componentWillMount() {
        this.props.fetchBouncedCheques();
        this.props.fetchOverdueTerms();
        this.props.fetchAllBeforeTerms();
        this.checkCurrencyFormat();
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.bouncedCheques !== this.props.bouncedCheques) {
            this.setState({ bouncedCheques: nextProps.bouncedCheques })
        }

        if (nextProps.overdueTerms !== this.props.overdueTerms) {
            this.setState({ overdueTerms: nextProps.overdueTerms })
        }

        if (nextProps.allReportLoading !== this.props.allReportLoading) {
            this.setState({ allReportLoading: nextProps.allReportLoading })
        }

        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading })
        }

        if (nextProps.resetCollectorLoading !== this.props.resetCollectorLoading) {
            this.setState({ resetCollectorLoading: nextProps.resetCollectorLoading })
        }

        if (nextProps.exeOfficer !== this.props.exeOfficer) {
            this.setState({ exeOfficer: nextProps.exeOfficer })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.reportDate !== this.props.reportDate) {
            this.setState({ reportDate: nextProps.reportDate })
        }

        if (nextProps.dateRange !== this.props.dateRange) {
            this.setState({ dateRange: nextProps.dateRange })
        }

        if (nextProps.filteredLegalRepoStatus !== this.props.filteredLegalRepoStatus) {
            this.setState({ filteredLegalRepoStatus: nextProps.filteredLegalRepoStatus })
        }

        if (nextProps.filterBucket !== this.props.filterBucket) {
            this.setState({ filterBucket: nextProps.filterBucket })
        }

        if (nextProps.showUnAssignedLoansCard !== this.props.showUnAssignedLoansCard) {
            this.setState({ showUnAssignedLoansCard: nextProps.showUnAssignedLoansCard })
        }

        if (nextProps.assigneeSaved !== this.props.assigneeSaved) {
            this.setState({ assigneeSaved: nextProps.assigneeSaved })
        }

        if (nextProps.collectorDeleted !== this.props.collectorDeleted) {
            this.setState({ collectorDeleted: nextProps.collectorDeleted })
        }

        if (nextProps.resetexecutiveLoading !== this.props.resetexecutiveLoading) {
            this.setState({ resetexecutiveLoading: nextProps.resetexecutiveLoading })
        }

        if (nextProps.fetchRangedBouncedChequesLoading !== this.props.fetchRangedBouncedChequesLoading) {
            this.setState({ fetchRangedBouncedChequesLoading: nextProps.fetchRangedBouncedChequesLoading })
        }

        if (nextProps.fetched !== this.props.fetched) {
            this.setState({ fetched: nextProps.fetched })
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo })
        }

        if (nextProps.masterListReportCreated !== this.props.masterListReportCreated) {
            this.setState({ masterListReportCreated: nextProps.masterListReportCreated })
        }

        if (nextProps.masterListReportEmpty !== this.props.masterListReportEmpty) {
            this.setState({ masterListReportEmpty: nextProps.masterListReportEmpty })
        }

        if (nextProps.masterListReportFailed !== this.props.masterListReportFailed) {
            this.setState({ masterListReportFailed: nextProps.masterListReportFailed })
        }

        if (nextProps.masterListReportLoading !== this.props.masterListReportLoading) {
            this.setState({ masterListReportLoading: nextProps.masterListReportLoading })
        }
        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState({ currentLoan: nextProps.currentLoan })
        }

    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onDateRangeChange(date){
        this.props.dateRangeChanged(date);
    }

    onReportDate(value){
        this.props.odAgingReportDateChanged({prop: 'reportDate', value})
    }

    onRefreshButton() {
        //call a custom function that performs penal interest calculation first then fetches the bounced cheques when it is complete
        this.props.refreshMasterList();
    }

    onShowUnAssignedLoans() {
        this.props.refreshMasterList();
        this.props.showUnAssignedLoans();
    }

    renderButton() {
        const {fetchRangedBouncedChequesLoading} = this.state;

        if (fetchRangedBouncedChequesLoading) {
            return(
              <div><BeatLoader/></div>
            );
        } else {
            return (
                <div>
                    <Button onClick={() => this.onFetchRangedBouncedCheques()}>Fetch Loans</Button>
                </div>
            );
        }
    }

    renderRefreshButton() {
        const {loading} = this.state;

        if (loading) {
            return(
                <div><BeatLoader/></div>
            );
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon={'sync-alt'} className={'refreshIcon'} onClick={() => this.onRefreshButton() }/>
                </div>
            );
        }
    }

    onFetchRangedBouncedCheques() {
        //extract state
        const { dateRange } = this.state;

        if (dateRange) {
            //show clear button
            // this.setState({ fetched: !this.state.fetched });
            this.props.fetchRangedBouncedCheques(dateRange);
            this.props.fetchRangedOverdueTerms(dateRange);
        } else {
            message.warn("Please select a date range");
        }
    }

    onClear() {
        this.setState({ fetched: !this.state.fetched });
        this.props.fetchBouncedCheques();
        this.props.fetchOverdueTerms();
        this.props.fetchAllBeforeTerms();
    }

    onFilterLegalRepoStatus(value) {
        this.props.filterLegalRepoStatusChange(value);
    }

    onSelectExecative(value) {
        this.props.exeOfficerChange(value);
    }

    onCreateMasterListReport() {
        //extract state
        const { bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, reportDate, profile } = this.state;
        //
        if (reportDate) {
            this.props.generateCreateMasterListReport({ reportDate, filteredLegalRepoStatus, filterBucket, systemInfo });
        } else {
            this.props.createMasterListReport({ bouncedCheques, filteredLegalRepoStatus, filterBucket, systemInfo, profile });
        }
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value) {
        //extract state
        const { profile, currentLoan, systemInfo, localCurrency } = this.state;

        console.log({ localCurrency });

        let displayValue;
        //check if currency is activated in profile
        let currency = "USD";

        //check if payment object is not empty
        if (!(_.isEmpty(currentLoan))) {
            const loanCurrency = currentLoan.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        } else {
            if (value) {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    renderMessage() {
        const {assigneeSaved} = this.state;

        if (assigneeSaved) {
            message.success("Collector is assigned to a selected loan, kindly refresh to see changes");
        } else {
            return null
        }
    }

    renderDeleteMessage() {
            const {collectorDeleted} = this.state;

            if (collectorDeleted) {
                message.success('Collector is deleted, kindly refresh to see changes');
            } else {
                return null
            }
        }

    renderSuccessMessage() {
        const {reportGenerated} = this.state;

        if (reportGenerated) {
            return <p>{message.success("Report is successfully generated")}</p>
        } else {
            return null
        }
    }

    renderFailedMessage() {
        const {reportGenerateFailed} = this.state;

        if (reportGenerateFailed) {
            return <p>{message.error("Report is not generated")}</p>
        } else {
            return null
        }
    }

    renderEmptyMessage() {
        const {emptyReport} = this.state;

        if (emptyReport) {
            return <p>{message.info("Sorry! No report to generate")}</p>
        } else {
            return null
        }
    }

    onShowNotices(term) {
        const loanID = term.loanID;
        this.props.showNotices(loanID);
        this.props.fetchDemandNotices(loanID);
    }


    renderCheques() {
        //extract state
        let { loading, overdueTerms, filteredLegalRepoStatus, filterBucket, exeOfficer } = this.state;
            if(exeOfficer){
                overdueTerms = overdueTerms.filter(cheque => {
                    if ("assignee" in cheque) {
                        if(cheque.assignee.name === exeOfficer){
                            return cheque;
                        }
                    }
                })
            }
            //data is loaded
            //check bouncedCheques is not empty
            if (overdueTerms.length !== 0) {

                //calculate total overdue of all terms
                let legalRepoFilteredCheques = [];

                if(filteredLegalRepoStatus){
                    legalRepoFilteredCheques = overdueTerms.filter(cheque => cheque.legalRepoStatus === filteredLegalRepoStatus );
                } else {
                    legalRepoFilteredCheques = overdueTerms.map(cheque => cheque);
                }

                //create filtered loans bucket
                let bucketFilteredCheques = [];

                if (legalRepoFilteredCheques.length !== 0) {
                    legalRepoFilteredCheques.map(cheque => {
                        //get the cheque bucket
                        let seconds;
                        if ("transactionDate" in cheque) {
                            cheque.transactionDate.seconds ? seconds = cheque.transactionDate.seconds : seconds = cheque.transactionDate._seconds;
                        } else {
                            cheque.dueDate.seconds ? seconds = cheque.dueDate.seconds : seconds = cheque.dueDate._seconds;
                        }

                        const dueDate = moment.unix(seconds);
                        const now = moment();

                        const fromNow = now.diff(dueDate, 'days');

                        const bucket = Math.ceil(fromNow/30);

                        switch (filterBucket) {
                            case "1":
                                if (bucket === 1) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "2":
                                if (bucket === 2) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "3":
                                if (bucket === 3) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "4":
                                if (bucket === 4) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "5":
                                if (bucket === 5) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "6":
                                if (bucket === 6) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "7":
                                if (bucket === 7) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "8":
                                if (bucket === 8) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "9":
                                if (bucket === 9) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "10":
                                if (bucket === 10) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "11":
                                if (bucket === 11) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "12":
                                if (bucket === 12) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            case "13":
                                if (bucket === 13) {
                                    bucketFilteredCheques.push(cheque)
                                }
                                break;
                            default:
                                bucketFilteredCheques.push(cheque);
                                break;
                        }
                    })
                }

                const sortedCheques = arraySort(bucketFilteredCheques, 'customerName');

                //react list
                return (
                    // style={{overflow: 'auto', maxHeight: 800}}
                    <div >
                        <ReactList
                            itemRenderer={(index, key) => {
                                const cheque = sortedCheques[index];
                                const loanLoanTerms = overdueTerms.filter(term => term.loanID === cheque.loanID);
                                return <div key={cheque.loanTermID}><BouncedChequeCard cheque={cheque} key={cheque.loanTermID} loanLoanTerms={loanLoanTerms} /></div>;
                            }}
                            length={sortedCheques.length}
                            type='uniform'
                        />
                    </div>
                );

                //calculate total overdue
                //const total = overdueArray.reduce((a, b) => a + b, 0);

                // const chequeArray = bucketFilteredCheques.map(cheque => {
                //     return {
                //         key: cheque.loanTermID,
                //         cheque: <BouncedChequeCard cheque={cheque} key={cheque.loanTermID} />
                //     }
                // });
                //
                // function rowRenderer({
                //                          key, // Unique key within array of rows
                //                          index, // Index of row within collection
                //                          isScrolling, // The List is currently being scrolled
                //                          isVisible, // This row is visible within the List (eg it is not an overscanned row)
                //                          style, // Style object to be applied to row (to position it)
                //                      }) {
                //
                //
                //     const chequeObj = chequeArray[index];
                //     // If row content is complex, consider rendering a light-weight placeholder while scrolling.
                //     const content = isScrolling ? '...' : chequeObj.cheque;
                //
                //     return (
                //         <div key={chequeObj.key}>
                //             {content}
                //         </div>
                //     );
                // }

                //react virtualized
                // return (
                //     <div>
                //         <List
                //             width={1600}
                //             height={1000}
                //             rowCount={chequeArray.length}
                //             rowHeight={45}
                //             rowRenderer={rowRenderer}
                //             className={""}
                //         />
                //     </div>
                // );

                //react window
                // return (
                //     <List
                //         height={600}
                //         itemData={bucketFilteredCheques}
                //         itemkey={(index, data) => {
                //             const item = data[index];
                //             // Return a value that uniquely identifies this item.
                //             // Typically this will be a UID of some sort.
                //             return item.loanTermID;
                //         }}
                //         itemCount={bucketFilteredCheques.length}
                //         itemSize={40}
                //         width="100%"
                //     >
                //         {ItemRenderer}
                //     </List>
                // )

                //TODO: This is the OLD render method that had the performance issue
                //clients is an object of clients and their cheques
                // return (
                //     <div>
                //         {
                //             _.map(bouncedCheques, client => {
                //
                //                 return (
                //                     <div key={client.customerID}>
                //                         <ClientCard client={client}/>
                //                     </div>
                //                 );
                //             })
                //         }
                //     </div>
                // );


            } else {
                if (loading) {
                    //fetch is on
                    return <BeatLoader/>
                } else {
                    //bounced cheques not loaded yet
                    return <p className={"userAdminsEmptyWarning"}>There are no overdue loans</p>
                }
            }
        }

    onResetCollectors(){
        const { overdueTerms } = this.state;

        let collectorsArray = [];
        const legalRepoFilteredCheques = overdueTerms.map(cheque => cheque);

        if (legalRepoFilteredCheques.length !== 0) {
            legalRepoFilteredCheques.map(cheque => {
                if('assignee' in cheque){
                    collectorsArray.push(cheque);
                }            
            })
        }

        if (collectorsArray.length !== 0) {
            this.props.eraseAlCollectors({ collectorsArray });
        }
    }

    renderResetCollectorsButton(){
        if (this.state.resetCollectorLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button onClick={() => this.onResetCollectors()}>Reset Collectors</Button>
                </div>
            );
        }
    }

    renderResetExecutiveButton(){
        if (this.state.resetexecutiveLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            return (
                <div>
                    <Button onClick={() => this.props.resetExecutive()}>Clear Filtered Collector</Button>
                </div>
            );
        }
    }

    checkUserAccess() {
        //extract state
        const { profile, dateRange, fetched, filteredLegalRepoStatus, showUnAssignedLoansCard } = this.state;

        const access = profile.userAccess.some(access => access === "masterListView");

        if (access) {
            return(
                <div className={"masterlistParentBox"}>
                    <div>
                        <h3>Overdue Debtor Aging</h3>
                    </div>

                    <div style={{textAlign: 'right', padding: '0 0.8rem 0.2rem 0'}}>
                        { this.renderRefreshButton() }
                    </div>

                    <div className={"dateRangeLoanStatusBox"}>
                        <div>
                            <div className={"masterListDateChequeBox"}>
                                <DateRange
                                    label={'Date Range'}
                                    onChange={this.onDateRangeChange}
                                    value={dateRange}
                                />
                            </div>

                            <div className={"fetchChequeButtonBox"}>
                                {this.renderButton()}
                                {
                                    fetched ?
                                        <Button onClick={() => this.onClear()}>Clear</Button> : null
                                }
                            </div>
                        </div>

                        <div>
                            <div className={"filterLegalRepoStatus"}>
                                <SelectLegalRepoStatus
                                    onChange={(value) => this.onFilterLegalRepoStatus(value)}
                                    label={"Filter loan status"}
                                    value={filteredLegalRepoStatus}
                                />
                            </div>
                            
                            <div className={"filterLegalRepoStatusButtonBox"}>
                                <Button onClick={() => this.props.clearLegalRepoFilter()}>Clear Filters</Button>
                            </div>
                        </div>

                        <div className={"filterLegalRepoStatus"}>
                            <div className={"filterLegalRepoStatusButtonBox"}>
                                <Button type="default" onClick={() => this.onShowUnAssignedLoans() }>
                                    View Un-Assigned Loans
                                </Button>
                                <UnAssignedCheques
                                   visible={showUnAssignedLoansCard}
                                />
                            </div>
                            <div className={"filterLegalRepoStatusButtonBox"}>
                                <FilterBucketCard />
                            </div>

                            <div  className={"filterLegalRepoStatusButtonBox"}>
                                <Button type="default" onClick={this.showModal}>Create Report</Button>
                                <Modal
                                    title="Create OD Aging Report"
                                    visible={this.state.visible}
                                    onOk={this.handleOk}
                                    onCancel={this.handleCancel}
                                    footer={null}
                                >
                                    <div>
                                        <div>
                                            <PickDate
                                                label={"Select Date"}
                                                onChange={this.onReportDate}
                                                value={this.state.reportDate}
                                            />
                                        </div>
                                        {
                                            this.state.allReportLoading ?
                                            <div>
                                                <BeatLoader/>
                                            </div> : 
                                            <div className={"filterLegalRepoStatusButtonBox"}>
                                                <Button onClick={() => this.onCreateMasterListReport()}>Create Report</Button>
                                            </div>
                                        }
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <div className={"filterLegalRepoStatusButtonBox"}>
                            {this.renderResetCollectorsButton()}
                        </div>
                        <div className={"filterLegalRepoStatusButtonBox"}>
                            <AddExternalCollector />
                        </div>

                        <div className={"filterLegalRepoStatus"}>
                            <SelectExecative
                                onChange={(value) => this.onSelectExecative(value)}
                                label={"Filter Collector"}
                                value={this.state.exeOfficer}
                            />
                            <div className={"filterLegalRepoStatusButtonBox"}>
                                {
                                    this.state.exeOfficer ?
                                    this.renderResetExecutiveButton() : null
                                }
                            </div>
                        </div>
                    </div>

                    <h4>Overdue Terms</h4>
                    
                    <div className={"masterlistOverdueLoansBox"}>
                        <div className={"bouncedChequeHeaders"}>
                            <div className={"stickyDivHeader1"}><p>Transaction Date</p></div>
                            <div className={"stickyDivHeader2"}><p>Name</p></div>
                            <div className={"stickyDivHeader3"}><p>Collector</p></div>
                            <div className={"stickyDivHeader4"}><p>Sales Officer</p></div>
                            <div className={"stickyDivHeader5"}><p>Cheque #</p></div>
                            <div><p>Debit Amount</p></div>
                            <div><p>Bounced Reason</p></div>
                            <div><p>Others Specify</p></div>
                            <div><p>Overdue</p></div>
                            <div><p>Character</p></div>
                            <div><p>Action</p></div>
                            <div><p>Status</p></div>
                            <div><p>Comment</p></div>
                            <div><p>Loan Statement</p></div>
                            <div><p>Days</p></div>
                            <div><p>Age</p></div>
                            <div><p>Profile</p></div>
                            <div><p>Truck</p></div>
                            <div><p>Industry</p></div>
                            <div><p>Model</p></div>
                            <div><p>Segment</p></div>
                            <div><p>OD Reporting USD</p></div>
                            <div><p>Due Date</p></div>
                            <div><p>1st Demand Notice</p></div>
                            <div><p>2nd Demand Notice</p></div>
                        </div>

                        <div className={"clientChequesBox"}>
                            { this.renderCheques() }
                        </div>

                        <div>
                            {this.renderMessage()}
                            {this.renderDeleteMessage()}
                        </div>
                        <div>
                            {this.renderSuccessMessage()}
                            {this.renderFailedMessage()}
                            {this.renderEmptyMessage()}
                        </div>
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {

        return (
            <div className={"bouncedCheques"}>
                { this.checkUserAccess() }
            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { bouncedCheques, loading, reportDate, dateRange, filteredLegalRepoStatus, filterBucket, showUnAssignedLoansCard, assigneeSaved,
        collectorDeleted, fetchRangedBouncedChequesLoading, fetched, overdueTerms, resetCollectorLoading } = state.masterListNew;
    const {masterListReportCreated, masterListReportEmpty, masterListReportFailed, masterListReportLoading, allReportLoading } = state.reports;
    const { profile } = state.profile;
    const { systemInfo } = state.app;
    const { currentLoan } = state.loans;
    const { exeOfficer } = state.masterList;

    //return object with state properties
    return {
        reportDate,
        dateRange,
        bouncedCheques,
        filteredLegalRepoStatus,
        filterBucket,
        overdueTerms,
        loading,
        profile,
        showUnAssignedLoansCard,
        assigneeSaved,
        collectorDeleted,
        fetchRangedBouncedChequesLoading,
        fetched,
        systemInfo,
        currentLoan,
        resetCollectorLoading,

        masterListReportCreated,
        masterListReportEmpty,
        masterListReportFailed,
        masterListReportLoading,
        allReportLoading,
        exeOfficer
    }
};

export default connect(mapStateToProps, { fetchBouncedCheques, dateRangeChanged, fetchRangedBouncedCheques,
    fetchRangedOverdueTerms, filterLegalRepoStatusChange, clearLegalRepoFilter, exeOfficerChange, resetExecutive,
    showUnAssignedLoans, createMasterListReport, odAgingReportDateChanged, generateCreateMasterListReport, refreshMasterList,
    showNotices, fetchDemandNotices, fetchOverdueTerms, fetchAllBeforeTerms, eraseAlCollectors })(MasterList);

//react window
// class ItemRenderer extends React.PureComponent {
//     render() {
//         // Access the items array using the "data" prop:
//         const item = this.props.data[this.props.index];
//
//         return (
//             <BouncedChequeCard cheque={item}/>
//         );
//     }
// }