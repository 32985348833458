import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import * as firebase from 'firebase';
import { generalConfig } from "./env/PROJECTSCONFIGURATIONS";
import _ from 'lodash';
import {BeatLoader} from "react-spinners";
import {isMobile} from 'react-device-detect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faHome,
  faCheckCircle,
  faChevronRight,
  faSignOutAlt,
  faCaretRight,
  faFileExcel,
  faThLarge,
  faInfoCircle,
  faFile,
  faExclamationCircle,
  faLayerGroup,
  faChartLine, 
  faObjectGroup, 
  faBatteryEmpty, 
  faPause, 
  faUserAlt,
  faSlidersH,
    faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

//components import
import IsMobile from "./components/IsMobile";
import Main from "./components/Main";
import Landing from "./components/Landing";

//css import
import './App.css';
import './MacOS.css';


//FontAwesome icon library imports
library.add(
  fab,
  faHome, 
  faCheckCircle,
  faChevronRight,
  faSignOutAlt,
  faCaretRight,
  faFileExcel,
  faThLarge,
  faInfoCircle,
  faFile,
  faExclamationCircle,
  faLayerGroup,
  faChartLine,
  faObjectGroup,
  faBatteryEmpty,
  faPause,
  faUserAlt,
  faSlidersH,
    faSyncAlt
);


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
    }

    this.renderComponent = this.renderComponent.bind(this);
  }

  componentWillMount() {
      //check if there is a project config in cache
      // Retrieve the object from storage
      const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');
      const previousConfig = JSON.parse(retrievedObject);

      if (!(_.isEmpty(previousConfig))) {
          //initialize project with previous config file
          firebase.initializeApp(previousConfig);

          //check if a user has logged in
          firebase.auth().onAuthStateChanged((user) => {
              if(user) {
                  // user has logged in
                  this.setState({ user: true });
                  //this.fetchFunctions();
              } else {
                  this.setState({ user: false });
              }
          });
      } else {
          //initialize general project
          // no project has been initialized
          // Initialize Firebase
          firebase.initializeApp(generalConfig.config);
          console.log("general project is initialized");

          //check if a user has logged in
          //user can't be in main app
          firebase.auth().onAuthStateChanged((user) => {
              if(user) {
                  // user has logged in
                  console.log("user is present from will mount");
                  this.setState({ user: false });
                  // this.setState({ user: true });
                  // this.fetchFunctions();
              } else {
                  console.log("user is NOT present from will mount");
                  this.setState({ user: false });
              }
          });
      }
  }

  componentDidMount() {

    // check if user is logged in

      firebase.auth().onAuthStateChanged((user) => {
          if (user) {
              // user is logged in
              this.setState({ user: true })
          } else {
              //is not logged in
              this.setState({ user: false })
          }
      })

  }

  renderContent() {
    if (isMobile) {
      return (
          <div>
           <IsMobile/>
          </div>
      );
    } else {
      return (
          <div>
            { this.renderComponent() }
          </div>
      );
    }
  }

  renderComponent() {
    switch (this.state.user) {
      case true:
        return (
            <div>
              <Main/>
            </div>
        );
      case false:
        return(
            <div>
               <Landing/>
            </div>
        );
      default:
        return (
            <div>
              <BeatLoader/>
            </div>
        );
    }
  }


  render() {

    return (
        <div>
          <BrowserRouter>
            { this.renderContent() }
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
