import {
    CLEAR_MASTER_FILE_MESSAGES,
    UPLOAD_MASTER_FILE,
    UPLOAD_MASTER_FILE_FAILED,
    UPLOAD_MASTER_FILE_SUCCESSFUL
} from "../actions/Types";

const INITIAL_STATE = {

    selectedFile: {},
    loading: false,
    masterFileUploaded: false,
    masterFileUploadFailed: false
};

const MasterFileReducer = ( state = INITIAL_STATE, action ) => {

    switch (action.type) {
        case UPLOAD_MASTER_FILE:
            return { ...state, loading: true };
        case UPLOAD_MASTER_FILE_SUCCESSFUL:
            return { ...state, masterFileUploaded: true, masterFileUploadFailed: false, loading: false, selectedFile: {} };
        case UPLOAD_MASTER_FILE_FAILED:
            return { ...state, masterFileUploaded: true, masterFileUploadFailed: false, loading: false, selectedFile: {} };
        case CLEAR_MASTER_FILE_MESSAGES:
            return { ...state, masterFileUploaded: false, masterFileUploadFailed: false, loading: false, selectedFile: {} };
        default:
            return state;
    }
};

export default MasterFileReducer;