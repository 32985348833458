import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import _ from "lodash";


//action import


class ChooseCustomerLoan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fetchedCustomerLoans: this.props.fetchedCustomerLoans,
            currentLoanTerms: this.props.currentLoanTerms,
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.fetchedCustomerLoans !== this.props.fetchedCustomerLoans) {
            this.setState ({fetchedCustomerLoans: nextProps.fetchedCustomerLoans})
        }

        if (nextProps.currentLoanTerms !== this.props.currentLoanTerms) {
            this.setState ({currentLoanTerms: nextProps.currentLoanTerms})
        }
    }


    render() {

        const loans = _.map(this.state.fetchedCustomerLoans, term => {
            return term
        });

        const { Option } = Select;

        return (
            <div>
                <div className={"inputLabel"}>
                    <p>
                        { this.props.label }
                    </p>
                </div>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >

                    {
                        loans.map(loan => {
                            const loanID = loan.loanID;
                            return (

                                <Option value={JSON.stringify(loan)} key={loan.loanID}>{loanID}</Option>
                            );
                        })
                    }
                </Select>

            </div>
        );
    }
}

//call method to transform redux state to component props
const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const { currentLoanTerms, fetchedCustomerLoans } = state.unidentified;

    //return object with state properties
    return {
        currentLoanTerms,
        fetchedCustomerLoans
    };
};

export default connect(mapStateToProps, { })(ChooseCustomerLoan);
