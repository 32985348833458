import React from "react";
import { connect } from "react-redux";
import {BeatLoader} from "react-spinners";
import _ from "lodash";
import {message} from "antd";

//action imports
import {heldChequeStatusChange, updateHeldChequeStatus} from "../../../actions/HeldActions";

//common imports
import {Button} from "../../common/Button";

//component imports
import ChooseChequeStatus from "../subComponents/ChooseChequeStatus";

class HeldChequeCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cheque: this.props.cheque,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            localCurrency: this.props.localCurrency,
            date: this.props.date,
            chequeStatus: this.props.chequeStatus,
            updateLoading: this.props.updateLoading,
        };

        this.onHeldChequeStatusChange = this.onHeldChequeStatusChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props !== nextProps) {
            this.setState({
                cheque: nextProps.cheque,
                profile: nextProps.profile,
                systemInfo: nextProps.systemInfo,
                localCurrency: nextProps.localCurrency,
                date: nextProps.date,
                chequeStatus: nextProps.chequeStatus,
                updateLoading: nextProps.updateLoading,
            });
        }
    }

    onHeldChequeStatusChange(value) {
        // this.props.heldChequeStatusChange(value);
        this.setState({ chequeStatus: value });
    }

    onUpdateStatus(cheque) {

        const { chequeStatus } = this.state;

        if (chequeStatus !== "held") {

            const previousChequeStatus = cheque.chequeStatus;
            const customerID = cheque.customerID;
            const loanID = cheque.loanID;
            const loanTermID = cheque.loanTermID;

            let paidAmount = cheque.paidAmount;
            //check if check status is cleared
            if (chequeStatus === "cleared"){
                paidAmount = cheque.amount;
            }

            this.props.updateHeldChequeStatus({ previousChequeStatus, chequeStatus, paidAmount, customerID, loanID, loanTermID })

        } else {
            message.warning("Cheque status is already held, please select another status category")
        }
    }

    displayCurrency(value, cheque) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency;

        //check if cheque object is not empty
        if (!(_.isEmpty(cheque))) {
            const loanCurrency = cheque.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <p>{formatter.format(displayValue)}</p>;
    }

    render() {
        //extract state
        const { cheque, date } = this.state;

        return(
            <div key={cheque.loanTermID} className={"heldChequesValues"}>

                <div><p>{cheque.customerName}</p></div>
                <div>{this.displayCurrency(cheque.amount, cheque)}</div>
                <div><p>{cheque.chequeName}</p></div>
                <div><p>{cheque.bankName}</p></div>
                <div><p>{date}</p></div>

                <div className={"chequeStatusBox"}>
                    <ChooseChequeStatus
                        onChange={this.onHeldChequeStatusChange}
                        value={this.state.chequeStatus}
                    />
                </div>
                <div>
                    {
                        this.state.updateLoading ?
                            <BeatLoader /> :
                            <div className={"updateHeldStatusButtonBox"}>
                                <Button
                                    text={'Update Status'}
                                    onPress={() => this.onUpdateStatus(cheque)}
                                />
                            </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(null, { heldChequeStatusChange, updateHeldChequeStatus })(HeldChequeCard);