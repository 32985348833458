import * as firebase from "firebase";
import moment from "moment";
import _ from "lodash";

//Types imports
import {
    HELD_DATE_CHANGED,
    FETCH_HELD_CHEQUES,
    FETCH_HELD_CHEQUES_FAILED,
    FETCH_HELD_CHEQUES_SUCCESSFUL,
    HELD_STATUS_CHANGED,
    UPDATE_HELD_CHEQUES_STATUS,
    UPDATE_HELD_CHEQUES_STATUS_FAILED,
    UPDATE_HELD_CHEQUES_STATUS_SUCCESSFUL,
    FETCH_ALL_HELD_CHEQUES,
    FETCH_ALL_HELD_CHEQUES_SUCCESSFUL,
    FETCH_ALL_HELD_CHEQUES_FAILED,
    CLEAR_UPDATE_HELD_CHEQUE_MESSAGE,
    CLEAR_HELD_CHEQUE_SELECTED_DATE,
    COMPUTE_MODULO_AND_CURRENT_PAID, COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL, COMPUTE_MODULO_AND_CURRENT_PAID_FAILED
} from "./Types";

//property declarations
const retrievedProject = localStorage.getItem('afclInitializedProject');
let project = {};
if (retrievedProject) {
    project = JSON.parse(retrievedProject);
}

export const heldDateChanged = (date) => {
    return {
        type: HELD_DATE_CHANGED,
        payload: date,
    }
};

export const heldChequeStatusChange = (value) => {

    return {
        type: HELD_STATUS_CHANGED,
        payload: value,
    }
};

export const clearSelectedDate = () => {
    return {
        type: CLEAR_HELD_CHEQUE_SELECTED_DATE,
    }
};

export const fetchHeldCheques = (date) => {

    return(dispatch) => {

        dispatch({ type: FETCH_HELD_CHEQUES });
        firebase.firestore().collection("loanTerms").where("chequeStatus", "==", "held")
            .onSnapshot(function(querySnapshot) {
                //create held cheques container
                let heldCheques = {};

                //loop over all held cheques
                querySnapshot.forEach(function(doc) {

                    //grab cheque
                    const cheque = doc.data();

                    let status = true;
                    //check that cheque is not from early liquidated loan
                    if ("earlyLiquidation" in cheque) {
                        status = false;
                    } else {
                        //check that cheque is not from an old loan
                        if ("rescheduleStatus" in cheque) {
                            if (cheque.rescheduleStatus === "oldLoan") {
                                status = false;
                            }
                        }
                    }

                    if (status) {
                        //grab the new held cheque date
                        let seconds;
                        cheque.newDueDate.seconds ? seconds = cheque.newDueDate.seconds : seconds = cheque.newDueDate._seconds;

                        const newDueDate = moment.unix(seconds);

                        //check if specific date was selected
                        if (date) {
                            //return held cheques of this date
                            if (newDueDate.isSame(date, 'day')) {
                                //put held cheque into cheque container (obj)
                                heldCheques[doc.id] = cheque;
                            } else {
                                console.log('no data');
                            }
                        } else {
                            //return all held cheques before and today's
                            const today = moment();

                            if((newDueDate.isSameOrBefore(today, 'day'))) {
                                //put held cheque into cheque container (obj)
                                heldCheques[doc.id] = cheque;
                            } else {
                                console.log('no data');
                            }
                        }
                    }
                });

                dispatch({
                    type: FETCH_HELD_CHEQUES_SUCCESSFUL,
                    payload: { heldCheques, date }
                })

            }, function (error) {
                console.log("error fetching held cheques");
                dispatch({ type: FETCH_HELD_CHEQUES_FAILED });
                console.log(error);
            })

    }

};

export const fetchAllHeldCheques = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_ALL_HELD_CHEQUES });

        let heldCheques = {};

        firebase.firestore().collection("loanTerms").where("chequeStatus", "==", "held")
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {
                    //add service charges to object
                    heldCheques[doc.id] = doc.data();
                });
                dispatch({
                    type: FETCH_ALL_HELD_CHEQUES_SUCCESSFUL,
                    payload: heldCheques
                });
            },
            function (error) {
                console.log("error fetching all held cheques");
                dispatch({ type: FETCH_ALL_HELD_CHEQUES_FAILED });
                console.log(error);
            })
    }
};

export const updateHeldChequeStatus = ({customerID, loanID, paidAmount, loanTermID, chequeStatus, previousChequeStatus }) => {

    const lastEdit = new Date();

    let updates = {chequeStatus, lastEdit};

    if (chequeStatus === "bounced" || chequeStatus === "notDeposited") {
        updates["termStatus.status"] = false;
        updates["loanTermStatus"] = false;
        updates["termStatus.penalInterest"] = 0;
    }


    return(dispatch) => {

        dispatch({ type: UPDATE_HELD_CHEQUES_STATUS });
        const logDate = new Date();
        const code = Math.random().toString(36).substring(6).toUpperCase();
        const ID = moment().unix();
        const logIDx = ID.toString();
        const logID = `${code}${logIDx}`;
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("logs").doc(logID)
            .set({ customerID, loanID, logID, triggerEventID: loanTermID, paidAmount, logDate, event: `updating cheque status from ${previousChequeStatus} to ${chequeStatus}` })
            .then(() => {
                firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(loanTermID)
                    .update(updates)
                    .then(() => {
                        // update held cheque status to term bucket
                        updateHeldChequeStatusToBucket({ customerID, loanID, paidAmount, loanTermID, updates, chequeStatus, previousChequeStatus, lastEdit, dispatch });
                    })
                    .catch(error => {
                        dispatch({ type: UPDATE_HELD_CHEQUES_STATUS_FAILED });
                        console.log(error);
                        console.log("cheque status is not updated to customer profile");
                        dispatch({ type: CLEAR_UPDATE_HELD_CHEQUE_MESSAGE });
                    })
            })
            .catch(e => {
                console.log(e);
                console.log("could not save log");
            });
    };
};

function updateHeldChequeStatusToBucket({ customerID, loanID, paidAmount, loanTermID, updates, chequeStatus, previousChequeStatus, lastEdit, dispatch }) {
    firebase.firestore().collection("loanTerms").doc(loanTermID)
        .update(updates)
        .then(() => {
            dispatch({ type: UPDATE_HELD_CHEQUES_STATUS_SUCCESSFUL });
            console.log("cheque status is updated");
            dispatch({ type: CLEAR_UPDATE_HELD_CHEQUE_MESSAGE });

            if (chequeStatus === "cleared") {
                storeCurrentPaid({ loanID, loanTermID, customerID, paidAmount });
                computeChequePayment({ loanID, paidAmount, customerID, lastEdit });
            } else {
                if (previousChequeStatus === "cleared") {
                    //if yes, deduct the cleared amounts
                    deductChequePayment({ customerID, loanID, loanTermID, paidAmount, dispatch });
                }
            }
        })
        .catch(error => {
            dispatch({ type: UPDATE_HELD_CHEQUES_STATUS_FAILED })
            console.log(error);
            console.log("cheque status is not updated to terms");
            dispatch({ type: CLEAR_UPDATE_HELD_CHEQUE_MESSAGE });
        })
}

function storeCurrentPaid({ loanID, loanTermID, customerID, paidAmount }) {
    //
    //const currentPaidID = Math.random().toString(36).substring(6).toUpperCase();
    const currentPaidID = loanTermID;
    //save current paid  to bucket
    firebase.firestore().collection("currentPaidBucket").doc(currentPaidID)
        .set({loanID, customerID, loanTermID, paidAmount, currentPaidID})
        .then(() => {
            console.log("current paid is saved to bucket");
            //update current paid amount on loan bucket and user loan path

        }).catch((error) => {
        console.log("error in saving current paid to loan bucket");
        console.log(error);
    });
}

function computeChequePayment({ customerID, loanID, paidAmount, lastEdit }) {
    firebase.firestore().collection("loans").doc(loanID).get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data();
                const oldTotalChequePaid = data.totalChequePaid;
                //calculate total paid
                const oldTotalPaid = data.totalPaid;

                //add paid amount to old total cheque paid
                const totalChequePaid = paidAmount + oldTotalChequePaid;
                //add paid amount to old total paid
                const totalPaid = paidAmount + oldTotalPaid;

                //check if loan has been cleared or not
                let loanStatus = false;
                const totalAmount = data.totalAmount;
                //find if there are extra fees and their total
                let totalArray = [];
                if ("extraFees" in data) {
                    //there are extra fees, loop over each extra fee and put amount into amountArray
                    _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
                }
                let totalFeesAmount = 0;
                //calculate the total amount from numbers in the array
                //check that total array is not empty
                if (totalArray.length > 0) {
                    totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
                }
                let debt = totalAmount + totalFeesAmount;
                if ("earlyLiquidation" in data) {
                    debt = data.earlyLiquidation;
                }
                const clearedAmount = debt - totalPaid;
                //if clearedAmount is below zero then debt is cleared
                if (clearedAmount <= 0) {
                    loanStatus = true;
                }

                //assign loanStatus true to all terms in this loan
                assignClearedLoanStatus({ customerID, loanID, loanStatus });

                //register currentPaid
                let oldCurrentPaid = 0;
                if ("currentPaid" in data) {
                    oldCurrentPaid = data.currentPaid;
                }
                const currentPaid = paidAmount + oldCurrentPaid;

                //post to loan profile on loan bucket and customer profile
                firebase.firestore().collection("loans").doc(loanID).update({ totalChequePaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: lastEdit, cheque: true  } })
                    .then(() => {
                        console.log("calculations saved to bucket");
                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                            .update({ totalChequePaid, totalPaid, loanStatus, currentPaid, lastPayment: { paidAmount, time: lastEdit, cheque: true  } })
                            .then(() => {
                                console.log("calculations saved to loan in profile");
                            }).catch((error) => {
                            console.log("error in saving calculations to loan in profile");
                            console.log(error);
                        });
                    }).catch((error) => {
                    console.log("error in saving calculations to loan in bucket");
                    console.log(error);
                });

            }
        }).catch(e => e);
}

function deductChequePayment({ customerID, loanID, loanTermID, paidAmount, dispatch }) {
    firebase.firestore().collection("loans").doc(loanID).get()
        .then((doc) => {
            if (doc.exists) {
                const data = doc.data();

                const oldTotalChequePaid = data.totalChequePaid;
                //calculate total paid
                const oldTotalPaid = data.totalPaid;

                //add paid amount to old total cheque paid
                const totalChequePaid = oldTotalChequePaid - paidAmount;
                const totalPaid = oldTotalPaid - paidAmount;

                //check if loan has been cleared or not
                let loanStatus = false;
                const totalAmount = data.totalAmount;
                //find if there are extra fees and their total
                let totalArray = [];
                if ("extraFees" in data) {
                    //there are extra fees, loop over each extra fee and put amount into amountArray
                    _.map(data.extraFees, extraFee => totalArray.push(extraFee.extraFeeAmount));
                }
                let totalFeesAmount = 0;
                //calculate the total amount from numbers in the array
                //check that total array is not empty
                if (totalArray.length > 0) {
                    totalFeesAmount = totalArray.reduce((a, b) => a + b, 0);
                }
                let debt = totalAmount + totalFeesAmount;
                if ("earlyLiquidation" in data) {
                    debt = data.earlyLiquidation;
                }
                const clearedAmount = debt - totalPaid;
                //if clearedAmount is below zero then debt is cleared
                if (clearedAmount <= 0) {
                    loanStatus = true;
                }

                let update = { totalChequePaid, totalPaid, loanStatus };


                if ("currentPaid" in data) {
                    const currentPaid = data.currentPaid;
                    //write to database
                    console.log("we have current paid");
                    console.log({ currentPaid: data.currentPaid });
                    fetchLoanTermCurrentPaid({ customerID, loanID, loanTermID, currentPaid });
                }

                if ("modulo" in data) {
                    //write to database
                    console.log("we have a modulo");
                    computeModuloAndCurrentPaid({ customerID, loanID, loanTermID, paidAmount, dispatch })
                }

                //post to loan profile on loan bucket and customer profile
                firebase.firestore().collection("loans").doc(loanID).update(update)
                    .then(() => {
                        console.log("cheque deduction calculations saved to bucket");
                        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                            .update(update)
                            .then(() => {
                                console.log("cheque deduction calculations saved to loan in profile");
                                assignClearedLoanStatus({ customerID, loanID, loanStatus });
                            }).catch((error) => {
                            console.log("error in saving cheque deduction calculations to loan in profile");
                            console.log(error);
                        });
                    }).catch((error) => {
                    console.log("error in saving cheque deduction calculations to loan in bucket");
                    console.log(error);
                });

            }
        }).catch(e => e);
}

function assignClearedLoanStatus({ customerID, loanID, loanStatus }) {
    //fetch loan terms ids
    //create loan terms obj
    let loanTerms = {};

    firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").where("loanID", "==", loanID)
        .get()
        .then((querySnapshot) => {

            if (querySnapshot.size !== 0) {
                querySnapshot.forEach(function(doc) {
                    // doc.data() is never undefined for query doc snapshots
                    loanTerms[doc.id] = doc.data();
                });
            }

        })
        .catch(e => console.log(e));

    //loop over all loan terms
    _.map(loanTerms, term => {
        firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loanTerms").doc(term.loanTermID)
            .update({ loanStatus })
            .then(() => {
                firebase.firestore().collection("loanTerms").doc(term.loanTermID)
                    .update({ loanStatus })
                    .then(() => {
                        //
                    })
                    .catch(e => {
                        console.log(e);
                    })
            }).catch(e => console.log(e))
    })
}

function fetchLoanTermCurrentPaid({ customerID, loanID, loanTermID, currentPaid }) {
    console.log({ customerID, loanID, loanTermID, currentPaid });
    firebase.firestore().collection("currentPaidBucket").doc(loanTermID).get()
        .then(doc => {
            if (doc.exists) {
                console.log("currentPaid in loan term id captured");
                const currentPaidTerm = doc.data();

                //initialize data
                const paidAmount = currentPaidTerm.paidAmount;
                const currentPaidID = currentPaidTerm.currentPaidID;

                //check if loan current paid is bigger or equal to loan term current paid
                if (currentPaid >= paidAmount) {
                    const pay = currentPaid - paidAmount;

                    //check that current paid is not 0
                    if (pay >= 0) {
                        //write to database
                        updateCurrentPaid({ customerID, loanID, pay, currentPaidID})
                    } else {
                        //dont include in update
                    }
                }

            } else {
                console.log("currentPaid path does not exists");
            }
        }).catch(e => {
        console.log("Error getting documents: ", e);
    })
}

function updateCurrentPaid({customerID, loanID, pay, currentPaidID}) {
    //
    //post to loan profile on loan bucket and customer profile
    let update = {};

    if (pay === 0) {
        update["currentPaid"] = firebase.firestore.FieldValue.delete()
    } else {
        update["currentPaid"] = pay;
    }
    firebase.firestore().collection("loans").doc(loanID)
        .update(update)
        .then(() => {
            console.log("cheque current paid saved to bucket");

            firebase.firestore().collection("users").doc("clients").collection(customerID).doc("public").collection("loans").doc(loanID)
                .update(update)
                .then(() => {
                    console.log("current paid saved to loan in profile");
                    deleteLoanTermCurrentPaid({currentPaidID});

                }).catch((error) => {
                console.log("error in saving update current paid to loan in profile");
                console.log(error);
            });

        }).catch((error) => {
        console.log("error in update current paid to loan in bucket");
        console.log(error);
    });
}

function deleteLoanTermCurrentPaid({currentPaidID}) {
    //
    firebase.firestore().collection("currentPaidBucket").doc(currentPaidID)
        .delete()
        .then(() => {
            //
            console.log("current paid delete from current paid bucket");
        })
        .catch(e => {
            console.log(e);
        })
}

export const computeModuloAndCurrentPaid = ({ customerID, loanID, loanTermID, paidAmount, dispatch }) => {

    dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID });

    const data = JSON.stringify({
        customerID,
        loanID,
        paidAmount,
        currentPaidID: loanTermID
    });

    const url = `${project.serverUrl}computeModuloAndCurrentPaid`;
    fetch(url, {
        method: 'POST',
        mode: 'cors',
        body: data,
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {

            dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_SUCCESSFUL });
            console.log("compute_modulo_and_current_paid_successful");
            console.log(response);

        }).catch((error) => {
        dispatch({ type: COMPUTE_MODULO_AND_CURRENT_PAID_FAILED });
        console.log("compute_modulo_and_current_paid_successful");
        console.log(error);
    })
};




