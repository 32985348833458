import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { message, Popconfirm, Button} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//action imports
import { changedCharacterValue, addClientCharacter, deleteClientCharacter, fetchCharacters } from '../../../actions/SettingsActions';


//component imports
import { Input } from '../../common/Input';
import {BeatLoader} from "react-spinners";


class Character extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            characterValue: this.props.characterValue,
            characterLoading: this.props.characterLoading,
            characterSuccess: this.props.characterSuccess,
            characterFailed: this.props.characterFailed,
            characters: this.props.characters,
        };
        this.onAddCharacterValue = this.onAddCharacterValue.bind(this);
        this.onDeleteCharacterValue = this.onDeleteCharacterValue.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.characterValue !== this.props.characterValue) {
            this.setState({ characterValue: nextProps.characterValue });
        }
        if (nextProps.characterLoading !== this.props.characterLoading) {
            this.setState({ characterLoading: nextProps.characterLoading });
        }
        if (nextProps.characterSuccess !== this.props.characterSuccess) {
            this.setState({ characterSuccess: nextProps.characterSuccess });
        }
        if (nextProps.characterFailed !== this.props.characterFailed) {
            this.setState({ characterFailed: nextProps.characterFailed });
        }
        if (nextProps.characters !== this.props.characters) {
            this.setState({ characters: nextProps.characters });
        }

    }


    componentDidMount() {
        this.props.fetchCharacters();
    }

    onAddCharacterValue(){
        const {characterValue} = this.state;

        if(characterValue){
            //
            this.props.addClientCharacter({characterValue})
        } else {
            message.warning("Kindly enter all fields");
        }
    }

    renderButton () {
        //extract state
        const { characterLoading } = this.state;

        if (characterLoading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        }  else {
            return (
                <div className={"masterFileUploadButton"}>
                    <Button onClick={() => this.onAddCharacterValue()}>Add</Button>
                </div>
            );
        }
    }


    onDeleteCharacterValue(character) {
        //
        if(character){
            const characterID = character.characterID;
            this.props.deleteClientCharacter({characterID});
        } else {
            message.warning("There is no character to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderStatuses() {
        const {characters} = this.state;

        const charactersArray = _.map(characters, character => character);

        if(charactersArray.size !== 0) {
            return (
                <div>
                    {
                        charactersArray.map(character => {
                            const index = charactersArray.indexOf(character);
                            const position = index + 1;
                            return (
                                <div key={character.characterID} className={"industryListValue"}>
                                    <div><p>{position}</p></div>
                                    <div><p>{character.value}</p></div>
                                    <div>
                                        <Popconfirm
                                            title="Are you sure to delete this character?"
                                            onConfirm={() => this.onDeleteCharacterValue(character)}
                                            onCancel={() => this.cancel()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            return <h4>There is no data found</h4>
        }
    }

    render() {
        return (
            <div className={"industryContainer"}>
                <div>
                    <h2>Enter Character Value</h2>
                    <Input className="inputField"
                           type="text"
                           placeholder="Title"
                           onChange={e => this.props.changedCharacterValue({ prop: 'characterValue', value: e.target.value })}
                           value={this.state.characterValue}
                    />
                </div>
                <div>
                    {this.renderButton()}
                </div>

                <div>
                    <div>
                        <div className={"industryList"}>
                            <div><h5>#</h5></div>
                            <div><h5>Client Character</h5></div>
                            <div><h5>Delete Character</h5></div>
                        </div>
                        <div>{this.renderStatuses()}</div>
                    </div>
                </div>

            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

    const {characterValue, characterLoading, characterSuccess,characterFailed, characters } = state.settings

    //return object with state properties
    return {
        characterValue,
        characterLoading,
        characterSuccess,
        characterFailed,
        characters,
    }
};


export default connect(mapStateToProps, {changedCharacterValue, addClientCharacter, deleteClientCharacter, fetchCharacters })(Character);