import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import moment from 'moment';
import { projectArray } from "../../../env/PROJECTSCONFIGURATIONS";

//actions import
import {fetchServiceCharges, deleteServiceCharge} from "../../../actions/ServiceChargeActions";

//component imports
import {Button, message, Popconfirm} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";

class ViewServiceCharge extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            deleteServiceChargeSuccessful: this.props.deleteServiceChargeSuccessful,
            serviceChargesPayments:  this.props.serviceChargesPayments,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            currentLoan: this.props.currentLoan,
            localCurrency: "TSH",
        };

        this.displayCurrency = this.displayCurrency.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.checkCurrencyFormat = this.checkCurrencyFormat.bind(this);
    };

    componentWillMount() {
        this.props.fetchServiceCharges();

        this.checkCurrencyFormat();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.serviceChargesPayments !== this.props.serviceChargesPayments) {
            this.setState ({serviceChargesPayments: nextProps.serviceChargesPayments})
        }

        if (nextProps.deleteServiceChargeSuccessful !== this.props.deleteServiceChargeSuccessful) {
            this.setState ({deleteServiceChargeSuccessful: nextProps.deleteServiceChargeSuccessful})
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState ({profile: nextProps.profile})
        }

        if (nextProps.currentLoan !== this.props.currentLoan) {
            this.setState ({currentLoan: nextProps.currentLoan})
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState ({systemInfo: nextProps.systemInfo})
        }
    }

    checkCurrencyFormat() {
        //pull firebase config object that was used to initialize project
        const retrievedObject = localStorage.getItem('afclFirebaseInitializationObject');

        //check that retrieved object is not empty
        if (retrievedObject) {
            const previousConfig = JSON.parse(retrievedObject);

            //compare previousConfig with objects in project array
            const localCountryObjectArray = projectArray.filter(project => project.config.apiKey === previousConfig.apiKey);
            const localCountryObject = localCountryObjectArray[0];

            //grab currency and set in component state
            const currency = localCountryObject.currency;
            this.setState({ localCurrency: currency });
        } else {
            this.setState({ localCurrency: "TSH" });
        }
    }

    displayCurrency(value, charge) {
        //extract state
        const { profile, systemInfo, localCurrency } = this.state;

        let displayValue;
        //check if currency is activated in profile
        let currency = "USD";

        //check if payment object is not empty
        if (charge) {
            const loanCurrency = charge.currency;
            if (loanCurrency === "usd") {
                currency = "USD";

                displayValue = value;
            } else {
                if (profile.viewInUSD) {
                    //change currency to usd
                    currency = "USD";

                    //check if user has selected a specific exchange rate date
                    if ("exchangeRateOnDate" in profile) {
                        //grab the exchange rate from profile
                        const { exchangeRateOnDate } = profile;
                        displayValue = value / exchangeRateOnDate;
                    } else {
                        //grab the default exchange rate from system
                        const { exchangeRate } = systemInfo;
                        displayValue = value / exchangeRate;
                    }
                } else {
                    currency = localCurrency;

                    displayValue = value;
                }
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });

        return <h4 id={"loanTermsValue"}>{formatter.format(displayValue)}</h4>;
    }

    onDelete(charge) {

        //check if service charge is not empty
        if (!(_.isEmpty(charge))) {
            //extract service charge payment ID
            const serviceChargeID = charge.serviceChargeID;

            this.props.deleteServiceCharge(serviceChargeID);
        } else {
            message.warning("No service charge to delete");
        }
    }

    cancelDelete = () => {
        message.info('Deletion is canceled');
    };

    renderServiceCharges() {

        const {serviceChargesPayments} = this.state;

        if(!(_.isEmpty(serviceChargesPayments))) {
            const charge = _.map(serviceChargesPayments, charge => charge);

            return(
                <div>
                    {
                        charge.map(charge => {

                            const serviceChargeDateSec = charge.serviceChargeDate.seconds;
                            const chargeDate = moment.unix(serviceChargeDateSec).format("DD/MM/YYYY");

                            return(
                                <div key={charge.serviceChargeID} className={"viewServiceChargeValue"}>
                                    <div>
                                        { this.displayCurrency(charge.serviceChargePaidAmount, charge) }
                                    </div>

                                    <div>
                                        <p>{charge.serviceChargeBankName}</p>
                                    </div>

                                    <div>
                                        <p>{chargeDate}</p>
                                    </div>

                                    <div>
                                        <p>{charge.serviceChargeComments}</p>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                        <Popconfirm
                                            title="Are you sure to delete this service charge?"
                                            onConfirm={() => this.onDelete(charge)}
                                            onCancel={() => this.cancelDelete()}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            )
                        })
                    } 
                </div>
            )
        } else {
            return <p className={"userAdminsEmptyWarning"}>There are no service charges</p>;
        }
    }

    renderMessage() {
        const {deleteServiceChargeSuccessful} = this.state;

        if (deleteServiceChargeSuccessful) {
            return (
                <p>{message.success("Service charge is deleted..")}</p>
            );
        } else {
            return null
        }
    }

    
    render(){
        return (
            <div className={"viewServiceChargesBox"}>
                <h4>Service Charges</h4>
                
                <div className={"viewServiceChargeHeaders"}>
                    <div>
                        <p>Paid Amount</p>
                    </div>
                    <div>
                        <p>Bank Name</p>
                    </div>
                    <div>
                        <p>Date</p>
                    </div>
                    <div>
                        <p>Comment</p>
                    </div>
                    <div>
                    </div>
                </div>

                <div>
                    {this.renderServiceCharges()}
                </div>
                <div>
                    {this.renderMessage()}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const {serviceChargesPayments, deleteServiceChargeSuccessful} = state.serviceCharge;
    const { profile } = state.profile;
    const { systemInfo } = state.app;
    const { currentLoan } = state.loans;

    return {
        deleteServiceChargeSuccessful,
        serviceChargesPayments,
        profile,
        systemInfo,
        currentLoan,
    }
};



export default connect(mapStateToProps, {fetchServiceCharges, deleteServiceCharge})(ViewServiceCharge)
