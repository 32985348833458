import React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import _ from "lodash";
import {message, } from "antd"
//import { Alert, Space } from 'antd';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//action imports
import { uploadMasterFile, migrateMasterFile, clearMessages } from "../../actions/MasterFileActions";

//component imports
//import {Input} from "./common/Input";
import {Button} from "../common/Button";
//import {Spinner} from "../common/Spinner";

//file imports
import excelIcon from '../../assets/images/excel.png';
import flowerImage from '../../assets/images/flower.png';
import {BeatLoader} from "react-spinners";

class MasterFile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedFile: this.props.selectedFile,
            profile: this.props.profile,
            loading: this.props.loading,
            masterFileUploaded: this.props.masterFileUploaded,
            masterFileUploadFailed: this.props.masterFileUploadFailed,
            migration: false,
            penalInterest: false,
        };

        this.onUploadClick = this.onUploadClick.bind(this);
        this.onMigrateClick = this.onMigrateClick.bind(this);
    }

    componentWillMount() {
        this.props.clearMessages();

        if (!(_.isEmpty(this.props.systemInfo))) {
            //check if migration exists
            if ("migration" in this.props.systemInfo) {
                this.setState({ migration: this.props.systemInfo.migration })
            }

            //check if penal interest exists
            if ("penalInterest" in this.props.systemInfo) {
                this.setState({ penalInterest: this.props.systemInfo.penalInterest })
            }
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }

        if (nextProps.selectedFile !== this.props.selectedFile) {
            this.setState({ selectedFile: nextProps.selectedFile });
        }

        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading });
        }

        if (nextProps.masterFileUploaded !== this.props.masterFileUploaded) {
            this.setState({ masterFileUploaded: nextProps.masterFileUploaded });
        }

        if (nextProps.masterFileUploadFailed !== this.props.masterFileUploadFailed) {
            this.setState({ masterFileUploadFailed: nextProps.masterFileUploadFailed });
        }

        if (nextProps.systemInfo !== this.props.systemInfo) {
            //check if migration exists
            if ("migration" in nextProps.systemInfo) {
                this.setState({ migration: nextProps.systemInfo.migration })
            }
            //check if penal interest exists
            if ("penalInterest" in nextProps.systemInfo) {
                this.setState({ penalInterest: nextProps.systemInfo.penalInterest })
            }
        }
    }


    onDrop = (acceptedFiles) => {
        console.log('data sent to state');
        console.log(acceptedFiles);

        this.setState({
            selectedFile: acceptedFiles[0],
        });

        const key = 'updatable';

        message.loading({ content: 'File Loading...', key });
        setTimeout(() => {
           alert("File is loaded");
        }, 1000)

    };

    onUploadClick() {

        //extract state
        const { selectedFile } = this.state;

        if (!(_.isEmpty(selectedFile))) {
            this.props.uploadMasterFile(selectedFile);
        } else {
            message.warning('Select a file to upload');
        }

    }

    onMigrateClick() {

        //extract state
        const { selectedFile } = this.state;

        if (!(_.isEmpty(selectedFile))) {
            this.props.migrateMasterFile(selectedFile);
        } else {
            message.warning('Select a file to migrate');
        }
    }

    onClose() {

    }

    renderSuccessMessage() {
        //
        const {masterFileUploaded} = this.state;
        //
         if (masterFileUploaded) {
             return   alert("File is uploaded");
         } else {
             return null;
         }
    }

    renderFailedMessage() {
        //
        const {masterFileUploadFailed} = this.state;
        //
        if (masterFileUploadFailed) {
            return   null;//alert("File is not uploaded");
        } else {
            return null;
        }
    }

    renderButton () {
        //extract state
        const { loading, migration, penalInterest } = this.state;

        if (loading) {
            return (
                <div>
                    <BeatLoader/>
                </div>
            );
        } else {
            //check if penal interest is active or not
            if (penalInterest) {
                return <h2 style={{ color: "#D7373B"}}>Deactivate Penal Interest to proceed</h2>;
            } else {
                return (
                    <div className={"masterFileUploadButton"}>
                        {
                            migration ?
                                <Button
                                    text={'Migrate'}
                                    onPress={() => this.onMigrateClick()}
                                /> :
                                <Button
                                    text={'Upload'}
                                    onPress={() => this.onUploadClick()}
                                />
                        }
                    </div>
                );
            }
        }
    }

    checkUserAccess() {
        //extract state
        const {profile} = this.state;

        const access = profile.userAccess.some(access => access === "pdc");

        if (access) {
            const maxSize = 1048576;
            return(
                <div className={"masterFileBox"}>
                    <h1>Upload excel file</h1>

                    <Dropzone
                        onDrop={this.onDrop}
                        accept={ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }
                        minSize={0}
                        maxSize={maxSize}
                        multiple={false}
                    >
                        {({getRootProps, getInputProps, isDragActive, isDragReject}) => {

                            //const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

                            return (
                                <div {...getRootProps()} className={"dropzoneInput"}>
                                    <input {...getInputProps()} />
                                    {!isDragActive && 'Click here or drop a file to upload!'}
                                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                                    {isDragReject && "File type not accepted, sorry!"}
                                    {/*{isFileTooLarge && (*/}
                                    {/*<div className="text-danger mt-2">*/}
                                    {/*File is too large.*/}
                                    {/*</div>*/}
                                    {/*)}*/}
                                </div>
                            )}
                        }
                    </Dropzone>
                    <div className={"excelIconBox"}><img src={excelIcon} alt="excel file" /></div>
                    <div className={"flowerImageBox"}><img src={flowerImage} alt="flower artwork" /></div>
                    <div className={"flowerRightImageBox"}><img src={flowerImage} alt="flower artwork" /></div>
                    <div className={"masterFileUploadButton"}>
                        {this.renderButton()}
                    </div>
                </div>
            );
        } else {
            return <h2>You have no access</h2>
        }
    }

    render() {
        return (
            <div className="text-center mt-5">
                {this.renderSuccessMessage()}
                {this.renderFailedMessage()}
                { this.checkUserAccess() }
            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

    //fetch state properties from specific reducer
    const {loading, masterFileUploaded, masterFileUploadFailed, selectedFile} = state.masterFile;

    const { profile } = state.profile;

    const { systemInfo } = state.app;

    //return object with state properties
    return {
        profile,
        loading,
        masterFileUploaded,
        masterFileUploadFailed,
        systemInfo,
        selectedFile,
    }
};


export default connect(mapStateToProps, { uploadMasterFile, migrateMasterFile, clearMessages })(MasterFile);