import React from 'react';
import { connect } from 'react-redux';
// import Dropzone from 'react-dropzone';
import { BrowserRouter, Route, NavLink, withRouter,  } from 'react-router-dom';
import _ from "lodash";
import { message, Popconfirm, Button} from "antd"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

//action imports
import { changedIndustryValue, addIndustryValue, fetchIndustryTitles, deleteTitleComplete } from '../../../actions/SettingsActions';


//component imports
import { Input } from '../../common/Input';
import { BeatLoader } from "react-spinners";


class Industry extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            industryValue: this.props.industryValue,
            industryLoading: this.props.industryLoading,
            industrySuccess: this.props.industrySuccess,
            industryFailed: this.props.industryFailed,
            industryTitle: this.props.industryTitle,
        };

    }

    componentDidMount() {
        this.props.fetchIndustryTitles();
       
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.industryValue !== this.props.industryValue) {
            this.setState({ industryValue: nextProps.industryValue });
        }
        if (nextProps.industryLoading !== this.props.industryLoading) {
            this.setState({ industryLoading: nextProps.industryLoading });
        }
        if (nextProps.industrySuccess !== this.props.industrySuccess) {
            this.setState({ industrySuccess: nextProps.industrySuccess });
        }
        if (nextProps.industryFailed !== this.props.industryFailed) {
            this.setState({ industryFailed: nextProps.industryFailed });
        }
        if(nextProps.industryTitle !== this.props.industryTitle) {
            this.setState({ industryTitle: nextProps.industryTitle })
        }

    }

    onAddIndustryValue() {
        const { industryValue } = this.state;

        if (industryValue) {
            this.props.addIndustryValue({ industryValue });
        } else {
            message.warning("Kindly enter all fields");
        }
    }

    renderButton() {
        //extract state
        const { industryLoading, } = this.state;

        if (industryLoading) {
            return (
                <div>
                    <BeatLoader />
                </div>
            );
        } else {
            return (
                <div className={"masterFileUploadButton"}>
                    <Button onClick={() => this.onAddIndustryValue()}>Add</Button>
                </div>
            );
        }
    }

    onDeleteIndustryValue(title) {
        //
        if (title){
            const industryID = title.industryID;
            this.props.deleteTitleComplete({industryID});
        } else {
            message.warning("There is no industry to delete");
        }
    }

    cancel() {
        message.error('Deletion is canceled');
    }

    renderIndustryTitles() {
        const {industryTitle} = this.state;

        const titleArray = _.map(industryTitle, title => title);

        if(titleArray.size !== 0) {
            return (
                <div>
                    {
                        titleArray.map(title => {
                            
                            const index = titleArray.indexOf(title);
                            const position = index + 1;
                            return (
                                        <div key={title.industryID} className={"industryListValue"}>

                                            <div><p>{position}</p></div>
                                            <div><p>{title.value}</p></div>
                                            <div>
                                                <Popconfirm
                                                    title="Are you sure to delete this industry?"
                                                    onConfirm={() => this.onDeleteIndustryValue(title)}
                                                    onCancel={() => this.cancel()}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Button><FontAwesomeIcon icon={faTrashAlt} style={{fontSize: '1.2rem'}} color="#d10000" /></Button>
                                                </Popconfirm>
                                            </div>
                                        
                                        </div>
                                    );
                            })
                        }
                </div>
            );
        } else {
            return <h4>There is no data found</h4>
        }
    }
    

    render() {
        return (
            <div className={"industryContainer"}>
                
                    <h2>Enter Industry Value</h2>

                    <Input 
                        type={"text"}
                        placeholder={"Title"}
                        onChange={e => this.props.changedIndustryValue({ prop: 'industryValue', value: e.target.value })}
                        value={this.state.industryValue}
                    />
                <div>
                    {this.renderButton()}
                </div>

                <div>
                    
                    <div>
                    <div className={"industryList"}>
                     <div><h5>#</h5></div>
                    <div><h5>Industry</h5></div>
                    <div><h5>Delete</h5></div>
                </div>
                <div>{this.renderIndustryTitles()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

//call method to transform redux state to component props

const mapStateToProps = (state) => {

    const { industryValue, industryLoading, industrySuccess, industryFailed, industryTitle } = state.settings

    //return object with state properties
    return {
        industryValue,
        industryLoading,
        industrySuccess,
        industryFailed,
        industryTitle,
    }
};


export default connect(mapStateToProps, { changedIndustryValue, addIndustryValue, fetchIndustryTitles, deleteTitleComplete })(Industry);